import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { SOCKET_URL } from "../constants/envs";

const WebSocketContext = createContext();

export const useSocketContext = () => {
  return useContext(WebSocketContext);
};

export const WebSocketProvider = ({ children }) => {
  const [connection, setConnection] = useState(null);
  const [onMessage, setOnMessage] = useState(null);
  const [onError, setError] = useState(null);
  const reconnectInterval = useRef(null);

  const connectWebSocket = () => {
    const newConnection = new WebSocket(SOCKET_URL);

    let connectionOpenedAt = null;

    newConnection.onopen = () => {
      connectionOpenedAt = new Date();
      console.info(
        "WebSocket connection established at",
        connectionOpenedAt.toLocaleTimeString()
      );
      setConnection(newConnection);
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
        reconnectInterval.current = null;
      }
    };

    newConnection.onclose = (error) => {
      const connectionClosedAt = new Date();
      console.error(
        "Connection Closed at",
        connectionClosedAt.toLocaleTimeString(),
        error
      );

      if (connectionOpenedAt) {
        const diff = connectionClosedAt - connectionOpenedAt;
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);
        console.info(
          `Time interval: ${hours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
        );
      }

      setConnection(null);
      if (!reconnectInterval.current) {
        reconnectInterval.current = setInterval(connectWebSocket, 5000);
      }
    };

    newConnection.onmessage = (message) => {
      if (message.data.indexOf("{") >= 0) {
        setOnMessage(JSON.parse(message.data));
      } else {
        setOnMessage(message.data);
      }
    };

    newConnection.onerror = (error) => {
      setError(error);
      console.error("Connection Error!", error);
    };
  };

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (connection) {
        connection.close();
      }
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
      }
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ connection, onMessage, onError }}>
      {children}
    </WebSocketContext.Provider>
  );
};
