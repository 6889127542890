import Close from "../../../assets/newicons/close.svg";
import aiIcon from "../../../assets/newicons/ai-white-icon.svg";
import arrow from "../../../assets/newicons/arrow-left-dark.svg";
import chooseImage from "../../../assets/newimages/choose-small-image.png";
import noImage from "../../../assets/newimages/tabs-image.png";
import heartIcon from "../../../assets/newicons/heart-icon.svg";

export const GenerateWithAI = () => {
  return (
    <div className="h-100">
      <div
        type="button"
        data-bs-dismiss="modal"
        className="d-flex justify-content-end"
      >
        <img src={Close} alt="Close" className="close-popup-button" />
      </div>
      <div className="modal-body d-flex flex-lg-row flex-column p-0 overflow-hidden h-100">
        <div
          className="d-flex w-100 h-100 flex-column flex-md-row"
          style={{ overflowY: "auto" }}
        >
          <div className="position-relative popup-sidebar-width">
            <div className="border-right ps-md-3 pe-md-3 pb-md-3 ps-2 pe-2 pb-2 pt-0 bg-white font-noto-sans">
              <div className="d-flex align-items-center justify-content-between pb-2 pt-3  bg-white position-sticky top-0">
                <div className="d-flex align-items-center gap-2">
                  <button className="bg-transparent border-0 p-0">
                    <img src={arrow} alt="arrow" />
                  </button>
                  <h5 className="fs-14 font-noto-sans fw-600 mb-0">
                    Generate with MicoAI{" "}
                  </h5>
                </div>
              </div>
              <div className="gnerate-textarea mt-4">
                <textarea
                  placeholder="Prompt user entered"
                  className="w-100 rounded"
                ></textarea>
              </div>
              <h5 className="fs-14 font-noto-sans fw-600 mb-0 mt-3">
                Choose a look & feel
              </h5>
              <div className="choose-image-container2 mt-3 ">
                <div>
                  <div className="choose-image">
                    <img src={chooseImage} alt="chooseImage" />
                  </div>
                  <span>Professional</span>
                </div>
                <div>
                  <div className="choose-image">
                    <img src={chooseImage} alt="chooseImage" />
                  </div>
                  <span>Casual</span>
                </div>
                <div>
                  <div className="choose-image">
                    <img src={chooseImage} alt="chooseImage" />
                  </div>
                  <span>Casual</span>
                </div>
              </div>
              <button
                className="d-flex align-items-center justify-content-center gap-2 px-4 border-0 black-gradiant-btn py-2 rounded-pill my-4"
                style={{ width: "auto", height: "auto" }}
              >
                <img src={aiIcon} alt="aiIcon" />
                <span>Generate</span>
              </button>
            </div>
          </div>
          {/* first view */}

          {/* <div className='genrate-image-wrapper'>
                        <div className='genrate-image-container'>
                            <span>Enter your creative prompt, choose a look & feel,and hit 'Generate' to see the magic!</span>
                         </div>
                    </div> */}

          <div className="w-100 genrate-image-wrapper">
            <div className="genrate-image">
              <div className="genrate-image-container">
                <img src={noImage} alt="noImage" className="w-100 h-100" />
              </div>
            </div>
            <div className="">
              <h5 className="fs-14 font-noto-sans fw-bolder mb-0 padding">
                Variations
              </h5>
              <div className="overflow-auto">
                <div className="casual-image-container2 mt-2 ">
                  <label
                    className={`position-relative ms-0 image-container image-container-ref mb-0 cursor-pointerselected`}
                    id="select_image"
                  >
                    <div className="export-images">
                      <img
                        loading="lazy"
                        src={noImage}
                        className="rounded-3 "
                        alt="no-img"
                      />
                    </div>
                    <input
                      type="checkbox"
                      className="image-checkbox"
                      htmlFor="select_image"
                    />
                    <img
                      src={heartIcon}
                      alt="heartIcon"
                      className="heart-icon"
                    />
                  </label>
                  <label
                    className={`position-relative ms-0 image-container image-container-ref mb-0 cursor-pointerselected`}
                    id="select_image"
                  >
                    <div className="export-images">
                      <img
                        loading="lazy"
                        src={noImage}
                        className="rounded-3 "
                        alt="no-img"
                      />
                    </div>
                    <input
                      type="checkbox"
                      className="image-checkbox"
                      htmlFor="select_image"
                    />
                    <img
                      src={heartIcon}
                      alt="heartIcon"
                      className="heart-icon"
                    />
                  </label>
                  <label
                    className={`position-relative ms-0 image-container image-container-ref mb-0 cursor-pointerselected`}
                    id="select_image"
                  >
                    <div className="export-images">
                      <img
                        loading="lazy"
                        src={noImage}
                        className="rounded-3 "
                        alt="no-img"
                      />
                    </div>
                    <input
                      type="checkbox"
                      className="image-checkbox"
                      htmlFor="select_image"
                    />
                    <img
                      src={heartIcon}
                      alt="heartIcon"
                      className="heart-icon"
                    />
                  </label>
                  <label
                    className={`position-relative ms-0 image-container image-container-ref mb-0 cursor-pointerselected`}
                    id="select_image"
                  >
                    <div className="export-images">
                      <img
                        loading="lazy"
                        src={noImage}
                        className="rounded-3 "
                        alt="no-img"
                      />
                    </div>
                    <input
                      type="checkbox"
                      className="image-checkbox"
                      htmlFor="select_image"
                    />
                    <img
                      src={heartIcon}
                      alt="heartIcon"
                      className="heart-icon"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
