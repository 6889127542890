import React, { useContext, useEffect, useState } from "react";
import { imageGet, newImagePost, newPost_V3, newPut_V3 } from "../../API/axios";
import { useTranslation } from "react-i18next";
import { useReferenceContext } from "../../context/useReferenceContext";
import { useKonvaContext } from "../../context/useKonvaContext";
import ImageContext from "../../views/Manager/component/ImageContext";
import { showAlert } from "../../views/Manager/component/AlertService";
import { CircularProgress } from "@mui/material";
import dragDropIconUrl from "../../assets/newicons/upload2.svg";

import styles from "./upload-component.module.css";

const UploadComponent = ({
  uploadKeys = {},
  onFilesFetched = () => {},
  setPopupState = () => {},
  getSheetDetails = () => {},
  getDesignDetails = () => {},
  screen,
  isMultiSelect,
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [filesSize, setFilesSize] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [imageProgress, setImageProgress] = useState(false);
  const { designId } = useReferenceContext();
  const { selectedId, selectedSheetElement, selectedSheet, selectedSheetId } =
    useKonvaContext();
  const { setImages, resetPopup } = useContext(ImageContext);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!validTypes.includes(selectedFiles[0]?.type)) {
      showAlert(t("IMAGE_UPLOAD_VALIDATION"), "error");
      return;
    }
    addFiles(selectedFiles);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false); // Reset the highlight effect
    const droppedFiles = Array.from(e.dataTransfer.files);
    const validTypes = ["image/png", "image/jpeg", "image/jpg"];
    const isValid = droppedFiles.every((file) =>
      validTypes.includes(file.type)
    );

    if (!isValid) {
      showAlert(t("IMAGE_UPLOAD_VALIDATION"), "error");
      return;
    }

    addFiles(droppedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const addFiles = (newFiles) => {
    if (selectedId?.includes("background") || selectedId?.includes("image")) {
      if (files.length + newFiles.length > 1) {
        alert("You can only upload single file at once");
      } else {
        setFiles((prev) => [...prev, ...newFiles]);
      }
    } else if (newFiles.length + files.length <= 5) {
      setFiles((prev) => [...prev, ...newFiles]);
    } else {
      alert("You can only upload up to 5 files at once");
    }
  };

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const uploadValues = async () => {
    if (screen == "visionBoard") {
      setImageProgress(true);
      const uploadPromises = files.map((file) => {
        const formData = new FormData();
        formData.append("files", file);

        for (let [key, value] of Object.entries(uploadKeys)) {
          formData.append(key, value);
        }
        return newImagePost(`file/upload`, formData);
      });
      try {
        const results = await Promise.allSettled(uploadPromises);

        const uploadedFiles = results
          .filter(
            (res) =>
              res.status === "fulfilled" &&
              (res.value.status_code === 200 || res.value.status_code === 201)
          )
          .map((res) => res.value.result[0]);

        if (onFilesFetched) {
          onFilesFetched(uploadedFiles);
        }

        setPopupState(false);
        setImageProgress(false);
      } catch (error) {
        console.error("Upload failed:", error);
        setImageProgress(false);
      }
    } else if (screen == "visionStudio") {
      setImageProgress(true);
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      formData.append("design_id", designId);
      const uploadedURLs = await newImagePost(`upload`, formData);
      if (uploadedURLs.status_code == 201 || uploadedURLs.status_code == 200) {
        let image_urls;
        if (!isMultiSelect || !selectedId?.includes("background")) {
          image_urls = uploadedURLs?.result.map((image) => image.url);
        } else {
          image_urls = uploadedURLs?.result?.[0]?.url;
        }

        let data;
        if (selectedId?.includes("background")) {
          data = await newPut_V3(`layer/changeBackgroundLayerImage`, {
            layer_id: +selectedId?.replace("background_", ""),
            layer_type: "background",
            image_url: image_urls,
          });
        } else if (selectedId?.includes("image")) {
          let blob;
          try {
            const response = await fetch(image_urls[0]);
            if (!response.ok) throw new Error("Network response was not ok");
            blob = await response.blob();
          } catch (error) {
            console.error("Error fetching image:", error);
            blob = await imageGet("download_image", {
              image_url: image_urls[0],
            });
            if (!blob) {
              throw new Error("Failed to fetch or download the image.");
            }
          }
          const reader = new FileReader();
          reader.onloadend = async () => {
            let base64Image = reader.result;
            base64Image = base64Image.replace(
              /^data:image\/(jpeg|png|gif);base64,/,
              ""
            );

            try {
              const data = await newPut_V3(`layer/changeLayerValue`, {
                layer_id: selectedSheetElement.id,
                layer_type: selectedSheetElement.image_type,
                sheet_id: selectedSheetId,
                updates: {
                  url_path: image_urls[0],
                  image_data: base64Image,
                },
              });

              if ([200, 201].includes(data?.status_code)) {
                await getSheetDetails(selectedSheetId);
              }
            } catch (apiError) {
              console.error("Error during API call:", apiError);
            }
          };

          reader.onerror = (error) => {
            console.error("FileReader error:", error);
          };

          try {
            reader.readAsDataURL(blob);
          } catch (error) {
            console.error("Error reading the image file:", error);
          }
        } else {
          data = await newPost_V3(`sheet/createSheetLayer`, {
            design_id: designId,
            layer_type: "image",
            image_urls,
          });
        }

        if ([200, 201].includes(data?.status_code)) {
          await getDesignDetails();
        }
        setImageProgress(false);
        setPopupState(false);
        setImages([]);
        setFiles([]);
      }
    }
  };

  const closeUploadPopup = () => {
    setFiles([]);
    setImages([]);
    setPopupState(false);
  };

  async function getImageDimensions(file) {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = URL.createObjectURL(file);
    });
  }

  useEffect(() => {
    (async () => {
      if (files.length) {
        const result = await Promise.all(
          files.map(async (file) => {
            const dimensions = await getImageDimensions(file);
            return dimensions;
          })
        );
        setFilesSize(result);
      }
    })();
  }, [files]);

  return (
    <div
      className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
      style={{
        zIndex: 51,
        background: "rgba(0,0,0,0.3)",
      }}
    >
      <div
        className={`bg-white p-4 shadow-lg ${styles.container}`}
        style={{
          maxWidth: "600px",
          width: "50%",
          minWidth: "400px",
          borderRadius: "1rem",
        }}
      >
        <h4 className="fw-bold mb-3">{t("UPLOAD")}</h4>
        {!selectedId && (
          <p className="text-muted">{t("IMAGE_UPLOAD_INSTRUCTION")}</p>
        )}

        {/* Drag and Drop Box */}
        <div
          className={`border-black rounded text-center p-4 position-relative border_desh`}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          style={{
            cursor: "pointer",
            transition: "background 0.3s, border-color 0.3s",
            autoFlowY: "auto",
            ...(isDragging
              ? {
                  background: "rgba(0,0,0,0.3)",
                  border: "2px solid",
                }
              : {
                  border: "2px dashed #312EFF",
                }),
          }}
        >
          <img
            src={dragDropIconUrl}
            alt="Drag and Drop Icon"
            style={{
              width: "50px",
              height: "50px",
              marginBottom: "10px",
              autoFlowY: "auto",
            }}
          />
          <p className="mt-2">{t("DRAG_TEXT")}</p>
          <p className="text-muted small">{t("OR")}</p>
          <label
            htmlFor="fileInput"
            className="font-noto-sans vBoard-btn"
            style={{
              //position: "absolute",
              //bottom: "40%",
              //left: "49%", // Centering horizontally
              //transform: "translateX(-50%)", // Adjusting to exact center
              //zIndex: 999,
              width: "auto",
              height: "48px",
              border: "2px solid #6E82FF",
              backgroundColor: "transparent",
              borderRadius: "50px",
              color: "#312EFF",
              fontSize: "16px",
              fontWeight: "600",
              padding: "10px 30px",
              transition: "0.5s all ease",
              marginRight: "0px !important",
              cursor: "pointer",
              autoFlowY: "auto",
              //display: "flex"
            }}
          >
            {t("BROWSE_FILES")}
          </label>

          <input
            id="fileInput"
            type="file"
            accept=".jpg, .png"
            multiple
            className="d-none"
            onChange={handleFileChange}
          />
        </div>

        <p className="text-muted small mt-2">{t("SUPPORTED_FILES")}</p>

        {files.length > 0 && (
          <div className={`mt-3 ${styles.imagesContainer}`}>
            {files.map((file, index) => (
              <div
                key={index}
                className="d-flex align-items-center justify-content-between border rounded p-2 mb-2"
              >
                <div
                  className={`d-flex align-items-center ${styles.itemContainer}`}
                >
                  <img
                    src={URL.createObjectURL(file)}
                    alt="preview"
                    className="rounded"
                    style={{
                      width: "40px",
                      height: "40px",
                      objectFit: "cover",
                    }}
                  />
                  <div className={styles.info}>
                    <p className="mb-0 fw-bold">{file.name}</p>
                    <div className={styles.imageInfo}>
                      <p className="mb-0 text-muted small">
                        {(file.size / 1024).toFixed(2)} KB
                      </p>
                      {filesSize[index] ? (
                        <p className={`${styles.dimensions} text-muted small`}>
                          (<span>{filesSize[index]?.width}</span> X{" "}
                          <span>{filesSize[index]?.height}</span>)
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div
                  className="cursor-pointer"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => removeFile(index)}
                >
                  <span className="fw-bold">X</span>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className={`d-flex mt-3 ${styles.buttonsContainer}`}>
          <button
            className={`${styles.cancelButton}`}
            onClick={closeUploadPopup}
            disabled={imageProgress}
            style={{
              width: "auto",
              height: "48px",
              border: "none",
              backgroundColor: "transparent",
              borderRadius: "50px",
              color: "black",
              fontSize: "16px",
              fontWeight: "600",
              padding: "10px 30px",
              transition: "0.5s all ease",
              marginTop: "5px",
              cursor: "pointer",
              autoFlowY: "auto",
            }}
          >
            {t("cancel")}
          </button>

          <button
            className=""
            disabled={imageProgress || files.length === 0}
            onClick={uploadValues}
            style={{
              background: "#000",
              borderRadius: "24px",
              width: "120px",
              backgroundColor: "#312EFF",
              color: "white",
              height: "45px",
              border: "none",
              marginTop: "5px",
              autoFlowY: "auto",
            }}
          >
            {!imageProgress ? `${t("UPLOAD")}` : ""}
            <React.Fragment>
              {imageProgress ? (
                <CircularProgress color="inherit" size={20} />
              ) : null}
            </React.Fragment>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadComponent;
