import React, { useRef, useEffect, useState } from 'react';
import { Group, Image, Transformer } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { updateElement } from '../../store/konvaSlice';
import { useKonvaContext } from '../../context/useKonvaContext';

const DraggableImage = ({
  element,
  index,
  sheetId,
  handleDragEnd,
  handleDragStart,
  handleTransformEnd,
  isSelected,
  onSelect,
}) => {
  const [image, setImage] = useState(null);
  const imageRef = useRef();
  const transformerRef = useRef();
  const dispatch = useDispatch();
  const { setContextMenuVisible, setMenuPosition, alignmentDetails, setAlignDetails, stageRef } = useKonvaContext();
  const isBackground = element.image_type === 'background' || index === 1;

  // Get sheet position from Redux store
  const sheetFromRedux = useSelector(state => {
    const sheets = state.konva.canvas?.layers?.[0]?.groups || [];
    return sheets.find(sheet => sheet.id === sheetId);
  });

  useEffect(() => {
    setAlignDetails((pre) => ({
      ...pre,
      [element.id]: JSON.parse(element.align || "{}"),
    }));
  }, [element.id]);

  useEffect(() => {
    if (element.image_data?.data) {
      const img = new window.Image();
      img.crossOrigin = "Anonymous";
      img.src = element.image_data.data.match(/\.(jpg|jpeg|png|gif|svg)$/i)
        ? element.image_data.data
        : `data:image/${element.image_data.format.toLowerCase()};base64,${element.image_data.data}`;
      img.onload = () => {
        setImage(img);
      };
    }
  }, [element.image_data]);

  useEffect(() => {
    if (isSelected && transformerRef.current && imageRef.current) {
      transformerRef.current.nodes([imageRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  // Calculate dimensions to fit image within page while maintaining aspect ratio
  const calculateDimensions = () => {
    if (!image) return { width: 500, height: 500 };

    const pageWidth = 500;
    const pageHeight = 500;
    const imageRatio = image.width / image.height;
    const pageRatio = pageWidth / pageHeight;
    const crop_scale = element.crop_scale || "fit";

    let width, height;
    if (!isBackground) {
      width = element.width || image.width;
      height = element.height || image.height;
      // Scale dimensions to fit within page bounds while maintaining aspect ratio
      if (width > pageWidth || height > pageHeight) {
        const scaleX = pageWidth / width;
        const scaleY = pageHeight / height;
        const scale = Math.min(scaleX, scaleY);
        width *= scale;
        height *= scale;
      }
      return { width, height };
    }
    switch (crop_scale) {
      case "fill":
        // Fill the entire space while maintaining aspect ratio
        if (imageRatio > pageRatio) {
          height = pageHeight;
          width = pageHeight * imageRatio;
        } else {
          width = pageWidth;
          height = pageWidth / imageRatio;
        }
        break;

      case "stretch":
        // Stretch to fill the entire space without maintaining aspect ratio
        width = pageWidth;
        height = pageHeight;
        break;

      case "center":
        // Use original image dimensions centered in the space
        width = Math.min(image.width, pageWidth);
        height = Math.min(image.height, pageHeight);
        break;

      case "fit":
      default:
        // Fit within the space while maintaining aspect ratio
        if (imageRatio > pageRatio) {
          width = pageWidth;
          height = pageWidth / imageRatio;
        } else {
          height = pageHeight;
          width = pageHeight * imageRatio;
        }
        break;
    }

    return { width, height };
  };

  const dimensions = calculateDimensions();

  // Calculate position based on crop strategy
  const calculatePosition = () => {
    if (!isBackground) {
      return { x: element.position.x, y: element.position.y };
    }
    const crop_scale = element.crop_scale || "fit";
    const x = (500 - dimensions.width) / 2;
    const y = (500 - dimensions.height) / 2;

    return { x, y };
  };

  const position = calculatePosition();

  const onTransformEnd = (e) => {
    // Get current transformer node
    const node = imageRef.current;
    if (!node) return;

    // Calculate new dimensions based on scale
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    const newWidth = Math.max(20, node.width() * scaleX);
    const newHeight = Math.max(20, node.height() * scaleY);

    // Reset scale to avoid accumulating scale values
    node.scaleX(1);
    node.scaleY(1);

    // Set the new dimensions
    node.width(newWidth);
    node.height(newHeight);

    // Get current position
    let x = node.x();
    let y = node.y();

    // Force the image to stay within sheet boundaries
    const sheetWidth = 500;
    const sheetHeight = 500;

    // Calculate how far the edges of the image are from its position point
    const halfWidth = newWidth / 2;
    const halfHeight = newHeight / 2;

    // Ensure left edge (x - halfWidth) is not less than 0
    x = Math.max(halfWidth, x);
    // Ensure right edge (x + halfWidth) is not greater than sheetWidth
    x = Math.min(x, sheetWidth - halfWidth);

    // Ensure top edge (y - halfHeight) is not less than 0
    y = Math.max(halfHeight, y);
    // Ensure bottom edge (y + halfHeight) is not greater than sheetHeight
    y = Math.min(y, sheetHeight - halfHeight);

    // Set the new position
    node.position({ x, y });

    // Update the transformer
    if (transformerRef.current) {
      transformerRef.current.nodes([node]);
      transformerRef.current.getLayer().batchDraw();
    }

    // Call the parent component's transform handler
    if (handleTransformEnd) {
      handleTransformEnd(e);
    }
  };

  const handleContextMenu = (e) => {
    if (isSelected) {
      return;
    }
    e.evt.preventDefault();
    e.cancelBubble = true;
    setMenuPosition({
      x: e.evt.clientX,
      y: e.evt.clientY,
    });
    setContextMenuVisible(true);
  };


  const handleTransform = (e) => {
    const node = imageRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    const rotation = node.rotation();

    // Reset scale to 1 after transformation
    node.scaleX(1);
    node.scaleY(1);

    // Get the new width and height after transformation
    const newWidth = Math.max(5, node.width() * scaleX); // Prevent the image from going too small
    const newHeight = Math.max(5, node.height() * scaleY); // Prevent the image from going too small

    // Create new properties to update the image
    const newProps = {
      x: node.x(),
      y: node.y(),
      width: newWidth,
      height: newHeight,
      rotation: rotation,
      opacity: element.opacity || 1,
      corner_radius: element.corner_radius || 0,
      globalCompositeOperation: element.blend_mode || 'source-over'
    };

    // Dispatch the updated properties to Redux
    dispatch(updateElement({
      sheetId,
      elementId: element.id,
      newProps
    }));
  };


  return (
    <Group
      x={position.x}
      y={position.y}
      draggable={!isBackground && element.draggable}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onContextMenu={handleContextMenu}
      visible={element.visible !== false} // Add this line to control visibility
      dragBoundFunc={(pos) => {
        const sheet = imageRef.current?.getParent()?.getParent();
        const stage = imageRef.current?.getStage();
        if (!sheet || !imageRef.current || !stage) return pos;

        // Get stage scale
        const stageScale = stage.scaleX();

        // Get current element properties
        const currentNode = imageRef.current;
        const width = currentNode.width();
        const height = currentNode.height();

        // Get sheet position
        const sheetPos = sheet.getAbsolutePosition();

        // Calculate relative position within sheet, accounting for scale
        const relativePos = {
          x: (pos.x - sheetPos.x) / stageScale,
          y: (pos.y - sheetPos.y) / stageScale
        };

        // Clamp position within sheet bounds, accounting for scale
        const maxX = Math.max(0, 500 - width);
        const maxY = Math.max(0, 500 - height);
        const newX = Math.min(Math.max(0, relativePos.x), maxX);
        const newY = Math.min(Math.max(0, relativePos.y), maxY);

        // Convert back to stage coordinates, accounting for scale
        return {
          x: (newX * stageScale) + sheetPos.x,
          y: (newY * stageScale) + sheetPos.y
        };
      }}
    >
      {image && (
        <>
          <Image
            ref={imageRef}
            image={image}

            width={dimensions.width}
            height={dimensions.height}
            opacity={element.opacity / 100 || 1}
            cornerRadius={element.corner_radius || 0}
            globalCompositeOperation={element.blend_mode || "source-over"}
            onClick={onSelect}
            // Add these lines to set the rotation origin to the center of the image
            offsetX={dimensions.width / 2}
            offsetY={dimensions.height / 2}
            // Move the image to compensate for the offset
            x={dimensions.width / 2}
            y={dimensions.height / 2}
            rotation={alignmentDetails[element.id]?.rotation || 0}
            scaleX={alignmentDetails[element.id]?.scaleX || 1}
            scaleY={alignmentDetails[element.id]?.scaleY || 1}
            onTap={onSelect}
            onTransformEnd={handleTransform}
          />

          {isSelected && (
            <Transformer
              ref={transformerRef}
              // onTransformEnd={handleTransformEnd}
              // boundBoxFunc={(oldBox, newBox) => oldBox} // Prevent resizing and rotation
              // enabledAnchors={[]}
              rotateEnabled={element?.rotateEnabled || false}
              flipEnabled={false}
              enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
              keepRatio={true}
              padding={5}
              boundBoxFunc={(oldBox, newBox) => {
                const sheet = imageRef.current?.getParent()?.getParent();
                const stage = imageRef.current?.getStage();
                if (!sheet || !stage) return oldBox;

                const stageScale = stage.scaleX();
                const sheetPos = sheet.getAbsolutePosition();

                // Calculate relative position accounting for scale
                const relativeBox = {
                  x: (newBox.x - sheetPos.x) / stageScale,
                  y: (newBox.y - sheetPos.y) / stageScale,
                  width: newBox.width / stageScale,
                  height: newBox.height / stageScale
                };

                // Minimum and maximum constraints adjusted for scale
                const MIN_SIZE = 5 / stageScale;
                const MAX_SIZE = 500 / stageScale;

                // Check boundaries accounting for scale
                const rightBound = relativeBox.x + relativeBox.width;
                const bottomBound = relativeBox.y + relativeBox.height;

                if (
                  relativeBox.x < 0 ||
                  relativeBox.y < 0 ||
                  rightBound > 500 / stageScale ||
                  bottomBound > 500 / stageScale ||
                  relativeBox.width < MIN_SIZE ||
                  relativeBox.height < MIN_SIZE ||
                  relativeBox.width > MAX_SIZE ||
                  relativeBox.height > MAX_SIZE
                ) {
                  return oldBox;
                }

                return newBox;
              }}
            />
          )}
        </>
      )}
    </Group>
  );
};

export default DraggableImage;
