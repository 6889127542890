import { configureStore } from "@reduxjs/toolkit";
import konvaReducer from "./konvaSlice";
import headerReducer from "./headerSlice";

const store = configureStore({
  reducer: {
    konva: konvaReducer,
    header: headerReducer,
  },
});

export default store;
