import axios from "axios";
import { getCookie } from "cookies-next";
import { toast } from "react-toastify";
import {
  authSessionKey,
  userPermissionsKey,
  userSiteKey,
} from "../context/useAuthContext";
import i18n from "i18next";
import { useNavigate } from "react-router-dom";
import { showAlert } from "../views/Manager/component/AlertService";

// Create a single toast ID for error messages
const ERROR_TOAST_ID = "global-error-toast";

// Helper function to show error toast
const showErrorToast = (message) => {
  // If a toast with this ID is already active, don't show another one
  if (toast.isActive(ERROR_TOAST_ID)) {
    return;
  }

  toast.error(message, {
    toastId: ERROR_TOAST_ID,
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const axiosInstance = axios.create();
const newAxiosInstance = axios.create();

// Add axios interceptor for handling errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      console.log("Unexpected API error:", error);
    } else {
      const errorMessage =
        error?.response?.data?.detail?.status_meassages ||
        error?.response?.data?.message ||
        error?.response?.data?.data?.message ||
        error?.message ||
        "Error while communicating with the server.";

      // showErrorToast(errorMessage);
    }
    return Promise.reject(error);
  }
);

// Add axios interceptor for setting Authorization header
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = getCookie(authSessionKey);
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
const validStatusCodes = [
  200, 201, 204, 404, 1027, 1050, 1043, 1054, 1058, 2001, 2004, 2011, 2012,
  2013, 2005, 6021, 2014, 6004,
];
// new Axios for create project
newAxiosInstance.interceptors.response.use(
  (response) => {
    if (validStatusCodes.includes(response?.data?.status_code)) {
      return response;
    } else if (
      response?.data?.status_code == 403 ||
      response?.data?.status_code == 401
    ) {
      const navigate = useNavigate();
      navigate("/access-denied");
    } else if (response?.data?.status_code == 400) {
      // toast.error("Error");
    } else {
      if (!response?.data?.message) {
        console.log("Unexpected API error:", response);
        // toast.error(
        //   "Unexpected error while communicating with the server. Please try again later."
        // );
      } else {
        // toast.error(
        //   i18n.t(response?.data.message[0]?.property_message) ||
        //   response?.data.message ||
        //   response?.data?.message ||
        //   "Error while communicating with the server."
        // );
      }
    }
    // return response;
  },
  (error) => {
    if (!error.response) {
      console.log("Unexpected API error:", error);
    } else {
      const errorMessage =
        error?.response?.data?.detail?.status_meassages ||
        error?.response?.data?.message ||
        error?.response?.data?.data?.message ||
        error?.message ||
        "Error while communicating with the server.";

      showErrorToast(errorMessage);
    }

    if (error?.response?.status == 403) {
      window.location.href = "/access-denied";
    }

    if (error?.response?.status == 401) {
      // Skip logout for getMyNotifications endpoint
      if (error?.config?.url?.includes("getMyNotifications")) {
        return Promise.reject(error);
      }
      // Clear all storage
      localStorage.clear();
      sessionStorage.clear(); // Add this to clear session storage

      // Clear all cookies
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });

      // Specifically remove the permissions and site data from sessionStorage
      sessionStorage.removeItem(userPermissionsKey);
      sessionStorage.removeItem(userSiteKey);

      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

// Function to make a POST request
const post = async (url, data) => {
  try {
    const token = getCookie(authSessionKey);
    if (!token || token == undefined) {
      return;
    }
    const response = await axiosInstance.post(
      // TODO, remove / from between and add it to all endpoints
      `${process.env.REACT_APP_BASE_URL}/${url}`,
      data
    );
    return response?.data || [];
  } catch (error) {
    // return new Error(error?.response?.data);
  }
};
const imagePost = async (url, data) => {
  try {
    const response = await axiosInstance.post(
      // TODO, remove / from between and add it to all endpoints
      `${process.env.REACT_APP_BASE_URL}/${url}`,
      data,
      { responseType: "blob" }
    );
    return response?.data || [];
  } catch (error) {
    return new Error(error?.response?.data);
  }
};
const newImagePost = async (url, data) => {
  try {
    const token = getCookie(authSessionKey);
    const response = await newAxiosInstance.post(
      `${process.env.REACT_APP_BASE_URL_NEW}/${url}`,
      data,
      {
        headers: {
          Authorization: `${token}`,
          "ngrok-skip-browser-warning": "1",
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    return response?.data || [];
  } catch (error) {
    return new Error(error?.response?.data);
  }
};

const newImageGet = async (url) => {
  try {
    const token = getCookie(authSessionKey);
    const response = await newAxiosInstance.get(
      `${process.env.REACT_APP_BASE_URL}/${url}?image_url`,
      {
        responseType: "blob",
      }
    );
    return response.data ?? [];
  } catch (error) {
    throw new Error(error?.response?.data);
  }
};
const imageGet = async (url, data) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASE_URL}/${url}?image_url=${encodeURI(
        data.image_url
      )}`,
      {
        responseType: "blob",
      }
    );
    return response.data ?? [];
  } catch (error) {
    return new Error(error?.response?.data);
  }
};
// Function to make a GET request
const get = async (url) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASE_URL}/${url}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

const getv1 = async (url) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASE_URL}/${url}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

const newGet = async (url) => {
  try {
    const token = getCookie(authSessionKey);
    if (!token || token === undefined) {
      return;
    }
    const response = await newAxiosInstance.get(
      `${process.env.REACT_APP_BASE_URL_NEW}/${url}`,
      {
        headers: {
          Authorization: `${token}`,
          "ngrok-skip-browser-warning": "1",
        },
      }
    );
    return response?.data;
  } catch (error) {
    // toast.error(
    //   "Unexpected error while communicating with the server. Please try again later."
    // );
    throw new Error(
      error?.response?.data || "Error while communicating with the server."
    );
  }
};

const newGet_V3 = async (url) => {
  try {
    const token = getCookie(authSessionKey);
    if (!token || token === undefined) {
      return;
    }
    const response = await newAxiosInstance.get(
      `${process.env.REACT_APP_BASE_URL_NEW_V3}/${url}`,
      {
        headers: {
          Authorization: `${token}`,
          "ngrok-skip-browser-warning": "1",
        },
      }
    );
    return response?.data;
  } catch (error) {
    // toast.error(
    //   "Unexpected error while communicating with the server. Please try again later."
    // );
    throw new Error(
      error?.response?.data || "Error while communicating with the server."
    );
  }
};

const newGet_V4 = async (url) => {
  try {
    const token = getCookie(authSessionKey);
    if (!token || token === undefined) {
      return;
    }
    const response = await newAxiosInstance.get(
      `${process.env.REACT_APP_BASE_URL_NEW_V4}/${url}`,
      {
        headers: {
          Authorization: `${token}`,
          "ngrok-skip-browser-warning": "1",
        },
      }
    );
    return response?.data;
  } catch (error) {
    // toast.error(
    //   "Unexpected error while communicating with the server. Please try again later."
    // );
    throw new Error(
      error?.response?.data || "Error while communicating with the server."
    );
  }
};

const newPost = async (url, data) => {
  try {
    const token = getCookie(authSessionKey);
    const response = await newAxiosInstance.post(
      `${process.env.REACT_APP_BASE_URL_NEW}/${url}`,
      data,
      {
        headers: {
          Authorization: `${token}`,
          // 'ngrok-skip-browser-warning': '1',
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.log("error", error);
    // toast.error(

    //     error?.response?.data?.message[0]?.property_message
    // );
    return new Error(error?.response?.data);
  }
};

const newPost_V3 = async (url, data) => {
  try {
    const token = getCookie(authSessionKey);
    const response = await newAxiosInstance.post(
      `${process.env.REACT_APP_BASE_URL_NEW_V3}/${url}`,
      data,
      {
        headers: {
          Authorization: `${token}`,
          // 'ngrok-skip-browser-warning': '1',
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.log("error", error);
    // toast.error(

    //     error?.response?.data?.message[0]?.property_message
    // );
    return new Error(error?.response?.data);
  }
};

const newPost_V4 = async (url, data) => {
  try {
    const token = getCookie(authSessionKey);
    const response = await newAxiosInstance.post(
      `${process.env.REACT_APP_BASE_URL_NEW_V4}/${url}`,
      data,
      {
        headers: {
          Authorization: `${token}`,
          // 'ngrok-skip-browser-warning': '1',
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.log("error", error);
    // toast.error(

    //     error?.response?.data?.message[0]?.property_message
    // );
    return new Error(error?.response?.data);
  }
};

const newPut = async (url, data) => {
  try {
    const token = getCookie(authSessionKey);
    const response = await newAxiosInstance.put(
      `${process.env.REACT_APP_BASE_URL_NEW}/${url}`,
      data,
      {
        headers: {
          Authorization: `${token}`,
          "ngrok-skip-browser-warning": "1",
        },
      }
    );
    return response?.data;
  } catch (error) {
    // toast.error(
    //   "Unexpected error while communicating with the server. Please try again later."
    // );
    return new Error(error?.response?.data);
  }
};

const newPut_V3 = async (url, data) => {
  try {
    const token = getCookie(authSessionKey);
    const response = await newAxiosInstance.put(
      `${process.env.REACT_APP_BASE_URL_NEW_V3}/${url}`,
      data,
      {
        headers: {
          Authorization: `${token}`,
          "ngrok-skip-browser-warning": "1",
        },
      }
    );
    return response?.data;
  } catch (error) {
    // toast.error(
    //   "Unexpected error while communicating with the server. Please try again later."
    // );
    return new Error(error?.response?.data);
  }
};

const newPut_V4 = async (url, data) => {
  try {
    const token = getCookie(authSessionKey);
    const response = await newAxiosInstance.put(
      `${process.env.REACT_APP_BASE_URL_NEW_V4}/${url}`,
      data,
      {
        headers: {
          Authorization: `${token}`,
          "ngrok-skip-browser-warning": "1",
        },
      }
    );
    return response?.data;
  } catch (error) {
    // toast.error(
    //   "Unexpected error while communicating with the server. Please try again later."
    // );
    return new Error(error?.response?.data);
  }
};

const newDelete = async (url) => {
  try {
    const token = getCookie(authSessionKey);
    const response = await newAxiosInstance.delete(
      `${process.env.REACT_APP_BASE_URL_NEW}/${url}`,
      {
        headers: {
          Authorization: `${token}`,
          "ngrok-skip-browser-warning": "1",
        },
      }
    );
    return response?.data;
  } catch (error) {
    // toast.error(
    //   "Unexpected error while communicating with the server. Please try again later."
    // );
    return new Error(error?.response?.data);
  }
};

const newDelete_V3 = async (url) => {
  try {
    const token = getCookie(authSessionKey);
    const response = await newAxiosInstance.delete(
      `${process.env.REACT_APP_BASE_URL_NEW_V3}/${url}`,
      {
        headers: {
          Authorization: `${token}`,
          "ngrok-skip-browser-warning": "1",
        },
      }
    );
    return response?.data;
  } catch (error) {
    // toast.error(
    //   "Unexpected error while communicating with the server. Please try again later."
    // );
    return new Error(error?.response?.data);
  }
};

const newDelete_V4 = async (url) => {
  try {
    const token = getCookie(authSessionKey);
    const response = await newAxiosInstance.delete(
      `${process.env.REACT_APP_BASE_URL_NEW_V4}/${url}`,
      {
        headers: {
          Authorization: `${token}`,
          "ngrok-skip-browser-warning": "1",
        },
      }
    );
    return response?.data;
  } catch (error) {
    // toast.error(
    //   "Unexpected error while communicating with the server. Please try again later."
    // );
    return new Error(error?.response?.data);
  }
};

export {
  post,
  get,
  imageGet,
  imagePost,
  newImagePost,
  newImageGet,
  newGet,
  newGet_V3,
  newGet_V4,
  newPost,
  newPost_V3,
  newPost_V4,
  newPut,
  newPut_V3,
  newPut_V4,
  newDelete,
  newDelete_V3,
  newDelete_V4,
  getv1,
};
