import { Skeleton, Box, useMediaQuery } from "@mui/material";
import React from "react";
import "../../Manager/reference.scss";

const ImageListLoader = ({ height }) => {
  // Define custom breakpoints
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:960px)");

  const getWidth = () => {
    if (isSmallScreen) return "100%";
    if (isMediumScreen) return "90%";
    return "170px";
  };

  return (
    <Box
      className="max-w-170 image-card"
      style={{ minHeight: height ? height : "" }}
      width={getWidth()}
      height={getWidth()}
      margin="auto"
      // padding={isSmallScreen ? 1 : 2}
    >
      <Skeleton variant="rounded" animation="wave" width="100%" height={170} />
    </Box>
  );
};

export default ImageListLoader;
