import React, { useState } from "react";
import styles from "./uploaded-files.module.css";

import { Button, Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { imageGet, newDelete } from "../../../API/axios";
import threeDots from "../../../assets/newicons/three-dots.svg";
import { useReferenceContext } from "../../../context/useReferenceContext";
import {
  showAlert,
  showConfirmationDialog2,
} from "../../Manager/component/AlertService";

const UploadedFiles = ({ setFiledata, modalClass }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuState, setMenuState] = useState({});
  const {
    uploadedFiles,
    setUploadedFiles,
    isProjectActive,
    referenceList,
    setReferenceList,
  } = useReferenceContext();
  const open = Boolean(anchorEl);
  const { t, i18n } = useTranslation();

  const deleteImages = (id) => {
    const updatedReferenceList = referenceList.filter(
      (image) => image.file_id !== id
    );
    setReferenceList(updatedReferenceList);
    if (document?.excalidrawAPI) {
      const { getSceneElements, updateScene, getFiles } =
        document?.excalidrawAPI;
      const updatedElements = getSceneElements().filter(
        (element) => element.type !== "image" || element.fileId !== id
      );
      const updatedFiles = Object.fromEntries(
        Object.entries(getFiles()).filter(([fileId]) => fileId !== id)
      );

      updateScene({ elements: updatedElements, files: updatedFiles });
    }
  };

  const handleClick = (event, index) => {
    setMenuState({
      ...menuState,
      [index]: {
        anchorEl: event.currentTarget,
        open: true,
      },
    });
  };

  const handleClose = (index) => {
    setMenuState({
      ...menuState,
      [index]: {
        anchorEl: null,
        open: false,
      },
    });
  };

  const handleDragStart1 = (e, item) => {
    if (!isProjectActive()) {
      return;
    }
    // Set the drag data with both the URL and file info
    const dragData = {
      type: "excalidraw-image",
      src: item?.file_url || item?.image_url,
      // id: item?.file_id || item?.image_url,
      fileName: item?.file_name || "image",
    };

    e.dataTransfer.setData("application/json", JSON.stringify(dragData));

    // Create a smaller drag preview
    // const img = new Image();
    // img.src = item?.file_url;
    // img.style.width = "50px"; // Set smaller width
    // img.height = "50px"; // Set smaller height
    // img.style.maxWidth = '200px';
    // e.dataTransfer.setDragImage(img, 10, 10);
    // Set effectAllowed to copy to indicate this is a copy operation
    e.dataTransfer.effectAllowed = "copy";
  };

  const downloadImage = async (url, item) => {
    try {
      const response = await imageGet("download_image", { image_url: url });
      const downloadUrl = URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = item?.file_name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const deleteImage = async (id) => {
    try {
      // Show confirmation dialog first
      const confirmation = await showConfirmationDialog2(
        t("deleteImageVisionBoard"), // Add this to your translation files
        t("deleteImageWarningVisionBoard"), // Add this to your translation files
        t("cancel"),
        t("yesDelete")
      );

      // Only proceed if user confirms
      if (confirmation) {
        const data = await newDelete(`file/delete?file_id=${id}`);
        if (data.status_code == 200 || data.status_code == 201) {
          const updatedFiles = uploadedFiles.filter(
            (file) => file.file_id !== id
          );
          setUploadedFiles(updatedFiles);
          deleteImages(id);
          showAlert(t("fileDeletedSuccess"), "success"); // Add this to your translation files
        }
      }
    } catch (error) {
      console.error(error);
      showAlert(t("fileDeleteError"), "error"); // Add this to your translation files
    }
  };

  return (
    <div
      className={`tabs-grid-image uploaded-files-container ${styles.gridContainer}`}
      style={
        modalClass ? { display: "flex", flexWrap: "wrap" } : { display: "grid" }
      }
    >
      {uploadedFiles && uploadedFiles.length > 0 ? (
        uploadedFiles.map((item, i) => (
          <div
            key={item?.file_id}
            className="d-flex align-items-center justify-content-center"
            style={
              modalClass
                ? { width: "13%" }
                : { minWidth: "120px", maxWidth: "120px" }
            }
          >
            <div
              className="p-2"
              style={{ backgroundColor: "#F6F6F6", height: "100%" }}
            >
              <Button
                id={`basic-button-${i}`}
                aria-controls={
                  menuState[i]?.open ? `basic-menu-${i}` : undefined
                }
                aria-haspopup="true"
                aria-expanded={menuState[i]?.open ? "true" : undefined}
                onClick={(event) => handleClick(event, i)}
                style={{ justifyContent: "flex-end", width: "100%" }}
                disabled={!isProjectActive()}
              >
                <img src={threeDots} alt="threedot" />
              </Button>

              {/* Display the base64 image if available */}
              <img
                draggable="true"
                onDragStart={(e) => handleDragStart1(e, item)}
                src={item?.file_url || "placeholder-image.jpg"}
                alt="noImage"
                // height={90}
                style={{ maxWidth: "110px", objectFit: "cover" }}
                onError={(e) => {
                  e.target.src = "placeholder-image.jpg";
                }}
                className={`${styles.imageItem}`}
                onDragStartCapture={(e) =>
                  e.dataTransfer.setDragImage(e.target, 0, 0)
                }
              />

              <span
                className={`fs-12 font-noto-sans text-truncate truncate ${styles.imageItemTitle}`}
              >
                {item?.file_name.length > 15
                  ? item?.file_name.slice(0, 15) + "..."
                  : item?.file_name}
              </span>
            </div>

            <Menu
              id={`basic-menu-${i}`}
              anchorEl={menuState[i]?.anchorEl}
              open={menuState[i]?.open || false}
              onClose={() => handleClose(i)}
              MenuListProps={{
                "aria-labelledby": `basic-button-${i}`,
              }}
            >
              <MenuItem
                onClick={() => {
                  downloadImage(item?.file_url, item);
                  handleClose(i);
                }}
              >
                {t("sheetDropdownDownload")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  deleteImage(item?.file_id);
                  handleClose(i);
                }}
              >
                {t("delete")}
              </MenuItem>
              <MenuItem
                data-bs-toggle="modal"
                data-bs-target="#renameModal"
                onClick={() => {
                  setFiledata({
                    file_id: item?.file_id,
                    file_name: item?.file_name,
                  });
                  handleClose(i);
                }}
              >
                {t("rename")}
              </MenuItem>
            </Menu>
          </div>
        ))
      ) : (
        <p
          className="fs-14"
          style={
            modalClass
              ? {
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  fontSize: 18,
                  fontWeight: 600,
                }
              : null
          }
        >
          {t("NoFileFound")}
        </p>
      )}
    </div>
  );
};

export default UploadedFiles;
