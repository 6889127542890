import React, { createContext, useState } from "react";

const ImageContext = createContext();

export const ImageProvider = ({ children }) => {
  const [images, setImages] = useState([]);
  const [resetPopup, setResetPopup] = useState("");

  return (
    <ImageContext.Provider
      value={{ images, setImages, resetPopup, setResetPopup }}
    >
      {children}
    </ImageContext.Provider>
  );
};

export default ImageContext;
