import { Suspense, useEffect } from "react";
import Header from "../views/Header";
import PageLoading from "../views/PageLoading";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../context/useAuthContext";
import { useTranslationContext } from "../context/useTranslationContext";
import { useFilterContext } from "../context/useFilterContext";
import { useTranslation } from "react-i18next";

const UserLayout = ({ children, routeInfo }) => {
  const { pathname } = useLocation();
  const { validateToken, setAreAPIsLoading, getUserSettings } =
    useAuthContext();
  const { fetchTranslations } = useTranslationContext();
  const { fetchFilterTags } = useFilterContext();

  const { i18n } = useTranslation();

  useEffect(() => {
    const lang = localStorage.getItem("selectedLanguage");
    i18n.changeLanguage(lang || "en");
  }, [pathname]);

  const getFilters = async () => {
    try {
      setAreAPIsLoading(true);

      await validateToken();
      await getUserSettings();
      await fetchFilterTags();
      await fetchTranslations();

      setAreAPIsLoading(false);
    } catch (err) {
      setAreAPIsLoading(false);

      console.error("Error fetching filters:", err);
    }
  };

  useEffect(() => {
    getFilters();
  }, []);

  return (
    <>
      {!routeInfo?.new_header && <Header />}
      <Suspense fallback={<PageLoading />}>{children}</Suspense>
    </>
  );
};
export default UserLayout;
