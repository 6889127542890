/* eslint-disable react-hooks/exhaustive-deps */
import { MenuItem, Select } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  newDelete,
  newGet,
  newGet_V3,
  newGet_V4,
  newPost,
  newPost_V3,
  newPost_V4,
} from "../../API/axios";
import { ReactComponent as AddIcon } from "../../assets/icons/addIcon.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/projectsearch.svg";
import { useAuthContext } from "../../context/useAuthContext";
import { useDebounce } from "../../context/useDebounce";
import { showAlert, showConfirmationDialog } from "./component/AlertService";
import ProjectTable from "./component/ProjectTable";
import "./project.scss";
import CreateProjectForm from "./component/createProjectForm";
import CreateProjectFormLoader from "./skeletonLoaders/CreateProjectFormLoader";
import { format } from "date-fns";
const useDebouncedValue = (inputValue, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);

  return debouncedValue;
};
const AllProject = () => {
  const [loading, setLoading] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [filteredProject, setFilteredProject] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [isAll, setIsAll] = useState(false);
  const [channelname, setChannelName] = useState("");
  const [searchBox, setSearchBox] = useState(false);
  const [isAllUsers, setIsAllUsers] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const [projectFilter, setProjectFilter] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [dueIn7Days, setDueIn7Days] = useState(false);
  const [search, setSearch] = useState("");
  const searchInputRef = useRef(null);
  const [inputProjectName, setInputProjectName] = useState("");
  const navigate = useNavigate();
  const searchDebounce = useDebounce(search);
  const { t, i18n } = useTranslation();
  const { userSettings, initialLoading, saveSelectedSite } = useAuthContext();
  const debouncedProjectName = useDebouncedValue(inputProjectName, 300);
  useEffect(() => {
    if (projectFormValue.project_name.length >= 1) {
      checkProjectName(inputProjectName);
    }
  }, [debouncedProjectName]);

  const handleClearForm = () => {
    setProjectFormValue({
      project_name: "",
      project_description: "",
      channel: "",
      required_sheet: "",
      assign_to: [],
      internal_approver: [],
      client_approver: [],
      // watchlist: false,
      watch_list: [],
      // start_date: '',
      // due_date: '',
      type_id: "",
      // project_type : ""
    });
    setErrors({
      project_name: "",
      project_description: "",
      channel: "",
      required_sheet: "",
      assign_to: "",
      internal_approver: "",
      client_approver: "",
      watch_list: "",
      // start_date: '',
      // due_date: '',
      type_id: "",
      // project_type : ""
    });
    setIsCreateDisable(false);
    closeModal();
  };

  const statusMappings = {
    "Not started": "notStarted",
    "In progress": "inProgress",
    Cancelled: "cancelled",
    Approved: "approved",
    Completed: "completed",
    "Wireframe in progress": "WireframeInProgress",
    "Waiting for wireframe confirmation": "WaitingForWireframeConfirmation",
    "Design in progress": "designInProgress",
    "Waiting for design confirmation": "WaitingForDesignConfirmation",
    Pending: "pending",
  };
  useEffect(() => {
    const modalElement = document.getElementById("exampleModal");
    const handleModalHidden = () => {
      handleClearForm();
    };
    modalElement?.addEventListener("hidden.bs.modal", handleModalHidden);
    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalHidden);
    };
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (searchBox) {
      searchInputRef.current?.focus();
    }
  }, [searchBox]);

  const toHalfWidth = (str) => {
    return str
      .replace(/[Ａ-Ｚａ-ｚ０-９！-～]/g, (char) =>
        String.fromCharCode(char.charCodeAt(0) - 0xfee0)
      )
      .replace(/　/g, " "); // Convert full-width spaces to half-width
  };
  const checkProjectName = async (value) => {
    const normalizedValue = toHalfWidth(value).trim(); // Convert to half-width and trim
    // if (normalizedValue.length < 3) {
    //   setErrors({
    //     ...errors,
    //     project_name: "It required at least 3 characters",
    //   });
    //   return;
    // }
    // Check if the first character is not a single-byte special character
    const firstCharSingleByteSpecialPattern =
      /^[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]/; // Range of single-byte special characters
    const firstCharIsAlphanumericOrDoubleByte =
      /^[a-zA-Z0-9\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]/;

    if (firstCharSingleByteSpecialPattern.test(normalizedValue.charAt(0))) {
      setErrors({
        ...errors,
        project_name:
          t("firstCharacterCannotBeSingleByte"),
      });
      setIsCreateDisable(true);
      return;
    }

    if (!firstCharIsAlphanumericOrDoubleByte.test(normalizedValue.charAt(0))) {
      setErrors({
        ...errors,
        project_name:
          t("firstCharacterMustBeAlphanumeric"),
      });
      setIsCreateDisable(true);
      return;
    }

    // Check if the % character is present anywhere in the string
    if (normalizedValue.includes("%")) {
      setErrors({
        ...errors,
        project_name: t("%CharacterNotAllowed"),
      });
      setIsCreateDisable(true);
      return;
    }

    // Allow double-byte and special characters except for %
    const validCharactersPattern =
      /^[a-zA-Z0-9\s\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF!@#\$^\&\*\(\)\-_+=\[\]\{\};:'"<>,.?\/\\|`~]*$/;
    // const testValue = validCharactersPattern.test(value);

    if (!validCharactersPattern.test(normalizedValue)) {
      setErrors({ ...errors, project_name: t("invalidCharactersDetected") });
      setIsCreateDisable(true);
      return;
    }

    try {
      const response = await newGet_V4(
        `project/checkProjectName?project_name=${normalizedValue}&site_id=${projectFormValue?.channel?.site_id}`
      );
      if (response?.status_code === 6004 || response?.status_code === 6021) {
        setErrors({ ...errors, project_name: t("projectNameAlreadyExist") });
        setIsCreateDisable(true);
      } else if (response?.status_code === 2005) {
        setErrors({ ...errors, project_name: t("projectNameValid") });
        setIsCreateDisable(false);
      }
    } catch (error) {
      console.error("Error checking project name:", error);
      setErrors({
        ...errors,
        project_name: t("anErrorOccurredPleaseTryAgain"),
      });
      setIsCreateDisable(true);
    }
  };

  const fetchProjects = async () => {
    try {
      setLoading(true);
      const data = await newGet_V3(`project`);
      setProjectList(data?.result);
      setFilteredProject(
        data?.result?.filter((role) => role?.user_role !== "Observer")
      );
      const results = data?.result;
      // Get unique statuses using the mapped values
      // const uniqueStatuses = [...new Set(results.map(project => project.status))];
      const uniqueStatuses = new Set(
        results.map((project) => {
          const status = project.status;
          return statusMappings[status] || status; // Replace if in mapping, else keep original
        })
      );
      setStatusOptions([...uniqueStatuses]);
      setLoading(false);
      setIsAll(false);
    } catch (error) {
      console.error("error", error);
      setLoading(false);
    }
  };
  const handleFormattedData = useCallback((data) => {
    setFormattedData((prevData) => {
      if (JSON.stringify(prevData) !== JSON.stringify(data)) {
        return data;
      }
      return prevData;
    });
  }, []);

  const modeChange = (value, roleName) => {
    setIsAll(value);
    if (roleName === "Observer") {
      const filteredList = projectList?.filter(
        (role) => role?.user_role !== roleName
      );
      setFilteredProject(filteredList);
    } else {
      setFilteredProject(projectList);
    }
  };
  const handleDelete = async (e, id) => {
    e.stopPropagation();
    const confirmation = await showConfirmationDialog(
      `${t("projectDeleteMsg")}`,
      "",
      "warning",
      `${t("no")}`,
      `${t("yes")}`
    );
    try {
      if (confirmation) {
        setLoading(true);
        const data = await newDelete(`project?project_id=${id}`);
        if (data.status_code === 200) {
          showAlert(i18n.t(data?.message[0]?.property_message), "success");
          fetchProjects();
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("error:???????", error);
      setLoading(false);
    }
  };

  const makeSiteObject = (str) => {
    if (!str) {
      return null;
    }
    const jsonString = str?.replace(/(\w+):([^,}]+)/g, (match, key, value) => {
      const formattedValue = isNaN(value)
        ? `"${value?.trim()}"`
        : value?.trim();
      return `"${key}":${formattedValue}`;
    });

    return JSON.parse(jsonString).site_id;
  };

  const handleUpdateProject = (
    projectID,
    channel,
    sharedWithClient,
    userRole
  ) => {
    if (
      userSettings?.permissions &&
      userSettings?.permissions?.hasOwnProperty(18)
    ) {
      if (userSettings?.role_id === 6) {
        if (sharedWithClient === 1) {
          navigate(
            `/reference?project_id=${projectID}&site_id=${makeSiteObject(channel)}`,
            {
              state: {
                siteId: makeSiteObject(channel),
                createdProjectId: projectID,
                userRole: userRole
              },
            }
          );
        } else {
          showAlert(
            "This Project is still in Process. You can see it after it is ready for the approval",
            "warning"
          );
        }
      } else if (userSettings?.role_id !== 6) {
        navigate(
          `/reference?project_id=${projectID}&site_id=${makeSiteObject(channel)}`,
          {
            state: {
              siteId: makeSiteObject(channel),
              createdProjectId: projectID,
              userRole: userRole
            },
          }
        );
      }
    }
  };

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setProjectFilter(value);
    if (value === 20) {
      modeChange(false, "Observer");
    } else {
      modeChange(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Clear the error for the current field
    setErrors({ ...errors, [name]: "" });

    if (name === "required_sheet") {
      // Allow only digits and no strings
      if (/^\d*$/.test(value.trim())) {
        setProjectFormValue({ ...projectFormValue, [name]: value.trim() });

        // Check after the user has entered a value (when it's a valid number)
        const numericValue = parseInt(value.trim(), 10);
        if (numericValue === 0) {
          setErrors({ ...errors, [name]: "Value cannot be 0!" });
        } else if (numericValue > 50) {
          setErrors({ ...errors, [name]: "Maximum sheet allowed is 50!" });
        }
      }
    } else {
      // Handle other input fields normally
      setProjectFormValue({ ...projectFormValue, [name]: value });
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);
  useEffect(() => {
    let filtered = projectList;

    if (searchDebounce?.trim()) {
      filtered = filtered?.filter((project) =>
        project?.project_name
          ?.toLowerCase()
          .includes(searchDebounce.toLowerCase())
      );
    }

    if (statusFilter) {
      filtered = filtered?.filter((project) => {
        const projectStatus = statusMappings[project.status] || project.status;
        return projectStatus === statusFilter;
      });
    }

    if (projectFilter === 20) {
      filtered = filtered?.filter(
        (project) => project?.user_role !== "Observer"
      );
    }

    // Modified Due in 7 Days Filter to check duration between created and delivery date
    if (dueIn7Days) {
      filtered = filtered.filter((project) => {
        // Skip if no final delivery date
        if (!project.final_delivery_date) return false;

        const deliveryDate = new Date(project.final_delivery_date);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Calculate remaining days from today until delivery date
        const timeDiff = deliveryDate.getTime() - today.getTime();
        const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));

        // Show projects that:
        // 1. Have 7 or fewer days remaining until delivery
        // 2. Haven't passed their delivery date yet (daysRemaining >= 0)
        return daysRemaining >= 0 && daysRemaining <= 7;
      });
    }

    setFilteredProject(filtered);
  }, [searchDebounce, statusFilter, projectFilter, projectList, dueIn7Days]);

  // Add these new state variables
  const [userList, setUserList] = useState([]);
  const [clientUserList, setClientUserList] = useState([]);
  const [clientManager, setClientManager] = useState([]);
  const [isCreateDisable, setIsCreateDisable] = useState(false);
  const [checked, setChecked] = useState(false);
  const allUsers = [...userList, ...clientUserList];

  const [projectFormValue, setProjectFormValue] = useState({
    project_name: "",
    project_description: "",
    channel: "",
    required_sheet: "",
    assign_to: [],
    internal_approver: [],
    client_approver: [],
    watch_list: [],
    type_id: "",
  });

  const [errors, setErrors] = useState({
    project_name: "",
    project_description: "",
    channel: "",
    required_sheet: "",
    assign_to: "",
    internal_approver: "",
    client_approver: "",
    watch_list: "",
    type_id: "",
  });

  // Add these new functions
  const fetchUsers = async () => {
    setIsAllUsers(false);
  };

  const fetchChannelUsers = async (site) => {
     try {
          const [channelData, designerData] = await Promise.all([
            newGet(`project/usersByRole?role_name=channel&site_id=${site?.site_id}`),
            newGet(`project/usersByRole?role_name=designer&site_id=${site?.site_id}`)
          ]);
      
           // Check if both requests were successful
        if ((channelData?.status_code === 200 || channelData?.status_code === 201) &&
        (designerData?.status_code === 200 || designerData?.status_code === 201)) {
           // Merge both results into a single list
           const mergedUsers = [...(channelData?.result || []), ...(designerData?.result || [])];
            setUserList(mergedUsers || []);
            setLoading(false);
            setIsAllUsers(true);
          }
        } catch (error) {
          console.log("error:???????", error);
          setLoading(false);
          // return [];
        }
  };

  const fetchClientUsers = async (site) => {
    try {
      const data = await newGet(
        `project/usersByRole?role_name=client&site_id=${site?.site_id}`
      );
      setClientUserList(data?.result || []);
      setClientManager(
        data?.result?.filter((user) => user.role_name === "CLIENT_MANAGER")
      );
      setLoading(false);
    } catch (error) {
      console.error("error:", error);
      setLoading(false);
    }
  };

  const handleChannelChange = (site) => {
    fetchChannelUsers(site);
    fetchClientUsers(site);
    setProjectFormValue({ ...projectFormValue, channel: site });
    setErrors({ ...errors, channel: "" });
  };

  const handlemultipleUsers = (e, options, fieldName) => {
    let newFormValue = { ...projectFormValue, [fieldName]: options };
    let newErrors = { ...errors, [fieldName]: "" };

    if (
      ["assign_to", "internal_approver", "client_approver"].includes(fieldName)
    ) {
      const combinedWatchList = [
        ...new Set([
          ...newFormValue.assign_to,
          ...newFormValue.internal_approver,
          ...newFormValue.client_approver,
        ]),
      ];

      const watchListUser = projectFormValue?.watch_list?.filter((user) =>
        combinedWatchList?.some((combined) => combined.user_id === user.user_id)
      );

      newFormValue = { ...newFormValue, watch_list: watchListUser };
    }
    setProjectFormValue(newFormValue);
    setErrors(newErrors);
  };

  const handleDateChange = (date, name) => {
    const formattedDate = date ? format(date, "MM-dd-yyyy") : null;
    setProjectFormValue({ ...projectFormValue, [name]: formattedDate });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async () => {
    const requiredFields = [
      { key: "project_name", message: t("projectNameRequired") },
      { key: "project_description", message: t("projectDescriptionRequired") },
      { key: "channel", message: t("channelRequired") },
      { key: "required_sheet", message: t("noOfSheetRequired") },
      // { key: 'assign_to', message: t("assignToRequired"), isArray: true },
      // { key: 'project_type', message: 'Project type is required.'},
      {
        key: "internal_approver",
        message: t("internalApproverRequired"),
        isArray: true,
      },
      { key: "client_approver", message: t("clientRequired"), isArray: true },
      { key: "type_id", message: t("projectTypeRequired") },
    ];
    let formIsValid = true;
    const newErrors = {};

    // Validate required fields
    requiredFields.forEach((field) => {
      if (
        field.isArray
          ? projectFormValue[field.key]?.length === 0
          : !projectFormValue[field.key]
      ) {
        if (field.key === "client_approver") {
          if (
            projectFormValue?.type_id === 2 &&
            (!projectFormValue[field.key] ||
              projectFormValue[field.key]?.length === 0)
          ) {
            newErrors[field.key] = field.message;
            formIsValid = false;
          }
        } else if (field.key === "internal_approver") {
          if (
            projectFormValue?.type_id === 1 &&
            (!projectFormValue[field.key] ||
              projectFormValue[field.key]?.length === 0)
          ) {
            newErrors[field.key] = field.message;
            formIsValid = false;
          }
        } else if (field.key === "type_id" && !projectFormValue[field.key]) {
          newErrors[field.key] = t("projectTypeRequired");
          formIsValid = false;
        } else {
          newErrors[field.key] = field.message;
          formIsValid = false;
        }
      } else if (
        field.key === "required_sheet" &&
        (!projectFormValue[field.key] || projectFormValue[field.key] === 0)
      ) {
        newErrors[field.key] =
          projectFormValue[field.key] === 0
            ? "Required sheet cannot be zero."
            : field.message;
        formIsValid = false;
      } else {
        // if (field.key === "project_name") {
        //   if (projectFormValue[field.key].length < 3) {
        //     newErrors[field.key] = "It required at least 3 ";
        //     formIsValid = false;
        //     return;
        //   }

        // }
        newErrors[field.key] = ""; // Clear errors if field is valid
      }
    });

    // Display validation errors
    setErrors(newErrors);

    if (!formIsValid) {
      return; // Stop submission if form is invalid
    }

    // Prepare form data for submission
    let approver_id = "";
    if (projectFormValue?.type_id === 1) {
      approver_id = projectFormValue?.internal_approver
        ?.map((user) => user?.account_login_id)
        .join(",");
    } else if (projectFormValue?.type_id === 2) {
      approver_id = projectFormValue?.client_approver
        ?.map((user) => user?.account_login_id)
        .join(",");
    }

    const formData = {
      project_name: projectFormValue?.project_name,
      project_desc: projectFormValue?.project_description,
      site_id: projectFormValue?.channel?.site_id,
      assign_to_user_id: projectFormValue?.assign_to
        ?.map((user) => user?.account_login_id)
        .join(","),
      approver_id,
      watchlist: projectFormValue?.watch_list
        ?.map((user) => user?.account_login_id)
        .join(","),
      required_sheet: parseInt(projectFormValue?.required_sheet, 10),
      type_id: projectFormValue?.type_id,
      project_type: 1,
    };

    try {
      setLoading(true);
      const data = await newPost_V4(
        `project/createProjectWithBoardDesign`,
        formData
      );
      setLoading(false);
      if (data.status_code === 2001) {
        showAlert(i18n.t(data?.message[0]?.property_message), "success");
        closeModal();

        // Save site and navigate with query params
        saveSelectedSite(
          projectFormValue?.channel?.site_id,
          projectFormValue?.channel
        );
        navigate(
          `/reference?project_id=${data?.result?.project_id}&site_id=${projectFormValue?.channel?.site_id}`,
          {
            state: {
              siteId: projectFormValue?.channel?.site_id,
              createdProjectId: data?.result?.project_id,
            },
          }
        );
        fetchProjects();
      }
    } catch (error) {
      console.error("Submission error:", error);
      setLoading(false);
    }
  };

  const closeModal = () => {
    const modalElement = document.getElementById("exampleModal");
    const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
    modalInstance.hide();
  };

  // Update the Add New Project button to call fetchUsers

  return (
    <>
      <section className="heroProject">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="titleWrap">
                <h2>{t("project")}</h2>
                <div className="titleAction">
                  <div
                    className={`searchBox ${searchBox ? "active" : ""}`}
                    onClick={() => setSearchBox(true)}
                  >
                    <input
                      type="search"
                      ref={searchInputRef}
                      onBlur={() => setSearchBox(false)}
                      onChange={handleSearch}
                    />
                    <div className="searchIcon">
                      <SearchIcon />
                    </div>
                  </div>
                  <Link
                    to="#"
                    onClick={() => fetchUsers()}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <AddIcon /> {t("newProject")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="project-table-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="filterTabs">
                <ul>
                  {/* <li><Link to="#">Awaiting Approval</Link></li> */}
                  <li>
                    <Link
                      to="#"
                      onClick={() => setDueIn7Days((prev) => !prev)}
                      className={dueIn7Days ? "active" : ""}
                    >
                      {t("dueInDays", { days: 7 })}
                    </Link>
                  </li>
                </ul>
                <div className="dropdownBtn">
                  <ul>
                    <li>
                      <Select
                        labelId="project"
                        id="project"
                        value={projectFilter}
                        onChange={handleFilterChange}
                        className="projectTab"
                      >
                        <MenuItem value={10}>{t("allProjects")}</MenuItem>
                        <MenuItem value={20}>{t("myProjects")}</MenuItem>
                      </Select>
                    </li>
                    <li>
                      <Select
                        labelId="status"
                        id="status"
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                        className="projectTab"
                        displayEmpty
                      >
                        <MenuItem value="">{t("ALL")}</MenuItem>
                        {statusOptions.map((status) => (
                          <MenuItem key={status} value={status}>
                            {t(status)}
                          </MenuItem>
                        ))}
                      </Select>
                    </li>
                  </ul>
                </div>
              </div>

              <ProjectTable
                projectList={filteredProject}
                handleDelete={handleDelete}
                loading={loading}
                handleUpdateProject={handleUpdateProject}
                userSettings={userSettings}
                channelname={channelname}
                onFormattedDataChange={handleFormattedData}
                searchTerm={searchDebounce}
              />
            </div>
          </div>
        </div>
      </section>

      {/* create new project modal */}
      {!initialLoading && (
        <div
          className="modal fade font-noto-sans"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content" style={{ overflow: "inherit" }}>
              <div className="modal-header border-0 pb-0">
                <h5 className="modal-title fw-bold" id="exampleModalLabel">
                  {t("createNewProject")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClearForm}
                ></button>
              </div>

              <CreateProjectForm
                handleChange={handleChange}
                handlemultipleUsers={handlemultipleUsers}
                projectFormValue={projectFormValue}
                setProjectFormValue={setProjectFormValue}
                errors={errors}
                userList={userList}
                allUsers={allUsers}
                clientManager={clientManager}
                clientUserList={clientUserList}
                handleChannelChange={handleChannelChange}
                loading={loading && initialLoading}
                handleDateChange={handleDateChange}
                setInputProjectName={setInputProjectName}
                checked={checked}
                setChecked={setChecked}
                isAllUsers={isAllUsers}
                screen="manager"
              />

              {loading ? (
                <div className="modal-footer border-0">
                  <CreateProjectFormLoader
                    height={60}
                    width={80}
                    rounded={"rounded-25"}
                  />
                  <CreateProjectFormLoader
                    height={60}
                    width={100}
                    rounded={"rounded-25"}
                  />
                </div>
              ) : (
                <div className="modal-footer border-0">
                  <p
                    type="button"
                    className="cancel_modal_footer"
                    onClick={handleClearForm}
                  >
                    {t("cancel")}
                  </p>
                  <button
                    type="button"
                    className="send_modal_footer"
                    onClick={handleSubmit}
                    disabled={isCreateDisable}
                  >
                    {t("create")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AllProject;
