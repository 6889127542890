import React, { useTransition } from "react";
import { Translation, useTranslation } from "react-i18next";

const NoDataFound = () => {
  const { t } = useTranslation();
  return (
    <div className="no-data-container">
      <svg
        viewBox="0 0 250 150"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="black"
        strokeWidth="2"
      >
        <rect x="50" y="40" width="120" height="100" strokeDasharray="5,5" />
        <circle cx="180" cy="90" r="30" stroke="black" fill="none" />
        <line
          x1="200"
          y1="110"
          x2="230"
          y2="140"
          stroke="black"
          strokeWidth="3"
        />
      </svg>
      <p>{t("noResults")}</p>
    </div>
  );
};

export default NoDataFound;
