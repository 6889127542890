import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowForward from "../../assets/newicons/btn-next.svg";
import ArrowBack from "../../assets/newicons/btn-back.svg";
import { useTranslation } from "react-i18next";

const SimilarImages = ({ similarData, handleSimilarImageClick }) => {
  const { t } = useTranslation();
  // Don't render if there's no data or only one image
  if (!similarData?.length || similarData.length <= 1) {
    return null;
  }

  return (
    <div className="smilierImagesWrapper">
      <h5>{t("SIMILAR_VISION")}</h5>
      <div className="sliderContainer">
        <div className="swiper-button-prev custom-prev">
          <img src={ArrowBack} alt="Previous" />
        </div>
        <div className="swiper-button-next custom-next">
          <img src={ArrowForward} alt="Next" />
        </div>

        <Swiper
          modules={[Navigation]}
          navigation={{
            enabled: similarData.length > 1,
            prevEl: ".custom-prev",
            nextEl: ".custom-next",
          }}
          spaceBetween={20}
          breakpoints={{
            320: {
              slidesPerView: Math.min(2, similarData.length),
              spaceBetween: 10,
            },
            768: {
              slidesPerView: Math.min(3, similarData.length),
              spaceBetween: 15,
            },
            1024: {
              slidesPerView: Math.min(4, similarData.length),
              spaceBetween: 20,
            },
            1366: {
              slidesPerView: Math.min(8, similarData.length),
              spaceBetween: 20,
            },
            1600: {
              slidesPerView: Math.min(10, similarData.length),
              spaceBetween: 20,
            },
          }}
          className="similar-images-slider"
        >
          {similarData?.map((item, index) => (
            <SwiperSlide key={index}>
              <div
                className="resultBox"
                style={{ minHeight: 150 }}
                onClick={() => handleSimilarImageClick(item)}
              >
                <img
                  src={item?.image_url}
                  alt={`micovision`}
                  className="img-fluid"
                />
                <div className="ctr-tag">
                  <p>{(item?.image_ctr[1] * 100).toFixed(2)}%</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default SimilarImages;
