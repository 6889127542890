// src/features/header/headerSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  debugModeChecked: false, // Initial state: not checked
};

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    toggleDebugMode: (state) => {
      state.debugModeChecked = !state.debugModeChecked; // Toggle the state
    },
    setDebugMode: (state, action) => {
      state.debugModeChecked = action.payload; // Set state directly
    },
  },
});

export const { toggleDebugMode, setDebugMode } = headerSlice.actions;

export const selectDebugModeChecked = (state) => state.header.debugModeChecked;

export default headerSlice.reducer;
