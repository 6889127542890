/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, Skeleton } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  newGet,
  newGet_V4,
  newPost,
  newPut,
  newPut_V4,
} from "../../../API/axios";
import { ReactComponent as Logo } from "../../../assets/newicons/header-logo-micovision.svg";
import headerSetting from "../../../assets/newicons/header-setting.svg";
import logOutImg from "../../../assets/newicons/logout.png";
import { ReactComponent as Note } from "../../../assets/newicons/note.svg";
import { ReactComponent as Notifications } from "../../../assets/newicons/notifications.svg";
import translateIcon from "../../../assets/icons/header_icon_2.svg";
import tick from "../../../assets/icons/tick.svg";
import { useAuthContext } from "../../../context/useAuthContext";
import { useSocketContext } from "../../../context/useSocketContext";
import { showAlert } from "./AlertService";
import "./header.scss";
import HeaderDropdown from "./HeaderDropdown";
import { useSearchContext } from "../../../context/useSearchContext";
import { isParsable } from "../../../utils/functions";
import { InputSwitch } from "primereact/inputswitch";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDebugModeChecked,
  toggleDebugMode,
} from "../../../store/headerSlice";

export default function Header({
  fetchProjects,
  setIsAll,
  screen,
  onChannelNameUpdate,
  setSearchView = () => {},
}) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [notificationLoader, setNotificationLoader] = useState(false);
  const [notificationList, setNotificationList] = useState();
  const [slicedNotificationList, setSlicedNotificationList] = useState();
  const [timer, setTimer] = useState(0);
  const dropdownRef = useRef(null);
  const { removeSession, userSettings, initialLoading } = useAuthContext();
  const currentLanguage = i18n.language;
  const { onMessage } = useSocketContext();

  const dispatch = useDispatch();
  const isDebugModeChecked = useSelector(selectDebugModeChecked);

  const location = useLocation();

  const fetchNotification = async () => {
    try {
      setNotificationLoader(true);
      const data = await newGet_V4(`project/getMyNotifications`);
      const list = data?.result?.reverse();
      setNotificationList(list);
      setSlicedNotificationList(list?.slice(0, 5));
      setNotificationLoader(false);
    } catch (error) {
      console.error("error:???????", error);
      setNotificationLoader(false);
    }
  };

  const formatRelativeTime = (dateString) => {
    const date = new Date(dateString);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  const handleKeyDown = (event) => {
    if (dropdownRef.current) {
      const items = Array.from(
        dropdownRef.current.querySelectorAll(".dropdown-item")
      );
      const currentIndex = items.indexOf(document.activeElement);

      if (event.key === "ArrowDown") {
        event.preventDefault();
        const nextIndex =
          currentIndex + 1 < items.length ? currentIndex + 1 : 0;
        items[nextIndex].focus();
      }

      if (event.key === "ArrowUp") {
        event.preventDefault();
        const prevIndex =
          currentIndex - 1 >= 0 ? currentIndex - 1 : items.length - 1;
        items[prevIndex].focus();
      }
    }
  };

  function makeUserArray(input) {
    if (input) {
      const objectsArray = input.split("},").map((item) => {
        // Add back the closing curly brace that was removed during the split (if needed)
        item = item.trim().endsWith("}") ? item : item + "}";

        // Extract the id and username values
        const idMatch = item.match(/id:([a-f0-9\-]+)/);
        const usernameMatch = item.match(/username:([^,}]+)/);

        // Return the object in proper format
        return {
          id: idMatch ? idMatch[1] : null,
          username: usernameMatch ? usernameMatch[1].trim() : null,
        };
      });
      return objectsArray;
    } else {
      return;
    }
  }

  const getProjectDetail = async (createdProjectId, siteId) => {
    if (createdProjectId && siteId) {
      try {
        const data = await newGet(
          `project/getProjectDetails?project_id=${createdProjectId}&site_id=${siteId}`
        );
        if (
          data?.message[0].property_message === "PROJECT_NOT_FOUND" ||
          data?.message[0].property_message === "UNAUTHORIZED"
        ) {
          // navigate('/dashboard');
          return "PROJECT_NOT_FOUND";
        }
        return data?.result[0];
      } catch (error) {
        console.error("error:???????", error);
      }
    }
  };

  const handleLogoClick = () => {
    if (screen === "manager" || screen === "settings") {
      navigate("/dashboard");
      setSearchView(false);
    }
  };

  useEffect(() => {
    if (onMessage) {
      try {
        const socketData = onMessage;
        console.log("Websocket Message ", socketData);
        const notificationTriggerEvents = [
          "design_uploaded",
          "create_project",
          "update_project",
          "project_delete",
          "comment_mention",
          "client_design_return",
          "client_design_approve",
          "share_with_client",
          "sheet_revoked",
          "sheet_returned",
          "sheet_approved",
          "sheet_approve_request",
          "sheet_revoke_request",
          "sheet_sent_for_approval",
          "sheet_approved_notification",
          "sheet_returned_notification",
          "comment_added",
          "comment_mention",
          "timeline_created",
          "timeline_updated",
        ];

        if (notificationTriggerEvents.includes(socketData.message)) {
          // let datacomments = JSON.parse(JSON.stringify(slicedNotificationList));
          // datacomments = [...datacomments, socketData.data[0]];
          // setSlicedNotificationList(datacomments);
          fetchNotification();
          fetchProjects();
          // setIsAll(true)
        }
      } catch (error) {
        console.error("Failed to parse WebSocket message", error);
      }
    }
  }, [onMessage]);

  const handleLogout = async () => {
    try {
      await newPost(`project/logout`).then((response) => {
        if (response?.status_code === 200) {
          // console.log("response ", response);
          removeSession();
          localStorage.clear();
          sessionStorage.clear();
          localStorage.setItem("selectedLanguage", currentLanguage);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleNotification = async (notification) => {
    // const obj = JSON.parse(notification.redirect)
    let data = {
      notification_ids: [notification?.notification_id],
    };
    try {
      await newPut_V4(`project/updateNotification`, data).then((response) => {
        if (response?.status_code === 2013) {
          fetchNotification();
        }
      });
    } catch (error) {
      console.error(error);
    }
    switch (notification?.notification_type) {
      case "project_created ":
      case "project_created_assign":
      case "project_created_approver":
      case "project_created_followers":
      case "project_updated ":
      case "project_updated_assign":
      case "project_updated_approver":
      case "project_updated_followers":
      case "project_updated_creator":
      case "project_updated_assign_change":
        let searchParams = "";
        if (isParsable(notification?.redirect)) {
          searchParams = "?";
          for (let [key, value] of Object.entries(
            JSON.parse(notification?.redirect)
          )) {
            searchParams += key + "=" + value + "&";
          }
        } else {
          searchParams = notification?.redirect;
        }
        const params = new URLSearchParams(searchParams);
        const siteId = params.get("site_id");
        const projectDetailResponse2 = await getProjectDetail(
          notification?.project_id,
          siteId
        );
        if (projectDetailResponse2 === "PROJECT_NOT_FOUND") {
          showAlert("Project has been deleted", "warning");
          return;
        }
        params.delete("project_name");
        const updatedQuery = "?" + params.toString();
        if (window.location.pathname === "/reference") {
          // If already on reference page, reload with new state
          // window.location.href = `/reference?project_id=${notification?.project_id}&site_id=${obj.site_id}`;
          window.location.href = `/reference${updatedQuery}`;
        } else {
          // Otherwise use normal navigation
          navigate(`/reference${updatedQuery}`, {
            state: {
              siteId: siteId,
              createdProjectId: notification?.project_id,
            },
            replace: true,
          });
        }
        break;
      case "project_delete":
      case "project_deleted_assign":
      case "project_deleted_approver":
      case "project_deleted_followers":
      case "project_deleted_creator":
        showAlert("Project has been deleted", "warning");
        break;

      case "timeline_created":
      case "timeline_updated":
        const redirectParam = new URL(notification?.redirect);
        const SITE_ID = redirectParam.searchParams.get("site_id");
        const projectDetail_Response = await getProjectDetail(
          notification?.project_id,
          SITE_ID
        );
        if (projectDetail_Response === "PROJECT_NOT_FOUND") {
          showAlert("Project has been deleted", "warning");
          return;
        }
        if (window.location.pathname === "/reference") {
          window.location.href = `/reference?project_id=${notification?.project_id}&site_id=${SITE_ID}`;
        } else if (redirectParam.pathname === "/reference") {
          navigate(
            `/reference?project_id=${notification?.project_id}&site_id=${SITE_ID}`,
            {
              state: {
                siteId: SITE_ID,
                createdProjectId: notification?.project_id,
              },
            }
          );
        }
        break;
      case "comment_mentioned_user_created":
      case "comment_mentioned_user_updated":
      case "project_user_commented_vs":
      case "project_user_commented_vb":
        const redirectParam2 = new URL(notification?.redirect);
        const SITE_ID2 = redirectParam2.searchParams.get("channel_id");
        const DESIGN_ID = redirectParam2.searchParams.get("design_id");
        const BOARD_ID = redirectParam2.searchParams.get("board_id");
        const PROJECT_NAME = redirectParam2.searchParams.get("project_name");
        const CHANNEL_NAME = redirectParam2.searchParams.get("channel_name");
        const COMMENT_ID = redirectParam2.searchParams.get("comment_id");
        console.log("DESOGN ID ", BOARD_ID);
        const projectDetailResponse = await getProjectDetail(
          notification?.project_id,
          SITE_ID2
        );
        if (projectDetailResponse === "PROJECT_NOT_FOUND") {
          showAlert("Project has been deleted", "warning");
          return;
        }
        if (BOARD_ID) {
          navigate(
            // "/reference-details?project_id=10863&channel_id=3&project_name=Project%20Akshay%20Joshi&channel_name=Sonsing&comment_id=1457"
            `/reference-details?project_id=${notification?.project_id}&channel_id=${
              SITE_ID2
            }&project_name=${PROJECT_NAME}&channel_name=${
              CHANNEL_NAME || ""
            }&comment_id=${COMMENT_ID}`,
            {
              state: {
                createdProjectId: notification?.project_id,
                channelId: SITE_ID2,
                projectName: PROJECT_NAME,
                channelName: CHANNEL_NAME || "",
              },
            }
          );
        } else if (DESIGN_ID) {
          navigate(
            // http://localhost:5000/vision-studio?project_name=Project%20Akshay%20Joshi&sheet=10&channel_name=Sonsing&project_id=10863&design_id=1298&site_id=3&comment_id=382
            `/vision-studio?&project_name=${PROJECT_NAME}&channel_name=${
              CHANNEL_NAME || ""
            }&project_id=${notification?.project_id}&design_id=${
              DESIGN_ID
            }&site_id=${SITE_ID2}&comment_id=${COMMENT_ID}`,
            {
              state: {
                project_name: PROJECT_NAME,
                sheet: "",
                channelName: CHANNEL_NAME || "",
                createdProjectId: notification?.project_id,
              },
            }
          );
        }
        break;
      // case "share_with_client":
      //   getProjectDetail(notification?.project_id, obj.site_id).then((res) => {
      //     navigate("/design-version", {
      //       state: {
      //         project_id: notification?.project_id,
      //         version_number: getDesignVersion(notification),
      //         projectDetails: res,
      //         assignee: makeUserArray(res?.assign_to_user_name),
      //         internalApprover: makeUserArray(res?.internal_approver_username),
      //         clientApprover: makeUserArray(res?.client_approver_username),
      //         design_id: getDesignId(notification),
      //       },
      //     });
      //   });
      //   break;
      case "sheet_returned":
      case "sheet_approved":
      case "sheet_revoked":
      case "sheet_sent_for_approval":
      case "sheet_approve_request":
      case "sheet_approve_followers":
      case "sheet_revoke_request":
      case "sheet_revoke_followers":
      case "sheet_approved_notification":
      case "sheet_approved_followers":
      case "sheet_returned_notification":
      case "sheet_returned_followers":
        const params2 = new URLSearchParams(notification?.redirect);
        const projectName = params2.get("project_name");
        const siteID = params2.get("site_id");
        const sheetId = params2.get("sheet_id");
        const channelName = params2.get("channel_name");
        const designId = params2.get("design_id");
        navigate(
          `/vision-studio?project_id=${notification?.project_id}&project_name=${
            projectName
          }&sheet_id=${sheetId}&channel_name=${
            channelName || ""
          }&design_id=${designId}&site_id=${siteID}`,
          {
            state: {
              project_name: projectName,
              sheet: sheetId,
              channelName: channelName || "",
              createdProjectId: notification?.project_id,
            },
          }
        );
        break;
      default:
        if (window.location.pathname === "/reference") {
          window.location.href = `/reference?project_id=${notification?.project_id}&site_id=${siteID}`;
        } else {
          navigate(
            `/reference?project_id=${notification?.project_id}&site_id=${siteID}`,
            {
              state: {
                siteId: siteID,
                createdProjectId: notification?.project_id,
              },
            }
          );
        }
        break;
    }
  };

  const handleDebugMode = () => {
    dispatch(toggleDebugMode());
  };

  const clearAllNotifications = async () => {
    const notificationIds = notificationList.map(
      (notification) => notification.notification_id
    );

    const data = {
      notification_ids: notificationIds,
    };
    try {
      await newPut_V4(`project/updateNotification`, data).then((response) => {
        if (response?.status_code == 2013) {
          fetchNotification();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getProjectName = (notification) => {
    const obj = JSON.parse(notification.redirect);
    return obj?.project_name;
  };

  const getDesignVersion = (notification) => {
    const obj = JSON.parse(notification.redirect);
    return obj?.version_number;
  };
  const getDesignId = (notification) => {
    const obj = JSON.parse(notification.redirect);
    return obj?.design_id;
  };

  const handleLanguageSelection = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("selectedLanguage", language);
  };

  useEffect(() => {
    const result = formatDistanceToNow(new Date(2024, 7, 17, 11, 40, 10), {
      includeSeconds: true,
    });
    fetchNotification();
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const dropdown = dropdownRef.current;
    if (dropdown) {
      const dropdownToggle = dropdown.previousElementSibling;

      const handleDropdownToggle = (event) => {
        setTimeout(() => {
          const firstItem = dropdown.querySelector(".dropdown-item");
          if (firstItem) {
            // firstItem.focus();
          }
        }, 0);
      };

      dropdownToggle.addEventListener("click", handleDropdownToggle);

      return () => {
        dropdownToggle.removeEventListener("click", handleDropdownToggle);
      };
    }
  }, []);

  useEffect(() => {
    if (userSettings?.channel_name && onChannelNameUpdate) {
      onChannelNameUpdate(userSettings.channel_name);
    }
  }, [userSettings?.channel_name, onChannelNameUpdate]);

  const extractMentionsFromComment = (comment) => {
    const mentionedUsers = comment
      .replace(/@\[(.*?)\]\(.*?\)/g, "@$1")
      .replace(/:/, "");
    return mentionedUsers;
  };

  return (
    //bg-light
    <>
      <header>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="menuWrap">
                <div className="logoWrap">
                  <Link to="/dashboard" onClick={handleLogoClick}>
                    <Logo />
                  </Link>
                  <HeaderDropdown screen={screen} />
                </div>
                <div className="menuList">
                  {initialLoading ? (
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      width={40}
                      height={40}
                    />
                  ) : (
                    <ul className="menuListing">
                      <div className="btn-group dropstart">
                        {location.pathname === "/search-result" &&
                          userSettings?.permissions[3] === "DEBUG_MODE" && (
                            <li className="flex items-center m-3">
                              {" "}
                              {/* Added Tailwind classes */}
                              <div className="flex items-center gap-2">
                                <InputSwitch
                                  checked={isDebugModeChecked ? true : false}
                                  onChange={handleDebugMode}
                                  className="scale-80"
                                />{" "}
                                {/* Adjusted size with Tailwind */}
                              </div>
                            </li>
                          )}
                        <img
                          src={translateIcon}
                          width={45}
                          className="cursor-pointer dropdown-image-width"
                          alt="translateIcon"
                          data-bs-toggle="dropdown"
                          style={{ cursor: "pointer" }}
                        />
                        <div className="dropdown-menu dropdown-menu-end profile">
                          <div
                            className="dropdown-item border-0 p-3"
                            onClick={() => handleLanguageSelection("en")}
                          >
                            {currentLanguage === "en" ? (
                              <img
                                className="img-fulid"
                                height={20}
                                width={20}
                                src={tick}
                                alt="currentLang"
                              />
                            ) : (
                              <></>
                            )}
                            <span
                              className="ms-1"
                              style={{
                                paddingLeft:
                                  currentLanguage === "en" ? 0 : "20px",
                              }}
                            >
                              English
                            </span>
                          </div>
                          <div
                            className="dropdown-item border-0 p-3"
                            onClick={() => handleLanguageSelection("ja")}
                          >
                            {currentLanguage === "ja" ? (
                              <img
                                className="img-fulid"
                                height={20}
                                width={20}
                                src={tick}
                                alt="currentLang"
                              />
                            ) : (
                              <></>
                            )}
                            <span
                              className="ms-1"
                              style={{
                                paddingLeft:
                                  currentLanguage === "ja" ? 0 : "20px",
                              }}
                            >
                              日本語
                            </span>
                          </div>
                        </div>
                      </div>
                      <li>
                        <Link to="/dashboard" onClick={handleLogoClick}>
                          {t("home")}{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/projects">{t("projects")} </Link>
                      </li>
                      {/* <li>
                        <Link to="">{t('designs')} </Link>
                      </li> */}
                      {/* <li>
                        <Link to=""><Note /> </Link>
                      </li> */}
                      {/* {screen !== "settings" && */}
                      <li>
                        <Badge
                          badgeContent={notificationList?.length}
                          color="primary"
                          style={{ left: "25px", top: "-14px" }}
                        ></Badge>
                        <Link
                          to="#"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          id="dropdownMenuNotification"
                        >
                          <Notifications />{" "}
                        </Link>
                        <div
                          className="dropdown-menu dropdown-menu-end notification"
                          ref={dropdownRef}
                          onKeyDown={handleKeyDown}
                          tabIndex={-1}
                          style={{
                            boxShadow: "rgb(0 0 0 / 53%) 0px 0px 10px 0px",
                            borderRadius: "16px",
                            maxWidth: "400px",
                          }}
                        >
                          <div className="p-3">
                            <div className="main_title_drop color notificationPadding">
                              {t("notifications")}
                            </div>
                          </div>
                          <div
                            style={{
                              maxHeight: "195px",
                              overflowY: "auto",
                              overflowX: "hidden",
                            }}
                          >
                            {notificationLoader ? (
                              <div className="px-3">
                                {/* <ProjectLoader height={90} />
                      <ProjectLoader height={90} /> */}
                              </div>
                            ) : slicedNotificationList?.length > 0 ? (
                              slicedNotificationList?.map((notification, i) => {
                                let type = notification?.notification_type;
                                return (
                                  <div
                                    key={i}
                                    className="dropdown-item border-0 p-3 d-flex align-content-center gap-3"
                                    tabIndex={0}
                                    onClick={() =>
                                      handleNotification(notification)
                                    }
                                  >
                                    {notification?.user_image_url ? (
                                      <img
                                        src={notification.user_image_url}
                                        height={50}
                                        width={50}
                                        minWidth={50}
                                        className="cursor-pointer bg-secondary"
                                        alt="headerProfile"
                                        data-bs-toggle="dropdown"
                                        style={{
                                          borderRadius: "50%",
                                          minWidth: "50px",
                                        }}
                                      />
                                    ) : (
                                      <div
                                        className="cursor-pointer bg-secondary d-flex align-items-center justify-content-center"
                                        data-bs-toggle="dropdown"
                                        style={{
                                          height: 50,
                                          width: 50,
                                          borderRadius: "50%",
                                          color: "white",
                                          fontSize: "22px",
                                          minWidth: "50px",
                                        }}
                                      >
                                        {notification?.created_details?.length >
                                        0
                                          ? notification?.created_details[0]?.username
                                              ?.charAt(0)
                                              .toUpperCase()
                                          : "?"}
                                      </div>
                                    )}
                                    <div>
                                      {type === "create_project" && (
                                        <div className="profile_tile">
                                          {" "}
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}{" "}
                                        </div>
                                      )}
                                      {type === "project_created" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "project_created_assign" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "project_created_approver" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "project_created_followers" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "project_updated" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "project_updated_assign" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "project_updated_approver" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "project_updated_followers" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "project_updated_creator" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type ===
                                        "project_updated_assign_change" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "project_delete" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "project_deleted_assign" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "project_deleted_approver" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "project_deleted_followers" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "project_deleted_creator" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type ===
                                        "comment_mentioned_user_created" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? ` ${extractMentionsFromComment(notification?.notification_content_en)}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type ===
                                        "comment_mentioned_user_updated" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? ` ${extractMentionsFromComment(notification?.notification_content_en)}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "project_user_commented_vs" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "project_user_commented_vb" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type ===
                                        "project_user_commented_general" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "design_uploaded" && (
                                        <div>
                                          <div className="profile_tile">
                                            {currentLanguage === "en"
                                              ? `${notification?.notification_content_en}`
                                              : `${notification?.notification_content_jp}`}
                                          </div>
                                        </div>
                                      )}
                                      {type === "share_with_client" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "sheet_returned" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}{" "}
                                        </div>
                                      )}
                                      {type === "sheet_approved" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}{" "}
                                        </div>
                                      )}
                                      {type === "sheet_revoked" && (
                                        <div className="profile_tile">
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}{" "}
                                        </div>
                                      )}
                                      {type === "sheet_sent_for_approval" && (
                                        <div className="profile_tile">
                                          {" "}
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "sheet_approve_request" && (
                                        <div className="profile_tile">
                                          {" "}
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "sheet_approve_followers" && (
                                        <div className="profile_tile">
                                          {" "}
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "sheet_revoke_request" && (
                                        <div className="profile_tile">
                                          {" "}
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "sheet_revoke_followers" && (
                                        <div className="profile_tile">
                                          {" "}
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type ===
                                        "sheet_approved_notification" && (
                                        <div className="profile_tile">
                                          {" "}
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "sheet_approved_followers" && (
                                        <div className="profile_tile">
                                          {" "}
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type ===
                                        "sheet_returned_notification" && (
                                        <div className="profile_tile">
                                          {" "}
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "sheet_returned_followers" && (
                                        <div className="profile_tile">
                                          {" "}
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "timeline_created" && (
                                        <div className="profile_tile">
                                          {" "}
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      {type === "timeline_updated" && (
                                        <div className="profile_tile">
                                          {" "}
                                          {currentLanguage === "en"
                                            ? `${notification?.notification_content_en}`
                                            : `${notification?.notification_content_jp}`}
                                        </div>
                                      )}
                                      <div className="profile_time">
                                        {" "}
                                        {formatRelativeTime(
                                          notification?.created_timestamp
                                        )}
                                      </div>
                                      {/* {currentLanguage === "en" ? 
                                          (
                                            <p className="text-black fs-14 font-noto-sans mb-0 fw-normal mt-1 text-break" style={{ padding: "5px", marginLeft:"1px", borderLeft:"2px solid black", whiteSpace:"break-spaces"}}> Hi @Akshay Joshi, can you please check if these designs are okay </p>
                                          ): (
                                            <p className="text-black fs-14 font-noto-sans mb-0 fw-normal mt-1 text-break" style={{ padding: "5px", marginLeft:"1px", borderLeft:"2px solid black", whiteSpace:"break-spaces"}}> こんにちは @Akshay Joshi、これらのデザインが大丈夫かどうか確認してもらえますか </p>
                                          )} */}
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="dropdown-item border-0 p-3 d-flex align-content-center gap-3">
                                <div className="profile_tile">
                                  {t("noNotifications")}
                                </div>
                              </div>
                            )}
                            {notificationList &&
                            notificationList?.length > 0 ? (
                              notificationList?.length > 5 &&
                              slicedNotificationList?.length !==
                                notificationList?.length ? (
                                <div className="d-flex">
                                  <div
                                    className="link-secondary ps-4 text-decoration-underline cursor_pointer padding"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setSlicedNotificationList(
                                        notificationList
                                      );
                                    }}
                                  >
                                    <h6 className="fs-14">{t("SEE_MORE")} </h6>
                                  </div>

                                  <div
                                    className="link-secondary ps-4 text-decoration-underline cursor_pointer color"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      clearAllNotifications();
                                    }}
                                  >
                                    <h6 className="fs-14">{t("clearAll")}</h6>
                                  </div>
                                </div>
                              ) : (
                                notificationList?.length > 5 && (
                                  <div className="d-flex">
                                    <div
                                      className="link-secondary ps-4 text-decoration-underline cursor_pointer"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setSlicedNotificationList(
                                          notificationList?.slice(0, 5)
                                        );
                                      }}
                                    >
                                      See less
                                    </div>
                                    <div
                                      className="link-secondary ps-4 text-decoration-underline cursor_pointer"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                      }}
                                    >
                                      {t("clearAll")}
                                    </div>
                                  </div>
                                )
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </li>
                      {/* } */}
                      <li className="btn-group dropstart">
                        {userSettings?.user_image_url ? (
                          <img
                            src={userSettings.user_image_url}
                            height={30}
                            width={30}
                            alt="headerProfile"
                            data-bs-toggle="dropdown"
                          />
                        ) : (
                          <div
                            className="profile-manu"
                            data-bs-toggle="dropdown"
                          >
                            <span>
                              {userSettings?.username?.charAt(0).toUpperCase()}
                            </span>
                          </div>
                        )}
                        <div className="dropdown-menu dropdown-menu-end profile">
                          <div
                            className="dropdown-item border-0 p-3"
                            onClick={() => {
                              handleNavigation("/profile-settings");
                            }}
                          >
                            <img src={headerSetting} alt="logout" />
                            <span className="ms-1">{t("settings")}</span>
                          </div>
                          <div
                            className="dropdown-item border-0 p-3"
                            onClick={handleLogout}
                          >
                            <img
                              height={20}
                              className="ms-1"
                              src={logOutImg}
                              alt="logout"
                            />
                            <span className="ms-2">{t("logout")}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid px-lg-5 px-3">
          <div>
            <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-sm-flex d-xs-flex d-flex justify-content-between w-100'>
              <Link to="/dashboard">
                <Logo />
              </Link>
              <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-sm-none d-xs-none d-none ms-4 gap-lg-4 align-items-center'>
                <div className='border-line d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-xs-none d-none'></div>
                <HeaderDropdown screen={screen} />
              </div>
            </div>
          </div>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon d-flex justify-content-end align-items-center">
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse justify-content-end mt-2 mt-md-0" id="navbarTogglerDemo02">
            <div className='d-flex justify-content-md-end justify-content-between w-100'>

              <div className=' d-md-none d-block ms-md-4 gap-lg-4 align-items-center'>
                <div className='border-line d-lg-block d-none'></div>
                <HeaderDropdown />
              </div>

              <div className=" d-flex justify-content-end align-items-center gap-md-3 gap-2">

                <div>
                  {initialLoading ? (
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      width={40}
                      height={40}
                    />
                  ) : (
                    <div className="btn-group dropstart">
                      <img src={translateIcon} width={45} className='cursor-pointer dropdown-image-width' alt="translateIcon" data-bs-toggle="dropdown" />
                      <div className="dropdown-menu dropdown-menu-end profile">

                        <div className='dropdown-item border-0 p-3' onClick={() => handleLanguageSelection("en")}>
                          {currentLanguage === "en" ? (
                            <img className="img-fulid" height={20} width={20} src={tick} alt="currentLang" />
                          ) : <></>}<span className="ms-1" style={{ paddingLeft: currentLanguage === "en" ? 0 : "20px" }}>English</span>
                        </div>
                        <div className='dropdown-item border-0 p-3' onClick={() => handleLanguageSelection("ja")}>
                          {currentLanguage === "ja" ? (
                            <img className="img-fulid" height={20} width={20} src={tick} alt="currentLang" />
                          ) : <></>}<span className="ms-1" style={{ paddingLeft: currentLanguage === "ja" ? 0 : "20px" }}>日本語</span>
                        </div>

                      </div>
                    </div>

                  )}
                </div>

                <div>
                  {screen !== "settings" &&
                    <div className="btn-group dropstart" >

                      <Badge badgeContent={notificationList?.length} color="primary" >
                      </Badge>
                      <>
                        <img src={headerBell} className='cursor-pointer dropdownMenuNotification-width' alt="headerBell" id="dropdownMenuNotification" />
                        <div
                          className="dropdown-menu dropdown-menu-end notification"
                          ref={dropdownRef}
                          onKeyDown={handleKeyDown}
                          tabIndex={-1}
                          style={{ boxShadow: "rgb(0 0 0 / 53%) 0px 0px 10px 0px" }}
                        >
                          <div className='p-3'>
                            <div className='main_title_drop'>{t("notifications")}</div>
                          </div>
                          <div style={{ maxHeight: "195px", overflowY: "auto", overflowX: "hidden" }}>
                            {notificationLoader ? (
                              <div className='px-3'>
                                <ProjectLoader height={90} />
                                <ProjectLoader height={90} />
                              </div>
                            ) : (
                              slicedNotificationList?.length > 0 ? (
                                slicedNotificationList?.map((notification, i) => {
                                  let type = notification?.notification_type
                                  return (
                                    <div
                                      key={i}
                                      className='dropdown-item border-0 p-3 d-flex align-content-center gap-3'
                                      tabIndex={0}
                                      onClick={() => handleNotification(notification)}
                                    >
                                      {notification?.user_image_url ? (
                                        <img src={notification.user_image_url}
                                          height={50}
                                          width={50}
                                          minWidth={50}
                                          className='cursor-pointer bg-secondary'
                                          alt="headerProfile"
                                          data-bs-toggle="dropdown"
                                          style={{ borderRadius: "50%", minWidth: "50px", }}
                                        />
                                      ) : (
                                        <div
                                          className='cursor-pointer bg-secondary d-flex align-items-center justify-content-center'
                                          data-bs-toggle="dropdown"
                                          style={{
                                            height: 50,
                                            width: 50,
                                            borderRadius: "50%",
                                            color: "white",
                                            fontSize: "22px",
                                            minWidth: "50px"
                                          }}
                                        >
                                          {notification?.created_details[0]?.username?.charAt(0).toUpperCase()}
                                        </div>
                                      )}
                                      <div>
                                        {type === "create_project" &&
                                          <div className="profile_tile">{notification?.created_details[0]?.username} assigned you "{getProjectName(notification)}" project.</div>
                                        }
                                        {type === "update_project" &&
                                          <div className="profile_tile">{notification?.created_details[0]?.username} updated the "{getProjectName(notification)}" project.</div>
                                        }
                                        {type === "project_delete" &&
                                          <div className="profile_tile">{notification?.created_details[0]?.username} deleted "{getProjectName(notification)}" project.</div>
                                        }
                                        {type === "comment_mention" &&
                                          <div className="profile_tile">{notification?.created_details[0]?.username} mentioned you in the "{getProjectName(notification)}" project.</div>
                                        }
                                        {type === "design_uploaded" &&
                                          <div>
                                            <div className="profile_tile">{notification?.created_details[0]?.username} has submitted the design for approval.</div>
                                          </div>
                                        }
                                        {type === "share_with_client" &&
                                          <div className="profile_tile">{notification?.created_details[0]?.username} shared the designs of the "{getProjectName(notification)}" project.</div>
                                        }
                                        {type === "sheet_returned" &&
                                          <div className="profile_tile">{notification?.notification_content} </div>
                                        }
                                        {type === "sheet_approved" &&
                                          <div className="profile_tile">{notification?.notification_content} </div>
                                        }
                                        {type === "sheet_revoked" &&
                                          <div className="profile_tile">{notification?.notification_content} </div>
                                        }
                                        {type === "sheet_sent_for_approval" &&
                                          <div className="profile_tile"> {notification?.notification_content}</div>
                                        }

                                        <div className="profile_time"> {formatRelativeTime(notification?.created_timestamp)}</div>
                                      </div>
                                    </div>
                                  )
                                })
                              ) : (
                                <div className='dropdown-item border-0 p-3 d-flex align-content-center gap-3'>
                                  <div className="profile_tile">{t("noNotifications")}</div>
                                </div>
                              )
                            )}
                            {notificationList && notificationList?.length > 0 ?
                              (notificationList?.length > 5 && slicedNotificationList?.length !== notificationList?.length) ? (
                                <div className='d-flex'>
                                  <div
                                    className='link-secondary ps-4 text-decoration-underline cursor_pointer'
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setSlicedNotificationList(notificationList);
                                    }}
                                  >
                                    See more
                                  </div>

                                  <div
                                    className='link-secondary ps-4 text-decoration-underline cursor_pointer'
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      clearAllNotifications()
                                    }}
                                  >
                                    {t("clearAll")}
                                  </div>
                                </div>
                              ) : (
                                notificationList?.length > 5 &&
                                <div className='d-flex'>
                                  <div
                                    className='link-secondary ps-4 text-decoration-underline cursor_pointer'
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setSlicedNotificationList(notificationList?.slice(0, 5))
                                    }}
                                  >
                                    See less
                                  </div>
                                  <div
                                    className='link-secondary ps-4 text-decoration-underline cursor_pointer'
                                    onClick={(event) => {
                                      event.stopPropagation();

                                    }}
                                  >
                                    {t("clearAll")}
                                  </div>
                                </div>
                              ) : (<></>)
                            }
                          </div>
                        </div>
                      </>
                    </div>
                  }
                </div>


                <div>
                  <div className="btn-group dropstart">
                    {userSettings?.user_image_url ? (
                      <img src={userSettings.user_image_url} height={30} width={30}
                        className='cursor-pointer bg-secondary'
                        alt="headerProfile"
                        data-bs-toggle="dropdown"
                        style={{ borderRadius: "50%" }}
                      />
                    ) : (
                      <div style={{ marginLeft: "18px" }}
                        className='cursor-pointer bg-secondary d-flex align-items-center justify-content-center profile-manu'
                        data-bs-toggle="dropdown"

                      >
                        <span>
                          {userSettings?.username?.charAt(0).toUpperCase()}
                        </span>
                      </div>
                    )}
                    <div className="dropdown-menu dropdown-menu-end profile">
                      <div className='dropdown-item border-0 p-3' onClick={() => { handleNavigation('/settings') }}>
                        <img src={headerSetting} alt="logout" /><span className="ms-1">{t("settings")}</span>
                      </div>
                      <div className='dropdown-item border-0 p-3' onClick={handleLogout}>
                        <img height={20} className='ms-1' src={logOutImg} alt="logout" /><span className="ms-2">{t("logout")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav> */}
    </>
  );
}
