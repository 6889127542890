import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ImageDetail from "../../components/ImageDetail/ImageDetail";
import { ReactComponent as BackIcon } from "../../assets/newicons/btn-back.svg";
import { useFilterContext } from "../../context/useFilterContext";
import { t } from "i18next";
import "./ImageDetailPage.scss";

const ImageDetailPage = () => {
  const { imageId } = useParams();
  const navigate = useNavigate();
  const [imageDetails, setImageDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Only initialize state once when component mounts
    setLoading(true);
    setError(null);

    // Return a cleanup function that will run when the component unmounts
    return () => {
      // Clean up any subscriptions or pending requests here
    };
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="standalone-detail-page">
      {loading && !imageDetails && !error && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>{t("Loading image details...")}</p>
        </div>
      )}

      {error && (
        <div className="error-container">
          <p>
            {t("Error loading image")}: {error}
          </p>
          <button onClick={handleGoBack}>{t("Go Back")}</button>
        </div>
      )}

      {/* {imageId && ( */}
      <ImageDetail
        imageId={imageId}
        showDetails={imageDetails}
        setShowDetails={(details) => {
          setImageDetails(details);
          setLoading(false);
        }}
        isStandalone={true}
      />
      {/* )} */}
    </div>
  );
};

export default ImageDetailPage;
