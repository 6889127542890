import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { post } from "../../../API/axios";
import Search1 from "../../../assets/newicons/search1.svg";
import { useAuthContext } from "../../../context/useAuthContext";
import { useSearchContext } from "../../../context/useSearchContext";
import "../../Manager/reference.scss";
import ImageListLoader from "../../Manager/skeletonLoaders/ImageListLoader";
import { GenerateWithAI } from "./GenerateWithAI";
import Close from "../../../assets/newicons/close.svg";
import aiIcon from "../../../assets/newicons/ai-white-icon.svg";
import arrow from "../../../assets/newicons/arrow-left-dark.svg";
import chooseImage from "../../../assets/newimages/choose-small-image.png";
import noImage from "../../../assets/newimages/tabs-image.png";
import heartIcon from "../../../assets/newicons/heart-icon.svg";
import { layoutData } from "../../templates/layoutData";
import { showAlert } from "../../Manager/component/AlertService";
const AddImagePopUp = ({
  prevSelectedImages,
  createdProjectId,
  channelId,
  referenceList,
  anotationData,
  typeChange,
}) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const [tab, setTab] = useState(1);
  const [isImageListLoading, setIsImageListLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState(
    prevSelectedImages || []
  );
  const [offSetId, setOffSetId] = useState(null);
  const currentLanguage = i18n.language;
  const [resetData, setResetData] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const { setResults, setNumberOfResults, setShowResults } = useSearchContext();

  const { selectedSite } = useAuthContext();

  const [searchTag, setSearchTag] = useState([
    {
      tag: "search-tag-1",
      id: 1,
    },
    {
      tag: "search-tag-2",
      id: 2,
    },
    {
      tag: "search-tag-3",
      id: 3,
    },
  ]);

  const [formData, setFormData] = useState({
    input_text: "",
    page: 1,
    ctr: 0,
    tags: [],
    model_filters: [],
    is_edited: "all",
    search_by: "desc",
  });

  const handleSelectedImage = (image) => {
    setSelectedImages((prevImages) => {
      const isSelected = prevImages?.some(
        (selectedImage) => selectedImage.image_id === image.image_id
      );
      if (isSelected) {
        return prevImages.filter(
          (selectedImage) => selectedImage.image_id !== image.image_id
        );
      } else {
        return [...prevImages, image];
      }
    });
  };

  const handleClose = () => {
    setTab(1);
  };

  const handleSelectReference = () => {
    navigate("/reference-details?project_id=" + createdProjectId, {
      state: {
        selectedImages: selectedImages,
        createdProjectId: createdProjectId,
        channelId: channelId,
      },
    });
  };

  const handleSearchInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const resetSearch = () => {
    setNumberOfResults({
      primary: 0,
      similar: 0,
    });
    setFormData({ ...formData, page: 1 });
  };

  const getSearchText = async (isNextPage = false) => {
    // setLoading(true);
    // setIsImageListLoading(true)

    let payload;
    if (!isNextPage) {
      resetSearch();
      setIsImageListLoading(true);
    }

    if (formData?.input_text) {
      payload = {
        input_text: formData.input_text,
        lang: currentLanguage, //en or ja
        page: isNextPage ? formData.page + 1 : 1,
        ctr: Number(formData.ctr),
      };

      if (formData?.input_text || formData?.ctr || formData.tags.length !== 0) {
        payload = {
          input_text: formData.input_text,
          lang: currentLanguage, //en or ja
          page: isNextPage ? formData.page + 1 : 1,
          ctr: Number(formData.ctr),
          is_edited: formData.is_edited,
        };
        if (formData.tags.length > 0) {
          payload.filter_tags = formData.tags;
        }
      }
    } else {
      payload = {
        input_text: formData.input_text,
        lang: currentLanguage, //en or ja
        page: isNextPage ? formData.page + 1 : 1,
        ctr: Number(formData.ctr),
        is_edited: formData.is_edited,
      };
    }

    if (isNextPage) {
      // setScrollLoader(true);
      // setIsImageListLoading(true)
      setFormData({ ...formData, page: formData.page + 1 });
    }
    payload.site_id =
      typeof selectedSite?.site_id == "object"
        ? selectedSite?.site_id
        : [selectedSite?.site_id];
    const { tags, ctr, input_text, lang, page, site_id, is_edited, ...rest } =
      formData;

    payload = {
      ...payload,
      ...rest,
    };

    if (
      !payload?.input_text &&
      payload?.ctr <= 0 &&
      !payload?.filter_tags?.length &&
      !payload?.delivery_content?.length &&
      !payload?.delivery_type?.length &&
      !payload?.image_type?.length &&
      !payload?.industry?.length &&
      !payload?.model_filters?.length &&
      !payload?.is_edited
    ) {
      showAlert(t("oneFilterAtleast"), "warning");
      // setLoading(false);
      return;
    }

    setShowResults(true);
    setResetData(formData);
    await post(
      `text_search_image/`,
      offSetId && isNextPage ? { offset_id: offSetId, ...payload } : payload
    )
      .then((response) => {
        setNumberOfResults({
          total_images: response?.pagination?.total_images_in_page,
          primary: response?.pagination?.total_images,
          similar: response?.pagination?.total_images_in_current_page,
        });
        setOffSetId(response?.pagination?.offset_id);
        if (!isNextPage) {
          setSearchResults(response?.data);
          setResults(response?.data);
        } else {
          if (response?.data?.length > 0) {
            setSearchResults((prevResults) => [
              ...prevResults,
              ...response?.data,
            ]);
            setResults((prevResults) => [...prevResults, ...response?.data]);
          }
        }
        setTimeout(() => {
          // setLoading(false);
          // setScrollLoader(false);
          setIsImageListLoading(false);
        }, 1000);
      })
      .catch((error) => {
        // setLoading(false);
        setShowResults(true);
        // setScrollLoader(false);
        setIsImageListLoading(false);
        console.error("error", error);
      })
      .finally(() => {});
  };

  // const [sentryRef] = useInfiniteScroll({
  //     loading: scrollLoader,
  //     hasNextPage: numberOfResults.total_images == numberOfResults.primary ? false :
  //         true || searchResults?.length === 0 ? true : false,
  //     onLoadMore: () => {
  //         if (formData.input_text) {
  //             getSearchText(true)
  //         }
  //     },
  //     disabled: false,
  //     delayInMs: 2000,
  //     rootMargin: "0px 0px 400px 0px",
  // });

  const handleInputSearchText = (e) => {
    e.preventDefault();
    getSearchText();
  };

  useEffect(() => {
    if (formData.input_text) {
      getSearchText();
    }
  }, []);

  return (
    <div>
      <div
        className="modal fade"
        id="addImage"
        aria-hidden="true"
        aria-labelledby="addImageLabel"
        tabIndex="-1"
      >
        <div className="modal-dialog modal_lg modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content mx-4 mb-0">
            <div
              type="button"
              data-bs-dismiss="modal"
              className="d-flex justify-content-end"
            >
              <img
                src={Close}
                alt="Close"
                className="close-popup-button"
                onClick={handleClose}
              />
            </div>
            <div className="modal-body d-flex flex-lg-row flex-column p-0 overflow-hidden">
              <div
                className={`p-4 refrenece-image-width overflow-auto ${selectedImages?.length === 1 ? " " : "w-100"} `}
                style={{ maxHeight: "100%" }}
              >
                <div className="Add_reference_text font-noto-sans">
                  {typeChange === "image" ? "Add image" : "Add illustration"}
                </div>
                <div className="d-flex align-items-center gap-3 mt-3">
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                  />

                  <button
                    className="d-flex align-items-center justify-content-center gap-2 py-md-3 py-2 w-100 border-0 black-gradiant-btn"
                    data-bs-target="#exampleModalToggle2"
                    data-bs-toggle="modal"
                    style={{ height: "auto" }}
                  >
                    <img src={aiIcon} alt="aiIcon" />
                    <span>Generate with MicoAI</span>
                  </button>

                  {(typeChange === "image" || typeChange === "text") && (
                    <button
                      className="text-center w-100 border-0 fw-600 py-md-3 py-2 add-fs-16"
                      style={{ borderRadius: "8px", maxWidth: "360px" }}
                    >
                      Upload
                    </button>
                  )}
                </div>
                {/* <div className="modal fade" id="generateWithAI" tabindex="-1" aria-labelledby="generateWithAIModalLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <GenerateWithAI />
                                        </div>
                                    </div>
                                </div> */}
                <div className="d-flex align-items-center gap-4 mt-2 mt-sm-0">
                  <div
                    className={`font-noto-sans add_navbar add-fs-16 ${tab === 1 && "active"} cursor_pointer`}
                    onClick={() => setTab(1)}
                  >
                    Library
                  </div>
                  <div
                    className={`font-noto-sans add_navbar add-fs-16 ${tab === 2 && "active"} cursor_pointer`}
                    onClick={() => setTab(2)}
                  >
                    My files
                  </div>
                </div>

                {tab === 1 && (
                  <div>
                    <form onSubmit={handleInputSearchText}>
                      <div className="position-relative w-90 mt-4 mb-4">
                        <div className="position-relative big-search-input">
                          <input
                            className="form-control rounded-pill ps-4"
                            name="input_text"
                            value={formData.input_text}
                            onChange={handleSearchInput}
                            placeholder={t("searchPastVision")}
                            aria-label="Search"
                          />
                          <img src={Search1} className="" alt="search" />
                        </div>
                      </div>
                    </form>
                    {/* <div className='font-noto-sans add_body_recent'>
                                            {t("recentSearces")}
                                        </div> */}

                    {/* <div className='d-flex flex-wrap  gap-2'>
                                            {searchTag.length > 0 &&
                                                searchTag.map((tag, i) => (
                                                    <div key={i} className='d-flex gap-1 align-items-center search_iteam'>
                                                        <div className='search_iteam_name font-noto-sans'>
                                                            {tag.tag}
                                                        </div>
                                                        <div className='search_iteam_name cursor_pointer' onClick={() => handleDeleteTag(tag.id)}>
                                                            <img src={Close} alt="Close" width={15} />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div> */}

                    <div className="d-flex flex-wrap justify-content-start gap-3">
                      {isImageListLoading ? (
                        <>
                          {[...Array(30)].map((_, i) => (
                            <ImageListLoader key={i} />
                          ))}
                        </>
                      ) : layoutData &&
                        layoutData.layouts[0].elements.length > 0 ? (
                        layoutData.layouts[0].elements.map((image, i) => (
                          <label
                            key={i}
                            className={`position-relative image-container image-container-ref mb-0 cursor-pointer ${selectedImages?.some((selectedImage) => selectedImage.image_id === image.image_id) ? "selected" : ""}`}
                            id="abc"
                          >
                            <img
                              loading="lazy"
                              // src={image.image_url}
                              src={image?.properties?.src || noImage}
                              width={160}
                              height={160}
                              className="rounded-3"
                              alt="no-img"
                            />
                            <input
                              type="checkbox"
                              className="image-checkbox"
                              htmlFor="abc"
                              checked={layoutData?.layouts[0]?.elements?.some(
                                (selectedImage) =>
                                  selectedImage.image_id === image.image_id
                              )}
                              onChange={() => handleSelectedImage(image)}
                            />
                          </label>
                        ))
                      ) : (
                        <p>{t("noRecentImagesFound")}</p>
                      )}
                      {/* <div ref={sentryRef} /> */}
                    </div>
                  </div>
                )}

                {tab === 2 && (
                  <div>
                    <div className="my-5">
                      {/* <UploadDesignFile setTab={setTab} /> */}
                      Work in Progress
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`${selectedImages?.length === 1 && tab === 1 ? "d-block" : "d-none"} p-4 refrenece-image-width pt-5 overflow-auto`}
                style={{ backgroundColor: "#F6F6F6" }}
              >
                <div className=" d-flex flex-column align-items-center">
                  <div style={{ maxWidth: "560px" }}>
                    <div className="d-flex justify-content-center">
                      <img
                        src={selectedImages ? selectedImages[0]?.image_url : ""}
                        alt="noFramImage"
                        className="img-fluid reference-image"
                      />
                    </div>
                    <div className="">
                      <div className="d-flex justify-content-between align-content-center">
                        <button className="d-flex gap-1 align-items-center search_iteam mt-3">
                          <div className="search_iteam_name">{t("ctr")}:</div>
                          <div className="search_iteam_name">
                            {selectedImages[0]?.ctr?.length &&
                            selectedImages[0]?.ctr[0] !== null &&
                            selectedImages[0]?.ctr[1] !== null ? (
                              <div className="search_iteam_name">
                                {selectedImages[0]?.ctr[0].toFixed(3) ===
                                selectedImages[0]?.ctr[1].toFixed(3)
                                  ? `${(selectedImages[0].ctr[0] * 100).toFixed(2)}%`
                                  : selectedImages[0].ctr[0].toFixed(2) ===
                                      selectedImages[0].ctr[1].toFixed(2)
                                    ? `${(selectedImages[0].ctr[0] * 100).toFixed(2)}%`
                                    : `${(selectedImages[0].ctr[0] * 100).toFixed(2)}% ~ ${(
                                        selectedImages[0].ctr[1] * 100
                                      ).toFixed(2)}%`}
                              </div>
                            ) : (
                              <div className="search_iteam_name">N/A</div>
                            )}
                          </div>
                        </button>
                        <div className="img_des mt-3">
                          <span>{selectedImages[0]?.image_size}</span>{" "}
                          <span>232kb</span>
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="reference_name">
                          {selectedImages[0]?.image_url?.split("/").pop()}
                        </div>

                        <div className="img_des my-3">
                          <span className="fw-600">{t("description")}: </span>
                          {currentLanguage === "ja"
                            ? selectedImages[0]?.image_description_ja
                            : selectedImages[0]?.image_description}
                        </div>
                        {/* <div className='img_des'>Resolution: <span>1350 x 1350 px</span> </div>
                                            <div className='img_des'>File size: <span>232kb</span></div> */}
                      </div>
                      {/* <div className='my-4'>
                                            <div className="d-flex flex-wrap gap-1">
                                                {imageTags.length > 0 &&
                                                    imageTags.map((tag, i) => (
                                                        <div key={i} className='search_iteam'>
                                                            <div className='search_iteam_name'>{tag.tag}</div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div> */}
                      {/* <hr />
                                        <div>
                                            <div>
                                                <button className="referencebtn" data-bs-target="#exampleModalToggle2" data-bs-dismiss="modal" onClick={handleSelectReference}> select as reference </button>
                                            </div>
                                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`${selectedImages?.length > 0 && tab === 1 ? "d-block" : "d-none"} modal-footer w-100`}
            >
              <div className="d-flex justify-content-between w-100">
                <div className="d-flex gap-1 align-items-center ">
                  <div className="fs-14 fw-bold font-noto-sans">
                    <span className="me-1">{selectedImages?.length}</span>
                    {t("selected")}
                  </div>
                  <div className=" cursor_pointer">
                    <img src={Close} alt="Close" width={15} />
                  </div>
                </div>
                <div className="d-flex justify-content-end ">
                  <button
                    type="button"
                    className="border-0 bg-transparent text-black fs-14 me-3 fw-bold font-noto-sans"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    {t("cancel")}
                  </button>
                  <button
                    type="button"
                    className="btn rounded-pill py-2 px-4 fs-14 font-noto-sans fw-bold"
                    data-bs-dismiss="modal"
                    style={{ backgroundColor: "black" }}
                    onClick={handleSelectReference}
                  >
                    {t("select")}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Generate with AI popup */}

          <div
            className="modal fade"
            id="exampleModalToggle2"
            aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel2"
            tabindex="-1"
          >
            <div
              className="modal-dialog modal-dialog-centered"
              style={{ maxWidth: "100%" }}
            >
              <div
                className="modal-content mx-sm-4"
                style={{ height: "calc(100vh - 3.5rem)" }}
              >
                <GenerateWithAI />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddImagePopUp;
