import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const CountdownPopup = () => {
  const [countdown, setCountdown] = useState(3);
  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      navigate("/dashboard");
    }
  }, [countdown, navigate]);

  return (
    <div className="modal show d-block" tabIndex="-1">
      <div className="overlay popup-overlay">&nbsp;</div>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content text-center">
          <div
            className="modal-header text-black"
            style={{
              background: "#e6e6e6",
            }}
          >
            <h5 className="modal-title">{t("Redirecting")}</h5>
          </div>
          <div className="modal-body">
            <h3 className="display-4">{countdown}</h3>
            <p>{t("INVALID_LINK")}</p>
          </div>
          <div className="modal-footer">
            <button
              className="btn text-black"
              onClick={() => navigate("/dashboard")}
              style={{
                background: "#e6e6e6",
              }}
            >
              {t("goNow")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountdownPopup;
