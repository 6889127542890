import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  useLocation,
  useNavigate,
  useBeforeUnload,
} from "react-router-dom";
import {
  newDelete,
  newGet,
  newGet_V3,
  newGet_V4,
  newPost,
  newPost_V3,
  newPost_V4,
} from "../../API/axios";
import { ReactComponent as AddIcon } from "../../assets/icons/addIcon.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/project-search.svg";
import { useAuthContext } from "../../context/useAuthContext";
import { useSearchContext } from "../../context/useSearchContext";
import Search from "../Search";
import { showAlert, showConfirmationDialog } from "./component/AlertService";
import CreateProjectForm from "./component/createProjectForm";
import Header from "./component/header";
import "./manager.scss";
import CreateProjectFormLoader from "./skeletonLoaders/CreateProjectFormLoader";
import { ReactComponent as ProjectIcon } from "../../assets/images/project-icon.svg";
import visionImg from "../../assets/images/visionposter.png";
import { ReactComponent as ArrowForward } from "../../assets/icons/arrow_forward.svg";
import { Skeleton } from "@mui/material";

const useDebouncedValue = (inputValue, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);

  return debouncedValue;
};

const Manager = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [projectList, setProjectList] = useState([]);
  const [filteredProject, setFilteredProject] = useState([]);
  const [userList, setUserList] = useState([]);
  const [clientUserList, setClientUserList] = useState([]);
  const [clientManager, setClientManager] = useState([]);
  const allUsers = [...userList, ...clientUserList];
  const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);
  const [isCreateDisable, setIsCreateDisable] = useState(false);
  const [checked, setChecked] = useState(false);
  const [inputProjectName, setInputProjectName] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [channelname, setChannelName] = useState("");
  const [showComponent, setShowComponent] = useState(false);
  const [searchIcon, setSearchIcon] = useState(false);
  const [formattedData, setFormattedData] = useState([]);
  const debouncedProjectName = useDebouncedValue(inputProjectName, 300);
  const { saveSelectedSite } = useAuthContext();
  const [isAllUsers, setIsAllUsers] = useState(false);
  const [fetchedSites, setFetchedSites] = useState({});

  useEffect(() => {
    if (projectFormValue.project_name.length >= 1) {
      checkProjectName(inputProjectName);
    }
  }, [debouncedProjectName]);

  const [searchView, setSearchView] = useState(false);
  const { t, i18n } = useTranslation();

  const {
    initialLoading,
    isUserSettingsLoading,
    hasPermission,
    userSettings,
    selectedSite,
  } = useAuthContext();

  const { formValue, setFormValue, setShowResults } = useSearchContext();

  const [isAll, setIsAll] = useState(false);

  const [projectFormValue, setProjectFormValue] = useState({
    project_name: "",
    project_description: "",
    channel: "",
    assign_to: [],
    internal_approver: [],
    client_approver: [],
    // watchlist: false,
    watch_list: [],
    // start_date: '',
    // due_date: '',
    required_sheet: "",
    type_id: "",
    // project_type : ""
  });

  const [errors, setErrors] = useState({
    project_name: "",
    project_description: "",
    channel: "",
    assign_to: "",
    internal_approver: "",
    client_approver: "",
    watch_list: "",
    // start_date: '',
    // due_date: '',
    required_sheet: "",
    type_id: "",
    // project_type : ""
  });

  useEffect(() => {
    localStorage.removeItem("searchResults");
  }, []);

  const toHalfWidth = (str) => {
    return str
      .replace(/[Ａ-Ｚａ-ｚ０-９！-～]/g, (char) =>
        String.fromCharCode(char.charCodeAt(0) - 0xfee0)
      )
      .replace(/　/g, " "); // Convert full-width spaces to half-width
  };

  const checkProjectName = async (value) => {
    const normalizedValue = toHalfWidth(value).trim(); // Convert to half-width and trim
    // if (normalizedValue.length < 3) {
    //   setErrors({
    //     ...errors,
    //     project_name: "It required at least 3 characters",
    //   });
    //   return;
    // }
    // Check if the first character is not a single-byte special character
    const firstCharSingleByteSpecialPattern =
      /^[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]/; // Range of single-byte special characters
    const firstCharIsAlphanumericOrDoubleByte =
      /^[a-zA-Z0-9\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]/;

    if (firstCharSingleByteSpecialPattern.test(normalizedValue.charAt(0))) {
      setErrors({
        ...errors,
        project_name:
          t("firstCharacterCannotBeSingleByte"),
      });
      setIsCreateDisable(true);
      return;
    }

    if (!firstCharIsAlphanumericOrDoubleByte.test(normalizedValue.charAt(0))) {
      setErrors({
        ...errors,
        project_name:
          t("firstCharacterMustBeAlphanumeric"),
      });
      setIsCreateDisable(true);
      return;
    }

    // Check if the % character is present anywhere in the string
    if (normalizedValue.includes("%")) {
      setErrors({
        ...errors,
        project_name: t("%CharacterNotAllowed"),
      });
      setIsCreateDisable(true);
      return;
    }

    // Allow double-byte and special characters except for %
    const validCharactersPattern =
      /^[a-zA-Z0-9\s\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF!@\$^\&\*\(\)\-_+=\[\]\{\};:'"<>,.?\/\\|`~]*$/;
    // const testValue = validCharactersPattern.test(value);

    if (!validCharactersPattern.test(normalizedValue)) {
      setErrors({ ...errors, project_name: t("invalidCharactersDetected") });
      setIsCreateDisable(true);
      return;
    }

    try {
      const response = await newGet_V4(
        `project/checkProjectName?project_name=${normalizedValue}&site_id=${projectFormValue?.channel?.site_id}`
      );
      if (response?.status_code === 6004 || response?.status_code === 6021) {
        setErrors({ ...errors, project_name: t("projectNameAlreadyExist") });
        setIsCreateDisable(true);
      } else if (response?.status_code === 2005) {
        setErrors({ ...errors, project_name: t("projectNameValid") });
        setIsCreateDisable(false);
      }
    } catch (error) {
      console.error("Error checking project name:", error);
      setErrors({
        ...errors,
        project_name: t("anErrorOccurredPleaseTryAgain"),
      });
      setIsCreateDisable(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const normalizedValue = toHalfWidth(value); // Convert to half-width and trim

    // Clear the error for the current field
    setErrors({ ...errors, [name]: "" });

    if (name === "required_sheet") {
      // Allow only digits and no strings
      if (/^\d*$/.test(normalizedValue.trim())) {
        setProjectFormValue({
          ...projectFormValue,
          [name]: normalizedValue.trim(),
        });

        // Check after the user has entered a value (when it's a valid number)
        const numericValue = parseInt(normalizedValue.trim(), 10);
        if (numericValue === 0) {
          setErrors({ ...errors, [name]: "Value cannot be 0!" });
        } else if (numericValue > 50) {
          setErrors({ ...errors, [name]: "Maximum sheet allowed is 50!" });
        }
      }
    } else {
      // Handle other input fields normally
      setProjectFormValue({ ...projectFormValue, [name]: normalizedValue });
    }
  };

  const isValidYear = (date) => {
    const currentYear = new Date().getFullYear();
    const year = date.getFullYear();
    return year >= currentYear && year <= 2026; // Adjust the range as needed
  };

  const handleDateChange = (date, name) => {
    // if (isValidYear(date)) {
    const formattedDate = date ? format(date, "MM-dd-yyyy") : null;
    setProjectFormValue({ ...projectFormValue, [name]: formattedDate });
    setErrors({ ...errors, [name]: "" });
    // }
  };

  const handleChannelChange = (site) => {
    fetchChannelUsers(site);
    fetchClientUsers(site);
    setProjectFormValue({
      ...projectFormValue,
      channel: site,
      project_name: "",
      assign_to: [],
      internal_approver: [],
      client_approver: [],
      watch_list: [],
    });
    setErrors({ ...errors, channel: "", project_name: "" });

    // const siteId = site?.site_id;

    // if (fetchedSites[siteId]) {
    //     // Use cached data
    //     setUserList(fetchedSites[siteId].userList);
    //     setClientUserList(fetchedSites[siteId].clientUserList);
    //     setClientManager(fetchedSites[siteId].clientManager);
    // } else {
    //     // Fetch data and update cache
    //     fetchChannelUsers(site).then((userList) => {
    //         fetchClientUsers(site).then((clientUserList) => {
    //             const clientManager = clientUserList.filter((user) => user.role_name === "CLIENT_MANAGER");
    //             setFetchedSites((prev) => ({
    //                 ...prev,
    //                 [siteId]: { userList, clientUserList, clientManager }
    //             }));
    //             setUserList(userList);
    //             setClientUserList(clientUserList);
    //             setClientManager(clientManager);
    //         });
    //     });
    // }

    // setProjectFormValue({ ...projectFormValue, channel: site, project_name: "", assign_to: [], internal_approver: [], client_approver: [], watch_list: [] });
    // setErrors({ ...errors, channel: "", project_name: "" });
  };

  const handlemultipleUsers = (e, options, fieldName) => {
    let newFormValue = { ...projectFormValue, [fieldName]: options };
    let newErrors = { ...errors, [fieldName]: "" };

    if (
      ["assign_to", "internal_approver", "client_approver"].includes(fieldName)
    ) {
      const combinedWatchList = [
        ...new Set([
          ...newFormValue.assign_to,
          ...newFormValue.internal_approver,
          ...newFormValue.client_approver,
        ]),
      ];

      const watchListUser = projectFormValue?.watch_list?.filter((user) =>
        combinedWatchList?.some((combined) => combined.user_id === user.user_id)
      );

      newFormValue = { ...newFormValue, watch_list: watchListUser };
    }
    setProjectFormValue(newFormValue);
    setErrors(newErrors);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const fetchUsers = async () => {
    // fetchChannelUsers();
    // fetchClientUsers();
    setIsAllUsers(false);
  };

  const fetchChannelUsers = async (site) => {
    // if (userList.length === 0) {
    try {
      // setLoading(true);
      const [channelData, designerData] = await Promise.all([
        newGet(`project/usersByRole?role_name=channel&site_id=${site?.site_id}`),
        newGet(`project/usersByRole?role_name=designer&site_id=${site?.site_id}`)
      ]);
  
       // Check if both requests were successful
    if ((channelData?.status_code === 200 || channelData?.status_code === 201) &&
    (designerData?.status_code === 200 || designerData?.status_code === 201)) {
       // Merge both results into a single list
       const mergedUsers = [...(channelData?.result || []), ...(designerData?.result || [])];
        setUserList(mergedUsers || []);
        setLoading(false);
        setIsAllUsers(true);
      }
    } catch (error) {
      console.log("error:???????", error);
      setLoading(false);
      // return [];
    }
    // }
  };

  const fetchClientUsers = async (site) => {
    // if (clientUserList.length === 0) {
    try {
      // setLoading(true);
      const data = await newGet(
        `project/usersByRole?role_name=client&site_id=${site?.site_id}`
      );
      setClientUserList(data?.result || []);
      setClientManager(
        data?.result?.filter((user) => user.role_name === "CLIENT_MANAGER")
      );
      setLoading(false);
      setIsAllUsers(true);
      // setIsAllUsers(true);
      // return data?.result || [];
    } catch (error) {
      console.log("error:???????", error);
      setLoading(false);
      // return [];
    }
    // }
  };

  const fetchProjects = async () => {
    try {
      setLoading(true);
      const data = await newGet_V3(`project`);
      setProjectList(data?.result);
      // console.group(data?.result)
      setFilteredProject(
        data?.result?.filter((role) => role?.user_role !== "Observer")
      );
      setLoading(false);
      setIsAll(false);
    } catch (error) {
      console.log("error:???????", error);
      setLoading(false);
    }
  };

  // const handleDelete = async (e, id) => {
  //     e.stopPropagation()
  //     const confirmation = await showConfirmationDialog(
  //         `${t("projectDeleteMsg")}`,
  //         "",
  //         "warning",
  //         `${t("no")}`,
  //         `${t("yes")}`
  //     );
  //     try {
  //         if (confirmation) {
  //             setLoading(true);
  //             const data = await newDelete(`project?project_id=${id}`);
  //             if (data.status_code == 200) {
  //                 showAlert(i18n.t(data?.message[0]?.property_message), 'success');
  //                 fetchProjects()
  //                 setLoading(false);
  //             }
  //         }
  //     } catch (error) {
  //         console.log("error:???????", error);
  //         setLoading(false);
  //     }
  // }

  const makeSiteObject = (str) => {
    if (!str) {
      return null;
    }
    const jsonString = str?.replace(/(\w+):([^,}]+)/g, (match, key, value) => {
      // If the value is a number, keep it as is; otherwise, wrap it in quotes
      const formattedValue = isNaN(value) ? `"${value.trim()}"` : value.trim();
      return `"${key}":${formattedValue}`;
    });

    return JSON.parse(jsonString).site_id;
  };

  const getSiteName = (str) => {
    if (!str) {
      return null;
    }
    const jsonString = str?.replace(/(\w+):([^,}]+)/g, (match, key, value) => {
      // If the value is a number, keep it as is; otherwise, wrap it in quotes
      const formattedValue = isNaN(value) ? `"${value.trim()}"` : value.trim();
      return `"${key}":${formattedValue}`;
    });

    return JSON.parse(jsonString).site_name;
  };
  // const modeChange = (value, roleName) => {
  //     setIsAll(value);
  //     if (roleName === 'Observer') {
  //         const filteredList = projectList?.filter((role) => role?.user_role !== roleName)
  //         setFilteredProject(filteredList)
  //     } else {
  //         setFilteredProject(projectList)
  //     }
  // }

  const handleUpdateProject = (projectID, channel, sharedWithClient, userRole) => {
    if (
      userSettings?.permissions &&
      userSettings?.permissions?.hasOwnProperty(18)
    ) {
      if (userSettings?.role_id === 6) {
        if (sharedWithClient === 1) {
          navigate(
            `/reference?project_id=${projectID}&site_id=${makeSiteObject(
              channel
            )}`,
            {
              state: {
                siteId: makeSiteObject(channel),
                createdProjectId: projectID,
                userRole: userRole,
              },
            }
          );
        } else {
          showAlert(
            "This Project is still in Process. You can see it after it is ready for the approval",
            "warning"
          );
        }
      } else if (userSettings?.role_id !== 6) {
        navigate(
          `/reference?project_id=${projectID}&site_id=${makeSiteObject(
            channel
          )}`,
          {
            state: {
              siteId: makeSiteObject(channel),
              createdProjectId: projectID,
              userRole: userRole,
            },
          }
        );
      }
    }
  };

  const formatDate = (date1) => {
    // let date = new Date(date1);
    // let day = String(date.getDate()).padStart(2, '0');
    // let month = String(date.getMonth() + 1).padStart(2, '0');
    // let year = date.getFullYear()
    // let formattedDate = `${month}-${day}-${year}`;
    // return formattedDate
    const [year, month, day] = date1.split("-");
    return `${month}-${day}-${year}`;
  };

  const handleSubmit = async () => {
    const requiredFields = [
      { key: "project_name", message: t("projectNameRequired") },
      { key: "project_description", message: t("projectDescriptionRequired") },
      { key: "channel", message: t("channelRequired") },
      { key: "required_sheet", message: t("noOfSheetRequired") },
      // { key: 'assign_to', message: t("assignToRequired"), isArray: true },
      // { key: 'project_type', message: 'Project type is required.'},
      {
        key: "internal_approver",
        message: t("internalApproverRequired"),
        isArray: true,
      },
      { key: "client_approver", message: t("clientRequired"), isArray: true },
      { key: "type_id", message: t("projectTypeRequired") },
    ];
    let formIsValid = true;
    const newErrors = {};

    // Validate required fields
    requiredFields.forEach((field) => {
      if (
        field.isArray
          ? projectFormValue[field.key]?.length === 0
          : !projectFormValue[field.key]
      ) {
        if (field.key === "client_approver") {
          if (
            projectFormValue?.type_id === 2 &&
            (!projectFormValue[field.key] ||
              projectFormValue[field.key]?.length === 0)
          ) {
            newErrors[field.key] = field.message;
            formIsValid = false;
          }
        } else if (field.key === "internal_approver") {
          if (
            projectFormValue?.type_id === 1 &&
            (!projectFormValue[field.key] ||
              projectFormValue[field.key]?.length === 0)
          ) {
            newErrors[field.key] = field.message;
            formIsValid = false;
          }
        } else if (field.key === "type_id" && !projectFormValue[field.key]) {
          newErrors[field.key] = t("projectTypeRequired");
          formIsValid = false;
        } else {
          newErrors[field.key] = field.message;
          formIsValid = false;
        }
      } else if (
        field.key === "required_sheet" &&
        (!projectFormValue[field.key] || projectFormValue[field.key] === 0)
      ) {
        newErrors[field.key] =
          projectFormValue[field.key] === 0
            ? "Required sheet cannot be zero."
            : field.message;
        formIsValid = false;
      } else {
        // if (field.key === "project_name") {
        //   if (projectFormValue[field.key].length < 3) {
        //     newErrors[field.key] = "It required at least 3 characters";
        //     formIsValid = false;
        //     return;
        //   }
        // }
        //ewErrors[field.key] = ""; // Clear errors if field is valid
      }
    });

    // Display validation errors
    setErrors(newErrors);

    if (!formIsValid) {
      return; // Stop submission if form is invalid
    }

    // Prepare form data for submission
    let approver_id = "";
    if (projectFormValue?.type_id === 1) {
      approver_id = projectFormValue?.internal_approver
        ?.map((user) => user?.account_login_id)
        .join(",");
    } else if (projectFormValue?.type_id === 2) {
      approver_id = projectFormValue?.client_approver
        ?.map((user) => user?.account_login_id)
        .join(",");
    }

    const formData = {
      project_name: projectFormValue?.project_name,
      project_desc: projectFormValue?.project_description,
      site_id: projectFormValue?.channel?.site_id,
      assign_to_user_id: projectFormValue?.assign_to
        ?.map((user) => user?.account_login_id)
        .join(","),
      approver_id,
      watchlist: projectFormValue?.watch_list
        ?.map((user) => user?.account_login_id)
        .join(","),
      required_sheet: parseInt(projectFormValue?.required_sheet, 10),
      type_id: projectFormValue?.type_id,
      project_type: 1,
    };

    try {
      setLoading(true);
      const data = await newPost_V4(
        `project/createProjectWithBoardDesign`,
        formData
      );
      setLoading(false);
      if (data.status_code === 2001) {
        showAlert(i18n.t(data?.message[0]?.property_message), "success");
        closeModal();

        saveSelectedSite(
          projectFormValue?.channel?.site_id,
          projectFormValue?.channel
        );
        navigate(
          `/reference?project_id=${data?.result?.project_id}&site_id=${projectFormValue?.channel?.site_id}`,
          {
            state: {
              siteId: projectFormValue?.channel?.site_id,
              createdProjectId: data?.result?.project_id,
            },
          }
        );
        fetchProjects();
      }
    } catch (error) {
      console.error("Submission error:", error);
      setLoading(false);
    }
  };

  const closeModal = () => {
    const modalElement = document.getElementById("exampleModal");
    const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
    modalInstance.hide();
  };

  const handleClearForm = () => {
    setProjectFormValue({
      project_name: "",
      project_description: "",
      channel: "",
      required_sheet: "",
      assign_to: [],
      internal_approver: [],
      client_approver: [],
      // watchlist: false,
      watch_list: [],
      // start_date: '',
      // due_date: '',
      type_id: "",
      // project_type : ""
    });
    setErrors({
      project_name: "",
      project_description: "",
      channel: "",
      required_sheet: "",
      assign_to: "",
      internal_approver: "",
      client_approver: "",
      watch_list: "",
      // start_date: '',
      // due_date: '',
      type_id: "",
      // project_type : ""
    });
    setIsCreateDisable(false);
    closeModal();
  };

  const handleSearchImage = () => {
    // navigate('/search', {
    //     state: {

    //     }
    // })
    console.log("searchInput");
    // setSearchView(true);
  };

  // const handleFormattedData = useCallback((data) => {
  //     setFormattedData((prevData) => {
  //         if (JSON.stringify(prevData) !== JSON.stringify(data)) {
  //             return data;
  //         }
  //         return prevData;
  //     });
  // }, []);

  useEffect(() => {
    const modalElement = document.getElementById("exampleModal");
    const handleModalHidden = () => {
      handleClearForm();
    };
    modalElement?.addEventListener("hidden.bs.modal", handleModalHidden);
    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalHidden);
    };
  }, []);

  // useEffect(() => {
  //   const modalElement = document.getElementById("exampleModal");
  //   let modalInstance = null;

  //   if (modalElement) {
  //     modalInstance = new window.bootstrap.Modal(modalElement);
  //   }

  //   const handleModalClose = () => {
  //     console.log("Route changed, closing the modal if it is open.");
  //     setSearchView(false);
  //     if (modalInstance && modalInstance._isShown) {
  //       modalInstance.hide();
  //     }
  //   };

  //   handleModalClose();

  //   return () => {
  //     handleModalClose();
  //   };
  // }, [searchView]);

  useEffect(() => {
    fetchProjects();
    setFormValue({
      input_text: "",
      page: 1,
      ctr: 0,
      tags: [],
      model_filters: [],
      is_edited: "",
      search_by: "both",
    });
    setSearchView(false);
    setShowResults(false);
  }, []);

  const handleChannelNameUpdate = (name) => {
    setChannelName(name);
  };

  const handleFocus = () => {
    setSearchView(true); // Show component on focus
  };

  const formatDueDate = (createdTimestamp, finalDeliveryDate) => {
    if (!finalDeliveryDate) return t("none");

    const deliveryDate = new Date(finalDeliveryDate);
    const currentDate = new Date();

    const diffInDays = Math.ceil(
      (deliveryDate - currentDate) / (1000 * 60 * 60 * 24)
    );
    const formattedDate = format(deliveryDate, "yyyy.MM.dd");

    if (diffInDays > 2) {
      return t("dueDateFull", { date: formattedDate });
    } else if (diffInDays > 0) {
      return t("dueInDays", { days: diffInDays });
    } else if (diffInDays === 0) {
      return t("dueToday");
    } else {
      return t("dueDateExpired");
    }
  };

  const formatName = (nameStr) => {
    if (!nameStr) return "N/A";
    const match = nameStr.match(/username:([\w\s]+)/);
    if (!match) return "N/A";

    const fullName = match[1].trim();
    const nameParts = fullName.split(" ");

    return nameParts
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  // calculate due date of progress
  const calculateProgress = (createdDate, finalDate) => {
    if (!finalDate) return { progress: 0, daysLeft: null };

    const startDate = new Date(createdDate);
    const endDate = new Date(finalDate);
    const currentDate = new Date();
    const totalDuration = endDate - startDate;
    const elapsed = currentDate - startDate;
    const daysLeft = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));

    // Calculate progress percentage
    let progress = (elapsed / totalDuration) * 100;
    progress = Math.min(Math.max(progress, 0), 100);

    return {
      progress,
      daysLeft,
      isExpired: daysLeft < 0,
    };
  };

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      // Clear the history and replace with dashboard
      window.history.replaceState(null, "", "/dashboard");

      const handleBackButton = (e) => {
        e.preventDefault();
        e.stopPropagation();

        // Force stay on dashboard
        window.history.replaceState(null, "", "/dashboard");
        navigate("/dashboard", { replace: true });

        // Optionally show a message
        // window.alert('Cannot navigate back from dashboard');

        return false;
      };

      // Add multiple event listeners to catch all navigation attempts
      window.addEventListener("popstate", handleBackButton);
      window.addEventListener("beforeunload", handleBackButton);

      // Disable back button
      window.history.pushState(null, "", "/dashboard");
      window.history.pushState(null, "", "/dashboard");

      return () => {
        window.removeEventListener("popstate", handleBackButton);
        window.removeEventListener("beforeunload", handleBackButton);
      };
    }
  }, [location.pathname, navigate]);

  const filteredProjectData =
    selectedSite?.site_name === "All"
      ? projectList
      : projectList?.filter((project) => {
          return getSiteName(project.channel_name) === selectedSite?.site_name;
        });

  return (
    <>
      {/* header */}
      {!initialLoading && (
        <Header
          fetchProjects={fetchProjects}
          setIsAll={setIsAll}
          screen="manager"
          onChannelNameUpdate={handleChannelNameUpdate}
          setSearchView={setSearchView}
        />
      )}
      {/* search hero section */}
      {!initialLoading && !isUserSettingsLoading && !searchView && (
        <div className="heroBanner">
          <div className="contentWrap">
            <h4>{t("welcomeTitle", { username: userSettings?.username })}!</h4>
            <h4>{t("welcomeManagerDashboardTitle2")}</h4>
          </div>
          <div className="buttonWrap">
            <Link to={"#"} onClick={handleFocus}>
              <SearchIcon /> {t("search")}
            </Link>
            {userSettings?.permissions && hasPermission(8) && (
              <Link
                to={"#"}
                onClick={() => fetchUsers()}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <AddIcon /> {t("NewProject")}
              </Link>
            )}
          </div>
        </div>
      )}

      {/* my project section */}

      {!initialLoading && !isUserSettingsLoading && !searchView && (
        <section className="hero-project">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="dash-title">
                  <h2>{t("myProjects")}</h2>
                  <Link to={"/projects"}>
                    {t("seeMore")} <ArrowForward />
                  </Link>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div
                  className="cardWrapper"
                  style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                >
                  {loading ? (
                    Array.from({ length: 4 }).map((_, index) => (
                      <div className="projectWrap" key={index}>
                        <div className="projectImg">
                          <Skeleton
                            variant="rectangular"
                            width={60}
                            height={60}
                          />
                          <Skeleton variant="text" width={150} />
                        </div>
                        <div className="projectContent">
                          <Skeleton variant="text" width={200} height={30} />
                          <Skeleton variant="text" width={250} />
                        </div>
                        <div className="projectFooter">
                          {[...Array(4)].map((_, i) => (
                            <div className="projectFooterLeft" key={i}>
                              <Skeleton variant="text" width={120} />
                              <Skeleton variant="text" width={80} />
                            </div>
                          ))}
                        </div>
                        <div className="progresswrapper">
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={10}
                          />
                          <Skeleton variant="text" width={100} />
                        </div>
                      </div>
                    ))
                  ) : filteredProjectData?.length ? (
                    [...filteredProjectData]
                      .sort((a, b) => {
                        return (
                          new Date(b.created_timestamp) -
                          new Date(a.created_timestamp)
                        );
                      })
                      .map(
                        (item, index) =>
                          item?.channel_name !== null && (
                            <div
                              className="projectWrap"
                              key={index}
                              onClick={() =>
                                handleUpdateProject(
                                  item?.project_id,
                                  item?.channel_name,
                                  item?.shared_with_client,
                                  item?.user_role
                                )
                              }
                            >
                              <div className="projectImg">
                                <ProjectIcon />
                                <p>
                                  {t(
                                    (item?.status.length > 25
                                      ? item?.status.substring(0, 25) + "..."
                                      : item?.status
                                    ).replace(/\s+/g, "_") // Replace spaces with underscores
                                  )}
                                </p>
                              </div>
                              <div className="projectContent">
                                <div className="d-flex justify-content-between">
                                  <h2>{t(item?.project_name)}</h2>
                                  <h2>{getSiteName(item?.channel_name)}</h2>
                                </div>
                                <p>{t(item?.project_desc)}</p>
                              </div>
                              <div className="projectFooter">
                                <div className="projectFooterLeft">
                                  <h5>{t("requiredSheet")}</h5>
                                  <p>{item?.required_sheet}</p>
                                </div>
                                <div className="projectFooterLeft">
                                  <h5>{t("APPROVESHEET")}</h5>
                                  <p>{item?.approved_sheets}</p>
                                </div>
                                <div className="projectFooterLeft">
                                  <h5>{t("projectTimeline")}</h5>
                                  <p>
                                    {formatDueDate(
                                      item?.created_timestamp,
                                      item?.final_delivery_date
                                    )}
                                  </p>
                                </div>
                                <div className="projectFooterLeft">
                                  <h5>
                                    {t("approver")} / {t("assignee")}
                                  </h5>
                                  <p>
                                    <span className="circle">
                                      {formatName(item?.approver_name)}
                                    </span>{" "}
                                    |{" "}
                                    <span className="circle">
                                      {formatName(item?.assign_to_user_name)}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="progresswrapper">
                                <div className="due-date">
                                  <div className="progressbar">
                                    <div
                                      className="inlineProgress"
                                      style={{
                                        width: `${
                                          calculateProgress(
                                            item.created_timestamp,
                                            item.final_delivery_date
                                          ).progress
                                        }%`,
                                      }}
                                    ></div>
                                  </div>
                                  <p>
                                    {item.final_delivery_date
                                      ? calculateProgress(
                                          item.created_timestamp,
                                          item.final_delivery_date
                                        ).daysLeft > 0
                                        ? t("dueDateleft", {
                                            days: calculateProgress(
                                              item.created_timestamp,
                                              item.final_delivery_date
                                            ).daysLeft,
                                          })
                                        : t("dueDateExpired")
                                      : t("NOT_AVAILABLE")}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                      )
                  ) : (
                    <p>{t("noRecordsFound")}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {searchView && (
        <>
          <Search
            searchView={searchView}
            setSearchView={setSearchView}
            setIsWelcomeScreen={setIsWelcomeScreen}
            isWelcomeScreen={isWelcomeScreen}
            searchInput={searchInput}
            handleSearchImage={handleSearchImage}
            searchIcon={searchIcon}
          />
        </>
      )}

      {/* create new project modal */}
      {!initialLoading && (
        <div
          className="modal fade font-noto-sans"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content" style={{ overflow: "inherit" }}>
              <div className="modal-header border-0 pb-0">
                <h5 className="modal-title fw-bold" id="exampleModalLabel">
                  {t("createNewProject")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClearForm}
                ></button>
              </div>

              <CreateProjectForm
                handleChange={handleChange}
                handlemultipleUsers={handlemultipleUsers}
                projectFormValue={projectFormValue}
                setProjectFormValue={setProjectFormValue}
                errors={errors}
                userList={userList}
                allUsers={allUsers}
                clientManager={clientManager}
                clientUserList={clientUserList}
                handleChannelChange={handleChannelChange}
                loading={loading && initialLoading}
                handleDateChange={handleDateChange}
                setInputProjectName={setInputProjectName}
                checked={checked}
                setChecked={setChecked}
                isAllUsers={isAllUsers}
                screen="manager"
              />

              {loading ? (
                <div className="modal-footer border-0">
                  {/* <p type="button" className="cancel_modal_footer" onClick={handleClearForm}>{t("cancel")}</p> */}
                  <CreateProjectFormLoader
                    height={60}
                    width={80}
                    rounded={"rounded-25"}
                  />
                  <CreateProjectFormLoader
                    height={60}
                    width={100}
                    rounded={"rounded-25"}
                  />
                </div>
              ) : (
                <div className="modal-footer border-0">
                  <p
                    type="button"
                    className="cancel_modal_footer"
                    onClick={handleClearForm}
                  >
                    {t("cancel")}
                  </p>
                  <button
                    type="button"
                    className="send_modal_footer"
                    onClick={handleSubmit}
                    disabled={isCreateDisable}
                  >
                    {t("create")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Manager;
