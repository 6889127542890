import { Box, Skeleton } from "@mui/material";
import React from "react";

const CreateProjectFormLoader = ({ height, width, rounded }) => {
  return (
    <div>
      <Box>
        <Skeleton
          animation="wave"
          height={height || 50}
          width={width}
          className={rounded}
        />
      </Box>
    </div>
  );
};

export default CreateProjectFormLoader;
