import React, { useContext, useEffect, useState } from "react";
import UploadDesignFile from "./UploadDesignFile";
import "../../Manager/reference.scss";
import ArrowBack from "../../../assets/newicons/arrow_back.svg";
import Close from "../../../assets/newicons/close.svg";
import Search1 from "../../../assets/newicons/search1.svg";
import noImage from "../../../assets/newimages/no_image.jpg";
import CheckCircleOnImage from "../../../assets/newicons/check_circle_onImage.svg";
import image1 from "../../../assets/newimages/image1.jpg";
import image2 from "../../../assets/newimages/image2.jpg";
import image3 from "../../../assets/newimages/image3.jpg";
import image21 from "../../../assets/newimages/sunflower.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useSearchContext } from "../../../context/useSearchContext";
import { useTranslation } from "react-i18next";
import { useEditContext } from "../../../context/useEditContext";
import { useAuthContext } from "../../../context/useAuthContext";
import { useFilterContext } from "../../../context/useFilterContext";
import debounce from "debounce";
import { post } from "../../../API/axios";
import { toast } from "react-toastify";
import ImageListLoader from "../skeletonLoaders/ImageListLoader";
import ImageContext from "./ImageContext";
import { showAlert } from "./AlertService";
const ReferenceComp = ({
  prevSelectedImages,
  createdProjectId,
  channelId,
  referenceList,
  anotationData,
}) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const [tab, setTab] = useState(1);
  const [isImageListLoading, setIsImageListLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState(
    prevSelectedImages || []
  );
  // const [scrollLoader, setScrollLoader] = useState(false);
  const [offSetId, setOffSetId] = useState(null);
  const currentLanguage = i18n.language;
  const [resetData, setResetData] = useState({});
  const [tagLoader, setTagLoader] = useState(false);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const { setImages } = useContext(ImageContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search); // Parse the query string
  const project_id = queryParams.get("project_id");
  const {
    setResults,
    numberOfResults,
    setNumberOfResults,
    showResults,
    setShowResults,
  } = useSearchContext();

  const { selectedSite } = useAuthContext();

  const [searchTag, setSearchTag] = useState([
    {
      tag: "search-tag-1",
      id: 1,
    },
    {
      tag: "search-tag-2",
      id: 2,
    },
    {
      tag: "search-tag-3",
      id: 3,
    },
  ]);

  const [formData, setFormData] = useState({
    input_text: "",
    page: 1,
    ctr: 0,
    tags: [],
    model_filters: [],
    is_edited: "all",
    search_by: "desc",
  });

  const handleSelectedImage = (image) => {
    setSelectedImages((prevImages) => {
      const isSelected = prevImages?.some(
        (selectedImage) => selectedImage.image_id === image.image_id
      );
      if (isSelected) {
        return prevImages.filter(
          (selectedImage) => selectedImage.image_id !== image.image_id
        );
      } else {
        return [...prevImages, image];
      }
    });
  };

  const handleDeleteTag = (id) => {
    const remainingTags = searchTag.filter((tag) => tag.id !== id);
    setSearchTag(remainingTags);
  };

  const handleClose = () => {
    // setSelectedImages([])
    // setImages([]);
    setTab(1);
  };

  const handleSelectReference = () => {
    // handleClose();
    // navigate('/annotation')
    // if(location.pathname === '/newimage-annotation'){

    // }else{

    navigate(
      `/reference-details?project_id=${createdProjectId}&channel_id=${channelId}`,
      {
        state: {
          selectedImages: selectedImages,
          createdProjectId: createdProjectId,
          channelId: channelId,
        },
      }
    );
    // }
  };

  const handleSearchInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const resetSearch = () => {
    setNumberOfResults({
      primary: 0,
      similar: 0,
    });
    setFormData({ ...formData, page: 1 });
  };

  const getSearchText = async (isNextPage = false) => {
    // setLoading(true);
    // setIsImageListLoading(true)

    let payload;
    if (!isNextPage) {
      resetSearch();
      setIsImageListLoading(true);
    }

    if (formData?.input_text) {
      payload = {
        input_text: formData.input_text,
        lang: currentLanguage, //en or ja
        page: isNextPage ? formData.page + 1 : 1,
        ctr: Number(formData.ctr),
      };

      if (formData?.input_text || formData?.ctr || formData.tags.length !== 0) {
        payload = {
          input_text: formData.input_text,
          lang: currentLanguage, //en or ja
          page: isNextPage ? formData.page + 1 : 1,
          ctr: Number(formData.ctr),
          is_edited: formData.is_edited,
        };
        if (formData.tags.length > 0) {
          payload.filter_tags = formData.tags;
        }
      }
    } else {
      payload = {
        input_text: formData.input_text,
        lang: currentLanguage, //en or ja
        page: isNextPage ? formData.page + 1 : 1,
        ctr: Number(formData.ctr),
        is_edited: formData.is_edited,
      };
    }

    if (isNextPage) {
      // setScrollLoader(true);
      // setIsImageListLoading(true)
      setFormData({ ...formData, page: formData.page + 1 });
    }
    payload.site_id =
      typeof selectedSite?.site_id == "object"
        ? selectedSite?.site_id
        : [selectedSite?.site_id];
    const { tags, ctr, input_text, lang, page, site_id, is_edited, ...rest } =
      formData;

    payload = {
      ...payload,
      ...rest,
    };

    if (
      !payload?.input_text &&
      payload?.ctr <= 0 &&
      !payload?.filter_tags?.length &&
      !payload?.delivery_content?.length &&
      !payload?.delivery_type?.length &&
      !payload?.image_type?.length &&
      !payload?.industry?.length &&
      !payload?.model_filters?.length &&
      !payload?.is_edited
    ) {
      showAlert(t("oneFilterAtleast"), "warning");

      // setLoading(false);
      return;
    }

    setShowResults(true);
    setResetData(formData);
    await post(
      `text_search_image/`,
      offSetId && isNextPage ? { offset_id: offSetId, ...payload } : payload
    )
      .then((response) => {
        setNumberOfResults({
          total_images: response?.pagination?.total_images_in_page,
          primary: response?.pagination?.total_images,
          similar: response?.pagination?.total_images_in_current_page,
        });
        setOffSetId(response?.pagination?.offset_id);
        if (!isNextPage) {
          setSearchResults(response?.data);
          setResults(response?.data);
        } else {
          if (response?.data?.length > 0) {
            setSearchResults((prevResults) => [
              ...prevResults,
              ...response?.data,
            ]);
            setResults((prevResults) => [...prevResults, ...response?.data]);
          }
        }
        setTimeout(() => {
          // setLoading(false);
          // setScrollLoader(false);
          setIsImageListLoading(false);
        }, 1000);
      })
      .catch((error) => {
        // setLoading(false);
        setShowResults(true);
        // setScrollLoader(false);
        setIsImageListLoading(false);
        console.error("error", error);
      })
      .finally(() => {});
  };

  // const [sentryRef] = useInfiniteScroll({
  //     loading: scrollLoader,
  //     hasNextPage: numberOfResults.total_images == numberOfResults.primary ? false :
  //         true || searchResults?.length === 0 ? true : false,
  //     onLoadMore: () => {
  //         if (formData.input_text) {
  //             getSearchText(true)
  //         }
  //     },
  //     disabled: false,
  //     delayInMs: 2000,
  //     rootMargin: "0px 0px 400px 0px",
  // });

  const searchTags = debounce(() => {
    getSearchTags();
  }, 1000);

  useEffect(() => {
    searchTags();
  }, []);

  const getSearchTags = async () => {
    try {
      setTagLoader(true);
      const { data } = await post(`get_all_unique_tags/`, {
        lang: "all",
      });

      localStorage.setItem("en_tags", JSON.stringify(data.en));
      localStorage.setItem("ja_tags", JSON.stringify(data.ja));
      setTags(
        (currentLanguage === "ja" ? data.ja : data.en).filter((t) => t !== "")
      );
      setTagLoader(false);
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleInputSearchText = (e) => {
    e.preventDefault();
    getSearchText();
  };

  useEffect(() => {
    searchTags();
  }, []);

  useEffect(() => {
    if (formData.input_text) {
      getSearchText();
    }
  }, []);

  return (
    <div>
      <div
        className="modal fade"
        id="addreference"
        aria-hidden="true"
        aria-labelledby="addreferenceLabel"
        tabIndex="-1"
      >
        <div className="modal-dialog modal_lg modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content mx-4">
            <div
              type="button"
              data-bs-dismiss="modal"
              className="d-flex justify-content-end"
            >
              <img
                src={Close}
                alt="Close"
                className="close-popup-button"
                onClick={handleClose}
              />
            </div>
            <div className="modal-body d-flex flex-lg-row flex-column p-0 overflow-hidden">
              <div
                className={`p-4 refrenece-image-width overflow-auto ${selectedImages?.length === 1 ? " " : "w-100"} `}
              >
                <div className="Add_reference_text font-noto-sans">
                  {t("addReference")}
                </div>
                <div className="d-flex align-items-center gap-4">
                  <div
                    className={`font-noto-sans add_navbar ${tab === 1 && "active"} cursor_pointer`}
                    onClick={() => setTab(1)}
                  >
                    {t("search")}
                  </div>
                  <div
                    className={`font-noto-sans add_navbar ${tab === 2 && "active"} cursor_pointer`}
                    onClick={() => setTab(2)}
                  >
                    {t("upload")}
                  </div>
                </div>

                {tab === 1 && (
                  <div>
                    <form onSubmit={handleInputSearchText}>
                      <div className="position-relative w-90 mt-4 mb-5">
                        <div className="rounded-pill mx-4 mx-lg-0 px-lg-0 position-relative ">
                          <input
                            className="form-control rounded-pill border-1 ps-4"
                            name="input_text"
                            value={formData.input_text}
                            onChange={handleSearchInput}
                            placeholder={t("searchPastVision")}
                            aria-label="Search"
                            style={{ height: "56px" }}
                          />
                          <div
                            className="position-absolute"
                            style={{
                              top: "8px",
                              right: "10px",
                              cursor: "pointer",
                            }}
                            onClick={handleInputSearchText}
                          >
                            <img src={Search1} className="" alt="search" />
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="font-noto-sans add_body_recent">
                      {t("recentSearces")}
                    </div>

                    <div className="d-flex flex-wrap  gap-2">
                      {searchTag.length > 0 &&
                        searchTag.map((tag, i) => (
                          <div
                            key={i}
                            className="d-flex gap-1 align-items-center search_iteam"
                          >
                            <div className="search_iteam_name font-noto-sans">
                              {tag.tag}
                            </div>
                            <div
                              className="search_iteam_name cursor_pointer"
                              onClick={() => handleDeleteTag(tag.id)}
                            >
                              <img src={Close} alt="Close" width={15} />
                            </div>
                          </div>
                        ))}
                    </div>

                    <div className="font-noto-sans add_body_recent mt-4 mb-2">
                      {t("recentView")}
                    </div>

                    <div className="d-flex flex-wrap justify-content-start gap-3">
                      {isImageListLoading ? (
                        <>
                          {[...Array(30)].map((_, i) => (
                            <ImageListLoader key={i} />
                          ))}
                        </>
                      ) : searchResults && searchResults.length > 0 ? (
                        searchResults.map((image, i) => (
                          <label
                            key={i}
                            className={`position-relative image-container image-container-ref mb-0 cursor-pointer ${selectedImages?.some((selectedImage) => selectedImage.image_id === image.image_id) ? "selected" : ""}`}
                            id="abc"
                          >
                            <img
                              loading="lazy"
                              src={image.image_url}
                              width={160}
                              height={160}
                              className="rounded-3"
                              alt="no-img"
                            />
                            <input
                              type="checkbox"
                              className="image-checkbox"
                              htmlFor="abc"
                              checked={selectedImages?.some(
                                (selectedImage) =>
                                  selectedImage.image_id === image.image_id
                              )}
                              onChange={() => handleSelectedImage(image)}
                            />
                          </label>
                        ))
                      ) : (
                        <p>{t("noRecentImagesFound")}</p>
                      )}
                      {/* <div ref={sentryRef} /> */}
                    </div>
                  </div>
                )}

                {tab === 2 && (
                  <div>
                    <div className="my-5">
                      <UploadDesignFile setTab={setTab} />
                      {/* Work in Progress */}
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`${selectedImages?.length === 1 && tab === 1 ? "d-block" : "d-none"} p-4 refrenece-image-width pt-5 overflow-auto`}
                style={{ backgroundColor: "#F6F6F6" }}
              >
                <div className=" d-flex flex-column align-items-center">
                  <div style={{ maxWidth: "560px" }}>
                    <div className="d-flex justify-content-center">
                      <img
                        src={selectedImages ? selectedImages[0]?.image_url : ""}
                        alt="noFramImage"
                        className="img-fluid reference-image"
                      />
                    </div>
                    <div className="">
                      <div className="d-flex justify-content-between align-content-center">
                        <button className="d-flex gap-1 align-items-center search_iteam mt-3">
                          <div className="search_iteam_name">{t("ctr")}:</div>
                          <div className="search_iteam_name">
                            {selectedImages[0]?.ctr?.length &&
                            selectedImages[0]?.ctr[0] !== null &&
                            selectedImages[0]?.ctr[1] !== null ? (
                              <div className="search_iteam_name">
                                {selectedImages[0]?.ctr[0].toFixed(3) ===
                                selectedImages[0]?.ctr[1].toFixed(3)
                                  ? `${(selectedImages[0].ctr[0] * 100).toFixed(2)}%`
                                  : selectedImages[0].ctr[0].toFixed(2) ===
                                      selectedImages[0].ctr[1].toFixed(2)
                                    ? `${(selectedImages[0].ctr[0] * 100).toFixed(2)}%`
                                    : `${(selectedImages[0].ctr[0] * 100).toFixed(2)}% ~ ${(
                                        selectedImages[0].ctr[1] * 100
                                      ).toFixed(2)}%`}
                              </div>
                            ) : (
                              <div className="search_iteam_name">N/A</div>
                            )}
                          </div>
                        </button>
                        <div className="img_des mt-3">
                          <span>{selectedImages[0]?.image_size}</span>{" "}
                          <span>232kb</span>
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="reference_name">
                          {selectedImages[0]?.image_url?.split("/").pop()}
                        </div>

                        <div className="img_des my-3">
                          <span className="fw-600">{t("description")}: </span>
                          {currentLanguage === "ja"
                            ? selectedImages[0]?.image_description_ja
                            : selectedImages[0]?.image_description}
                        </div>
                        {/* <div className='img_des'>Resolution: <span>1350 x 1350 px</span> </div>
                                            <div className='img_des'>File size: <span>232kb</span></div> */}
                      </div>
                      {/* <div className='my-4'>
                                            <div className="d-flex flex-wrap gap-1">
                                                {imageTags.length > 0 &&
                                                    imageTags.map((tag, i) => (
                                                        <div key={i} className='search_iteam'>
                                                            <div className='search_iteam_name'>{tag.tag}</div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div> */}
                      {/* <hr />
                                        <div>
                                            <div>
                                                <button className="referencebtn" data-bs-target="#exampleModalToggle2" data-bs-dismiss="modal" onClick={handleSelectReference}> select as reference </button>
                                            </div>
                                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${selectedImages?.length > 0 && tab === 1 ? "d-block" : "d-none"} modal-footer w-100`}
            >
              <div className="d-flex justify-content-between w-100">
                <div className="d-flex gap-1 align-items-center ">
                  <div className="fs-14 fw-bold font-noto-sans">
                    <span className="me-1">{selectedImages?.length}</span>
                    {t("selected")}
                  </div>
                  <div className=" cursor_pointer">
                    <img src={Close} alt="Close" width={15} />
                  </div>
                </div>
                <div className="d-flex justify-content-end ">
                  <button
                    type="button"
                    className="border-0 bg-transparent text-black fs-14 me-3 fw-bold font-noto-sans"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    {t("cancel")}
                  </button>
                  <button
                    type="button"
                    className="btn rounded-pill py-2 px-4 fs-14 font-noto-sans fw-bold"
                    data-bs-dismiss="modal"
                    style={{ backgroundColor: "black" }}
                    onClick={handleSelectReference}
                  >
                    {t("select")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabIndex="-1">
                <div className="modal-dialog modal_lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content mx-4">
                        <div className="modal-header border-0">
                            <div data-bs-target="#addreference" data-bs-toggle="modal" data-bs-dismiss="modal">
                                <img src={ArrowBack} alt="ArrowBack" />
                            </div>
                            <div data-bs-target="#addreference" data-bs-toggle="modal" data-bs-dismiss="modal">
                                <img src={Close} alt="Close" />
                            </div>
                        </div>
                        <div className='modal-body'>
                            <div className='p-3'>
                                <div className='row justify-content-evenly'>
                                    <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 col-12 text-center'>
                                        <img src={selectedImage?.imageUrl} width={704} height={748} alt='noFramImage' className='img-fluid' />
                                    </div>
                                    <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 col-12'>
                                        <button className='d-flex gap-1 align-items-center search_iteam mt-4'>
                                            <div className='search_iteam_name'>
                                                CTR:
                                            </div>
                                            <div className='search_iteam_name'>
                                                0%
                                            </div>
                                        </button>
                                        <div className="mt-4">
                                            <div className='reference_name'>
                                                reference-fine-name.png
                                            </div>

                                            <div className='img_des my-3'>
                                                Description:
                                            </div>
                                            <div className='img_des'>Resolution: <span>1350 x 1350 px</span> </div>
                                            <div className='img_des'>File size: <span>232kb</span></div>
                                        </div>
                                        <div className='my-4'>
                                            <div className="d-flex flex-wrap gap-1">
                                                {imageTags.length > 0 &&
                                                    imageTags.map((tag, i) => (
                                                        <div key={i} className='search_iteam'>
                                                            <div className='search_iteam_name'>{tag.tag}</div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <hr />
                                        <div>
                                            <div>
                                                <button className="referencebtn" data-bs-target="#exampleModalToggle2" data-bs-dismiss="modal" onClick={handleSelectReference}> select as reference </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

      {/* <div className="modal fade" id="addreference" aria-hidden="true" aria-labelledby="addreferenceLabel" tabIndex="-1">
                <div className="modal-dialog modal_lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content mx-4">
                        <div className="modal-header border-0">
                            <div className='Add_reference_text font-noto-sans'>
                                Add reference
                            </div>
                            <div type="button" data-bs-dismiss="modal">
                                <img src={Close} alt="Close" />
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className='p-4'>
                                <div className='d-flex align-items-center gap-4'>
                                    <div className={`font-noto-sans add_navbar ${tab === 1 && "active"} cursor_pointer`} onClick={() => setTab(1)}>
                                        Search
                                    </div>
                                    <div className={`font-noto-sans add_navbar ${tab === 2 && "active"} cursor_pointer`} onClick={() => setTab(2)}>
                                        Upload
                                    </div>
                                </div>

                                {tab === 1 &&
                                    <div>
                                        <div className='position-relative w-90 mt-4 mb-5'>
                                            <div className='rounded-pill'>
                                                <input className="form-control me-2 rounded-pill border-1 ps-4" type="search" placeholder="Search past vision" aria-label="Search" style={{ height: '56px' }} />
                                            </div>
                                            <div className='position-absolute' style={{ top: '8px', right: '10px' }}>
                                                <img src={Search1} className='' alt='search'></img>
                                            </div>
                                        </div>

                                        <div className='font-noto-sans add_body_recent'>
                                            Recent Searches
                                        </div>

                                        <div className='d-flex flex-wrap  gap-2'>
                                            {searchTag.length > 0 &&
                                                searchTag.map((tag, i) => (
                                                    <div key={i} className='d-flex gap-1 align-items-center search_iteam'>
                                                        <div className='search_iteam_name'>
                                                            {tag.tag}
                                                        </div>
                                                        <div className='search_iteam_name cursor_pointer' onClick={() => handleDeleteTag(tag.id)}>
                                                            <img src={Close} alt="Close" />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        <div className='font-noto-sans add_body_recent mt-4 mb-2'>
                                            Recent view
                                        </div>

                                        <div className='d-flex flex-wrap justify-content-start gap-3'>
                                            {recentImages && recentImages.length > 0 ? (
                                                recentImages.map((image, i) => (
                                                    <div key={i} data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" data-bs-dismiss="modal"
                                                        onClick={() => handleSelectedImage(image.id)}>
                                                        <img src={image.imageUrl} width={160} height={160} className='rounded-3' alt='no-img' />
                                                    </div>
                                                ))
                                            ) : (
                                                <p>No recent images found</p>
                                            )}
                                        </div>
                                    </div>
                                }

                                {tab === 2 &&
                                    <div>
                                        <div className='my-5'>
                                            <UploadDesignFile />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabIndex="-1">
                <div className="modal-dialog modal_lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content mx-4">
                        <div className="modal-header border-0">
                            <div data-bs-target="#addreference" data-bs-toggle="modal" data-bs-dismiss="modal">
                                <img src={ArrowBack} alt="ArrowBack" />
                            </div>
                            <div data-bs-target="#addreference" data-bs-toggle="modal" data-bs-dismiss="modal">
                                <img src={Close} alt="Close" />
                            </div>
                        </div>
                        <div className='modal-body'>
                            <div className='p-3'>
                                <div className='row justify-content-evenly'>
                                    <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 col-12 text-center'>
                                        <img src={selectedImage?.imageUrl} width={704} height={748} alt='noFramImage' className='img-fluid' />
                                    </div>
                                    <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 col-12'>
                                        <button className='d-flex gap-1 align-items-center search_iteam mt-4'>
                                            <div className='search_iteam_name'>
                                                CTR:
                                            </div>
                                            <div className='search_iteam_name'>
                                                0%
                                            </div>
                                        </button>
                                        <div className="mt-4">
                                            <div className='reference_name'>
                                                reference-fine-name.png
                                            </div>

                                            <div className='img_des my-3'>
                                                Description:
                                            </div>
                                            <div className='img_des'>Resolution: <span>1350 x 1350 px</span> </div>
                                            <div className='img_des'>File size: <span>232kb</span></div>
                                        </div>
                                        <div className='my-4'>
                                            <div className="d-flex flex-wrap gap-1">
                                                {imageTags.length > 0 &&
                                                    imageTags.map((tag, i) => (
                                                        <div key={i} className='search_iteam'>
                                                            <div className='search_iteam_name'>{tag.tag}</div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <hr />
                                        <div>
                                            <div>
                                                <button className="referencebtn" data-bs-target="#exampleModalToggle2" data-bs-dismiss="modal" onClick={handleSelectReference}> select as reference </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </div>
  );
};

export default ReferenceComp;
