import React, { useRef, useEffect } from "react";
import { Group, Text, Transformer } from "react-konva";
import { useDispatch } from "react-redux";
import { updateLayerElement } from "../../store/konvaSlice";

const DraggableText = ({
  element,
  layerId,
  handleDragEnd,
  handleGroupClick,
  handleDoubleClick,
  handleRightClick,
  handleTransformEnd,
  stageDimensions, // e.g. { width: number, height: number } for the sheet dimensions
  handleDragStart,
  sheetId,
  isSelected,
  onSelect,
}) => {
  const groupRef = useRef();
  const textRef = useRef();
  const transformerRef = useRef();
  const dispatch = useDispatch();

  const dragBoundFunc = (pos) => {
    try {
      const group = groupRef.current;
      const text = textRef.current;
      const stage = group?.getStage();
      const sheet = group?.getParent()?.getParent();

      if (!group || !text || !stage || !sheet) {
        console.log("Missing references");
        return pos;
      }

      // Get stage scale
      const stageScale = stage.scaleX();

      // Get current element properties
      const width = text.width() * text.scaleX();
      const height = text.height() * text.scaleY();

      // Get sheet position
      const sheetPos = sheet.absolutePosition();

      // Calculate relative position within sheet, accounting for scale
      const relativePos = {
        x: (pos.x - sheetPos.x) / stageScale,
        y: (pos.y - sheetPos.y) / stageScale,
      };

      // Clamp position within sheet bounds, accounting for scale
      const maxX = Math.max(0, 500 - width);
      const maxY = Math.max(0, 500 - height);
      const newX = Math.min(Math.max(0, relativePos.x), maxX);
      const newY = Math.min(Math.max(0, relativePos.y), maxY);

      // Convert back to stage coordinates, accounting for scale
      return {
        x: newX * stageScale + sheetPos.x,
        y: newY * stageScale + sheetPos.y,
      };
    } catch (error) {
      console.error("DragBound error:", error);
      return pos;
    }
  };

  useEffect(() => {
    console.log("DraggableText mounted with props:", {
      id: element.id,
      position: { x: element.props.x, y: element.props.y },
      isSelected,
      text: element.props.text,
    });
  }, []);

  useEffect(() => {
    if (isSelected) {
      console.log("Text selected, updating transformer");
      const textNode = textRef.current;
      const transformer = transformerRef.current;
      if (textNode && transformer) {
        transformer.nodes([textNode]);
        transformer.getLayer()?.batchDraw();
      }
    }
  }, [isSelected]);

  useEffect(() => {
    console.log("Text element props updated:", {
      ...element.props,
      elementId: element.id,
      sheetId,
      isSelected,
    });
  }, [element.props, element.id, sheetId, isSelected]);

  const handleTextDragEnd = (e) => {
    dispatch(
      updateLayerElement({
        sheetId,
        layerId: element.id,
        updates: {
          x: e.target.x(),
          y: e.target.y(),
        },
      })
    );
    if (handleDragEnd) {
      handleDragEnd(e, layerId, element.id);
    }
  };

  const handleTextTransformEnd = (e) => {
    const node = textRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // Reset scale to 1 after transformation.
    node.scaleX(1);
    node.scaleY(1);

    dispatch(
      updateLayerElement({
        sheetId,
        layerId: element.id,
        updates: {
          x: node.x(),
          y: node.y(),
          fontSize: element.fontSize * scaleX,
          width: Math.max(5, node.width() * scaleX),
          height: Math.max(5, node.height() * scaleY),
        },
      })
    );

    if (handleTransformEnd) {
      handleTransformEnd(e, layerId, element.id);
    }
  };

  const handleSelect = (e) => {
    e.cancelBubble = true;
    if (onSelect) {
      console.log("Text selected:", element);
      onSelect(element.id);
    }
  };

  return (
    <>
      <Group
        id={element.id}
        ref={groupRef}
        draggable
        x={element.props.x}
        y={element.props.y}
        onDragEnd={handleTextDragEnd}
        onClick={(e) => {
          console.log("Text clicked");
          handleSelect(e);
        }}
        onTap={handleSelect}
        onDblClick={(e) => handleDoubleClick(element, e.target)}
        onContextMenu={(e) => handleRightClick(e, element)}
        dragBoundFunc={dragBoundFunc}
        onTouchStart={() => handleGroupClick(layerId, element.id)}
        onDblTap={(e) => handleDoubleClick(element, e.target)}
        onDragStart={(e) => {
          console.log("Started dragging text");
          if (handleDragStart) handleDragStart(e, layerId, element.id);
        }}
      >
        <Text
          ref={textRef}
          {...element.props}
          draggable={false}
          text={element.props.text || ""} // Ensure text is never undefined
          fontSize={element.props.fontSize || 16} // Provide default fontSize
          onTransformEnd={handleTextTransformEnd}
          onClick={handleSelect}
          onTap={handleSelect}
        />
      </Group>
      {isSelected && (
        <Transformer
          ref={transformerRef}
          boundBoxFunc={(oldBox, newBox) => {
            // Force immediate console log
            console.log(
              "%c BoundBoxFunc called",
              "background: #222; color: #bada55",
              {
                oldBox,
                newBox,
                element: element.id,
                time: new Date().toISOString(),
              }
            );

            try {
              const text = textRef.current;
              const stage = text?.getStage();
              const sheet = text?.getParent()?.getParent();

              // Add more detailed logging
              console.log(
                "%c Transform References",
                "background: #222; color: #bada55",
                {
                  hasText: !!text,
                  hasStage: !!stage,
                  hasSheet: !!sheet,
                  textId: text?.id(),
                  stageId: stage?.id(),
                  sheetId: sheet?.id(),
                }
              );

              if (!text || !stage || !sheet) {
                console.warn("Missing required references", {
                  text,
                  stage,
                  sheet,
                });
                return oldBox;
              }

              // Get stage scale and sheet position
              const stageScale = stage.scaleX();
              const sheetPos = sheet.absolutePosition();

              // Log position data
              console.log(
                "%c Position Data",
                "background: #222; color: #bada55",
                {
                  stageScale,
                  sheetPosition: sheetPos,
                  textPosition: text.getPosition(),
                  textSize: {
                    width: text.width(),
                    height: text.height(),
                  },
                }
              );

              // Calculate proposed box position relative to sheet
              const relativeBox = {
                x: (newBox.x - sheetPos.x) / stageScale,
                y: (newBox.y - sheetPos.y) / stageScale,
                width: newBox.width / stageScale,
                height: newBox.height / stageScale,
              };

              // Log the calculations for debugging
              console.log("Transform bounds:", {
                relativeBox,
                sheetPos,
                stageScale,
                oldBox,
                newBox,
              });

              // Check if proposed position would be outside sheet bounds
              if (
                relativeBox.x < 0 ||
                relativeBox.y < 0 ||
                relativeBox.x + relativeBox.width > 500 ||
                relativeBox.y + relativeBox.height > 500 ||
                relativeBox.width < 30 ||
                relativeBox.height < 30
              ) {
                console.log("Rejecting transform - out of bounds");
                return oldBox;
              }

              return newBox;
            } catch (error) {
              // Enhanced error logging
              console.error("Error in boundBoxFunc:", {
                error,
                stack: error.stack,
                element: element.id,
                oldBox,
                newBox,
              });
              return oldBox;
            }
          }}
          enabledAnchors={[
            "top-left",
            "top-right",
            "bottom-left",
            "bottom-right",
          ]}
          rotateEnabled={true}
          keepRatio={false}
          padding={5}
        />
      )}
    </>
  );
};

export default DraggableText;
