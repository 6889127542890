import React, { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../../../context/useAuthContext";
import { ReactComponent as Logomico } from "../../../assets/newicons/header-logo-microworks.svg";
import headerArrowDown from "../../../assets/newicons/keyboard_arrow_down.svg";
// import downArrow from "../../../assets/icons/drop_blue.svg";

const HeaderDropdown = ({ screen }) => {
  const { saveSelectedSite, selectedSite, userSettings } = useAuthContext();
  const inputRef = useRef(null);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [filteredSites, setFilteredSites] = useState(
    userSettings?.channel_name
  );

  useEffect(() => {
    const savedSite = localStorage.getItem("selectedSite");
    const savedIndex = localStorage.getItem("selectedSiteIndex");

    if (savedSite && savedIndex && userSettings?.channel_name) {
      const site = JSON.parse(savedSite);
      saveSelectedSite(parseInt(savedIndex), site);
    }
  }, [userSettings]);

  const handleSiteChange = (e) => {
    const value = e.target.value;
    const filtered = userSettings?.channel_name.filter((site) =>
      site.site_name.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredSites(filtered);
  };

  const onSiteChange = (index, site = "") => {
    if (index === -1) {
      localStorage.removeItem("selectedSite");
      localStorage.removeItem("selectedSiteIndex");
    } else {
      localStorage.setItem("selectedSite", JSON.stringify(site));
      localStorage.setItem("selectedSiteIndex", index);
    }

    saveSelectedSite(index, site);
    setIsDropDownOpen(false);
    setFilteredSites(userSettings?.channel_name);
  };

  const handleClickOutside = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setIsDropDownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdown = () => {
    if (screen === "manager" || screen === "settings") {
      setIsDropDownOpen(!isDropDownOpen);
    }
  };

  useEffect(() => {
    setFilteredSites(userSettings?.channel_name);
  }, [userSettings]);

  return (
    <div className="filterdropdown" ref={inputRef}>
      <div className="dropdownWrap" onClick={() => handleDropdown()}>
        <div className="logoIcon">
          <Logomico />
        </div>
        {isDropDownOpen ? (
          <input type="text" onChange={handleSiteChange} autoFocus />
        ) : (
          <span className="text-500 fs-14" style={{ whiteSpace: "nowrap" }}>
            {selectedSite?.site_name}
          </span>
        )}
        <img
          onClick={() => handleDropdown()}
          src={headerArrowDown}
          style={{
            cursor: "pointer",
            transform: isDropDownOpen ? "rotate(180deg)" : "rotate(0)",
          }}
          alt="icon"
        />
      </div>
      {isDropDownOpen && (
        <ul>
          {userSettings && <li onClick={() => onSiteChange(-1)}>All</li>}
          {filteredSites &&
            filteredSites?.map((site, index) => (
              <li key={index} onClick={() => onSiteChange(index, site)}>
                {site?.site_name}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default HeaderDropdown;
