export function formatDate(dateString) {
  if (!dateString) return null;
  const date = new Date(dateString);

  // Check if the date object is valid
  if (isNaN(date.getTime())) {
    return null; // Return null for invalid date strings
  }

  // Additional check for date rollovers (e.g., Feb 29 in non-leap year)
  if (typeof dateString === 'string') {
    const match = dateString.match(/^(\d{4})-(\d{2})-(\d{2})/);
    if (match) {
      const inputMonth = parseInt(match[2], 10);
      // Compare input month (1-based) with Date object's month (0-based + 1)
      if (inputMonth !== date.getUTCMonth() + 1) {
        return null; // Month mismatch indicates rollover
      }
    }
    // Note: This regex is basic and only handles YYYY-MM-DD format at the start.
    // More complex regex could handle other formats if needed.
  }


  // Use UTC methods to avoid timezone issues
  const year = date.getUTCFullYear();
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2); // Add leading zero if needed
  const day = ("0" + date.getUTCDate()).slice(-2); // Add leading zero if needed
  return `${year}/${month}/${day}`;
}

// Helper function to create a FileReader instance (allows mocking)
export function _createReader() {
  return new FileReader();
}

export const convertToBase64 = async (url) => {
  try {
    const response = await fetch(url);
    // Check if the fetch was successful
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = _createReader(); // Use the helper function
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (err) => reject(err); // Pass the actual error event/object
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error converting image to Base64:", error);
    // Decide if we should return null or re-throw, returning null for now
    return null;
  }
};

export function timeAgo(date) {
  // Handle invalid, null, or undefined input date
  if (!date || isNaN(new Date(date).getTime())) {
      return ""; // Return empty string for invalid inputs
  }

  const inputDate = new Date(date); // Ensure we have a Date object
  const now = new Date();
  const seconds = Math.floor((now - inputDate) / 1000);
  // console.log(seconds); // Keep console log for now, remove later if desired

  // Handle future dates gracefully
  if (seconds < 0) {
      // Treat future dates as 0 seconds ago for simplicity
      return "0 secs ago";
  }

  if (seconds < 60) {
    return seconds + " secs ago";
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return minutes + " mins ago";
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return hours + " hrs ago";
  }

  const days = Math.floor(hours / 24);
  return days + " days ago";
}

export function isParsable(str) {
  if (typeof str !== "string") {
    return false; // Not a string, so can't be parsed
  }

  // Check for common parsable types (numbers, JSON, dates)
  if (!isNaN(str) && str.trim() !== "") {
    return true; // Can be parsed as a number
  }

  try {
    JSON.parse(str);
    return true; // Can be parsed as JSON
  } catch (e) {
    // JSON parse failed, continue checking other types
  }

  // Trim the string before attempting Date.parse
  if (!isNaN(Date.parse(str.trim()))) {
    return true; // Can be parsed as a date
  }

  return false; // No parsable type found
}

export const getDueStatus = (dateString, completed) => {
  const inputDate = new Date(dateString);
  // console.log("inputDate", inputDate); // Keep console log for now

  // Check if the input date string resulted in a valid Date object
  if (isNaN(inputDate.getTime())) {
    return ""; // Return empty string for invalid date inputs
  }

  const currentDate = new Date();

  // Normalize both dates to remove time differences
  inputDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  const diffInDays = Math.round(
    (inputDate - currentDate) / (1000 * 60 * 60 * 24)
  );

  if (completed) {
    return diffInDays === 0
      ? "Completed today"
      : `Completed ${Math.abs(diffInDays)} ${Math.abs(diffInDays) === 1 ? "day" : "days"} ago`;
  }

  if (diffInDays > 0) {
    return `Due in ${diffInDays} ${diffInDays === 1 ? "day" : "days"}`;
  } else if (diffInDays < 0) {
    return `Was due ${Math.abs(diffInDays)} ${Math.abs(diffInDays) === 1 ? "day" : "days"} ago`;
  } else {
    return "Due today";
  }
};

export function isDateGreaterThan(date1, date2) {
  // Check if both inputs are valid Date objects
  if (!date1 || isNaN(date1.getTime()) || !date2 || isNaN(date2.getTime())) {
    // If either date is invalid or null/undefined, comparison is meaningless. Return false.
    // This prevents errors from calling methods on invalid dates.
    return false;
  }

  // Use UTC methods to compare dates based on calendar day, ignoring time and timezone
  const date1Only = new Date(
    Date.UTC(date1.getUTCFullYear(), date1.getUTCMonth(), date1.getUTCDate())
  );
  const date2Only = new Date(
    Date.UTC(date2.getUTCFullYear(), date2.getUTCMonth(), date2.getUTCDate())
  );

  return date1Only > date2Only;
}
