import React from "react";
import "./new-design.scss";

import styles from "./image-design-screen.module.css";

import aiIcon from "../../assets/newicons/ai-white-icon.svg";
import imageIcon from "../../assets/newicons/imageIcon.svg";
import tIcon from "../../assets/newicons/tIcon.svg";
import addIcon from "../../assets/newicons/add.svg";
import ImageComponent from "../../views/Manager/component/ImageComponent";
import CreateProjectForm from "../Manager/component/createProjectForm";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { newGet, newGet_V4, newPost } from "../../API/axios";
import { showAlert } from "../Manager/component/AlertService";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../context/useAuthContext";
import CreateProjectFormLoader from "../Manager/skeletonLoaders/CreateProjectFormLoader";
import closebtn from "../../assets/newicons/close.svg";
import resizeIcon from "../../assets/newicons/btn-expand.svg";
import Search1 from "../../assets/newicons/search1.svg";
import draw_collage from "../../assets/newicons/draw_collage.svg";
import interests from "../../assets/newicons/interests.svg";
import noImage from "../../assets/newimages/tabs-image.png";
import WebFont from "webfontloader";
import AddImagePopUp from "./component/AddImagePopUp";
import arrow from "../../assets/newicons/arrow-left-dark.svg";
import chooseImage from "../../assets/newimages/choose-small-image.png";
import heartIcon from "../../assets/newicons/heart-icon.svg";
import store from "../../store/index";
import { Provider } from "react-redux";
import { KonvaProvider, useKonvaContext } from "../../context/useKonvaContext";
import KonvaHeader from "../Manager/component/KonvaHeader";
import { useLocation } from "react-router-dom";
import ImageSearchAndGrid from "./component/ImageSearchAndGrid";
import DualVisionBoard from "../Manager/component/DualMode/DualVisionBoard";
import SplitPane from "react-split-pane";
import { useReferenceContext } from "../../context/useReferenceContext";
import CommentAndTaskVisionStudio from "../Manager/component/CommentsVisionStudio";
import UploadedFiles from "./component/UploadedFiles";
import RenameFilesPopUp from "./component/RenameFilesPopUp";
import UploadComponent from "../../components/Shared/UploadComponent";
import { convertToBase64 } from "../../utils/functions";
import { useFlag } from "@unleash/proxy-client-react";

const useDebouncedValue = (inputValue, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);

  return debouncedValue;
};

const loadGoogleFonts = () => {
  WebFont.load({
    google: {
      families: [
        "Qwitcher Grypen",
        "Space Grotesk",
        "Epilogue",
        "Lexend",
        "Outfit",
        "Clash Display",
        "Red Hat Display",
        "Sora",
        "Be Vietnam Pro",
        "General Sans",
      ],
    },
  });
};

const importAllIcons = () => {
  const iconContext = require.context("../../assets/newicons", false, /\.svg$/);
  const icons = {};
  iconContext.keys().forEach((key) => {
    // Remove './' from start and '.svg' from end to get clean name
    const iconName = key.replace(/^\.\//, "").replace(/\.svg$/, "");
    icons[iconName] = iconContext(key);
  });
  return icons;
};

const icons = importAllIcons();

const SideMenu = ({
  onClose,
  selectedImages,
  setSelectedImages,
  setSelectedFont,
  typeChange,
  setFiledata,
}) => {
  const [isImageLayerOpen, setIsImageLayerOpen] = useState(false);
  const [uploadPopUpOpen, setUploadPopupOpen] = useState(false);

  useEffect(() => {
    loadGoogleFonts();
  }, []);

  const fonts = [
    { family: "Qwitcher Grypen", name: "Qwitcher Grypen" },
    { family: "Space Grotesk", name: "Space Grotesk" },
    { family: "Epilogue", name: "Epilogue" },
    { family: "Lexend", name: "Lexend" },
    { family: "Outfit", name: "Outfit" },
    { family: "Clash Display", name: "Clash Display" },
    { family: "Cabinet Grotesk", name: "Cabinet Grotesk" },
    { family: "GT Walsheim", name: "GT Walsheim" },
    { family: "Red Hat Display", name: "Red Hat Display" },
    { family: "Sora", name: "Sora" },
    { family: "Be Vietnam Pro", name: "Be Vietnam Pro" },
    { family: "General Sans", name: "General Sans" },
  ];

  const [tab, setTab] = useState(1);

  const fileInputRef = useRef(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImage = {
          url: e.target.result,
          rotation: 0,
          id: "image_" + Date.now(),
          x: 50,
          y: 50,
        };
        setSelectedImages((prevImages) => {
          const updatedImages = [...prevImages, newImage];
          return updatedImages;
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const { boardId, setUploadedFiles } = useReferenceContext();

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  const handleDragStart = (e, font) => {
    const textData = {
      kind: "text",
      id: `text_${Date.now()}`,
      props: {
        text: "Double click to edit",
        fontSize: 24,
        fontFamily: font.family,
        fill: "#000000",
        x: 50,
        y: 50,
        width: 200,
        draggable: true,
      },
    };

    console.log("Starting text drag with data:", textData);
    e.dataTransfer.setData("text/plain", font.family);
    e.dataTransfer.setData("application/json", JSON.stringify(textData));
    e.currentTarget.style.opacity = "0.5";
  };

  const handleDragStartObject = (e, element) => {
    const imageData = {
      kind: "shape",
      id: `shape_${Date.now()}`,
      props: {
        src: element?.src,
        x: 50,
        y: 50,
        width: 200,
        height: 200,
        opacity: 1,
        corner_radius: [0, 0, 0, 0],
        isSelected: false,
      },
    };

    e.dataTransfer.setData("image", element.src);
    e.dataTransfer.setData("application/json", JSON.stringify(imageData));
    const dragImage = new Image();
    dragImage.src = element.src;
    dragImage.onload = () => {
      e.dataTransfer.setDragImage(dragImage, 10, 10);
    };
  };

  const getUploadedFiles = async () => {
    const params = new URLSearchParams(window.location.search);
    const projectId = params.get("project_id");
    const value = await newGet_V4(`MyFiles/get?project_id=${projectId}`);
    if (value?.result?.files) {
      setUploadedFiles(value?.result?.files);
    }
  };

  useEffect(() => {
    (async () => {
      if (tab !== 2) return;
      await getUploadedFiles();
    })();
  }, [tab]);

  const handleDragEnd = (e) => {
    e.currentTarget.style.opacity = "1";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.dataTransfer.getData("text/plain");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const hadleSetImage = () => {
    setIsImageLayerOpen(true);
  };

  const getIconEntries = () => {
    return Object.entries(icons).map(([name, src]) => ({
      name,
      src,
    }));
  };

  const handleFilesFetched = async (fetchedFiles) => {
    if (fetchedFiles && fetchedFiles.length > 0) {
      try {
        // Convert all file URLs to base64 in parallel
        const newImageDetails = await Promise.all(
          fetchedFiles.map(async (item) => {
            const { file_url } = item;
            const base64String = await convertToBase64(file_url);
            return { ...item, file_url, base64: base64String };
          })
        );

        await getUploadedFiles();

        // Update the state with the new image details
        // setImageDetails(prevDetails => [...prevDetails, ...newImageDetails]);
        // setUploadedFiles(prevFiles => [...prevFiles, ...newImageDetails]);
        // setImages(newImageDetails);
      } catch (error) {
        console.error("Error converting files to base64:", error);
      }
    }
  };
  const { t } = useTranslation();

  return (
    <div>
      {uploadPopUpOpen ? (
        <UploadComponent
          uploadKeys={{
            board_id: boardId,
          }}
          screen="visionBoard"
          onFilesFetched={handleFilesFetched}
          setPopupState={setUploadPopupOpen}
        />
      ) : null}

      {typeChange === "text" && (
        <div className="position-relative">
          <div className="border-right ps-md-3 pe-md-3 pb-md-3 ps-2 pe-2 pb-2 pt-0 bg-white font-noto-sans tabs-conatainer">
            <div className="d-flex align-items-center justify-content-between pb-2 pt-3 px-2 bg-white position-sticky top-0">
              <h5 className="fs-14 font-noto-sans fw-600 mb-0">Text</h5>
              <div className="d-flex align-items-center gap-2">
                <button className="bg-transparent border-0 p-0 d-flex align-items-center justify-content-center">
                  <img src={closebtn} alt="closebtn" onClick={onClose} />
                </button>
              </div>
            </div>
            <div className="p-2">
              <button className="text-center w-100 mt-md-2 bg-transparent fw-600 rounded-pill add-text-button">
                Add text box
              </button>
            </div>
            <div
              className="d-flex flex-column gap-md-3 gap-2 p-2 mt-md-4 mt-2"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              {fonts.map((font, index) => (
                <div key={index} className="text-conatiner-box">
                  <div
                    draggable
                    onDragStart={(e) => handleDragStart(e, font)}
                    onDragEnd={handleDragEnd}
                  >
                    <h4 className="m-0" style={{ fontFamily: font.family }}>
                      {" "}
                      ABC{" "}
                    </h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {typeChange === "image" && (
        <div className="position-relative">
          <div className="border-right ps-md-3 pe-md-3 pb-md-3 ps-2 pe-2 pb-2 pt-0 bg-white font-noto-sans tabs-conatainer">
            <div className="d-flex align-items-center justify-content-between pb-2 pt-3 px-2  bg-white position-sticky top-0">
              <h5 className="fs-14 font-noto-sans fw-600 mb-0">{t("Image")}</h5>
              <div className="d-flex align-items-center gap-2">
                <button
                  className="bg-transparent border-0 p-0 d-flex align-items-center justify-content-center"
                  data-bs-toggle="modal"
                  data-bs-target="#addImage"
                  type="button"
                >
                  <img src={resizeIcon} alt="resizeIcon" />
                </button>
                <button className="bg-transparent border-0 p-0 d-flex align-items-center justify-content-center">
                  <img src={closebtn} alt="closebtn" onClick={onClose} />
                </button>
              </div>
            </div>
            <div className="p-2">
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={handleImageUpload}
                style={{ display: "none" }}
              />

              {/* <button
                  className='d-flex align-items-center justify-content-center gap-2 w-100 border-0 black-gradiant-btn'
                  onClick={() => { setTypeChange('interests') }}
                >
                  <img src={aiIcon} alt='aiIcon' />
                  <span>Generate with MicoAI</span>
                </button> */}

              <button
                className={`${styles.uploadButton}`}
                onClick={() => setUploadPopupOpen(true)}
              >
                {t("upload")}
              </button>
            </div>
            <div className="w-100 d-flex flex-lg-row flex-column p-0">
              <div className="p-2 w-100">
                <div className="d-flex align-items-center gap-2">
                  <div
                    className={`font-noto-sans add_navbar add_navbar-fs ${tab === 1 && "active"} cursor_pointer ${styles.tabTitle}`}
                    onClick={() => setTab(1)}
                  >
                    {t("Library")}
                  </div>
                  <div
                    className={`font-noto-sans add_navbar add_navbar-fs ${tab === 2 && "active"} cursor_pointer ${styles.tabTitle}`}
                    onClick={() => setTab(2)}
                  >
                    {t("MyFiles")}
                  </div>
                </div>

                {tab === 1 && <ImageSearchAndGrid />}

                {tab === 2 && (
                  <div>
                    <form>
                      <div className="position-relative w-90 mt-4 mb-4 mt-md-4 mt-3 mb-3">
                        <div className="position-relative tabs-search">
                          <input
                            className="form-control"
                            name="input_text"
                            placeholder={t("Search")}
                            aria-label="Search"
                          />
                          <img
                            src={Search1}
                            className="position-absolute"
                            alt="search"
                          />
                        </div>
                      </div>
                    </form>
                    <div>
                      <UploadedFiles setFiledata={setFiledata} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* drow type */}
      {typeChange === "interests" && (
        <div className="position-relative">
          <div className="border-right ps-md-3 pe-md-3 pb-md-3 ps-2 pe-2 pb-2 pt-0 bg-white font-noto-sans tabs-conatainer">
            <div className="d-flex align-items-center justify-content-between pb-2 pt-3 px-2  bg-white position-sticky top-0">
              <h5 className="fs-14 font-noto-sans fw-600 mb-0">Illustration</h5>
              <div className="d-flex align-items-center gap-2">
                <button
                  className="bg-transparent border-0 p-0 d-flex align-items-center justify-content-center"
                  data-bs-toggle="modal"
                  data-bs-target="#addImage"
                  type="button"
                >
                  <img src={resizeIcon} alt="resizeIcon" />
                </button>
                <button className="bg-transparent border-0 p-0 d-flex align-items-center justify-content-center">
                  <img src={closebtn} alt="closebtn" onClick={onClose} />
                </button>
              </div>
            </div>
            <div className="p-2">
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={handleImageUpload}
                style={{ display: "none" }}
              />

              <button
                className="d-flex align-items-center justify-content-center gap-2 w-100 border-0 black-gradiant-btn"
                onClick={handleUploadClick}
              >
                <img src={aiIcon} alt="aiIcon" />
                <span>Generate with MicoAI</span>
              </button>
            </div>
            <div className="w-100 d-flex flex-lg-row flex-column p-0">
              <div className="p-2 w-100">
                <div className="d-flex align-items-center gap-2">
                  <div
                    className={`font-noto-sans add_navbar add_navbar-fs ${tab === 1 && "active"} cursor_pointer`}
                    onClick={() => setTab(1)}
                  >
                    Library
                  </div>
                  <div
                    className={`font-noto-sans add_navbar add_navbar-fs ${tab === 2 && "active"} cursor_pointer`}
                    onClick={() => setTab(2)}
                  >
                    My files
                  </div>
                </div>

                {tab === 1 && (
                  <div>
                    <form>
                      <div className="position-relative w-90 mt-md-4 mt-3 mb-3 mb-md-4">
                        <div className="position-relative tabs-search">
                          <input
                            className="form-control"
                            name="input_text"
                            placeholder={t("Search")}
                            aria-label="Search"
                          />
                          <img
                            src={Search1}
                            className="position-absolute"
                            alt="search"
                          />
                        </div>
                      </div>
                    </form>
                    <div>
                      <div className="tabs-grid-image">
                        {getIconEntries().map((icon, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center justify-content-center tabs-image"
                            draggable
                            onDragStart={(e) =>
                              handleDragStartObject(e, { src: icon.src })
                            }
                          >
                            <img
                              src={icon.src}
                              alt={icon.name}
                              style={{ pointerEvents: "none" }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                {tab === 2 && (
                  <div>
                    <form>
                      <div className="position-relative w-90 mt-4 mb-4 mt-md-4 mt-3 mb-3">
                        <div className="position-relative tabs-search">
                          <input
                            className="form-control"
                            name="input_text"
                            placeholder={t("Search")}
                            aria-label="Search"
                          />
                          <img
                            src={Search1}
                            className="position-absolute"
                            alt="search"
                          />
                        </div>
                      </div>
                    </form>
                    <div>
                      <div className="tabs-grid-image">
                        {getIconEntries().map((icon, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center justify-content-center tabs-image"
                            draggable
                            onDragStart={(e) =>
                              handleDragStartObject(e, { src: icon.src })
                            }
                          >
                            <img
                              src={icon.src}
                              alt={icon.name}
                              style={{ pointerEvents: "none" }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {typeChange === "draw" && (
        <div className="position-relative">
          <div className="border-right ps-md-3 pe-md-3 pb-md-3 ps-2 pe-2 pb-2 pt-0 bg-white font-noto-sans tabs-conatainer">
            <div className="d-flex align-items-center justify-content-between pb-2 pt-3  bg-white position-sticky top-0">
              <div className="d-flex align-items-center gap-2">
                <button className="bg-transparent border-0 p-0">
                  <img src={arrow} alt="arrow" />
                </button>
                <h5 className="fs-14 font-noto-sans fw-600 mb-0">
                  Generate with MicoAI
                </h5>
              </div>
              <div className="d-flex align-items-center gap-2">
                <button
                  className="bg-transparent border-0 p-0 d-flex align-items-center justify-content-center"
                  data-bs-toggle="modal"
                  data-bs-target="#addImage"
                  type="button"
                >
                  <img src={Search1} alt="resizeIcon" />
                </button>
                <button className="bg-transparent border-0 p-0 d-flex align-items-center justify-content-center">
                  <img src={closebtn} alt="closebtn" onClick={onClose} />
                </button>
              </div>
            </div>
            <div className="gnerate-textarea mt-4">
              <textarea
                placeholder="Write your prompt"
                className="w-100"
              ></textarea>
            </div>
            <h5 className="fs-14 font-noto-sans fw-600 mb-0 mt-3">
              Choose a look & feel
            </h5>
            <div className="choose-image-container mt-3 ">
              <div>
                <div className="choose-image">
                  <img src={chooseImage} alt="chooseImage" />
                </div>
                <span>Professional</span>
              </div>
              <div>
                <div className="choose-image">
                  <img src={chooseImage} alt="chooseImage" />
                </div>
                <span>Casual</span>
              </div>
              <div>
                <div className="choose-image">
                  <img src={chooseImage} alt="chooseImage" />
                </div>
                <span>Casual</span>
              </div>
            </div>
            <button
              className="d-flex align-items-center justify-content-center gap-2 px-4 border-0 black-gradiant-btn py-2 rounded-pill my-4"
              style={{ width: "auto" }}
            >
              <img src={aiIcon} alt="aiIcon" />
              <span>Generate</span>
            </button>
            <div className="border-top pt-4">
              <h5 className="fs-14 font-noto-sans fw-bolder mb-0">
                Variations
              </h5>
              <div className="casual-image-container mt-3 ">
                <div className="casual-image position-relative">
                  <img src={noImage} alt="noImage" className="image rounded" />
                  <img src={heartIcon} alt="heartIcon" className="heart-icon" />
                </div>
                <div className="casual-image position-relative">
                  <img src={noImage} alt="noImage" className="image rounded" />
                  <img src={heartIcon} alt="heartIcon" className="heart-icon" />
                </div>
                <div className="casual-image position-relative">
                  <img src={noImage} alt="noImage" className="image rounded" />
                  <img src={heartIcon} alt="heartIcon" className="heart-icon" />
                </div>
                <div className="casual-image position-relative">
                  <img src={noImage} alt="noImage" className="image rounded" />
                  <img src={heartIcon} alt="heartIcon" className="heart-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {typeChange === "folder" && <div>hello</div>}
      {typeChange === "bubble" && <div>hello</div>}
      <AddImagePopUp typeChange={typeChange} />
    </div>
  );
};

const ImageDesignScreen = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedFont, setSelectedFont] = useState("");
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [clientUserList, setClientUserList] = useState([]);
  const [clientManager, setClientManager] = useState([]);
  const allUsers = [...userList, ...clientUserList];
  const [inputProjectName, setInputProjectName] = useState("");
  const debouncedProjectName = useDebouncedValue(inputProjectName, 1000);
  const [isCreateDisable, setIsCreateDisable] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [typeChange, setTypeChange] = useState("");
  const [isImageLayerOpen, setIsImageLayerOpen] = useState(false);
  const location = useLocation();
  let { projectName, sheet, channelName, createdProjectId, projectStatus } =
    location.state || {};
  const [splitScreenSwitchedOn, setSplitScreenSwitchedOn] = useState(false);
  const [splitMinMax, setSplitMinMax] = useState({
    min: null,
    max: null,
  });
  const [sliderDragging, setSliderDragging] = useState(false);
  const [sliderResetkey, setSliderResetKey] = useState(0);
  const SplitPaneRef = useRef(null);
  const { projectDetails } = useReferenceContext();
  const [ProjectId, setCreatedProjectId] = useState(createdProjectId);
  const {
    showCommentSection,
    setShowCommentSection,
    setShowSideModal,
    sheetListLoading,
  } = useKonvaContext();
  const [activeButton, setActiveButton] = useState(null);
  const [fileData, setFiledata] = useState({
    file_id: "",
    file_name: "",
  });


  const VisionBoardSideMenuFlag = useFlag("VisionBoardSideMenu")
  const TextLayerVisionStudioFlag = useFlag("TextLayerVisionStudio")
  const ImageLayerVisionStudioFlag = useFlag("ImageLayerVisionStudio")


  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("project_name")) {
    projectName = decodeURIComponent(urlParams.get("project_name"));
  }
  if (urlParams.get("sheet")) {
    sheet = urlParams.get("sheet");
  }
  if (urlParams.get("channel_name")) {
    channelName = decodeURIComponent(urlParams.get("channel_name"));
  }
  if (urlParams.get("project_id")) {
    createdProjectId = urlParams.get("project_id");
  }
  useEffect(() => {
    if (projectFormValue.project_name.length >= 2) {
      checkProjectName(inputProjectName);
    }
  }, [debouncedProjectName]);

  useEffect(() => {
    const getMinAndMax = () => {
      if (
        SplitPaneRef.current &&
        SplitPaneRef.current?.splitPane?.offsetWidth
      ) {
        const width = SplitPaneRef.current?.splitPane?.offsetWidth;
        console.log({
          max: width * 0.8,
          min: width * 0.2,
        });

        setSplitMinMax({
          max: width * 0.8,
          min: width * 0.2,
        });
      } else {
        console.warn("SplitPaneRef or offsetWidth not available yet.");
        setSplitMinMax({ min: 0, max: 0 });
      }
    };

    getMinAndMax();

    const handleResize = () => {
      getMinAndMax();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { t } = useTranslation();

  const { initialLoading } = useAuthContext();

  const handleImageUploadClick = (e, image) => {
    e.preventDefault();
    setActiveButton(image);
    if (!showMenu) {
      setShowMenu(true);
    }
    setTypeChange(image);
  };

  const [projectFormValue, setProjectFormValue] = useState({
    project_name: "",
    project_description: "",
    channel: "",
    assign_to: [],
    internal_approver: [],
    client_approver: [],
    watch_list: [],
    start_date: "",
    due_date: "",
    required_sheet: "",
  });

  const [errors, setErrors] = useState({
    project_name: "",
    project_description: "",
    channel: "",
    assign_to: "",
    internal_approver: "",
    watch_list: "",
    start_date: "",
    due_date: "",
    required_sheet: "",
  });

  // Check if project is active based on status
  const isProjectActive = () => {
    if (!projectStatus) return true; // Default to active if no status

    const statusId = parseInt(projectStatus);

    // Status 5 means completed/cancelled project, status 3 means inactive
    return statusId !== 5 && statusId !== 3;
  };
  const toggleCommentSection = () => {
    setShowSideModal(false);
    setShowCommentSection((prev) => !prev);
  };

  const checkProjectName = async (value) => {
    const specialCharsPattern = /[^a-zA-Z0-9 _]/g;
    const testValue = specialCharsPattern.test(value);
    if (!testValue) {
      try {
        await newGet(
          `project/checkProjectName?project_name=${value}&site_id=${projectFormValue?.channel?.site_id}`
        ).then((data) => {
          if (data && data.status_code == 200) {
            if (data.result[0]?.count_project_name > 0) {
              setErrors({
                ...errors,
                project_name: "Project name already exist !!!",
              });
              setIsCreateDisable(true);
            } else {
              setErrors({ ...errors, project_name: "Project name is valid" });
              setIsCreateDisable(false);
            }
          }
        });
      } catch (error) {
        console.log("error:???????", error);
        setLoading(false);
      }
    } else {
      setErrors({
        ...errors,
        project_name: "special characters are not allowed !!!",
      });
      setIsCreateDisable(true);
    }
  };

  const handleChange = async (e, fieldName) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    if (name === "required_sheet") {
      if (/^\d*$/.test(value)) {
        setProjectFormValue({ ...projectFormValue, [name]: value });
      }
    } else {
      setProjectFormValue({ ...projectFormValue, [name]: value });
    }
  };

  const handleDateChange = (date, name) => {
    const formattedDate = date ? format(date, "MM-dd-yyyy") : null;
    setProjectFormValue({ ...projectFormValue, [name]: formattedDate });
    setErrors({ ...errors, [name]: "" });
  };

  const handleChannelChange = (site) => {
    setProjectFormValue({ ...projectFormValue, channel: site });
    setErrors({ ...errors, channel: "" });
  };

  const handlemultipleUsers = (e, options, fieldName) => {
    let newFormValue = { ...projectFormValue, [fieldName]: options };
    let newErrors = { ...errors, [fieldName]: "" };

    if (
      ["assign_to", "internal_approver", "client_approver"].includes(fieldName)
    ) {
      const combinedWatchList = [
        ...new Set([
          ...newFormValue.assign_to,
          ...newFormValue.internal_approver,
          ...newFormValue.client_approver,
        ]),
      ];
      const watchListUser = allUsers?.filter(
        (user) =>
          !combinedWatchList?.some(
            (combined) => combined.user_id === user.user_id
          )
      );
    }

    setProjectFormValue(newFormValue);
    setErrors(newErrors);
  };

  const handleSubmit = async () => {
    const requiredFields = [
      { key: "project_name", message: "Project name is required." },
      {
        key: "project_description",
        message: "Project description is required.",
      },
      { key: "channel", message: "Channel name is required." },
      { key: "required_sheet", message: "No.of sheet is required." },
      {
        key: "assign_to",
        message: "Assign to name is required.",
        isArray: true,
      },
      {
        key: "internal_approver",
        message: "Internal approver name is required.",
        isArray: true,
      },
      { key: "start_date", message: "start date is required." },
      { key: "due_date", message: "Due date is required." },
      { key: "required_sheet", message: "No. of sheet is required." },
    ];

    let formIsValid = true;
    const newErrors = { ...errors };

    requiredFields.forEach((field) => {
      if (
        field.isArray
          ? projectFormValue[field.key].length === 0
          : !projectFormValue[field.key]
      ) {
        newErrors[field.key] = field.message;
        formIsValid = false;
      } else if (
        field.key === "required_sheet" &&
        (!projectFormValue[field.key] || projectFormValue[field.key] == 0)
      ) {
        newErrors[field.key] =
          projectFormValue[field.key] == 0
            ? "Required sheet can not be zero"
            : field.message;
        formIsValid = false;
      } else {
        newErrors[field.key] = "";
      }
    });

    if (!formIsValid) {
      setErrors(newErrors);
      return;
    }
    const formData = {
      project_name: projectFormValue?.project_name,
      project_desc: projectFormValue?.project_description,
      site_id: projectFormValue?.channel?.site_id,
      assign_to_user_id: projectFormValue?.assign_to
        ?.map((user) => user?.account_login_id)
        .join(","),
      internal_approver_user_id: projectFormValue?.internal_approver
        ?.map((user) => user?.account_login_id)
        .join(","),
      client_approver_user_id: projectFormValue?.client_approver
        ?.map((user) => user?.account_login_id)
        .join(","),
      watchlist: projectFormValue?.watch_list
        ?.map((user) => user?.account_login_id)
        .join(","),
      start_date: projectFormValue?.start_date,
      due_date: projectFormValue?.due_date,
      required_sheet: parseInt(projectFormValue?.required_sheet),
    };
    try {
      setLoading(true);
      const data = await newPost(`project`, formData);
      setLoading(false);
      if (data.status_code == 201) {
        showAlert("Project created successfully", "success");
        closeModal();
      }
    } catch (error) {
      console.log("error:", error);
      setLoading(false);
    }
  };

  const closeModal = () => {
    const modalElement = document.getElementById("exampleModal");
    const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
    modalInstance.hide();
  };

  const handleClearForm = () => {
    setProjectFormValue({
      project_name: "",
      project_description: "",
      channel: "",
      required_sheet: "",
      assign_to: [],
      internal_approver: [],
      client_approver: [],
      watch_list: [],
      start_date: "",
      due_date: "",
    });
    setErrors({
      project_name: "",
      project_description: "",
      channel: "",
      required_sheet: "",
      assign_to: "",
      internal_approver: "",
      client_approver: "",
      watch_list: "",
      start_date: "",
      due_date: "",
    });
    setIsCreateDisable(false);
    closeModal();
  };

  useEffect(() => {
    const modalElement = document.getElementById("exampleModal");
    const handleModalHidden = () => {
      handleClearForm();
    };
    modalElement?.addEventListener("hidden.bs.modal", handleModalHidden);
    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalHidden);
    };
  }, []);

  useEffect(() => {
    if (splitScreenSwitchedOn) {
      setSliderResetKey((pre) => pre + 1);
    }
  }, [splitScreenSwitchedOn]);

  const closeSideMenu = () => {
    setShowMenu(false);
    setActiveButton(null);
  };

  return (
    <SplitPane
      {...(splitScreenSwitchedOn ? {} : { size: "100%" })}
      split="vertical"
      allowResize={true}
      minSize={splitMinMax.min || "auto"}
      maxSize={splitMinMax.max || "auto"}
      resizerClassName="custom-resizer"
      ref={SplitPaneRef}
      defaultSize={splitScreenSwitchedOn ? "70%" : "100%"}
      onChange={() => setSliderDragging(true)}
      onDragFinished={() => setSliderDragging(false)}
    >
      <React.Fragment>
        <KonvaProvider>
          <Provider store={store}>
            <KonvaHeader
              splitScreenSwitchedOn={splitScreenSwitchedOn}
              setSplitScreenSwitchedOn={setSplitScreenSwitchedOn}
              projectName={projectName}
              sheet={sheet}
              channelName={channelName}
              toggleCommentSection={toggleCommentSection}
              projectStatus={projectStatus}
            />
            <div className='d-flex' style={{ ...(splitScreenSwitchedOn ? { overflow: "hidden" } : {}) }}>
              {VisionBoardSideMenuFlag && !sheetListLoading && <div className='bg-white px-sm-2 py-4 d-flex flex-column gap-4 border-end position-relative' style={{ zIndex: "51" }}>
                <button className='border-0 bg-transparent d-flex align-items-center'>
                  <img src={addIcon} alt='addIcon' width={10} />
                  <span style={{ fontSize: "10px", fontWeight: "500", width: "20px"}} >{t("add")}</span>
                </button>
                {TextLayerVisionStudioFlag && <button className='border-0'
                  style={{
                    backgroundColor: activeButton === "text" ? "#E6E6E6" : "transparent",
                    borderRadius: "8px",
                    width: "40px",
                    height: "40px",
                  }}
                  onClick={(e) => { handleImageUploadClick(e, 'text') }}>
                  <img src={tIcon} alt='tIcon' />
                </button>}
                {ImageLayerVisionStudioFlag && <button className='border-0'
                  style={{
                    backgroundColor: activeButton === "image" ? "#E6E6E6" : "transparent",
                    borderRadius: "8px",
                    width: "40px",
                    height: "40px",
                  }}
                >
                  <img src={imageIcon} alt='imageIcon' onClick={(e) => { activeButton === "image" ? closeSideMenu() : handleImageUploadClick(e, 'image') }} />
                </button>
                }
                {/* <button className='border-0 bg-transparent'>
                  <img src={draw_collage} alt='draw_collage' onClick={(e) => { handleImageUploadClick(e, 'draw') }} />
                </button>
                <button className='border-0 border-bottom pb-3 bg-transparent'>
                  <img src={interests} alt='interests' onClick={(e) => { handleImageUploadClick(e, 'interests') }} />
                </button>
            
              <button className='border-0 bg-transparent'>
                <img src={draw_collage} alt='draw_collage' onClick={(e) => { handleImageUploadClick(e, 'draw') }} />
              </button>
              <button className='border-0 border-bottom pb-3 bg-transparent'>
                <img src={interests} alt='interests' onClick={(e) => { handleImageUploadClick(e, 'interests') }} />
              </button>
              <button className='border-0 bg-transparent'>
                <img src={folderIcon} alt='folderIcon' onClick={(e) => { handleImageUploadClick(e, 'folder') }} />
              </button>
              <button className='border-0 bg-transparent'>
                <img src={chat_bubble} alt='chat_bubble' onClick={(e) => { handleImageUploadClick(e, 'bubble') }} />
              </button>*/}
              
              </div>}

              {showMenu && (
                <SideMenu
                  onClose={closeSideMenu}
                  selectedImages={selectedImages}
                  setTypeChange={setTypeChange}
                  typeChange={typeChange}
                  setSelectedImages={setSelectedImages}
                  setSelectedFont={setSelectedFont}
                  selectedFont={selectedFont}
                  setFiledata={setFiledata}
                />
              )}
              <div
                style={{
                  width: showCommentSection
                    ? splitScreenSwitchedOn
                      ? "1px"
                      : "76%"
                    : "100%",
                  // pointerEvents : !isProjectActive()? "none" : "auto",
                  transition: "width 0.3s ease",
                  ...(splitScreenSwitchedOn
                    ? {
                        flexGrow: 1,
                      }
                    : {}),
                }}
              >
                <ImageComponent
                  selectedImages={selectedImages}
                  selectedFont={selectedFont}
                  typeChange={typeChange}
                  setSelectedImages={setSelectedImages}
                  setTypeChange={setTypeChange}
                  sheet={sheet}
                  setShowCommentSection={setShowCommentSection}
                  showCommentSection={showCommentSection}
                  projectStatus={projectStatus}
                />
              </div>
              {!showCommentSection && (
                <button
                  className="visionstudio-sidebar-close-btn"
                  onClick={() => {
                    setShowSideModal(false);
                    setShowCommentSection(true);
                  }}
                  style={{ right: 0, left: "auto" }}
                >
                  <span style={{ rotate: "225deg" }}></span>
                </button>
              )}
              <div
                className={`${showCommentSection ? "d-block" : "d-none"}`}
                style={{
                  position: splitScreenSwitchedOn ? "relative" : "fixed",
                  right: 0,
                  top: splitScreenSwitchedOn ? "0" : "42px",
                  width: splitScreenSwitchedOn ? "max-content" : "24%",
                  height: splitScreenSwitchedOn ? "auto" : "calc(100vh - 56px)",
                  backgroundColor: "#F6F6F6",
                  borderLeft: "1px solid #e0e0e0",
                  // pointerEvents: isProjectActive() ? 'auto' : 'none',
                  transition: "all 0.3s ease",
                  ...(splitScreenSwitchedOn
                    ? { minHeight: "100%", maxWidth: "485px" }
                    : {}),
                }}
              >
                <button
                  className="visionstudio-sidebar-close-btn"
                  onClick={() => setShowCommentSection(false)}
                >
                  <span style={{ rotate: "45deg" }}></span>
                </button>
                <CommentAndTaskVisionStudio
                  createdProjectId={createdProjectId}
                  ProjectId={ProjectId}
                  isActive={isProjectActive()}
                />
              </div>
            </div>
          </Provider>
        </KonvaProvider>
        {/* initial modal */}
        {!initialLoading && (
          <div
            className="modal fade font-noto-sans"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content" style={{ overflow: "inherit" }}>
                <div className="modal-header border-0 pb-0">
                  <h5 className="modal-title fw-bold" id="exampleModalLabel">
                    {t("createNewProject")}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleClearForm}
                  ></button>
                </div>

                <CreateProjectForm
                  handleChange={handleChange}
                  handlemultipleUsers={handlemultipleUsers}
                  projectFormValue={projectFormValue}
                  setProjectFormValue={setProjectFormValue}
                  errors={errors}
                  userList={userList}
                  allUsers={allUsers}
                  clientManager={clientManager}
                  clientUserList={clientUserList}
                  handleChannelChange={handleChannelChange}
                  loading={loading && initialLoading}
                  handleDateChange={handleDateChange}
                  setInputProjectName={setInputProjectName}
                />

                {loading ? (
                  <div className="modal-footer border-0">
                    <p
                      type="button"
                      className="cancel_modal_footer"
                      onClick={handleClearForm}
                    >
                      {t("cancel")}
                    </p>
                    <CreateProjectFormLoader
                      height={60}
                      width={100}
                      rounded={"rounded-25"}
                    />
                  </div>
                ) : (
                  <div className="modal-footer border-0">
                    <p
                      type="button"
                      className="cancel_modal_footer"
                      onClick={handleClearForm}
                    >
                      {t("cancel")}
                    </p>
                    <button
                      type="button"
                      className="send_modal_footer"
                      onClick={handleSubmit}
                      disabled={isCreateDisable}
                    >
                      {t("create")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <RenameFilesPopUp setFiledata={setFiledata} fileData={fileData} />
      </React.Fragment>
      {splitScreenSwitchedOn ? (
        <DualVisionBoard
          splitScreenSwitchedOn={splitScreenSwitchedOn}
          dragging={sliderDragging}
          createdProjectId={createdProjectId}
          isProjectActive={isProjectActive}
        />
      ) : null}
    </SplitPane>
  );
};
export default ImageDesignScreen;
