import React, { useEffect, useState } from "react";
import { get, newGet } from "../../API/axios";
import {
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "./user-management-content.module.css";
import optionSvg from "../../assets/options-vertical-svgrepo-com.svg";
import arrowSvg from "../../assets/chevron-left-svgrepo-com.svg";
import { formatDate } from "../../utils/functions";

const UserField = ({ user }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const transformRole = (name) => {
    if (typeof name !== "string") return "";
    return name.replaceAll("_", " ").toLocaleLowerCase();
  };

  return (
    <TableRow key={user.user_id}>
      <TableCell>
        <input type="checkbox" />
      </TableCell>
      <TableCell className={`${styles.tableCellItem}`}>
        <div className={`${styles.tableUserCell}`}>
          <span className={`${styles.tableUserName}`}>{user.username}</span>
          <span className={`${styles.tableUserEmail}`}>{user.email}</span>
        </div>
      </TableCell>
      <TableCell className={`${styles.tableCellItem}  ${styles.roleField}`}>
        {transformRole(user.role?.[0]?.role_name || "-")}
      </TableCell>
      <TableCell className={`truncate ${styles.tableCellItem}`}>
        {user.job_title || "-"}
      </TableCell>
      <TableCell className={`${styles.tableCellItem}`}>
        {user.department?.[0]?.department_name || "-"}
      </TableCell>
      <TableCell className={`${styles.tableCellItem}`}>
        {user.source || "-"}
      </TableCell>
      <TableCell className={`${styles.tableCellItem}`}>
        {formatDate(user.access_expiry_date) || "-"}
      </TableCell>
      <TableCell className={``}>
        <img
          height={20}
          src={optionSvg}
          className={`${styles.optionImg}`}
          onClick={handleClick}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "&.MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem>Change role</MenuItem>
          <MenuItem>Remove</MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

const UserManagementContent = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginatedUser, setPaginatedUsers] = useState([[]]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage] = useState(100);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const output = await newGet("users");
        if (output.status_code === 200) {
          const noOfRows = 100;
          const localUsers = output.result;
          setUsers(localUsers);
          const reducedUsers = localUsers.reduce((prev, curr, idx) => {
            const currentIndex = Math.floor(idx / noOfRows);
            if (!prev[currentIndex]) prev[currentIndex] = [];
            prev[currentIndex].push(curr);
            return prev;
          }, []);
          setPaginatedUsers(reducedUsers);
          setSelectedUsers(reducedUsers[0]);
          setLoading(false);
        } else {
          throw output;
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (currentPage >= paginatedUser.length) {
      setSelectedUsers(paginatedUser[paginatedUser.length - 1]);
    } else if (currentPage < 0) {
      setSelectedUsers(paginatedUser[0]);
    } else {
      setSelectedUsers(paginatedUser[currentPage]);
    }
  }, [currentPage]);

  return (
    <div className="user-management-container">
      {loading ? <div> Loading </div> : null}
      <div>Users</div>
      <div className={`${styles.paginationDiv}`}>
        <div className={`${styles.totalUsers}`}>
          Total Users: {users.length}
        </div>
        <div className={`${styles.arrowContainer}`}>
          <img
            src={arrowSvg}
            alt=""
            width={20}
            className={``}
            onClick={() => currentPage > 0 && setCurrentPage((pre) => pre - 1)}
          />
          <img
            src={arrowSvg}
            alt=""
            width={20}
            className={`${styles.rightArrow}`}
            onClick={() =>
              currentPage < paginatedUser.length - 1 &&
              setCurrentPage((pre) => pre + 1)
            }
          />
        </div>
      </div>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead className={`${styles.tableHead}`}>
            <TableRow className={`${styles.table}`}>
              <TableCell
                className={`${styles.tableHeadCellItem} ${styles.tableCellItem}`}
              >
                <input type="checkbox" />
              </TableCell>
              <TableCell
                className={`${styles.tableHeadCellItem} ${styles.tableCellItem}`}
              >
                User
              </TableCell>
              <TableCell
                className={`${styles.tableHeadCellItem} ${styles.tableCellItem}`}
              >
                Role
              </TableCell>
              <TableCell
                className={`${styles.tableHeadCellItem} ${styles.tableCellItem}`}
              >
                Job Title
              </TableCell>
              <TableCell
                className={`${styles.tableHeadCellItem} ${styles.tableCellItem}`}
              >
                Department
              </TableCell>
              <TableCell
                className={`${styles.tableHeadCellItem} ${styles.tableCellItem}`}
              >
                Account Source
              </TableCell>
              <TableCell
                className={`${styles.tableHeadCellItem} ${styles.tableCellItem}`}
              >
                Access Expiry Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedUsers.map((user) => (
              <UserField key={user.user_id} user={user} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UserManagementContent;
