import Swal from "sweetalert2";
import "./alertService.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./toastStyles.css"; // Import custom CSS
import ErrorIcon from "../../../assets/newicons/Toast_error.svg";
import WarningIcon from "../../../assets/newicons/warning_new.svg";
import SuccessIcon from "../../../assets/newicons/Toast_sucess.svg"; // ✅ Added success icon
import DefaultIcon from "../../../assets/newicons/Toast_sucess.svg";
import Close from "../../../assets/newicons/toast_close.svg"; // ✅ Close button icon

export const showAlert = (message, type) => {
  let icon;

  // Assign icon based on type
  if (type === "error") {
    icon = ErrorIcon;
  } else if (type === "warning") {
    icon = WarningIcon;
  } else if (type === "success") {
    icon = SuccessIcon; // ✅ Success icon added
  } else {
    icon = DefaultIcon; // Fallback default icon
  }

  const config = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
    className: `custom-toast ${type}`,
    bodyClassName: "custom-toast-body",
    progressClassName: "custom-toast-progress",
    closeButton: () => (
      <div className="toast-close">
        <img src={Close} alt="Close" width="16" height="16" />
      </div>
    ), // ✅ Close button included for all types
  };

  toast(
    <div style={{ display: "flex", alignItems: "center" }}>
      <div className="toast-icon">
        <img src={icon} alt="Icon" width="20" height="20" />
      </div>
      <div className="custom-toast-body">{message}</div>
    </div>,
    config
  );
};

export const showConfirmationDialog = async (
  title,
  description,
  cancel,
  confirm
) => {
  try {
    const result = await Swal.fire({
      title: `<div style="
                        text-align: left !important;
                font-family: 'Noto Sans', sans-serif;
                        font-weight: 700;
                font-size: 20px;
                line-height: 28px;
                        letter-spacing: 0%;
                    ">${title}</div>`, // Styled only the title
      html: `<div style="
                        text-align: left !important; 
                        margin-top: 5px;
                font-size: 16px;
                        font-weight: 400;
                        font-family: 'Noto Sans', sans-serif;
                    ">${description}</div>`, // Styled only the description
      showCancelButton: true,
      confirmButtonColor: "#82b440",
      cancelButtonColor: "#d33",
      confirmButtonText: cancel, // Swapped buttons
      cancelButtonText: confirm, // Swapped buttons
      customClass: {
        popup: "custom-swal-popup",
        actions: "custom-swal-actions",
      },
      didRender: () => {
        // Apply inline styling for the entire popup (border-radius)
        const popup = document.querySelector(".swal2-popup");
        if (popup) {
          popup.style.cssText = `
                        border-radius: 16px;
                        height: 245px !important;
                        width: 600px !important;

                    `;
        }

        // Apply inline styling for "Yes, update" button
        const cancelBtn = document.querySelector(".swal2-cancel");
        if (cancelBtn) {
          cancelBtn.style.cssText = `
                        width: 111px;
                        height: 40px;
                        padding: 10px 16px;
                        gap: 16px;
                        border-radius: 40px;
                        background: #312EFF;
                        color: #fff;
                        font-size: 14px;
                        border: none;
                        margin-right: -215px;
                        
                    `;
        }

        // Apply inline styling for "No, keep in progress" button
        const confirmBtn = document.querySelector(".swal2-confirm");
        if (confirmBtn) {
          confirmBtn.style.cssText = `
                        width: 174px;
                        height: 40px;
                        padding: 10px 16px;
                        border-radius: 40px;
                        background: #FFFFFF;
                        color: #000;
                        font-size: 14px;
                        border: 1px solid #FFFFFF;
                    `;
        }
      },
    });

    return result.dismiss === Swal.DismissReason.cancel; // Swap return value logic
  } catch (error) {
    console.error("Error in showConfirmationDialog:", error);
    return false;
  }
};

export const showConfirmationDialog2 = async (
  title,
  description,
  cancel,
  confirm
) => {
  try {
    const result = await Swal.fire({
      title: `<div style="
                        text-align: left !important;
                        font-family: 'Noto Sans', sans-serif;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 28px;
                        letter-spacing: 0%;
                    ">${title}</div>`, // Styled only the title
      html: `<div style="
                        text-align: left !important; 
                        margin-top: 5px;
                        font-size: 16px;
                        font-weight: 400;
                        font-family: 'Noto Sans', sans-serif;
                    ">${description}</div>`, // Styled only the description
      showCancelButton: true,
      confirmButtonColor: "#82b440",
      cancelButtonColor: "#d33",
      confirmButtonText: cancel, // Swapped buttons
      cancelButtonText: confirm, // Swapped buttons
      customClass: {
        popup: "custom-swal-popup",
        actions: "custom-swal-actions",
      },
      didRender: () => {
        // Apply inline styling for the entire popup (border-radius)
        const popup = document.querySelector(".swal2-popup");
        if (popup) {
          popup.style.cssText = `
                        border-radius: 16px;
                        height: 245px !important;
                        width: 600px !important;

                    `;
        }

        // Apply inline styling for "Yes, update" button
        const cancelBtn = document.querySelector(".swal2-cancel");
        if (cancelBtn) {
          cancelBtn.style.cssText = `
                        height: 40px;
                        padding: 10px 16px;
                        gap: 16px;
                        border-radius: 40px;
                        background: #312EFF;
                        color: #fff;
                        font-size: 14px;
                        border: none;
                        margin-right: -327px;
                        
                    `;
        }

        // Apply inline styling for "Cancel" button
        const confirmBtn = document.querySelector(".swal2-confirm");
        if (confirmBtn) {
          confirmBtn.style.cssText = `
                        height: 26px;
                        padding: 10px 16px;
                        border-radius: 40px;
                        background: #FFFFFF;
                        color: #000;
                        font-size: 14px;
                        border: 1px solid #FFFFFF;
                    `;
        }
      },
    });

    return result.dismiss === Swal.DismissReason.cancel; // Swap return value logic
  } catch (error) {
    console.error("Error in showConfirmationDialog:", error);
    return false;
  }
};

export const saveConfirmationDialog = async (
  title,
  description,
  cancel,
  confirm
) => {
  try {
    const result = await Swal.fire({
      title: `<div style="
                font-family: 'Noto Sans', sans-serif;
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                color: #000000;
                text-align: left;
                margin-bottom: 8px;
            ">${title}</div>`,
      html: `<div style="
                font-family: 'Noto Sans', sans-serif;
                font-size: 16px;
                line-height: 24px;
                color: #666666;
                text-align: left;
                margin-bottom: 24px;
            ">${description}</div>`,
      showCancelButton: true,
      confirmButtonColor: "#312EFF",
      cancelButtonColor: "#FFFFFF",
      confirmButtonText: confirm,
      cancelButtonText: cancel,
      customClass: {
        popup: "custom-swal-popup",
        actions: "custom-swal-actions",
        confirmButton: "custom-confirm-button",
        cancelButton: "custom-cancel-button",
      },
      didRender: () => {
        // Style the popup container
        const popup = document.querySelector(".swal2-popup");
        if (popup) {
          popup.style.cssText = `
                        border-radius: 16px;
                        padding: 24px;
                        max-width: 480px;
                        width: 100%;
                    `;
        }

        // Style the actions container
        const actions = document.querySelector(".swal2-actions");
        if (actions) {
          actions.style.cssText = `
                        display: flex;
                        justify-content: flex-end;
                        gap: 16px;
                        margin-top: 32px;
                    `;
        }

        // Style the confirm button
        const confirmBtn = document.querySelector(".swal2-confirm");
        if (confirmBtn) {
          confirmBtn.style.cssText = `
                        background: #312EFF !important;
                        color: #FFFFFF !important;
                        border-radius: 40px !important;
                        padding: 8px 24px !important;
                        font-size: 14px !important;
                        font-weight: 500 !important;
                        min-width: 120px !important;
                        border: none !important;
                        box-shadow: none !important;
                    `;
        }

        // Style the cancel button
        const cancelBtn = document.querySelector(".swal2-cancel");
        if (cancelBtn) {
          cancelBtn.style.cssText = `
                        background: #FFFFFF !important;
                        color: #000000 !important;
                        border: 1px solid #E0E0E0 !important;
                        border-radius: 40px !important;
                        padding: 8px 24px !important;
                        font-size: 14px !important;
                        font-weight: 500 !important;
                        min-width: 120px !important;
                        box-shadow: none !important;
                    `;
        }
      },
    });

    return result;
  } catch (error) {
    console.error("Error in showConfirmationDialog:", error);
    return false;
  }
};
