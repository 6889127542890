import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ProjectIcon } from "../../../assets/icons/projectIcon.svg";
import { ReactComponent as CheckSmall } from "../../../assets/newicons/Completed.svg";
import { ReactComponent as Approved } from "../../../assets/newicons/approved.svg";
import { ReactComponent as ApprovedNew } from "../../../assets/newicons/approvedNew.svg";
import { ReactComponent as Cancelled } from "../../../assets/newicons/cancel.svg";
import { ReactComponent as OnHoldIcon } from "../../../assets/newicons/pending.svg";
import { ReactComponent as WireFrameInProgress } from "../../../assets/newicons/pendingNew2.svg";
import { ReactComponent as WireFrameConfirmation } from "../../../assets/newicons/wireframeConfirmation.svg";
import { ReactComponent as Pending } from "../../../assets/newicons/pendingNew.svg";
import { ReactComponent as InProgress } from "../../../assets/newicons/radio_button_partial.svg";
import { ReactComponent as Completed } from "../../../assets/newicons/Completed.svg";
import { ReactComponent as DesignInProgress } from "../../../assets/newicons/designInProgress.svg";
import { ReactComponent as DesignConfirmation } from "../../../assets/newicons/DesignConfirmation2.svg";
import { ReactComponent as NotStarted } from "../../../assets/newicons/notStarted.svg";

import { useAuthContext } from "../../../context/useAuthContext";
import ProjectLoader from "../skeletonLoaders/ProjectLoader";
const ProjectTable = ({
  projectList,
  handleDelete,
  loading,
  handleUpdateProject,
  userSettings,
  channelname,
  onFormattedDataChange,
  searchTerm,
}) => {
  const { t } = useTranslation();
  const { hasPermission, selectedSite } = useAuthContext();

  const formatAssignee = (name) => {
    const usernameMatches = name?.match(/username:([^,}]+)/g);
    if (usernameMatches) {
      const usernames = usernameMatches
        ?.map((match) => match?.split(":")[1])
        .join(",");
      return usernames;
    } else {
      //console.error("No usernames found in the input string.");
      return <span style={{ color: "rgb(0, 0, 0)" }}>{t("notSpecified")}</span>;
    }
  };

  const getProjectStatus_JA = (status) => {
    const statusMappings = {
      "Not started": "notStarted",
      "In progress": "inProgress",
      Cancelled: "cancelled",
      Approved: "approved",
      Completed: "completed",
      "Wireframe in progress": "WireframeInProgress",
      "Waiting for wireframe confirmation": "WaitingForWireframeConfirmation",
      "Design in progress": "designInProgress",
      "Waiting for design confirmation": "WaitingForDesignConfirmation",
      Pending: "pending",
    };
    return t(statusMappings[status] || status);
  };

  const formatCreatedName = (name) => {
    const name1 = name?.match(/username:([^,}]+)/);
    let userName;
    if (name1) {
      userName = name1[1];
      return userName;
    }
    return userName;
  };

  const formatCreatedTimestamp = (timestamp) => {
    if (timestamp) {
      const options = { year: "numeric", month: "short", day: "2-digit" };
      const date = new Date(timestamp);
      return date.toLocaleDateString("en-US", options);
    } else {
      return <span style={{ color: "rgb(0, 0, 0)" }}>{t("notSpecified")}</span>;
    }
  };

  // to get current time line
  const formatDueDate = (createdTimestamp, finalDeliveryDate) => {
    if (!finalDeliveryDate)
      return { text: t("NOT_AVAILABLE"), color: "#A01200" };
    const deliveryDate = new Date(finalDeliveryDate);
    const currentDate = new Date();
    const diffInDays = Math.ceil(
      (deliveryDate - currentDate) / (1000 * 60 * 60 * 24)
    );
    if (diffInDays > 0) {
      return { text: t("dueInDays", { days: diffInDays }), color: "#000" };
    } else if (diffInDays === 0) {
      return { text: t("dueToday"), color: "#000" };
    } else {
      return { text: t("dueDateExpired"), color: "#A01200" };
    }
  };

  const highlightText = (text, searchTerm) => {
    if (!text || !searchTerm?.trim()) return text;

    const regex = new RegExp(`(${searchTerm})`, "gi");

    return text.split(regex).map((part, index) =>
      part.toLowerCase() === searchTerm?.toLowerCase() ? (
        <span key={index} className="highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const makeSiteObject = (str) => {
    if (!str) {
      return null;
    }
    const jsonString = str?.replace(/(\w+):([^,}]+)/g, (match, key, value) => {
      const formattedValue = isNaN(value)
        ? `"${value?.trim()}"`
        : value?.trim();
      return `"${key}":${formattedValue}`;
    });
    return JSON.parse(jsonString).site_name;
  };

  const formattedData =
    selectedSite?.site_name === "All"
      ? projectList
      : projectList?.filter((project) => {
          return (
            makeSiteObject(project.channel_name) === selectedSite?.site_name
          );
        });

  const formatKey = (key) => {
    if (!key) return "";
    return key
      .replace(/([A-Z]+)([A-Z][a-z])/g, "$1 $2") // Add space before a new word
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space in camelCase
      .replace(/\s+/g, " ") // Ensure single spaces
      .trim() // Remove extra spaces
      .toUpperCase(); // Convert to uppercase
  };

  useEffect(() => {
    onFormattedDataChange(formattedData);
  }, [formattedData, onFormattedDataChange]);
  return (
    <>
      {/* Inline CSS for adjusting scrollbar width */}
      <style>
        {`
          .tabel_border_bottam::-webkit-scrollbar {
            height: 5px; /* Adjust the horizontal scrollbar height */
          }
  
          .tabel_border_bottam::-webkit-scrollbar-thumb {
            background-color: #888; /* Scrollbar color */
            border-radius: 4px;
          }
  
          .tabel_border_bottam::-webkit-scrollbar-track {
            background:rgb(0, 0, 0); /* Scrollbar track color */
          }
        `}
      </style>

      <div
        className="tabel_border_bottam"
        style={{
          maxHeight: "490px",
          overflowY: "auto",
          overflowX: "auto", // Ensures horizontal scrollbar appears
          width: "100%", // Ensures full width visibility
          whiteSpace: "nowrap", // Prevents table from wrapping content
        }}
      >
        <table
          className="table table-responsive"
          style={{ width: "100%", minWidth: "100%" }}
        >
          <thead className="sticky_top">
            <tr>
              <th>{formatKey(t("project"))}</th>
              <th>{formatKey(t("client"))}</th>
              <th>{formatKey(t("createdBy"))}</th>
              <th>{formatKey(t("assignee"))}</th>
              <th>{formatKey(t("status"))}</th>
              <th>{formatKey(t("createdDate"))}</th>
              <th>{formatKey(t("designSubmissionDate"))}</th>
              <th>{formatKey(t("implementationDate"))}</th>
              <th>{formatKey(t("finalDeliveryDate"))}</th>
              <th>{formatKey(t("role"))}</th>
              <th></th>
            </tr>
          </thead>

          {loading ? (
            <tbody>
              {Array.from({ length: 5 }).map((_, i) => (
                <tr key={i} className="border-bottom">
                  <td className="text-center align-middle border-0">
                    <div className="w-75">
                      <ProjectLoader />
                    </div>
                    <ProjectLoader />
                  </td>
                  {Array.from({ length: 9 }).map((_, index) => (
                    <td
                      key={index}
                      className="text-center align-middle border-0"
                    >
                      <ProjectLoader />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          ) : formattedData && formattedData?.length > 0 ? (
            <tbody>
              {[...formattedData]
                .sort(
                  (a, b) =>
                    new Date(b.created_timestamp) -
                    new Date(a.created_timestamp)
                )
                .map(
                  (project, i) =>
                    project?.channel_name !== null && (
                      <tr
                        key={i}
                        className="cursor_pointer"
                        onClick={() =>
                          handleUpdateProject(
                            project?.project_id,
                            project?.channel_name,
                            project?.shared_with_client,
                            project?.user_role
                          )
                        }
                      >
                        <th>
                          <span className="project_name_div">
                            <span className="projectIcon">
                              <ProjectIcon />
                            </span>
                            <span className="projectCont">
                              <span
                                className="name"
                                title={project?.project_name}
                              >
                                {highlightText(
                                  project?.project_name,
                                  searchTerm
                                )}
                              </span>
                              <span
                                className="description"
                                title={project?.project_desc}
                              >
                                {t(project?.project_desc)}
                              </span>
                            </span>
                          </span>
                        </th>
                        <td
                          className="tabel-fild"
                          title={makeSiteObject(project?.channel_name)}
                        >
                          {makeSiteObject(project?.channel_name)}
                        </td>
                        <td
                          className="tabel-fild"
                          title={formatCreatedName(project?.created_username)}
                        >
                          {formatCreatedName(project?.created_username)}
                        </td>
                        <td
                          className="tabel-fild"
                          title={
                            project?.assign_to_user_name === null
                              ? t("notSpecified")
                              : formatAssignee(project?.assign_to_user_name)
                          }
                        >
                          {formatAssignee(project?.assign_to_user_name)}
                        </td>
                        <td>
                          <span
                            className="project-status"
                            title={t(project?.status.replaceAll(" ", "_"))}
                          >
                            {project?.status === "Not started" ? (
                              <NotStarted />
                            ) : project?.status === "Wireframe in progress" ? (
                              <WireFrameInProgress />
                            ) : project?.status ===
                              "Waiting for wireframe confirmation" ? (
                              <WireFrameConfirmation />
                            ) : project?.status === "Design in progress" ? (
                              <DesignInProgress />
                            ) : project?.status ===
                              "Waiting for design confirmation" ? (
                              <DesignConfirmation />
                            ) : project?.status === "In progress" ? (
                              <InProgress />
                            ) : project?.status === "Approved" ? (
                              <Approved />
                            ) : project?.status === "Completed" ? (
                              <CheckSmall />
                            ) : project?.status === "Pending" ? (
                              <Pending />
                            ) : project?.status === "Cancelled" ? (
                              <Cancelled />
                            ) : (
                              <CheckSmall />
                            )}

                            {t(project?.status.replaceAll(" ", "_"))}
                          </span>
                        </td>
                        <td
                          className="tabel-fild"
                          title={formatCreatedTimestamp(
                            project?.created_timestamp
                          )}
                        >
                          {formatCreatedTimestamp(project?.created_timestamp)}
                        </td>
                        <td
                          className="tabel-fild"
                          title={
                            project?.design_submission_due === null
                              ? t("notSpecified")
                              : formatCreatedTimestamp(
                                  project?.design_submission_due
                                )
                          }
                        >
                          {formatCreatedTimestamp(
                            project?.design_submission_due
                          )}
                        </td>
                        <td
                          className="tabel-fild"
                          title={
                            project?.implementation_date === null
                              ? t("notSpecified")
                              : formatCreatedTimestamp(
                                  project?.implementation_date
                                )
                          }
                        >
                          {formatCreatedTimestamp(project?.implementation_date)}
                        </td>
                        <td
                          className="tabel-fild"
                          title={
                            project?.final_delivery_date === null
                              ? t("notSpecified")
                              : formatCreatedTimestamp(
                                  project?.final_delivery_date
                                )
                          }
                        >
                          {formatCreatedTimestamp(project?.final_delivery_date)}
                        </td>
                        <td className="tabel-fild" title={project?.user_role}>
                          {project?.user_role}
                        </td>
                      </tr>
                    )
                )}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={8} className="text-center pt-5 border-0">
                  {t("noRecordsFound")}
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};

export default ProjectTable;
