import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { newGet, newPost } from "../../../API/axios";
import { showAlert } from "./AlertService";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { useForm, Controller } from "react-hook-form";

const UserCreateForm = ({ roles, departments }) => {
  const { t, i18n } = useTranslation();
  const languages = ["English", "Japanese"];

  // Initialize useForm hook
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      username: "",
      email: "",
      hashed_password: "12345@12345",
      phone_number: "",
      job_title: "",
      role_id: "",
      department_id: "",
      is_active: false,
      access_expiry_date: "",
      pref_language: "English",
      created_by: "admin",
      user_image_url: "",
    },
  });

  const handleCreateUser = async (data) => {
    // Prepare data for submission
    const submissionData = {
      ...data,
      phone_number: parseInt(data.phone_number),
      role_id: parseInt(data.role_id),
      department_id: parseInt(data.department_id),
      user_image_url:
        "https://images.pexels.com/photos/17490713/pexels-photo-17490713/free-photo-of-aerial-view-of-apartment-blocks-in-city-under-blue-sky.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    };

    await newPost("users", submissionData).then((response) => {
      if (response && response.status_code === 200) {
        closeCreateUserModel();
        // showAlert('User Created Successfully', 'success');
        showAlert(i18n.t(data?.message[0]?.property_message), "success");

        const closeButton = document.querySelector(
          "#create_profile [data-bs-dismiss='modal']"
        );
        if (closeButton) {
          closeButton.click();
        }
      }
    });
  };

  const closeCreateUserModel = () => {
    const create_profile = document.getElementById("create_profile");
    if (create_profile) {
      const handleModalClose = () => {
        reset(); // Reset form fields
      };
      create_profile.addEventListener("hidden.bs.modal", handleModalClose);

      return () => {
        create_profile.removeEventListener("hidden.bs.modal", handleModalClose);
      };
    }
  };

  useEffect(() => {
    closeCreateUserModel();
  }, []);

  return (
    <div>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <form
            className="overflow-auto"
            onSubmit={handleSubmit(handleCreateUser)}
          >
            <div className="modal-body pb-0">
              <div className="edit-default-role">{t("createUser")}</div>

              <div className="role my-3">
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    {t("username")}
                  </label>
                  <input
                    {...register("username", { required: "Name is required." })}
                    type="text"
                    className={`form-control ${errors.username ? "is-invalid" : ""}`}
                    id="username"
                    placeholder="Enter name"
                  />
                  {errors.username && (
                    <div className="invalid-feedback">
                      {errors.username.message}
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    {t("email")}
                  </label>
                  <input
                    {...register("email", {
                      required: "Email address is required.",
                    })}
                    type="email"
                    className={`form-control ${errors.email ? "is-invalid" : ""}`}
                    id="email"
                    placeholder="Enter email address"
                  />
                  {errors.email && (
                    <div className="invalid-feedback">
                      {errors.email.message}
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="job_title" className="form-label">
                    {t("jobTitle")}
                  </label>
                  <input
                    {...register("job_title", {
                      required: "Job title is required.",
                    })}
                    type="text"
                    className={`form-control ${errors.job_title ? "is-invalid" : ""}`}
                    id="job_title"
                    placeholder="Enter job title"
                  />
                  {errors.job_title && (
                    <div className="invalid-feedback">
                      {errors.job_title.message}
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="phone_number" className="form-label">
                    {t("phoneNumber")}
                  </label>
                  <input
                    {...register("phone_number", {
                      required: "Phone number is required.",
                      pattern: {
                        value: /^\d{12}$/,
                        message: "Phone number must be exactly 12 digits.",
                      },
                    })}
                    type="text"
                    className={`form-control ${errors.phone_number ? "is-invalid" : ""}`}
                    id="phone_number"
                    placeholder="Enter 12-digit phone number"
                  />
                  {errors.phone_number && (
                    <div className="invalid-feedback">
                      {errors.phone_number.message}
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="role_id" className="form-label">
                    {t("role")}
                  </label>
                  <select
                    {...register("role_id", { required: "Role is required." })}
                    className={`form-select ${errors.role_id ? "is-invalid" : ""}`}
                    id="role_id"
                  >
                    <option value="">Select Role</option>
                    {roles &&
                      roles.map((role) => (
                        <option key={role.role_id} value={role.role_id}>
                          {t(role?.role_name)}
                        </option>
                      ))}
                  </select>
                  {errors.role_id && (
                    <div className="invalid-feedback">
                      {errors.role_id.message}
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="department_id" className="form-label">
                    {t("department")}
                  </label>
                  <select
                    {...register("department_id", {
                      required: "Department is required.",
                    })}
                    className={`form-select ${errors.department_id ? "is-invalid" : ""}`}
                    id="department_id"
                  >
                    <option value="">Select Department</option>
                    {departments &&
                      departments.map((dept) => (
                        <option
                          key={dept.department_id}
                          value={dept.department_id}
                        >
                          {dept.department_name}
                        </option>
                      ))}
                  </select>
                  {errors.department_id && (
                    <div className="invalid-feedback">
                      {errors.department_id.message}
                    </div>
                  )}
                </div>

                <div className="form-check mb-3">
                  <input
                    {...register("is_active")}
                    className="form-check-input"
                    type="checkbox"
                    id="is_active"
                  />
                  <label className="form-check-label" htmlFor="is_active">
                    {t("isActive")}
                  </label>
                </div>

                <div className="mb-3">
                  <label htmlFor="access_expiry_date" className="form-label">
                    {t("accessExpiryDate")}
                  </label>
                  <div className="position-relative" id="access_expiry_date">
                    <Controller
                      control={control}
                      name="access_expiry_date"
                      rules={{
                        required: "Access expiry date is required.",
                        validate: (value) => {
                          const today = new Date();
                          today.setHours(0, 0, 0, 0);
                          const expiryDate = new Date(value);
                          expiryDate.setHours(0, 0, 0, 0);
                          return (
                            expiryDate >= today ||
                            "Expiry date cannot be in the past."
                          );
                        },
                      }}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          placeholderText={t("mm/dd/yyyy")}
                          className={`input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-2 from-input ${
                            errors.access_expiry_date ? "is-invalid" : ""
                          }`}
                          dateFormat="MM/dd/yyyy"
                          minDate={new Date()}
                        />
                      )}
                    />
                  </div>
                  {errors.access_expiry_date && (
                    <div className="invalid-feedback">
                      {errors.access_expiry_date.message}
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="pref_language" className="form-label">
                    {t("preferredLanguage")}
                  </label>
                  <select
                    {...register("pref_language", {
                      required: "Preferred language is required.",
                    })}
                    className={`form-select ${errors.pref_language ? "is-invalid" : ""}`}
                    id="pref_language"
                  >
                    <option value="">Select Language</option>
                    {languages.map((lang) => (
                      <option key={lang} value={lang}>
                        {lang}
                      </option>
                    ))}
                  </select>
                  {errors.pref_language && (
                    <div className="invalid-feedback">
                      {errors.pref_language.message}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end gap-4 p-3 pt-0">
              <button
                type="button"
                className="cancel_modal_footer"
                data-bs-dismiss="modal"
              >
                {t("cancel")}
              </button>
              <button type="submit" className="send_modal_footer">
                {t("create")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserCreateForm;
