export const UserRole = Object.freeze({
  SYSTEM_ADMIN: 1,
  SUPER_ADMIN: 2,
  CHANNEL_MANAGER: 3,
  CLIENT_MANAGER: 4,
  CHANNEL_USER: 5,
  CLIENT_USER: 6,
});

export const ProjectStatus = Object.freeze({
  NOT_STARTED: 1,
  IN_PROGRESS: 2,
  CANCELLED: 3,
  APPROVED: 4,
  COMPLETED: 5,
});

export const ProjectUserRole = Object.freeze({
  CREATOR: "creator",
  APPROVER: "Approver",
  EXECUTOR: "Executor",
  OBSERVER: "Observer",
});

export const LanguageEnum = Object.freeze({
  ENGLISH: "en",
  JAPANESE: "ja",
});

export const TimelineMarkStatusKey = Object.freeze({
  "Start Date": "start_status",
  "Design Submission Date": "design_submission_status",
  "Client Feedback Date": "client_feedback_status",
  "Implementation Date": "implementation_status",
  "Final Delivery Date": "final_delivery_status",
});
