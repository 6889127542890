import React, { useState, useEffect, useRef, useContext } from "react";

import styles from "./image-component.module.css";

import plusIcon from "../../../assets/newicons/add.svg";
import removeIcon from "../../../assets/newicons/remove-icon.svg";
import aiIcon from "../../../assets/newicons/ai-white-icon.svg";
import smallTabImage from "../../../assets/newimages/tabs-image.png";
import percentage from "../../../assets/newicons/percentage-icon.svg";
import opacityImage from "../../../assets/newicons/opacity-icon.svg";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import newDesign from "../../../assets/newimages/new-design-image.png";
import refresh from "../../../assets/newicons/refresh.svg";
import aiIconBlack from "../../../assets/newicons/ai-dark-icon.svg";
import upload from "../../../assets/newicons/upload.svg";
import boldIcon from "../../../assets/newicons/bold-icon.svg";
import italicIcon from "../../../assets/newicons/italic-icon.svg";
import underline from "../../../assets/newicons/underlined-icon.svg";
import alignManu from "../../../assets/newicons/format-align.svg";
import formatList from "../../../assets/newicons/format-list.svg";
import formatLine from "../../../assets/newicons/format-line.svg";
import copyIcon from "../../../assets/newicons/copy-icon.svg";
import pastIcon from "../../../assets/newicons/past-icon.svg";
import reArrangeIcon from "../../../assets/newicons/sorting.png";
import duplicateIocn from "../../../assets/newicons/duplicate-iocn.svg";
import deleteIocn from "../../../assets/newicons/delete.svg";
import downloadIcon from "../../../assets/newicons/download.svg";
import WebFont from "webfontloader";
import KonvaCanvas from "../../../components/KonvaCanvas";
import { useKonvaContext } from "../../../context/useKonvaContext";
import roundedCorner from "../../../assets/newicons/rounded-corner-icon.svg";
import revartIcon from "../../../assets/newicons/revert-icon.svg";
import notrevartIcon from "../../../assets/newicons/not-revart-icon.svg";
import roteteImage from "../../../assets/newicons/rotete-center.svg";
import leftroteteImage from "../../../assets/newicons/left-rotete-icon.svg";
import { Menu, styled, Tooltip, tooltipClasses } from "@mui/material";
import { alpha } from "@mui/material/styles";
import {
  initializeCanvas,
  removeSheet,
  updateSheetStatus,
  updateElement,
  updateSheet,
  bulkUpdateSheets,
  updateGroup,
  removeElement,
  addElement,
  resetKonvaState,
} from "../../../store/konvaSlice";
import { useDispatch, useSelector } from "react-redux";
import MagicBrush from "./MagicBrush";
import {
  imageGet,
  newGet,
  newGet_V3,
  newPost_V3,
  newPut,
  newPut_V3,
  newPut_V4,
} from "../../../API/axios";
import { useReferenceContext } from "../../../context/useReferenceContext";

import { useTranslation } from "react-i18next";

import ImageContext from "./ImageContext";
import ColorPicker from "./ColorPicker";
import "./imageComponent.scss";
import UploadComponent from "../../../components/Shared/UploadComponent";
import visibilityIcon from "../../../assets/newicons/visibility.svg";
import visibilityOffIcon from "../../../assets/newicons/visibility_off.svg";
import { showAlert } from "../../../views/Manager/component/AlertService";
import { useFlag } from "@unleash/proxy-client-react";

const ImageComponent = ({
  selectedImages,
  typeChange,
  setSelectedImages,
  setTypeChange,
  handleExport,
  setShowCommentSection,
  showCommentSection,
  projectStatus,
}) => {
  const dispatch = useDispatch();
  const canvas = useSelector((state) => state.konva.canvas);
  const [scale, setScale] = useState(1);
  const [reload, setReload] = useState(0);
  const { setResetPopup, setImages } = useContext(ImageContext);

  const [sheetName, setsheetName] = useState("Sheet 1");
  const [isEditing, setIsEditing] = useState(false);
  const editorRef = useRef(null);
  const [layerId, setLayerId] = useState(null);
  const { t } = useTranslation();
  const [returnComment, setReturnComment] = useState("");
  const { designId, projectDetails } = useReferenceContext();
  const {
    setSheetCount,
    selectedSheetId,
    showSideModal,
    selectedId,
    selectedSheet,
    sheetListLoading,
    setSheetListLoading,
    selectedSheetElement,
    setSelectedSheetElement,
    setSelectedSheet,
    isSelecting,
    setIsSelecting,
    setSelectedSheetIds,
    selectedSheetIds,
    setSelectedSheetLayerId,
  } = useKonvaContext();

  const modalRef = useRef(null);
  const [currentSheetId, setCurrentSheetId] = useState(0);
  const [showImageDetails, setShowImageDetails] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectionBox, setSelectionBox] = useState(null);
  const [hasCopied, setHasCopied] = useState(false);
  const [details, setDetails] = useState({
    corner_radius: selectedSheetElement?.corner_radius || 0,
    blend_mode: selectedSheetElement?.blend_mode || "source-over",
    opacity: selectedSheetElement?.opacity || 100,
    crop_scale: selectedSheetElement?.crop_scale || "fit",
    color: selectedSheetElement?.color || "#ffffff",
  });

  const [imageAlignDetails, setImageAlignDetails] = useState({});

  const [uploadPopUpOpen, setUploadPopupOpen] = useState(false);

  const EnhanceWithMicoFlag = useFlag("EnhanceWithMico");
  const ImageLayersFlag = useFlag("ImageLayers");
  const LayoutCompositionFlag = useFlag("LayoutComposition");


  const {
    fontSize,
    setFontSize,
    fontFamily,
    setFontFamily,
    color,
    setColor,
    menuPosition,
    contextMenuVisible,
    setContextMenuVisible,
    newTextValue,
    setNewTextValue,
    inputPosition,
    showTextOptionsModal,
    setShowTextOptionsModal,
    alignmentDetails,
    setAlignDetails,
    fonts,
    selectedLayerId,
    setTextStyle,
    textStyle,
    setCornerRadiusValue,
    blendModes,
    setSelectedBlendMode,
    setOpacity,
    setSelectedImageId,
    setSelectedId,
    setShowSideModal,
    stageRef, // Add this to the destructured values,
  } = useKonvaContext();

  const loadGoogleFonts = () => {
    WebFont.load({
      google: {
        families: fonts.map((font) => font.family),
      },
    });
  };

  useEffect(() => {
    if (selectedSheetElement?.id && alignmentDetails[selectedSheetElement.id]) {
      setImageAlignDetails(alignmentDetails[selectedSheetElement.id]);
    }
  }, [selectedSheetElement?.id]);

  useEffect(() => {
    if (selectedSheetElement) {
      setFontFamily(selectedSheetElement.fontFamily);
      setFontSize(selectedSheetElement.fontSize);
      setColor(selectedSheetElement.fill);
      setOpacity(selectedSheetElement.opacity);
      setCornerRadiusValue(selectedSheetElement.corner_radius);
      setSelectedBlendMode(selectedSheetElement.blend_mode);
      setDetails({
        corner_radius: selectedSheetElement?.corner_radius || 0,
        blend_mode: selectedSheetElement?.blend_mode || "source-over",
        opacity: selectedSheetElement?.opacity || 100,
        crop_scale: selectedSheetElement?.crop_scale || "fit",
        color: selectedSheetElement?.color || "#ffffff",
      });
    }
  }, [selectedSheetElement, selectedSheet]);

  const getSheetList = async () => {
    // setSheetListLoading(true);
    const response = await newGet_V3(
      `sheet/getSheetList?design_id=${designId}`
    );
    // setSheetCount(response?.result?.length);
    dispatch(updateGroup({ newGroup: response?.result || [] }));
    return response?.result || [];
  };

  useEffect(() => {
    loadGoogleFonts();

    getDesignDetails(designId);
  }, []);

  const getDesignDetails = async (designId, loading = true) => {
    if (loading) {
      setSheetListLoading(true);
    }
    const sheets = await getSheetList();
    console.log(sheets);

    const urlParams = new URLSearchParams(window.location.search);
    const design_id = urlParams.get("design_id");
    try {
      const values = await Promise.allSettled(
        sheets.map((sheet) => {
          return new Promise((resolve, reject) => {
            newGet(`sheet/getSheetDetail?sheet_id=${sheet.sheet_id}`)
              .then((res) => {
                resolve({
                  ...res.result,
                  sheet_id: sheet.sheet_id,
                });
              })
              .catch((error) => reject(error));
          });
        })
      );
      console.log(values);

      const designData = await newGet_V3(
        `design/getDesignDetail?design_id=${design_id || designId}`
      );
      console.log(designData);

      if ([200, 201].includes(designData?.status_code)) {
        setSheetCount(designData?.result?.sheets.length);

        // Find the layer ID from sheets that matches selectedSheetElement.id
        let layerId;
        if (selectedSheetElement) {
          for (const sheet of designData?.result?.sheets) {
            const element = sheet.layers.find((layer) => {
              return layer.id === selectedSheetElement.id;
            });
            if (element) {
              setSelectedSheetElement(element);
              setSelectedSheet(sheet); // Set the selected sheet when element is found
            }
          }
        }
        console.log("designn detail update", designData.result);
        dispatch(initializeCanvas(designData.result));

        if (selectedSheetElement) {
          // Perform actions based on the selected layer
          console.log("Selected Layer:", selectedSheetElement);
          // You can dispatch actions or update the canvas here
        }
        setSheetListLoading(false);

        setTimeout(() => {
          if (!initialPositions) {
            initInitialPositions();
          }
        }, 1000);
        if (loading) {
          setSheetListLoading(false);
        }
      } else {
        dispatch(
          initializeCanvas({
            id: null,
            sheets: [],
          })
        );
      }
    } catch (error) {
      setSheetListLoading(false);
      console.log(error);
    }
  };

  const handleRewrite = () => {
    if (selectedId && selectedId.includes("text")) {
      const newProps = {
        text: newTextValue,
      };
      dispatch(
        updateElement({
          sheetId: selectedSheetId,
          elementId: selectedId,
          newProps,
        })
      );
      setShowTextOptionsModal(false);
    }
  };

  const hideContextMenu = () => {
    setContextMenuVisible(false);
  };

  const handleFontChange = (e) => {
    setFontFamily(e.target.value);
    if (selectedSheetId && selectedId) {
      dispatch(
        updateElement({
          sheetId: selectedSheetId,
          elementId: selectedId,
          newProps: {
            fontFamily: e.target.value,
          },
        })
      );
    }
  };

  const handleFontSizeChange = (e) => {
    setFontSize(e.target.value);
    if (selectedLayerId && selectedId) {
      const newProps = {
        fontSize: e.target.value,
      };
      dispatch(
        updateElement({
          layerId: selectedLayerId,
          elementId: selectedId,
          newProps,
        })
      );
    }
  };

  const handleColorChange = (color) => {
    const rgba = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    setDetails({ ...details, color: rgba });
    if (selectedId) {
      const sheetId = selectedSheetId;
      dispatch(
        updateElement({
          sheetId,
          elementId: selectedSheetElement.id,
          newProps: {
            color: rgba,
          },
        })
      );
      // dispatch(updateSheet({
      //   sheetId,
      //   updates: {
      //     backgroundColor: rgba, // Change this to backgroundColor
      //   }
      // }));
    }
  };

  const textFontStyleClick = (e, name) => {
    e.preventDefault();
    const styleMap = {
      bold: {
        prop: "fontWeight",
        activeValue: "bold",
        inactiveValue: "normal",
      },
      italic: {
        prop: "fontStyle",
        activeValue: "italic",
        inactiveValue: "normal",
      },
      underline: {
        prop: "textDecoration",
        activeValue: "underline",
        inactiveValue: "none",
      },
    };

    const { prop, activeValue, inactiveValue } = styleMap[name];

    if (selectedLayerId && selectedId) {
      const currentValue = textStyle[name];
      const newValue =
        currentValue !== activeValue ? activeValue : inactiveValue;

      const newProps = {
        [prop]: newValue,
      };

      dispatch(
        updateElement({
          layerId: selectedLayerId,
          elementId: selectedId,
          newProps,
        })
      );

      setTextStyle((prevStyle) => ({
        ...prevStyle,
        [name]: newValue,
      }));
    }
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },

    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 160,
      backgroundColor: "black",
    },
  }));
  const handleZoomIn = () => {
    const newScale = Math.min(scale + 0.1, 3); // Max zoom 300%
    setScale(newScale);
  };

  const handleZoomOut = () => {
    const newScale = Math.max(scale - 0.1, 0.1); // Min zoom 10%
    setScale(newScale);
  };

  // Handle mouse wheel zoom
  const handleWheel = (e) => {
    if (e.ctrlKey) {
      e.preventDefault();
      const delta = e.deltaY;
      setScale((prev) => {
        const newZoom =
          delta > 0
            ? Math.max(prev - 0.1, 0.1) // Zoom out
            : Math.min(prev + 0.1, 3); // Zoom in
        return Number(newZoom.toFixed(1));
      });
    }
  };

  const handleSetImageUrl = (url) => {
    setImageUrl(url);
  };

  const onHandleSelectedImage = async () => {
    try {
      setLoading(true);
      let blob;

      try {
        const response = await fetch(imageUrl);
        blob = await response.blob();
      } catch (error) {
        blob = await imageGet("download_image", { image_url: imageUrl });
      }

      const reader = new FileReader();
      reader.onloadend = async () => {
        let base64Image = reader.result; // This is the base64 string

        // Remove the prefix
        base64Image = base64Image.replace(/^data:image\/jpeg;base64,/, ""); // Remove the prefix

        const file = new File([blob], `image_${Date.now()}.jpg`, {
          type: blob.type,
        });
        const objectURL = URL.createObjectURL(file);
        file.objectURL = objectURL;

        console.log("Image URL:", imageUrl);
        console.log("Payload:", {
          layer_id: selectedSheetElement.id,
          layer_type: selectedSheetElement.image_type,
          update_column: { image_data: file },
        });

        console.log("Base64 Image before API call:", base64Image); // Log the base64 image
        const replacedImage = await newPut_V3(`layer/changeLayerValue`, {
          layer_id: selectedSheetElement.id,
          layer_type: selectedSheetElement.image_type,
          sheet_id: selectedSheet.id,
          updates: { url_path: imageUrl, image_data: base64Image },
        });

        if ([201, 200].includes(replacedImage.status_code)) {
          // await getDesignDetails();
          await getSheetDetails(selectedSheet.id);
          setLoading(false);
          const modalElement = document.getElementById("search_modal");
          const modalInstance =
            window.bootstrap.Modal.getInstance(modalElement);
          modalInstance.hide();
          setShowImageDetails(false);
        }
      };

      reader.readAsDataURL(blob); // Convert blob to base64
    } catch (error) {
      console.error(error);
    }
  };

  // Add wheel event listener
  useEffect(() => {
    const editor = editorRef.current;
    if (editor) {
      editor.addEventListener("wheel", handleWheel, { passive: false });
    }
    return () => {
      if (editor) {
        editor.removeEventListener("wheel", handleWheel);
      }
      dispatch(resetKonvaState({}));
    };
  }, []);

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 250,
      color: "rgb(55, 65, 81)",
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        fontWeight: 500,
        fontSize: 14,
        "& .MuiSvgIcon-root": {
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(0.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[300],
      }),
    },
  }));

  const handleDoubleClick = () => {
    setIsEditing(true); // Enable editing mode
  };

  const handleChange = (event) => {
    setsheetName(event.target.value); // Update text as user types
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setIsEditing(false); // Save and exit editing mode on Enter
    }
  };

  const handleBlur = () => {
    setIsEditing(false); // Save and exit editing mode on blur
  };

  const handleRemoveSheet = async (sheetId) => {
    try {
      const response = await newPut("sheet/delete", {
        sheet_id: sheetId,
      });

      if (response?.status_code === 200) {
        dispatch(removeSheet(sheetId));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const lockNRequestApproval = async () => {
    try {
      const sheetIds =
        selectedSheetIds?.length > 0 ? selectedSheetIds : [selectedSheetId];

      if (!sheetIds || sheetIds.length === 0) {
        console.error("No sheets selected");
        return;
      }

      const response = await newPut_V4(`approval/sentForApproval`, {
        sheet_ids: sheetIds,
      });

      if ([200, 201, 2011].includes(response?.status_code)) {
        sheetIds.forEach((sheetId) => {
          dispatch(
            updateSheetStatus({
              sheetId,
              status: 2, // 2 for awaiting approval status
            })
          );
        });
      }
    } catch (error) {
      console.error("Error in lockNRequestApproval:", error);
    }
  };

  const handleDownloadImage = async (element) => {
    try {
      const base64Image = `data:image/png;base64,${element.image_data.data}`;
      const a = document.createElement("a");
      a.href = base64Image;
      a.download = `${element.name}.jpg`;
      a.click();
    } catch (ex) {
      console.log("error", ex);
    }
  };

  const cancelRequestApproval = async () => {
    try {
      const response = await newPut_V4(`approval/revoke`, {
        sheet_ids: [selectedSheetId],
      });

      if ([200, 201, 2011].includes(response?.status_code)) {
        // This will update status and automatically unlock the sheet
        dispatch(
          updateSheetStatus({
            sheetId: selectedSheetId,
            status: 1, // 1 for draft status
          })
        );
      }
    } catch (error) {
      console.error("Error in cancelRequestApproval:", error);
    }
  };

  const handleReturnSheet = async () => {
    try {
      const response = await newPut(`approval/return`, {
        sheet_ids: [currentSheetId],
        return_reason: returnComment,
      });

      if ([200, 201].includes(response?.status_code)) {
        dispatch(
          updateSheetStatus({
            sheetId: currentSheetId,
            status: 4, // 4 for returned status
          })
        );
        // Close modal or reset comment if needed
        setReturnComment("");
      }
    } catch (error) {
      console.error("Error in handleReturnSheet:", error);
    }
  };

  // Handle blend mode changes
  const handleBlendModeChange = (e) => {
    const value = e.target.value;
    setSelectedBlendMode(value);
    if (selectedId) {
      const element_id = selectedId.includes("shape")
        ? selectedId
        : Number(selectedId.split("_")[1]);
      const sheet = canvas.layers[0].groups.find((group) =>
        group.elements.some((element) => element.id === element_id)
      );

      if (sheet) {
        const newProps = {
          blend_mode: value,
        };
        setDetails({ ...details, blend_mode: value });
        dispatch(
          updateElement({
            sheetId: sheet.id,
            elementId: element_id,
            newProps,
          })
        );
      }
    }
  };

  // Handle corner radius changes
  const handleCornerRadius = (e) => {
    const value = e.target.value;
    setCornerRadiusValue(value);
    setHasChanges(true); // Mark as changed
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (
      (regex.test(value) || value === "") &&
      Number(value) >= 0 &&
      Number(value) <= 100
    ) {
      setCornerRadiusValue(parseFloat(value));
      if (selectedId) {
        const element_id = selectedId.includes("shape")
          ? selectedId
          : Number(selectedId.split("_")[1]);
        const sheet = canvas.layers[0].groups.find((group) =>
          group.elements.some((element) => element.id === element_id)
        );
        if (sheet) {
          const newProps = {
            corner_radius: parseFloat(value), // Single value for all corners
          };
          setDetails({ ...details, corner_radius: parseFloat(value) });
          dispatch(
            updateElement({
              sheetId: sheet.id,
              elementId: element_id,
              newProps,
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    if (selectedSheetElement?.id)
      setAlignDetails((pre) => ({
        ...pre,
        [selectedSheetElement.id]: imageAlignDetails,
      }));
  }, [imageAlignDetails]);

  // Handle rotation
  const handleRotation = (direction) => {
    if (is_editing_blocked) return;
    if (selectedId) {
      const element_id = selectedId.includes("shape")
        ? selectedId
        : Number(selectedId.split("_")[1]);
      const sheet = canvas.layers[0].groups.find((group) =>
        group.elements.some((element) => element.id === element_id)
      );

      if (sheet) {
        const element = sheet.elements.find((el) => el.id === element_id);
        const currentRotation = imageAlignDetails?.rotation || 0;
        const newRotation =
          direction === "left" ? currentRotation - 90 : currentRotation + 90;

        const rotation = ((newRotation / 90) % 4) * 90;

        const newProps = {
          rotation: rotation,
        };

        setImageAlignDetails((pre) => ({
          ...pre,
          rotation,
        }));

        dispatch(
          updateElement({
            sheetId: sheet.id,
            elementId: element_id,
            newProps,
          })
        );
      }
    }
  };

  const handleFlip = (axis) => {
    if (is_editing_blocked) return;
    if (selectedId) {
      const element_id = selectedId.includes("shape")
        ? selectedId
        : Number(selectedId.split("_")[1]);
      const sheet = canvas.layers[0].groups.find((group) =>
        group.elements.some((element) => element.id === element_id)
      );

      if (sheet) {
        const element = sheet.elements.find((el) => el.id === element_id);
        // Toggle the scaleX value between 1 and -1

        const newProps = {};
        switch (axis) {
          case "x":
            const newScaleX = (imageAlignDetails.scaleX || 1) * -1;
            newProps["scaleX"] = newScaleX;
            setImageAlignDetails((pre) => ({
              ...pre,
              scaleX: newScaleX,
            }));
            break;
          case "y":
            const newScaleY = (imageAlignDetails.scaleY || 1) * -1;
            newProps["scaleY"] = newScaleY;
            setImageAlignDetails((pre) => ({
              ...pre,
              scaleY: newScaleY,
            }));
            break;
        }

        dispatch(
          updateElement({
            sheetId: sheet.id,
            elementId: element_id,
            newProps,
          })
        );
      }
    }
  };

  // Update opacity handler for images
  const handleImageOpacity = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (
      (regex.test(value) || value === "") &&
      Number(value) >= 0 &&
      Number(value) <= 100
    ) {
      setOpacity(parseFloat(value));

      if (selectedId) {
        const element_id = selectedId.includes("shape")
          ? selectedId
          : Number(selectedId.split("_")[1]);
        // Find the sheet containing the selected image
        const sheet = canvas.layers[0].groups.find((group) =>
          group.elements.some((element) => element.id === element_id)
        );

        if (sheet) {
          const newProps = {
            opacity: parseInt(value), // Convert percentage to decimal
          };
          setDetails({ ...details, opacity: parseInt(value) });
          dispatch(
            updateElement({
              sheetId: sheet.id,
              elementId: element_id,
              newProps,
            })
          );
        }
      }
    }
  };

  // Handle crop and scale changes
  const handleCropAndScale = (e) => {
    const value = e.target.value;
    if (selectedId) {
      const element_id = Number(selectedId.split("_")[1]);
      const sheet = canvas.layers[0].groups.find((group) =>
        group.elements.some((element) => element.id === element_id)
      );

      if (sheet) {
        const newProps = {
          crop_scale: value,
        };
        setDetails({ ...details, crop_scale: value });
        dispatch(
          updateElement({
            sheetId: sheet.id,
            elementId: element_id,
            newProps,
          })
        );
      }
    }
  };

  // Get the selected element from canvas state
  const getSelectedElement = () => {
    if (selectedId) {
      const element_id = Number(selectedId?.split("_")[1]);
      const sheet = canvas?.layers[0]?.groups?.find((group) =>
        group?.elements?.some((element) => element.id === element_id)
      );
      if (sheet) {
        return sheet.elements.find((el) => el.id === element_id);
      }
    }
    return null;
  };

  const selectedElement = getSelectedElement();
  const imageLink = (element, defaultImage) => {
    if (!element || !element.image_data) {
      return defaultImage;
    }

    if (element.image_data.data) {
      return element.image_data.data.match(/\.(jpg|jpeg|png|gif|svg)$/i)
        ? element.image_data.data
        : `data:image/${element.image_data.format?.toLowerCase() || "png"};base64,${element.image_data.data}`;
    }

    return defaultImage;
  };

  // const getOpacity = (element) => {
  //   return element.opacity ? element.opacity * 100 : 100;
  // }

  const modalOpen = () => {
    setResetPopup("");
    setResetPopup("reset");
  };

  const isProjectActive = () => {
    if (typeof projectStatus !== "number") return true; // Default to active if no status

    const statusId = parseInt(projectStatus);

    // Status 5 means completed/cancelled project, status 3 means inactive
    return statusId !== 5 && statusId !== 3;
  };

  const [initialPositions, setInitialPositions] = useState(null);

  // Store initial positions when component mounts or canvas updates
  const initInitialPositions = () => {
    if (canvas?.layers?.[0]?.groups.length > 0) {
      const positions = {};
      canvas.layers[0].groups.forEach((sheet) => {
        positions[sheet.id] = {
          x: sheet.position?.x || 50,
          y: sheet.position?.y || 50,
        };
      });
      setInitialPositions(positions);
    }
  };

  const handleReset = () => {
    // Reset zoom level to 1
    setScale(1);

    // Reset stage position to 0,0 (center)
    const stage = stageRef.current;
    if (stage) {
      stage.position({ x: 0, y: 0 });
    }

    // Reset all sheets to their initial positions
    if (canvas?.layers?.[0]?.groups) {
      const bulkUpdates = canvas.layers[0].groups.map((sheet) => ({
        sheetId: sheet.id,
        updates: {
          position: initialPositions[sheet.id] || { x: 50, y: 50 },
        },
      }));

      dispatch(bulkUpdateSheets(bulkUpdates));
    }
  };

  const handleReorderSheets = async () => {
    try {
      if (!canvas?.layers?.[0]?.groups?.length) return;

      setScale(1);
      const stage = stageRef.current;
      if (stage) {
        stage.position({ x: 0, y: 0 });
        stage.batchDraw();
      }

      const groups = canvas.layers[0].groups;
      const sheetIds = [];
      const positionXs = [];
      const positionYs = [];
      const Y_POSITION = 50;
      const X_SPACING = 600;

      // Create bulk update array
      const bulkUpdates = groups.map((sheet, index) => {
        const xPos = 50 + index * X_SPACING;
        sheetIds.push(sheet.id);
        positionXs.push(xPos);
        positionYs.push(Y_POSITION);

        return {
          sheetId: sheet.id,
          updates: {
            position: {
              x: xPos,
              y: Y_POSITION,
            },
          },
        };
      });

      // Dispatch single action for all updates
      dispatch(bulkUpdateSheets(bulkUpdates));

      // // Force immediate stage update
      // stage?.batchDraw();

      // Save to backend
      const response = await newPut_V3("sheet/saveAllSheetsCoordinates", {
        design_id: designId,
        sheet_ids: sheetIds,
        position_xs: positionXs,
        position_ys: positionYs,
      });

      if ([200, 201].includes(response?.status_code)) {
        await getDesignDetails(designId);
      }
    } catch (error) {
      console.error("Error reordering sheets:", error);
    }
  };

  const handleDeleteLayer = async () => {
    try {
      if (!selectedSheetElement?.id) {
        console.error("No layer selected");
        return;
      }

      const response = await newPut("layer/deleteSheetLayer", {
        layer_id: selectedSheetElement.id,
        layer_type: selectedSheetElement.image_type || "image",
      });

      if (response?.status_code === 200) {
        // Remove the layer from Redux store
        dispatch(
          removeElement({
            sheetId: selectedSheetId,
            elementId: selectedSheetElement.id,
          })
        );

        // Clear selection and close side modal
        setSelectedImageId(null);
        setSelectedId(null);
        setShowSideModal(false);
        setContextMenuVisible(false);

        // Refresh design details
        await getDesignDetails(designId, false);
      }
    } catch (error) {
      console.error("Error deleting layer:", error);
      showAlert("Failed to delete layer", "error");
    }
  };

  const handleDuplicateLayer = async () => {
    if (!selectedSheetElement?.id) {
      console.error("No layer selected");
      return;
    }
    const response = await newPost_V3(`layer/copySheetLayer`, {
      layer_id: selectedSheetElement?.id,
      layer_type: "image",
      target_sheet_id: selectedSheetId,
    });
    if ([201, 200].includes(response?.status_code)) {
      await getSheetDetails(selectedSheetId);
    }
  };

  const boxWidth = 15;
  const boxHeight = 15;
  const boxX = (window.innerWidth - boxWidth) / 2;
  const boxY = 10;

  // Add this helper function at the top level of the component:
  const parseRgba = (rgba) => {
    if (!rgba) return { r: 255, g: 255, b: 255, a: 1 };
    const match = rgba.match(
      /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)/
    );

    if (match) {
      return {
        r: parseInt(match[1]),
        g: parseInt(match[2]),
        b: parseInt(match[3]),
        a: match[4] ? parseFloat(match[4]) : 1,
      };
    }
    return { r: 255, g: 255, b: 255, a: 1 };
  };

  // selection of multiple sheets
  const handleMouseDown = (e) => {
    if (!isSelecting) return; // Only activate if selecting
    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();
    // Get the actual position of the cursor
    const { x, y } = pointerPosition;

    // Set the starting point of the selection box
    setSelectionBox({ startX: x, startY: y, endX: x, endY: y });
  };

  const handleMouseMove = (e) => {
    if (!isSelecting) return;
    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();

    // Get the actual position of the cursor
    const { x, y } = pointerPosition;

    // Update the end point of the selection box
    setSelectionBox((prev) => ({ ...prev, endX: x, endY: y }));
  };

  const handleMouseUp = () => {
    if (!isSelecting) return;
    setIsSelecting(false);
    // Check which sheets are within the selection box
    const selectedIds = canvas?.layers[0]?.groups
      .filter((sheet) => {
        const { x, y } = sheet.position; // Ensure this is a Konva node
        return (
          x >= selectionBox.startX &&
          x <= selectionBox.endX &&
          y >= selectionBox.startY &&
          y <= selectionBox.endY
        );
      })
      .map((sheet) => sheet.id);
    setSelectedSheetIds(selectedIds); // Update selected sheet IDs
    setSelectionBox(null); // Clear selection box
  };

  // Add these new state variables near the top of your component
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const containerRef = useRef(null); // Add this ref for the container

  // Improved drag and drop handlers
  const handleDragStart = (e, index) => {
    e.stopPropagation();

    // Create a custom drag image that resembles the entire layer
    try {
      const draggedElement = document.querySelectorAll(
        ".version-image-wrapper"
      )[index];
      if (draggedElement) {
        // Set a ghost image that's slightly transparent
        const rect = draggedElement.getBoundingClientRect();
        const ghostElement = draggedElement.cloneNode(true);

        // Add styling to the ghost element to make it look like it's being dragged
        ghostElement.style.width = `${rect.width}px`;
        ghostElement.style.height = `${rect.height}px`;
        ghostElement.style.backgroundColor = "transparent";
        // ghostElement.style.border = "1px solid #ddd";
        ghostElement.style.borderRadius = "4px";
        // ghostElement.style.boxShadow = "0 3px 8px rgba(0,0,0,0.15)";
        ghostElement.style.transform = "rotate(2deg)";
        ghostElement.style.opacity = "0.8";
        ghostElement.style.position = "absolute";
        ghostElement.style.top = "-1000px"; // Position off-screen
        ghostElement.style.left = "-1000px";

        // Add to DOM temporarily to use as drag image
        document.body.appendChild(ghostElement);

        // Set the drag image to our styled clone
        e.dataTransfer.setDragImage(
          ghostElement,
          rect.width / 2,
          rect.height / 2
        );

        // Remove the ghost element after a short delay
        setTimeout(() => {
          document.body.removeChild(ghostElement);
        }, 100);
      }
    } catch (error) {
      console.error("Error creating drag image:", error);
    }

    // We're already working with the visually sorted elements in the UI
    // So the index is the visual position in the sorted list

    // Save the current visual index to be used in handleDrop
    setDraggedIndex(index);
    e.dataTransfer.setData("text/plain", index.toString());
    e.target.classList.add("dragging");
  };

  const handleDragEnd = (e) => {
    e.preventDefault();
    // Reset drag states
    setDraggedIndex(null);
    setDragOverIndex(null);

    // Clean up any lingering visual styles
    document.querySelectorAll(".version-image-wrapper").forEach((el) => {
      el.classList.remove(
        "dragging",
        "drag-over",
        "drag-over-above",
        "drag-over-below"
      );
    });
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();

    // Since we're already working with the sorted elements in the UI,
    // we just need to check if this is a background element via its props
    const visibleElements = canvas.layers[0].groups
      .find((group) => group.id === selectedSheetId)
      ?.elements?.slice()
      .sort((a, b) => (b.zIndex || 0) - (a.zIndex || 0));

    if (visibleElements?.[index]?.image_type === "background") {
      return; // Don't allow dragging over background elements
    }

    // Only update if we're hovering over a different element than the one being dragged
    if (draggedIndex !== index) {
      setDragOverIndex(index);

      // Remove any existing drag-over classes
      document.querySelectorAll(".version-image-wrapper").forEach((el) => {
        el.classList.remove("drag-over-above", "drag-over-below");
      });

      // Add appropriate class based on whether we're dropping above or below
      const dropTarget = document.querySelectorAll(".version-image-wrapper")[
        index
      ];
      if (dropTarget) {
        // If dragging from above to below
        if (draggedIndex < index) {
          dropTarget.classList.add("drag-over-below");
        }
        // If dragging from below to above
        else if (draggedIndex > index) {
          dropTarget.classList.add("drag-over-above");
        }
      }
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    // No need to reset dragOverIndex here as it would make the indicator disappear too quickly
  };

  const handleDrop = async (e, dropIndex) => {
    e.preventDefault();

    // Clean up styles and reset states
    document.querySelectorAll(".version-image-wrapper").forEach((el) => {
      el.classList.remove(
        "dragging",
        "drag-over",
        "drag-over-above",
        "drag-over-below"
      );
    });

    setDraggedIndex(null);
    setDragOverIndex(null);

    // Get elements from Redux store
    const elements = canvas.layers[0].groups.find(
      (group) => group.id === selectedSheetId
    )?.elements;
    if (!elements || elements.length < 2) return;

    // Get the dragged index from the data transfer (the visual index in the sorted list)
    const visualDragIndex = parseInt(e.dataTransfer.getData("text/plain"));

    // Handle special case for top drop zone
    const actualDropIndex = dropIndex === -1 ? 0 : dropIndex;

    // Sort elements the same way they appear in the UI
    const sortedElements = [...elements].sort(
      (a, b) => (b.zIndex || 0) - (a.zIndex || 0)
    );

    // Get the elements being dragged and dropped on
    const draggedElement = sortedElements[visualDragIndex];

    // For top drop zone, we don't need a drop element
    const dropElement =
      actualDropIndex === 0 && dropIndex === -1
        ? null
        : sortedElements[actualDropIndex];

    // Don't proceed if we're dealing with background elements or same positions
    // Exception: allow dropping at top position even if there's no drop element
    if (
      !draggedElement ||
      (dropElement === null && dropIndex !== -1) || // Only allow null dropElement for top drop zone
      draggedElement.image_type === "background" ||
      (dropElement && dropElement.image_type === "background") ||
      visualDragIndex === actualDropIndex
    ) {
      return;
    }

    try {
      // Create a new array based on the current visual order
      let newOrder = [...sortedElements];

      // Remove the dragged element and insert it at the new position
      newOrder.splice(visualDragIndex, 1);
      newOrder.splice(actualDropIndex, 0, draggedElement);

      // Background should always be at the bottom (end of the sorted array)
      // Find and move background to the end if it exists
      const backgroundIndex = newOrder.findIndex(
        (el) => el.image_type === "background"
      );
      if (backgroundIndex !== -1) {
        const backgroundElement = newOrder.splice(backgroundIndex, 1)[0];
        newOrder.push(backgroundElement); // Add to end (visually bottom)
      }

      // Assign new zIndex values to reflect the visual order
      // Higher zIndex values appear on top, so we give higher values to elements early in the array
      const elementsWithNewOrder = newOrder.map((element, idx) => ({
        ...element,
        zIndex: element.image_type === "background" ? 0 : newOrder.length - idx,
      }));

      // Prepare the layer order update for the API based on the new visual order
      // IMPORTANT: The API expects layer_order to follow visual order from top to bottom,
      // where the topmost layer has layer_order=1
      // First, sort elements by zIndex in descending order (highest first)
      const sortedForApi = [...elementsWithNewOrder]
        .filter((e) => e.image_type !== "background")
        .sort((a, b) => b.zIndex - a.zIndex);

      // Then map to create layer_order starting from 1 for the top layer
      const layer_order_update = sortedForApi.reverse().map((element, idx) => ({
        layer_type: element.image_type || "decorative",
        layer_id: element.id,
        layer_order: idx + 1, // 1-based indexing where 1 is the topmost layer
      }));

      console.log("Sending layer order update to API:", layer_order_update);

      // Call API with the new order
      const response = await newPut_V3("layer/changeLayerOrder", {
        layer_order_update,
        sheet_id: selectedSheetId,
      });

      if ([200, 201].includes(response?.status_code)) {
        // Update Redux state with new elements array
        await dispatch(
          updateSheet({
            sheetId: selectedSheetId,
            updates: {
              elements: elementsWithNewOrder,
            },
          })
        );

        // Update local state if needed
        if (selectedSheet) {
          setSelectedSheet({
            ...selectedSheet,
            elements: elementsWithNewOrder,
          });
        }
      }
    } catch (error) {
      console.error("Error reordering layers:", error);
    }
  };

  const toggleLayerVisibility = async (element) => {
    try {
      const newVisibility = !element.visible;

      // Get elements from Redux store
      const elements = canvas.layers[0].groups.find(
        (group) => group.id === selectedSheetId
      )?.elements;

      if (!elements) {
        return;
      }

      // Find the element in the current sheet and update its visibility
      const updatedElements = elements.map((el) => {
        if (el.id === element.id) {
          return { ...el, visible: newVisibility };
        }
        return el;
      });

      // Update Redux store directly without API call
      dispatch(
        updateSheet({
          sheetId: selectedSheetId,
          updates: {
            elements: updatedElements,
          },
        })
      );

      // Update local state if needed
      if (selectedSheet) {
        setSelectedSheet({
          ...selectedSheet,
          elements: updatedElements,
        });
      }
    } catch (error) {
      console.error("Error toggling layer visibility:", error);
    }
  };

  const getSheetDetails = async (sheetId) => {
    const response = await newGet_V3(
      `sheet/getSheetDetail?sheet_id=${sheetId}`
    );
    if (selectedSheetElement) {
      const element = response?.result?.layers.find((layer) => {
        return layer.id === selectedSheetElement.id;
      });
      if (element) {
        setSelectedSheetElement(element);
        setSelectedSheet(response.result); // Set the selected sheet when element is found
      }
    }
    const sheet = response.result;

    // Sort layers by their layer_order to ensure correct z-ordering
    // Lower layer_order values (like 1) should be at the top (higher zIndex)
    const sortedLayers = [...(sheet.layers || [])].sort((a, b) => {
      // Background elements should always be at the back
      if (a.image_type === "background") return 1;
      if (b.image_type === "background") return -1;

      // Otherwise sort by layer_order
      return (a.layer_order || 0) - (b.layer_order || 0);
    });

    // Assign zIndex values based on the sorted order
    // Higher indices in the array get higher zIndex values (to appear on top)
    const layersWithZIndex = sortedLayers.map((layer, idx) => {
      const zIndex =
        layer.image_type === "background" ? 0 : sortedLayers.length - idx;

      return {
        ...layer,
        zIndex,
      };
    });

    await dispatch(
      updateSheet({
        sheetId: selectedSheetId,
        updates: {
          name: sheet?.sheet_name || "Untitled",
          status_id: sheet?.sheet_status_id || 1,
          is_locked: sheet?.sheet_is_locked || false,
          position: {
            x: sheet?.position?.[0] || null,
            y: sheet?.position?.[1] || null,
          },
          elements:
            layersWithZIndex.map((layer) => ({
              ...layer,
              id: layer.id,
              type: "Image",
              sheet_id: sheet.sheet_id || sheet.id,
              x: 0,
              y: 0,
              width: 500,
              height: 500,
              image_data: {
                data: layer?.image_data?.data || "",
                format: layer?.image_data?.format || "PNG",
                size: layer?.image_data?.size || [500, 500],
              },
              draggable: false,
              blend_mode: layer?.blend_mode || "norm",
              opacity: layer?.opacity || 100,
              corner_radius: layer?.corner_radius || 0,
              position: {
                x: layer?.position?.[0] || 0,
                y: layer?.position?.[1] || 0,
              },
              rotation: layer?.rotation || 0,
              visible: layer?.visible !== undefined ? layer?.visible : true,
              // zIndex is already set in the layersWithZIndex array
            })) || [],
        },
      })
    );
  };

  const handleCopy = (e) => {
    e?.preventDefault();
    if (!selectedElement) {
      console.log("No element selected");
      return;
    }

    // Store the copied element in localStorage
    localStorage.setItem("copiedElement", JSON.stringify(selectedElement));

    // Use clipboard API for text-based copying (optional)
    navigator.clipboard
      .writeText(JSON.stringify(selectedElement))
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
    setHasCopied(true);
    setContextMenuVisible(false);

    // dispatch(setHasCopied(true)); // Update UI state
  };

  const handlePaste = async (e) => {
    e?.preventDefault();
    let copiedElement = localStorage.getItem("copiedElement");
    if (!copiedElement) {
      console.log("No copied element found");
      return;
    }
    copiedElement = JSON.parse(copiedElement);

    const response = await newPost_V3(`layer/copySheetLayer`, {
      layer_id: copiedElement?.id,
      layer_type: copiedElement.image_type,
      target_sheet_id: selectedSheetId,
    });
    if ([201, 200].includes(response?.status_code)) {
      await getSheetDetails(selectedSheetId);
      setContextMenuVisible(false);
    }
  };

  const handleSaveDetails = async () => {
    // Check if details have changed compared to selectedSheetElement
    const changedDetails = Object.keys(details).reduce((acc, key) => {
      if (details[key] !== selectedSheetElement[key]) {
        acc[key] = details[key];
      }
      return acc;
    }, {});
    const elementAlign = JSON.parse(selectedSheetElement.align || "{}");
    const alignmentChange = Object.keys(imageAlignDetails).reduce(
      (acc, key) => {
        if (imageAlignDetails[key] !== elementAlign[key]) {
          acc[key] = imageAlignDetails[key];
        }
        return acc;
      },
      {}
    );
    if (
      !Object.keys(changedDetails).length &&
      !Object.keys(alignmentChange).length
    )
      return;

    // console.log("Changed details:", changedDetails);
    let updates = {};
    if (Object.keys(changedDetails).length) {
      updates = {
        ...updates,
        blend_mode: changedDetails.blend_mode,
        opacity: changedDetails.opacity,
        corner_radius: changedDetails.corner_radius,
        color: changedDetails.color,
        crop_scale: changedDetails.crop_scale,
      };
    }
    if (Object.keys(alignmentChange).length) {
      updates = {
        ...updates,
        align: JSON.stringify(imageAlignDetails),
      };
    }
    console.log("selectedSheetElement.image_type", selectedSheetElement);
    // Only include crop_scale if the image type is not 'background'
    if (selectedSheetElement.image_type !== "background") {
      delete updates.color;
    }

    console.log("updates>>>>>>>>>>>>>>>>>", updates);

    const response = await newPut_V3(`layer/changeLayerValue`, {
      layer_id: selectedSheetElement.id,
      layer_type: selectedSheetElement.image_type,
      sheet_id: selectedSheetId,
      updates: updates,
    });
    if ([200, 201].includes(response?.status_code)) {
      await getSheetDetails(selectedSheetId);
    }

    // Uncomment and implement the API call if needed
  };
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.metaKey && e.key === "c") {
        handleCopy(e);
      }
      if (e.metaKey && e.key === "v") {
        handlePaste(e);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  const [hasChanges, setHasChanges] = useState(false);
  const is_editing_blocked =
    (selectedSheet?.is_locked ||
      selectedSheet?.status_id === 2 ||
      selectedSheet?.status_id === 3) &&
    isProjectActive();
  return (
    <div className="d-flex justify-content-center w-100 main-image-editor position-relative overflow-hidden">
      <KonvaCanvas
        selectedImages={selectedImages}
        setSelectedImages={setSelectedImages}
        scale={scale}
        setScale={setScale}
        removeSheet={handleRemoveSheet}
        modalRef={modalRef}
        handleCopy={handleCopy}
        handlePaste={handlePaste}
        setCurrentSheetId={setCurrentSheetId}
        lockNRequestApproval={lockNRequestApproval}
        cancelRequestApproval={cancelRequestApproval}
        selectionBox={selectionBox}
        setSelectionBox={setSelectionBox}
        handleMouseDown={handleMouseDown}
        handleMouseUp={handleMouseUp}
        handleMouseMove={handleMouseMove}
        sheetListLoading={false}
        setShowCommentSection={setShowCommentSection}
        showCommentSection={showCommentSection}
        projectStatus={projectStatus}
      />
      {/* side layers */}
      {ImageLayersFlag ? (
        <div
          className={`${showSideModal && "version-image-active"} version-image-container d-flex flex-column align-items-center gap-2`}
          ref={containerRef}
        >
          {/* Top drop zone for placing elements at the first position */}
          {draggedIndex !== null && (
            <div
              className={`top-drop-zone ${dragOverIndex === -1 ? "active" : ""}`}
              onDragOver={(e) => {
                e.preventDefault();
                setDragOverIndex(-1);

                // Remove any existing drag-over classes
                document
                  .querySelectorAll(".version-image-wrapper")
                  .forEach((el) => {
                    el.classList.remove("drag-over-above", "drag-over-below");
                  });
              }}
              onDrop={(e) => {
                e.preventDefault();

                // Clean up styles and reset states
                document
                  .querySelectorAll(".version-image-wrapper")
                  .forEach((el) => {
                    el.classList.remove(
                      "dragging",
                      "drag-over",
                      "drag-over-above",
                      "drag-over-below"
                    );
                  });

                const draggedIdx = parseInt(
                  e.dataTransfer.getData("text/plain")
                );
                handleDrop(e, 0); // Drop at index 0 (very top)
              }}
            ></div>
          )}

          {canvas.layers[0].groups
            .find((group) => group.id === selectedSheetId)
            ?.elements?.slice() // Create a copy to avoid modifying the original array
            ?.sort((a, b) => (b.zIndex || 0) - (a.zIndex || 0)) // Sort by zIndex in descending order (highest first)
            ?.map((element, index) => (
              <div
                className={`version-image-wrapper ${draggedIndex === index ? "dragging" : ""} 
              ${dragOverIndex === index ? "drag-over" : ""}`}
                key={element.id}
                draggable={element.image_type !== "background"}
                onDragStart={(e) =>
                  element.image_type !== "background" &&
                  handleDragStart(e, index)
                }
                onDragEnd={handleDragEnd}
                onDragOver={(e) => handleDragOver(e, index)}
                onDragLeave={handleDragLeave}
                onDrop={(e) => handleDrop(e, index)}
              >
                {/* Visibility toggle on the left side */}
                {element.image_type !== "background" && (
                  <div className="layer-visibility-toggle">
                    <button
                      className="visibility-toggle"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleLayerVisibility(element);
                      }}
                      title={
                        element.visible === false ? "Show layer" : "Hide layer"
                      }
                    >
                      <img
                        src={
                          element.visible === false
                            ? visibilityOffIcon
                            : visibilityIcon
                        }
                        alt={element.visible === false ? "Show" : "Hide"}
                      />
                    </button>
                  </div>
                )}

                <div
                  className="version-image"
                  onClick={() => {
                    setSelectedSheetElement(element);
                    setSelectedImageId(element.id);
                    setSelectedId(`image_${element.id}`);
                    setShowSideModal(true);
                  }}
                >
                  {element.image_data && element.image_data.data ? (
                    <img
                      src={imageLink(element, smallTabImage)}
                      alt="smallTabImage"
                      className="h-100"
                      style={{ opacity: element.visible === false ? 0.5 : 1 }}
                    />
                  ) : (
                    <div
                      className="version-image"
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: element.color || "#ffffff", // Use the color if image data is not present
                        opacity: element.visible === false ? 0.5 : 1,
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      ) : null}
      <div>
        {/* Zoom controls */}
        <div className="image-editor-controls">
          <div className="zoom-controls">
            <button
              className="zoom-btn"
              onClick={handleZoomOut}
              disabled={scale <= 0.1}
            >
              <img src={removeIcon} alt="Zoom out" />
            </button>
            <span className="zoom-level">{Math.round(scale * 100)}%</span>
            <button
              className="zoom-btn"
              onClick={handleZoomIn}
              disabled={scale >= 3}
            >
              <img src={plusIcon} alt="Zoom in" />
            </button>
            <button
              className="zoom-btn ms-2"
              onClick={handleReset}
              title={t("RESET_VIEW")}
            >
              <img
                src={revartIcon}
                alt="Reset view"
                style={{ width: "20px", height: "20px" }}
              />
            </button>
            <button
              className="zoom-btn ms-2"
              onClick={handleReorderSheets}
              title={t("REARRANGE_VIEW")}
            >
              <img
                src={reArrangeIcon}
                alt="Reset view"
                style={{
                  width: "20px",
                  height: "20px",
                  transform: "rotate(270deg)",
                }}
              />
            </button>
          </div>
        </div>

        {showTextOptionsModal && (
          <div
            className="text-options-modal position-absolute w-100 bg-white text-start"
            style={{
              top: inputPosition.y,
              left: inputPosition.x,
            }}
          >
            <div className="position-relative">
              <textarea
                value={newTextValue}
                onChange={(e) => setNewTextValue(e.target.value)}
                rows={3}
                className="w-100"
                placeholder="Write your prompt"
              />
              <div className="d-flex aalign-items-center gap-2 text-options-wrapper">
                <button className="text-options-btn font-noto-sans">
                  More fun
                </button>
                <button className="text-options-btn font-noto-sans">
                  More formal
                </button>
                <button className="text-options-btn font-noto-sans">
                  Shorten
                </button>
              </div>
            </div>
            <button
              className="d-flex align-items-center justify-content-center gap-2 px-4 border-0 black-gradiant-btn py-md-2 py-1 rounded-pill mt-2"
              onClick={handleRewrite}
              style={{ height: "auto", opacity: isEditing ? 0.5 : 1 }}
              disabled={is_editing_blocked}
            >
              <img src={aiIcon} alt="aiIcon" />
              <span>Rewrite</span>
            </button>
          </div>
        )}
        {/* right side menu */}
        {showSideModal && (
          <div
            style={{ zIndex: "50" }}
            className={`${showCommentSection ? "d-none" : ""}`}
          >
            {selectedId && selectedId.includes("text") && (
              <div className="border-right ps-md-3 pe-md-3 pb-md-3 ps-2 pe-2 pb-2 pt-0 bg-white font-noto-sans tabs-right-conatainer ">
                <div className="d-flex align-items-center justify-content-between pb-2 pt-3  bg-white position-sticky top-0">
                  <h5 className="fs-16 font-noto-sans fw-bolder mb-0 ">
                    {t("textLayerName")}
                  </h5>
                </div>
                <button className="d-flex align-items-center justify-content-center gap-2 px-4 border-0 black-gradiant-btn py-2 rounded-pill my-3 w-100">
                  <img src={aiIcon} alt="aiIcon" />
                  <span>{t("micoAIRewriter")}</span>
                </button>
                <h5 className="font-noto-sans fs-14 mt-4 fw-bolder">
                  {t("typography")}
                </h5>
                <div className="appearance-content border-bottom-0 pb-2">
                  <div className="w-100 d-flex flex-column gap-2 mt-3">
                    <select
                      className="form-select"
                      onChange={handleFontChange}
                      value={fontFamily}
                      disabled={is_editing_blocked}
                      style={{ opacity: is_editing_blocked ? 0.5 : 1 }}
                    >
                      {fonts.map((font) => (
                        <option key={font.family} value={font.family}>
                          {font.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-100 d-flex gap-3 align-items-center mt-3">
                    <div className="w-100 d-flex flex-column gap-2">
                      <select
                        className="form-select"
                        id="inputGroupSelect01"
                        disabled={is_editing_blocked}
                        style={{ opacity: is_editing_blocked ? 0.5 : 1 }}
                      >
                        <option selected>{t("regular")}</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="position-relative">
                      <input
                        className="form-control px-2 text-center"
                        placeholder="size"
                        type="number"
                        min="0"
                        max="100"
                        value={fontSize}
                        onChange={handleFontSizeChange}
                        disabled={is_editing_blocked}
                        style={{ opacity: is_editing_blocked ? 0.5 : 1 }}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between gap-md-3 gap-2 mt-3">
                    <div className="d-flex align-items-center gap-md-3 gap-2">
                      <button
                        className={`bg-transparent border-0 p-0 ${textStyle.bold === "bold" ? "active" : ""}`}
                        onClick={(e) => textFontStyleClick(e, "bold")}
                        disabled={is_editing_blocked}
                        style={{ opacity: is_editing_blocked ? 0.5 : 1 }}
                      >
                        <img src={boldIcon} alt="boldIcon" />
                      </button>
                      <button
                        className={`bg-transparent border-0 p-0 ${textStyle.italic === "italic" ? "active" : ""}`}
                        onClick={(e) => textFontStyleClick(e, "italic")}
                        disabled={is_editing_blocked}
                        style={{ opacity: is_editing_blocked ? 0.5 : 1 }}
                      >
                        <img src={italicIcon} alt="italicIcon" />
                      </button>
                      <button
                        className={`bg-transparent border-0 p-0 ${textStyle.underline === "underline" ? "active" : ""}`}
                        onClick={(e) => textFontStyleClick(e, "underline")}
                        disabled={is_editing_blocked}
                        style={{ opacity: is_editing_blocked ? 0.5 : 1 }}
                      >
                        <img src={underline} alt="underline" />
                      </button>
                    </div>

                    <div
                      className="header_Bigline"
                      style={{ width: "2px" }}
                    ></div>
                    <div className="d-flex align-items-center gap-md-3 gap-2">
                      <button
                        className={`bg-transparent border-0 p-0 ${textStyle.align === "center" ? "active" : ""}`}
                        onClick={(e) => {
                          textFontStyleClick(e, "align");
                        }}
                        disabled={is_editing_blocked}
                        style={{ opacity: is_editing_blocked ? 0.5 : 1 }}
                      >
                        <img src={alignManu} alt="alignManu" />
                      </button>
                      <button
                        className="bg-transparent border-0 p-0"
                        disabled={is_editing_blocked}
                        style={{ opacity: is_editing_blocked ? 0.5 : 1 }}
                      >
                        <img src={formatList} alt="formatList" />
                      </button>
                      <button
                        className="bg-transparent border-0 p-0"
                        disabled={is_editing_blocked}
                        style={{ opacity: is_editing_blocked ? 0.5 : 1 }}
                      >
                        <img src={formatLine} alt="formatLine" />
                      </button>
                    </div>
                  </div>
                </div>
                <h5 className="font-noto-sans fs-14 mt-3 fw-bolder">
                  {t("appearance")}
                </h5>
                <div className="appearance-content border-bottom-0 pb-2">
                  <div className="d-flex align-items-center gap-md-3 gap-2">
                    <div className="w-100 d-flex flex-column gap-2 mt-3">
                      <label for="formControlRange">{t("opacity")}</label>
                      <div className="d-flex gap-2 align-items-center">
                        <input
                          type="range"
                          className="form-control-range w-100"
                          style={{ height: "3px", accentColor: "black" }}
                          value={details.opacity}
                          onChange={handleImageOpacity}
                          min="0"
                          max="100"
                          step="1"
                          id="formControlRange"
                        />
                        <div
                          className="position-relative"
                          style={{ maxWidth: "65px" }}
                        >
                          <input
                            className="form-control px-4 text-center"
                            type="number"
                            min="0"
                            max="100"
                            step="1"
                            value={details.opacity}
                            onChange={handleImageOpacity}
                          />
                          <img
                            src={percentage}
                            alt="percentage"
                            className="percentage"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-100 d-flex flex-column gap-2 mt-3">
                    <label className="fs-14 font-noto-sans ms-0">
                      {t("blendMode")}
                    </label>
                    <select
                      className="form-select"
                      id="inputGroupSelect01"
                      value={details.blend_mode}
                      onChange={handleBlendModeChange}
                    >
                      <option value="source-over">{t("normal")}</option>
                      {blendModes?.map((mode, index) => (
                        <option key={mode} value={mode}>
                          {mode}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-100 d-flex flex-column gap-2 mt-3">
                    <label className="fs-14 font-noto-sans ms-0">
                      {t("color")}
                    </label>

                    <div className="d-flex gap-md-3 gap-2 align-items-center">
                      <div className="position-relative">
                        <input
                          className="form-control px-2"
                          placeholder=""
                          value={color}
                          type="text"
                          onChange={handleColorChange}
                        />
                      </div>
                      <div
                        className="position-relative"
                        style={{ maxWidth: "85px" }}
                      >
                        <input
                          className="form-control px-4 text-center"
                          type="number"
                        />
                        <img
                          src={opacityImage}
                          alt="opacity"
                          className="opacity"
                        />
                        <img
                          src={percentage}
                          alt="percentage"
                          className="percentage"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* background Right Menu */}
            {selectedId && selectedId.includes("background") && (
              <div className="border-right ps-md-3 pe-md-3 pb-md-3 ps-2 pe-2 pb-2 pt-0 bg-white font-noto-sans tabs-right-conatainer z-n1">
                <div className="d-flex align-items-center justify-content-between pb-2 pt-3  bg-white position-sticky top-0">
                  <h5
                    className="fs-16 font-noto-sans fw-bolder mb-0 max-w-[170px] text-ellipsis"
                    title={selectedSheetElement?.name}
                  >
                    {selectedSheetElement?.name}
                  </h5>
                  <button
                    className="btn-black py-md-2 py-1 px-md-4 px-3 fw-600"
                    onClick={handleSaveDetails}
                    disabled={is_editing_blocked}
                    style={{ opacity: is_editing_blocked ? 0.5 : 1 }}
                  >
                    {t("save")}
                  </button>
                </div>

                <div className="replace-img-container w-100 position-relative d-flex align-items-center justify-content-center">
                  <img
                    className="image rounded-3 object-fit-contain"
                    src={imageLink(selectedSheetElement, newDesign)}
                    alt="newDesign"
                  />
                  {is_editing_blocked ? (
                    <button
                      className="replace-btn d-flex px-3 py-1 border-0 rounded-pill bg-white gap-2 d-flex justify-content-center align-items-center"
                      // data-bs-toggle="modal"
                      // data-bs-target="#add_design"
                      onClick={() => {
                        setSelectedSheetLayerId(selectedSheetElement.id);
                        setUploadPopupOpen(true);
                      }}
                      type="button"
                    >
                      {selectedSheetElement?.image_data?.data ? (
                        <>
                          <img src={refresh} alt="refresh" />
                          <span className="font-noto-sans">{t("replace")}</span>
                        </>
                      ) : (
                        <>
                          <img src={upload} alt="upload" />
                          <span className="font-noto-sans">{t("upload")}</span>
                        </>
                      )}
                    </button>
                  ) : null}
                </div>

                <h5 className="font-noto-sans fs-14 mt-3 fw-bolder">
                  Select Color
                </h5>
                <div className="appearance-content">
                  <ColorPicker
                    initialColor={
                      details?.color
                        ? parseRgba(details?.color)
                        : { r: 255, g: 255, b: 255, a: 1 }
                    }
                    onColorChange={handleColorChange}
                    disabled={is_editing_blocked}
                    style={{ opacity: is_editing_blocked ? 0.5 : 1 }}
                  />
                </div>
                {/*Commented For sprint one */}
                {selectedSheetElement?.image_data?.data && (
                  <>
                    <h5 className="font-noto-sans fs-14 mt-3 fw-bolder">
                      {t("appearance")}
                    </h5>
                    <div className="appearance-content">
                      <div className="w-100 d-flex gap-md-3 gap-2 align-items-center mt-3">
                        <div className="w-100 d-flex flex-column gap-2">
                          <label className="fs-14 font-noto-sans ms-0">
                            {t("opacity")}
                          </label>
                          <div className="position-relative">
                            <input
                              className="form-control px-4 text-center"
                              type="number"
                              min="0"
                              max="100"
                              step="1"
                              value={details.opacity}
                              onChange={handleImageOpacity}
                            />
                            <img
                              src={opacityImage}
                              alt="opacity"
                              className="opacity"
                            />
                            <img
                              src={percentage}
                              alt="percentage"
                              className="percentage"
                            />
                          </div>
                        </div>
                        <div className="w-100 d-flex flex-column gap-2">
                          <label className="fs-14 font-noto-sans ms-0">
                            {t("cornerRadius")}
                          </label>
                          <div className="position-relative">
                            <input
                              className="form-control px-4 text-center"
                              type="number"
                              min="0"
                              max="100"
                              step="1"
                              value={details.corner_radius}
                              onChange={handleCornerRadius}
                            />
                            <img
                              src={roundedCorner}
                              alt="roundedCorner"
                              className="roundedCorner"
                              width={20}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-100 d-flex flex-column gap-2 mt-3">
                        <label className="fs-14 font-noto-sans ms-0">
                          {t("blendMode")}
                        </label>
                        <select
                          className="form-select"
                          value={details.blend_mode}
                          onChange={handleBlendModeChange}
                        >
                          <option value="source-over">{t("normal")}</option>
                          {blendModes?.map((mode) => (
                            <option key={mode} value={mode}>
                              {mode}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <h5 className="font-noto-sans fs-14 mt-3 fw-bolder">
                      {t("layoutAndComposition")}
                    </h5>
                    <div className="appearance-content border-bottom-0">
                      <div className="w-100 d-flex flex-column gap-2 mt-3">
                        <label className="fs-14 font-noto-sans ms-0">
                          {t("cropAndScale")}
                        </label>
                        <select
                          className="form-select"
                          onChange={handleCropAndScale}
                          value={details.crop_scale}
                        >
                          <option value="fit">{t("fit")}</option>
                          <option value="fill">{t("fill")}</option>
                          <option value="stretch">{t("stretch")}</option>
                          <option value="center">{t("center")}</option>
                        </select>
                      </div>
                      {LayoutCompositionFlag ? (
                        <div className="d-flex align-items-center gap-4 mt-4">
                          <button
                            className="p-0 bg-transparent border-0"
                            onClick={() => handleRotation("left")}
                            style={{
                              opacity: is_editing_blocked ? 0.5 : 1,
                              cursor: is_editing_blocked
                                ? "not-allowed"
                                : "pointer",
                            }}
                          >
                            <img src={revartIcon} alt="revartIcon" />
                          </button>
                          <button
                            className="p-0 bg-transparent border-0"
                            onClick={() => handleRotation("right")}
                            style={{
                              opacity: is_editing_blocked ? 0.5 : 1,
                              cursor: is_editing_blocked
                                ? "not-allowed"
                                : "pointer",
                            }}
                          >
                            <img src={notrevartIcon} alt="notrevartIcon" />
                          </button>
                          <button
                            className="p-0 bg-transparent border-0"
                            onClick={() => handleFlip("x")}
                            style={{
                              opacity: is_editing_blocked ? 0.5 : 1,
                              cursor: is_editing_blocked
                                ? "not-allowed"
                                : "pointer",
                            }}
                          >
                            <img src={roteteImage} alt="roteteImage" />
                          </button>
                          <button
                            className="p-0 bg-transparent border-0"
                            onClick={() => handleFlip("y")}
                            style={{
                              opacity: is_editing_blocked ? 0.5 : 1,
                              cursor: is_editing_blocked
                                ? "not-allowed"
                                : "pointer",
                            }}
                          >
                            <img src={leftroteteImage} alt="leftroteteImage" />
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
              </div>
            )}

            {/* right side image menu */}
            {selectedId && selectedId.includes("image") && (
              <div className="border-right ps-md-3 pe-md-3 pb-md-3 ps-2 pe-2 pb-2 pt-0 bg-white font-noto-sans tabs-right-conatainer z-n1 image-right-menu">
                <div
                  className={`d-flex align-items-center justify-content-between pb-2 pt-3  bg-white position-sticky top-0 ${styles.imageLayerHeader}`}
                >
                  <h5
                    className="fs-16 font-noto-sans fw-bolder mb-0 max-w-[170px] text-ellipsis"
                    title={selectedSheetElement?.name}
                  >
                    {selectedSheetElement?.name}
                  </h5>
                  <div>
                    <button
                      className="p-0 bg-transparent border-0 mr-2"
                      onClick={() => handleDownloadImage(selectedSheetElement)}
                      style={{ marginRight: "10px" }}
                    >
                      <img
                        src={downloadIcon}
                        alt="downloadIcon"
                        style={{ width: "20px", height: "20px" }}
                      />
                    </button>
                    <button
                      className="btn-black py-md-2 py-1 px-md-4 px-3 fw-600"
                      onClick={handleSaveDetails}
                      disabled={is_editing_blocked}
                    >
                      {t("save")}
                    </button>
                  </div>
                </div>

                <div className="replace-img-container w-100 position-relative d-flex align-items-center justify-content-center">
                  <img
                    className="image rounded-3 object-fit-contain"
                    src={imageLink(selectedSheetElement, newDesign)}
                    alt="newDesign"
                  />
                  {!(
                    selectedSheet?.is_locked ||
                    selectedSheet?.status_id === 2 ||
                    selectedSheet?.status_id === 3
                  ) && isProjectActive() ? (
                    <button
                      className="replace-btn d-flex px-3 py-1 border-0 rounded-pill bg-white gap-2 d-flex justify-content-center align-items-center"
                      onClick={() => {
                        setSelectedSheetLayerId(selectedSheetElement.id);
                        setUploadPopupOpen(true);
                      }}
                      type="button"
                    >
                      <img src={refresh} alt="refresh" />
                      <span className="font-noto-sans">{t("replace")}</span>
                    </button>
                  ) : null}
                </div>
                {EnhanceWithMicoFlag ? (
                  <div className="accordion mt-3" id="accordionExample">
                    <div className="accordion-item border-0">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed p-0 font-noto-sans"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          disabled={is_editing_blocked}
                          style={{ opacity: is_editing_blocked ? 0.5 : 1 }}
                        >
                          {t("enhanceWithMicoAI")}
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body p-0">
                          <div className="position-relative">
                            <Swiper
                              cssMode={true}
                              slidesPerView={3.5}
                              spaceBetween={15}
                              navigation={true}
                              pagination={true}
                              mousewheel={true}
                              keyboard={true}
                              modules={[
                                Navigation,
                                Pagination,
                                Mousewheel,
                                Keyboard,
                              ]}
                              className="mySwiper mt-2"
                            >
                              <SwiperSlide>
                                <div
                                  className="swipper-container rounded-circle"
                                  data-bs-toggle="modal"
                                  data-bs-target="#magicBrush"
                                  type="button"
                                >
                                  <BootstrapTooltip
                                    title={t("bgRemoverDescription")}
                                  >
                                    <img
                                      src={newDesign}
                                      alt="newDesign"
                                      className="w-100 h-100 rounded-circle"
                                    />
                                  </BootstrapTooltip>
                                  <div className="swipper-btn">
                                    <img src={aiIconBlack} alt="aiIconBlack" />
                                  </div>
                                </div>
                                <span className="label font-noto-sans text-center">
                                  {t("bgRemover")}
                                </span>
                              </SwiperSlide>
                              <SwiperSlide>
                                <div className="swipper-container rounded-circle">
                                  <BootstrapTooltip
                                    title={t("smartEditDescription")}
                                  >
                                    <img
                                      src={newDesign}
                                      alt="newDesign"
                                      className="w-100 h-100 rounded-circle"
                                    />
                                  </BootstrapTooltip>
                                  <div className="swipper-btn">
                                    <img src={aiIconBlack} alt="aiIconBlack" />
                                  </div>
                                </div>
                                <span className="label font-noto-sans text-center">
                                  {t("smartEdit")}
                                </span>
                              </SwiperSlide>
                              <SwiperSlide>
                                <div className="swipper-container rounded-circle">
                                  <BootstrapTooltip
                                    title={t("magicBrushDescription")}
                                  >
                                    <img
                                      src={newDesign}
                                      alt="newDesign"
                                      className="w-100 h-100 rounded-circle"
                                    />
                                  </BootstrapTooltip>
                                  <div className="swipper-btn">
                                    <img src={aiIconBlack} alt="aiIconBlack" />
                                  </div>
                                </div>
                                <span className="label font-noto-sans text-center">
                                  {t("magicBrush")}
                                </span>
                              </SwiperSlide>
                              <SwiperSlide>
                                <div className="swipper-container rounded-circle">
                                  <BootstrapTooltip
                                    title={t("bgRemoverDescription")}
                                  >
                                    <img
                                      src={newDesign}
                                      alt="newDesign"
                                      className="w-100 h-100 rounded-circle"
                                    />
                                  </BootstrapTooltip>
                                  <div className="swipper-btn">
                                    <img src={aiIconBlack} alt="aiIconBlack" />
                                  </div>
                                </div>
                                <span className="label font-noto-sans text-center">
                                  {t("bgRemover")}
                                </span>
                              </SwiperSlide>
                            </Swiper>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <h5 className="font-noto-sans fs-14 mt-3 fw-bolder">
                  {t("appearance")}
                </h5>
                <div className="appearance-content">
                  <div className="w-100 d-flex gap-md-3 gap-2 align-items-center mt-3">
                    <div className="w-100 d-flex flex-column gap-2">
                      <label className="fs-14 font-noto-sans ms-0">
                        {t("opacity")}
                      </label>
                      <div className="position-relative">
                        <input
                          className="form-control px-4 text-center"
                          type="number"
                          min="0"
                          max="100"
                          step="1"
                          value={details.opacity}
                          onChange={handleImageOpacity}
                          disabled={is_editing_blocked}
                          style={{ opacity: is_editing_blocked ? 0.5 : 1 }}
                        />
                        <img
                          src={opacityImage}
                          alt="opacity"
                          className="opacity"
                        />
                        <img
                          src={percentage}
                          alt="percentage"
                          className="percentage"
                        />
                      </div>
                    </div>
                    <div className="w-100 d-flex flex-column gap-2">
                      <label className="fs-14 font-noto-sans ms-0">
                        {t("cornerRadius")}
                      </label>
                      <div className="position-relative">
                        <input
                          className="form-control px-4 text-center"
                          type="number"
                          min="0"
                          max="100"
                          step="1"
                          value={details.corner_radius}
                          onChange={handleCornerRadius}
                          disabled={is_editing_blocked}
                          style={{ opacity: is_editing_blocked ? 0.5 : 1 }}
                        />
                        <img
                          src={roundedCorner}
                          alt="roundedCorner"
                          className="roundedCorner"
                          width={20}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className='w-100 d-flex flex-column gap-2 mt-3'>
                    <label className='fs-14 font-noto-sans ms-0'>{t('blendMode')}</label>
                    <select
                      className="form-select"
                      value={details.blend_mode}
                      onChange={handleBlendModeChange}
                    >
                      <option value="source-over">{t('normal')}</option>
                      {blendModes?.map((mode) => (
                        <option key={mode} value={mode}>{mode}</option>
                      ))}
                    </select>
                  </div> */}
                </div>
                <h5 className="font-noto-sans fs-14 mt-3 fw-bolder">
                  {t("layoutAndComposition")}
                </h5>
                <div className="appearance-content border-bottom-0">
                  <div className="w-100 d-flex flex-column gap-2 mt-3">
                    <label className="fs-14 font-noto-sans ms-0">
                      {t("cropAndScale")}
                    </label>
                    <select
                      className="form-select"
                      onChange={handleCropAndScale}
                      value={details.crop_scale}
                      disabled={is_editing_blocked}
                      style={{ opacity: is_editing_blocked ? 0.5 : 1 }}
                    >
                      <option value="fit">{t("fit")}</option>
                      <option value="fill">{t("fill")}</option>
                      <option value="stretch">{t("stretch")}</option>
                      <option value="center">{t("center")}</option>
                    </select>
                  </div>
                  {LayoutCompositionFlag ? (
                    <div className="d-flex align-items-center gap-4 mt-4">
                      <button
                        className="p-0 bg-transparent border-0"
                        onClick={() => handleRotation("left")}
                        style={{
                          opacity: is_editing_blocked ? 0.5 : 1,
                          cursor: is_editing_blocked
                            ? "not-allowed"
                            : "pointer",
                        }}
                      >
                        <img src={revartIcon} alt="revartIcon" />
                      </button>
                      <button
                        className="p-0 bg-transparent border-0"
                        onClick={() => handleRotation("right")}
                        style={{
                          opacity: is_editing_blocked ? 0.5 : 1,
                          cursor: is_editing_blocked
                            ? "not-allowed"
                            : "pointer",
                        }}
                      >
                        <img src={notrevartIcon} alt="notrevartIcon" />
                      </button>
                      <button
                        className="p-0 bg-transparent border-0"
                        onClick={() => handleFlip("x")}
                        style={{
                          opacity: is_editing_blocked ? 0.5 : 1,
                          cursor: is_editing_blocked
                            ? "not-allowed"
                            : "pointer",
                        }}
                      >
                        <img src={roteteImage} alt="roteteImage" />
                      </button>
                      <button
                        className="p-0 bg-transparent border-0"
                        onClick={() => handleFlip("y")}
                        style={{
                          opacity: is_editing_blocked ? 0.5 : 1,
                          cursor: is_editing_blocked
                            ? "not-allowed"
                            : "pointer",
                        }}
                      >
                        <img src={leftroteteImage} alt="leftroteteImage" />
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            )}

            {selectedId && selectedId.includes("shape") && (
              <div className="border-right ps-md-3 pe-md-3 pb-md-3 ps-2 pe-2 pb-2 pt-0 bg-white font-noto-sans tabs-right-conatainer ">
                <div className="d-flex align-items-center justify-content-between pb-2 pt-3  bg-white position-sticky top-0">
                  <h5 className="fs-16 font-noto-sans fw-bolder mb-0">
                    {t("imageLayerName")}
                  </h5>
                </div>
                <div className="d-flex flex-column gap-md-2 gap-1 my-md-3 my-2 ">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="Solid_fill"
                      id="solid"
                    />
                    <label className="form-check-label" for="solid">
                      {t("solidFill")}
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="Solid_fill"
                      id="gradient"
                    />
                    <label className="form-check-label" for="gradient">
                      {t("gradientFill")}
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="Solid_fill"
                      id="image"
                    />
                    <label className="form-check-label" for="image">
                      {t("imageFill")}
                    </label>
                  </div>
                </div>
                <div className="appearance-content">
                  <div className="replace-img-container w-100 position-relative d-flex align-items-center justify-content-center">
                    <img
                      src={imageLink(selectedSheetElement, newDesign)}
                      alt="newDesign"
                      className="image object-fit-contain"
                    />
                    <button className="replace-btn d-flex px-3 py-1 border-0 rounded-pill bg-white d-flex align-items-center justify-content-center">
                      <span className="font-noto-sans">{t("select")}</span>
                    </button>
                  </div>
                </div>
                <h5 className="font-noto-sans fs-14 mt-3 fw-bolder">
                  {t("appearance")}
                </h5>
                <div className="appearance-content">
                  <div className="w-100 d-flex gap-md-3 gap-2 align-items-center mt-3">
                    <div className="w-100 d-flex flex-column gap-2">
                      <label className="fs-14 font-noto-sans ms-0">
                        {t("opacity")}
                      </label>
                      <div className="position-relative">
                        <input
                          className="form-control px-4 text-center"
                          type="number"
                          min="0"
                          max="100"
                          step="1"
                          value={details.opacity}
                          onChange={handleImageOpacity}
                        />
                        <img
                          src={opacityImage}
                          alt="opacity"
                          className="opacity"
                        />
                        <img
                          src={percentage}
                          alt="percentage"
                          className="percentage"
                        />
                      </div>
                    </div>
                    <div className="w-100 d-flex flex-column gap-2">
                      <label className="fs-14 font-noto-sans ms-0">
                        {t("cornerRadius")}
                      </label>
                      <div className="position-relative">
                        <input
                          className="form-control px-4 text-center"
                          type="number"
                          min="0"
                          max="100"
                          step="1"
                          value={details.corner_radius}
                          onChange={handleCornerRadius}
                        />
                        <img
                          src={roundedCorner}
                          alt="roundedCorner"
                          className="roundedCorner"
                          width={20}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-100 d-flex flex-column gap-2 mt-3">
                    <label className="fs-14 font-noto-sans ms-0">
                      {t("blendMode")}
                    </label>
                    <select
                      className="form-select"
                      value={details.blend_mode}
                      onChange={handleBlendModeChange}
                    >
                      <option value="source-over">{t("normal")}</option>
                      {blendModes?.map((mode) => (
                        <option key={mode} value={mode}>
                          {mode}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <h5 className="font-noto-sans fs-14 mt-3 fw-bolder">
                  {t("layoutAndComposition")}
                </h5>
                <div className="appearance-content border-bottom-0">
                  <div className="w-100 d-flex flex-column gap-2 mt-3">
                    <label className="fs-14 font-noto-sans ms-0">
                      {t("cropAndScale")}
                    </label>
                    <select
                      className="form-select"
                      onChange={handleCropAndScale}
                      value={details.crop_scale}
                    >
                      <option value="fit">{t("fit")}</option>
                      <option value="fill">{t("fill")}</option>
                      <option value="stretch">{t("stretch")}</option>
                      <option value="center">{t("center")}</option>
                    </select>
                  </div>
                  <div className="d-flex align-items-center gap-4 mt-4">
                    <button
                      className="p-0 bg-transparent border-0"
                      onClick={() => handleRotation("left")}
                      style={{
                        opacity: is_editing_blocked ? 0.5 : 1,
                        cursor: is_editing_blocked ? "not-allowed" : "pointer",
                      }}
                    >
                      <img src={revartIcon} alt="revartIcon" />
                    </button>
                    <button
                      className="p-0 bg-transparent border-0"
                      onClick={() => handleRotation("right")}
                      style={{
                        opacity: is_editing_blocked ? 0.5 : 1,
                        cursor: is_editing_blocked ? "not-allowed" : "pointer",
                      }}
                    >
                      <img src={notrevartIcon} alt="notrevartIcon" />
                    </button>
                    <button
                      className="p-0 bg-transparent border-0"
                      onClick={() => handleFlip("x")}
                      style={{
                        opacity: is_editing_blocked ? 0.5 : 1,
                        cursor: is_editing_blocked ? "not-allowed" : "pointer",
                      }}
                    >
                      <img src={roteteImage} alt="roteteImage" />
                    </button>
                    <button
                      className="p-0 bg-transparent border-0"
                      onClick={() => handleFlip("y")}
                      style={{
                        opacity: is_editing_blocked ? 0.5 : 1,
                        cursor: is_editing_blocked ? "not-allowed" : "pointer",
                      }}
                    >
                      <img src={leftroteteImage} alt="leftroteteImage" />
                    </button>
                  </div>
                </div>
              </div>
            )}
            <MagicBrush />
          </div>
        )}

        <div>
          {/* right click big popup */}

          {contextMenuVisible ? (
            <div
              className="bg-white text-rightclick-popup position-absolute"
              style={{
                top: `${menuPosition.y}px`,
                left: `${menuPosition.x}px`,
              }}
              onClick={hideContextMenu}
            >
              {/* <div className='popup-body d-flex gap-3 flex-column'>
                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                  <img src={aiIcon} alt="aiIcon" style={{ filter: "brightness(0)" }} />
                  <span>{t('bgRemover')}</span>
                </button>
                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                  <img src={aiIcon} alt="aiIcon" style={{ filter: "brightness(0)" }} />
                  <span>{t('smartEdit')}</span>
                </button>
                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                  <img src={aiIcon} alt="aiIcon" style={{ filter: "brightness(0)" }} />
                  <span>{t('magicBrush')}</span>
                </button>
                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                  <img src={aiIcon} alt="aiIcon" style={{ filter: "brightness(0)" }} />
                  <span>{t('infiniteExpand')}</span>
                </button>
                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                  <img src={aiIcon} alt="aiIcon" style={{ filter: "brightness(0)" }} />
                  <span>{t('upscaler')}</span>
                </button>
              </div> */}
              {/* <div className='popup-body d-flex gap-3 flex-column'>

                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                  <img src={refresh} alt="refresh" width={18} style={{ filter: "brightness(0)" }} />
                  <span>{t('replace')}</span>
                </button>
                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                  <img src={cropIcon} alt="cropIcon" style={{ filter: "brightness(0)" }} />
                  <span>{t('crop')}</span>
                </button>
              </div> */}
              <div className="popup-body d-flex gap-3 flex-column">
                <div className='d-flex align-items-center justify-content-between' onClick={(e) => handleCopy(e)}>
                  <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center' disabled={!selectedElement}>
                    <img src={copyIcon} alt="copyIcon" />
                    <span>{t('copy')}</span>
                  </button>
                  <p className='m-0'>cmd+C</p>
                </div>
                <div className='d-flex align-items-center justify-content-between' onClick={(e) => handlePaste(e)}>
                  <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center' disabled={!hasCopied} >
                    <img src={pastIcon} alt="pastIcon" />
                    <span>{t('paste')}</span>
                  </button>
                  <p className='m-0'>cmd+V</p>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                  <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center' onClick={handleDuplicateLayer}>
                    <img src={duplicateIocn} alt="duplicateIocn" />
                    <span>{t('duplicate')}</span>
                  </button>
                  <p className='m-0'>cmd+D</p>
                </div>
              </div>
              <div className="popup-footer border-bottom-0">
                <div className="d-flex align-items-center justify-content-between">
                  <button
                    className="border-0 bg-transparent p-0 d-flex gap-2 align-items-center"
                    onClick={handleDeleteLayer}
                    disabled={is_editing_blocked}
                    style={{ opacity: is_editing_blocked ? 0.5 : 1 }}
                  >
                    <img src={deleteIocn} alt="deleteIocn" width={20} />
                    <span>{t("delete")}</span>
                  </button>
                  <p className="m-0">del</p>
                </div>
              </div>
            </div>
          ) : (
            // <div className='popup-body d-flex gap-3 flex-column'>
            //   <div className='d-flex align-items-center justify-content-between'>
            //     <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center' disabled={!hasCopied}>
            //       <img src={pastIcon} alt="pastIcon" />
            //       <span>{t('paste')}</span>
            //     </button>
            //     <p className='m-0'>cmd+V</p>
            //   </div>
            // </div>
            <></>
          )}
        </div>
        {/* <AddImagePopUp typeChange={typeChange} /> */}

        {/* Replace Modal start*/}
        {/* <div className="modal fade" id="search_modal" tabIndex="-1" aria-labelledby="search_modalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-fullscreen modal-dialog-scrollable">
            <div className="modal-content px-3">
              <div className="modal-header border-0">
                <div className='upload_design'>
                  Browse
                </div>
                <div className='cursor_pointer' data-bs-dismiss="modal" onClick={() => { setShowImageDetails(false); setResults([]); }}>
                  <img src={Close} alt='Close' />
                </div>
              </div>
              <div className="modal-body">
                <BrowseSearch onSetImageUrl={handleSetImageUrl} showImageDetails={showImageDetails} setShowImageDetails={setShowImageDetails} />
              </div>
              {showImageDetails &&
                <div className="modal-footer border-0" disabled={loading}>
                  <button type="button" className="cancel_modal_footer" data-bs-dismiss="modal" disabled={loading} onClick={() => setShowImageDetails(false)}>{t("cancel")}  </button>
                  <button type="button" className="send_modal_footer" onClick={onHandleSelectedImage}>
                    {loading ? <CircularProgress color="inherit" size={20} /> : "Select"}
                  </button>
                </div>
              }
            </div>
          </div>
        </div> */}
        {/* Replace Modal end*/}
      </div>

      {/* <div className="modal fade" id="add_design" tabIndex="-1" aria-labelledby="add_designLabel" aria-hidden="true"> */}
      {/* <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content px-3">
            <div className="modal-header border-0">
              <div className='upload_design'>
                {t('uploadDesign')}
              </div>
              <div className='cursor_pointer' data-bs-dismiss="modal">
                <img src={Close} alt='Close' />
              </div>
            </div>
            <div className="modal-body">
              <UploadDesignFile
                instanceId="normal-upload"
                screen="visionStudio"
                layerId={layerId}
                isMultiSelect={true}
              />
            </div>
          </div>
        </div> */}
      {uploadPopUpOpen && (
        <UploadComponent
          instanceId="normal-upload"
          screen="visionStudio"
          layerId={layerId}
          isMultiSelect={true}
          setPopupState={setUploadPopupOpen}
          getSheetDetails={getSheetDetails}
          getDesignDetails={getDesignDetails}
        />
      )}
      {/* </div> */}

      {/* Selection Box */}
      {/* {!isSelecting && <div
        className='selection-box'
        style={{
          position: 'absolute',
          left: boxX,
          top: boxY,
          width: boxWidth,
          height: boxHeight,
          // backgroundColor: 'rgba(0, 0, 0, 0.1)', // Light transparent background
          cursor: 'pointer',
          border: '2px solid grey',
          borderRadius: '2px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        title='Select multiple sheets'
        onClick={() => setIsSelecting(true)}
      />} */}
    </div>
  );
};

export default ImageComponent;
