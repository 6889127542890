/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ModelOptions = ({ tag, formValue, setFormValue }) => {
  const [showAll, setShowAll] = useState(false);
  const [checkedOptions, setCheckedOptions] = useState({});
  const [visibleOptionsLength, setVisibleOptionsLength] = useState(4);
  const { t } = useTranslation();
  const options = Object.entries(tag.model_options);

  const toggleShowAll = () => setShowAll(!showAll);

  // Add helper function to get label for model options
  const getModelOptionLabel = (keyName, value) => {
    if (keyName === "None") return t("None");
    if (keyName === "All") return t("All");
    if (tag.model_key_name === "model_image_type") {
      switch (value) {
        case 0:
          return t("Photo");
        case 1:
          return t("Illustration");
        case -1:
          return t("None");
        case -2:
          return t("All");
        default:
          return keyName.replace(`${tag.model_key_name}_`, "");
      }
    }
    return keyName.replace(`${tag.model_key_name}_`, "");
  };

  const handleChange = (e, value, modelId, tag, option) => {
    const isChecked = e.target.checked;

    if (option === "All") {
      // Handle "All" selection
      const newCheckedOptions = {};
      options.forEach(([key]) => {
        if (key !== "None" && key !== "All") {
          newCheckedOptions[key] = isChecked;
        }
      });
      setCheckedOptions(newCheckedOptions);

      setFormValue((prevForm) => {
        let updatedModelFilters = [...prevForm.model_filters];
        const modelIndex = updatedModelFilters.findIndex(
          (model) => model.model_id === modelId
        );

        if (isChecked) {
          const allValues = options
            .filter(([key]) => key !== "None" && key !== "All")
            .map(([_, val]) => val);

          if (modelIndex === -1) {
            updatedModelFilters.push({
              model_id: modelId,
              model_key_name: tag.model_key_name,
              model_options: allValues,
            });
          } else {
            updatedModelFilters[modelIndex].model_options = allValues;
          }
        } else {
          if (modelIndex !== -1) {
            updatedModelFilters.splice(modelIndex, 1);
          }
        }
        return { ...prevForm, model_filters: updatedModelFilters };
      });
      return;
    }

    // Regular option handling
    setCheckedOptions((prev) => {
      const updated = { ...prev, [option]: isChecked };

      // Check if all options are selected
      const allSelected = options
        .filter(([key]) => key !== "None" && key !== "All")
        .every(([key]) => updated[key]);

      updated["All"] = allSelected;
      return updated;
    });

    // Update model filters for individual options
    setFormValue((prevForm) => {
      let updatedModelFilters = [...prevForm.model_filters];
      const modelIndex = updatedModelFilters.findIndex(
        (model) => model.model_id === modelId
      );

      if (isChecked) {
        if (modelIndex === -1) {
          updatedModelFilters.push({
            model_id: modelId,
            model_key_name: tag.model_key_name,
            model_options: [value],
          });
        } else if (
          !updatedModelFilters[modelIndex].model_options.includes(value)
        ) {
          updatedModelFilters[modelIndex].model_options.push(value);
        }
      } else {
        if (modelIndex !== -1) {
          updatedModelFilters[modelIndex].model_options = updatedModelFilters[
            modelIndex
          ].model_options.filter((opt) => opt !== value);

          // Remove the filter entirely if no options are selected
          if (updatedModelFilters[modelIndex].model_options.length === 0) {
            updatedModelFilters.splice(modelIndex, 1);
          }
        }
      }

      return { ...prevForm, model_filters: updatedModelFilters };
    });
  };

  useEffect(() => {
    if (formValue?.model_filters?.length > 0) {
      const newCheckedOptions = {};

      formValue.model_filters.forEach((model) => {
        if (model.model_id === tag.model_id) {
          const selectedOptions = options
            .filter(([key, value]) => model.model_options.includes(value))
            .map(([key]) => key);

          selectedOptions.forEach((option) => {
            newCheckedOptions[option] = true;
          });

          // Check if all options are selected
          const allOptionsSelected = options
            .filter(([key]) => key !== "None" && key !== "All")
            .every(([key]) => selectedOptions.includes(key));

          if (allOptionsSelected) {
            newCheckedOptions["All"] = true;
          }
        }
      });

      setCheckedOptions(newCheckedOptions);
    } else {
      setCheckedOptions({});
    }
  }, [formValue]);

  useEffect(() => {
    const checkedOptionsLength = Object.keys(checkedOptions).length;
    setVisibleOptionsLength(
      checkedOptionsLength > 4 ? checkedOptionsLength : 4
    );
  }, []);

  const sortedOptions = [...options]
    .filter(([key]) => key !== "None")
    .sort(([optionA], [optionB]) => {
      if (optionA === "All") return -1;
      if (optionB === "All") return 1;
      return !!checkedOptions[optionB] - !!checkedOptions[optionA];
    });

  const limitedOptions = sortedOptions.slice(0, visibleOptionsLength);
  const remainingOptionsCount = sortedOptions.length - visibleOptionsLength;

  return (
    <FormGroup>
      {(showAll ? sortedOptions : limitedOptions).map(([option, value]) => (
        <FormControlLabel
          key={option}
          className={checkedOptions[option] ? "checked" : ""}
          control={
            <Checkbox
              checked={!!checkedOptions[option]}
              onChange={(e) =>
                handleChange(e, value, tag.model_id, tag, option)
              }
            />
          }
          label={getModelOptionLabel(option, value)}
        />
      ))}
      {remainingOptionsCount > 0 && !showAll && (
        <FormControlLabel
          control={<Checkbox onClick={toggleShowAll} />}
          label={`${t("showMore")} (${remainingOptionsCount})`}
        />
      )}
      {showAll && (
        <FormControlLabel
          control={<Checkbox onClick={toggleShowAll} />}
          label={t("showLess")}
        />
      )}
    </FormGroup>
  );
};

export default ModelOptions;
