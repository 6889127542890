import { useLocation, useNavigate } from "react-router-dom";
import close from "../../../assets/icons/close.svg";
import Add from "../../../assets/newicons/add.svg";
import "./DesignVersion.scss";
import UploadDesignFile from "./UploadDesignFile";
import { useTranslation } from "react-i18next";
import DesignGrid from "./DesignGrid";
import { useEffect, useState } from "react";
import { imageGet, newDelete, newGet, newPut } from "../../../API/axios";
import frameImage from "../../../assets/newicons/Frame_image.png";
import gridView from "../../../assets/newicons/grid_view.svg";
import viewArray from "../../../assets/newicons/view_array.svg";
import refresh from "../../../assets/newicons/refresh.svg";
import download from "../../../assets/newicons/download.svg";
import firstArrow from "../../../assets/newicons/arrow-first-page.svg";
import lastArrow from "../../../assets/newicons/arrow-last-page.svg";
import deleteIcon from "../../../assets/newicons/delete.svg";
import { Paginator } from "primereact/paginator";
import { classNames } from "primereact/utils";
import firstPage from "../../../assets/newicons/first_page.svg";
import CommentAndTask from "./CommentAndTask";
import { useAuthContext } from "../../../context/useAuthContext";
import { showAlert } from "./AlertService";
import { useReferenceContext } from "../../../context/useReferenceContext";
const DesignVersion = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    project_id,
    version_number,
    projectDetails,
    assignee,
    internalApprover,
    clientApprover,
    design_id,
  } = location.state;
  // console.log(`Project id ${project_id}, version number ${version_number}, projectDetails ${projectDetails}, assignee ${assignee}, internalApprover ${internalApprover}, clientApprover ${clientApprover}, design_id ${design_id}`)
  const [designDetails, setdesignDetails] = useState([]);
  const [isGridView, setIsGridView] = useState(true);
  const [first, setFirst] = useState(0);
  const [showCommentSection, setShowCommentSection] = useState(true);

  const [activeImag, setActiveImage] = useState([]);
  // Slider variables
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transformValue, setTransformValue] = useState(0);
  const { userSettings, hasPermission } = useAuthContext();
  const { boardId, setBoardId } = useReferenceContext();

  const getDesigns = async () => {
    try {
      const data = await newGet(`design/getDesign?project_id=${project_id}`);
      const designFilterData = data?.result.filter(
        (design) => design?.design_id === design_id
      );
      setdesignDetails(designFilterData);
    } catch (error) {
      console.error(error);
    }
  };

  const onDownloadDesign = async (url) => {
    try {
      const response = await imageGet("download_image", { image_url: url });
      const downloadUrl = URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = url.substring(url.lastIndexOf("/") + 1);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
      // console.log("Image downloaded successfully.");
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const onDeleteDesign = async (id) => {
    try {
      const data = await newDelete(`design/delete?image_id=${id}`);
      if (data.status_code == 200) {
        getDesigns();
        // showAlert("Design deleted successfully", "success")
        //    closeModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formatCreatedTimestamp = (timestamp) => {
    if (timestamp) {
      const options = { year: "numeric", month: "short", day: "2-digit" };
      const date = new Date(timestamp);
      return date.toLocaleDateString("en-US", options);
    } else {
      return "Not specified";
    }
  };

  const onReplaceDesign = async (result) => {
    const stageId = Object.keys(result.stage)[0];
    try {
      const formData = {
        design_id: result?.design_id,
        project_Id: result?.project_id,
        stage_id: stageId,
        version_id: result?.version_id,
        is_latest: result?.is_latest,
      };
      const data = await newPut(`design/update`, formData);
      if (data.status_code == 200) {
        // showAlert("Design Replaced successfully", "success");
        // closeModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDesigns();
    setBoardId(0);
  }, []);

  const handleClientApproveReturn = async (status) => {
    try {
      const data = await newPut(`design/approveReturnDesign`, {
        project_id: project_id,
        design_id: design_id,
        version_number: version_number,
        status: status,
      });
      if (data.status_code == 200 || data.status_code == 201) {
        showAlert(`Design ${status} successfully`, "success");
      }
    } catch (err) {
      console.error("err: ", err);
    }
  };

  const handleClientSubmit = async () => {
    try {
      const data = await newPut(`design/shareWithClient`, {
        project_id: project_id,
        design_id: design_id,
        version_number: version_number,
        // status: status
      });
      if (data.status_code == 200 || data.status_code == 201) {
        getDesigns();
        showAlert("Design shared with client", "success");
      }
    } catch (err) {
      console.error("err: ", err);
    }
  };

  const DateFormatter = () => {
    const date = new Date(projectDetails?.due_date);
    const formattedDate = `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, "0")}/${String(date.getDate()).padStart(2, "0")}`;
    return formattedDate;
  };

  // Slider button Functionality
  const handlePrev = () => {
    setCurrentIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => Math.min(prev + 1, designDetails.length - 1));
  };

  const handleFirstSlide = () => {
    setCurrentIndex(0);
  };
  const handleLastSlide = () => {
    setCurrentIndex(designDetails.length - 1);
  };

  const handleTransform = () => {
    // if (curreentIndex === 1) {
    //     setTransformValue(40)
    // } else if (curreentIndex === 2) {
    //     setTransformValue(97)
    // }
    // else if (curreentIndex === 3) {
    //     setTransformValue(44)
    // } else if (curreentIndex === 4) {
    //     setTransformValue(61)
    // } else if (curreentIndex=== 5) {
    //     setTransformValue(72)
    // }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center p-3 bg_color_gray border-bottom">
          <div className="d-flex flex-column align-items-center text-center">
            <h6 className="font-noto-sans micro_ad mb-0 ">Project Name</h6>
            <h6 className="font-noto-sans micro_ad text-truncate mb-0">
              {projectDetails?.project_name || "---"}
            </h6>
          </div>

          <div className="d-flex justify-content-around align-items-baseline flex-wrap gap-3 w-100">
            {/* Approvers Section */}
            <div className="d-flex flex-column align-items-center">
              <h6 className="font-noto-sans micro_ad mb-0">{t("approver")}</h6>
              <div className="d-flex gap-1 align-items-center">
                <div className="d-flex align-items-center">
                  {internalApprover?.map((name) => (
                    <p key={name?.id} className="approver-icon mb-0">
                      <span>{name?.username?.slice(0, 2)?.toUpperCase()}</span>
                    </p>
                  ))}
                </div>
                <div className="header_line"></div>
                <div className="d-flex align-items-center">
                  {clientApprover?.map((name) => (
                    <p key={name?.id} className="approver-icon mb-0">
                      <span>{name?.username?.slice(0, 2)?.toUpperCase()}</span>
                    </p>
                  ))}
                </div>
              </div>
            </div>

            {/* Assignee Section */}
            <div className="d-flex flex-column align-items-center">
              <h6 className="font-noto-sans micro_ad mb-0">{t("assignee")}</h6>
              <div className="d-flex align-items-center">
                {assignee?.map((name) => (
                  <p key={name?.id} className="approver-icon mb-0">
                    <span>{name?.username?.slice(0, 2)?.toUpperCase()}</span>
                  </p>
                ))}
              </div>
            </div>

            {/* Due Date Section */}
            <div className="d-flex flex-column align-items-center">
              <h6 className="font-noto-sans micro_ad mb-0">{t("DueDate")}</h6>
              <h6 className="font-noto-sans micro_ad mb-0">
                {formatCreatedTimestamp(projectDetails?.due_date)}
              </h6>
            </div>

            {/* Required Sheets Section */}
            <div className="d-flex flex-column align-items-center">
              <h6 className="font-noto-sans micro_ad mb-0">
                {t("requiredSheet")}
              </h6>
              <h6 className="font-noto-sans micro_ad mb-0">
                {projectDetails?.required_sheet}
              </h6>
            </div>

            {/* Total Designs Section */}
            <div className="d-flex flex-column align-items-center">
              <h6 className="font-noto-sans micro_ad mb-0">Total Designs</h6>
              <h6 className="font-noto-sans micro_ad mb-0">
                {designDetails?.length}
              </h6>
            </div>

            {/* Approve / Return Buttons */}
            <div
              className="d-flex align-items-center justify-content-center gap-2"
              style={{ marginBlockEnd: "auto" }}
            >
              {userSettings?.permissions &&
                hasPermission(16) &&
                designDetails[0]?.is_customer !== 1 &&
                designDetails[0]?.stage !== "Approved" &&
                designDetails[0]?.stage !== "Returned" &&
                designDetails[0]?.stage !== "Approval" && (
                  <button
                    className="header-submit rounded-pill text-nowrap"
                    // data-bs-toggle="modal"
                    // data-bs-target="#exampleModalSubmit"
                    disabled={
                      !designDetails?.length > 0 ||
                      clientApprover === undefined ||
                      designDetails[0]?.stage === "Approval"
                    }
                    onClick={handleClientSubmit}
                  >
                    {t("submitForClientApproval")}
                  </button>
                )}
              {userSettings?.permissions && hasPermission(12) && (
                <button
                  className=" header-submit rounded-pill text-nowrap"
                  disabled={
                    designDetails[0]?.stage === "Approved" ||
                    designDetails[0]?.stage === "Returned"
                  }
                  onClick={() => handleClientApproveReturn("approved")}
                >
                  Approve
                </button>
              )}

              {userSettings?.permissions && hasPermission(13) && (
                <button
                  className="header-submit rounded-pill text-nowrap"
                  disabled={
                    designDetails[0]?.stage === "Returned" ||
                    designDetails[0]?.stage === "Approved"
                  }
                  onClick={() => handleClientApproveReturn("returned")}
                >
                  Return
                </button>
              )}
            </div>
          </div>

          {/* Close Button */}
          <div className="d-flex align-items-center design-close-btn">
            <img
              className="cursor-pointer"
              src={close}
              alt="Close icon"
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
      </div>

      {/* <div className='header-container'>
                <span className="font-noto-sans fs-14 text-black fw-500">
                    Design
                </span>
                <p className="font-noto-sans fw-bold fs-14 text-nowrap mb-0">
                    Total: {designDetails?.length}
                </p>
                <div className="d-flex align-items-center gap-3">
                    <img className="cursor-pointer" src={close} alt='Close icon' onClick={() => navigate(-1)} />
                </div>
            </div> */}

      {!isGridView && (
        <div className="d-flex" style={{ justifyContent: "flex-end" }}>
          <div className="slider-container">
            <div className="nav-container">
              <img
                style={{ marginTop: "3px", cursor: "pointer" }}
                src={firstArrow}
                alt="firstArrow"
                onClick={handleFirstSlide}
              />
              {/* <p className="slideBtn" onClick={handleFirstSlide} >
                                |&lt;
                            </p> */}
              <button
                className="slideBtn"
                onClick={handlePrev}
                disabled={currentIndex === 0}
              >
                &lt;
              </button>
              <p className="count">
                {currentIndex + 1} of {designDetails.length}
              </p>
              <button
                className="slideBtn"
                onClick={handleNext}
                disabled={currentIndex >= designDetails.length - 1}
              >
                &gt;
              </button>
              <img
                style={{ marginTop: "3px", cursor: "pointer" }}
                src={lastArrow}
                alt="lastArrow"
                onClick={handleLastSlide}
              />
            </div>

            <div className="slides-container">
              <div
                className="slides"
                style={{
                  transform: `translateX(-${currentIndex * 85}%)`,
                  transition: "transform 0.5s ease-in-out",
                }}
              >
                {designDetails?.map((degn, index) => (
                  <div className="slide" key={index}>
                    <img src={degn?.image_url} alt="onlyImage" />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <button
            className="sidebar-open-btn"
            onClick={() => setShowCommentSection(true)}
          >
            <span></span>
          </button>
          <div
            className={`sidebar-comment max-w-420px w-100  ${showCommentSection ? "d-block" : "d-none"}`}
          >
            <div className=" w-100 bg-color-gray border-start heigth-calc-56  comment-task-sidebar">
              <div
                className={`h-100 ${showCommentSection ? "d-block overflow-auto" : "d-none"}`}
              >
                <button
                  className="sidebar-close-btn"
                  onClick={() => setShowCommentSection(false)}
                >
                  <span></span>
                </button>
                <CommentAndTask
                  createdProjectId={project_id}
                  ProjectId={project_id}
                  design_id={design_id}
                  designVersion={version_number}
                  designDetails={designDetails}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isGridView && (
        <div className="d-flex" style={{ justifyContent: "flex-end" }}>
          <div className="grid-wrapper m-1">
            {designDetails?.map((degn) => (
              <div
                key={degn?.image_id}
                className="grid-item design_img d-flex justify-content-center align-items-center"
              >
                {/* <img src={frameImage} height={160} width={160} alt="onlyImage" /> */}
                <img
                  style={{ objectFit: "cover" }}
                  src={degn?.image_url}
                  height={160}
                  width={160}
                  alt="onlyImage"
                />

                <div className="overlay">
                  {/* {degn?.stage[Object.keys(degn?.stage)[0]] === "Returned" && */}
                  <button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => onDeleteDesign(degn?.image_id)}
                  >
                    <img
                      style={{
                        width: "15px",
                        height: "15px",
                        marginRight: "5px",
                      }}
                      src={deleteIcon}
                      alt="refresh"
                    />
                    Remove
                  </button>
                  {userSettings?.role_id === 6 && (
                    <button
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => onDownloadDesign(degn?.image_url)}
                    >
                      <img
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "5px",
                        }}
                        src={download}
                        alt="download"
                      />
                      Download
                    </button>
                  )}
                  {/* } */}

                  {/* {degn?.stage[Object.keys(degn?.stage)[0]] !== "Approved" &&
                                        <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => onDeleteDesign(degn?.design_id)}>
                                            <img style={{ width: '15px', height: '15px', marginRight: '5px' }} src={deleteIcon} alt="delete" />
                                            Remove
                                        </button>
                                     }  */}
                </div>
              </div>
            ))}
            {(userSettings?.role_id === 1 ||
              userSettings?.role_id === 2 ||
              userSettings?.role_id === 5) && (
              <div
                className="cursor_pointer"
                data-bs-toggle="modal"
                data-bs-target="#add_design"
                type="button"
              >
                <div className="add_reference d-flex justify-content-center align-items-center">
                  <div className="text-center">
                    <img src={Add} className="Add" alt="logo" />
                    <div className="add_reference_text">{t("addDesign")}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <button
            className="sidebar-open-btn"
            onClick={() => setShowCommentSection(true)}
          >
            <span></span>
          </button>
          <div
            className={`sidebar-comment max-w-420px w-100  ${showCommentSection ? "d-block" : "d-none"}`}
          >
            <div className=" w-100 bg-color-gray border-start heigth-calc-56  comment-task-sidebar">
              <div
                className={`h-100 ${showCommentSection ? "d-block overflow-auto" : "d-none"}`}
              >
                <button
                  className="sidebar-close-btn"
                  onClick={() => setShowCommentSection(false)}
                >
                  <span></span>
                </button>
                <CommentAndTask
                  createdProjectId={project_id}
                  ProjectId={project_id}
                  design_id={design_id}
                  designVersion={version_number}
                  designDetails={designDetails}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal to upload a design */}
      <div
        className="modal fade"
        id="add_design"
        tabIndex="-1"
        aria-labelledby="add_designLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content px-3">
            <div className="modal-header border-0">
              <div className="upload_design">{t("uploadDesign")}</div>
              <div className="cursor_pointer" data-bs-dismiss="modal">
                <img src={close} alt="Close" />
              </div>
            </div>
            <div className="modal-body">
              <UploadDesignFile screen="component" onUploadImage={getDesigns} />
            </div>
          </div>
        </div>
      </div>

      {/* Grid View */}
      <div
        className="d-flex align-items-center justify-content-center  "
        style={{
          position: "fixed",
          bottom: "0px",
          padding: "10px",
          marginLeft: "50px",
        }}
      >
        <button
          className={`${isGridView ? "bg-btn-zoom" : ""} border-0 bg-white px-2 rounded-3 me-1 pb-2`}
          style={{ height: "24px" }}
          onClick={() => setIsGridView(true)}
        >
          <img
            src={gridView}
            alt="gridView"
            className="align-baseline"
            width={10}
            height={10}
            style={{ display: "flex", marginTop: "7px" }}
          />
        </button>
        <button
          className={`${isGridView ? "" : "bg-btn-zoom"} border-0 bg-white px-1 rounded-3  pb-3`}
          style={{ height: "24px" }}
          onClick={() => setIsGridView(false)}
        >
          <img
            src={viewArray}
            alt="viewArrat"
            width={16}
            height={16}
            className="align-baseline"
            style={{ marginTop: "3px" }}
          />
        </button>
      </div>
      {/* <DesignGrid/> */}
    </div>
  );
};

export default DesignVersion;
