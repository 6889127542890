/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import i18next, { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { imageGet, post } from "../../API/axios";
import { ReactComponent as CloseSmall } from "../../assets/icons/close_small.svg";
import { ReactComponent as FilterList } from "../../assets/icons/filter_list.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/project-search.svg";
import { ReactComponent as BackIcon } from "../../assets/newicons/btn-back.svg";
import { ReactComponent as CloseIcon } from "../../assets/newicons/btn-close.svg";
import { ReactComponent as NextIcon } from "../../assets/newicons/btn-next.svg";
import { ReactComponent as DownloadIcon } from "../../assets/newicons/btn-save.svg";
import { ReactComponent as ShareIcon } from "../../assets/newicons/share-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/newicons/edit.svg";
import { ReactComponent as AIIcon } from "../../assets/newicons/ico-ai.svg";
import { ReactComponent as ToolIcon } from "../../assets/newicons/info2.svg";
import CardLoader from "../../components/Loaders/CardLoader";
import NoDataFound from "../../components/NoDataComponent/NoDataFound";
import { useAuthContext } from "../../context/useAuthContext";
import { useEditContext } from "../../context/useEditContext";
import { useFilterContext } from "../../context/useFilterContext";
import { useSearchContext } from "../../context/useSearchContext";
import ModelOptions from "../../helpers/ModelOptions";
import { getCategoryLabel } from "../../helpers/labels";
import "./SearchResults.scss";
import SimilarImages from "./SimilarImages";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import {
  showAlert,
  saveConfirmationDialog,
} from "../Manager/component/AlertService";
import { useSelector } from "react-redux";
import { selectDebugModeChecked } from "../../store/headerSlice";
import debounce from "debounce";

const SearchResults = () => {
  const [tags, setTags] = useState([]);
  const [counter, setCounter] = useState(8);
  const [isLoading, setIsLoading] = useState(false);
  const { filterTags } = useFilterContext();
  const [chipRemoved, setChipRemoved] = useState(false);
  const [isToggle, setIsToggle] = useState(false);

  const [resetData, setResetData] = useState({});
  const [offSetId, setOffSetId] = useState(null);
  const [isEditingTags, setIsEditingTags] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [showCTRDetails, setShowCTRDetails] = useState(false);
  const [globalGroupedImageIds, setGlobalGroupedImageIds] = useState(new Set());
  const globalGroupedImageIdsRef = useRef(globalGroupedImageIds);
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [chipList, setChipList] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showDetails, setShowDetails] = useState(null);
  const [tagInputValue, setTagInputValue] = useState("");
  const [keepDetailViewOpen, setKeepDetailViewOpen] = useState(false);
  const [activeBatchIndex, setActiveBatchIndex] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [initialFormState, setInitialFormState] = useState(null);
  const [apiCalled, setApiCalled] = useState(false);

  const isDebugModeChecked = useSelector(selectDebugModeChecked);
  const [hasFetchedData, setHasFetchedData] = useState(false);

  const [imageDetailsFilters, setImageDetailsFilters] = useState({
    industry: [],
    tags: [],
    deliveryContent: [],
    deliveryType: [],
    imageType: [],
    model_filters: [],
  });
  const {
    searchResults,
    setSearchResults,
    similarResults,
    setSimilarResults,
    results,
    similarityGroup,
    setSimilarityGroup,
    setResults,
    numberOfResults,
    setNumberOfResults,
    formValue,
    setFormValue,
    showResults,
    setShowResults,
    prevSearch,
    setPrevSearch,
    setIsEdited,
    searchView,
    setSearchViewState,
    setSelectedFilter,
    selectedFilter,
    checked,
    setChecked,
    scrollLoader,
    setScrollLoader,
    resetSearchContext,
    applyQueryParams,
    addToQueryParams,
  } = useSearchContext();
  const {
    clearEditData,
    searchQuery,
    setSearchQuery,
    refreshSearch,
    setRefreshSearch,
  } = useEditContext();
  const {
    selectedSite,
    initialLoading,
    saveSelectedSite,
    areAPIsLoading,
    isDebug,
    setIsDebug,
    userSettings,
    hasPermission,
  } = useAuthContext();
  const [filteredSites, setFilteredSites] = useState(
    userSettings?.channel_name
  );
  const en_tags = localStorage.getItem("en_tags");
  const ja_tags = localStorage.getItem("ja_tags");
  const currentLanguage = i18next.language;
  const isFirstRender = useRef(true);
  const previousSite = useRef(null);
  // const results = Array.from({ length: 48 });
  useEffect(() => {
    localStorage.setItem("searchResults", "true");
    return () => {
      resetSearchContext();
    };
  }, []);

  useEffect(() => {
    if (filterTags) {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.size > 0 && filterTags) {
        // setTimeout(() => {

        applyQueryParams(queryParams, filterTags);

        // }, 1000);
      }
    }
  }, [filterTags]);

  const searchTags = debounce(() => {
    getSearchTags();
  }, 1000);
  const getSearchTags = async () => {
    try {
      const { data } = await post(`get_all_unique_tags/`, {
        lang: "all",
      });

      localStorage.setItem("en_tags", JSON.stringify(data.en));
      localStorage.setItem("ja_tags", JSON.stringify(data.ja));

      // Combine and deduplicate tags
      const combinedTags = Array.from(
        new Set([...(data?.en || []), ...(data?.ja || [])])
      );
      setTags(combinedTags);
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    setupLanguage();
  }, [currentLanguage]);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const setupLanguage = () => {
    if (en_tags || ja_tags) {
      setFormValue({ ...formValue, tags: [] });
      setTags([...JSON.parse(en_tags), ...JSON.parse(ja_tags)]);
    } else {
      setFormValue({ ...formValue, tags: [] });
      searchTags();
    }
  };

  const dataCheck = debounce(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const defaultFormValue = {
      input_text: "",
      page: 1,
      ctr: 0,
      tags: [],
      model_filters: [],
      is_edited: "",
      search_by: "both",
    };
    const isDefaultForm =
      JSON.stringify(formValue) === JSON.stringify(defaultFormValue);
    if (isFirstLoad && queryParams.size > 0 && !isDefaultForm && filterTags) {
      setIsFirstLoad(false);
      getSearchText(false);
      if (scrollRef) {
        scrollRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }, 1000);
  useEffect(() => {
    const defaultFormValue = {
      input_text: "",
      page: 1,
      ctr: 0,
      tags: [],
      model_filters: [],
      is_edited: "",
      search_by: "both",
    };
    const isDefaultForm =
      JSON.stringify(formValue) === JSON.stringify(defaultFormValue);

    if (showResults && !isDefaultForm) {
      addToQueryParams(formValue);
    }

    dataCheck();
  }, [formValue]);

  // Remove the redundant context variable declarations (lines 63-114)
  // Add this near the top state declarations
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  // Function to check if URL has parameters
  const hasUrlParameters = () => {
    const params = new URLSearchParams(window.location.search);
    return Array.from(params.entries()).length > 0;
  };

  // Update the initialization useEffect to properly handle URL parameters
  useEffect(() => {
    if (!isInitialLoad) return;

    const params = new URLSearchParams(window.location.search);
    const hasParams = hasUrlParameters();

    if (hasParams && filterTags) {
      // Use the context function to apply URL parameters
      applyQueryParams(params, filterTags);

      // Set showResults to true to display search results immediately
      setShowResults(true);
    } else {
      setIsInitialLoad(false);
    }
  }, [isInitialLoad, filterTags, window.location.search]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      previousSite.current = selectedSite;
      return;
    }

    // Only trigger if selectedSite actually changed
    if (
      selectedSite !== previousSite.current &&
      !isLoading &&
      !initialLoading
    ) {
      getSearchText(false);
    }

    previousSite.current = selectedSite;
  }, [selectedSite]);
  // Add initialization effect
  useEffect(() => {
    // Reset states on mount
    setSearchResults([]);
    setSimilarResults([]);
    setResults([]);
    setShowResults(false);

    // If we have search parameters, trigger a new search
    if (
      (formValue.input_text ||
        selectedFilter.industry.length > 0 ||
        selectedFilter.tags.length > 0 ||
        selectedFilter.ctr > 0 ||
        selectedFilter.editableImages ||
        selectedFilter.imageType.length > 0 ||
        selectedFilter.deliveryType.length > 0 ||
        selectedFilter.deliveryContent.length > 0) &&
      !isLoading
    ) {
      // getSearchText(false);
    }
  }, []); // Only run on mount

  useEffect(() => {
    if (checked) {
      // When checked, show similar results if they exist, otherwise show all results
      setSearchResults(similarResults?.length > 0 ? similarResults : results);
    } else {
      // When unchecked, show all results
      setSearchResults(results);
    }
  }, [checked, results, similarResults]);

  useEffect(() => {
    setShowDetails(null);
  }, [isLoading]);

  useEffect(() => {
    if (filterTags) {
      filterTags.categories.forEach((category) => {
        if (category.category_key === "tags") {
          selectedFilter[category.category_key] = Object.entries(
            category.category_options
          )
            .filter(([key]) => key !== "None" && key !== "All")
            .map(([key, value]) =>
              key.replace("category_" + category.category_key + "_", "")
            );
        }
      });
    }
  }, [filterTags]);

  const industryOptions =
    filterTags?.categories?.find(
      (category) => category.category_key === "industry"
    )?.category_options || {};

    const formattedIndustryOptions = Object.entries(industryOptions).map(
      ([key, value]) => ({
        id: value,
        title: t(key),
      })
    );

  const imageTypeOptions =
    filterTags?.categories?.find(
      (category) => category.category_key === "image_type"
    )?.category_options || {};
  const deliveryTypeOptions =
    filterTags?.categories?.find(
      (category) => category.category_key === "delivery_type"
    )?.category_options || {};
  const deliveryContentOptions =
    filterTags?.categories?.find(
      (category) => category.category_key === "delivery_content"
    )?.category_options || {};
  const industryFilter =
    filterTags?.categories?.find(
      (category) => category.category_key === "industry"
    ) || {};
  const formattedIndustryOptionsKeys = Object.entries(industryOptions).map(
    ([key, value]) => ({
      id: value,
      title: key,
    })
  );

  // Add with other filter definitions
  const deliveryTypeFilter =
    filterTags?.categories?.find(
      (category) => category.category_key === "delivery_type"
    ) || {};
  const deliveryContentFilter =
    filterTags?.categories?.find(
      (category) => category.category_key === "delivery_content"
    ) || {};

  const handleChange = (filterKey, value, checked) => {
    setSelectedFilter((prev) => {
      const updatedFilters = { ...prev };

      // Get options for the current filter type
      let allFilterOptions = {};
      switch (filterKey) {
        case "imageType":
          allFilterOptions = imageTypeOptions;
          break;
        case "deliveryType":
          allFilterOptions = deliveryTypeOptions;
          break;
        case "deliveryContent":
          allFilterOptions = deliveryContentOptions;
          break;
        default:
          allFilterOptions = {};
      }

      // If clicking 'None' (-1)
      if (allFilterOptions[value] === -1) {
        if (checked) {
          // If selecting 'None', clear all other selections and only select 'None'
          updatedFilters[filterKey] = [value];
        } else {
          // If unchecking 'None', clear all selections
          updatedFilters[filterKey] = [];
        }
      }
      // If clicking 'All' (-2)
      else if (allFilterOptions[value] === -2) {
        if (checked) {
          // Select all options except 'None'
          updatedFilters[filterKey] = Object.keys(allFilterOptions).filter(
            (key) => allFilterOptions[key] !== -1
          );
        } else {
          // Deselect all
          updatedFilters[filterKey] = [];
        }
      }
      // If clicking any other option
      else {
        if (checked) {
          // Remove 'None' if it was selected
          const withoutNone = prev[filterKey].filter(
            (key) => allFilterOptions[key] !== -1
          );
          // Add the selected value
          updatedFilters[filterKey] = [...withoutNone, value];

          // Check if all regular options are selected
          const regularOptions = Object.keys(allFilterOptions).filter(
            (key) =>
              allFilterOptions[key] !== -1 && allFilterOptions[key] !== -2
          );

          if (
            regularOptions.every((option) =>
              updatedFilters[filterKey].includes(option)
            )
          ) {
            // Add 'All' option if not already included
            const allOption = Object.keys(allFilterOptions).find(
              (key) => allFilterOptions[key] === -2
            );
            if (!updatedFilters[filterKey].includes(allOption)) {
              updatedFilters[filterKey].push(allOption);
            }
          }
        } else {
          // Remove the value and 'All' option
          const allOption = Object.keys(allFilterOptions).find(
            (key) => allFilterOptions[key] === -2
          );
          updatedFilters[filterKey] = prev[filterKey].filter(
            (item) => item !== value && item !== allOption
          );
        }
      }

      return updatedFilters;
    });
  };

  const handleRadioChange = (filterKey, value) => {
    setSelectedFilter((prev) => ({
      ...prev,
      [filterKey]: prev[filterKey] === value ? "" : value,
    }));
  };

  const handleInputChange = (e) => {
    const value = Math.min(Math.max(0, parseInt(e.target.value) || 0), 100);
    setSelectedFilter((prev) => ({
      ...prev,
      ctr: value || 0,
    }));
  };

  useEffect(() => {
    if (chipRemoved) {
      getSearchText(false);
      setChipRemoved(false);
    }
  }, [chipRemoved]);

  const handleRemoveChip = (category, value) => {
    // Update selectedFilter
    const updatedFilters = { ...selectedFilter };
    if (Array.isArray(updatedFilters[category])) {
      updatedFilters[category] = updatedFilters[category].filter(
        (item) => item !== value
      );
    } else {
      updatedFilters[category] = "";
    }
    setSelectedFilter(updatedFilters);

    // Create a new form value
    const newFormValue = { ...formValue };

    // Update specific category in formValue
    switch (category) {
      case "industry":
        newFormValue.industry = updatedFilters.industry
          .map(
            (ind) =>
              formattedIndustryOptions.find((option) => option.title === ind)
                ?.id
          )
          .filter(Boolean);
        break;

      case "imageType":
        newFormValue.image_type = updatedFilters.imageType
          .map((type) => imageTypeOptions[type])
          .filter(Boolean);
        break;

      case "deliveryType":
        newFormValue.delivery_type = updatedFilters.deliveryType
          .map((type) => deliveryTypeOptions[type])
          .filter(Boolean);
        break;

      case "deliveryContent":
        newFormValue.delivery_content = updatedFilters.deliveryContent
          .map((content) => deliveryContentOptions[content])
          .filter(Boolean);
        break;

      case "imageStyle":
      case "personInImage":
        if (newFormValue.model_filters) {
          const modelFilters = [...newFormValue.model_filters];
          const modelKeyName =
            category === "imageStyle"
              ? "model_image_style"
              : "model_image_type";

          if (modelFilters.some((f) => f.model_key_name === modelKeyName)) {
            modelFilters.forEach((f) => {
              if (f.model_key_name === modelKeyName) {
                f.model_options =
                  category === "imageStyle"
                    ? updatedFilters.imageStyle.map((style) =>
                        style === "Photo" ? 0 : 1
                      )
                    : updatedFilters.personInImage.map((person) =>
                        person === "Man" ? 0 : 1
                      );
              }
            });
          }

          newFormValue.model_filters = modelFilters;
        }
        break;

      case "ctr":
        newFormValue.ctr = updatedFilters.ctr || 0;
        break;

      case "editableImages":
        newFormValue.is_edited = "";
        break;
    }

    // Update formValue and trigger search
    setFormValue(newFormValue);
    setChipRemoved(true);
  };

  const handleInputChipRemove = () => {
    setFormValue({ ...formValue, input_text: "" });
    getSearchText(false, true, { input_text: "" });

    const url = new URL(window.location.href);
    url.searchParams.set("q", "");
    window.history.pushState({}, "", url.toString());
  };

  // Function to scroll to top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const checkCategoryLengths = (payload) => {
    const updatedPayload = { ...payload };
    for (let tag of filterTags?.categories || []) {
      const categoryKey = tag?.category_key;
      const payloadKey = payload[categoryKey];

      if (payloadKey) {
        const filterTagLength = Object.keys(tag?.category_options)?.length;
        const payloadLength = payloadKey?.length;
        if (filterTagLength === payloadLength) {
          updatedPayload[categoryKey] = [];
          // delete updatedPayload[categoryKey];
        }
      }
    }

    for (let tag of filterTags?.models || []) {
      const modelKey = tag?.model_key_name;
      const payloadKey = payload?.model_filters?.find(
        (model) => model?.model_key_name === modelKey
      );

      if (payloadKey) {
        const filterTagLength = Object.keys(tag?.model_options)?.length;
        const payloadLength = payloadKey?.model_options?.length;
        if (filterTagLength === payloadLength) {
          updatedPayload.model_filters = updatedPayload?.model_filters.map(
            (item) => {
              if (item.model_key_name === modelKey) {
                return { ...item, model_options: [] };
              }
              return item;
            }
          );
        }
      }
    }
    return updatedPayload;
  };

  useEffect(() => {
    globalGroupedImageIdsRef.current = globalGroupedImageIds;
  }, [globalGroupedImageIds]);

  const resetSearch = () => {
    setNumberOfResults({
      primary: 0,
      similar: 0,
    });
    setFormValue({ ...formValue, page: 1 });
    setGlobalGroupedImageIds(new Set());
    setSimilarityGroup([]);
    setSimilarResults([]);
    setSelectedFilter({
      industry: [],
      tags: [],
      personInImage: [],
      imageStyle: [],
      deliveryContent: [],
      imageType: [],
      deliveryType: [],
      imageType: [],
      editableImages: "",
      ctr: 0,
    });
  };

  const handleSearchIcon = () => {
    setShowResults(false);
    setSearchResults([]);
    setSimilarResults([]);
    setResults([]);

    // navigate("/search");
    getSearchText(false);
    if (scrollRef) {
      scrollRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const manageSimilarGroup = (response, isNextPage = false) => {
    const newGroupedImageIds = new Set(globalGroupedImageIdsRef.current);

    const groupedImages =
      response?.data?.reduce((acc, image) => {
        const imageId = image.image_id;

        if (!newGroupedImageIds.has(imageId)) {
          const similarGroup = {
            similar_group_id: imageId,
            similar_group_images: [image],
          };
          newGroupedImageIds.add(imageId);

          const similarImageIds = response?.similar_image_map[imageId] || [];

          similarImageIds.forEach((similarId) => {
            if (!newGroupedImageIds.has(similarId)) {
              const similarImageInfo = response?.data.find(
                (img) => img.image_id === similarId
              );
              if (similarImageInfo) {
                similarGroup.similar_group_images.push(similarImageInfo);
                newGroupedImageIds.add(similarId);
              }
            }
          });

          response?.data.forEach((otherImage) => {
            if (!newGroupedImageIds.has(otherImage.image_id)) {
              const otherSimilarIds =
                response?.similar_image_map[otherImage.image_id] || [];
              if (otherSimilarIds.includes(imageId)) {
                similarGroup.similar_group_images.push(otherImage);
                newGroupedImageIds.add(otherImage.image_id);
              }
            }
          });
          acc.push(similarGroup);
        }

        return acc;
      }, []) || [];

    const groupData = groupedImages.map((group) => ({
      ...group,
      ...group.similar_group_images[0],
    }));
    if (!isNextPage) {
      setSimilarityGroup(groupedImages);
      setSimilarResults(groupData);
    } else {
      setSimilarityGroup((prevResults) => [...prevResults, ...groupedImages]);
      setSimilarResults((prevResults) => [...prevResults, ...groupData]);
    }
    setGlobalGroupedImageIds(newGroupedImageIds);
  };

  const getSearchText = async (
    isNextPage = false,
    isRefresh = true,
    customValues = {}
  ) => {
    const defaultFormValue = {
      input_text: "",
      page: 1,
      ctr: 0,
      tags: [],
      model_filters: [],
      is_edited: "",
      search_by: "both",
    };
    const isDefaultForm =
      JSON.stringify(formValue) === JSON.stringify(defaultFormValue);
    if (isDefaultForm) {
      return;
    }
    // Prevent duplicate calls if already loading
    if (isLoading && !isNextPage) return;

    if (isNextPage) {
      setScrollLoader(true);
    } else {
      if (isRefresh) {
        setIsLoading(true);
        // Reset detail view state when starting a new search
        setShowDetails(null);
        setKeepDetailViewOpen(false);
        setActiveBatchIndex(null);
        scrollToTop();
      }
    }

    setRefreshSearch(false);
    setApiCalled(true);
    // Update form value with the new page number
    const currentPage = isNextPage ? (formValue.page || 1) + 1 : 1;
    setFormValue((prev) => ({
      ...prev,
      page: currentPage,
    }));

    // Base payload construction
    let payload = {
      input_text: formValue.input_text || searchQuery?.input_text || "",
      lang: currentLanguage,
      page: currentPage,
      ctr: Number(selectedFilter.ctr || searchQuery?.ctr || 0),
      is_edited: selectedFilter.editableImages || "",
      site_id:
        typeof selectedSite?.site_id === "object"
          ? selectedSite?.site_id
          : [selectedSite?.site_id],
      search_by: "both",
      ...customValues,
    };

    // Transform selected filters into payload format
    payload.industry =
      Array.isArray(selectedFilter.industry) &&
      selectedFilter.industry.length > 0
        ? selectedFilter.industry
            .map(
              (ind) =>
                formattedIndustryOptions.find((option) => option.title === ind)
                  ?.id
            )
            .filter(Boolean)
            .filter((a) => a != null)
        : [];

    payload.tags = selectedFilter.tags;

    // if (selectedFilters.imageType?.length > 0) {
    payload.image_type = selectedFilter.imageType
      .map(
        (type) =>
          Object.entries(imageTypeOptions).find(
            ([key, value]) => key === type
          )?.[1]
      )
      .filter((a) => a != null);
    // }

    // if (selectedFilters.deliveryType?.length > 0) {
    payload.delivery_type = selectedFilter.deliveryType
      .map(
        (type) =>
          Object.entries(deliveryTypeOptions).find(
            ([key, value]) => key === type
          )?.[1]
      )
      .filter((a) => a != null);
    // }

    // if (selectedFilters.deliveryContent?.length > 0) {
    payload.delivery_content = selectedFilter.deliveryContent
      .map(
        (content) =>
          Object.entries(deliveryContentOptions).find(
            ([key, value]) => key === content
          )?.[1]
      )
      .filter((a) => a != null);
    // }

    // Filter out empty model filters before adding to payload
    if (formValue.model_filters?.length > 0) {
      const nonEmptyModelFilters = formValue.model_filters.filter(
        (filter) => filter.model_options && filter.model_options.length > 0
      );

      if (nonEmptyModelFilters.length > 0) {
        payload.model_filters = nonEmptyModelFilters;
      }
    }

    // Add CTR range if different from default
    if (selectedFilter.ctr !== 0) {
      payload.ctr = selectedFilter.ctr || 0;
    }

    // Validation checks
    if (payload?.ctr > 100) {
      toast.info("CTR cannot be greater than 100");
      setIsLoading(false);
      return;
    }
    const { tags, ctr, input_text, lang, page, site_id, is_edited, ...rest } =
      formValue;
    // payload = {
    //   ...rest,
    //   ...payload,
    // };

    if (
      !payload?.input_text &&
      payload?.ctr <= 0 &&
      !payload?.industry?.length &&
      !payload?.tags?.length &&
      !payload?.delivery_content?.length &&
      !payload?.delivery_type?.length &&
      !payload?.image_type?.length &&
      !payload?.model_filters?.length &&
      !payload?.is_edited
    ) {
      showAlert(t("oneFilterAtleast"), "warning");
      //toast.info("Apply At least One Filter!");
      setIsLoading(false);
      return;
    }

    // Clean up input text
    if (payload.input_text.includes("in:")) {
      payload.input_text = payload.input_text.replace(/in:.*/, "").trim();
    }

    setShowResults(true);
    payload = await checkCategoryLengths(payload);
    setFormValue({ ...formValue, ...payload });
    setResetData(formValue);
    // addToQueryParams(payload);
    try {
      const response = await post(
        "text_search_image/",
        offSetId && isNextPage ? { offset_id: offSetId, ...payload } : payload
      );

      handleSearchResponse(response, isNextPage);
    } catch (error) {
      setIsLoading(false);
      setScrollLoader(false);
      setShowResults(true);
      console.error("error", error);
    } finally {
      setPrevSearch(false);
    }
  };
  const handleSearchResponse = (response, isNextPage) => {
    setNumberOfResults({
      total_images: response?.pagination?.total_images_in_page,
      primary: response?.pagination?.total_images,
      similar: response?.pagination?.total_images_in_current_page,
    });

    setOffSetId(response?.pagination?.offset_id);

    if (!isNextPage) {
      // setSearchResults(response?.data);
      manageSimilarGroup(response, isNextPage);
      setResults(response?.data);
    } else if (response?.data?.length > 0) {
      // setSearchResults(prev => [...prev, ...response?.data]);
      manageSimilarGroup(response, isNextPage);
      setResults((prev) => [...prev, ...response?.data]);
    }
    setSearchResults(!checked ? results : similarResults);
    setTimeout(() => {
      // addToQueryParams(formValue);
      setIsLoading(false);
      setScrollLoader(false);
    }, 500);
  };

  //Search text changes
  const handleSearch = (e) => {
    const { name, value } = e.target;
    if (value.includes("in:")) {
      // setIsSiteSearchOpen(true);
      const searchValue = value.split("in:")[1].trim();
      const filtered = userSettings?.channel_name.filter((site) =>
        site.site_name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredSites(filtered);
    } else {
      // setIsSiteSearchOpen(false);
      setFilteredSites(userSettings?.channel_name);
    }
    setFormValue({ ...formValue, [name]: value });
  };
  // Clear all filters
  const handleClearAllFilters = () => {
    const initialFilters = {
      industry: [],
      tags: [],
      personInImage: [],
      imageStyle: [],
      deliveryContent: [],
      deliveryType: [],
      imageType: [],
      editableImages: "",
      ctr: 0,
    };

    setSelectedFilter(initialFilters);

    // Reset form value
    const resetFormValue = {
      input_text: formValue?.input_text,
      lang: currentLanguage,
      page: 1,
      ctr: 0,
      is_edited: "",
      site_id:
        typeof selectedSite?.site_id === "object"
          ? selectedSite?.site_id
          : [selectedSite?.site_id],
      search_by: "both",
      industry: [],
      tags: [],
      image_type: [],
      delivery_type: [],
      delivery_content: [],
      model_filters: [],
    };

    setFormValue(resetFormValue);
    if (filterTags?.models) {
      filterTags.models.forEach((model) => {
        const modelIndex = resetFormValue.model_filters.findIndex(
          (f) => f.model_key_name === model.model_key_name
        );
        if (modelIndex === -1) {
          resetFormValue.model_filters.push({
            model_id: model.model_id,
            model_key_name: model.model_key_name,
            model_options: [],
          });
        }
      });
    }

    // Trigger search with cleared filters
    setTimeout(() => {
      getSearchText(false);
    }, 0);
  };
  const handleSwitch = async () => {
    const newChecked = !checked;
    setChecked(newChecked);
    setIsToggle(true);
    setTimeout(() => {
      setIsToggle(false);
    }, 500);

    // Update search results based on the new checked state
    if (newChecked) {
      // When enabling similar images grouping

      setSearchResults(similarResults?.length > 0 ? similarResults : results);
    } else {
      // When disabling similar images grouping
      setSearchResults(results);
    }
  };

  // useEffect(() => {

  //   setSearchResults(!checked ? results : similarResults);
  // }, []);
  const handleResetIndustry = () => {
    // Update both selectedFilters and selectedFilter to ensure industry is properly reset
    setSelectedFilter((prev) => ({
      ...prev,
      industry: [],
    }));

    setFormValue((prev) => ({
      ...prev,
      industry: [],
    }));

    // Trigger search immediately without setTimeout
    getSearchText(false);
  };

  const handleResetTags = () => {
    setSelectedFilter((prev) => ({
      ...prev,
      tags: [],
    }));
    getSearchText(false);
  };

  const [sentryRef] = useInfiniteScroll({
    loading: scrollLoader,
    hasNextPage: numberOfResults?.similar === 50,
    onLoadMore: () => getSearchText(true),
    disabled: false,
    delayInMs: 1000,
    rootMargin: "0px 0px 450px 0px",
    threshold: 0.5,
  });

  const [similarImages, setSimilarImages] = useState([]);
  const swiperRef = useRef(null);

  // Add these helper functions for slider navigation
  const goToNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goToPrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  // Update the getImageDetails function to avoid setting image_type directly in formValue
  const getImageDetails = async (imageId, load = true) => {
    if (load) {
      setIsLoading(true);
    }
    const { image } = await post("get_image_detail/", {
      image_id: imageId ? imageId : formValue.image_id,
    });
    setImageData(image);

    // Get similar images
    try {
      const response = await post("get_similar_images/", { image_id: imageId });
      setSimilarImages(response || []);
    } catch (error) {
      console.error("Error fetching similar images:", error);
      setSimilarImages([]);
    }
    scrollToDetailsWithOffset();
    // Create a temporary object for display values WITHOUT affecting filters
    const displayFormValue = {
      image_id: image?.image_id || 0,
      image_uuid: image?.image_uuid || "",
      lang: currentLanguage,
      edit_description:
        image[
          currentLanguage === "ja"
            ? "image_description_ja"
            : "image_description"
        ] || "",
      edit_tags: [
        ...(image["image_en_tags"] || []),
        ...(image["image_ja_tags"] || []),
      ],
      inputTag: "",
      image_url: image?.image_url || "",
      ctr: image?.ctr || 0,
      score: image?.score,
      model_filters: image?.model_filters,
      image_size: image?.image_size,
      image_extension: image?.image_extension,
      industry: image?.industry,
      text_extraction: image?.text_extraction,
      delivery_type: image?.delivery_type,
      delivery_content: image?.delivery_content,
      _display_image_type: image?.image_type,
    };
    setShowDetails(image);

    // Update formValue while preserving existing filter-related fields
    // setFormValue(prev => ({
    //   ...prev,
    //   ...displayFormValue,
    //   // Preserve existing filter-related fields
    //   industry: prev.industry,
    //   tags: prev.tags,
    //   image_type: prev.image_type,
    //   delivery_type: prev.delivery_type,
    //   delivery_content: prev.delivery_content,
    //   model_filters: prev.model_filters,
    //   ctr: prev.ctr,
    //   is_edited: prev.is_edited
    // }));

    if (load) {
      setIsLoading(false);
    }
  };

  // Add a ref for the details view
  const detailsRef = useRef(null);

  // Add a scroll helper function to handle scroll offset
  const scrollToDetailsWithOffset = () => {
    if (!detailsRef.current) return;

    setTimeout(() => {
      const headerHeight = 147; // Fixed header height
      const yOffset = headerHeight + 20; // Add some extra padding
      const element = detailsRef.current;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset - yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }, 150);
  };

  // Update this function to use the scroll helper
  const setShowDetailsAndBatch = (item, batchIndex) => {
    setShowDetails(item);
    setIsEditingTags(false);
    // setImageDetailsFilters(item);
    setActiveBatchIndex(batchIndex);
    setKeepDetailViewOpen(true);
    getImageDetails(item.image_id, false);
    scrollToDetailsWithOffset();
  };

  const handleSimilarImageClick = async (image) => {
    // Find if the similar image exists in search results
    const imageInResults = searchResults.find(
      (item) => item.image_id === image.similar_image_id
    );

    if (imageInResults) {
      // If found in search results, find which batch it belongs to
      let foundBatchIndex = null;
      const batches = createBatches(searchResults || [], counter);

      for (let i = 0; i < batches.length; i++) {
        if (
          batches[i].some((item) => item.image_id === image.similar_image_id)
        ) {
          foundBatchIndex = i;
          break;
        }
      }

      if (foundBatchIndex !== null) {
        setShowDetails(imageInResults);
        setActiveBatchIndex(foundBatchIndex);
      } else {
        // Fallback, should not happen
        setShowDetails(imageInResults);
        setKeepDetailViewOpen(true);
      }
    } else {
      // If not in search results, keep current batch open but update details
      setShowDetails({
        ...showDetails,
        image_id: image.similar_image_id,
        image_url: image.image_url,
      });
      setKeepDetailViewOpen(true);
    }

    await getImageDetails(image.similar_image_id, false);
    scrollToDetailsWithOffset();
  };

  const handleDownload = async (url) => {
    try {
      // setIsSaving(true);
      const response = await imageGet("download_image", { image_url: url });
      const downloadUrl = URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = url.substring(url.lastIndexOf("/") + 1);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
      console.log("Image downloaded successfully.");
      // setIsSaving(false);
      // setIsEdited(false);
    } catch (error) {
      // setIsSaving(false);
      // setIsEdited(false);
      console.error("Error downloading image:", error);
    }
  };

  const handleShare = async (url) => {
    try {
      // Copy URL to clipboard
      await navigator.clipboard.writeText(url);
      showAlert(t("LinkCopied"), "success");
    } catch (err) {
      console.error("Failed to copy URL: ", err);
    }
  };

  const handlePreviousImage = () => {
    askToSave(() => {
      setIsEditingTags(false);
      const currentIndex = searchResults.findIndex(
        (item) => item.image_id === showDetails?.image_id
      );
      if (currentIndex > 0) {
        const previousItem = searchResults[currentIndex - 1];
        setShowDetails(previousItem);
        getImageDetails(previousItem.image_id, false);
      }
    });
  };

  const handleNextImage = () => {
    askToSave(() => {
      setIsEditingTags(false);
      const currentIndex = searchResults.findIndex(
        (item) => item.image_id === showDetails?.image_id
      );
      if (currentIndex < searchResults?.length - 1) {
        const nextItem = searchResults[currentIndex + 1];
        setShowDetails(nextItem);
        getImageDetails(nextItem.image_id, false);
      }
    });
  };

  useEffect(() => {
    if (showDetails && imageData) {
      const industry = showDetails?.industry?.map((ind) => {
        return getCategoryLabel("industry", ind, filterTags);
      });

      setImageDetailsFilters({
        image_id: showDetails?.image_id,
        image_uuid: showDetails?.image_uuid,
        tags:
          showDetails?.[
            currentLanguage === "ja" ? "image_ja_tags" : "image_en_tags"
          ] || [],
        model_filters: showDetails?.model_filters || [],
        industry,
        deliveryType:
          showDetails?.delivery_type?.map((type) =>
            Object.keys(deliveryTypeOptions).find(
              (key) => deliveryTypeOptions[key] === type
            )
          ) || [],
        deliveryContent:
          showDetails?.delivery_content?.map((content) =>
            Object.keys(deliveryContentOptions).find(
              (key) => deliveryContentOptions[key] === content
            )
          ) || [],
        imageType:
          showDetails?.image_type?.map((type) =>
            Object.keys(imageTypeOptions).find(
              (key) => imageTypeOptions[key] === type
            )
          ) || [],
        image_description: showDetails?.image_description || "",
        image_description_ja: showDetails?.image_description_ja || "",
        text_extraction: showDetails?.text_extraction || "",
      });
    }
  }, [showDetails, imageData, formValue]);

  const renderCategoryDetails = (label, items, field) => (
    <>
      {items && items?.length > 0 ? (
        items.map((item, index) => (
          <li key={index}>
            {t(
              typeof item === "string"
                ? item
                : getCategoryLabel(field, item, filterTags)
            )}
          </li>
        ))
      ) : (
        <li>{t("None")}</li>
      )}
    </>
  );
  const renderModelFilters = () => {
    return formValue?.model_filters?.map((filter, index) => {
      // Find the matching tag from filterTags.models
      const modelTag = filterTags?.models?.find(
        (model) => model.model_key_name === filter.model_key_name
      );

      if (!modelTag) return null;

      // Convert model_options object to array format for Autocomplete
      const options = Object.entries(modelTag.model_options)
        .filter(([key]) => !["None", "All"].includes(key))
        .map(([key, value]) => ({
          label: t(key.replace(`${modelTag.model_key_name}_`, "")),
          value: value,
        }));

      return (
        <div className="addTag" key={index}>
          <h5>{t(modelTag.model_display_code)}</h5>
          {isEditingTags ? (
            <Autocomplete
              multiple
              options={options}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              value={options.filter(
                (option) =>
                  imageDetailsFilters.model_filters
                    .find((f) => f.model_key_name === filter.model_key_name)
                    ?.model_options?.includes(option.value) || false
              )}
              onChange={(event, newValue) => {
                const updatedModelFilters =
                  imageDetailsFilters.model_filters.map((f) =>
                    f.model_key_name === filter.model_key_name
                      ? { ...f, model_options: newValue.map((v) => v.value) }
                      : f
                  );
                setImageDetailsFilters((prev) => ({
                  ...prev,
                  model_filters: updatedModelFilters,
                }));
                setFormValue((prev) => ({
                  ...prev,
                  model_filters: updatedModelFilters,
                }));
              }}
              renderOption={(props, option, { selected }) => {
                const { key, ...otherProps } = props;
                return (
                  <li key={key} {...otherProps}>
                    <Checkbox checked={selected} />
                    {option.label}
                  </li>
                );
              }}
              style={{
                width: "100%",
                border: "1px solid #B3B3B3",
                borderRadius: 8,
              }}
              renderInput={(params) => (
                <TextField {...params} label={t(modelTag.model_display_code)} />
              )}
            />
          ) : (
            <ul>
              {filter.model_options?.map((optionValue) => {
                const option = options.find((opt) => opt.value === optionValue);
                return option && <li key={optionValue}>{option.label}</li>;
              })}
            </ul>
          )}
        </div>
      );
    });
  };

  // Update the handleEdit function to include image_type only when explicitly saving
  const handleEdit = async () => {
    setSaving(true);

    try {
      // Store current CTR value
      const currentCtr = selectedFilter.ctr;

      // Construct base image data for API
      const baseImageData = {
        image_uuid: imageDetailsFilters.image_uuid,
        lang: currentLanguage,
        edit_description:
          imageDetailsFilters?.[
            currentLanguage == "ja"
              ? "image_description_ja"
              : "image_description"
          ]?.trimEnd() || "",
        edit_tags: imageDetailsFilters.tags || [],
        image_id: imageDetailsFilters.image_id,
        industry:
          imageDetailsFilters?.industry
            ?.map(
              (ind) =>
                formattedIndustryOptionsKeys.find(
                  (option) => option.title === ind
                )?.id
            )
            ?.filter(Boolean) || [],
        delivery_content:
          imageDetailsFilters?.deliveryContent
            ?.map((content) => deliveryContentOptions[content])
            ?.filter(Boolean) || [],
        delivery_type:
          imageDetailsFilters?.deliveryType
            ?.map((type) => deliveryTypeOptions[type])
            ?.filter(Boolean) || [],
        image_type:
          imageDetailsFilters?.imageType
            ?.map((type) => imageTypeOptions[type])
            ?.filter(Boolean) || [],
        model_filters: imageDetailsFilters?.model_filters,
        text_extraction: imageDetailsFilters?.text_extraction || "",
      };

      let apiPayload;

      // Handle bulk editing for similar images
      if (checked && similarImages?.similar_image_list?.length > 1) {
        apiPayload = {
          image_list: similarImages.similar_image_list.map((image) => ({
            ...baseImageData,
            image_id: image.image_id,
            image_uuid: image.image_uuid,
          })),
        };
      } else {
        // Single image edit
        apiPayload = {
          image_list: [baseImageData],
        };
      }

      const response = await post("edit_images/", apiPayload);

      if (response.status_code === 200) {
        // toast.success(t("Description Updated Successfully"));
        showAlert(t("ImageDetailsUpdatedSuccessfully"), "success");

        setIsEdited(true);
        setPrevSearch(true);
        setIsEditingTags(false);

        // Update formValue to preserve CTR before refreshing search
        // setFormValue(prev => ({
        //   ...prev,
        //   ctr: currentCtr
        // }));

        // Preserve CTR in selectedFilter
        setSelectedFilter((prev) => ({
          ...prev,
          ctr: currentCtr,
        }));
        getImageDetails(imageDetailsFilters.image_id, false);

        // Pass false to refresh search while maintaining current filters
        // await getSearchText(false,false);
      } else {
        throw new Error("API call failed");
      }
    } catch (error) {
      console.error("Error updating image:", error);
      showAlert(t("failedToUpdateImage"), "error");
    } finally {
      setSaving(false);
    }
  };

  // Add this helper function to create batches
  const createBatches = (data, batchSize) => {
    const batches = [];
    for (let i = 0; i < data?.length; i += batchSize) {
      batches.push(data.slice(i, i + batchSize));
    }
    return batches;
  };

  // Update this function to enforce min 4 and max 8 columns
  const calculateColumns = () => {
    const width = window.innerWidth;
    if (width < 768) return 4; // Mobile
    if (width < 1024) return Math.min(6, counter); // Tablet
    if (width < 1440) return Math.min(7, counter); // Small Desktop
    return Math.min(8, counter); // Large Desktop and up
  };

  // Update the initial counter value in useEffect
  useEffect(() => {
    const handleResize = () => {
      const calculatedColumns = calculateColumns();
      setCounter((prev) =>
        Math.min(Math.max(4, prev), Math.min(8, calculatedColumns))
      );
    };
    setCounter(6);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Update counter when window resizes
  useEffect(() => {
    const handleResize = () => {
      const calculatedColumns = calculateColumns();
      // Preserve user's chosen column count when possible
      setCounter((prev) => Math.min(prev, calculatedColumns));
    };

    // Set initial value to a moderate number of columns
    setCounter(6); // or any default value you prefer

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Update the counter increment/decrement handlers
  const handleIncrement = (event) => {
    event.preventDefault();
    const maxColumns = Math.max(8, calculateColumns()); // Allow up to 12 columns or more
    setCounter((prev) => Math.min(maxColumns, prev + 1));
    // Don't close details view when changing grid size
  };

  const handleDecrement = () => {
    setCounter((prev) => Math.max(4, prev - 1));
    // Don't close details view when changing grid size
  };

  // Add this helper function
  const getModelDisplayLabel = (modelKeyName, value) => {
    if (modelKeyName === "model_image_type") {
      switch (value) {
        case 0:
          return "Photo";
        case 1:
          return "Illustratio";
        // Remove -1 and -2 cases since we don't want to show chips for All/None
        default:
          return "";
      }
    }
    return "";
  };

  // Add this function to handle model filter removal
  const handleRemoveModelFilter = (modelKeyName, optionValue) => {
    setFormValue((prev) => {
      const updatedModelFilters = prev.model_filters
        .map((filter) => {
          if (filter.model_key_name === modelKeyName) {
            const updatedOptions = filter.model_options.filter(
              (opt) => opt !== optionValue
            );
            return {
              ...filter,
              model_options: updatedOptions,
            };
          }
          return filter;
        })
        .filter((filter) => filter.model_options.length > 0); // Remove filters with no options

      return {
        ...prev,
        model_filters: updatedModelFilters,
      };
    });

    setTimeout(() => {
      getSearchText(false);
    }, 0);
  };
  const handleTagInput = (event, newValue, reason) => {
    if (event.key === "Enter" && tagInputValue.trim()) {
      event.preventDefault();

      if (!imageDetailsFilters.tags.includes(tagInputValue.trim())) {
        setImageDetailsFilters((prev) => ({
          ...prev,
          tags: [...(prev.tags || []), tagInputValue.trim()],
        }));
      }
      setTagInputValue("");
    }
  };

  // Add these after other useState declarations
  const [virtualizedOptions, setVirtualizedOptions] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const ITEMS_PER_PAGE = 20;

  // Add this useEffect with your other effects
  useEffect(() => {
    setVirtualizedOptions([]);
    setPage(0);
    setHasMore(true);
    loadMoreOptions();
  }, [tags]);

  // Add this helper function
  const loadMoreOptions = () => {
    if (!hasMore) return;
    const start = page * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    const newOptions = tags?.slice(start, end) || [];

    if (newOptions.length < ITEMS_PER_PAGE) {
      setHasMore(false);
    }
    setVirtualizedOptions((prev) => [...prev, ...newOptions]);
    setPage((prev) => prev + 1);
  };

  // Add the new handleIndustryChange function
  const handleIndustryChange = (newValue) => {
    // Find the English and Japanese "All" and "None" options
    const allOption = formattedIndustryOptions.find(
      (opt) => opt.title === t("All") // Check English and Japanese
    );
    const noneOption = formattedIndustryOptions.find(
      (opt) => opt.title === t("None") // Check English and Japanese
    );

    // Check if "All" (English or Japanese) was previously selected
    const wasAllSelected = selectedFilter.industry.some(
      (title) => title === t("All")
    );
    // Check if "All" (English or Japanese) is currently selected in the new value
    const isAllSelected = newValue.some((opt) => opt.title === t("All"));
    // Check if "None" (English or Japanese) is currently selected
    const isNoneSelected = newValue.some((opt) => opt.title === t("None"));

    let finalSelection;

    if (isNoneSelected) {
      // If "None" is selected, clear everything else
      finalSelection = [noneOption];
    } else if (!wasAllSelected && isAllSelected) {
      // If "All" (English or Japanese) was just selected, select everything except "None" (English or Japanese)
      finalSelection = formattedIndustryOptions.filter(
        (opt) => opt.title !== t("None")
      );
    } else if (wasAllSelected && !isAllSelected) {
      // If "All" (English or Japanese) was deselected, clear everything
      finalSelection = [];
    } else if (
      wasAllSelected &&
      newValue.length < formattedIndustryOptions.length
    ) {
      // If we had "All" selected and deselected something, remove "All" (English and Japanese) and keep others
      finalSelection = newValue.filter((opt) => opt.title !== t("All"));
    } else {
      // Normal selection
      // Filter out "None" if other items are selected
      finalSelection = newValue.filter((opt) => opt.title !== t("None"));
    }

    // Update formValue with IDs
    setFormValue((prev) => ({
      ...prev,
      industry: finalSelection.map((option) => option.id),
    }));
  
    // Update selectedFilter with titles
    setSelectedFilter((prev) => ({
      ...prev,
      industry: finalSelection.map((option) => option.title),
    }));
  };
  
  // Add this function to check if form has changes
  const hasFormChanges = () => {
    if (!initialFormState || !imageDetailsFilters) return false;

    // Check for changes in each field
    const hasTagChanges =
      JSON.stringify(initialFormState.tags) !==
      JSON.stringify(imageDetailsFilters.tags);
    const hasIndustryChanges =
      JSON.stringify(initialFormState.industry) !==
      JSON.stringify(imageDetailsFilters.industry);
    const hasDeliveryContentChanges =
      JSON.stringify(initialFormState.deliveryContent) !==
      JSON.stringify(imageDetailsFilters.deliveryContent);
    const hasDeliveryTypeChanges =
      JSON.stringify(initialFormState.deliveryType) !==
      JSON.stringify(imageDetailsFilters.deliveryType);
    const hasImageTypeChanges =
      JSON.stringify(initialFormState.imageType) !==
      JSON.stringify(imageDetailsFilters.imageType);
    const hasDescriptionChanges =
      initialFormState.image_description !==
      imageDetailsFilters.image_description;
    const hasDescriptionJaChanges =
      initialFormState.image_description_ja !==
      imageDetailsFilters.image_description_ja;

    return (
      hasTagChanges ||
      hasIndustryChanges ||
      hasDeliveryContentChanges ||
      hasDeliveryTypeChanges ||
      hasImageTypeChanges ||
      hasDescriptionChanges ||
      hasDescriptionJaChanges
    );
  };

  const saveConfirmation = (callback) => {
    saveConfirmationDialog(
      t("There are your unsaved changes do you wanna to save it?"),
      "",
      t("No"),
      t("Yes")
    ).then((result) => {
      if (result.isConfirmed) {
        callback(true);
      } else {
        callback(false);
      }
    });
  };

  const askToSave = (callback) => {
    if (isEditingTags) {
      if (hasFormChanges()) {
        saveConfirmation((result) => {
          if (!result) {
            callback();
          } else {
            handleEdit();
          }
        });
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  // Add this function to handle cancel click
  const handleCancelClick = () => {
    if (hasFormChanges()) {
      saveConfirmation((result) => {
        if (!result) {
          setImageDetailsFilters(initialFormState);
          setIsEditingTags(false);
          setShowDetails({ ...showDetails });
        } else {
          handleEdit();
        }
      });
    } else {
      setIsEditingTags(false);
      setShowDetails({ ...showDetails });
    }
  };

  // Update useEffect to store initial form state when editing starts
  useEffect(() => {
    if (isEditingTags && imageDetailsFilters) {
      setInitialFormState({ ...imageDetailsFilters });
    }
  }, [isEditingTags]);

  const shouldShowNoData =
    !isLoading &&
    searchResults?.length === 0 &&
    hasFetchedData &&
    !scrollLoader;

  useEffect(() => {
    if (apiCalled && !isLoading) {
      setHasFetchedData(true);
    }
  }, [apiCalled, isLoading]);

  return (
    <section className="heroResult">
      <div className="container-fluid">
        <div className="stickyHeader">
          <div className="searchHeader">
            <div className="searchInput">
              <div className="searcIcon">
                <SearchIcon />
              </div>
              <input
                type="text"
                placeholder={t("Search...")}
                value={formValue.input_text}
                name="input_text"
                onChange={(e) => handleSearch(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearchIcon();
                  }
                }}
              />
            </div>
            <div className="counterWrapper">
              <Link
                to="#"
                onClick={counter > 4 ? handleDecrement : undefined}
                style={{
                  pointerEvents: counter === 4 ? "none" : "auto",
                  opacity: counter === 4 ? 0.5 : 1,
                }}
              >
                <img
                  src={require("../../assets/icons/decreament.png")}
                  alt=""
                  className="img-fluid"
                />
              </Link>

              <p>{counter}</p>
              <Link
                to="#"
                onClick={counter < 8 ? handleIncrement : undefined}
                style={{
                  pointerEvents: counter === 8 ? "none" : "auto",
                  opacity: counter === 8 ? 0.5 : 1,
                }}
              >
                <img
                  src={require("../../assets/icons/Increament.png")}
                  alt=""
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
          <div className="chipWrapper">
            <div
              className="filterIcon"
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              <FilterList />
            </div>
            <ul
              style={chipList ? { flexWrap: "wrap" } : { flexWrap: "nowrap" }}
            >
              {formValue?.input_text ? (
                <li key="search-input">
                  {formValue?.input_text}

                  <CloseSmall onClick={() => handleInputChipRemove()} />
                </li>
              ) : (
                <></>
              )}
              {Object.entries(selectedFilter)?.map(([category, values]) =>
                (Array.isArray(values) ? values : [values])
                  .filter((value) => value && value !== "All")
                  .map((value, index) => (
                    <li key={`${category}-${index}`}>
                      {t(category === "ctr" ? `${value}%` : `${value}`)}
                      <CloseSmall
                        onClick={() => handleRemoveChip(category, value)}
                      />
                    </li>
                  ))
              )}
              {formValue?.model_filters?.map((filter) =>
                filter.model_options
                  ?.map((option, index) => {
                    const label = getModelDisplayLabel(
                      filter.model_key_name,
                      option
                    );
                    if (label && option !== -1 && option !== -2) {
                      return (
                        <li key={`${filter.model_key_name}-${index}`}>
                          {label}
                          <CloseSmall
                            onClick={() =>
                              handleRemoveModelFilter(
                                filter.model_key_name,
                                option
                              )
                            }
                          />
                        </li>
                      );
                    }
                    return null;
                  })
                  .filter(Boolean)
              )}
            </ul>
            {isDebugModeChecked && (
              <div
                className="searchResult"
                onClick={() => setChipList((prev) => !prev)}
              >
                <p>
                  <span>
                    {checked
                      ? t("totalHiddenResults", {
                          count: results?.length || 0,
                          hidden:
                            (results?.length || 0) -
                            (similarResults?.length || 0),
                        })
                      : t("totalResults", { count: results?.length || 0 })}
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="main-result-wrapper">
              {isFilterOpen && (
                <div className="filterWrap">
                  <div className="filterHeader">
                    <h5>{t("filterstitle")}</h5>
                    <img
                      src={require("../../assets/icons/close.png")}
                      alt=""
                      className="img-fluid"
                      onClick={() => setIsFilterOpen(!isFilterOpen)}
                    />
                  </div>
                  {/* <div className="displayOption">
                  <p>{t('filterssearchDisplayOptions')}</p>
                  <Link to="#">{t('filtersreset')}</Link>
                </div> */}
                  <div className="similarImg">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            defaultChecked={checked}
                            onChange={handleSwitch}
                          />
                        }
                        label={t("filtersgroupSimilarImages")}
                      />
                    </FormGroup>
                  </div>
                  {/* industry filter section --- start */}
                  <div className="industryWrap">
                    <div className="indTitle">
                      <p>{t(industryFilter?.category_display_code)}</p>
                      <Link to="#" onClick={handleResetIndustry}>
                        {t("filtersreset")}
                      </Link>
                    </div>
                    <div className="indBody">
                      <Autocomplete
                        multiple
                        options={formattedIndustryOptions}
                        disableCloseOnSelect
                        disablePortal={true}
                        getOptionLabel={(option) => option.title}
                        value={formattedIndustryOptions.filter(
                          (option) =>
                            selectedFilter.industry.find((ind) => {
                              if (ind?.includes("_")) {
                                return t(ind) === option.title;
                              } else {
                                return ind === option.title;
                              }
                            }) || false
                        )}
                        onChange={(event, newValue) =>
                          handleIndustryChange(newValue)
                        }
                        className="autoCompleteDropdown"
                        renderOption={(props, option, { selected }) => {
                          const { key, ...otherProps } = props;
                          return (
                            <li key={key} {...otherProps}>
                              <Checkbox checked={selected} />
                              {option.title}
                            </li>
                          );
                        }}
                        style={{
                          width: "100%",
                          border: "1px solid #B3B3B3",
                          borderRadius: 8,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("filters.industry")}
                          />
                        )}
                        sx={{
                          ".MuiAutocomplete-endAdornment": {
                            display: "none",
                          },
                        }}
                      />
                    </div>
                  </div>
                  {/* tag section --- start */}
                  <div className="industryWrap">
                    <div className="indTitle">
                      <p>{t("tags")}</p>
                      <Link to="#" onClick={handleResetTags}>
                        {t("filtersreset")}
                      </Link>
                    </div>
                    <div className="indBody">
                      <Autocomplete
                        multiple
                        options={tags || []}
                        disableCloseOnSelect
                        disablePortal={true}
                        getOptionLabel={(option) => option}
                        value={selectedFilter.tags || []}
                        onChange={(event, newValue) => {
                          setSelectedFilter((prev) => ({
                            ...prev,
                            tags: newValue,
                          }));
                          setFormValue((prev) => ({
                            ...prev,
                            tags: newValue,
                          }));
                        }}
                        filterOptions={(options, { inputValue }) => {
                          // Only filter if there's input value to improve performance
                          if (!inputValue) {
                            return options.slice(0, 100); // Show first 100 options when no input
                          }
                          return options
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            )
                            .slice(0, 100); // Limit filtered results to 100
                        }}
                        ListboxProps={{
                          style: {
                            maxHeight: "300px",
                          },
                        }}
                        onInputChange={(event, value, reason) => {
                          if (reason === "input") {
                            // Add debouncing logic here if needed
                            clearTimeout(window.searchTimeout);
                            window.searchTimeout = setTimeout(() => {
                              // Additional filtering logic if needed
                            }, 300);
                          }
                        }}
                        className="autoCompleteDropdown"
                        renderOption={(props, option, { selected }) => {
                          const { key, ...otherProps } = props;
                          return (
                            <li key={key} {...otherProps}>
                              <Checkbox checked={selected} />
                              {option}
                            </li>
                          );
                        }}
                        style={{
                          width: "100%",
                          border: "1px solid #B3B3B3",
                          borderRadius: 8,
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label={t("filters.tags")} />
                        )}
                        loading={false}
                        loadingText="Loading..."
                        noOptionsText={t("No matching tags")}
                        blurOnSelect={false}
                        clearOnBlur={false}
                        sx={{
                          ".MuiAutocomplete-endAdornment": {
                            display: "none",
                          },
                        }}
                      />
                    </div>
                  </div>
                  {/* ctr section --- start */}
                  <div className="industryWrap">
                    <div className="indTitle">
                      <p>{t("ctr")}</p>
                      <Link to="#">{t("filtersreset")}</Link>
                    </div>
                    <div className="indBody">
                      <div className="inputWrap">
                        <div className="inputbox">
                          <input
                            type="number"
                            min={0}
                            max={100}
                            value={selectedFilter.ctr}
                            onChange={handleInputChange}
                            onKeyDown={(e) => {
                              if (
                                !/^[0-9\b]+$/.test(e.key) &&
                                e.key !== "Backspace" &&
                                e.key !== "Delete" &&
                                e.key !== "ArrowLeft" &&
                                e.key !== "ArrowRight"
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          <div className="perIcon">
                            <img
                              src={require("../../assets/icons/percentage.png")}
                              alt="mico"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <FormControlLabel control={<Checkbox value={'abc'} />} label={t('filtersincludeUncalculatedCTR')} /> */}
                    </div>
                  </div>
                  {/* Image Status --- start */}
                  <div className="industryWrap">
                    <div className="indTitle">
                      <p>{t("imageStatus")}</p>
                    </div>
                    <div className="indBody">
                      <div className="checklist">
                        <FormGroup>
                          {[
                            { key: "edited", label: t("edited") },
                            { key: "unedited", label: t("unEdited") },
                          ].map(({ key, label }) => (
                            <FormControlLabel
                              key={key}
                              className={
                                selectedFilter.editableImages === key
                                  ? "checked"
                                  : ""
                              }
                              control={
                                <Checkbox
                                  checked={
                                    selectedFilter.editableImages === key
                                  }
                                  onChange={() =>
                                    handleRadioChange("editableImages", key)
                                  }
                                />
                              }
                              label={label}
                            />
                          ))}
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  {/* Image Type --- start */}
                  <div className="industryWrap">
                    <div className="indTitle">
                      <p>{t("imageType")}</p>
                    </div>
                    <div className="indBody">
                      <div className="checklist">
                        <FormGroup>
                          {Object.entries(imageTypeOptions).map(
                            ([key, value]) => {
                              return (
                                <FormControlLabel
                                  key={key}
                                  className={
                                    selectedFilter.imageType.includes(key)
                                      ? "checked"
                                      : ""
                                  }
                                  control={
                                    <Checkbox
                                      checked={selectedFilter.imageType.includes(
                                        key
                                      )}
                                      onChange={(e) =>
                                        handleChange(
                                          "imageType",
                                          key,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  }
                                  label={t(
                                    key.replace("category_imageType_", "")
                                  )}
                                />
                              );
                            }
                          )}
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  {/* delivery type --- start */}
                  <div className="industryWrap">
                    <div className="indTitle">
                      <p>{t(deliveryTypeFilter?.category_display_code)}</p>
                    </div>
                    <div className="indBody">
                      <div className="checklist">
                        <FormGroup>
                          {Object.entries(deliveryTypeOptions).map(
                            ([key, value]) => {
                              return (
                                <FormControlLabel
                                  key={key}
                                  className={
                                    selectedFilter.deliveryType.includes(key)
                                      ? "checked"
                                      : ""
                                  }
                                  control={
                                    <Checkbox
                                      checked={selectedFilter.deliveryType.includes(
                                        key
                                      )}
                                      onChange={(e) =>
                                        handleChange(
                                          "deliveryType",
                                          key,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  }
                                  label={t(
                                    key.replace("category_deliveryType_", "")
                                  )}
                                />
                              );
                            }
                          )}
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  {/* delivery content --- start */}
                  <div className="industryWrap">
                    <div className="indTitle">
                      <p>{t(deliveryContentFilter?.category_display_code)}</p>
                    </div>
                    <div className="indBody">
                      <div className="checklist">
                        <FormGroup>
                          {Object.entries(deliveryContentOptions).map(
                            ([key, value]) => (
                              <FormControlLabel
                                key={key}
                                className={
                                  selectedFilter.deliveryContent.includes(key)
                                    ? "checked"
                                    : ""
                                }
                                control={
                                  <Checkbox
                                    checked={selectedFilter.deliveryContent.includes(
                                      key
                                    )}
                                    onChange={(e) =>
                                      handleChange(
                                        "deliveryContent",
                                        key,
                                        e.target.checked
                                      )
                                    }
                                  />
                                }
                                label={t(
                                  key.replace("category_deliveryContent_", "")
                                )}
                              />
                            )
                          )}
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  {filterTags?.models?.map((tag, index) => {
                    return (
                      <div className="industryWrap" key={index}>
                        <div className="indTitle">
                          <p>{t(tag.model_display_code)}</p>
                        </div>
                        <div className="indBody">
                          <div className="checklist">
                            {
                              <ModelOptions
                                tag={tag}
                                formValue={formValue}
                                setFormValue={setFormValue}
                              />
                            }
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="buttongrp">
                    <button onClick={handleClearAllFilters}>
                      {t("filtersclearAll")}
                    </button>
                    <button
                      onClick={() => {
                        getSearchText(false);
                        scrollToTop();
                      }}
                    >
                      {t("filtersapply")}
                    </button>
                  </div>
                </div>
              )}
              <div className={`filterResult ${isFilterOpen ? "active" : ""}`}>
                {isLoading || isToggle ? (
                  <div className="result-batch">
                    <div className={`batch-grid column${counter}`}>
                      {[...Array(50)].map((_, i) => (
                        <div className="resultBoxWrapper" key={i}>
                          <div className="resultBox">
                            <CardLoader />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : shouldShowNoData ? (
                  <NoDataFound />
                ) : (
                  <>
                    {createBatches(searchResults || [], counter)?.map(
                      (batch, batchIndex) => (
                        <div key={batchIndex} className="result-batch">
                          <div className={`batch-grid column${counter}`}>
                            {batch?.map((item, index) => (
                              <div className="resultBoxWrapper" key={index}>
                                <div
                                  className={`resultBox ${
                                    showDetails?.image_id === item.image_id
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    setShowDetailsAndBatch(item, batchIndex);
                                  }}
                                >
                                  <LazyLoadImage
                                    src={item?.image_url}
                                    alt={`micovision`}
                                    className="img-lazy"
                                    delayTime={2000}
                                    effect="blur"
                                  />
                                  <div className="ctr-tag">
                                    <p>{(item?.ctr[1] * 100).toFixed(2)}%</p>
                                  </div>
                                </div>
                              </div>
                            ))}
                            {/* Add skeleton loaders in the same batch if it's the last batch and loading more */}
                            {batchIndex ===
                              createBatches(searchResults || [], counter)
                                ?.length -
                                1 &&
                              scrollLoader &&
                              Array.from({
                                length: counter - batch.length,
                              }).map((_, i) => (
                                <div
                                  className="resultBoxWrapper"
                                  key={`scroll-loader-${i}`}
                                >
                                  <div className="resultBox">
                                    <CardLoader />
                                  </div>
                                </div>
                              ))}
                          </div>
                          {(batch?.some(
                            (item) => showDetails?.image_id === item.image_id
                          ) ||
                            (keepDetailViewOpen &&
                              activeBatchIndex === batchIndex)) && (
                            <div
                              ref={detailsRef}
                              className={`batch-details ${
                                showDetails ? "show" : ""
                              }`}
                            >
                              <div className="detailsPage">
                                <div className="detailMain">
                                  <div className="detailImg">
                                    <div className="imgWrap">
                                      <LazyLoadImage
                                        src={showDetails?.image_url}
                                        alt={`micovision`}
                                        className="img-fluid img-lazy"
                                        delayTime={2000}
                                        effect="blur"
                                      />
                                      {similarImages?.very_similar_image_list &&
                                        similarImages?.very_similar_image_list
                                          ?.length > 0 && (
                                          <div className="similargrpImg">
                                            <Swiper
                                              modules={[Navigation]}
                                              navigation={true}
                                              slidesPerView="auto"
                                              spaceBetween={10}
                                              className="similar-thumb-slider"
                                              breakpoints={{
                                                320: {
                                                  slidesPerView: 2,
                                                  spaceBetween: 10,
                                                },
                                                768: {
                                                  slidesPerView: 4,
                                                  spaceBetween: 10,
                                                },
                                                1024: {
                                                  slidesPerView: 6,
                                                  spaceBetween: 10,
                                                },
                                              }}
                                            >
                                              {similarImages?.very_similar_image_list?.map(
                                                (item, index) => (
                                                  <SwiperSlide key={index}>
                                                    <div
                                                      className={`thumb-item ${
                                                        item.image_id ===
                                                        showDetails?.image_id
                                                          ? "active"
                                                          : ""
                                                      }`}
                                                      onClick={() =>
                                                        handleSimilarImageClick(
                                                          item
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        src={item?.image_url}
                                                        alt={`micovision`}
                                                        className="img-fluid"
                                                      />
                                                    </div>
                                                  </SwiperSlide>
                                                )
                                              )}
                                            </Swiper>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  <div className="detailContent">
                                    <div className="detailHeader">
                                      <div className="detailTitle">
                                        <h5>{t("visionDetailstitle")}</h5>
                                      </div>
                                      <div className="detailFeature">
                                        <ul>
                                          <li>
                                            <Link
                                              to="#"
                                              onClick={() =>
                                                handleDownload(
                                                  showDetails?.image_url
                                                )
                                              }
                                            >
                                              <DownloadIcon />
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              to="#"
                                              onClick={() =>
                                                handleShare(
                                                  showDetails?.image_url
                                                )
                                              }
                                            >
                                              <ShareIcon />
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              to="#"
                                              onClick={handlePreviousImage}
                                            >
                                              <BackIcon
                                                style={
                                                  searchResults.findIndex(
                                                    (item) =>
                                                      item.image_id ===
                                                      showDetails?.image_id
                                                  ) === 0
                                                    ? {
                                                        fill: "#f8f8f8",
                                                        stroke: "#f8f8f8",
                                                      }
                                                    : {}
                                                }
                                              />
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              to="#"
                                              onClick={handleNextImage}
                                            >
                                              <NextIcon
                                                style={
                                                  searchResults.findIndex(
                                                    (item) =>
                                                      item.image_id ===
                                                      showDetails?.image_id
                                                  ) ===
                                                  searchResults.length - 1
                                                    ? {
                                                        fill: "#f8f8f8",
                                                        stroke: "#f8f8f8",
                                                      }
                                                    : {}
                                                }
                                              />
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              to="#"
                                              onClick={() => {
                                                askToSave(() => {
                                                  setShowDetails(null);
                                                  setKeepDetailViewOpen(false);
                                                  setActiveBatchIndex(null);
                                                });
                                              }}
                                            >
                                              <CloseIcon />
                                            </Link>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="ctrBox">
                                      <div className="formate">
                                        <div className="formateCont">
                                          <h5>{t("ctr")}</h5>
                                          <p
                                            style={{
                                              display: "inline-flex",
                                              alignItems: "center",
                                              gap: "5px",
                                            }}
                                          >
                                            {imageData?.ctr &&
                                            imageData.ctr.length === 2
                                              ? `${t((imageData.ctr[0] * 100).toFixed(2))} ~ ${t(
                                                  (
                                                    imageData.ctr[1] * 100
                                                  ).toFixed(2)
                                                )} %`
                                              : t("NOT_AVAILABLE")}

                                            <span
                                              onClick={() =>
                                                setShowCTRDetails(
                                                  (prev) => !prev
                                                )
                                              }
                                              style={{ cursor: "pointer" }}
                                            >
                                              <Tooltip
                                                title={t("clickThrough")}
                                                arrow
                                                placement="right"
                                                PopperProps={{
                                                  style: {
                                                    zIndex: 3000,
                                                    pointerEvents: "auto",
                                                  },
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    pointerEvents: "auto",
                                                  }}
                                                  onClick={(e) =>
                                                    e.stopPropagation()
                                                  }
                                                >
                                                  <ToolIcon />
                                                </div>
                                              </Tooltip>
                                            </span>
                                          </p>
                                        </div>
                                        <div className="formateCont">
                                          <h5>{t("visionDetailsformat")}</h5>
                                          <p
                                            style={{
                                              textAlign: "center",
                                              display: "block",
                                            }}
                                          >
                                            {t(
                                              imageData?.image_extension?.trim()
                                                ? imageData.image_extension.toUpperCase()
                                                : t("NOT_AVAILABLE")
                                            )}
                                          </p>
                                        </div>
                                        <div className="formateCont">
                                          <h5>{t("visionDetailssize")}</h5>
                                          <p style={{ textAlign: "center" }}>
                                            {t(
                                              imageData?.image_size?.trim()
                                                ? imageData.image_size
                                                : t("NOT_AVAILABLE")
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="ctrBox">
                                      <div className="project-filter">
                                        <div className="project-title">
                                          <h5>{t("tags")}</h5>
                                          <EditIcon
                                            onClick={() => {
                                              setIsEditingTags(true);
                                            }}
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                        <div className="project-listing">
                                          {isEditingTags ? (
                                            <Autocomplete
                                              multiple
                                              freeSolo
                                              options={virtualizedOptions}
                                              disableCloseOnSelect
                                              value={
                                                imageDetailsFilters.tags || []
                                              }
                                              inputValue={tagInputValue}
                                              onInputChange={(
                                                event,
                                                newValue
                                              ) => {
                                                setTagInputValue(newValue);
                                              }}
                                              onChange={(event, newValue) => {
                                                setImageDetailsFilters(
                                                  (prev) => ({
                                                    ...prev,
                                                    tags: newValue,
                                                  })
                                                );
                                              }}
                                              onKeyDown={handleTagInput}
                                              ListboxComponent={React.forwardRef(
                                                (props, ref) => (
                                                  <div ref={ref}>
                                                    <div
                                                      {...props}
                                                      onScroll={(event) => {
                                                        const listboxNode =
                                                          event.currentTarget;
                                                        if (
                                                          listboxNode.scrollTop +
                                                            listboxNode.clientHeight >=
                                                            listboxNode.scrollHeight -
                                                              50 &&
                                                          hasMore
                                                        ) {
                                                          loadMoreOptions();
                                                        }
                                                      }}
                                                    >
                                                      {props.children}
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                              renderOption={(
                                                props,
                                                option,
                                                { selected }
                                              ) => (
                                                <li {...props}>
                                                  <Checkbox
                                                    checked={selected}
                                                  />
                                                  {option}
                                                </li>
                                              )}
                                              style={{
                                                width: "100%",
                                                border: "1px solid #B3B3B3",
                                                borderRadius: 8,
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label={t("filters.tags")}
                                                  onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                      handleTagInput(
                                                        e,
                                                        null,
                                                        "enter"
                                                      );
                                                    }
                                                  }}
                                                />
                                              )}
                                              filterOptions={(
                                                options,
                                                { inputValue }
                                              ) => {
                                                if (!inputValue) {
                                                  return options;
                                                }
                                                return options.filter(
                                                  (option) =>
                                                    option
                                                      .toLowerCase()
                                                      .includes(
                                                        inputValue.toLowerCase()
                                                      )
                                                );
                                              }}
                                            />
                                          ) : (
                                            <ul>
                                              {imageDetailsFilters?.tags
                                                ?.filter(
                                                  (tag) => tag?.length > 1
                                                )
                                                ?.sort((a, b) =>
                                                  a.localeCompare(b)
                                                )
                                                ?.map((tag, index) => (
                                                  <li key={index}>{tag}</li>
                                                ))}
                                            </ul>
                                          )}

                                          <div className="addTagWrapper">
                                            <div className="industryTagBox">
                                              <div className="addTag">
                                                <h5>
                                                  {t(
                                                    industryFilter?.category_display_code
                                                  )}
                                                </h5>
                                                {isEditingTags ? (
                                                  <Autocomplete
                                                    multiple
                                                    options={
                                                      formattedIndustryOptionsKeys
                                                    }
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) =>
                                                      t(option?.title)
                                                    }
                                                    value={formattedIndustryOptionsKeys.filter(
                                                      (option) =>
                                                        imageDetailsFilters?.industry?.includes(
                                                          option.title
                                                        )
                                                    )}                                                   
                                                    onChange={(
                                                      event,
                                                      newValue
                                                    ) => {
                                                      setImageDetailsFilters(
                                                        (prev) => ({
                                                          ...prev,
                                                          industry:
                                                            newValue.map(
                                                              (option) =>
                                                                option.title
                                                            ),
                                                        })
                                                      );
                                                    }}
                                                    className="autoCompleteDropdown"
                                                    renderOption={(
                                                      props,
                                                      option,
                                                      { selected }
                                                    ) => {
                                                      const {
                                                        key,
                                                        ...otherProps
                                                      } = props;
                                                      return (
                                                        <li
                                                          key={key}
                                                          {...otherProps}
                                                        >
                                                          <Checkbox
                                                            checked={selected}
                                                          />
                                                          {t(option.title)}
                                                        </li>
                                                      );
                                                    }}
                                                    style={{
                                                      width: "100%",
                                                      border:
                                                        "1px solid #B3B3B3",
                                                      borderRadius: 8,
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        label={t(
                                                          "filters.industry"
                                                        )}
                                                      />
                                                    )}
                                                  />
                                                ) : (
                                                  <ul>
                                                    {renderCategoryDetails(
                                                      "category_industry",
                                                      imageDetailsFilters?.industry,
                                                      "industry"
                                                    )}
                                                  </ul>
                                                )}
                                              </div>
                                              <div className="addTag">
                                                <h5>
                                                  {t(
                                                    deliveryContentFilter?.category_display_code
                                                  )}
                                                </h5>
                                                {isEditingTags ? (
                                                  <Autocomplete
                                                    multiple
                                                    options={Object.keys(
                                                      deliveryContentOptions
                                                    )}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) =>
                                                      t(
                                                        option.replace(
                                                          "category_deliveryContent_",
                                                          ""
                                                        )
                                                      )
                                                    }
                                                    value={
                                                      imageDetailsFilters.deliveryContent
                                                    }
                                                    onChange={(
                                                      event,
                                                      newValue
                                                    ) => {
                                                      setImageDetailsFilters(
                                                        (prev) => ({
                                                          ...prev,
                                                          deliveryContent:
                                                            newValue,
                                                        })
                                                      );
                                                    }}
                                                    className="autoCompleteDropdown"
                                                    renderOption={(
                                                      props,
                                                      option,
                                                      { selected }
                                                    ) => {
                                                      const {
                                                        key,
                                                        ...otherProps
                                                      } = props;
                                                      return (
                                                        <li
                                                          key={key}
                                                          {...otherProps}
                                                        >
                                                          <Checkbox
                                                            checked={selected}
                                                          />
                                                          {t(
                                                            option.replace(
                                                              "category_deliveryContent_",
                                                              ""
                                                            )
                                                          )}
                                                        </li>
                                                      );
                                                    }}
                                                    style={{
                                                      width: "100%",
                                                      border:
                                                        "1px solid #B3B3B3",
                                                      borderRadius: 8,
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        label={t(
                                                          "filters.deliveryContent"
                                                        )}
                                                      />
                                                    )}
                                                  />
                                                ) : (
                                                  <ul>
                                                    {renderCategoryDetails(
                                                      "category_delivery_content",
                                                      imageDetailsFilters?.deliveryContent,
                                                      "delivery_content"
                                                    )}
                                                  </ul>
                                                )}
                                              </div>
                                              <div className="addTag">
                                                <h5>
                                                  {t(
                                                    deliveryTypeFilter?.category_display_code
                                                  )}
                                                </h5>
                                                {isEditingTags ? (
                                                  <Autocomplete
                                                    multiple
                                                    options={Object.keys(
                                                      deliveryTypeOptions
                                                    )}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) =>
                                                      t(
                                                        option.replace(
                                                          "category_deliveryType_",
                                                          ""
                                                        )
                                                      )
                                                    }
                                                    value={
                                                      imageDetailsFilters.deliveryType
                                                    }
                                                    onChange={(
                                                      event,
                                                      newValue
                                                    ) => {
                                                      setImageDetailsFilters(
                                                        (prev) => ({
                                                          ...prev,
                                                          deliveryType:
                                                            newValue,
                                                        })
                                                      );
                                                    }}
                                                    className="autoCompleteDropdown"
                                                    renderOption={(
                                                      props,
                                                      option,
                                                      { selected }
                                                    ) => {
                                                      const {
                                                        key,
                                                        ...otherProps
                                                      } = props;
                                                      return (
                                                        <li
                                                          key={key}
                                                          {...otherProps}
                                                        >
                                                          <Checkbox
                                                            checked={selected}
                                                          />
                                                          {t(
                                                            option.replace(
                                                              "category_deliveryType_",
                                                              ""
                                                            )
                                                          )}
                                                        </li>
                                                      );
                                                    }}
                                                    style={{
                                                      width: "100%",
                                                      border:
                                                        "1px solid #B3B3B3",
                                                      borderRadius: 8,
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        label={t(
                                                          "filters.deliveryType"
                                                        )}
                                                      />
                                                    )}
                                                  />
                                                ) : (
                                                  <ul>
                                                    {renderCategoryDetails(
                                                      "category_delivery_type",
                                                      imageDetailsFilters?.deliveryType,
                                                      "delivery_type"
                                                    )}
                                                  </ul>
                                                )}
                                              </div>
                                            </div>
                                            <div className="industryTagBox">
                                              <div className="addTag">
                                                <h5>{t("imageType")}</h5>
                                                {isEditingTags ? (
                                                  <Autocomplete
                                                    multiple
                                                    options={Object.keys(
                                                      imageTypeOptions
                                                    )}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) =>
                                                      t(option)
                                                    }
                                                    value={
                                                      imageDetailsFilters?.imageType ||
                                                      []
                                                    }
                                                    onChange={(
                                                      event,
                                                      newValue
                                                    ) => {
                                                      setImageDetailsFilters(
                                                        (prev) => ({
                                                          ...prev,
                                                          imageType: newValue,
                                                        })
                                                      );
                                                    }}
                                                    className="autoCompleteDropdown"
                                                    renderOption={(
                                                      props,
                                                      option,
                                                      { selected }
                                                    ) => {
                                                      const {
                                                        key,
                                                        ...otherProps
                                                      } = props;
                                                      return (
                                                        <li
                                                          key={key}
                                                          {...otherProps}
                                                        >
                                                          <Checkbox
                                                            checked={selected}
                                                          />
                                                          {t(
                                                            option.replace(
                                                              "category_imageType_",
                                                              ""
                                                            )
                                                          )}
                                                        </li>
                                                      );
                                                    }}
                                                    style={{
                                                      width: "100%",
                                                      border:
                                                        "1px solid #B3B3B3",
                                                      borderRadius: 8,
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        label={t(
                                                          "filters.imageType"
                                                        )}
                                                      />
                                                    )}
                                                  />
                                                ) : (
                                                  <ul>
                                                    {renderCategoryDetails(
                                                      "category_image_type",
                                                      imageDetailsFilters?.imageType,
                                                      "image_type"
                                                    )}
                                                  </ul>
                                                )}
                                              </div>
                                              {renderModelFilters()}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="ctrBox">
                                      <div className="ai-header">
                                        <h5>
                                          <AIIcon /> {t("aiDescriptiontitle")}
                                        </h5>
                                      </div>
                                      <div className="ai-cont">
                                        {isEditingTags ? (
                                          <TextField
                                            fullWidth
                                            multiline
                                            rows={4}
                                            defaultValue={
                                              (currentLanguage == "ja"
                                                ? imageData?.image_description_ja
                                                : imageData?.image_description) ||
                                              ""
                                            }
                                            onChange={(e) => {
                                              // setFormValue(prev => ({
                                              //   ...prev,
                                              //   edit_description: e.target.value
                                              // }));
                                              setImageDetailsFilters(
                                                (prev) => ({
                                                  ...prev,
                                                  [currentLanguage == "ja"
                                                    ? "image_description_ja"
                                                    : "image_description"]:
                                                    e.target.value,
                                                })
                                              );
                                            }}
                                            placeholder={t(
                                              "Enter AI description"
                                            )}
                                            style={{
                                              width: "100%",
                                              marginTop: "10px",
                                              border: "1px solid #B3B3B3",
                                              borderRadius: "8px",
                                            }}
                                          />
                                        ) : (
                                          <p>
                                            {t(
                                              imageData?.[
                                                currentLanguage === "ja"
                                                  ? "image_description_ja"
                                                  : "image_description"
                                              ]
                                            )}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    {isEditingTags && (
                                      <div className="buttonGrp">
                                        <button onClick={handleCancelClick}>
                                          {t("cancel")}
                                        </button>
                                        <button onClick={handleEdit}>
                                          {t("save")}
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {similarImages?.similar_image_list?.length >
                                0 && (
                                <SimilarImages
                                  similarData={
                                    similarImages?.similar_image_list
                                  }
                                  handleSimilarImageClick={
                                    handleSimilarImageClick
                                  }
                                />
                              )}
                            </div>
                          )}
                        </div>
                      )
                    )}
                    {scrollLoader && (
                      <div className="result-batch">
                        <div className={`batch-grid column${counter}`}>
                          {[...Array(50)].map((_, i) => (
                            <div
                              className="resultBoxWrapper"
                              key={`scroll-loader-${i}`}
                            >
                              <div className="resultBox">
                                <CardLoader />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <div ref={sentryRef} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchResults;
