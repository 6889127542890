import { createSlice } from "@reduxjs/toolkit";

const sortElementsByZIndex = (elements) => {
  if (!elements) return [];
  return [...elements].sort((a, b) => (a.zIndex || 0) - (b.zIndex || 0));
};

const initialState = {
  canvas: {
    id: null,
    layers: [
      {
        id: "mainLayer",
        groups: [], // This will contain all the sheets/pages
      },
    ],
  },
};

const konvaSlice = createSlice({
  name: "konva",
  initialState,
  reducers: {
    initializeCanvas: (state, action) => {
      state.canvas = {
        id: action?.payload?.id || action?.payload?.design_id,
        layers: [
          {
            id: "mainLayer",
            groups: (action.payload.sheets || []).map((sheet, index) => ({
              id: sheet.sheet_id || sheet.id,
              name: sheet.sheet_name || "Untitled",
              status_id: sheet.sheet_status_id || 1,
              is_locked: sheet.sheet_is_locked || false,
              is_maximized: sheet.is_maximized || false,
              position: {
                x: sheet?.position?.[0] || 50 + index * 600, // Position each sheet horizontally with 600px spacing
                y: sheet?.position?.[1] || 50,
              },
              elements: sortElementsByZIndex(
                sheet.layers?.map((layer, layerIndex) => ({
                  ...layer,
                  id: layer.id,
                  type: "Image",
                  sheet_id: sheet.sheet_id || sheet.id,
                  x: 0,
                  y: 0,
                  width: 500,
                  height: 500,
                  color: layer.color || "#ffffff",
                  image_data: {
                    data: layer.image_data?.data || "",
                    format: layer.image_data?.format || "PNG",
                    size: layer.image_data?.size || [500, 500],
                  },
                  draggable: layer.draggable || true,
                  blend_mode: layer?.blend_mode || "norm",
                  opacity: layer?.opacity || 1,
                  corner_radius: layer?.corner_radius || 0,
                  position: {
                    x: layer.position?.[0] || 0,
                    y: layer.position?.[1] || 0,
                  },
                  rotation: layer.rotation || 0,
                  visible: layer.visible !== undefined ? layer.visible : true,
                  zIndex: layer["z-index"] || layer.zIndex || layerIndex,
                })) || []
              ),
            })),
          },
        ],
      };
    },

    // zIndex: layer['z-index']  || layer.zIndex || layerIndex

    updateGroup: (state, action) => {
      const { newGroup } = action.payload;
      const layer = state.canvas.layers[0];
      layer.groups = newGroup; // Replace the entire groups array with newGroup
    },

    addSheet: (state, action) => {
      const newSheet = {
        id: action.payload.sheet_id,
        name: action.payload.sheet_name,
        status_id: action.payload.sheet_status_id,
        is_locked: action.payload.sheet_is_locked,
        is_maximized: false,
        position: {
          x: 50 + state.canvas.layers[0].groups.length * 600,
          y: 50,
        },
        elements: [],
        layers: action.payload.layers || [],
      };
      state.canvas.layers[0].groups.push(newSheet);
    },

    removeSheet: (state, action) => {
      const sheetId = action.payload;
      if (state.canvas?.layers?.[0]?.groups) {
        state.canvas.layers[0].groups = state.canvas.layers[0].groups.filter(
          (group) => group.id !== sheetId
        );
      }
    },

    updateSheetPosition: (state, action) => {
      const { sheetId, position } = action.payload;
      const sheet = state.canvas.layers[0].groups.find((g) => g.id === sheetId);
      if (sheet) {
        sheet.position = position;
      }
    },

    updateSheetStatus: (state, action) => {
      const { sheetId, status } = action.payload;
      const sheet = state.canvas.layers[0].groups.find((g) => g.id === sheetId);
      if (sheet) {
        sheet.status_id = status;
        sheet.is_locked = status === 2 || status === 3;
      }
    },

    addElement: (state, action) => {
      const { sheetId, element } = action.payload;
      const sheet = state.canvas.layers[0].groups.find((g) => g.id === sheetId);
      if (sheet) {
        // Add the new element
        sheet.elements.push(element);
        // Calculate highest existing zIndex
        const highestZIndex = Math.max(
          ...sheet.elements.map((el) => el.zIndex || 0)
        );
        // Set new element's zIndex if not already set
        if (!element.zIndex) {
          element.zIndex = highestZIndex + 1;
        }
        // Sort elements by zIndex
        sheet.elements = sortElementsByZIndex(sheet.elements);
      }
    },

    updateElement: (state, action) => {
      const { sheetId, elementId, newProps } = action.payload;
      const sheet = state.canvas.layers[0].groups.find((g) => g.id === sheetId);
      if (sheet) {
        const element = sheet.elements.find((el) => el.id === elementId);
        if (element) {
          // Update element properties
          Object.assign(element, newProps);
          // Re-sort elements if zIndex was changed
          if (newProps.zIndex !== undefined) {
            sheet.elements = sortElementsByZIndex(sheet.elements);
          }
        }
      }
    },

    removeElement: (state, action) => {
      const { sheetId, elementId } = action.payload;
      const sheet = state.canvas.layers[0].groups.find((g) => g.id === sheetId);
      if (sheet) {
        sheet.elements = sheet.elements.filter((el) => el.id !== elementId);
      }
    },

    updateSheetName: (state, action) => {
      const { sheetId, name } = action.payload;
      const sheet = state.canvas.layers[0].groups.find((g) => g.id === sheetId);
      if (sheet) {
        sheet.name = name;
      }
    },

    updateSheet: (state, action) => {
      const { sheetId, updates } = action.payload;
      const sheet = state.canvas.layers[0].groups.find(
        (group) => group.id === sheetId
      );
      if (sheet) {
        // Create a new position object to ensure Redux detects the change

        if (updates.position) {
          sheet.position = { ...updates.position };
        }
        if (updates.elements) {
          // Sort elements when updating them
          updates.elements = sortElementsByZIndex(updates.elements);
        }
        // Handle other updates
        Object.assign(sheet, updates);
      }
    },

    updateLayerElement: (state, action) => {
      const { sheetId, layerId, updates } = action.payload;
      const sheet = state.canvas.layers[0].groups.find((g) => g.id === sheetId);
      if (sheet) {
        const element = sheet.elements.find((el) => el.id === layerId);
        if (element) {
          // Update the element with new properties while preserving existing ones
          Object.assign(element, updates);
        }
      }
    },

    // Optional: Add a bulk update method if needed
    updateLayerElements: (state, action) => {
      const { sheetId, updates } = action.payload;
      const sheet = state.canvas.layers[0].groups.find((g) => g.id === sheetId);
      if (sheet) {
        updates.forEach((update) => {
          const element = sheet.elements.find((el) => el.id === update.id);
          if (element) {
            Object.assign(element, update.changes);
          }
        });
      }
    },
    updateSheetLock: (state, action) => {
      const { sheetId, is_locked } = action.payload;
      const sheet = state.canvas.layers[0].groups.find((g) => g.id === sheetId);
      if (sheet) {
        sheet.is_locked = is_locked;
      }
    },

    resetKonvaState: (state) => {
      // Reset to initial state
      state.canvas = {
        id: null,
        layers: [
          {
            id: "mainLayer",
            groups: [],
          },
        ],
      };
    },

    bulkUpdateSheets: (state, action) => {
      const updates = action.payload;
      updates.forEach((update) => {
        const sheet = state.canvas.layers[0].groups.find(
          (group) => group.id === update.sheetId
        );
        if (sheet) {
          if (update.updates.position) {
            sheet.position = { ...update.updates.position };
          }
          Object.assign(sheet, update.updates);
        }
      });
    },

    toggleSheetMaximized: (state, action) => {
      const { sheetId } = action.payload;
      const sheet = state.canvas.layers[0].groups.find((g) => g.id === sheetId);
      if (sheet) {
        // First, set all sheets to not maximized
        state.canvas.layers[0].groups.forEach((g) => {
          g.is_maximized = false;
        });
        // Then toggle the target sheet
        sheet.is_maximized = !sheet.is_maximized;
      }
    },

    resetMaximized: (state, action) => {
      state.canvas.layers[0].groups.forEach((g) => {
        g.is_maximized = false;
      });
    },
  },
});

export const {
  initializeCanvas,
  updateGroup,
  addSheet,
  removeSheet,
  updateSheetPosition,
  updateSheetStatus,
  updateSheetName,
  addElement,
  updateElement,
  removeElement,
  updateLayerElement,
  updateLayerElements,
  updateSheetLock,
  updateSheet,
  resetKonvaState,
  bulkUpdateSheets,
  toggleSheetMaximized,
  resetMaximized,
} = konvaSlice.actions;

export default konvaSlice.reducer;
