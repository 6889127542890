import React, { useState, useEffect, useCallback } from "react";
import { Skeleton } from "@mui/material";
import SearchIcon from "../../../assets/newicons/search1.svg";
import { post } from "../../../API/axios";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash"; // Import debounce from lodash
import { useAuthContext } from "../../../context/useAuthContext";

const ImageSearchAndGrid = () => {
  const [query, setQuery] = useState(""); // State for search query
  const [layoutData, setLayoutData] = useState([]); // State for storing image data
  const [isScrollLoading, setIsScrollLoading] = useState(false); // State for loading state
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [totalImages, setTotalImages] = useState(0); // Total images for pagination
  const [noDataFound, setNoDataFound] = useState(false); // State to show no data found
  const { t, i18n } = useTranslation();
  const {
    // selectedSite,
    siteIds,
  } = useAuthContext();
  const currentLanguage = i18n.language;

  useEffect(() => {
    // Create a debounced function that will trigger fetchImages
    const debouncedFetchImages = debounce((query) => {
      fetchImages(query, 1, true); // Trigger the fetchImages function
    }, 500);

    // Fetch images whenever the query changes
    if (query) {
      debouncedFetchImages(query);
    }

    return () => {
      debouncedFetchImages.cancel(); // Clean up debounce on unmount or query change
    };
  }, [query]);

  // Function to fetch images from the API
  const fetchImages = useCallback(
    async (query, page, resetValues = false) => {
      if (!query) return; // If no query, do not make the API call

      setIsScrollLoading(true);

      const payload = {
        input_text: query,
        lang: currentLanguage,
        page: page,
      };

      payload.site_id = siteIds;
      // typeof selectedSite?.site_id == "object"
      //   ? selectedSite?.site_id
      //   : [selectedSite?.site_id];

      try {
        const response = await post("text_search_image/", payload); // Replace with your actual API call
        if (response?.status_code === 200) {
          const newElements = response?.data || [];
          if (resetValues) {
            setLayoutData(newElements);
          } else {
            setLayoutData((prevState) => [
              ...(prevState || []),
              ...newElements,
            ]);
          }
          setTotalImages(response?.pagination?.total_images || 0);
          setNoDataFound(newElements.length === 0);
          setIsScrollLoading(false);
        } else {
          setNoDataFound(true);
          setIsScrollLoading(false);
        }
      } catch (error) {
        console.error("Error fetching images", error);
        setIsScrollLoading(false);
      }
    },
    [currentLanguage]
  );

  // Handle the input search change
  const handleSearchChange = (e) => {
    e.preventDefault();
    setQuery(e.target.value);
  };

  // Handle the drag start for images
  const handleDragStart1 = (e, element) => {
    const imageData = {
      kind: "image",
      id: `image_${Date.now()}`,
      props: {
        src: element.image_url,
        x: 50,
        y: 50,
        width: 200,
        height: 200,
        opacity: 1,
        corner_radius: [0, 0, 0, 0],
        isSelected: false,
      },
    };

    e.dataTransfer.setData("image", element.image_url);
    e.dataTransfer.setData("application/json", JSON.stringify(imageData));
    const dragImage = new Image();
    dragImage.src = element.image_url;
    dragImage.onload = () => {
      e.dataTransfer.setDragImage(dragImage, 10, 10);
    };
  };

  // Load more images when scrolling near the bottom (pagination)
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
    if (bottom && !isScrollLoading && layoutData?.length < totalImages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // Fetch more images when page changes
  useEffect(() => {
    if (currentPage > 1) {
      fetchImages(query, currentPage);
    }
  }, [currentPage, query, fetchImages]);

  useEffect(() => {
    fetchImages("photo", 1);
  }, []);

  return (
    <div>
      {/* Search Form */}
      <form>
        <div className="position-relative w-90 mt-4 mb-4 mt-md-4 mt-3 mb-3">
          <div className="position-relative tabs-search">
            <input
              className="form-control"
              name="input_text"
              placeholder={t("Search")} // Using translation for placeholder
              aria-label="Search"
              value={query}
              onChange={handleSearchChange} // Handle input change
            />
            <img src={SearchIcon} className="position-absolute" alt="search" />
          </div>
        </div>
      </form>

      {/* Image Grid */}
      <div className="tabs-grid-image" onScroll={handleScroll}>
        {isScrollLoading ? (
          // Skeleton Loader for images while fetching
          Array(10)
            .fill(0)
            .map((_, index) => (
              <div
                key={index}
                className="d-flex align-items-center justify-content-center tabs-image"
              >
                <Skeleton
                  variant="rectangular"
                  width={110}
                  height={100}
                  style={{ borderRadius: "5px" }}
                />
              </div>
            ))
        ) : noDataFound ? (
          // Show "No Data Found" if no images are returned
          <div className="no-data-found">{t("No data found")}</div>
        ) : (
          layoutData?.map((element, index) => (
            <div
              key={index}
              className="d-flex align-items-center justify-content-center tabs-image"
              draggable
              onDragStart={(e) => handleDragStart1(e, element)}
            >
              <img
                src={element?.image_url || "noImage"} // Default image if no src
                alt={`image_${index}`}
                loading="lazy"
                className="rounded-3"
                style={{
                  pointerEvents: "none",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ImageSearchAndGrid;
