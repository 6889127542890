import { Box, Skeleton } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import WebFont from "webfontloader";
import { imageGet, newGet, newPost, post } from "../../../API/axios";
import resizeIcon from "../../../assets/newicons/btn-expand.svg";
import {
  default as Close,
  default as closebtn,
} from "../../../assets/newicons/close.svg";
import Upload from "../../../assets/newicons/icon.svg";
import Search1 from "../../../assets/newicons/search1.svg";
import UploadComponent from "../../../components/Shared/UploadComponent";
import { useAuthContext } from "../../../context/useAuthContext";
import { useReferenceContext } from "../../../context/useReferenceContext";
import { useSearchContext } from "../../../context/useSearchContext";
import RenameFilesPopUp from "../../createNewDesign/component/RenameFilesPopUp";
import UploadedFiles from "../../createNewDesign/component/UploadedFiles";
import ImageContext from "./ImageContext";
import "./SideMenu.scss";
import UploadDesignFile from "./UploadDesignFile";

import styles from "./side-menu.module.css";
import BrowseSearch from "./BrowseSearch";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { showAlert } from "./AlertService";

let debounceTimer;
const SideMenu = ({
  onClose,
  setSelectedFont,
  typeChange,
  setTypeChange,
  createdProjectId,
  deleteImages,
  projectStatus,
}) => {
  const location = useLocation();
  const [isImageLayerOpen, setIsImageLayerOpen] = useState(false);
  const { setResetPopup, setImages } = useContext(ImageContext);
  const [selectedImages, setSelectedImages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [uploadPopupOpen, setUploadPopupOpen] = useState(false);

  const [resetData, setResetData] = useState({});
  const [offSetId, setOffSetId] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isImageListLoading, setIsImageListLoading] = useState(false);

  const [menuState, setMenuState] = useState({});
  const [imageUrl, setImageUrl] = useState([]);
  const {
    boardId,
    setBoardId,
    uploadedFiles,
    setUploadedFiles,
    isProjectActive,
  } = useReferenceContext();
  const [showImageDetails, setShowImageDetails] = useState(false);
  const [imageDetails, setImageDetails] = useState([]);
  const [responseValue, setResponseValue] = useState({});
  const [searchOpen, setSearchOpen] = useState(false);
  const [tabs, setTabs] = useState("quick-search");
  const browseSearchRef = useRef();

  const [fileData, setFiledata] = useState({
    file_id: "",
    file_name: "",
  });
  const [loading, setLoading] = useState(false);
  const [draggedItemIndex, setDraggedItemIndex] = useState(null);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [formData, setFormData] = useState({
    input_text: "",
    page: 1,
    ctr: 0,
    tags: [],
    model_filters: [],
    is_edited: "all",
    search_by: "desc",
  });

  const {
    setResults,
    setNumberOfResults,
    setShowResults,
    setFormValue,
    formValue,
    results,
  } = useSearchContext();
  const { selectedSite } = useAuthContext();

  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setMenuState({
      ...menuState,
      [index]: {
        anchorEl: event.currentTarget,
        open: true,
      },
    });
  };
  const handleClose = (index) => {
    setMenuState({
      ...menuState,
      [index]: {
        anchorEl: null,
        open: false,
      },
    });
  };

  useEffect(() => {
    return () => {
      document.body.classList.remove("modal-open");
      const backdrops = document.getElementsByClassName("modal-backdrop");
      while (backdrops.length > 0) {
        backdrops[0].parentNode.removeChild(backdrops[0]);
      }
    };
  }, [location]);

  const loadGoogleFonts = () => {
    WebFont.load({
      google: {
        families: [
          "Qwitcher Grypen",
          "Space Grotesk",
          "Epilogue",
          "Lexend",
          "Outfit",
          "Clash Display",
          "Red Hat Display",
          "Sora",
          "Be Vietnam Pro",
          "General Sans",
        ],
      },
    });
  };

  const modalOpen = () => {
    setResetPopup("");
    setResetPopup("reset");
  };

  const getUploadedFiles = async () => {
    try {
      const data = await newGet(`board/get?project_id=${createdProjectId}`);
      // const designFilterData = data?.result.filter((design => design?.design_id === design_id))
      setUploadedFiles(data?.result?.files);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadGoogleFonts();
    getUploadedFiles();
  }, []);

  const fonts = [
    { family: "Qwitcher Grypen", name: "Qwitcher Grypen" },
    { family: "Space Grotesk", name: "Space Grotesk" },
    { family: "Epilogue", name: "Epilogue" },
    { family: "Lexend", name: "Lexend" },
    { family: "Outfit", name: "Outfit" },
    { family: "Clash Display", name: "Clash Display" },
    { family: "Cabinet Grotesk", name: "Cabinet Grotesk" },
    { family: "GT Walsheim", name: "GT Walsheim" },
    { family: "Red Hat Display", name: "Red Hat Display" },
    { family: "Sora", name: "Sora" },
    { family: "Be Vietnam Pro", name: "Be Vietnam Pro" },
    { family: "General Sans", name: "General Sans" },
  ];

  const [tab, setTab] = useState(2);

  const fileInputRef = useRef(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImage = {
          url: e.target.result,
          rotation: 0,
          id: "image_" + Date.now(),
          x: 50,
          y: 50,
        };
        setSelectedImages((prevImages) => {
          const updatedImages = [...prevImages, ...newImage];
          return updatedImages;
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadClick = () => {
    // fileInputRef.current.click();
    setIsImageUploaded(true);
  };
  const handleDragStart = (e, fontFamily) => {
    e.dataTransfer.setData("text/plain", fontFamily);
    setSelectedFont(fontFamily);
    e.currentTarget.style.opacity = "0.5";
  };
  const handleDragStart1 = (e, item) => {
    if (!isProjectActive()) {
      return;
    }
    // Set the drag data with both the URL and file info
    const dragData = {
      type: "excalidraw-image",
      src: item?.file_url || item?.image_url,
      // id: item?.file_id || item?.image_url,
      fileName: item?.file_name || "image",
    };

    e.dataTransfer.setData("application/json", JSON.stringify(dragData));

    // Create a smaller drag preview
    // const img = new Image();
    // img.src = item?.file_url;
    // img.style.width = "50px"; // Set smaller width
    // img.height = "50px"; // Set smaller height
    // img.style.maxWidth = '200px';
    // e.dataTransfer.setDragImage(img, 10, 10);
    // Set effectAllowed to copy to indicate this is a copy operation
    e.dataTransfer.effectAllowed = "copy";
  };

  const handleDragEnd = (e) => {
    e.currentTarget.style.opacity = "1";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.dataTransfer.getData("text/plain");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragStartt = (index) => {
    setDraggedItemIndex(index);
  };

  const handleDragOverr = (event) => {
    event.preventDefault(); // Prevent default to allow dropping
  };

  const handleSetImageUrl = (url) => {
    setImageUrl(url);
  };

  const resetSideMenuState = () => {
    // Reset local states
    setSelectedImages([]);
    setImageUrl([]);
    setImageDetails([]);
    setSearchOpen(false);
    setTabs("quick-search");
    setShowImageDetails(false); // Add this line to reset image details visibility

    // Reset form value in search context
    setFormValue({
      input_text: "",
      lang: currentLanguage,
      page: 1,
      ctr: 0,
      is_edited: "",
      site_id:
        typeof selectedSite?.site_id === "object"
          ? selectedSite?.site_id
          : [selectedSite?.site_id],
      search_by: "both",
      industry: [],
      tags: [],
      image_type: [],
      delivery_type: [],
      delivery_content: [],
      model_filters: [],
    });

    // Reset browse search component if exists
    if (browseSearchRef.current?.resetState) {
      browseSearchRef.current.resetState();
    }
  };

  const onHandleSelectedImage = async () => {
    try {
      // Extract only image_urls from the selected images
      const imageUrls = Array.isArray(imageUrl)
        ? imageUrl.map((img) => img.image_url)
        : [imageUrl.image_url];

      // Upload each image URL
      const uploadPromises = imageUrls.map((url) =>
        newPost("file/insert", {
          board_id: boardId,
          file_url: url,
        })
      );

      const responses = [];
      for (const promise of uploadPromises) {
        const response = await promise;
        responses.push(response);
      }

      // Process responses
      const allNewImages = responses.flatMap((data) => {
        if (data.status_code === 200 || data.status_code === 201) {
          if (Array.isArray(data.result)) {
            return data.result.map((item) => ({
              ...item,
              base64: item.file_url,
            }));
          } else {
            return [
              {
                ...data.result,
                base64: data.result.file_url,
              },
            ];
          }
        }
        return [];
      });

      // Update states with safety checks
      setImageDetails((prevDetails) => [
        ...(prevDetails || []),
        ...allNewImages,
      ]);
      setUploadedFiles((prevFiles) => [...(prevFiles || []), ...allNewImages]);
      setImages(allNewImages);

      // Close modal and reset states
      const modalElement = document.getElementById("search_modal");
      const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
      modalInstance?.hide();

      // Reset all states including selected images
      resetSideMenuState();
    } catch (error) {
      console.error("Error uploading images:", error);
      toast.error("Error uploading images");
    }
  };

  const onCancelHandler = () => {
    setShowImageDetails(false);
    setImageUrl([]);
    // Reset all states including selected images
    resetSideMenuState();
  };

  const handleDropp = (index) => {
    if (draggedItemIndex !== null) {
      const updatedFiles = [...uploadedFiles];
      const draggedItem = updatedFiles[draggedItemIndex];
      updatedFiles.splice(draggedItemIndex, 1); // Remove the dragged item
      updatedFiles.splice(index, 0, draggedItem); // Insert it at the dropped position

      uploadedFiles(updatedFiles);
      setDraggedItemIndex(null); // Reset dragged item index
    }
  };

  const hadleSetImage = () => {
    if (!isProjectActive()) {
      return;
    }
    setIsImageLayerOpen(true);
  };

  const handleSearchInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const resetSearch = () => {
    setNumberOfResults({
      primary: 0,
      similar: 0,
    });
    setFormData({ ...formData, page: 1 });
  };

  useEffect(() => {
    if (formData.input_text !== formValue.input_text) {
      setFormData(formValue);
      setSearchResults(results);
    }
  }, [formValue]);

  const uploadButtonClick = () => {
    setUploadPopupOpen(true);
  };

  const getSearchText = async (isNextPage = false) => {
    // setLoading(true);
    // setIsImageListLoading(true)

    let payload;
    if (!isNextPage) {
      resetSearch();
      setIsImageListLoading(true);
    }

    if (formData?.input_text) {
      payload = {
        input_text: formData.input_text,
        lang: currentLanguage, //en or ja
        page: isNextPage ? formData.page + 1 : 1,
        ctr: Number(formData.ctr),
      };

      if (formData?.input_text || formData?.ctr || formData.tags.length !== 0) {
        payload = {
          input_text: formData.input_text,
          lang: currentLanguage, //en or ja
          page: isNextPage ? formData.page + 1 : 1,
          ctr: Number(formData.ctr),
          is_edited: formData.is_edited,
        };
        if (formData.tags.length > 0) {
          payload.filter_tags = formData.tags;
        }
      }
    } else {
      payload = {
        input_text: formData.input_text,
        lang: currentLanguage, //en or ja
        page: isNextPage ? formData.page + 1 : 1,
        ctr: Number(formData.ctr),
        is_edited: formData.is_edited,
      };
    }

    if (isNextPage) {
      // setScrollLoader(true);
      // setIsImageListLoading(true)
      setFormData({ ...formData, page: formData.page + 1 });
    }
    payload.site_id =
      typeof selectedSite?.site_id == "object"
        ? selectedSite?.site_id
        : [selectedSite?.site_id];
    const { tags, ctr, input_text, lang, page, site_id, is_edited, ...rest } =
      formData;

    payload = {
      ...payload,
      ...rest,
    };

    if (
      !payload?.input_text &&
      payload?.ctr <= 0 &&
      !payload?.filter_tags?.length &&
      !payload?.delivery_content?.length &&
      !payload?.delivery_type?.length &&
      !payload?.image_type?.length &&
      !payload?.industry?.length &&
      !payload?.model_filters?.length &&
      !payload?.is_edited
    ) {
      showAlert(t("oneFilterAtleast"), "warning");
      // setLoading(false);
      return;
    }

    setShowResults(true);
    setResetData(formData);
    await post(
      `text_search_image/`,
      offSetId && isNextPage ? { offset_id: offSetId, ...payload } : payload
    )
      .then((response) => {
        setNumberOfResults({
          total_images: response?.pagination?.total_images_in_page,
          primary: response?.pagination?.total_images,
          similar: response?.pagination?.total_images_in_current_page,
        });
        setResponseValue(response);
        setOffSetId(response?.pagination?.offset_id);
        if (!isNextPage) {
          setSearchResults(response?.data);
          setResults(response?.data);
        } else {
          if (response?.data?.length > 0) {
            setSearchResults((prevResults) => [
              ...prevResults,
              ...response?.data,
            ]);
            setResults((prevResults) => [...prevResults, ...response?.data]);
          }
        }
        setTimeout(() => {
          // setLoading(false);
          // setScrollLoader(false);
          setIsImageListLoading(false);
        }, 1000);
        setFormValue((prev) => ({
          ...prev,
          ...(formData || {}),
        }));
      })
      .catch((error) => {
        // setLoading(false);
        setShowResults(true);
        // setScrollLoader(false);
        setIsImageListLoading(false);
        console.error("error", error);
      })
      .finally(() => {});
  };

  const handleInputSearchText = (e) => {
    e.preventDefault();
    if (formData.input_text === "") {
      return;
    }
    if (debounceTimer) clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      getSearchText();
    }, 700); // 300ms delay
  };

  const downloadImage = async (url, item) => {
    try {
      const response = await imageGet("download_image", { image_url: url });
      const downloadUrl = URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = item?.file_name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const convertToBase64 = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = (err) => reject(err);
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error converting image to Base64:", error);
      return null;
    }
  };

  const handleFilesFetched = async (fetchedFiles) => {
    if (fetchedFiles && fetchedFiles.length > 0) {
      try {
        // Convert all file URLs to base64 in parallel
        const newImageDetails = fetchedFiles;
        // const newImageDetails = await Promise.all(
        //   fetchedFiles.map(async (item) => {
        //     const { file_url } = item;
        //     const base64String = await convertToBase64(file_url);
        //     return { ...item, file_url, base64: base64String };
        //   })
        // );

        // Update the state with the new image details
        setImageDetails((prevDetails) => [...prevDetails, ...newImageDetails]);
        setUploadedFiles((prevFiles) => [...prevFiles, ...newImageDetails]);
        setImages(newImageDetails);
      } catch (error) {
        console.error("Error converting files to base64:", error);
      }
    }
  };

  useEffect(() => {
    // When modal closes (searchOpen becomes false)
    if (!searchOpen) {
      resetSideMenuState();
    }

    // Cleanup function when component unmounts
    return () => {
      resetSideMenuState();
    };
  }, [searchOpen]);

  return (
    <div>
      {uploadPopupOpen ? (
        <UploadComponent
          uploadKeys={{
            board_id: boardId,
          }}
          screen="visionBoard"
          onFilesFetched={handleFilesFetched}
          setPopupState={setUploadPopupOpen}
        />
      ) : null}
      {typeChange === "image" && (
        <div className="position-relative">
          <div
            className="border-right ps-md-3 pe-md-3 pb-md-3 ps-2 pe-2 pb-2 pt-0 bg-white font-noto-sans tabs-conatainer"
            style={{ border: "1px solid #ddd" }}
          >
            <div className="d-flex align-items-center justify-content-between pb-2 pt-3 px-2  bg-white position-sticky top-0">
              <h5 className="fs-14 font-noto-sans fw-600 mb-0">
                {t("browse")}
              </h5>
              <div className="d-flex align-items-center gap-2">
                <button
                  className="bg-transparent border-0 p-0 d-flex align-items-center justify-content-center"
                  data-bs-toggle="modal"
                  data-bs-target="#search_modal"
                  type="button"
                  disabled={projectStatus === 3 || projectStatus === 5}
                  onClick={() => {
                    setShowResults(true);
                    modalOpen();
                    setSearchOpen(true);
                    //  setFormValue((prevFormValue) => ({
                    //     ...prevFormValue,
                    //     input_text: '',
                    // }));
                  }}
                >
                  <img src={resizeIcon} alt="resizeIcon" />
                </button>
                <button className="bg-transparent border-0 p-0 d-flex align-items-center justify-content-center">
                  <img src={closebtn} alt="closebtn" onClick={onClose} />
                </button>
              </div>
            </div>
            <div className="p-2">
              {/* <input
                                    type='file'
                                    accept='image/*'
                                    ref={fileInputRef}
                                    onChange={handleImageUpload}
                                    style={{ display: 'none' }}
                                /> */}

              {/* <button
                                    className='d-flex align-items-center justify-content-center gap-2 w-100 border-0 black-gradiant-btn'
                                    onClick={() => { setTypeChange('interests') }}
                                >
                                    <img src={aiIcon} alt='aiIcon' />
                                    <span>Generate with MicoAI</span>
                                </button> */}

              {/* <button
                                    className='text-center w-100 border-0 mt-2 fw-600 fs-14 py-md-2 py-2 text-size-btn'
                                    style={{ borderRadius: "8px" }}
                                    // onClick={handleUploadClick}
                                    disabled={!isProjectActive()}
                                    data-bs-toggle="modal" data-bs-target="#add_design"
                                >
                                    Upload
                                </button> */}
              <div className="d-flex justify-content-center">
                {/* <button
                    className={`bg-transparent d-flex justify-content-center text-black font-bold flex-grow-1 rounded-pill px-4 py-2 d-flex align-items-center  ${styles.uploadButton}`}
                    disabled={!isProjectActive()}
                    onClick={uploadButtonClick}
                  >
                    {t("UPLOAD")}
                  </button> */}

                <button
                  style={{ border: "2px solid #6E82FF", fontSize: "14px" }}
                  className={`bg-transparent d-flex justify-content-center text-black font-bold flex-grow-1 rounded-pill px-4 py-2 align-items-center weight-600 gap-2 ${styles.uploadButton}`}
                  disabled={projectStatus === 3 || projectStatus === 5}
                  onClick={uploadButtonClick}
                >
                  <img
                    src={Upload}
                    alt="Upload"
                    width="16"
                    height="16"
                    color="#312EFF"
                  />
                  {t("UPLOAD")}
                </button>
              </div>
            </div>
            {/* {isImageUploaded && ( <UploadDesignFile /> )} */}
            <div className="w-100 d-flex flex-lg-row flex-column p-0">
              <div className="p-2 w-100">
                <div
                  className="d-flex align-items-center gap-2"
                  style={{ display: "flex", flexWrap: "nowrap" }}
                >
                  <div
                    className={`font-noto-sans add_navbar add_navbar-fs ${tab === 1 && "active"} cursor_pointer`}
                    onClick={() => setTab(1)}
                  >
                    <p
                      className="fs-14 fw-600"
                      style={{ display: "inline-block", margin: 0 }}
                    >
                      {t("Library")}
                    </p>
                  </div>
                  <div
                    className={`font-noto-sans add_navbar add_navbar-fs ${tab === 2 && "active"} cursor_pointer`}
                    onClick={() => setTab(2)}
                  >
                    <p
                      className="fs-14 fw-600"
                      style={{ display: "inline-block", margin: 0 }}
                    >
                      {t("MyFiles")}
                    </p>
                  </div>
                </div>
                {tab === 1 && (
                  <div>
                    <form onSubmit={handleInputSearchText}>
                      <div className="position-relative w-90 mt-4 mb-4 mt-md-4 mt-3 mb-3">
                        <div className="position-relative tabs-search">
                          <input
                            className="form-control"
                            name="input_text"
                            value={formData.input_text}
                            onChange={handleSearchInput}
                            placeholder={t("Search")}
                            aria-label="Search"
                            disabled={!isProjectActive()}
                          />
                          <img
                            src={Search1}
                            className="position-absolute"
                            alt="search"
                          />
                        </div>
                      </div>
                    </form>
                    <div>
                      <div className="tabs-grid-image searched-files-container">
                        {/* {layoutData.layouts[0].elements.map((element, index) => (

                                                        <div className='d-flex align-items-center justify-content-center tabs-image'
                                                            draggable
                                                            onDragStart={(e) => handleDragStart1(e, element)} //
                                                            onClick={hadleSetImage}
                                                            >
                                                            <img style={{ width: "100%" }} src={element.properties.src } alt={`image_${index}`} />
                                                        </div>
                                                    ))} */}

                        {isImageListLoading ? (
                          <>
                            {[...Array(30)].map((_, i) => (
                              // <ImageListLoader height="100" key={i} />
                              <Box
                                className="max-w-170 image-card"
                                // style={{ minHeight: 100 ? height : "" }}
                                width={100}
                                height={100}
                                margin="auto"
                                key={i}
                                // padding={isSmallScreen ? 1 : 2}
                              >
                                <Skeleton
                                  variant="rounded"
                                  animation="wave"
                                  width="100%"
                                  height={100}
                                />
                              </Box>
                            ))}
                          </>
                        ) : searchResults && searchResults.length > 0 ? (
                          searchResults.map((image, i) => (
                            <div
                              key={i}
                              className="d-flex align-items-center justify-content-center tabs-image"
                              onClick={hadleSetImage}
                            >
                              <img
                                draggable="true"
                                onDragStart={(e) => handleDragStart1(e, image)}
                                loading="lazy"
                                src={image.image_url}
                                className="rounded-3"
                                alt={image.image_id}
                                style={{ width: "100%", objectFit: "cover" }}
                              />
                            </div>
                          ))
                        ) : // <p className="fs-14">{t("noRecentImagesFound")}</p>
                        null}
                      </div>
                    </div>
                  </div>
                )}

                {tab === 2 && (
                  <div>
                    <form>
                      <div className="position-relative w-90 mt-4 mb-4 mt-md-4 mt-3 mb-3">
                        {/* <div className='position-relative tabs-search'>
                                                        <input
                                                            className="form-control"
                                                            name='input_text'
                                                            placeholder="Search"
                                                            aria-label="Search"
                                                        />
                                                        <img src={Search1} className='position-absolute' alt='search' />
                                                    </div> */}
                      </div>
                    </form>
                    <div>
                      <UploadedFiles
                        setFiledata={setFiledata}
                        modalClass={false}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Rename popup */}
      <RenameFilesPopUp
        fileData={fileData}
        setFiledata={setFiledata}
        getUploadedFiles={getUploadedFiles}
      />
      {/* <AddImagePopUp typeChange={typeChange} /> */}

      <div
        className="modal fade"
        id="add_design"
        tabIndex="-1"
        aria-labelledby="add_designLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content px-3">
            <div className="modal-header border-0">
              <div className="upload_design">{t("uploadDesign")}</div>
              <div className="cursor_pointer asdasdas" data-bs-dismiss="modal">
                <img src={Close} alt="Close" />
              </div>
            </div>
            <div className="modal-body">
              <UploadDesignFile
                setUploadedFiles={setUploadedFiles}
                screen="sidemenu"
                board_id={boardId}
                createdProjectId={createdProjectId}
                onFilesFetched={handleFilesFetched}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Add Search Modal */}

      <div
        className="modal fade"
        id="search_modal"
        tabIndex="-1"
        aria-labelledby="search_modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-fullscreen modal-dialog-scrollable">
          <div className="modal-content px-3">
            <div className="modal-header border-0">
              <div className="upload_design">{t("browse")}</div>
              <div
                className="cursor_pointer"
                data-bs-dismiss="modal"
                onClick={onCancelHandler}
              >
                <img
                  src={Close}
                  alt="Close"
                  data-target="close-search-popup"
                  onClick={() => setSearchOpen(false)}
                />
              </div>
            </div>

            <div className="modal-body">
              <div className="modalWrap">
                <div className="modalSearchWrapper">
                  <div className="tabWrapper">
                    <ul>
                      <li>
                        <Link
                          to="#"
                          onClick={() => setTabs("quick-search")}
                          className={tabs === "quick-search" && "active"}
                        >
                          {t("Library")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          onClick={() => setTabs("uploaded")}
                          className={tabs === "uploaded" && "active"}
                        >
                          {t("MyFiles")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {tabs === "quick-search" && (
                    <div
                      className={`modalWrapper ${imageUrl?.length > 0 ? "active" : ""}`}
                    >
                      <BrowseSearch
                        setSearchOpen={setSearchOpen}
                        searchOpen={searchOpen}
                        onSetImageUrl={handleSetImageUrl}
                        showImageDetails={showImageDetails}
                        setShowImageDetails={setShowImageDetails}
                        prevResponseValue={responseValue}
                        ref={browseSearchRef}
                      />
                      {imageUrl?.length > 0 && (
                        <div className="modalDetailsWrapper">
                          <div className="imageDetailsSlider">
                            <Swiper
                              modules={[Navigation, Pagination]}
                              navigation={imageUrl.length > 1}
                              pagination={{
                                clickable: true,
                                dynamicBullets: true,
                              }}
                              slidesPerView={1}
                              className="detail-swiper"
                            >
                              {imageUrl?.map((item, index) => (
                                <SwiperSlide key={index}>
                                  <div
                                    className="detailImg"
                                    style={{ height: "300px" }}
                                  >
                                    <img
                                      src={item?.image_url}
                                      alt={`micovision`}
                                      className="img-fluid"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                  <div className="ctrBox">
                                    <div className="formate">
                                      <div className="formateCont">
                                        <h5>{t("ctr")}</h5>
                                        <p>{`${t(item?.ctr?.[0])} ~ ${t(item?.ctr?.[1])}`}</p>
                                      </div>
                                      <div className="formateCont">
                                        <h5>{t("visionDetailsformat")}</h5>
                                        <p>{t(item?.image_extension)}</p>
                                      </div>
                                      <div className="formateCont">
                                        <h5>{t("visionDetailssize")}</h5>
                                        <p>{t(item?.image_size)}</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ctrBox">
                                    <div className="project-filter">
                                      <div className="project-title">
                                        <h5>{t("tags")}</h5>
                                      </div>
                                      <div className="project-listing">
                                        <ul>
                                          {formValue?.edit_tags
                                            ?.filter((tag) => tag?.length > 1)
                                            ?.sort((a, b) => a.localeCompare(b))
                                            ?.map((tag, tagIndex) => (
                                              <li key={tagIndex}>{tag}</li>
                                            ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {tabs === "uploaded" && <UploadedFiles modalClass={true} />}
                </div>
              </div>
            </div>
            {showImageDetails && (
              <div className="modal-footer border-0">
                <p
                  type="button"
                  className="cancel_modal_footer"
                  data-bs-dismiss="modal"
                  onClick={onCancelHandler}
                >
                  {t("cancel")}{" "}
                </p>
                <button
                  type="button"
                  className="send_modal_footer"
                  onClick={() => {
                    onHandleSelectedImage();
                  }}
                >
                  Select
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
