import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const SearchContext = createContext();

export const useSearchContext = () => {
  return useContext(SearchContext);
};

export const SearchProvider = ({ children }) => {
  const { t } = useTranslation();
  // dashboard searchview
  const getSearchViewState = () => {
    const savedState = localStorage?.getItem("SEARCH_VIEW");
    const state = savedState === "true";
    setSearchView(state);
    return state;
  };

  const setSearchViewState = async (state) => {
    await localStorage?.setItem("SEARCH_VIEW", state);
    setSearchView(state);
  };
  const [searchView, setSearchView] = useState(false);
  const [scrollLoader, setScrollLoader] = useState(false);

  // search
  const [searchResults, setSearchResults] = useState([]);
  const [similarResults, setSimilarResults] = useState([]);

  const [results, setResults] = useState([]);
  const [similarityGroup, setSimilarityGroup] = useState([]);

  const [numberOfResults, setNumberOfResults] = useState({
    primary: 0,
    similar: 0,
  });
  const [data, setData] = useState({});
  const [prevSearch, setPrevSearch] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [showResults, setShowResults] = useState(
    localStorage.getItem("searchResults") ? true : false
  );
  const [formValue, setFormValue] = useState({
    input_text: "",
    page: 1,
    ctr: 0,
    tags: [],
    model_filters: [],
    is_edited: "",
    search_by: "both",
  });
  const [selectedFilter, setSelectedFilter] = useState({
    industry: [],
    tags: [],
    personInImage: [],
    imageStyle: [],
    deliveryContent: [],
    imageType: [],
    deliveryType: [],
    imageType: [],
    editableImages: "",
    ctr: 0,
  });
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    getSearchViewState();
  }, []);

  const resetSearchContext = () => {
    setSearchResults([]);
    setSimilarResults([]);
    setResults([]);
    setNumberOfResults({
      primary: 0,
      similar: 0,
    });
    setSimilarityGroup([]);
    setShowResults(false);
    setPrevSearch(false);
    setIsEdited(false);
    setFormValue({
      input_text: "",
      page: 1,
      ctr: 0,
      tags: [],
      model_filters: [],
      is_edited: "",
      search_by: "both",
    });
    setSelectedFilter({
      industry: [],
      tags: [],
      personInImage: [],
      imageStyle: [],
      deliveryContent: [],
      imageType: [],
      deliveryType: [],
      editableImages: "",
      ctr: 0,
    });
    setChecked(false);
    setScrollLoader(false);
  };

  const applyQueryParams = (queryParams, filterTags) => {
    const newFormValue = { ...formValue };
    const newSelectedFilter = { ...selectedFilter };

    // Handle input text
    if (queryParams.get("q")) {
      newFormValue.input_text = queryParams.get("q");
    }

    // Handle CTR
    if (queryParams.get("ctr")) {
      const ctrValue = parseInt(queryParams.get("ctr"));
      newFormValue.ctr = ctrValue;
      newSelectedFilter.ctr = ctrValue;
    }

    // Handle tags
    if (queryParams.get("tags")) {
      const tags = queryParams
        .get("tags")
        .split(",")
        .map((tag) => tag.replace(/%2C/g, ","));
      newFormValue.tags = tags;

      newSelectedFilter.tags = tags;
    }

    // Handle industry
    if (queryParams.get("industry")) {
      const industries = queryParams
        .get("industry")
        .split(",")
        .map((a) => Number(a));
      newSelectedFilter.industry = industries
        .map((industry) => {
          const industryFilter = filterTags?.categories?.find(
            (category) => category.category_key === "industry"
          );
          const industryEntry = Object.entries(
            industryFilter?.category_options || {}
          ).find(([key, value]) => value === parseInt(industry));
          return t(industryEntry ? industryEntry[0] : industry);
        })
        .filter((a) => a != null);
      newFormValue.industry = industries;
    }

    // Handle image type
    if (queryParams.get("imageType")) {
      const imageTypes = queryParams
        .get("imageType")
        .split(",")
        .map((a) => Number(a));

      newSelectedFilter.imageType = imageTypes
        .map((imageType) => {
          const imageTypeFilter = filterTags?.categories?.find(
            (category) => category.category_key === "image_type"
          );
          const imageTypeEntry = Object.entries(
            imageTypeFilter?.category_options || {}
          ).find(([key, value]) => value === parseInt(imageType));
          return imageTypeEntry ? imageTypeEntry[0] : imageType;
        })
        .filter((a) => a != null);
      newFormValue.imageType = imageTypes;
    }

    // Handle delivery type
    if (queryParams.get("deliveryType")) {
      const deliveryTypes = queryParams
        .get("deliveryType")
        .split(",")
        .map((a) => Number(a));
      newSelectedFilter.deliveryType = deliveryTypes
        .map((deliveryType) => {
          const deliveryTypeFilter = filterTags?.categories?.find(
            (category) => category.category_key === "delivery_type"
          );
          const deliveryTypeEntry = Object.entries(
            deliveryTypeFilter?.category_options || {}
          ).find(([key, value]) => value === parseInt(deliveryType));
          return deliveryTypeEntry ? deliveryTypeEntry[0] : deliveryType;
        })
        .filter((a) => a != null);
    }

    // Handle delivery content
    if (queryParams.get("deliveryContent")) {
      const deliveryContents = queryParams
        .get("deliveryContent")
        .split(",")
        .map((a) => Number(a));
      newSelectedFilter.deliveryContent = deliveryContents
        .map((deliveryContent) => {
          const deliveryContentFilter = filterTags?.categories?.find(
            (category) => category.category_key === "delivery_content"
          );
          const deliveryContentEntry = Object.entries(
            deliveryContentFilter?.category_options || {}
          ).find(([key, value]) => value === parseInt(deliveryContent));
          return deliveryContentEntry
            ? deliveryContentEntry[0]
            : deliveryContent;
        })
        .filter((a) => a != null);
      newFormValue.deliveryContent = deliveryContents;
    }

    // Handle editable images
    if (queryParams.get("editableImages")) {
      newFormValue.is_edited = queryParams.get("editableImages");
      newSelectedFilter.editableImages = queryParams.get("editableImages");
    }
    if (queryParams.get("model_filters")) {
      newFormValue.model_filters = JSON.parse(queryParams.get("model_filters"));
      newSelectedFilter.model_filters = JSON.parse(
        queryParams.get("model_filters")
      );
    }
    setFormValue(newFormValue);

    setSelectedFilter(newSelectedFilter);
    setShowResults(true);
  };

  const addToQueryParams = (queryParams) => {
    const newQueryParams = new URLSearchParams(window.location.search);
    if (queryParams?.input_text) {
      newQueryParams.set("q", queryParams.input_text);
    }
    if (queryParams?.ctr) {
      newQueryParams.set("ctr", queryParams.ctr);
    }
    if (queryParams?.tags) {
      newQueryParams.set(
        "tags",
        queryParams?.tags?.map((tag) => tag.replace(",", "%2C")).join(",")
      );
    }
    if (queryParams?.industry) {
      newQueryParams.set("industry", queryParams?.industry?.join(","));
    }

    if (queryParams?.imageType || queryParams?.image_type) {
      newQueryParams.set(
        "imageType",
        (queryParams?.imageType || queryParams?.image_type)?.join(",")
      );
    }
    if (queryParams?.deliveryType || queryParams?.delivery_type) {
      newQueryParams.set(
        "deliveryType",
        (queryParams?.deliveryType || queryParams?.delivery_type)?.join(",")
      );
    }
    if (queryParams?.deliveryContent || queryParams?.delivery_content) {
      newQueryParams.set(
        "deliveryContent",
        (queryParams?.deliveryContent || queryParams?.delivery_content)?.join(
          ","
        )
      );
    }
    if (queryParams?.editableImages) {
      newQueryParams.set("editableImages", queryParams?.editableImages);
    }

    if (queryParams?.model_filters) {
      newQueryParams.set(
        "model_filters",
        JSON.stringify(queryParams.model_filters)
      );
    }
    window.history.pushState(
      {},
      "",
      window.location.pathname + "?" + newQueryParams.toString()
    );
  };

  return (
    <SearchContext.Provider
      value={{
        searchResults,
        prevSearch,
        setPrevSearch,
        setSearchResults,
        similarResults,
        setSimilarResults,
        results,
        similarityGroup,
        setSimilarityGroup,
        setResults,
        numberOfResults,
        isEdited,
        setIsEdited,
        setNumberOfResults,
        formValue,
        setData,
        data,
        setFormValue,
        showResults,
        setShowResults,
        setSelectedFilter,
        selectedFilter,
        searchView,
        setSearchViewState,
        checked,
        setChecked,
        scrollLoader,
        setScrollLoader,
        resetSearchContext,
        applyQueryParams,
        addToQueryParams,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
