import React, { useEffect, useRef, useState } from "react";
import comments from "../../../../assets/newicons/comments.svg";
import headerSetting from "../../../../assets/setting.svg";
import viewArray from "../../../../assets/newicons/view_array.svg";
import minimize from "../../../../assets/newicons/minimize.svg";
import maximize from "../../../../assets/newicons/maximize.svg";

import initiData from "../../initiData";
import {
  Excalidraw,
  Footer,
  MIME_TYPES,
  restoreElements,
  Sidebar,
  useDevice,
} from "@excalidraw/excalidraw";
import { useReferenceContext } from "../../../../context/useReferenceContext";
import { useLocation } from "react-router-dom";
import { imageGet, newGet } from "../../../../API/axios";
import CommentAndTask from "../CommentAndTask";

const DualVisionBoard = ({
  dragging = false,
  isProjectActive = () => {},
  splitScreenSwitchedOn,
  createdProjectId,
}) => {
  const [isInternal, setIsInternal] = useState(true);
  const [referenceList, setReferenceList] = useState([]);
  const [excalidrawAPI, setExcalidrawAPI] = useState(null);
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [excalidrawData, setExcalidrawData] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const urlPattern = /^https:\/\/miro\.com\/app.*/;

  const location = useLocation();
  const device = useDevice();

  const {
    miroValue,
    setMiroValue,
    miroLink,
    setMiroLink,
    setExcalidrawLoading,
    excalidrawLoading,
    boardId,
    setBoardId,
    projectDetails,
    setProjectDetails,
    setUploadedFiles,
    referencesList,
    setReferencesList,
  } = useReferenceContext();

  let { selectedImages, channelId, projectName, channelName, projectStatus } =
    location.state || {};

  const [ProjectId, setCreatedProjectId] = useState(createdProjectId);

  const resolvablePromise = () => {
    let resolve;
    let reject;
    const promise = new Promise((_resolve, _reject) => {
      resolve = _resolve;
      reject = _reject;
    });
    promise.resolve = resolve;
    promise.reject = reject;
    return promise;
  };
  const initialStatePromiseRef = useRef({ promise: resolvablePromise() });
  if (!initialStatePromiseRef?.current?.promise) {
    initialStatePromiseRef.current.promise = resolvablePromise();
  }

  const toggleCommentSection = () => {
    setShowCommentSection((prev) => !prev);
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
        const base64Image = reader.result;
        const img = new Image();
        img.src = base64Image;
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  useEffect(() => {
    // if (!excalidrawData) {
    //     initialStatePromiseRef.current.promise.resolve(initialData);
    //     return;
    // };

    (async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const projectIdFromUrl = urlParams.get("project_id");
        if (!(projectIdFromUrl || createdProjectId)) {
          return;
        }
        const data = await newGet(
          // `reference/fetchReferences?project_id=${projectIdFromUrl || createdProjectId}`
          `board/get?project_id=${projectIdFromUrl || createdProjectId}`
        ).then(async (data) => {
          if (data && data.status_code == 200) {
            setMiroValue(data?.result?.miro_link);
            if (
              data?.result?.miro_link &&
              data?.result?.is_external &&
              urlPattern.test(data?.result?.miro_link)
            ) {
              setIsInternal(false);
            }
            setReferenceList(data?.result?.files);
            setMiroLink(data?.result?.miro_link);
            setBoardId(data?.result?.board_id);
            setReferencesList(data?.result);
            if (data?.result?.board_json) {
              const parsedData = JSON.parse(data.result.board_json);

              const updatedData = {
                ...parsedData,
                appState: {
                  ...(parsedData.appState || {}),
                },
              };
              setExcalidrawData(updatedData);
            } else {
              initialStatePromiseRef.current.promise.resolve(initiData);
              setExcalidrawLoading(false);
            }
          }
        });
      } catch (error) {}
    })();
  }, []);

  const addFilesToView = async () => {
    const files = excalidrawData.files || {};
    await Promise.all(
      Object.entries(files).map(async ([key, file]) => {
        if (!file.dataURL.startsWith("data:")) {
          let response;
          let blob;
          try {
            response = await fetch(file.dataURL);
            blob = await response.blob();
          } catch (error) {
            blob = await imageGet("download_image", {
              image_url: file.dataURL,
            });
          }

          const base64String = await blobToBase64(blob);

          excalidrawAPI.addFiles([
            { ...file, dataURL: base64String, imageUrl: file.dataURL },
          ]);
          return [key, { ...file, dataURL: base64String }];
        }
        return [key, file];
      })
    );

    const updatedData = {
      ...excalidrawData,
      files: files,
      appState: {
        ...(excalidrawData.appState || {}),
      },
    };

    initialStatePromiseRef.current.promise.resolve(updatedData);
    excalidrawAPI.updateScene(updatedData);
    setExcalidrawLoading(false);
    setTimeout(() => {
      excalidrawAPI.scrollToContent();
    }, 100);
  };

  useEffect(() => {
    if (excalidrawData && excalidrawAPI) {
      addFilesToView();
    }
  }, [excalidrawData, excalidrawAPI]);

  const toggleFullscreen = () => {
    const excalidrawContainer = document.getElementById("excalidraw-container");
    if (!isFullscreen) {
      if (excalidrawContainer.requestFullscreen) {
        excalidrawContainer.requestFullscreen();
      } else if (excalidrawContainer.webkitRequestFullscreen) {
        excalidrawContainer.webkitRequestFullscreen(); // Safari
      } else if (excalidrawContainer.msRequestFullscreen) {
        excalidrawContainer.msRequestFullscreen(); // IE11
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen(); // Safari
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen(); // IE11
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        pointerEvents: dragging ? "none" : "auto",
      }}
    >
      <div className="d-flex justify-content-between flex-wrap align-items-center gap-md-3 gap-2 py-md-2 px-md-3 p-sm-3 p-xs-2 p-2 py-2 bg-white border-bottom font-noto-sans">
        <div className="d-flex align-items-center fs-14 fw-600">
          Vision Board
        </div>
        <div className="d-flex align-items-center gap-3">
          <img
            src={comments}
            alt="comments"
            className="cursor-pointer"
            style={{ cursor: "pointer" }}
            onClick={() => setShowCommentSection(!showCommentSection)}
          />
        </div>
      </div>
      <div
        className="d-flex"
        style={{
          flexGrow: 1,
          height: "1px",
        }}
      >
        <div
          className="dual-mode-excalidraw"
          style={{
            height: "100%",
            flexGrow: 1,
            display: isInternal ? "block" : "none",
          }}
        >
          <Excalidraw
            viewModeEnabled={true}
            excalidrawAPI={(api) => setExcalidrawAPI(api)}
            initialData={initialStatePromiseRef.current.promise}
            UIOptions={{
              canvasActions: {
                loadScene: false,
                saveAsImage: false,
                saveScene: false,
                saveAsScene: false,
                export: false,
                clearCanvas: false,
                saveToActiveFile: false,
              },
            }}
            renderTopRightUI={() => {
              return (
                <>
                  {device?.editor?.isMobile ? (
                    <button
                      style={{
                        border: "none",
                        width: "max-content",
                        fontWeight: "bold",
                      }}
                      className="exciladraw-fullscreen-btn"
                    >
                      <Sidebar.Trigger name="custom" tab="one">
                        <img
                          src={viewArray}
                          alt="viewArray"
                          width={20}
                          height={16}
                          className="align-baseline"
                          style={{ marginTop: "3px" }}
                        />
                      </Sidebar.Trigger>
                    </button>
                  ) : (
                    <button
                      style={{
                        border: "none",
                        width: "max-content",
                        fontWeight: "bold",
                        width: "16px",
                      }}
                      className="exciladraw-fullscreen-btn"
                      onClick={toggleFullscreen}
                    >
                      {isFullscreen ? (
                        <img src={minimize}></img>
                      ) : (
                        <img src={maximize}></img>
                      )}
                    </button>
                  )}
                </>
              );
            }}
          ></Excalidraw>
        </div>
        <div
          style={{
            height: "100%",
            flexGrow: 1,
            display: isInternal ? "none" : "block",
          }}
        >
          <iframe
            className="responsive-iframe"
            src={miroLink}
            title="Miro"
            width="100%"
            height="100%"
            frameBorder="0"
            scrolling="no"
            allowFullScreen
          />
        </div>

        <div
          className={`sidebar-comment max-w-420px w-100  ${
            showCommentSection
              ? splitScreenSwitchedOn
                ? "d-flex align-items-stretch"
                : "d-block"
              : "d-none"
          }`}
        >
          <div
            className={`w-100 bg-color-gray border-start ${splitScreenSwitchedOn ? "h-auto" : "heigth-calc-56"}  comment-task-sidebar`}
          >
            <button
              className="sidebar-close-btn"
              onClick={() => setShowCommentSection(false)}
            >
              <span></span>
            </button>
            <div
              className={`h-100 ${showCommentSection ? "d-block overflow-auto" : "d-none"}`}
              // style={{ pointerEvents: isProjectActive() ? "auto" : "none" }}
            >
              <CommentAndTask
                key={"comments"}
                selectedImages={selectedImages}
                ProjectId={ProjectId}
                referenceList={referenceList}
                createdProjectId={createdProjectId}
                // selectedImage={selectedImage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DualVisionBoard;
