import React, { useState, useEffect, useRef } from "react";
import { useEditContext } from "../../context/useEditContext";
import close from "../../assets/icons/close.svg";
import { showAlert } from "../Manager/component/AlertService";
import {
  Autocomplete,
  CircularProgress,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { post } from "../../API/axios";
import { toast } from "react-toastify";
import { InputSwitch } from "primereact/inputswitch";
import debounce from "debounce";
import { SearchAPI } from "../../API/SearchAPI";
import "../ImageDetails/download.scss";
import "./edit.scss";
import "swiper/css";
import "swiper/css/navigation";
import refresh from "../../assets/icons/refresh.png";
import CategoryFilter from "../../helpers/CategoryFilter";
import ModelFilters from "../../helpers/ModelFilter";
import { Navigation } from "swiper/modules";
import { SwiperSlide, Swiper } from "swiper/react";
import BackIcon from "../../assets/icons/arrow-circle-left.svg";
import { useFilterContext } from "../../context/useFilterContext";
import { useSearchContext } from "../../context/useSearchContext";
import TextInputWithCounter from "../../components/TextInputWithCounter";
// import { AutoTokenizer, env } from "@xenova/transformers";
import MicoBackground from "../../components/MicoBackground";
import { useAuthContext } from "../../context/useAuthContext";

// env.allowLocalModels = false;
// env.useBrowserCache = false;
// const tokenizer = await AutoTokenizer.from_pretrained(
//   "Xenova/clip-vit-base-patch32"
// );

const NewEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isDebug, hasPermission } = useAuthContext();
  const { data, swiperImages } = location.state || {};
  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (!data || !hasPermission(24)) {
      navigate("/search");
    }
  }, [data, navigate]);

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const {
    editedImages,
    saveEditedImages,
    saveSavedImages,
    savedImages,
    clearEditData,
  } = useEditContext();

  const { setPrevSearch, setIsEdited } = useSearchContext();
  const [similarImages, setSimilarImages] = useState(
    swiperImages?.similar_group_images || []
  );
  const [checked, setChecked] = useState(false);
  const [tags, setTags] = useState([]);
  const [slidesPerView, setSlidesPerView] = useState(5);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [unSavedChanges, setUnSavedChanges] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openEditAlert, setOpenEditAlert] = useState(false);
  const [isDescriptionEdited, setIsDescriptionEdited] = useState(false);

  const [descriptionLength, setDescriptionLength] = useState(0);

  const [formValue, setFormValue] = useState({
    image_id: data?.image_id || 0,
    image_uuid: data?.image_uuid || "",
    lang: currentLanguage,
    edit_description:
      currentLanguage === "en"
        ? data?.image_description
        : data?.image_description_ja,
    edit_tags:
      currentLanguage === "ja" ? data?.image_ja_tags : data?.image_en_tags,
    inputTag: "",
    image_url: data?.image_url || "",
    ctr: data?.ctr || [],
    score: data?.score,
    industry: data?.industry,
    text_extraction: data?.text_extraction,
    delivery_content: data?.delivery_content,
    delivery_type: data?.delivery_type,
    // image_type: data?.image_type,
    image_size: data?.image_size,
    image_extension: data?.image_extension,
    model_filters: data?.model_filters,
  });

  const { filterTags } = useFilterContext();

  const handleRejection = () => {
    setOpenAlert(false);
  };

  const handleClose = () => {
    setOpenAlert(false);
  };

  const handleConfirmation = () => {
    setOpenAlert(false);
    navigateToDetails();
  };

  const handleEditConfirmation = () => {
    // setOpenAlert(false);
    // navigateToDetails();
    handleEdit();
  };

  const checkEdit = () => {
    if (!isDebug && isDescriptionEdited) {
      setOpenEditAlert(true);
    } else {
      handleEdit();
    }
  };
  const handleEdit = async () => {
    setSaving(true);
    try {
      if (checked === true && similarImages?.length > 1) {
        const updatedData = similarImages?.map((image) => {
          return {
            image_id: image.image_id,
            image_uuid: image.image_uuid,
            lang: currentLanguage,
            edit_description: formValue.edit_description.trimEnd(),
            edit_tags: formValue.edit_tags,
            industry: formValue.industry,
            delivery_content: formValue.delivery_content,
            delivery_type: formValue.delivery_type,
            image_type: formValue.image_type,
            text_extraction: formValue.text_extraction,
            model_filters: formValue.model_filters,
          };
        });
        setSimilarImages(updatedData);
        await post("edit_images/", {
          image_list: updatedData,
        }).then((res) => {
          if (res.status_code === 200) {
            showAlert(t("descriptionUpdatedSuccessfully"), "success");
            setIsEdited(true);
            setPrevSearch(true);
            setUnSavedChanges(false);
            if (!savedImages?.includes(formValue.image_id)) {
              saveSavedImages(formValue.image_id);
            }
            navigateToDetails("call_imageDetail_api");
          }
        });
      } else if (checked === false && similarImages.length > 1) {
        const index = similarImages?.findIndex(
          (image) => image.image_uuid === formValue.image_uuid
        );
        if (index !== -2) {
          const updatedData = [...similarImages];
          updatedData[index] = {
            ...updatedData[index],
            edit_description: formValue.edit_description.trimEnd(),
            edit_tags: formValue.edit_tags,
          };
          setSimilarImages(updatedData);
          await post("edit_images/", {
            image_list: [
              {
                image_uuid: updatedData[index].image_uuid,
                lang: currentLanguage,
                edit_description: formValue.edit_description.trimEnd(),
                edit_tags: formValue.edit_tags,
                image_id: updatedData[index].image_id,
                industry: formValue.industry,
                delivery_content: formValue.delivery_content,
                delivery_type: formValue.delivery_type,
                text_extraction: formValue.text_extraction,
                image_type: formValue.image_type,
                model_filters: formValue.model_filters,
              },
            ],
          }).then((res) => {
            if (res.status_code === 200) {
              showAlert(t("descriptionUpdatedSuccessfully"), "success");
              setUnSavedChanges(false);
              setPrevSearch(true);
              setIsEdited(true);
              if (!savedImages?.includes(formValue.image_id)) {
                saveSavedImages(formValue.image_id);
              }
              navigateToDetails("call_imageDetail_api");
            }
          });
        }
      } else if (similarImages.length === 0) {
        await post("edit_images/", {
          image_list: [
            {
              image_uuid: formValue.image_uuid,
              lang: currentLanguage,
              edit_description: formValue.edit_description.trimEnd(),
              edit_tags: formValue.edit_tags,
              image_id: formValue.image_id,
              industry: formValue.industry,
              delivery_content: formValue.delivery_content,
              delivery_type: formValue.delivery_type,
              image_type: formValue.image_type,
              text_extraction: formValue.text_extraction,
              model_filters: formValue.model_filters,
            },
          ],
        }).then((res) => {
          if (res.status_code === 200) {
            setUnSavedChanges(false);
            setPrevSearch(true);
            setIsEdited(true);
            if (!savedImages?.includes(formValue.image_id)) {
              saveSavedImages(formValue.image_id);
            }
            navigateToDetails();
          }
        });
      }
    } catch (error) {
      console.error("Error updating description:", error);
    } finally {
      setSaving(false);
    }
  };

  const getSearchTags = async () => {
    setLoading(true);
    const data = await SearchAPI.getSearchTags();
    localStorage.setItem("en_tags", JSON.stringify(data.en));
    localStorage.setItem("ja_tags", JSON.stringify(data.ja));
    setTags([...(data?.en || []), ...(data?.ja || [])]);

    // setTags(
    //   (currentLanguage === "ja" ? data.ja : data.en).filter((t) => t !== "")
    // );
    setLoading(false);
  };

  const handleEditDataSet = async (template) => {
    const descriptionKey =
      currentLanguage === "ja" ? "image_description_ja" : "image_description";
    const tagsKey = "image_" + (currentLanguage || "en") + "_tags";

    const updatedData = await similarImages.map((image) => {
      if (image.image_uuid === formValue.image_uuid) {
        return {
          ...image,
          [descriptionKey]: formValue.edit_description,
          [tagsKey]: formValue.edit_tags,
        };
      }
      return image;
    });

    await setSimilarImages(updatedData);
    setFormValue({
      image_id: template?.image_id || 0,
      image_uuid: template?.image_uuid || "",
      lang: template?.lang || "en",
      edit_description:
        currentLanguage === "ja"
          ? template?.image_description_ja
          : template?.image_description,
      // edit_tags:
      //   currentLanguage === "ja"
      // ? template?.image_ja_tags
      //     : template?.image_en_tags,
      edit_tags: [
        ...(template?.image_en_tags || []),
        ...(template?.image_ja_tags || []),
      ],
      inputTag: "",
      ctr: template?.ctr,
      score: template.score,
      image_url: template?.image_url,
      model_filters: template?.model_filters,
      industry: template?.industry,
      delivery_content: template?.delivery_content,
      delivery_type: template?.delivery_type,
      image_type: template?.image_type,
    });
  };

  const handleChange = async (e) => {
    setUnSavedChanges(true);
    const { name, value } = e.target;

    // if (name === "edit_description") {
    //   // const { input_ids } = await tokenizer(e.target.value);
    //   // const tokenCount = input_ids.size;
    //   // setDescriptionLength(tokenCount);
    //   // if (tokenCount < 70) {
    //     setFormValue((prevState) => ({
    //       ...prevState,
    //       [name]: value,
    //     }));
    //     if (!editedImages?.includes(formValue.image_id)) {
    //       saveEditedImages(formValue.image_id);
    //     }
    //   }
    // } else {
    if (name === "edit_description") {
      setIsDescriptionEdited(true);
    }
    setFormValue((prevState) => ({ ...prevState, [name]: value }));
    if (!editedImages?.includes(formValue.image_id)) {
      saveEditedImages(formValue.image_id);
    }
    // }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 450) {
        setSlidesPerView(1);
      } else if (window.innerWidth <= 700) {
        setSlidesPerView(2);
      } else if (window.innerWidth <= 992) {
        setSlidesPerView(3);
      } else if (window.innerWidth <= 1200) {
        setSlidesPerView(4);
      } else if (window.innerWidth <= 1400) {
        setSlidesPerView(5);
      } else if (window.innerWidth <= 1920) {
        setSlidesPerView(6);
      } else {
        setSlidesPerView(7);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleHashtagChange = (value) => {
    if (value && !formValue.edit_tags.includes(value)) {
      setFormValue((prevState) => ({
        ...prevState,
        edit_tags: [...prevState.edit_tags, value],
        inputTag: "",
      }));
    } else {
      setFormValue((prevState) => ({
        ...prevState,
        inputTag: "",
      }));
    }
    setUnSavedChanges(true);
    if (!editedImages?.includes(formValue.image_id)) {
      saveEditedImages(formValue.image_id);
    }
    const clearButton = autocompleteRef.current.querySelector(
      '[aria-label="Clear"]'
    );
    if (clearButton) {
      clearButton.click();
    }
  };

  const backToSearch = () => {
    if (unSavedChanges) {
      setOpenAlert(true);
    } else {
      navigateToDetails();
    }
  };
  const onLangChange = async (lang) => {
    setFormValue({
      ...formValue,
      lang: lang,
      edit_description:
        currentLanguage === "ja"
          ? data?.image_description_ja
          : data?.image_description,
      // edit_tags:
      //   currentLanguage === "ja" ? data?.image_ja_tags : data?.image_en_tags,
      edit_tags: [
        ...(data?.image_en_tags || []),
        ...(data?.image_ja_tags || []),
      ],
    });
    let desc =
      currentLanguage === "ja"
        ? data?.image_description_ja
        : data?.image_description;
    if (desc) {
      // const { input_ids } = await tokenizer(desc);
      // const tokenCount = input_ids?.size;
      // setDescriptionLength(tokenCount);
    }
  };
  const searchTags = debounce(() => {
    getSearchTags();
  }, 1000);

  useEffect(() => {
    onLangChange(currentLanguage);
    const en_tags = localStorage.getItem("en_tags");
    const ja_tags = localStorage.getItem("ja_tags");
    if (en_tags || ja_tags) {
      setTags([...JSON.parse(en_tags), ...JSON.parse(ja_tags)]);
      // setTags(
      //   currentLanguage === "ja" ? JSON.parse(ja_tags) : JSON.parse(en_tags)
      // );
    } else {
      searchTags();
    }
  }, [currentLanguage]);

  const handleRemoveTag = (index) => {
    const updatedTags = [...formValue.edit_tags];
    updatedTags.splice(index, 1);
    setFormValue({ ...formValue, edit_tags: updatedTags });
    setUnSavedChanges(true);
    if (!editedImages?.includes(formValue.image_id)) {
      saveEditedImages(formValue.image_id);
    }
  };

  const navigateToDetails = (callApi) => {
    navigate(`/image-details?id=${data.image_id}`, {
      state: {
        data: data,
        fetchNewImageDetail: true,
        swiperImages: swiperImages,
      },
    });
  };

  useEffect(() => {
    if (
      similarImages?.length !== 0 &&
      savedImages?.length === similarImages?.length
    ) {
      clearEditData();
      navigateToDetails();
    }
  }, [savedImages]);

  const handleSwitch = async () => {
    if (!checked === true) {
      setFormValue({
        image_id: data?.image_id || 0,
        image_uuid: data?.image_uuid || "",
        lang: currentLanguage,
        edit_description:
          currentLanguage === "ja"
            ? data?.image_description_ja
            : data?.image_description,
        // edit_tags:
        //   currentLanguage === "ja" ? data?.image_ja_tags : data?.image_en_tags,
        edit_tags: [
          ...(data?.image_en_tags || []),
          ...(data?.image_ja_tags || []),
        ],
        inputTag: "",
        image_url: data?.image_url || "",
        ctr: data?.ctr || [],
        score: data?.score,
      });
    }
    setChecked(!checked);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.defaultMuiPrevented = true;
      const value = e.target.value.trim();
      if (value.length > 0) {
        handleHashtagChange(value);
        document.getElementById("submitbtn").focus();
        e.preventDefault();
      }
    }
  };

  return (
    <div>
      <section
        className={`
          d-flex gap-2 search-hero-section overflow-hidden`}
      >
        <div className="w-100">
          <MicoBackground />
          <div className="d-flex justify-content-center w-100">
            <div className="max-w-1260 w-100 search-content">
              <h2 className="text-white text-end fs-bold">
                {t("editDescription")}
              </h2>
              <div className="bg-white box-shadow p-5 mt-3">
                <div className="back-btn">
                  <img
                    src={BackIcon}
                    alt="backicon"
                    onClick={backToSearch}
                  ></img>
                </div>
                <div className="d-flex gap-3 gap-sm-5 flex-wrap justify-content-between">
                  <div className="fs-12 bg-light-blue text-center text-white p-1 rounded">
                    <div>
                      {data?.ctr?.length &&
                      data.ctr[0] !== null &&
                      data.ctr[1] !== null ? (
                        <div>
                          <span>
                            CTR:{" "}
                            {data?.ctr[0].toFixed(3) === data?.ctr[1].toFixed(3)
                              ? `${(data?.ctr[0] * 100).toFixed(2)}%`
                              : data?.ctr[0].toFixed(2) ===
                                  data?.ctr[1].toFixed(2)
                                ? `${(data?.ctr[0] * 100).toFixed(2)}%`
                                : `${(data?.ctr[0] * 100).toFixed(2)}% ~ ${(
                                    data?.ctr[1] * 100
                                  ).toFixed(2)}%`}
                          </span>
                        </div>
                      ) : (
                        <span>CTR: N/A</span>
                      )}
                    </div>
                  </div>

                  {/* {similarImages?.length > 1 && (
                    <div className="d-flex p-menuitem-link flex align-items-center gap-2">
                      <span className="font-s-14">{t("switchTitle")}</span>
                      <InputSwitch checked={checked} onChange={handleSwitch} />
                    </div>
                  )} */}

                  {/* <button
                    className="rounded-pill border-0 p-2 max-w-170 w-100 position-relative text-dark bg-sky-blue"
                    onClick={() =>
                      navigate("/create", {
                        state: {
                          data,
                        },
                      })
                    }
                  >
                    {t("create")}
                  </button> */}
                </div>
                <div className="d-flex mt-2 gap-4 flex-column flex-md-row ">
                  <div className="max-w-312 max-h-498 h-100 w-100">
                    <img
                      loading="lazy"
                      src={formValue?.image_url}
                      title={formValue?.text_extraction || t("no_text")}
                      alt=""
                      className="w-100"
                    />
                  </div>
                  <div className="w-100">
                    {/* <div className="d-flex color-light-gray border-gray-solid w-fit-content px-4 rounded">
                      <span>アカウント名</span>:<span>湘南美容クリニック</span>
                    </div> */}
                    <div
                      className="d-flex gap-4 color-light-gray flex-wrap align-items-center mt-3"
                      style={{ fontSize: "14px" }}
                    >
                      <div className="d-flex">
                        <span>{t("size")}</span>:&nbsp;
                        <span>{formValue?.image_size || " None"} </span>
                      </div>
                      <div className="d-flex fs-14">
                        <span>{t("fileType")}</span>:&nbsp;
                        <span>{formValue?.image_extension || " None"} </span>
                      </div>
                    </div>
                    <h4
                      className="filter-label mt-2"
                      style={{
                        fontSize: "16px",
                        display: isDebug ? "block" : "none",
                      }}
                    >
                      {t("editTitle")}
                    </h4>
                    {isDebug ? (
                      <TextInputWithCounter
                        maxLength={70}
                        value={formValue.edit_description}
                        handleChange={handleChange}
                        descriptionLength={descriptionLength}
                      />
                    ) : (
                      <></>
                    )}

                    <h4
                      className="filter-label mt-2"
                      style={{ fontSize: "16px" }}
                    >
                      {t("extrectedText")}
                    </h4>
                    <TextInputWithCounter
                      maxLength={70}
                      value={formValue.text_extraction}
                      handleChange={handleChange}
                      name="text_extraction"
                      descriptionLength={descriptionLength}
                      // placeholder={t('no_text')}
                    />

                    <div
                      className={`d-flex p-menuitem-link flex-wrap gap-2 justify-content-between w-100 align-items-center py-4`}
                    >
                      <CategoryFilter
                        filterTags={
                          hasPermission(27)
                            ? { categories: filterTags?.categories?.slice(1) }
                            : { categories: filterTags?.categories }
                        }
                        formValue={formValue}
                        setFormValue={setFormValue}
                      />
                    </div>
                    <div
                      className={`d-flex p-menuitem-link flex-wrap gap-2 justify-content-between w-100 ${"border-bottom"} align-items-center`}
                    >
                      <ModelFilters
                        filterTags={filterTags}
                        formValue={formValue}
                        setFormValue={setFormValue}
                      />
                    </div>
                    <h4
                      className="filter-label mt-3"
                      style={{ fontSize: "16px" }}
                    >
                      {t("editTags")}
                    </h4>
                    <div>
                      <div className="d-flex flex-wrap gap-2 align-items-center">
                        {formValue?.edit_tags
                          ?.sort((a, b) => a.localeCompare(b))
                          ?.filter((a) => a.length > 1)
                          ?.map((tag, index) => (
                            <button
                              onClick={(e) => {
                                handleRemoveTag(index);
                              }}
                              className={`rounded border-gray-solid text-truncate bg-white fs-16 w-100 max-w-120 color-light-gray pe-4 position-relative`}
                              key={index}
                              type="button"
                              disabled={saving}
                              title={tag}
                            >
                              <span>{tag}</span>
                              <span
                                style={{
                                  position: "absolute",
                                  right: "0px",
                                  top: "-1px",
                                  filter: "brightness(0)",
                                }}
                              >
                                <img src={close} alt="close" />{" "}
                              </span>
                            </button>
                          ))}
                      </div>
                    </div>
                    <div className=" mt-3">
                      <div className="d-flex align-items-center gap-2">
                        <div className="d-flex gap-sm-3 gap-1 w-100 align-items-center p-0">
                          <Autocomplete
                            freeSolo
                            open={open}
                            onOpen={() => {
                              setOpen(true);
                            }}
                            onClose={() => {
                              setOpen(false);
                            }}
                            loading={loading}
                            id="hashtag"
                            options={tags}
                            name="inputTag"
                            className="input-border-gray w-100 bg-transparent"
                            value={formValue.inputTag}
                            getOptionLabel={(tags) => tags}
                            onChange={(e, value) => {
                              handleHashtagChange(value);
                            }}
                            // selectOnFocus
                            clearOnEscape
                            disabled={saving}
                            handleHomeEndKeys
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="inputTag"
                                onKeyDown={handleKeyPress}
                                value={formValue.inputTag}
                                onChange={handleChange}
                                placeholder="Select Tags..."
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {loading ? (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      ) : null}
                                      <img
                                        className="ms-2"
                                        src={refresh}
                                        style={{
                                          width: "20px",
                                          cursor: "pointer",
                                        }}
                                        width="10px"
                                        alt="refresh"
                                        onClick={getSearchTags}
                                      />
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                                ref={autocompleteRef}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between w-100 align-items-center gap-2 flex-wrap mt-3">
                      <button
                        className="rounded-pill border-0  p-2 px-3  px-sm-5 pe-5 detailsbtn position-relative"
                        onClick={checkEdit}
                        disabled={saving}
                        id="submitbtn"
                      >
                        {!saving ? t("save") : ""}
                        <React.Fragment>
                          {saving ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                        </React.Fragment>

                        <span
                          style={{
                            position: "absolute",
                            right: "20px",
                          }}
                        >
                          {">"}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                {similarImages &&
                  similarImages.length > 1 &&
                  checked === false && (
                    <div className="mt-4 position-relative">
                      <div
                        className="border-top mt-4"
                        style={{ borderTop: "1px solid #E1E1E1" }}
                      >
                        <h6
                          className="mt-4"
                          style={{ color: "#4B4B4B", fontWeight: "500" }}
                        >
                          {t("similarImages")}
                        </h6>
                      </div>
                      {/* <div className="mt-4 border-extrabluedark rounded-20 position-relative slider-padding"> */}
                      {/* <div className="bottom-border mb-5"></div> */}
                      <Swiper
                        modules={[Navigation]}
                        spaceBetween={20}
                        slidesPerView={slidesPerView}
                        navigation
                        // onSwiper={(swiper) => {
                        //   if (swiper) {
                        //     const disabled =
                        //       swiper.slides.length <= slidesPerView;
                        //     setIsDisabled(disabled);
                        //   }
                        // }}
                      >
                        {similarImages?.map((template, index) => (
                          <SwiperSlide
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEditDataSet(template)}
                          >
                            <div
                              className={`p-1 rounded-8 d-flex align-items-center justify-content-center image-container ${
                                formValue.image_id === template?.image_id
                                  ? "active_image"
                                  : ""
                              }`}
                            >
                              <img
                                src={template?.image_url}
                                className="editImg"
                                alt="search-img"
                                loading="lazy"
                              />
                            </div>
                            <div className="d-flex justify-content-center align-items-center gap-1">
                              <span className="fs-18 font-Oswald text-light-gray mt-1">
                                {index + 1}
                              </span>
                              <span className="fs-14 mt-1 text-extrabluedark">
                                {savedImages.includes(template.image_id)
                                  ? "Saved"
                                  : editedImages?.includes(template.image_id)
                                    ? "Edited"
                                    : ""}
                              </span>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <React.Fragment>
        <Dialog
          open={openAlert}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className="text-danger">
            Alert
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              There are unsaved changes.Do you want to leave the page?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRejection}>No</Button>
            <Button
              onClick={handleConfirmation}
              autoFocus
              className="purple-gradiant text-white"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>

      <React.Fragment>
        <Dialog
          open={openEditAlert}
          onClose={() => setOpenEditAlert(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className="text-danger">
            Alert
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("description_edited")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenEditAlert(false)}>No</Button>
            <Button
              onClick={handleEditConfirmation}
              autoFocus
              className="purple-gradiant text-white"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div>
  );
};

export default NewEdit;
