import arrowLeft from "../../../assets/newicons/arrow-left-dark.svg";
import trueIcon from "../../../assets/newicons/true-icon.svg";
import menu from "../../../assets/newicons/menu.svg";
import closeIcon from "../../../assets/newicons/close.svg";
import aiIcon from "../../../assets/newicons/ai-white-icon.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import "./visionStudio.scss";
import newImage from "../../../assets/newimages/no_image.jpg";
import { useKonvaContext } from "../../../context/useKonvaContext";
import { useNavigate } from "react-router-dom";
import comments from "../../../assets/newicons/comments.svg";
import ArrowBack from "../../../assets/newicons/arrow_back.svg";
import DualMode from "../../../assets/compare.svg";
import Hamburger from "../../../assets/hamburger.svg";
import { Tooltip } from "@mui/material";
import styles from "./konva-header.module.css";
import JSZip from "jszip";
import Konva from "konva";

const KonvaHeader = ({
  projectName,
  setSplitScreenSwitchedOn,
  splitScreenSwitchedOn,
  channelName,
  toggleCommentSection,
  projectStatus,
}) => {
  const { t } = useTranslation();
  const zip = new JSZip();
  const canvas = useSelector((state) => state.konva.canvas);
  const [modal, setModal] = useState(false);
  const [exportQuality, setExportQuality] = useState(100);
  const [commentOpen, setCommentOpen] = useState(false);
  // State for export format
  const [exportFormat, setExportFormat] = useState("PNG");
  const navigate = useNavigate();
  // Assume these come from your Konva context
  const {
    sheetCount,
    setSplitScreenGlobalSwitchedOn,
    groupRefs,
    sheetListLoading,
    stageRef,
  } = useKonvaContext();
  const exportPopupRef = useRef(null);
  const exportPopupButton = useRef(null);

  // Add new state variables for export dimensions
  const [exportWidth, setExportWidth] = useState(500);
  const [exportHeight, setExportHeight] = useState(500);
  const [maintainAspectRatio, setMaintainAspectRatio] = useState(true);

  const hamburgerOptionsRef = useRef(null);
  const comfirmationPopupOptionsRef = useRef(null);
  const [hamburgerMenu, setHamburgerMenu] = useState(false);
  const [selectedSheets, setSelectedSheets] = useState([]);
  const [hamburgerMenuConfirmationPopup, setHamburgerMenuConfirmationPopup] =
    useState(false);

  // New state to hold exported sheet images and user-selected sheet ids
  const [exportedSheets, setExportedSheets] = useState([]);
  const [selectedSheetIds, setSelectedSheetIds] = useState([]);

  const layers = useSelector((state) => state.konva.canvas.layers);

  useEffect(() => {
    const hamburgerOptionClose = (event) => {
      if (
        hamburgerOptionsRef.current &&
        !hamburgerOptionsRef.current.contains(event.target) &&
        comfirmationPopupOptionsRef.current &&
        !comfirmationPopupOptionsRef.current.contains(event.target)
      ) {
        setHamburgerMenu(false);
      }
    };

    document.addEventListener("mousedown", hamburgerOptionClose);
    return () => {
      document.removeEventListener("mousedown", hamburgerOptionClose);
    };
  }, []);

  useEffect(() => {
    setSplitScreenGlobalSwitchedOn(splitScreenSwitchedOn);
  }, [splitScreenSwitchedOn]);

  const navigateToReference = () => {
    navigate(-1);
  };

  useEffect(() => {
    const closePopup = (e) => {
      if (
        modal &&
        exportPopupRef.current &&
        !exportPopupRef.current.contains(e.target) &&
        exportPopupButton.current &&
        !exportPopupButton.current.contains(e.target)
      ) {
        setModal(false);
      }
    };

    document.body.addEventListener("click", closePopup);
    return () => {
      document.body.removeEventListener("click", closePopup);
    };
  }, [modal]);

  const renderGroupToImage = (group) => {
    const sheet = stageRef.current.findOne(`#sheet-${group.id}`);
    if (sheet) {
      // Get the original sheet page node
      const sheetRect = stageRef.current.findOne(`#sheet-page-${group.id}`);

      // Clone the stage
      const clonedStage = stageRef.current.clone();
      // Reset the clone to 100% zoom and default position
      clonedStage.scale({ x: 1, y: 1 });
      clonedStage.position({ x: 0, y: 0 });
      clonedStage.batchDraw();

      // In the cloned stage, find the corresponding sheet page
      const clonedSheetRect = clonedStage.findOne(`#sheet-page-${group.id}`);

      // Compute a tight bounding box (without extra padding, stroke, or shadow)
      const rect = clonedSheetRect.getClientRect({
        padding: 0,
        skipStroke: true,
        skipShadow: true,
      });

      // Export the cloned sheet page to a data URL using the computed dimensions
      const dataURL = clonedSheetRect.toDataURL({
        x: rect.x,
        y: rect.y,
        width: rect.width,
        height: rect.height,
        pixelRatio: 2, // Increase for higher resolution if needed
        quality: exportQuality / 100,
        mimeType: exportFormat === "PNG" ? "image/png" : "image/jpeg",
      });

      // Clean up the cloned stage to free memory
      clonedStage.destroy();

      // Store the exported image URL
      setExportedSheets((prev) => [
        ...prev,
        { id: group.id, dataURL: dataURL, name: group.name },
      ]);
    }
  };

  const createImagesOfAllGroups = () => {
    setExportedSheets([]); // Clear the existing images
    const groups = layers[0].groups; // Assuming layers[0] contains the groups
    groups.forEach((group) => {
      renderGroupToImage(group); // Render each group to an image
    });
  };

  /**
   * Prepare all sheets by converting each sheet group (identified by id="sheet-{sheet.id}")
   * to a PNG or JPG image using Konva's toDataURL method.
   */
  const prepareExportSheets = () => {
    if (groupRefs.current) {
      const sheets = canvas.layers[0].groups;
      const exported = sheets
        .map((sheet) => {
          const groupNode = groupRefs.current[sheet.id];
          if (groupNode) {
            const dataURL = groupNode.toDataURL({
              mimeType: exportFormat === "PNG" ? "image/png" : "image/jpeg",
              quality: exportQuality / 100,
              width: parseInt(exportWidth),
              height: parseInt(exportHeight),
              pixelRatio: 2, // Higher ratio for better resolution
            });
            return { id: sheet.id, dataURL, name: sheet.name };
          }
          return null;
        })
        .filter(Boolean);
      setExportedSheets(exported);
      createImagesOfAllGroups();
      // setSelectedSheetIds(exported.map(s => s.id));
    }
  };

  // Open the export modal and prepare exported images.
  const handleExport = async () => {
    await prepareExportSheets();
    setModal(true);
  };

  // Utility to trigger a download for a given data URL.
  const downloadURI = (uri, name) => {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportImage = async (e) => {
    e.preventDefault();

    if (selectedSheetIds.length === 0) return;

    const resizeImage = (dataURL) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = parseInt(exportWidth);
          canvas.height = parseInt(exportHeight);
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, parseInt(exportWidth), parseInt(exportHeight));
          resolve(canvas.toDataURL(exportFormat === "PNG" ? "image/png" : "image/jpeg", exportQuality / 100));
        };
        img.src = dataURL;
      });
    };

    if (selectedSheetIds.length === 1) {
      const singleSheet = exportedSheets.find(
        (s) => s.id === selectedSheetIds[0]
      );
      if (singleSheet) {
        const resizedDataURL = await resizeImage(singleSheet.dataURL);
        downloadURI(
          resizedDataURL,
          `${singleSheet.name}.${exportFormat.toLowerCase()}`
        );
      }
    } else {
      const imagePromises = selectedSheetIds.map(async (id) => {
        const sheetData = exportedSheets.find((s) => s.id === id);
        if (sheetData) {
          const resizedDataURL = await resizeImage(sheetData.dataURL);
          const response = await fetch(resizedDataURL);
          const blob = await response.blob();
          zip.file(`${sheetData.name}.${exportFormat.toLowerCase()}`, blob);
        }
      });

      await Promise.all(imagePromises);

      zip.generateAsync({ type: "blob" }).then((content) => {
        const zipUrl = URL.createObjectURL(content);
        downloadURI(zipUrl, "selected_images.zip");
        URL.revokeObjectURL(zipUrl);
      });
    }
    setModal(false);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const dualModeConfirmationPopup = () => {
    const value = localStorage.getItem("dual_mode_remember");
    if (value === "true") {
      setSplitScreenSwitchedOn(false);
    } else {
      setHamburgerMenuConfirmationPopup(true);
    }
  };

  const closeConfirmationPopup = (e) => {
    setHamburgerMenuConfirmationPopup(false);
    setSplitScreenSwitchedOn(false);
  };

  useEffect(() => {
    if (modal) {
      prepareExportSheets();
    }
  }, [exportQuality, exportFormat, exportWidth, exportHeight, groupRefs]);

  const handleWidthChange = (e) => {
    const newWidth = e.target.value;
    setExportWidth(newWidth);
    if (maintainAspectRatio) {
      // Maintain aspect ratio (1:1 by default)
      setExportHeight(newWidth);
    }
  };

  const handleHeightChange = (e) => {
    const newHeight = e.target.value;
    setExportHeight(newHeight);
    if (maintainAspectRatio) {
      // Maintain aspect ratio (1:1 by default)
      setExportWidth(newHeight);
    }
  };

  return (
    <>
      <div
        style={{ zIndex: "11", position: "relative" }}
        className="d-flex justify-content-between flex-wrap align-items-center gap-md-3 gap-2 py-md-2 px-md-3 p-sm-3 p-xs-2 p-2 py-2 bg-white border-bottom font-noto-sans"
        ref={exportPopupButton}
      >
        <div
          className="d-flex  align-items-center gap-sm-3 gap-2"
          style={{ flex: 1, maxWidth: "33%" }}
        >
          {splitScreenSwitchedOn ? (
            <div
              className="cursor-pointer position-relative"
              onClick={() => setHamburgerMenu(true)}
            >
              <img
                src={Hamburger}
                alt="ArrowBack"
                width={25}
                style={{ cursor: "pointer" }}
              />
              {hamburgerMenu && (
                <div
                  className="position-absolute"
                  ref={hamburgerOptionsRef}
                  style={{
                    left: 0,
                    zIndex: 10,
                    top: "100%",
                    width: "fit-content",
                    height: "fit-content",
                    padding: "10px",
                    background: "white",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    borderRadius: "0 0 10px 10px",
                  }}
                >
                  <div
                    className="d-flex gap-2 p-2 vs-hamburger-item"
                    onClick={dualModeConfirmationPopup}
                  >
                    <img
                      src={DualMode}
                      alt="dual-mode"
                      className="cursor-pointer"
                      width={25}
                    />
                    <span style={{ whiteSpace: "nowrap", cursor: "pointer" }}>
                      {t("DUAL_MODE_CLOSE")}
                    </span>
                  </div>
                  <div
                    className="d-flex gap-2 p-2 vs-hamburger-item"
                    onClick={handleGoBack}
                  >
                    <img src={ArrowBack} alt="ArrowBack" width={25} />
                    <span style={{ whiteSpace: "nowrap", cursor: "pointer" }}>
                      {t("BACK_TO_PROJECT")}
                    </span>
                  </div>
                </div>
              )}
              {hamburgerMenuConfirmationPopup && (
                <div
                  style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    zIndex: 10,
                    background: "rgba(0,0,0,0.5)",
                  }}
                  ref={comfirmationPopupOptionsRef}
                >
                  <div className="modal-dialog d-flex align-items-center min-vh-100">
                    <div className="modal-content p-3">
                      <div className="modal-body">
                        <p>
                          {t("DUAL_MODE_CLOSE_POPUP_TITLE_1")}{" "}
                          <img
                            src={DualMode}
                            alt="dual-mode"
                            className="cursor-pointer mx-2"
                            width={16}
                          />{" "}
                          {t("DUAL_MODE_CLOSE_POPUP_TITLE_2")}
                        </p>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="dontShowAgain"
                            defaultChecked={
                              localStorage.getItem("dual_mode_remember") ===
                              "true"
                            }
                            onChange={(e) =>
                              e.target.checked
                                ? localStorage.setItem(
                                    "dual_mode_remember",
                                    "true"
                                  )
                                : localStorage.removeItem("dual_mode_remember")
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="dontShowAgain"
                          >
                            {t("DONT_SHOW_AGAIN")}
                          </label>
                        </div>
                      </div>
                      <div className="modal-footer border-0 d-flex justify-content-end">
                        <button
                          className="btn btn-dark position-relative px-4 py-1 rounded-pill bg-purple"
                          onClick={closeConfirmationPopup}
                        >
                          {t("DUAL_MODE_POPUP_GOT_IT")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="cursor-pointer" onClick={handleGoBack}>
              <img
                src={ArrowBack}
                alt="ArrowBack"
                width={25}
                style={{ cursor: "pointer" }}
              />
            </div>
          )}

          <div className="header_Bigline"></div>
          <div
            className="d-flex align-items-center gap-2"
            style={{ overflow: "hidden" }}
          >
            <Tooltip title={<div>{channelName}</div>}>
              <span className="fs-14 fw-600 truncate">{channelName}</span>
            </Tooltip>
            <span className="fs-14 fw-600">/</span>
            <Tooltip title={<div>{projectName}</div>}>
              <span className="fs-14 fw-600 truncate">{projectName}</span>
            </Tooltip>
          </div>
        </div>

        <div style={{ position: "relative", textAlign: "center", flex: 1 }}>
          <p className="fs-14 fw-500 mb-0 text-black">
            {t("totalSheet")}: <span className="ms-1">{sheetCount}</span>
          </p>
        </div>
        <div
          className="d-flex align-items-center gap-2"
          style={{ flex: 1, justifyContent: "flex-end" }}
        >
          {!splitScreenSwitchedOn ? (
            <img
              src={DualMode}
              alt="dual-mode"
              className="cursor-pointer"
              style={{
                width: "16px",
                height: "16px",
                cursor: "pointer",
                pointerEvents: projectStatus == 3 ? "none" : "auto",
              }}
              onClick={() => {
                setHamburgerMenu(false);
                setSplitScreenSwitchedOn(true);
              }}
            />
          ) : null}
          <img
            src={comments}
            alt="comments"
            className="cursor-pointer"
            style={{ cursor: "pointer" }}
            onClick={toggleCommentSection}
          />
          {!sheetListLoading && (
            <button
              className="btn-black py-md-2 py-1 px-md-4 px-3 fw-600"
              onClick={handleExport}
            >
              {t("Export")}
            </button>
          )}
        </div>
      </div>

      {modal && (
        <div className="export-modal" ref={exportPopupRef}>
          <div className="export-modal-header">
            <h4>
              {t("Export")} <span>({selectedSheetIds.length})</span>
            </h4>
            <img
              src={closeIcon}
              alt="closeIcon"
              onClick={() => setModal(false)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="export-modal-body">
            <p
              className="m-0 text-end select cursor-pointer"
              onClick={() => {
                if (selectedSheetIds.length === exportedSheets.length) {
                  setSelectedSheetIds([]); // Deselect all
                } else {
                  setSelectedSheetIds(exportedSheets.map((s) => s.id)); // Select all
                }
              }}
            >
              {selectedSheetIds.length === exportedSheets.length
                ? t("DeselectAll")
                : t("SelectAll")}
            </p>
            <div className="export-image-container">
              {exportedSheets.map((sheet) => (
                <label
                  key={sheet.id}
                  id={`select_image_${sheet.id}`}
                  className={`position-relative ms-0 image-container image-container-ref mb-0 cursor-pointer ${
                    selectedSheetIds.includes(sheet.id) ? "selected-border" : ""
                  }`}
                >
                  <img
                    loading="lazy"
                    src={sheet.dataURL}
                    height={500}
                    width={500}
                    className={`export-images ${
                      selectedSheetIds.includes(sheet.id)
                        ? styles.exportImageUnselectedItem
                        : styles.exportImageItem
                    }`}
                    alt={`Sheet ${sheet.id}`}
                  />
                  <input
                    type="checkbox"
                    className={`image-checkbox display-block ${styles.exportImageItemCheckbox}`}
                    checked={selectedSheetIds.includes(sheet.id)}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setSelectedSheetIds((prev) => {
                        if (checked) {
                          return [...prev, sheet.id];
                        } else {
                          return prev.filter((id) => id !== sheet.id);
                        }
                      });
                    }}
                  />
                </label>
              ))}
            </div>

            <div className="image-select">
              <label>{t("ExportAs")}</label>
              <select
                value={exportFormat}
                onChange={(e) => setExportFormat(e.target.value)}
              >
                <option value="PNG">{t("PNG")}</option>
                <option value="JPG">{t("JPG")}</option>
              </select>
            </div>
            <div className="image-range">
              <label>{t("Quality")}</label>
              <div className="d-flex align-items-center gap-2">
                <input
                  type="range"
                  min="1"
                  max="100"
                  value={exportQuality}
                  onChange={(e) => setExportQuality(e.target.value)}
                />
                <button>{exportQuality}%</button>
              </div>
            </div>
            
            {/* Add dimensions input fields */}
            <div className="image-dimensions mt-3">
              <label>{t("Dimensions")}</label>
              <div className="d-flex align-items-center gap-3 mt-2">
                <div className="d-flex align-items-center gap-2">
                  <label>{t("Width")}:</label>
                  <input
                    type="number"
                    value={exportWidth}
                    min="1"
                    style={{ width: "80px" }}
                    onChange={handleWidthChange}
                  />
                  <span>px</span>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <label>{t("Height")}:</label>
                  <input
                    type="number"
                    value={exportHeight}
                    min="1"
                    style={{ width: "80px" }}
                    onChange={handleHeightChange}
                  />
                  <span>px</span>
                </div>
              </div>
              <div className="form-check mt-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="keepAspectRatio"
                  checked={maintainAspectRatio}
                  onChange={(e) => setMaintainAspectRatio(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="keepAspectRatio">
                  {t("MaintainAspectRatio")}
                </label>
              </div>
            </div>
            
            <button
              className={` ${styles.exportBtn} ${
                selectedSheetIds?.length
                  ? styles.activeExport
                  : styles.disabledExport
              }`}
              onClick={exportImage}
              disabled={!selectedSheetIds?.length}
            >
              {t("Export")}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default KonvaHeader;
