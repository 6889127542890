import React, { useState, useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./assets/i18n/i18n";
import { AppRouter } from "./routes/router";
import { AuthProvider } from "./context/useAuthContext";
import { toast, ToastContainer } from "react-toastify";

import { FlagProvider } from "@unleash/proxy-client-react";

import "./assets/scss/home.scss";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "react-toastify/dist/ReactToastify.css";
import { WebSocketProvider } from "./context/useSocketContext";
import { UploadProvider } from "./context/useUploadContext";
import { EditProvider } from "./context/useEditContext";
import { TranslationProvider } from "./context/useTranslationContext";
import { FilterProvider } from "./context/useFilterContext";
import { SearchProvider } from "./context/useSearchContext";
import { ImageProvider } from "./views/Manager/component/ImageContext";
import { ReferenceProvider } from "./context/useReferenceContext";
import { KonvaProvider } from "./context/useKonvaContext";
import { Provider } from "react-redux";
import store from "./store";
import InternetConnection from "./components/InternetConnection";

const OfflineNotification = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [lastPage, setLastPage] = useState(() => window.location.href); // Store the last visited page
  const [hasRedirected, setHasRedirected] = useState(false); // Track if offline redirection has occurred

  useEffect(() => {
    const checkInternetStatus = () => {
      const online = navigator.onLine;
      if (online !== isOnline) {
        setIsOnline(online);
      }
    };

    const intervalId = setInterval(checkInternetStatus, 3000); // Check internet every 3 seconds

    return () => clearInterval(intervalId);
  }, [isOnline]);

  useEffect(() => {
    if (!isOnline && !hasRedirected) {
      // When offline, redirect to the current page to show "No Internet"
      setHasRedirected(true);
      setLastPage(window.location.href); // Save the current page URL
      window.location.reload(); // Reload the current page to show the system's offline page
    } else if (isOnline && hasRedirected) {
      // When back online, refresh the last page
      setHasRedirected(false); // Reset the redirected state
      window.location.href = lastPage; // Reload the last visited page
    }
  }, [isOnline, hasRedirected, lastPage]);

  return null; // This component doesn't render anything
};

const config = {
  url: process.env.REACT_APP_UNLEASH_URL,
  clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY,
  refreshInterval: 15, // How often (in seconds) the client should poll the proxy for updates
  appName: process.env.REACT_APP_UNLEASH_APP_NAME, // The name of your application. It's only used for identifying your application
};

function App() {
  return (
    <>
      <InternetConnection />
      <OfflineNotification />
      <FlagProvider config={config}>
        <WebSocketProvider>
          <UploadProvider>
            <AuthProvider>
              <EditProvider>
                <FilterProvider>
                  <TranslationProvider>
                    <SearchProvider>
                      <I18nextProvider i18n={i18n}>
                        <ImageProvider>
                          <ReferenceProvider>
                            <KonvaProvider>
                              <Provider store={store}>
                                <AppRouter />
                              </Provider>
                            </KonvaProvider>
                          </ReferenceProvider>
                        </ImageProvider>
                      </I18nextProvider>
                    </SearchProvider>
                  </TranslationProvider>
                </FilterProvider>
              </EditProvider>
            </AuthProvider>
          </UploadProvider>
        </WebSocketProvider>
      </FlagProvider>
      <ToastContainer />
    </>
  );
}

export default App;

// import React, { useState, useEffect } from "react";
// import { I18nextProvider } from "react-i18next";
// import i18n from "./assets/i18n/i18n";
// import { AppRouter } from "./routes/router";
// import { AuthProvider } from "./context/useAuthContext";
// import { toast, ToastContainer } from "react-toastify";

// import "./assets/scss/home.scss";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/primereact.min.css";
// import "react-toastify/dist/ReactToastify.css";
// import { WebSocketProvider } from "./context/useSocketContext";
// import { UploadProvider } from "./context/useUploadContext";
// import { EditProvider } from "./context/useEditContext";
// import { TranslationProvider } from "./context/useTranslationContext";
// import { FilterProvider } from "./context/useFilterContext";
// import { SearchProvider } from "./context/useSearchContext";
// import { SettingProvider } from "./context/useSettingContext";
// import { ImageProvider } from "./views/Manager/component/ImageContext";
// import { ReferenceProvider } from "./context/useReferenceContext";
// import { KonvaProvider } from "./context/useKonvaContext";

// const OfflineNotification = () => {
//   const [isOnline, setIsOnline] = useState(navigator.onLine);

//   useEffect(() => {
//     const updateStatus = () => {
//       const online = navigator.onLine;
//       setIsOnline(online);

//       if (!online) {
//         toast.error("You are offline. Please check your internet connection and try again", {
//           toastId: "offline-toast",
//           autoClose: false,
//         });
//       } else {
//         toast.dismiss("offline-toast");
//       }
//     };

//     window.addEventListener("online", updateStatus);
//     window.addEventListener("offline", updateStatus);

//     updateStatus();

//     return () => {
//       window.removeEventListener("online", updateStatus);
//       window.removeEventListener("offline", updateStatus);
//     };
//   }, []);

//   return null;
// };

// function App() {
//   return (
//     <>
//       <OfflineNotification />
//       <WebSocketProvider>
//         <UploadProvider>
//           <AuthProvider>
//             <EditProvider>
//               <FilterProvider>
//                 {/* <SettingProvider> */}
//                 <TranslationProvider>
//                   <SearchProvider>
//                     <I18nextProvider i18n={i18n}>
//                       <ImageProvider>
//                         <ReferenceProvider>
//                           <KonvaProvider>
//                             <AppRouter />
//                           </KonvaProvider>
//                         </ReferenceProvider>
//                       </ImageProvider>
//                     </I18nextProvider>
//                   </SearchProvider>
//                 </TranslationProvider>
//                 {/* </SettingProvider> */}
//               </FilterProvider>
//             </EditProvider>
//           </AuthProvider>
//         </UploadProvider>
//       </WebSocketProvider>
//       <ToastContainer />
//     </>
//   );
// }

// export default App;
