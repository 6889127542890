import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
  useTransition,
} from "react";
import {
  Excalidraw,
  Footer,
  MIME_TYPES,
  restoreElements,
} from "@excalidraw/excalidraw";
import "@excalidraw/excalidraw/index.css";
import { imageGet, newDelete, newPost } from "../../../API/axios";
import initiData from "../initiData";
import "./AnnotatingImage.scss";
import gridView from "../../../assets/newicons/grid_view.svg";
import addIcon from "../../../assets/newicons/add.svg";
// import './index.css'
import viewArray from "../../../assets/newicons/view_array.svg";
import minimize from "../../../assets/newicons/minimize.svg";
import maximize from "../../../assets/newicons/maximize.svg";

import ImageContext from "./ImageContext";
import close from "../../../assets/newicons/close.svg";
import { showConfirmationDialog } from "./AlertService";
import { useReferenceContext } from "../../../context/useReferenceContext";
import { Sidebar, useDevice } from "@excalidraw/excalidraw";
import CommentAndTask from "./CommentAndTask";
import CreateProjectFormLoader from "../skeletonLoaders/CreateProjectFormLoader";
import { useTranslation } from "react-i18next";

const AnnotatingImage = ({
  excalidrawData,
  src,
  setSelectedImage,
  isGridView,
  setIsGridView,
  referenceList,
  setReferenceList,
  referenceComments,
  setReferenceComments,
  isInternal,
  miroLink,
  selectedImages,
  createdProjectId,
  excaliDrawChanges,
}) => {
  const [excalidrawAPI, setExcalidrawAPI] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageHeight, setImageHeight] = useState();
  const [image, setImage] = useState();
  const [loadedImages, setLoadedImages] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [localReferenceList, setLocalReferenceList] = useState();
  const { resetPopup, setResetPopup, images } = useContext(ImageContext);
  const {
    referencesList,
    setReferencesList,
    excalidrawLoading,
    setExcalidrawLoading,
    board_id,
  } = useReferenceContext();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [elements, setElements] = useState([]);
  const [prevElements, setPrevElements] = useState([]);
  const [state, setState] = useState(null);
  const [prevState, setPrevState] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const { t, i18n } = useTranslation();

  const [docked, setDocked] = useState(false);
  const device = useDevice();

  const modalOpen = () => {
    setResetPopup("");
    setResetPopup("reset");
  };

  const loaderStyle = {
    border: "8px solid #f3f3f3",
    borderTop: "8px solid #3498db",
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    animation: "spin 1s linear infinite",
    margin: "auto",
  };

  useEffect(() => {
    if (!excalidrawData) {
      initialStatePromiseRef.current.promise.resolve(initiData);
      // setExcalidrawLoading(false);
      return;
    }

    const updateData = async () => {
      try {
        const files = excalidrawData.files || {};
        await Promise.all(
          Object.entries(files).map(async ([key, file]) => {
            if (!file.dataURL.startsWith("data:")) {
              let response;
              let blob;
              try {
                response = await fetch(file.dataURL);
                blob = await response.blob();
              } catch (error) {
                blob = await imageGet("download_image", {
                  image_url: file.dataURL,
                });
              }

              const base64String = await blobToBase64(blob);

              excalidrawAPI.addFiles([
                { ...file, dataURL: base64String, imageUrl: file.dataURL },
              ]);
              return [key, { ...file, dataURL: base64String }];
            }
            return [key, file];
          })
        );

        const updatedData = {
          ...excalidrawData,
          appState: {
            ...(excalidrawData.appState || {}),
          },
        };

        initialStatePromiseRef.current.promise.resolve(updatedData);
        excalidrawAPI.updateScene(updatedData);
        setExcalidrawLoading(false);
        setTimeout(() => {
          excalidrawAPI.scrollToContent();
        }, 100);
      } catch (error) {
        setExcalidrawLoading(false);
        console.error("Error updating Excalidraw data:", error);
      }
    };
    if (excalidrawData && excalidrawAPI) {
      updateData();
    }
  }, [excalidrawData, excalidrawAPI]);

  useEffect(() => {
    setExcalidrawLoading(true);
  }, []);

  useEffect(() => {
    document.excalidrawAPI = excalidrawAPI;

    // if (src && src.length > 0) {
    //     setLoading(true);
    //     Promise.all(src.map(src => convertBase64(src))).then(() => {
    //         setLoading(false);
    //     });
    // }
  }, [excalidrawAPI, src]);

  //commented By Harsh
  // useEffect(() => {
  //     const combinedImages = [...(selectedImages || []), ...(images || [])];
  //     if (combinedImages.length > 0) {
  //         const data = addSelectedImagesToReferenceList(referenceList, combinedImages);
  //         const uniqueData = data.filter((item, index, self) =>
  //             index === self.findIndex((t) => t.file_url === item.file_url)
  //         );
  //
  //         setReferenceList(uniqueData);
  //     }
  // }, [selectedImages, images]);

  useEffect(() => {
    const convertImages = async () => {
      for (const image of images) {
        if (image.objectURL) {
          let fileId = "";
          let file_url = "";
          let data;
          if (image?.data) {
            fileId = image.data.file_id; // Unique ID for each image
            file_url = image.data.file_url;
            data = image.data;
            delete image.data;
          } else {
            fileId = Date.now(); // Unique ID for each image
          }

          try {
            let response;
            if (!image.objectURL.includes("blob:")) {
              try {
                // Fetch the image from the server
                response = await imageGet("download_image", {
                  image_url: image.objectURL,
                });
                console.log("API Call Success:", response);
              } catch (error) {
                console.error("Error fetching remote image:", error);
                continue; // Skip to the next image if there's an error
              }
            } else {
              // Handle localhost or blob-based image
              response = new Blob([image], { type: image.type });
            }

            // Convert Blob to Base64
            const base64Image = await blobToBase64(response);

            // Process and load the image into Excalidraw if it was successfully converted
            if (base64Image) {
              setLoadedImages((prev) => [...prev, base64Image]);

              if (excalidrawAPI) {
                const currentElements = JSON.parse(
                  JSON.stringify(excalidrawAPI.getSceneElements())
                );
                const existingImages = currentElements.filter(
                  (el) => el.type === "image"
                );

                // Check if the image already exists
                const imageExists = existingImages.some(
                  (img) => img.dataURL === base64Image
                );
                if (!imageExists) {
                  const lastImage = existingImages.pop();
                  let newX = 200,
                    newY = 100;

                  if (lastImage) {
                    newX = lastImage.x + lastImage.width + 10;
                    newY = lastImage.y;
                  }

                  excalidrawAPI.addFiles([
                    {
                      id: fileId,
                      dataURL: base64Image,
                      mimeType: MIME_TYPES.jpg,
                      created: Date.now(),
                      lastRetrieved: Date.now(),
                    },
                  ]);

                  const img = new Image();
                  img.src = base64Image;
                  img.onload = () => {
                    const { width, height } = aspectRatio(
                      img.width,
                      img.height
                    );

                    // Create a new image element in Excalidraw
                    const newImageElement = {
                      fileId,
                      id: fileId,
                      image_id: fileId,
                      type: "image",
                      x: newX,
                      y: newY,
                      width: width,
                      height: height,
                      angle: 0,
                      strokeColor: "transparent",
                      backgroundColor: "transparent",
                      fillStyle: "hachure",
                      strokeWidth: 1,
                      strokeStyle: "solid",
                      roughness: 1,
                      opacity: 100,
                      groupIds: [],
                      // strokeSharpness: "round",
                      version: 143,
                      versionNonce: 2028982666,
                      isDeleted: false,
                      boundElements: null,
                      link: "",
                      status: "saved",
                      scale: [1, 1],
                    };

                    // Add the image element to Excalidraw
                    const updatedElements = [
                      ...currentElements,
                      newImageElement,
                    ];

                    initialStatePromiseRef.current.promise.resolve({
                      elements: updatedElements,
                      appState: excalidrawData.appState,
                    });
                    excalidrawAPI.updateScene({
                      elements: updatedElements,
                      appState: excalidrawData.appState,
                    });
                    // setTimeout(() => {
                    //     excalidrawAPI.scrollToContent();

                    // }, 100);
                    excalidrawAPI.scrollToContent();

                    // Update reference list with the new image
                    // setNewRederenceList(base64Image, image, fileId);
                    setReferenceList((prevRefList) => [data, ...prevRefList]);
                  };
                }
              }
            }
          } catch (error) {
            console.error("Error fetching or converting blob:", error);
          }
        }
        if (image?.file_url) {
          convertHostedImage(image);
        }
      }
    };

    convertImages();
  }, [images]);

  const convertHostedImage = async (data) => {
    try {
      let blob;
      try {
        const response = await fetch(data.file_url);
        blob = await response.blob();
      } catch (error) {
        blob = await imageGet("download_image", {
          image_url: data.file_url,
        });
      }

      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result.split(",")[1];
        const base64Image = `data:image/png;base64,${base64String}`;

        // Process and load the image into Excalidraw if it was successfully converted
        if (base64Image) {
          setLoadedImages((prev) => [...prev, base64Image]);

          if (excalidrawAPI) {
            const currentElements = JSON.parse(
              JSON.stringify(excalidrawAPI.getSceneElements())
            );
            const existingImages = currentElements.filter(
              (el) => el.type === "image"
            );

            // Check if the image already exists
            const imageExists = existingImages.some(
              (img) => img.dataURL === base64Image
            );
            if (!imageExists) {
              const lastImage = existingImages.pop();
              let newX = 200,
                newY = 100;

              if (lastImage) {
                newX = lastImage.x + lastImage.width + 10;
                newY = lastImage.y;
              }

              const img = new Image();
              img.src = base64Image;
              img.onload = () => {
                const { width, height } = aspectRatio(img.width, img.height);

                // Create a new image element in Excalidraw
                const newImageElement = {
                  fileId: data.file_id,
                  id: data.file_id,
                  image_id: data.file_id,
                  type: "image",
                  x: newX,
                  y: newY,
                  width: width,
                  height: height,
                  angle: 0,
                  strokeColor: "transparent",
                  backgroundColor: "transparent",
                  fillStyle: "hachure",
                  strokeWidth: 1,
                  strokeStyle: "solid",
                  roughness: 1,
                  opacity: 100,
                  groupIds: [],
                  // strokeSharpness: "round",
                  version: 143,
                  versionNonce: 2028982666,
                  isDeleted: false,
                  boundElements: null,
                  link: "",
                  status: "pending",
                  scale: [1, 1],
                };

                // Add the image element to Excalidraw
                const updatedElements = [...currentElements, newImageElement];

                excalidrawAPI.addFiles([
                  {
                    id: data.file_id,
                    dataURL: base64Image,
                    imageURL: data.file_url,
                    mimeType: MIME_TYPES.jpg,
                    created: Date.now(),
                    lastRetrieved: Date.now(),
                  },
                ]);

                excalidrawAPI.updateScene({ elements: updatedElements });
                // setTimeout(() => {
                //     excalidrawAPI.scrollToContent();

                // }, 100);
                excalidrawAPI.scrollToContent();

                // Update reference list with the new image
                // setNewRederenceList(base64Image, image, fileId);
                setReferenceList((prevRefList) => [data, ...prevRefList]);
              };
            }
          }
        }
      };
      reader.onerror = (error) => console.error("Error reading file:", error);
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error fetching or converting blob:", error);
    }
  };

  const setNewRederenceList = (base64Image, file, fileId) => {
    let urlParams = new URLSearchParams(window.location.search);
    const projectIdFromUrl = parseInt(urlParams.get("project_id"));
    let PrevRef = JSON.parse(JSON.stringify(referenceList || []));
    const newImageObj = {
      is_active: 0,
      project_id: projectIdFromUrl,
      // reference_id: null,
      board_id: board_id,
      fileId: fileId,
      file_url: base64Image,
      file: file,
    };
    let newReferenceList = [newImageObj, ...PrevRef];
    setReferenceList((prevRefList) => [newImageObj, ...prevRefList]);
  };

  const addSelectedImagesToReferenceList = (
    referenceList = [],
    selectedImages = []
  ) => {
    let updatedReferenceList = [...referenceList];

    selectedImages.forEach(async (image) => {
      if (image?.objectURL) {
        const response = await fetch(image.objectURL);
        const blob = await response.blob();

        const reader = new FileReader();

        return new Promise((resolve, reject) => {
          reader.onloadend = () => {
            const base64String = reader.result.split(",")[1];
            const base64Image = `data:image/png;base64,${base64String}`;
            const newImageObj = {
              comments: [],
              is_active: null,
              project_id: null,
              // reference_id: null,
              board_id: board_id,
              file_url: base64Image,
            };
            updatedReferenceList = [newImageObj, ...updatedReferenceList];

            resolve(base64Image);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      } else {
        const newImageObj = {
          comments: [],
          is_active: null,
          project_id: null,
          // reference_id: null,
          board_id: board_id,
          image_id: image?.image_id,
          file_url: image.image_url,
          flag: true,
        };
        updatedReferenceList = [newImageObj, ...updatedReferenceList];
        // setReferenceList(updatedReferenceList)
      }
    });

    const imagesWithFlagTrue = updatedReferenceList.filter((item) => item.flag);

    if (imagesWithFlagTrue.length > 0) {
      Promise.all(
        imagesWithFlagTrue.map((item) => convertBase64(item.file_url, item))
      )
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error converting images:", error);
          setLoading(false);
        });
    }

    return updatedReferenceList;
  };

  const resolvablePromise = () => {
    let resolve;
    let reject;
    const promise = new Promise((_resolve, _reject) => {
      resolve = _resolve;
      reject = _reject;
    });
    promise.resolve = resolve;
    promise.reject = reject;
    return promise;
  };

  const initialStatePromiseRef = useRef({ promise: resolvablePromise() });
  if (!initialStatePromiseRef?.current?.promise) {
    initialStatePromiseRef.current.promise = resolvablePromise();
  }

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
        const base64Image = reader.result;
        const img = new Image();
        img.src = base64Image;
        img.onload = () => {
          setImageHeight(img.height);
        };
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleImageSelect = async (imageSrc, index, refData) => {
    setSelectedImage(imageSrc);
    setImage(imageSrc);
    setSelectedImageIndex(index);
    setReferenceComments(refData);
    setIsModalOpen(true);
  };

  const handleModifyButtonClick = (src) => {
    const refData = referenceComments;
    const base64Data = src.startsWith("data:image/");
    if (!base64Data) {
      convertBase64(src, refData);
    } else {
      setLoadedImages((prev) => [...prev, src]);

      if (excalidrawAPI) {
        const currentElements = JSON.parse(
          JSON.stringify(excalidrawAPI.getSceneElements())
        );
        const images = currentElements.filter((el) => el.type === "image");

        // Check if the image already exists
        const imageExists = images.some((img) => img.dataURL === src);

        if (!imageExists) {
          const lastImage = images.pop();
          let newX, newY;

          if (lastImage) {
            newX = lastImage.x + 10 + lastImage.width;
            newY = lastImage.y;
          } else {
            newX = 200;
            newY = 100;
          }

          const newImage = {
            fileId: Date.now(),
            id: Date.now(),
            type: "image",

            x: newX,
            y: newY,
            width: 231.30325348751828,
            height: 231.64340533088227,
            angle: 0,
            strokeColor: "transparent",
            backgroundColor: "transparent",
            fillStyle: "hachure",
            strokeWidth: 1,
            strokeStyle: "solid",
            roughness: 1,
            opacity: 100,
            groupIds: [],
            // strokeSharpness: "round",
            seed: 707269846,
            version: 143,
            versionNonce: 2028982666,
            isDeleted: false,
            boundElements: null,
            updated: 1644914782403,
            link: null,
            status: "pending",
            scale: [1, 1],
            dataURL: src,
          };

          const updatedElements = [...currentElements, newImage];

          excalidrawAPI.addFiles([
            {
              id: Date.now(),
              dataURL: src,
              mimeType: MIME_TYPES.jpg,
              created: Date.now(),
              lastRetrieved: Date.now(),
            },
          ]);

          excalidrawAPI?.updateScene({ elements: updatedElements });
          excalidrawAPI.scrollToContent();

          // setNewRederenceList(src);
        }
      }
    }
    setIsModalOpen(false);
  };

  const aspectRatio = (width, height) => {
    const maxWidth = 300; // Maximum width for the image
    const maxHeight = 300; // Maximum height for the image

    // Calculate the aspect ratio
    const aspectRatio = width / height;

    // Adjust width and height to fit within the maximum dimensions
    if (width > maxWidth || height > maxHeight) {
      if (aspectRatio > 1) {
        width = maxWidth;
        height = maxWidth / aspectRatio;
      } else {
        height = maxHeight;
        width = maxHeight * aspectRatio;
      }
    }

    return { width, height };
  };

  const convertToBase64 = async (src, image) => {
    try {
      const response = await imageGet("download_image", { image_url: src });
      if (!response) return;
      const base64String = await blobToBase64(response);
      return base64String;
    } catch (error) {
      console.error("Error converting image:", error);
    }
  };

  const convertBase64 = async (src, image) => {
    try {
      const response = await imageGet("download_image", { image_url: src });
      if (!response) return;

      const base64String = await blobToBase64(response);
      const img = new Image();
      img.src = base64String;

      img.onload = () => {
        let { width, height } = aspectRatio(img.width, img.height);

        if (base64String) {
          setLoadedImages((prev) => [...prev, base64String]);

          if (excalidrawAPI) {
            const currentElements = excalidrawAPI.getSceneElements();
            const images = currentElements.filter((el) => el.type === "image");

            // Check if the image already exists
            const imageExists = images.some(
              (img) => img.dataURL === base64String
            );

            if (!imageExists) {
              const lastImage = images[images.length - 1];
              const newX = lastImage ? lastImage.x + lastImage.width + 10 : 200;
              const newY = lastImage ? lastImage.y : 100;

              const newImage = {
                fileId: image?.image_id || image?.file_id || image?.board_id,
                id: Date.now(),
                type: "image",
                x: newX,
                y: newY,
                ref_type: image?.image_id ? "image" : "reference",
                image_id: image?.image_id || image?.file_id || image?.board_id,
                dataURL: image?.file_url || base64String,
                width: width,
                height: height,
                angle: 0,
                strokeColor: "transparent",
                backgroundColor: "transparent",
                fillStyle: "hachure",
                strokeWidth: 1,
                strokeStyle: "solid",
                roughness: 1,
                opacity: 100,
                groupIds: [],
                // strokeSharpness: "round",
                seed: 707269846,
                version: 143,
                versionNonce: 2028982666,
                isDeleted: false,
                boundElements: null,
                updated: 1644914782403,
                link: null,
                status: "pending",
                scale: [1, 1],
              };

              excalidrawAPI.addFiles([
                {
                  id: image?.image_id || image?.file_id || image?.board_id,
                  dataURL: base64String,
                  mimeType: MIME_TYPES.jpg,
                  created: Date.now(),
                  imageURL: src,
                  lastRetrieved: Date.now(),
                },
              ]);

              excalidrawAPI.updateScene({
                elements: [...currentElements, newImage],
              });
              excalidrawAPI.scrollToContent();
            }
          }
        }
      };
    } catch (error) {
      console.error("Error converting image:", error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const removeRef = async (data) => {
    const confirmation = await showConfirmationDialog(
      `${t("projectDeleteMsg")}`,
      "",
      "warning",
      `${t("no")}`,
      `${t("yes")}`
    );
    try {
      if (confirmation) {
        setLoading(true);
        if (data.reference_id) {
          const deleteRef = await newDelete(
            `reference/delete?reference_id=${data.reference_id}&project_id=${createdProjectId}`,
            data
          ).then((res) => {
            const updatedReferenceList = referenceList.filter(
              (item) => item.file_url !== data.file_url
            );
            setReferenceList(updatedReferenceList);
          });
        } else {
          const updatedReferenceList = referenceList.filter(
            (item) => item.file_url !== data.file_url
          );
          setReferenceList(updatedReferenceList);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Helper function for deep comparison
  const deepEqual = (obj1, obj2) => {
    if (
      typeof obj1 === "object" &&
      typeof obj2 === "object" &&
      obj1 !== null &&
      obj2 !== null
    ) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) return false;

      for (let key of keys1) {
        if (!deepEqual(obj1[key], obj2[key])) return false;
      }
      return true;
    } else {
      return obj1 === obj2;
    }
  };

  // Compare coordinates and detect changes
  const detectCoordinateChanges = (newElements) => {
    let hasChanges = false;
    newElements.forEach((newElement) => {
      const oldElement = elements.find((el) => el.id === newElement.id);
      if (!oldElement) {
        return; // Skip further checks for new elements
      }

      // if (oldElement) {
      //     // Compare old and new coordinates
      //     if (oldElement.x !== newElement.x || oldElement.y !== newElement.y) {
      //       hasChanges = true;
      //     }
      //   }
    });

    if (hasChanges || newElements.length !== elements.length) {
      setElements([...newElements]); // Update only if there are changes
    }
  };

  const handleChangeExaclidraw = useCallback(
    (newElements, newState) => {
      if (newElements?.length > excalidrawData?.elements?.length) {
        excaliDrawChanges(newElements);
      } else {
        excaliDrawChanges(false);
      }

      // }/
      // setElements(elements); // Save elements to state
      // setState(state);       // Optionally save state if needed
      // excaliDrawChanges(isChanged)
      // setIsChanged(true);
      const elementsChanged = !deepEqual(prevElements, newElements);
      // const stateChanged = !deepEqual(prevState, newState);
      if (elementsChanged) {
        setIsChanged(true); // Mark as changed if either elements or state changed
        // console.log("Excalidraw content changed.");
        // excaliDrawChanges(newElements)
        // Update previous elements and state
        setPrevElements(newElements);
        // setPrevState(newState);
      } else {
        setIsChanged(false); // No changes detected
        // excaliDrawChanges(isChanged)
      }

      setElements(newElements);
      //   setState(newState);
    },
    [prevElements]
  );

  const toggleFullscreen = () => {
    const excalidrawContainer = document.getElementById("excalidraw-container");
    if (!isFullscreen) {
      if (excalidrawContainer.requestFullscreen) {
        excalidrawContainer.requestFullscreen();
      } else if (excalidrawContainer.webkitRequestFullscreen) {
        excalidrawContainer.webkitRequestFullscreen(); // Safari
      } else if (excalidrawContainer.msRequestFullscreen) {
        excalidrawContainer.msRequestFullscreen(); // IE11
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen(); // Safari
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen(); // IE11
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      // Check if we're not in fullscreen anymore
      if (
        !document.fullscreenElement &&
        !document.webkitFullscreenElement &&
        !document.mozFullScreenElement &&
        !document.msFullscreenElement
      ) {
        setIsFullscreen(false);
      }
    };

    // Add event listeners for different browsers
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    // Cleanup function to remove event listeners
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  }, []);

  // Add this function to handle drops
  const handleDrop = async (e) => {
    e.preventDefault();

    try {
      const dragData = JSON.parse(e.dataTransfer.getData("application/json"));

      if (dragData.type === "excalidraw-image") {
        let dataUrl;
        const fileId = `file_${new Date().getTime()}`;

        // Fetch the image via backend to avoid CORS issues
        if (!dragData.src.startsWith("data:")) {
          try {
            const blob = await imageGet("download_image", {
              image_url: dragData.src,
            });
            await new Promise((resolve) => {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              resolve(
                new Promise((resolve) => {
                  reader.onload = function () {
                    dataUrl = reader.result;

                    excalidrawAPI.addFiles([
                      {
                        id: fileId,
                        dataURL: dataUrl,
                        mimeType: "image/jpeg",
                        imageUrl: dragData.src,
                      },
                    ]);

                    resolve(dataUrl);
                  };
                })
              );
            });
          } catch (error) {
            console.error("Error converting image:", error);
            return;
          }
        } else {
          dataUrl = dragData.src;
        }

        // Add the image to Excalidraw
        if (excalidrawAPI) {
          await new Promise((resolve) => {
            const currentElements = excalidrawAPI.getSceneElements();
            const images = currentElements.filter((el) => el.type === "image");
            const lastImage = images[images.length - 1];
            const newX = lastImage ? lastImage.x + lastImage.width + 10 : 200;
            const newY = lastImage ? lastImage.y : 100;
            const image = new Image();
            image.src = dataUrl;
            image.onload = () => {
              const { width, height } = aspectRatio(image.width, image.height);
              excalidrawAPI.updateScene({
                elements: restoreElements([
                  ...excalidrawAPI.getSceneElementsIncludingDeleted(),
                  {
                    fileId: fileId,
                    type: "image",
                    status: "saved",
                    x: newX,
                    y: newY,
                    width: width,
                    height: height,
                    angle: 0,
                    strokeColor: "transparent",
                    backgroundColor: "transparent",
                    fillStyle: "hachure",
                    strokeWidth: 1,
                    strokeStyle: "solid",
                    roughness: 1,
                    opacity: 100,
                    groupIds: [],
                    // strokeSharpness: "round",
                    version: 143,
                    versionNonce: 2028982666,
                    isDeleted: false,
                    boundElements: null,
                    link: "",
                    scale: [1, 1],
                  },
                ]),
              });
            };
          });
        }
      }
    } catch (error) {
      console.error("Error handling drop:", error);
    }
  };

  // Helper function to convert Blob to Base64
  const onlyBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  };

  const handlePointerDown = (event, pointerEvent) => {
    if (!pointerEvent?.originInGrid) return;
    const { x, y } = pointerEvent.originInGrid;
    const elements = excalidrawAPI?.getSceneElements() || [];

    for (const element of elements) {
      if (
        element.type === "rectangle" ||
        element.type === "ellipse" ||
        element.type === "diamond"
      ) {
        if (isPointInsideShape(x, y, element)) {
          excalidrawAPI?.updateScene({
            elements: elements,
            appState: { selectedElementIds: { [element.id]: true } },
          });
          return; // Stop after selecting one element
        }
      }
    }
  };

  const isPointInsideShape = (x, y, element) => {
    if (element.type === "rectangle") {
      return (
        x >= element.x &&
        x <= element.x + element.width &&
        y >= element.y &&
        y <= element.y + element.height
      );
    } else if (element.type === "ellipse") {
      const centerX = element.x + element.width / 2;
      const centerY = element.y + element.height / 2;
      const rx = element.width / 2;
      const ry = element.height / 2;

      const dx = (x - centerX) / rx;
      const dy = (y - centerY) / ry;

      return dx * dx + dy * dy <= 1;
    } else if (element.type === "diamond") {
      const centerX = element.x + element.width / 2;
      const centerY = element.y + element.height / 2;
      const halfWidth = element.width / 2;
      const halfHeight = element.height / 2;

      return (
        Math.abs(x - centerX) / halfWidth +
          Math.abs(y - centerY) / halfHeight <=
        1
      );
    }
    return false;
  };

  return (
    <div className="d-flex anotation">
      {isModalOpen && (
        <div className="modal-overlay" onClick={handleModalClose}>
          <div className="modal-content1" onClick={(e) => e.stopPropagation()}>
            <span className="close-button" onClick={handleModalClose}>
              &times;
            </span>
            <img src={image} alt="Selected" className="modal-image" />
            <button
              className="modify-button"
              onClick={() => handleModifyButtonClick(image)}
            >
              Insert
            </button>
          </div>
        </div>
      )}

      {/* Excalidraw and Loader */}

      <div
        id="excalidraw-container"
        style={{
          height: "calc(100vh - 100px)",
          width: isMenuOpen ? "calc(100% - 250px)" : "100%",
          marginLeft: isMenuOpen ? "250px" : "0",
          transition: "width 0.3s, margin-left 0.3s",
          display: !isInternal || excalidrawLoading ? "none" : "block",
        }}
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDrop}
      >
        <Excalidraw
          onChange={handleChangeExaclidraw}
          // onChange={(newElements, appState) => {
          //     detectCoordinateChanges(newElements);
          //     // setElements([...newElements]);
          // }}
          excalidrawAPI={(api) => setExcalidrawAPI(api)}
          initialData={initialStatePromiseRef.current.promise}
          UIOptions={{
            canvasActions: {
              loadScene: false,
              saveAsImage: false,
              saveScene: false,
              saveAsScene: false,
              export: false,
              clearCanvas: false,
              saveToActiveFile: false,
            },
          }}
          onPointerDown={handlePointerDown}
          renderTopRightUI={() => {
            return (
              <>
                {device?.editor?.isMobile ? (
                  <button
                    style={{
                      border: "none",
                      width: "max-content",
                      fontWeight: "bold",
                    }}
                    className="exciladraw-fullscreen-btn"
                  >
                    <Sidebar.Trigger name="custom" tab="one">
                      <img
                        src={viewArray}
                        alt="viewArray"
                        width={20}
                        height={16}
                        className="align-baseline"
                        style={{ marginTop: "3px" }}
                      />
                    </Sidebar.Trigger>
                  </button>
                ) : (
                  <button
                    style={{
                      border: "none",
                      width: "max-content",
                      fontWeight: "bold",
                    }}
                    className="exciladraw-fullscreen-btn"
                    onClick={toggleFullscreen}
                  >
                    {isFullscreen ? (
                      <img src={minimize}></img>
                    ) : (
                      <img src={maximize}></img>
                    )}
                  </button>
                )}
              </>
            );
          }}
        ></Excalidraw>

        {/* {loading && <div style={loaderStyle} />} */}
      </div>
      <div style={{ display: excalidrawLoading ? "block" : "none" }}>
        <CreateProjectFormLoader
          height="calc(100vh - 100px)"
          width="60vw"
        ></CreateProjectFormLoader>
      </div>
      <div
        style={{
          height: "calc(100vh - 100px)",
          width: isMenuOpen ? "calc(100% - 250px)" : "100%",
          marginLeft: isMenuOpen ? "250px" : "0",
          transition: "width 0.3s, margin-left 0.3s",
          display: isInternal ? "none" : "block",
        }}
      >
        <iframe
          className="responsive-iframe"
          src={miroLink}
          title="Miro"
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="no"
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default AnnotatingImage;
