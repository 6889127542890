/* eslint-disable react-hooks/exhaustive-deps */
import debounce from "debounce";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { post } from "../../API/axios";
import { useAuthContext } from "../../context/useAuthContext";
import { useEditContext } from "../../context/useEditContext";
import { useFilterContext } from "../../context/useFilterContext";
import { useSearchContext } from "../../context/useSearchContext";
// icons
//components
// css
import { Checkbox } from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import "../../assets/i18n/en.json";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search_insights.svg";
import "../Search/search.scss";
import SearchResults from "./SearchResults";
import { showAlert } from "../Manager/component/AlertService";

const TagRow = ({ data, index, style }) => {
  const { tags, formValue, handleHashtagChange } = data;
  const tag = tags[index];
  const isSelected = formValue.tags.includes(tag);

  return (
    <li
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        padding: "8px 12px",
        cursor: "pointer",
        fontSize: "13px",
        backgroundColor: isSelected ? "rgba(49, 46, 255, 0.08)" : "transparent",
      }}
      onClick={() => {
        handleHashtagChange(
          isSelected
            ? formValue.tags.filter((t) => t !== tag)
            : [...formValue.tags, tag]
        );
      }}
    >
      {tag}
    </li>
  );
};

const Search = () =>
  // {searchView, setSearchView}
  {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { filterTags } = useFilterContext();
    const { selectedSite, userSettings } = useAuthContext();

    const { clearEditData, searchQuery, refreshSearch, setRefreshSearch } =
      useEditContext();
    const scrollRef = useRef(null);
    const [isSiteSearchOpen, setIsSiteSearchOpen] = useState(false);
    const [offSetId, setOffSetId] = useState(null);

    const {
      searchResults,
      setSearchResults,
      similarResults,
      setSimilarResults,
      results,
      setSimilarityGroup,
      setResults,
      numberOfResults,
      setNumberOfResults,
      formValue,
      setFormValue,
      showResults,
      setShowResults,
      prevSearch,
      setPrevSearch,
      selectedFilter,
      setSelectedFilter,
      checked,
      setChecked,
      scrollLoader,
      setScrollLoader,
      applyQueryParams,
      addToQueryParams,
    } = useSearchContext();

    const [isLoading, setIsLoading] = useState(false);
    const [filteredSites, setFilteredSites] = useState(
      userSettings?.channel_name
    );
    const [tags, setTags] = useState([]);

    const [resetData, setResetData] = useState({});
    const [isBackGroundShow, setIsBackGroundShow] = useState(false);
    const [globalGroupedImageIds, setGlobalGroupedImageIds] = useState(
      new Set()
    );
    const globalGroupedImageIdsRef = useRef(globalGroupedImageIds);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isCtrDropdown, setIsCtrDropdown] = useState(false);
    const [isIndustryDropdownOpen, setIsIndustryDropdownOpen] = useState(false);
    const [isTagsDropdownOpen, setIsTagsDropdownOpen] = useState(false);
    const [tagSearchInput, setTagSearchInput] = useState("");
    const [filteredTags, setFilteredTags] = useState([]);
    const [isTagsLoading, setIsTagsLoading] = useState(false);
    const [tagsChunk, setTagsChunk] = useState(30);

    const dropdownRef = useRef(null);
    const ctrDropdownRef = useRef(null);

    const en_tags = localStorage.getItem("en_tags");
    const ja_tags = localStorage.getItem("ja_tags");
    const currentLanguage = i18n.language;

    useEffect(() => {
      resetSearch();
      if (!showResults) {
        setSelectedFilter({
          industry: [],
          tags: [],
          personInImage: [],
          imageStyle: [],
          deliveryContent: [],
          imageType: [],
          deliveryType: [],
          imageType: [],
          editableImages: "",
          ctr: 0,
        });
      }

      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.size > 0 && filterTags) {
        setShowResults(true);
      }

      return () => {
        resetSearch();
      };
    }, []);

    useEffect(() => {
      if (!showResults) {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.size > 0 && filterTags) {
          // setTimeout(() => {
          applyQueryParams(queryParams, filterTags);
          // }, 1000);
        }
      }
    }, [filterTags]);

    useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const defaultFormValue = {
        input_text: "",
        page: 1,
        ctr: 0,
        tags: [],
        model_filters: [],
        is_edited: "",
        search_by: "both",
      };
      const isDefaultForm =
        JSON.stringify(formValue) === JSON.stringify(defaultFormValue);

      if (
        !showResults &&
        queryParams.size > 0 &&
        !isDefaultForm &&
        filterTags
      ) {
        getSearchText(false);
        if (scrollRef) {
          scrollRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
        }
      }
    }, [formValue]);

    useEffect(() => {
      if (filterTags) {
        filterTags.categories.forEach((category) => {
          if (category.category_key === "tags") {
            selectedFilter[category.category_key] = Object.entries(
              category.category_options
            )
              .filter(([key]) => key !== "None" && key !== "All")
              .map(([key, value]) =>
                key.replace("category_" + category.category_key + "_", "")
              );
          }
        });
      }
    }, [filterTags]);

    const industryOptions =
      filterTags?.categories?.find(
        (category) => category.category_key === "industry"
      )?.category_options || {};
    const formattedIndustryOptions = [
      { id: -2, title: t("allIndustry") },
      ...Object.entries(industryOptions)
        .filter(([key]) => key !== "None" && key !== "All")
        .map(([key, value]) => ({
          id: value,
          title: t(key),
        })),
    ];

    const checkCategoryLengths = (payload) => {
      const updatedPayload = { ...payload };
      for (let tag of filterTags?.categories) {
        const categoryKey = tag?.category_key;
        const payloadKey = payload[categoryKey];

        if (payloadKey) {
          const filterTagLength = Object.keys(tag.category_options).length;
          const payloadLength = payloadKey.length;
          if (filterTagLength === payloadLength) {
            updatedPayload[categoryKey] = [];
          }
        }
      }
      for (let tag of filterTags?.models) {
        const modelKey = tag?.model_key_name;
        const payloadKey = payload?.model_filters?.find(
          (model) => model?.model_key_name === modelKey
        );

        if (payloadKey) {
          const filterTagLength = Object.keys(tag?.model_options).length;
          const payloadLength = payloadKey?.model_options?.length;
          if (filterTagLength === payloadLength) {
            updatedPayload.model_filters = updatedPayload.model_filters.map(
              (item) => {
                if (item.model_key_name === modelKey) {
                  return { ...item, model_options: [] };
                }
                return item;
              }
            );
          }
        }
      }
      return updatedPayload;
    };

    useEffect(() => {
      globalGroupedImageIdsRef.current = globalGroupedImageIds;
    }, [globalGroupedImageIds]);

    const resetSearch = () => {
      setNumberOfResults({
        primary: 0,
        similar: 0,
      });
      setFormValue({ ...formValue, page: 1 });
      setGlobalGroupedImageIds(new Set());
      setSimilarityGroup([]);
      setSimilarResults([]);
      setSearchResults([]);
    };

    const getSearchText = async (isNextPage = false) => {
      setIsLoading(true);

      // if (prevSearch) {
      //   toast.info("Refreshing Search", { position: "top-center" });
      // }
      let payload;
      if (!isNextPage) {
        resetSearch();
      }
      setRefreshSearch(false);

      payload = {
        input_text: formValue.input_text,
        lang: currentLanguage, //en or ja
        page: isNextPage ? formValue.page + 1 : 1,
        ctr: Number(formValue.ctr),
        is_edited: formValue.is_edited,
        model_filters: [],
        search_by: formValue.search_by,
        site_id:
          typeof selectedSite?.site_id == "object"
            ? selectedSite?.site_id
            : [selectedSite?.site_id],
      };

      payload.industry = selectedFilter.industry.map((ind) => {
        return formattedIndustryOptions.find((option) => option.title === ind)
          ?.id;
      });

      payload.tags = selectedFilter.tags;

      if (payload?.ctr > 100) {
        toast.info("CTR cannot be greater than 100");
        setIsLoading(false);
        return;
      }

      if (isNextPage) {
        setScrollLoader(true);
        setFormValue({ ...formValue, page: formValue.page + 1 });
      }

      if (
        !payload?.input_text &&
        payload?.ctr <= 0 &&
        !payload?.tags?.length &&
        !payload?.industry?.length &&
        !payload?.model_filters?.length &&
        !payload?.is_edited
      ) {
        showAlert(t("oneFilterAtleast"), "error");
        setIsLoading(false);
        return;
      }

      setShowResults(true);
      payload = await checkCategoryLengths(payload);

      setResetData(formValue);
      addToQueryParams(formValue);
      setScrollLoader(true);
      await post(
        `text_search_image/`,
        offSetId && isNextPage ? { offset_id: offSetId, ...payload } : payload
      )
        .then((response) => {
          setNumberOfResults({
            total_images: response?.pagination?.total_images_in_page,
            primary: response?.pagination?.total_images,
            similar: response?.pagination?.total_images_in_current_page,
          });
          setOffSetId(response?.pagination?.offset_id);
          if (!isNextPage) {
            manageSimilarGroup(response, isNextPage);
            setResults(response?.data);
          } else {
            if (response?.data?.length > 0) {
              manageSimilarGroup(response, isNextPage);
              setResults((prevResults) => [...prevResults, ...response?.data]);
            }
          }
          setSearchResults(
            checked
              ? similarResults?.length > 0
                ? similarResults
                : results
              : results
          );
          setTimeout(() => {
            setIsLoading(false);
            setScrollLoader(false);
          }, 1000);
        })
        .catch((error) => {
          setIsLoading(false);
          setShowResults(true);
          console.error("error", error);
        })
        .finally(() => {
          setPrevSearch(false);
          setScrollLoader(false);
        });
    };

    const isValidSearch = () => {
      let payload = {
        input_text: formValue.input_text,
        lang: currentLanguage, //en or ja
        page: 1,
        ctr: Number(formValue.ctr),
        is_edited: formValue.is_edited,
        model_filters: [],
        search_by: formValue.search_by,
        site_id:
          typeof selectedSite?.site_id == "object"
            ? selectedSite?.site_id
            : [selectedSite?.site_id],
      };

      payload.industry = selectedFilter.industry.map((ind) => {
        return formattedIndustryOptions.find((option) => option.title === ind)
          ?.id;
      });

      payload.tags = selectedFilter.tags;

      if (payload?.ctr > 100) {
        toast.info("CTR cannot be greater than 100");
        setIsLoading(false);
        return;
      }

      if (
        !payload?.input_text &&
        payload?.ctr <= 0 &&
        !payload?.tags?.length &&
        !payload?.industry?.length &&
        !payload?.model_filters?.length &&
        !payload?.is_edited
      ) {
        showAlert(t("oneFilterAtleast"), "error");
        // setIsLoading(false);
        return false;
      }
      return true;
    };

    const handleSearchIcon = async () => {
      if (!isValidSearch()) {
        return;
      }

      setShowResults(true);
      navigate("/search-result");

      addToQueryParams(formValue);
      if (scrollRef) {
        scrollRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
      }
    };

    const manageSimilarGroup = (response, isNextPage = false) => {
      const newGroupedImageIds = new Set(globalGroupedImageIdsRef.current);

      const groupedImages =
        response?.data?.reduce((acc, image) => {
          const imageId = image.image_id;

          if (!newGroupedImageIds.has(imageId)) {
            const similarGroup = {
              similar_group_id: imageId,
              similar_group_images: [image],
            };
            newGroupedImageIds.add(imageId);

            const similarImageIds = response?.similar_image_map[imageId] || [];

            similarImageIds.forEach((similarId) => {
              if (!newGroupedImageIds.has(similarId)) {
                const similarImageInfo = response?.data.find(
                  (img) => img.image_id === similarId
                );
                if (similarImageInfo) {
                  similarGroup.similar_group_images.push(similarImageInfo);
                  newGroupedImageIds.add(similarId);
                }
              }
            });

            response?.data.forEach((otherImage) => {
              if (!newGroupedImageIds.has(otherImage.image_id)) {
                const otherSimilarIds =
                  response?.similar_image_map[otherImage.image_id] || [];
                if (otherSimilarIds.includes(imageId)) {
                  similarGroup.similar_group_images.push(otherImage);
                  newGroupedImageIds.add(otherImage.image_id);
                }
              }
            });
            acc.push(similarGroup);
          }

          return acc;
        }, []) || [];

      const groupData = groupedImages.map((group) => ({
        ...group,
        ...group.similar_group_images[0],
      }));
      if (!isNextPage) {
        setSimilarityGroup(groupedImages);
        setSimilarResults(groupData);
      } else {
        setSimilarityGroup((prevResults) => [...prevResults, ...groupedImages]);
        setSimilarResults((prevResults) => [...prevResults, ...groupData]);
      }
      setGlobalGroupedImageIds(newGroupedImageIds);
    };

    // Get All Unique Tags
    const getSearchTags = async () => {
      try {
        setIsTagsLoading(true);
        const { data } = await post(`get_all_unique_tags/`, {
          lang: "all",
        });

        localStorage.setItem("en_tags", JSON.stringify(data.en));
        localStorage.setItem("ja_tags", JSON.stringify(data.ja));

        // Combine and deduplicate tags
        const combinedTags = Array.from(
          new Set([...(data?.en || []), ...(data?.ja || [])])
        );
        setTags(combinedTags);
        setFilteredTags(combinedTags.slice(0, tagsChunk));
        setIsTagsLoading(false);
      } catch (error) {
        console.error("error", error);
        setIsTagsLoading(false);
      }
    };

    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === "ctr") {
        const sanitizedValue = value.replace(/\D/g, "");
        if (
          sanitizedValue === "" ||
          (parseFloat(sanitizedValue) >= 0 && parseFloat(sanitizedValue) <= 100)
        ) {
          setFormValue({ ...formValue, [name]: sanitizedValue });
        }
      } else {
        setFormValue({ ...formValue, [name]: value });
      }
    };

    const handleHashtagChange = (value) => {
      setFormValue({ ...formValue, tags: value });
      setSelectedFilter({ ...selectedFilter, tags: value });
    };

    const searchTags = debounce(() => {
      getSearchTags();
    }, 1000);

    useEffect(() => {
      clearEditData();
      setupLanguage();
    }, [currentLanguage]);

    const setupLanguage = () => {
      if (en_tags || ja_tags) {
        setFormValue({ ...formValue, tags: [] });
        setTags([...JSON.parse(en_tags), ...JSON.parse(ja_tags)]);
      } else {
        setFormValue({ ...formValue, tags: [] });
        searchTags();
      }
    };

    const handleSwitch = async (e) => {
      const newChecked = !checked;
      setChecked(newChecked);
      // When enabling similar images grouping, show similar results if they exist, otherwise show all results
      setSearchResults(
        newChecked
          ? similarResults?.length > 0
            ? similarResults
            : results
          : results
      );
    };

    useEffect(() => {
      // Update search results whenever checked state or results change
      setSearchResults(
        checked
          ? similarResults?.length > 0
            ? similarResults
            : results
          : results
      );
    }, [checked, results, similarResults]);

    const setupPreviusSearch = () => {
      if (searchQuery?.input_text) {
        setFormValue(searchQuery);
        getPreviousSearch();
      } else {
        setFormValue({ input_text: "", page: 1, ctr: 0, tags: [] });
      }
    };
    useEffect(() => {
      if (refreshSearch) {
        setupPreviusSearch();
        clearEditData();
      }
    }, []);

    const getPreviousSearch = async () => {
      if (searchQuery?.input_text) {
        setShowResults(true);
        await getSearchText(false);
      }
    };

    // useEffect(() => {
    //   if (prevSearch) {
    //     getSearchText();
    //   }
    // }, [prevSearch]);

    useEffect(() => {
      const handleScroll = () => {
        if (scrollRef.current) {
          const { scrollTop } = scrollRef.current;
          if (scrollTop >= 215) {
            setIsBackGroundShow(true);
          } else {
            setIsBackGroundShow(false);
          }
        }
      };
      const scrollElement = scrollRef.current;
      if (scrollElement) {
        scrollElement.addEventListener("scroll", handleScroll);
      }
      return () => {
        if (scrollElement) {
          scrollElement.removeEventListener("scroll", handleScroll);
        }
      };
    }, []);

    useEffect(() => {
      searchTags();
    }, []);

    //Search text changes
    const handleSearch = (e) => {
      // debugger
      const { name, value } = e.target;
      if (value.includes("in:")) {
        setIsSiteSearchOpen(true);
        const searchValue = value.split("in:")[1].trim();
        const filtered = userSettings?.channel_name.filter((site) =>
          site.site_name.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredSites(filtered);
      } else {
        setIsSiteSearchOpen(false);
        setFilteredSites(userSettings?.channel_name);
      }
      setFormValue({ ...formValue, [name]: value });
    };

    const closeDropdown = () => {
      setIsDropdownOpen(false);
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          closeDropdown();
        }
        if (!event.target.closest(".custom-dropdown")) {
          setIsIndustryDropdownOpen(false);
        }
        if (!event.target.closest(".tags-dropdown")) {
          setIsTagsDropdownOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    const handleInputChange = (e) => {
      const value = e.target.value.replace(/\D/g, ""); // Only allow digits
      const numValue = parseInt(value, 10);

      // If empty or within valid range (0-100), update the value
      if (value === "" || (numValue >= 0 && numValue <= 100)) {
        setSelectedFilter((prev) => ({
          ...prev,
          ctr: value === "" ? "" : numValue,
        }));

        setFormValue((prev) => ({
          ...prev,
          ctr: value === "" ? "" : numValue,
        }));
      }
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          ctrDropdownRef.current &&
          !ctrDropdownRef.current.contains(event.target)
        ) {
          setIsCtrDropdown(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    const handleTagSearch = (e) => {
      const searchValue = e.target.value.toLowerCase();
      setTagSearchInput(searchValue);
      if (searchValue) {
        const filtered = tags.filter((tag) =>
          tag.toLowerCase().includes(searchValue)
        );
        setFilteredTags(filtered.slice(0, tagsChunk));
      } else {
        setFilteredTags([]);
      }
    };

    const handleChipRemove = (tagToRemove) => {
      handleHashtagChange(formValue.tags.filter((tag) => tag !== tagToRemove));
    };

    return (
      <>
        {!showResults && (
          <section
            className="heroBanner"
            style={{
              height: "calc(100vh - 60px)",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <div className="formWrap">
                    <h2>{t("searchPastVision")}</h2>
                    <div className="searchWrapper">
                      <div className="searchInput">
                        <span>
                          <SearchIcon />
                        </span>
                        <input
                          type="text"
                          placeholder={t("searchPastVision")}
                          value={formValue.input_text}
                          name="input_text"
                          onChange={(e) => handleSearch(e)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleSearchIcon();
                            }
                          }}
                        />
                      </div>
                      <div className="industryWrap">
                        <div className="custom-dropdown">
                          <div
                            className={`dropdown-header ${isIndustryDropdownOpen ? "active" : ""}`}
                            onClick={() =>
                              setIsIndustryDropdownOpen(!isIndustryDropdownOpen)
                            }
                          >
                            <h5>{t("Industry")}</h5>
                            {selectedFilter?.industry?.length > 0 ? (
                              <div className="selected-item-wrap">
                                <p
                                  className="selected-item"
                                  title={selectedFilter?.industry[0]}
                                >
                                  {selectedFilter?.industry[0]}
                                </p>
                                {selectedFilter.industry.length > 1 && (
                                  <p className="more-count">
                                    +{selectedFilter.industry.length - 1}
                                  </p>
                                )}
                              </div>
                            ) : (
                              <p className="placeholder">
                                {t("selectIndustry")}
                              </p>
                            )}
                          </div>
                          {isIndustryDropdownOpen && (
                            <ul className="options-list">
                              <div className="indTitle">
                                <span className="indTitle">
                                  {t("Industry")}
                                </span>
                                <span
                                  onClick={() =>
                                    setIsIndustryDropdownOpen(
                                      !isIndustryDropdownOpen
                                    )
                                  }
                                >
                                  <CloseIcon />
                                </span>
                              </div>
                              {formattedIndustryOptions.map((option) => (
                                <li
                                  key={option.id}
                                  data-id={option.id} // Add this attribute to target the All option
                                  onClick={() => {
                                    if (option.id === -2) {
                                      // All option
                                      setSelectedFilter((prev) => ({
                                        ...prev,
                                        industry:
                                          prev.industry.length ===
                                          formattedIndustryOptions.length - 1
                                            ? [] // If all were selected, clear all
                                            : formattedIndustryOptions
                                                .slice(1)
                                                .map((opt) => opt.title), // Select all except "All"
                                      }));
                                    } else {
                                      const isSelected =
                                        selectedFilter.industry.includes(
                                          option.title
                                        );
                                      setSelectedFilter((prev) => ({
                                        ...prev,
                                        industry: isSelected
                                          ? prev.industry.filter(
                                              (item) => item !== option.title
                                            )
                                          : [...prev.industry, option.title],
                                      }));
                                    }
                                  }}
                                  className={`${
                                    option.id === -2
                                      ? selectedFilter.industry.length ===
                                        formattedIndustryOptions.length - 1
                                        ? "selected all"
                                        : ""
                                      : selectedFilter.industry.includes(
                                            option.title
                                          )
                                        ? "selected"
                                        : ""
                                  }`}
                                >
                                  <Checkbox
                                    checked={
                                      option.id === -2
                                        ? selectedFilter.industry.length ===
                                          formattedIndustryOptions.length - 1
                                        : selectedFilter.industry.includes(
                                            option.title
                                          )
                                    }
                                    onChange={() => {}}
                                  />
                                  <span className="value">{option.title}</span>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                      <div className="tagWrap">
                        <div className="custom-dropdown tags-dropdown">
                          <div
                            className={`dropdown-header ${isTagsDropdownOpen ? "active" : ""}`}
                            onClick={() =>
                              setIsTagsDropdownOpen(!isTagsDropdownOpen)
                            }
                          >
                            <h5>{t("Tags")}</h5>
                            {formValue.tags.length > 0 ? (
                              <div className="selected-item-wrap">
                                <p
                                  className="selected-item"
                                  title={formValue.tags[0]}
                                >
                                  {formValue.tags[0]}
                                </p>
                                {formValue.tags.length > 1 && (
                                  <p className="more-count">
                                    +{formValue.tags.length - 1}
                                  </p>
                                )}
                              </div>
                            ) : (
                              <p className="placeholder">{t("selectTags")}</p>
                            )}
                          </div>
                          {isTagsDropdownOpen && (
                            <div className="tags-container">
                              <div
                                className={`search-header ${tagSearchInput ? "hightlighted" : ""}`}
                              >
                                {formValue.tags.length > 0 && (
                                  <div className="selected-chips">
                                    {formValue.tags.map((tag) => (
                                      <div key={tag} className="chip">
                                        <span className="chip-label">
                                          {tag}
                                        </span>
                                        <span
                                          className="chip-remove"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleChipRemove(tag);
                                          }}
                                        >
                                          <CloseIcon />
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                )}
                                <input
                                  type="search"
                                  placeholder={t("AddATag")}
                                  value={tagSearchInput}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    setTagSearchInput(e.target.value);
                                    handleTagSearch(e);
                                  }}
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </div>
                              <div
                                className={`filtered-tags-container ${tagSearchInput ? "activeDrop" : null}`}
                              >
                                <AutoSizer>
                                  {({ height, width }) => (
                                    <List
                                      height={Math.min(
                                        filteredTags.length * 40,
                                        210
                                      )}
                                      itemCount={filteredTags.length}
                                      itemSize={40}
                                      width={width}
                                      itemData={{
                                        tags: filteredTags,
                                        formValue,
                                        handleHashtagChange,
                                      }}
                                    >
                                      {TagRow}
                                    </List>
                                  )}
                                </AutoSizer>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="ctrWrap">
                        <div
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent click from propagating
                            setIsCtrDropdown(!isCtrDropdown);
                          }}
                        >
                          <span>{t("ctr")}</span>
                          <p>
                            {formValue.ctr > 0
                              ? `${formValue.ctr}%`
                              : "0 ~ 100%"}
                          </p>
                        </div>
                        {isCtrDropdown && (
                          <div className="indDropDown" ref={ctrDropdownRef}>
                            <div className="title">
                              <h5>{t("ctr")}</h5>
                              <p
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent click from propagating
                                  setIsCtrDropdown(false);
                                }}
                              >
                                <CloseIcon />
                              </p>
                            </div>
                            <div
                              className="content"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <p>{t("ctrDescription")}</p>
                            </div>
                            <div
                              className="inputWrapper"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div className="inputWrap">
                                <div className="inputbox">
                                  <input
                                    type="number"
                                    min={0}
                                    max={100}
                                    value={formValue.ctr}
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                  <div className="perIcon">
                                    <img
                                      src={require("../../assets/icons/percentage.png")}
                                      alt="mico"
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* <FormControlLabel control={<Checkbox value={'abc'} />} label={t("includeUncalculatedCTR")} /> */}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="buttonWraped btn-group dropdown">
                        <button
                          type="button"
                          onClick={() => {
                            handleChange({
                              target: { name: "search_by", value: "both" },
                            });
                            handleSearchIcon();
                          }}
                        >
                          {t("search")}
                        </button>
                        <button
                          type="button"
                          className="dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="visually-hidden"></span>
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                handleChange({
                                  target: { name: "search_by", value: "text" },
                                });
                                handleSearchIcon();
                              }}
                            >
                              {t("searchTextInImage")}
                            </button>
                          </li>
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                handleChange({
                                  target: { name: "search_by", value: "desc" },
                                });
                                handleSearchIcon();
                              }}
                            >
                              {t("searchImageDescriptions")}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {showResults && (
          <SearchResults
            searchData={searchResults}
            mainTags={formValue?.tags}
            mainIndustry={selectedFilter?.industry}
            tagData={tags}
            totalResults={numberOfResults}
          />
        )}
      </>
    );
  };

export default Search;
