import Header from "../Manager/component/header";
import "./new-design.scss";
import newDesignImage from "../../assets/newimages/new-design-image.png";
import templateImage from "../../assets/newimages/template-image.png";
import scratchImage from "../../assets/newimages/scratch-image.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import templateData from "../../assets/jsons/type_a.json";

const NewDesign = () => {
  const navigate = useNavigate();
  const [isSelectScratch, setIsSelectScratch] = useState(true);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedPurpose, setSelectedPurpose] = useState("");
  const [selectedTemplateIndices, setSelectedTemplateIndices] = useState([]);

  const handleIndustryChange = (e) => setSelectedIndustry(e.target.value);
  const handleSizeChange = (e) => setSelectedSize(e.target.value);
  const handlePurposeChange = (e) => setSelectedPurpose(e.target.value);

  const handleTemplateSelect = (index) => {
    if (selectedTemplateIndices.includes(index)) {
      const updatedIndices = selectedTemplateIndices.filter((i) => i !== index);
      setSelectedTemplateIndices(updatedIndices);
      if (updatedIndices.length === 0) {
        setIsSelectScratch(true);
      }
    } else {
      setIsSelectScratch(false);
      setSelectedTemplateIndices([...selectedTemplateIndices, index]);
    }
  };

  const handleCreateDesign = () => {
    const modalElement = document.getElementById("exampleModal");
    const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
    modalInstance.hide();
    if (isSelectScratch) {
      navigate("/image-design-screen", {
        state: {
          data: null,
        },
      });
    } else {
      navigate("/image-design-screen", {
        state: {
          data: templateData,
        },
      });
    }
  };

  return (
    <div className="new-design">
      <Header />
      <div className="min-container font-noto-sans">
        <div className="d-flex justify-content-between w-100 align-items-center mt-xl-5 mt-md-4 mt-sm-3 mt-2">
          <h2 className="fs-32 fw-600">Design</h2>
          <button
            className="btn-black"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Create new design
          </button>
        </div>
        <div className="container-fluid mt-4">
          <div className="new-design-gridTemplate">
            <div>
              <div className="new-design-image">
                <img src={newDesignImage} alt="newDesignImage" />
              </div>
              <h6 className="mb-0 fw-600 mt-3 text-black">Untitled design</h6>
              <p className="mb-0 fs-14 text-dark-gray">
                Last edit: <span className="ms-1"></span>
              </p>
            </div>
            <div>
              <div className="new-design-image">
                <img src={newDesignImage} alt="newDesignImage" />
              </div>
              <h6 className="mb-0 fw-600 mt-3 text-black">Untitled design</h6>
              <p className="mb-0 fs-14 text-dark-gray">
                Last edit: <span className="ms-1"></span>
              </p>
            </div>
            <div>
              <div className="new-design-image">
                <img src={newDesignImage} alt="newDesignImage" />
              </div>
              <h6 className="mb-0 fw-600 mt-3 text-black">Untitled design</h6>
              <p className="mb-0 fs-14 text-dark-gray">
                Last edit: <span className="ms-1"></span>
              </p>
            </div>
            <div>
              <div className="new-design-image">
                <img src={newDesignImage} alt="newDesignImage" />
              </div>
              <h6 className="mb-0 fw-600 mt-3 text-black">Untitled design</h6>
              <p className="mb-0 fs-14 text-dark-gray">
                Last edit: <span className="ms-1"></span>
              </p>
            </div>
            <div>
              <div className="new-design-image">
                <img src={newDesignImage} alt="newDesignImage" />
              </div>
              <h6 className="mb-0 fw-600 mt-3 text-black">Untitled design</h6>
              <p className="mb-0 fs-14 text-dark-gray">
                Last edit: <span className="ms-1"></span>
              </p>
            </div>
            <div>
              <div className="new-design-image">
                <img src={newDesignImage} alt="newDesignImage" />
              </div>
              <h6 className="mb-0 fw-600 mt-3 text-black">Untitled design</h6>
              <p className="mb-0 fs-14 text-dark-gray">
                Last edit: <span className="ms-1"></span>
              </p>
            </div>
            <div>
              <div className="new-design-image">
                <img src={newDesignImage} alt="newDesignImage" />
              </div>
              <h6 className="mb-0 fw-600 mt-3 text-black">Untitled design</h6>
              <p className="mb-0 fs-14 text-dark-gray">
                Last edit: <span className="ms-1"></span>
              </p>
            </div>
            <div>
              <div className="new-design-image">
                <img src={newDesignImage} alt="newDesignImage" />
              </div>
              <h6 className="mb-0 fw-600 mt-3 text-black">Untitled design</h6>
              <p className="mb-0 fs-14 text-dark-gray">
                Last edit: <span className="ms-1"></span>
              </p>
            </div>
            <div>
              <div className="new-design-image">
                <img src={newDesignImage} alt="newDesignImage" />
              </div>
              <h6 className="mb-0 fw-600 mt-3 text-black">Untitled design</h6>
              <p className="mb-0 fs-14 text-dark-gray">
                Last edit: <span className="ms-1"></span>
              </p>
            </div>
            <div>
              <div className="new-design-image">
                <img src={newDesignImage} alt="newDesignImage" />
              </div>
              <h6 className="mb-0 fw-600 mt-3 text-black">Untitled design</h6>
              <p className="mb-0 fs-14 text-dark-gray">
                Last edit: <span className="ms-1"></span>
              </p>
            </div>
            <div>
              <div className="new-design-image">
                <img src={newDesignImage} alt="newDesignImage" />
              </div>
              <h6 className="mb-0 fw-600 mt-3 text-black">Untitled design</h6>
              <p className="mb-0 fs-14 text-dark-gray">
                Last edit: <span className="ms-1"></span>
              </p>
            </div>
            <div>
              <div className="new-design-image">
                <img src={newDesignImage} alt="newDesignImage" />
              </div>
              <h6 className="mb-0 fw-600 mt-3 text-black">Untitled design</h6>
              <p className="mb-0 fs-14 text-dark-gray">
                Last edit: <span className="ms-1"></span>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* popup */}

      <div
        className="modal fade new-design-modal"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content font-noto-sans mx-3">
            <div className="modal-header border-0 pt-3 px-4">
              <h4
                className="modal-title fs-20 font-noto-sans text-black"
                id="exampleModalLabel"
              >
                Create new design
              </h4>
            </div>
            <div className="modal-body pt-1">
              <div className="container-fluid">
                <div className="template-image-row">
                  <div>
                    <div
                      className={`template-image ${isSelectScratch ? "selected-template" : ""}`}
                      onClick={(e) => {
                        if (isSelectScratch) {
                          setIsSelectScratch(false);
                        } else {
                          setSelectedTemplateIndices([]);
                          setIsSelectScratch(true);
                        }
                      }}
                    >
                      <img src={scratchImage} alt="scratchImage" />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-4 col-sm-6 col-12 px-2">
                    <label className="fs-14 font-noto-sans fw-600 text-black mb-1 ms-0">
                      Industry
                    </label>
                    <select
                      className="form-select"
                      value={selectedIndustry}
                      onChange={handleIndustryChange}
                      aria-label="Default select example"
                    >
                      <option value="">Select</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12 mt-2 mt-sm-0 px-2">
                    <label className="fs-14 font-noto-sans fw-600 text-black mb-1 ms-0">
                      Size
                    </label>
                    <select
                      className="form-select"
                      value={selectedSize}
                      onChange={handleSizeChange}
                      aria-label="Default select example"
                    >
                      <option value="">Select</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12 mt-2 mt-md-0 px-2">
                    <label className="fs-14 font-noto-sans fw-600 text-black mb-1 ms-0">
                      Marketing purpose
                    </label>
                    <select
                      className="form-select"
                      value={selectedPurpose}
                      onChange={handlePurposeChange}
                      aria-label="Default select example"
                    >
                      <option value="">Select</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
                <div className="mt-4 template-image-row">
                  {/* Display templates based on selected options */}
                  {[...Array(12)].map((_, index) => (
                    <div
                      key={index}
                      className={`template-image ${selectedTemplateIndices.includes(index) ? "selected-template" : ""}`}
                      onClick={() => handleTemplateSelect(index)}
                    >
                      <img src={templateImage} alt={`templateImage-${index}`} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 position-sticky bottom-0 bg-white">
              <button
                type="button"
                className="btn-black py-2 px-4 bg-transparent border-0 text-black fw-600"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn-black py-2 px-4 fw-600"
                onClick={handleCreateDesign}
                disabled={
                  !isSelectScratch && selectedTemplateIndices.length == 0
                }
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewDesign;
