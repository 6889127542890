import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { handleModelCheckBoxChange } from "./search";

const ModelFilters = ({ filterTags, formValue, setFormValue }) => {
  const { t } = useTranslation();
  const [expandedCategories, setExpandedCategories] = useState({});

  const toggleExpand = (categoryKey) => {
    setExpandedCategories((prevState) => ({
      ...prevState,
      [categoryKey]: !prevState[categoryKey],
    }));
  };

  const onAllToggle = async (e, modelId, model, isAllChecked) => {
    model.isAllChecked = !model?.isAllChecked;
    handleModelCheckBoxChange(
      e,
      -2,
      modelId,
      model,
      formValue,
      setFormValue,
      !isAllChecked
    );
  };

  const checkTagClick = async (e, value, model, isAllChecked) => {
    handleModelCheckBoxChange(
      e,
      value,
      model.model_id,
      model,
      formValue,
      setFormValue,
      !isAllChecked
    );
  };

  return (
    <>
      {filterTags?.models?.map((model, index) => (
        <React.Fragment key={index}>
          <div className="d-flex w-100 model-container">
            <span
              onClick={() => toggleExpand(model.model_key_name)}
              className="filter-label"
              style={{ fontSize: "16px" }}
            >
              {t(model.model_display_code)}
            </span>
            <span style={{ fontSize: "14px" }}>
              {model.model_options === null ? ": None" : ""}
            </span>
            <div className="d-flex flex-wrap gap-2 w-100 model-options">
              {model?.model_options &&
                Object.entries(model.model_options)
                  .sort(([optionA, valueA], [optionB, valueB]) => {
                    const isCheckedA = formValue?.model_filters?.some(
                      (filter) =>
                        filter.model_id == model.model_id &&
                        filter.model_options.includes(valueA)
                    );
                    const isCheckedB = formValue?.model_filters?.some(
                      (filter) =>
                        filter.model_id == model.model_id &&
                        filter.model_options.includes(valueB)
                    );
                    // return isCheckedB - isCheckedA;
                    return isCheckedA === isCheckedB ? 0 : isCheckedA ? -2 : 1;
                  })
                  .slice(
                    0,
                    expandedCategories[model.model_key_name] ? undefined : 4
                  )
                  .map(([option, value], optionIndex) => {
                    const isChecked = formValue?.model_filters?.some(
                      (filter) =>
                        filter.model_id == model.model_id &&
                        filter.model_options.includes(value)
                    );
                    return (
                      <button
                        className={`gap-1 d-flex text-truncate align-items-center tag-button fs-14 position-relative filter-tags 
                          ${isChecked ? "checked" : "bg-transparent"}`}
                        title={t(option)}
                        key={optionIndex}
                        // disabled={model.isAllChecked}
                        type="button"
                        name={model.model_key_name}
                        onClick={(e) => {
                          if (value == -2) {
                            onAllToggle(e, model.model_id, model, isChecked);
                          } else {
                            checkTagClick(e, value, model, isChecked);
                          }
                        }}
                      >
                        <input
                          type="checkbox"
                          id={`checkbox-${optionIndex}`}
                          style={{ display: "none" }}
                          value={option}
                        />
                        <span
                          style={{ pointerEvents: "none" }}
                          className="text-truncate"
                        >
                          {t(option)}
                        </span>
                      </button>
                    );
                  })}
              {model.model_options &&
                Object.entries(model.model_options).length > 4 && (
                  <button
                    className="see-more-button text-nowrap"
                    onClick={() => toggleExpand(model.model_key_name)}
                  >
                    {expandedCategories[model?.model_key_name]
                      ? t("less")
                      : t("more")}
                  </button>
                )}
            </div>
          </div>
          <div className="bottom-border"></div>
        </React.Fragment>
      ))}
    </>
  );
};

export default ModelFilters;
