import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../context/useAuthContext";
import "./header.scss";
import mainLogo from "../../assets/icons/favicon.png";
import headerLogo from "../../assets/newicons/header-logo-micovision.svg";
import micoWorkLogo from "../../assets/icons/micoworksimg.png";
import translateIcon from "../../assets/icons/header_icon.svg";
import downArrow from "../../assets/icons/drop_blue.svg";
import user_icon from "../../assets/icons/user_icon.svg";
import MainHeader from "../Manager/component/header";
import {
  SiteLoader,
  UserNameLoader,
} from "../../components/Loaders/DropDownLoader";
import { Skeleton } from "@mui/material";
import { useSearchContext } from "../../context/useSearchContext";
import tick from "../../assets/icons/tick.svg";
import { InputSwitch } from "primereact/inputswitch";
// import { getUserSettings } from "../../context/useSettingContext";

const Header = () => {
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const { i18n, t } = useTranslation();
  const {
    removeSession,
    saveSelectedSite,
    selectedSite,
    initialLoading,
    isDebug,
    setIsDebug,
    userSettings,
  } = useAuthContext();
  const { setFormValue, setShowResults } = useSearchContext();
  const [filteredSites, setFilteredSites] = useState(
    userSettings?.channel_name
  );
  const inputRef = useRef(null);
  const languageRef = useRef(null);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  // const [headerSiteChange, setHeaderSite] = useState();
  const currentLanguage = i18n.language;
  const toggleModal = () => {
    setIsLanguageModalOpen((prevValue) => !prevValue);
  };
  // useEffect(() => {
  //   if (userSettings?.channel_name && onChannelNameUpdate) {
  //       onChannelNameUpdate(userSettings.channel_name);
  //   }
  // }, [userSettings?.channel_name, onChannelNameUpdate]);

  useEffect(() => {
    setFilteredSites(userSettings?.channel_name);
    return () => {};
  }, [userSettings]);

  const onSiteChange = (index, site = "") => {
    saveSelectedSite(index, site);
    setIsDropDownOpen(false);
    setFilteredSites(userSettings?.channel_name);
  };

  const handleLanguageSelection = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("selectedLanguage", language);
    setIsLanguageModalOpen(false);
  };

  const handleSiteChange = (e) => {
    const value = e.target.value;
    const filtered = userSettings?.channel_name.filter((site) =>
      site.site_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSites(filtered);
  };

  const handleLogout = () => {
    localStorage.clear();
    removeSession();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsDropDownOpen(false);
      }
    };

    if (isDropDownOpen) {
      setFilteredSites(userSettings?.channel_name);
    }

    const handleClickOutsideLang = (event) => {
      if (languageRef.current && !languageRef.current.contains(event.target)) {
        setIsLanguageModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleClickOutsideLang);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousedown", handleClickOutsideLang);
    };
  }, [isDropDownOpen]);

  const clearFormValue = () => {
    setShowResults(false);
    setFormValue({
      input_text: "",
      page: 1,
      ctr: 0,
      tags: [],
      model_filters: [],
      search_by: "both",
      is_edited: "all",
    });
  };

  return (
    <div className="d-flex justify-content-between align-items-center gap-2 gap-sm-4 border-bottom sticky-top bg-white header-wrapper">
      <div className="container-fluid p-0">
        <MainHeader
          // fetchProjects={fetchProjects}
          // setIsAll={setIsAll}
          screen="settings"
          // selectedSite={selectedSite}
          // getUserSettings = {getUserSettings}
          // onChannelNameUpdate={onChannelNameUpdate}
          // setSearchView={setSearchView}
        />
      </div>
    </div>
  );
};

export default Header;
