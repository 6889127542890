import { Skeleton, Box, useMediaQuery } from "@mui/material";
import React from "react";
import "../../views/Search/search.scss";

const CardLoader = () => {
  // Define custom breakpoints
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:960px)");

  const getWidth = () => {
    if (isSmallScreen) return "100%";
    if (isMediumScreen) return "90%";
    return "280px";
  };

  return (
    <Box
      className="max-w-300 card"
      width={getWidth()}
      margin="auto"
      style={{ height: "300px" }}
      padding={isSmallScreen ? 1 : 2}
    >
      <Skeleton variant="rounded" animation="wave" width="100%" height={242} />
      {/* <Box
        display="flex"
        m={isSmallScreen ? 1 : 2}
        mx={isSmallScreen ? 2 : 4}
        flexDirection={isSmallScreen ? "column" : "row"}
        alignItems="center"
      >
        <Skeleton
          animation="wave"
          variant="rounded"
          width={isSmallScreen ? "50%" : 80}
          height={24}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={isSmallScreen ? "50%" : 126}
          height={24}
          style={{
            marginLeft: isSmallScreen ? 0 : "8px",
            marginTop: isSmallScreen ? "8px" : 0,
          }}
        />
      </Box> */}
      {/* <Skeleton
      className="p-1"
        animation="wave"
        variant="text"
        width="100%"
        height={53}
      /> */}

      {/* <Box
        display="flex"
        justifyContent={isSmallScreen ? "center" : "space-between"}
        alignItems="center"
        flexWrap="wrap"
        mt={1}
        p={1}
      >
        {[...Array(6)].map((_, i) => (
          <Skeleton
            animation="wave"
            key={i}
            variant="rounded"
            width={isSmallScreen ? "65%" : 80}
            height={24}
            style={{ marginBottom: "8px" }}
          />
        ))}
      </Box>
      <Box p={1} width="100%">
        <Skeleton animation="wave" variant="rounded" width="100%" height={36} />
      </Box> */}
    </Box>
  );
};

export default CardLoader;
