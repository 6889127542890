import React, { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../../context/useAuthContext";
import styles from "./account-management.module.css";
import { useTranslation } from "react-i18next";
import { newPost, newPut } from "../../API/axios";
import { useFlag } from "@unleash/proxy-client-react";

import UploadImageIcon from "../../assets/camera-svgrepo-com.png";
import DropDown from "../../assets/icons/drop_blue.svg";
import { toast } from "react-toastify";
import { LanguageEnum } from "../../utils/enums";
import { showAlert } from "../../views/Manager/component/AlertService";

const AccountManagement = () => {
  const { userSettings, getUserSettings } = useAuthContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState({});
  const [allPossibleChannel, setAllPossibleChannel] = useState([]);
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [isChannelDropdownOpen, setChannelIsDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    userSettings?.pref_language || "English"
  ); // Default to English
  const { i18n, t } = useTranslation();

  const channelDropdownRef = useRef(null);
  const channelDropdownFieldRef = useRef(null);

  const usernameChange = useFlag("SettingsPageUserNameChange");
  const passwordChange = useFlag("SettingsPasswordChangeField");

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    // handleInputChange("pref_language", language)
    const abbLang = language === "English" ? "en" : "ja";
    i18n.changeLanguage(abbLang);
    localStorage.setItem("selectedLanguage", abbLang);
    setIsDropdownOpen(false); // Close dropdown after selection
    // Here you would typically make an API call or update state to persist the change.
    console.log("Language changed to:", language);
  };

  useEffect(() => {
    const allSites = userSettings?.main_site_id == 0 ? true : false;
    let allSite = { site_id: 0, site_name: t("All") };
    const siteData = allSites
      ? allSite
      : userSettings?.channel_name.find(
          (s) => s.site_id == userSettings?.main_site_id
        );

    setAllPossibleChannel([allSite, ...(userSettings?.channel_name || [])]);
    setFilteredChannels([allSite, ...(userSettings?.channel_name || [])]);
    setSelectedChannel(siteData ? siteData : userSettings?.channel_name[0]);
  }, [userSettings]);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordShow, setPasswordShow] = useState({
    password: false,
    confirmPassword: false,
  });

  const [validation, setValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  });

  // State for editable fields
  const [editing, setEditing] = useState({
    username: false,
    email: false,
  });

  // State for storing the changed values
  const [editedValues, setEditedValues] = useState({
    username: userSettings?.username || "",
    email: userSettings?.email || "",
    pref_language: userSettings?.pref_language || "English",
  });

  const [selectedImage, setSelectedImage] = useState(null);

  // Function to handle image selection
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedImage(event.target.files[0]);
    }
  };

  // Function to handle changes in the input fields
  const handleInputChange = (field, value) => {
    setEditedValues({
      ...editedValues,
      [field]: value,
    });
  };

  const handleChannelSearch = (e) => {
    const value = e.target.value;
    const filtered = allPossibleChannel.filter((site) =>
      site.site_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredChannels(filtered);
  };

  const handleSaveAllChanges = async () => {
    try {
      // Send only the changed values to the API
      const changedValues = {};
      for (const field in editedValues) {
        if (editedValues[field] !== userSettings[field]) {
          changedValues[field] = editedValues[field];
        }
      }

      if (selectedImage) {
        const formData = new FormData();
        formData.append("files", selectedImage);

        const response = await newPost("upload", formData);
        if (response.status_code >= 200 && response.status_code < 300) {
          changedValues["user_image_url"] = response.result[0].url;
        }
      }

      if (selectedChannel.site_id !== userSettings?.main_site_id[0]?.site_id) {
        await newPut("project/saveSettings", {
          site_id: String(selectedChannel?.site_id),
          language: localStorage.getItem("selectedLanguage") || "en",
        });
      }

      for (const field in changedValues) {
        if (!changedValues[field]) {
          delete changedValues[field];
        }
      }

      if (Object.values(changedValues).length) {
        // Make API call to update user settings
        await newPut(`users/${userSettings.user_id}`, changedValues);
      }
      getUserSettings();
      // showAlert(t("VALUES_UPDATED_SUCCESSFULLY"),"success")

      showAlert(t("VALUES_UPDATED_SUCCESSFULLY"), "success");
      setSelectedImage(null);
    } catch (error) {
      toast.error(t("UNEXPECTED_ERROR"), "error");
      console.error("Error updating user settings:", error);
    }
  };

  const submitReady = () => {
    if (selectedImage) {
      return false;
    }

    if (
      (selectedChannel &&
        selectedChannel?.site_id != userSettings?.main_site_id) ||
      LanguageEnum[
        userSettings?.["pref_language"]?.toUpperCase() || "ENGLISH"
      ] !== localStorage.getItem("selectedLanguage")
    ) {
      return false;
    }

    const changedValues = {};
    for (const field in editedValues) {
      if (
        editedValues?.[field] &&
        editedValues[field] !== userSettings?.[field]
      ) {
        changedValues[field] = editedValues[field];
      }
    }

    for (const field in changedValues) {
      if (!changedValues[field]) {
        delete changedValues[field];
      }
    }

    if (Object.values(changedValues)?.length) {
      return false;
    }

    return true;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // Perform Validation
    setValidation(validatePassword(newPassword));
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const validatePassword = (password) => {
    const lengthValid = password.length >= 8 && password.length <= 64;
    const uppercaseValid = /[A-Z]/.test(password);
    const lowercaseValid = /[a-z]/.test(password);
    // const numberValid = //.test(password);
    const specialValid = /[!@#$%^&*()_+\-=\[\]{};':",\\|,.<>\/?]/.test(
      password
    );

    return {
      length: lengthValid,
      uppercase: uppercaseValid,
      lowercase: lowercaseValid,
      number: true,
      special: specialValid,
    };
  };

  const handleSave = () => {
    if (
      password === confirmPassword &&
      Object.values(validation).every((v) => v)
    ) {
      // Passwords match and meet all criteria
      alert("Password updated successfully!"); // Replace with your actual save logic
    } else {
      alert("Passwords do not match or do not meet all criteria.");
    }
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        channelDropdownFieldRef.current &&
        !channelDropdownFieldRef.current.contains(event.target) &&
        channelDropdownRef.current &&
        !channelDropdownRef.current.contains(event.target)
      ) {
        setChannelIsDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isChannelDropdownOpen]); // Dependency to reapply effect when dropdown state changes

  const channelClickHandler = (channel) => {
    setSelectedChannel(channel);
    setChannelIsDropdownOpen(false);
  };

  return (
    <div className="container">
      <div className="row">
        <div>
          <div className="">
            <div className="card-body">
              <h3>{t("MY_ACCOUNT")}</h3>

              {/* Profile Photo - Conditional rendering for image */}
              <div
                className={`${styles.fieldContainer} ${styles.firstContainer}`}
              >
                <span className={`${styles.formLabel} text-left`}>
                  {t("PROFILE_PHOTO")}
                </span>
                {userSettings?.user_image_url || selectedImage ? (
                  <div className={`${styles.profileImageContainer}`}>
                    <img
                      src={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : userSettings?.user_image_url
                      }
                      alt="Profile Photo"
                      width="100"
                      className={`rounded-circle ${styles.profileImage}`}
                    />
                    <img
                      src={UploadImageIcon}
                      className={`${styles.uploadImageIcon}`}
                      onClick={() =>
                        document.getElementById("profileImageUpload")?.click()
                      }
                    />
                  </div>
                ) : (
                  <div
                    className="rounded-circle bg-secondary d-flex align-items-center justify-content-center"
                    style={{
                      width: "100px",
                      height: "100px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      document.getElementById("profileImageUpload")?.click()
                    }
                  >
                    <span>{t("NO_PHOTO")}</span>{" "}
                    {/* Display "No Photo" or a default icon */}
                  </div>
                )}
                <input
                  type="file"
                  id="profileImageUpload"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
              </div>

              {/* Name */}
              <div className={`${styles.fieldContainer}`}>
                <div className="d-flex justify-content-between align-items-center">
                  <label className={`${styles.formLabel}`}>{t("NAME")}</label>
                  {usernameChange ? (
                    <button
                      className={`${styles.changeButton}`}
                      onClick={() =>
                        setEditing((pre) => ({
                          ...pre,
                          username: !pre.username,
                        }))
                      }
                    >
                      {t("CHANGE")}
                    </button>
                  ) : null}
                </div>
                {editing.username ? (
                  <input
                    type="text"
                    className={`${styles.formText}`}
                    value={editedValues.username}
                    onChange={(e) =>
                      handleInputChange("username", e.target.value)
                    }
                  />
                ) : (
                  <span className={`${styles.formText}`}>
                    {userSettings?.username}
                  </span>
                )}
              </div>

              {/* Email, Role, Language */}
              <div className={`${styles.fieldContainer}`}>
                <label className={`${styles.formLabel}`}>
                  {t("COMPANY_EMAIL")}
                </label>
                <span className={`${styles.formText}`}>
                  {userSettings?.email}
                </span>
              </div>
              <div className={`${styles.fieldContainer}`}>
                <label className={`${styles.formLabel}`}>{t("ROLE")}</label>
                <span className={`${styles.formText} ${styles.capitalize}`}>
                  {userSettings?.role_name?.replaceAll("_", " ").toLowerCase()}
                </span>
              </div>
              {passwordChange ? (
                <div className={`${styles.fieldContainer}`}>
                  <div>
                    <p className={`${styles.formLabel}`}>
                      {t("EDIT_PASSWORD")}
                    </p>
                    <p className={`${styles.formText}`}>
                      {t("NEW_PASSWORD_INSTRUCTION")}
                    </p>
                  </div>
                  <div>
                    <label htmlFor="password" className={`${styles.formLabel}`}>
                      {t("PASSWORD")}
                    </label>
                    <div className={`${styles.passwordContainer}`}>
                      <input
                        type="password"
                        id="password"
                        className={`${styles.passwordField}`}
                        value={password}
                        placeholder={t("ENTER_PASSWORD")}
                        onChange={handlePasswordChange}
                      />
                    </div>
                  </div>

                  <ul>
                    <li
                      className={
                        validation.length ? styles.valid : styles.invalid
                      }
                    >
                      {t("PASSWORD_LENGTH")}
                    </li>
                    <li
                      className={
                        validation.number ? styles.valid : styles.invalid
                      }
                    >
                      {t("PASSWORD_NUMBER")}
                    </li>
                    <li
                      className={
                        validation.lowercase ? styles.valid : styles.invalid
                      }
                    >
                      {t("PASSWORD_LOWERCASE")}
                    </li>
                    <li
                      className={
                        validation.uppercase ? styles.valid : styles.invalid
                      }
                    >
                      {t("PASSWORD_UPPERCASE")}
                    </li>
                    <li
                      className={
                        validation.special ? styles.valid : styles.invalid
                      }
                    >
                      {t("PASSWORD_SPECIAL")}
                    </li>
                  </ul>
                  <div>
                    <label
                      htmlFor="confirmPassword"
                      className={`${styles.formLabel}`}
                    >
                      {t("CONFIRM_PASSWORD")}
                    </label>
                    <div className={`${styles.passwordContainer}`}>
                      <input
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        className={`${styles.passwordField}`}
                        placeholder={t("ENTER_PASSWORD_CONFIRMATION")}
                        onChange={handleConfirmPasswordChange}
                      />
                    </div>
                  </div>

                  <button
                    onClick={handleSave}
                    className={`${styles.passwordButton}`}
                  >
                    {t("SAVE_CHANGES")}
                  </button>
                </div>
              ) : null}
              <div className={`${styles.fieldContainer} `}>
                <div className="d-flex justify-content-between align-items-center">
                  <label className={`${styles.formLabel}`}>
                    {t("defaultChannel")}
                  </label>
                </div>
                <div
                  className={`${styles.formText} ${styles.dropdownContainer}`}
                  ref={channelDropdownFieldRef}
                >
                  {isChannelDropdownOpen ? (
                    <input
                      type="text"
                      autoFocus
                      className={`${styles.dropdownLabel}`}
                      onChange={handleChannelSearch}
                    />
                  ) : (
                    <>
                      <span
                        className={`${styles.dropdownLabel}`}
                        onClick={() =>
                          setChannelIsDropdownOpen(!isChannelDropdownOpen)
                        }
                      >
                        {selectedChannel?.site_name || ""}
                      </span>
                      <img
                        src={DropDown}
                        alt=""
                        className={styles.dropdownIcon}
                        onClick={() =>
                          setChannelIsDropdownOpen(!isChannelDropdownOpen)
                        }
                      />
                    </>
                  )}
                  {isChannelDropdownOpen ? (
                    <div
                      className={styles.dropdownField}
                      ref={channelDropdownRef}
                    >
                      {filteredChannels.map((channel) => (
                        <p
                          className={styles.channelItem}
                          key={channel.site_id}
                          onClick={() => channelClickHandler(channel)}
                        >
                          {channel.site_name}
                        </p>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={`${styles.fieldContainer}`}>
                <div className="d-flex justify-content-between align-items-center">
                  <label className={`${styles.formLabel}`}>
                    {t("LANGUAGE")}
                  </label>
                  <button
                    className={`${styles.changeButton}`}
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)} // Toggle dropdown
                  >
                    {t("CHANGE")}
                  </button>
                </div>
                {/* Conditionally render the dropdown */}
                {isDropdownOpen && (
                  <div className={styles.dropdown}>
                    {" "}
                    {/* Style your dropdown */}
                    <button
                      className={`${styles.dropdownItem} ${selectedLanguage === "English" ? styles.selected : ""}`}
                      onClick={() => handleLanguageChange("English")}
                    >
                      English
                    </button>
                    <button
                      className={`${styles.dropdownItem} ${selectedLanguage === "Japanese" ? styles.selected : ""}`}
                      onClick={() => handleLanguageChange("Japanese")}
                    >
                      日本語
                    </button>
                  </div>
                )}
                <span className={`${styles.formText}`}>
                  {localStorage.getItem("selectedLanguage") === "ja"
                    ? "日本語"
                    : "English"}
                </span>{" "}
                {/* Display selected language */}
              </div>
              <button
                type="button"
                className={`${styles.passwordButton}`}
                onClick={handleSaveAllChanges}
                disabled={submitReady()}
              >
                {t("Save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountManagement;
