import React, { useEffect, useRef, useState } from "react";

import styles from "./reference.module.css";

import { Excalidraw } from "@excalidraw/excalidraw";
import {
  FormControl,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Tooltip,
} from "@mui/material";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  imageGet,
  newDelete,
  newGet,
  newGet_V3,
  newGet_V4,
  newPost,
  newPut,
  newPut_V3,
  newPut_V4,
} from "../../API/axios";
import ArrowBack from "../../assets/newicons/arrow_back.svg";
import Close from "../../assets/newicons/close.svg";
import Chate from "../../assets/newicons/forum.svg";
import info from "../../assets/newicons/info.svg";
import visionStudioPreview from "../../assets/newimages/editor-bg.png";
import { useAuthContext } from "../../context/useAuthContext";
import { useReferenceContext } from "../../context/useReferenceContext";
import { showAlert, showConfirmationDialog } from "./component/AlertService";
import ChateBoat from "./component/ChateBoat";
import CreateProjectForm from "./component/createProjectForm";
import Header from "./component/header";
import ProjectStages from "./component/ProjectStages";
import ReferenceComp from "./component/ReferenceComp";
import UploadDesignFile from "./component/UploadDesignFile";
import { ReactComponent as ProjectIcon } from "../../assets/newicons/quick_reference.svg";
import Pending from "../../assets/newicons/pendingNew.svg";
import InProgress from "../../assets/newicons/radio_button_partial.svg";
import Cancelled from "../../assets/newicons/cancelNew.svg";
import Completed from "../../assets/newicons/Completed.svg";
import Approved from "../../assets/newicons/approved.svg";
import NotStarted from "../../assets/newicons/notStarted.svg";
import WireFrameInProgress from "../../assets/newicons/pendingNew2.svg";
import WireFrameConfirmation from "../../assets/newicons/wireframeConfirmation.svg";
import designInProgress from "../../assets/newicons/designInProgress.svg";
import designConfirmation from "../../assets/newicons/DesignConfirmation2.svg";
import ApprovedNew from "../../assets/newicons/approvedNew.svg";
import "./reference.scss";
import CreateProjectFormLoader from "./skeletonLoaders/CreateProjectFormLoader";
// import en from "../../locales/en/translation.json";
// import ja from "../../locales/ja/translation.json";
import { useKonvaContext } from "../../context/useKonvaContext";
import { initializeCanvas, updateGroup } from "../../store/konvaSlice";
import CountdownPopup from "../../components/Shared/CountdownPopup";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { useMemo } from "react";

const useDebouncedValue = (inputValue, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);

  return debouncedValue;
};
const Reference = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { setSheetCount } = useKonvaContext();
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refListLoader, setRefListLoader] = useState(false);
  const location = useLocation();
  let { createdProjectId, siteId, userRole } = location.state || {};
  const [channelId, setChannelId] = useState(siteId);
  const [userList, setUserList] = useState([]);
  const [clientUserList, setClientUserList] = useState([]);
  const [clientManager, setClientManager] = useState([]);
  const allUsers = [...userList, ...clientUserList];

  const [referenceList, setReferenceList] = useState();
  const [assignee, setAssignee] = useState();
  const [internalApprover, setInternalApprover] = useState();
  const [clientApprover, setClientApprover] = useState();
  const [watchListList, setWatchListList] = useState();
  const [dueDate, setDueDate] = useState("");
  const [requiredSheet, setRequiredSheet] = useState();
  const [projectId, setProjectId] = useState();
  const [channelName, setChannelName] = useState();
  const [internalApprovalMsg, setInternalApprovalMsg] = useState("");
  // const [projectStatus, setProjectStatus] = useState(1);
  const [isCreateDisable, setIsCreateDisable] = useState(false);

  const [inputProjectName, setInputProjectName] = useState("");
  const debouncedProjectName = useDebouncedValue(inputProjectName, 1000);

  const { initialLoading, hasPermission, userSettings } = useAuthContext();
  const [excalidrawData, setExcalidrawData] = useState(null);
  const [excalidrawAPI, setExcalidrawAPI] = useState(null);
  const [designDetails, setdesignDetails] = useState([]);
  const [excalidrawLoading, setExcalidrawLoading] = useState(true);
  const [isApprovalBtnDisable, setIsApprovalBtnDisable] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkError, setLinkError] = useState("");
  const [visionBoardData, setVisionBoardData] = useState([]);
  const [isValidURL, setIsValidURL] = useState(true);

  const {
    referencesList,
    setReferencesList,
    setMiroValue,
    setMiroLink,
    setBoardId,
    setSheetId,
    setDesignId,
    projectDetails,
    setProjectDetails,
    setUploadedFiles,
    projectStatus,
    setProjectStatus,
  } = useReferenceContext();
  const [checked, setChecked] = useState(false);
  const [projectName, setProjectName] = useState();
  const [projectType, setProjectType] = useState();
  const [psdData, setPsdData] = useState([]);
  const [psdLinkItems, setPsdLinkItems] = useState([]);
  const [currentLink, setCurrentLink] = useState("");
  const [editPsdLinkId, setEditPsdLinkId] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [error, setError] = useState(false);
  const { designId } = useReferenceContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const disableStatus = {
    2: {
      1: t("STATUS_NOT_STARTED_DISABLE"),
    },
    4: {
      1: t("STATUS_NOT_STARTED_DISABLE"),
      3: t("STATUS_CANCELLED_DISABLE"),
    },
  };

  const checkMenuItemDisabled = useCallback(
    (value) => {
      // If project status is "Not Started" (1)
      if (projectStatus === 1) {
        // Enable only In Progress (2), Cancelled (3), and Pending (10)
        // Disable all other statuses
        return ![1, 2, 3, 10].includes(value);
      } else if (projectStatus === 2) {
        return ![2, 6, 3, 10].includes(value);
      } else if (projectStatus === 3) {
        // return [1,2, 3, 4, 5, 6, 7, 8, 9, 10].includes(value);
        return ![3].includes(value);
      } else if (projectStatus === 6) {
        return ![3, 6, 7, 10].includes(value);
      } else if (projectStatus === 7) {
        return ![3, 7, 8, 10].includes(value);
      } else if (projectStatus === 8) {
        return ![3, 8, 9, 10].includes(value);
      } else if (projectStatus === 9) {
        return ![3, 4, 9, 10].includes(value);
      } else if (projectStatus === 10) {
        return ![2, 3, 4, 6, 7, 8, 9, 10].includes(value);
      } else if (projectStatus === 4) {
        return ![3, 4, 5, 10].includes(value);
      } else if (projectStatus === 5) {
        return ![2, 3, 4, 5, 6, 7, 8, 9, 10].includes(value);
      }

      // For other status values, keep existing logic
      // switch (value) {
      //   case 1: // Not Started
      //     return (
      //       projectStatus === 1 ||
      //       (userRole !== "Creator" && userSettings?.role_id === 4)
      //     );
      //   case 2: // In Progress
      //     return (
      //       (projectStatus > 3 && projectStatus !== 4) ||
      //       (userRole !== "Creator" &&
      //         (userSettings?.role_id === 4 || userSettings?.role_id === 6))
      //     );
      //   case 3: // Cancelled
      //     return (
      //       projectStatus > 3 ||
      //       (userRole !== "Creator" &&
      //         (userSettings?.role_id === 4 || userSettings?.role_id === 6))
      //     );
      //   case 4: // Approved
      //     return (
      //       projectStatus === 3 ||
      //       projectStatus > 4 ||
      //       (userRole !== "Creator" && userSettings?.role_id === 6)
      //     );
      //   case 5: // Completed
      //     return (
      //       userRole !== "Creator" &&
      //       (userSettings?.role_id === 4 || userSettings?.role_id === 6)
      //     );
      //   case 10: // Pending
      //     return (
      //       userRole !== "Creator" &&
      //       (userSettings?.role_id === 4 || userSettings?.role_id === 6)
      //     );
      //   default:
      //     return false;
      // }
    },
    [projectStatus]
  );

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };
  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (isDropdownOpen) {
        setIsDropdownOpen(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isDropdownOpen]);

  const menuItems = useMemo(
    () => [
      {
        value: 1,
        disabled: checkMenuItemDisabled(1),
        backgroundColor: "#E6E6E6",
        hoverColor: "#F2F2F2",
        width: "138px",
        icon: NotStarted,
        text: t("notStarted"),
      },
      {
        value: 6,
        disabled: checkMenuItemDisabled(6),
        backgroundColor: "#FAF1E6",
        hoverColor: "#E5D1C0",
        width: "200px",
        icon: WireFrameInProgress,
        text: t("WireframeInProgress"),
      },
      {
        value: 7,
        disabled: checkMenuItemDisabled(7),
        backgroundColor: "#CCCCCC",
        hoverColor: "#F0F0F0",
        width: "294px",
        icon: WireFrameConfirmation,
        text: t("WaitingForWireframeConfirmation"),
      },
      {
        value: 8,
        disabled: checkMenuItemDisabled(8),
        backgroundColor: "#FFEFBF",
        hoverColor: "#FFFDE7",
        width: "174px",
        icon: designInProgress,
        text: t("designInProgress"),
      },
      {
        value: 9,
        disabled: checkMenuItemDisabled(9),
        backgroundColor: "#e4e4de",
        hoverColor: "#E0E0DA",
        width: "267px",
        icon: designConfirmation,
        text: t("WaitingForDesignConfirmation"),
      },
      {
        value: 2,
        disabled: checkMenuItemDisabled(2),
        backgroundColor: "#EFF3FF",
        hoverColor: "#A9B5FF",
        width: "124px",
        icon: InProgress,
        text: t("inProgress"),
      },
      {
        value: 4,
        disabled: checkMenuItemDisabled(4),
        backgroundColor: "#4C58FF",
        hoverColor: "#7A88FF",
        width: "113px",
        color: "#FFFFFF",
        icon: ApprovedNew,
        text: t("approved"),
      },
      {
        value: 5,
        disabled: checkMenuItemDisabled(5),
        backgroundColor: "#E6F4ED",
        hoverColor: "#A5BFB5",
        width: "121px",
        icon: Completed,
        text: t("completed"),
      },
      {
        value: 10,
        disabled: checkMenuItemDisabled(10),
        backgroundColor: "#FCE8E6",
        hoverColor: "#C9AEAC",
        width: "100px",
        icon: Pending,
        text: t("pending"),
      },
      {
        value: 3,
        disabled: checkMenuItemDisabled(3),
        backgroundColor: "#E74733",
        hoverColor: "#FFA092",
        width: "112px",
        color: "#FFFFFF",
        icon: Cancelled,
        text: t("cancelled"),
      },
    ],
    [checkMenuItemDisabled, t]
  );

  const [statusOptions, setStatusOptions] = useState(menuItems);

  const getProjectStatus = useCallback(async () => {
    try {
      const data = await newGet(`project/getStatus`);
      if (data.status_code === 200) {
        const updatedStatusOptions = menuItems.map((item) => {
          const matchedStatus = data.result.find(
            (status) => status.status_id === item.value
          );
          return matchedStatus
            ? { ...item, statusLabel: matchedStatus.status }
            : item;
        });
        setStatusOptions(updatedStatusOptions);
      }
    } catch (error) {
      console.error(error);
    }
  }, [menuItems]);

  useEffect(() => {
    getProjectStatus();
  }, [getProjectStatus]);

  // useEffect(() => {
  //   const savedLinks = JSON.parse(localStorage.getItem("psdLinkItems")) || [];
  //   setPsdLinkItems(savedLinks);
  // }, []);

  // Check if project is active based on status
  const isProjectActive = () => {
    if (!projectDetails?.status) return true; // Default to active if no status

    const statusId = parseInt(Object.keys(projectDetails.status)[0]);

    // Status 5 means completed/cancelled project, status 3 means inactive
    return statusId !== 5 && statusId !== 3;
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    createdProjectId = queryParams.get("project_id");
    siteId = queryParams.get("site_id");
    // userRole = queryParams.get('user_role');

    fetchPsdLinks();
  }, []);

  const resolvablePromise = () => {
    let resolve;
    let reject;
    const promise = new Promise((_resolve, _reject) => {
      resolve = _resolve;
      reject = _reject;
    });
    promise.resolve = resolve;
    promise.reject = reject;
    return promise;
  };

  const initialStatePromiseRef = useRef({ promise: resolvablePromise() });
  if (!initialStatePromiseRef?.current?.promise) {
    initialStatePromiseRef.current.promise = resolvablePromise();
  }

  // const projectStage = useMemo(() => {
  //   <ProjectStages  projectDetails={projectDetails}  designDetails={designDetails} />
  // }, [designDetails, projectDetails])

  const cancelConfirmation = async (id, e) => {
    try {
      const currentStatus =
        statusOptions.find((status) => status.value === projectStatus)?.text ||
        "Unknown Status";

      // Show confirmation dialog first
      const confirmation = await showConfirmationDialog(
        t("CancelProjectMsg"), // Add this to your translation files
        // t("CancelFileWarning"), // Add this to your translation files
        `${t("CancelFileWarning")} '${currentStatus}' ${t("to_cancelled")}`,
        t("cancel"),
        t("yes-update")
      );
      // If user clicks "No", return
      if (!confirmation) {
        return;
      } else if (confirmation) {
        // Extract URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        const URLParamsSiteId = urlParams.get("site_id");
        // Prepare form data
        const formData = {
          project_id: parseInt(createdProjectId, 10), // Using the provided project ID
          site_id: siteId?.toString() || URLParamsSiteId,
          status_id: 3,
        };
        try {
          const data = await newPut(`project`, formData);
          if (data.status_code === 200) {
            setProjectStatus("3");
          } else {
            console.error("Failed to update project status:", data);
          }
        } catch (error) {
          console.error("Error updating project status:", error);
        }
      } else {
        console.error("Invalid status_id, request not sent.");
      }
    } catch (error) {
      console.error("Error in confirmation dialog:", error);
    }
  };

  const fetchPsdLinks = async () => {
    try {
      const response = await newGet(
        `link/getLinkByProjectId?project_id=${createdProjectId}`
      );
      if (response?.status_code === 200) {
        const sortedResult = response.result.sort(
          (a, b) => b.link_id - a.link_id
        );
        setPsdLinkItems(sortedResult);
        // setPsdLinkItems(response.result);
      }
    } catch (error) {
      navigate("/dashboard");
    }
  };

  function formatURL(url) {
    return url.replace(/^(?:https?:\/\/)?(.+)$/, "https://$1");
  }

  // Add or Save link (based on state)
  const handleAddOrSave = async () => {
    if (isSubmitting || !isProjectActive()) return; // Prevent multiple submissions
    setIsSubmitting(true); // Set submitting state to true

    if (!currentLink.trim()) {
      // toast.error(t("emptyLinkError"), { autoClose: 1500 });
      setIsSubmitting(false); // Always reset submitting state after validation
      return;
    }
    setLinkError("");

    const isDuplicate = psdLinkItems.some(
      (item) => item.link_url === currentLink.trim()
    );
    if (isDuplicate) {
      // toast.error(t("duplicateLinkError"), { autoClose: 1500 });
      setLinkError(t("LINK_ERROR"));
      setIsSubmitting(false); // Always reset submitting state after validation
      return;
    }

    const queryParams = new URLSearchParams(window.location.search);
    const createdProjectId = queryParams.get("project_id");

    try {
      if (editPsdLinkId === null) {
        // Add new link
        const psdLinkData = {
          link_url: currentLink,
          project_id: Number(createdProjectId),
          is_active: true,
        };

        const response = await newPost("link/create", psdLinkData);
        if (response?.status_code === 1058) {
          showAlert(t("LINK_IS_NOT_IN_URL_FORMAT"), "error", {
            autoClose: 1500,
          });
        }

        if (response?.status_code === 200 || response?.status_code === 201) {
          showAlert(t("linkAddedSuccess"), "success", { autoClose: 1500 });
          fetchPsdLinks();

          const insertId = response?.result?.insertId;
          if (insertId) {
            const newLink = { link_id: insertId, link_url: currentLink };
            setPsdLinkItems((prevItems) => [...prevItems, newLink]);
          }
        } else {
          // toast.error(t("linkCreationFailed"), { autoClose: 1500 });
        }
      } else {
        // Save (edit) link
        const response = await newPut("link/update", {
          link_id: editPsdLinkId,
          link_url: currentLink,
        });

        if (response.status_code === 200) {
          showAlert(t("linkUpdatedSuccess"), "success", { autoClose: 1500 });

          setPsdLinkItems((prevItems) =>
            prevItems.map((item) =>
              item.link_id === editPsdLinkId
                ? { ...item, link_url: currentLink }
                : item
            )
          );

          setEditPsdLinkId(null); // Reset edit state
          fetchPsdLinks();
        } else {
          toast.error(t("updateFailed"), { autoClose: 1500 });
        }
      }
    } catch (error) {
      console.error("Error handling link:", error);
      toast.error(
        editPsdLinkId === null ? t("linkCreationFailed") : t("updateFailed"),
        {
          autoClose: 1500,
        }
      );
    }

    setCurrentLink(""); // Reset input field
    setIsSubmitting(false); // Always reset submitting state after operation
  };

  // Delete a link
  const handleDelete = async (id) => {
    try {
      if (!isProjectActive()) return;
      const response = await newDelete(`link/softDelete?link_id=${id}`);
      if (response.status_code === 200) {
        setCurrentLink("");
        setEditPsdLinkId(null);
        showAlert(t("linkdeleteSuccess"), "success", { autoClose: 1500 });
        setPsdLinkItems((prevItems) =>
          prevItems.filter((item) => item.link_id !== id)
        );
      } else {
        showAlert(t("linkDeleteFailed"), "error", { autoClose: 1500 });
      }
    } catch (error) {
      console.error("Error deleting link:", error);
      showAlert(t("linkDeleteFailed"), "error", { autoClose: 1500 });
    }
  };

  // Start editing a link
  const handleEdit = (linkId, currentUrl) => {
    if (!isProjectActive()) return;
    setEditPsdLinkId(linkId);
    setCurrentLink(currentUrl);
  };

  // Toggle to show all links
  const handleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  // Slice links for pagination
  const displayedLinks = showAll ? psdLinkItems : psdLinkItems.slice(0, 5);

  useEffect(() => {
    const updateData = async () => {
      setExcalidrawLoading(true);
      try {
        const files = excalidrawData.files || {};
        await Promise.all(
          Object.entries(files).map(async ([key, file]) => {
            if (!file.dataURL.startsWith("data:")) {
              const response = await imageGet("download_image", {
                image_url: file.dataURL,
              });
              const base64String = await blobToBase64(response);
              excalidrawAPI.addFiles([{ ...file, dataURL: base64String }]);
              return [key, { ...file, dataURL: base64String }];
            }
            return [key, file];
          })
        );

        const updatedData = {
          elements: excalidrawData?.elements,
          appState: {
            ...(excalidrawData.appState || {}),
          },
        };
        initialStatePromiseRef.current.promise.resolve(updatedData);

        excalidrawAPI.updateScene(updatedData);
        excalidrawAPI.scrollToContent();
        setTimeout((res) => {
          setExcalidrawLoading(false);
        }, 1000);
      } catch (error) {
        setExcalidrawLoading(false);
        console.error("Error updating Excalidraw data:", error);
      }
    };
    if (excalidrawData && excalidrawAPI) {
      updateData();
    } else {
      // if (excalidrawAPI && !excalidrawData) {
      //   setExcalidrawLoading(false);
      // }
      initialStatePromiseRef.current.promise.resolve({
        appState: { viewModeEnabled: true },
      });
      // setExcalidrawLoading(false);
    }
  }, [excalidrawData, excalidrawAPI]);

  const [projectFormValue, setProjectFormValue] = useState({
    project_name: "",
    project_description: "",
    channel: "",
    assign_to: [],
    internal_approver: [],
    client_approver: [],
    watch_list: [],
    start_date: "",
    due_date: "",
    required_sheet: "",
    type_id: "",
  });

  const [oldFormValue, setOldFormValue] = useState({
    project_name: "",
    project_description: "",
    channel: "",
    assign_to: [],
    internal_approver: [],
    client_approver: [],
    watch_list: [],
    start_date: "",
    due_date: "",
    required_sheet: "",
    type_id: "",
  });

  useEffect(() => {
    if (
      projectFormValue.project_name.length < 1 ||
      projectFormValue.project_name === oldFormValue.project_name
    ) {
      return;
    } else {
      checkProjectName(inputProjectName);
    }
  }, [debouncedProjectName]);

  useEffect(() => {
    const modalElement = document.getElementById("exampleModal");
    let modalInstance = null;

    if (modalElement) {
      modalInstance = new window.bootstrap.Modal(modalElement);
    }

    const handleModalClose = () => {
      if (modalInstance && modalInstance._isShown) {
        modalInstance.hide();
      }
    };

    handleModalClose();

    return () => {
      handleModalClose();
    };
  }, [location]);

  // useEffect(() => {
  //   const currentURL = location.search;
  //   // sessionStorage.removeItem("originalURL")
  //   // Retrieve the initially stored URL from sessionStorage
  //   const storedURL = localStorage.getItem("originalURL");
  //   console.log("storedURL", storedURL);
  //   console.log("currentURL", currentURL);
  //   // If there's no stored URL, save the current URL as the original one
  //   if (!storedURL) {
  //     localStorage.setItem("originalURL", currentURL);
  //   } else {
  //     // Compare the stored original URL with the current URL
  //     if (storedURL !== currentURL) {
  //       setIsValidURL(false); // Redirect to home if URL is modified
  //     } else {
  //       setIsValidURL(true);
  //     }
  //   }

  //   return () => {
  //     localStorage.removeItem("originalURL");
  //   }

  // }, [location.search])

  // Method to get the data of designs

  const getDesigns = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const projectIdFromUrl = urlParams.get("project_id");
    try {
      const data = await newGet(
        `design/getDesign?project_id=${
          createdProjectId || parseInt(projectIdFromUrl)
        }`
      ).then((data) => {
        if (data && data.status_code == 200) {
          data !== undefined && setdesignDetails(data?.result);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getPsdImages = async () => {
    try {
      // document/getDocument?project_id=165
      const data = await newGet(
        `document/getDocument?project_id=${createdProjectId}`
      );
      // data !== undefined && setdesignDetails(data?.result);
      if (data && (data.status_code == 200 || data.status_code == 201)) {
        setPsdData(data?.result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Remove the design version
  const removeDesignVersion = async (id) => {
    try {
      const data = await newDelete(`design/deleteDesign?design_id=${id}`);
      if (data.status_code === 200) {
        getDesigns();
        // showAlert("Design deleted successfully", "success")
        //    closeModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Method to handle "Submit for client approval" button
  const onHandleClientApproval = async () => {
    let formData = {};
    if (designDetails.length > 0) {
      const designIdArray = designDetails
        .map((design) => design.design_id)
        .join(",");
      formData = {
        design_id: `${designIdArray}`,
        stage_id: 8,
        project_id: createdProjectId,
        message: internalApprovalMsg,
      };
    }

    try {
      await newPut("design/updateDesignStage", formData).then((response) => {
        if (response.status_code == 200) {
          setIsApprovalBtnDisable(true);
          const modalElement = document.getElementById("exampleModalSubmit");
          const modalInstance =
            window.bootstrap.Modal.getInstance(modalElement);
          modalInstance.hide();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
  const formatUserArray = (users, list) => {
    if (users) {
      const idMatches = users.match(/id:([a-zA-Z0-9-]+)/g);
      if (idMatches) {
        const ids = idMatches.map((idStr) => idStr.split(":")[1]);
        const selectedUsers = list?.filter((user) =>
          ids.includes(user.account_login_id)
        );
        return selectedUsers;
      } else {
        console.error("No valid ids found in the input string.");
        return [];
      }
    } else {
      return null;
    }
  };

  const [errors, setErrors] = useState({
    project_name: "",
    project_description: "",
    channel: "",
    assign_to: "",
    internal_approver: "",
    client_approver: "",
    watch_list: "",
    start_date: "",
    due_date: "",
    required_sheet: "",
    type_id: "",
  });

  const getSheetList = async (designId) => {
    // setSheetListLoading(true)
    const response = await newGet_V3(
      `sheet/getSheetList?design_id=${designId}`
    );
    setSheetCount(response?.result?.length);
    dispatch(updateGroup({ newGroup: response?.result || [] }));
  };
  const getProjectDetail = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const projectIdFromUrl = urlParams.get("project_id");
    const storedSiteId = localStorage?.getItem("siteId");
    if ((createdProjectId || projectIdFromUrl) && (siteId || storedSiteId)) {
      try {
        setLoading(true);
        const data = await newGet(
          `project/getProjectDetails?project_id=${
            createdProjectId || parseInt(projectIdFromUrl)
          }&site_id=${siteId || parseInt(storedSiteId)}`
        );
        if (data?.result?.[0]) {
          // getSheetList(data?.result[0]?.design_id)
          getDesignDetail(data?.result[0]?.design_id);
        }
        if (
          data?.message?.[0].property_message === "PROJECT_NOT_FOUND" ||
          data?.message[0].property_message === "UNAUTHORIZED"
        ) {
          setError(true);
          return;
        }
        // setSheetId(data?.result[0]?.design_id);
        setDesignId(data?.result[0]?.design_id);
        setProjectDetails(data?.result[0]);
        const status = data?.result[0].status;
        setProjectStatus(parseInt(Object.keys(status)[0], 10));
        const assigneeList = makeUserArray(
          data?.result[0]?.assign_to_user_name
        );
        setAssignee(assigneeList);
        const internalApproverList = makeUserArray(
          data?.result[0]?.internal_approver_username
        );
        setInternalApprover(internalApproverList);
        const clientApproverList = makeUserArray(
          data?.result[0]?.client_approver_username
        );
        setClientApprover(clientApproverList);
        const watchListUsers = makeUserArray(data?.result[0]?.watchlist);
        setWatchListList(watchListUsers);
        const date = formatCreatedTimestamp(data?.result[0]?.due_date);
        setDueDate(date);
        setRequiredSheet(data?.result[0]?.required_sheet);
        setChannelName(data?.result[0]?.channel_name[0]?.site_name);
        setProjectName(data?.result[0]?.project_name);
        const projectType = data?.result[0]?.type;
        setProjectType(parseInt(Object.keys(projectType)[0]));

        localStorage.setItem(
          "siteId",
          data?.result[0]?.channel_name[0]?.site_id
        );

        setLoading(false);
        return data?.result?.[0] || null;
      } catch (error) {
        console.error("error:???????", error);
        setError(true);
        setLoading(false);
      }
    }
  };
  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
        const base64Image = reader.result;
        const img = new Image();
        img.src = base64Image;
        img.onload = () => {
          // setImageHeight(img.height)
        };
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };
  const getReferences = async () => {
    try {
      setRefListLoader(true);

      if (!createdProjectId) {
        return;
      }

      setLoading(true);
      await newGet(
        // `reference/fetchReferences?project_id=${createdProjectId}`
        `board/get?project_id=${createdProjectId}`
      ).then(async (data) => {
        if (data && data.status_code == 200) {
          setUploadedFiles(data.result.files);
          setReferenceList(data?.result.files);
          setReferencesList(data?.result);
          setMiroValue(data?.result.miro_link);
          setMiroLink(data?.result.miro_link);
          setBoardId(data?.result?.board_id);
          if (data?.result?.board_json) {
            const parsedData = JSON.parse(data.result.board_json);
            const fileKeys = Object.keys(parsedData.files);

            for (const key of fileKeys) {
              const file = parsedData.files[key];
              if (!file.dataURL.startsWith("data:")) {
                try {
                  const response = await fetch(file.dataURL);
                  const blob = await response.blob();
                  const base64String = await blobToBase64(blob);
                  if (excalidrawData) {
                    excalidrawAPI.addFiles([{ ...file, dataURL: base64String }]);
                  }
                } catch (error) {
                  const response = await imageGet("download_image", {
                    image_url: file.dataURL,
                  });
                  const base64String = await blobToBase64(response);
                  if (excalidrawData) {
                    excalidrawAPI.addFiles([{ ...file, dataURL: base64String }]);
                  }
                }
                // file.dataURL = base64String;
              }
            }

            const updatedData = {
              ...parsedData,
              appState: {
                ...(parsedData.appState || {}),
                viewModeEnabled: true,
              },
            };
            if (excalidrawAPI) {
              excalidrawAPI?.updateScene(updatedData);
              excalidrawAPI.scrollToContent();
              setExcalidrawLoading(false);
            } else {
              setExcalidrawData(updatedData);
            }
            setLoading(false);
          } else {
            setExcalidrawLoading(false);
          }
        }
      });

      setRefListLoader(false);
    } catch (error) {
      setLoading(false);
      console.error("error:???????", error);
      setRefListLoader(false);
    }
  };

  const checkProjectName = async (value) => {
    if (value.length < 1) {
      setErrors({
        ...errors,
        project_name: "It required at least 1",
      });
      return;
    }
    // Check if the first character is not a single-byte special character
    const firstCharSingleByteSpecialPattern =
      /^[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]/; // Range of single-byte special characters
    const firstCharIsAlphanumericOrDoubleByte =
      /^[a-zA-Z0-9\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]/;

    if (firstCharSingleByteSpecialPattern.test(value.charAt(0))) {
      setErrors({
        ...errors,
        project_name:
          t("firstCharacterCannotBeSingleByte"),
      });
      setIsCreateDisable(true);
      return;
    }

    if (!firstCharIsAlphanumericOrDoubleByte.test(value.charAt(0))) {
      setErrors({
        ...errors,
        project_name:
          t("firstCharacterMustBeAlphanumeric"),
      });
      setIsCreateDisable(true);
      return;
    }

    // Check if the % character is present anywhere in the string
    if (value.includes("%")) {
      setErrors({
        ...errors,
        project_name: t("%CharacterNotAllowed"),
      });
      setIsCreateDisable(true);
      return;
    }

    // Allow double-byte and special characters except for %
    const validCharactersPattern =
      /^[a-zA-Z0-9\s\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF!@#\$^\&\*\(\)\-_+=\[\]\{\};:'"<>,.?\/\\|`~]*$/;
    const testValue = validCharactersPattern.test(value);
    // const specialCharsPattern = /[^a-zA-Z0-9 _]/g;
    // const testValue = specialCharsPattern.test(value);
    // if (!testValue) {
    try {
      const response = await newGet_V4(
        `project/checkProjectName?project_name=${value}&site_id=${projectFormValue?.channel?.site_id}`
      );
      if (response?.status_code === 6021) {
        setErrors({
          ...errors,
          project_name: "Project name already exists !!!",
        });
        setIsCreateDisable(true);
      } else if (response?.status_code === 2005) {
        setErrors({ ...errors, project_name: "Project name is valid" });
        setIsCreateDisable(false);
      }
    } catch (error) {
      console.error("error:???????", error);
      setLoading(false);
    }
    // } else {
    //   setErrors({
    //     ...errors,
    //     project_name: "special characters are not allowed !!!",
    //   });
    //   setIsCreateDisable(true);
    // }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Clear the error for the current field
    setErrors({ ...errors, [name]: "" });

    if (name === "required_sheet") {
      // Allow only digits and no strings
      if (/^\d*$/.test(value.trim())) {
        setProjectFormValue({ ...projectFormValue, [name]: value.trim() });

        // Check after the user has entered a value (when it's a valid number)
        const numericValue = parseInt(value.trim(), 10);
        if (numericValue === 0) {
          setErrors({ ...errors, [name]: "Value cannot be 0!" });
        } else if (numericValue > 50) {
          setErrors({ ...errors, [name]: "Maximum sheet allowed is 50!" });
        }
      }
    } else {
      // Handle other input fields normally
      setProjectFormValue({ ...projectFormValue, [name]: value });
    }
  };

  const handleDateChange = (date, name) => {
    const formattedDate = date ? format(date, "MM-dd-yyyy") : null;
    setProjectFormValue({ ...projectFormValue, [name]: formattedDate });
    setErrors({ ...errors, [name]: "" });
  };

  const handleChannelChange = (site) => {
    setProjectFormValue({ ...projectFormValue, channel: site });
    setErrors({ ...errors, channel: "" });
  };

  const handleInternalApprovalMsg = (e) => {
    setInternalApprovalMsg(e.target.value);
  };

  const handlemultipleUsers = (e, options, fieldName) => {
    let newFormValue = { ...projectFormValue, [fieldName]: options };
    let newErrors = { ...errors, [fieldName]: "" };

    if (
      ["assign_to", "internal_approver", "client_approver"].includes(fieldName)
    ) {
      const combinedWatchList = [
        ...new Set([
          ...newFormValue.assign_to,
          ...newFormValue.internal_approver,
          ...newFormValue.client_approver,
        ]),
      ];
      const watchListUser = projectFormValue?.watch_list?.filter(
        (user) =>
          !combinedWatchList?.some(
            (combined) => combined.user_id === user.user_id
          )
      );
      newFormValue = { ...newFormValue, watch_list: watchListUser };
    }
    setProjectFormValue(newFormValue);
    setErrors(newErrors);
  };

  const openProjectForm = async () => {
    const details = await getProjectDetail();
    fetchUsers(details);
  };

  const fetchUsers = async (values = null) => {
    const urlParams = new URLSearchParams(window.location.search);
    const siteIdFromUrl = urlParams.get("site_id");

    const localProjectDetails = values || projectDetails;
    try {
      setLoading(true);
      const data = await newGet(
        `project/usersByRole?role_name=channel&site_id=${
          siteId || siteIdFromUrl
        }`
      );
      const dataClient = await newGet(
        `project/usersByRole?role_name=client&site_id=${
          siteId || siteIdFromUrl
        }`
      );
      const designerRole =  await newGet(
        `project/usersByRole?role_name=designer&site_id=${
          siteId || siteIdFromUrl
        }`
      );
      const allUsers = [...data?.result, ...dataClient?.result, ...designerRole?.result];
      setUserList(allUsers || []);
      setClientUserList(dataClient?.result || []);
      setClientManager(
        dataClient?.result?.filter(
          (user) => user.role_name === "CLIENT_MANAGER"
        )
      );
      const assignTo =
        formatUserArray(
          localProjectDetails?.assign_to_user_name || "",
          designerRole?.result
        ) || [];
      const internalApprover =
        formatUserArray(
          localProjectDetails?.approver_username || "",
          data?.result
        ) || [];
      const clientApprover =
        formatUserArray(
          localProjectDetails?.approver_username || "",
          dataClient?.result
        ) || [];

      const watchList =
        formatUserArray(localProjectDetails?.watchlist || "", allUsers) || [];

      setProjectFormValue({
        project_name: localProjectDetails.project_name || "",
        project_description: localProjectDetails.project_desc || "",
        channel: localProjectDetails.channel_name[0],
        assign_to: assignTo,
        internal_approver: internalApprover,
        client_approver: clientApprover,
        watch_list: watchList,
        start_date: formatDate(localProjectDetails.start_date || ""),
        due_date: formatDate(localProjectDetails.due_date || ""),
        required_sheet: localProjectDetails?.required_sheet
          ? localProjectDetails?.required_sheet
          : 0,
        type_id: projectType,
      });

      setOldFormValue({
        project_name: localProjectDetails.project_name || "",
        project_description: localProjectDetails.project_desc || "",
        channel: localProjectDetails.channel_name[0],
        assign_to: assignTo,
        internal_approver: internalApprover,
        client_approver: clientApprover,
        watch_list: watchList,
        start_date: formatDate(localProjectDetails.start_date || ""),
        due_date: formatDate(localProjectDetails.due_date || ""),
        required_sheet: localProjectDetails?.required_sheet
          ? localProjectDetails?.required_sheet
          : 0,
        type_id: projectType,
      });

      setProjectId(localProjectDetails.project_id);
      if (clientApprover?.length > 0) {
        setChecked(true);
      }
      setLoading(false);

      // getProjectDetail(data?.result || []);
    } catch (error) {
      console.error("error:???????", error);
      setLoading(false);
    }
  };

  const formatDate = (date1) => {
    const datePart = date1?.slice(0, 10);
    const [year, month, day] = datePart?.split("-");
    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;
  };

  const formatDueDate = (date1) => {
    const datePart = date1?.slice(0, 10);
    const [year, month, day] = datePart?.split("-");
    const formattedDate = `${year}/${month}/${day}`;
    return formattedDate;
  };

  const formatCreatedTimestamp = (timestamp) => {
    if (timestamp) {
      const options = { year: "numeric", month: "short", day: "2-digit" };
      const date = new Date(timestamp);
      return date.toLocaleDateString("en-US", options);
    } else {
      return "Not specified";
    }
  };

  // const handleClientSubmit = async () => {
  //   try {
  //     const data = await newPut(`design/shareWithClient`, {
  //       project_id: createdProjectId
  //     });
  //     if (data.status_code == 200 || data.status_code == 201) {
  //       getDesigns();
  //       showAlert("Design shared successfully", "success")
  //     }
  //   } catch (err) {
  //     console.log('err: ', err)
  //   }
  // }

  const handleClientApproveReturn = async (status) => {
    try {
      const data = await newPut(`design/approveReturnDesign`, {
        project_id: createdProjectId,
        design_id: 189,
        version_number: 1,
        status: status,
      });
      if (data.status_code == 200 || data.status_code == 201) {
        showAlert(`Design ${status} successfully`, "success");
        // showAlert(i18n.t(data?.message[0]?.property_message), 'success');
      }
    } catch (err) {
      console.error("err: ", err);
    }
  };

  const handleSubmit = async () => {
    if (!isProjectActive()) {
      return;
    }
    const requiredFields = [
      { key: "project_name", message: "Project name is required." },
      {
        key: "project_description",
        message: "Project description is required.",
      },
      { key: "channel", message: "Channel name is required." },
      // {
      //   key: "assign_to",
      //   message: "Assign to name is required.",
      //   isArray: true,
      // },
      {
        key: "internal_approver",
        message: t("internalApproverRequired"),
        isArray: true,
      },
      {
        key: "client_approver",
        message: t("clientRequired"),
        isArray: true,
      },

      { key: "due_date", message: "Due date is required." },
      { key: "required_sheet", message: "No. of sheet is required." },
    ];

    let formIsValid = true;
    const newErrors = { ...errors };

    requiredFields.forEach((field) => {
      if (
        field.isArray
          ? projectFormValue[field.key].length === 0
          : !projectFormValue[field.key]
      ) {
        if (field.key === "client_approver") {
          if (
            projectFormValue?.type_id === 2 &&
            projectFormValue[field.key].length === 0
          ) {
            newErrors[field.key] = field.message;
            formIsValid = false;
          }
        } else if (field.key === "internal_approver") {
          if (
            projectFormValue?.type_id === 1 &&
            projectFormValue[field.key].length === 0
          ) {
            newErrors[field.key] = field.message;
            formIsValid = false;
          }
        } else {
          newErrors[field.key] = field.message;
          formIsValid = false;
        }
      } else if (
        field.key === "required_sheet" &&
        (!projectFormValue[field.key] || projectFormValue[field.key] == 0)
      ) {
        newErrors[field.key] =
          projectFormValue[field.key] == 0
            ? "Required sheet can not be zero"
            : field.message;
        formIsValid = false;
      } else {
        if (field.key == "project_name") {
          if (projectFormValue[field.key].length < 1) {
            newErrors[field.key] = "It required at least 1 characters";
            formIsValid = false;
            return;
          }
        } else {
          newErrors[field.key] = "";
        }
      }
    });
    if (!formIsValid) {
      setErrors(newErrors);
      return;
    }

    const getChangedValues = (newForm, oldForm) => {
      let changedValues = {};

      Object.keys(newForm).forEach((key) => {
        if (JSON.stringify(newForm[key]) !== JSON.stringify(oldForm[key])) {
          changedValues[key] = newForm[key];
        }
      });

      return changedValues;
    };

    const changedFormData = getChangedValues(projectFormValue, oldFormValue);

    let approver_id = "";
    if (projectFormValue?.type_id === 1) {
      approver_id = projectFormValue?.internal_approver
        ?.map((user) => user?.account_login_id)
        .join(",");
    } else if (projectFormValue?.type_id === 2) {
      approver_id = projectFormValue?.client_approver
        ?.map((user) => user?.account_login_id)
        .join(",");
    }

    const formData = {
      project_id: projectId,
      site_id: projectFormValue?.channel?.site_id?.toString(),
      ...(changedFormData.project_name && {
        project_name: changedFormData.project_name,
      }),
      ...(changedFormData.project_description && {
        project_desc: changedFormData.project_description,
      }),
      // ...(changedFormData.channel && { site_id: (changedFormData.channel?.site_id).toString() }),
      ...(changedFormData.assign_to && {
        assign_to_user_id: changedFormData.assign_to
          ?.map((user) => user?.account_login_id)
          .join(","),
      }),
      ...(changedFormData.internal_approver && {
        internal_approver_user_id: changedFormData.internal_approver
          ?.map((user) => user?.account_login_id)
          .join(","),
      }),
      ...(changedFormData.client_approver && {
        client_approver_user_id: changedFormData.client_approver
          ?.map((user) => user?.account_login_id)
          .join(","),
      }),
      ...(changedFormData.watch_list && {
        watchlist: changedFormData.watch_list
          ?.map((user) => user?.account_login_id)
          .join(","),
      }),
      // start_date: projectFormValue?.start_date,
      ...(changedFormData.start_date && {
        start_date: projectFormValue?.start_date,
      }),
      ...(changedFormData.due_date && { due_date: changedFormData.due_date }),
      ...(changedFormData.required_sheet && {
        required_sheet: parseInt(changedFormData.required_sheet),
      }),
      approver_id: approver_id,
      type_id: projectFormValue?.type_id,
    };

    try {
      if (
        Object.keys(changedFormData).length === 0
        // !changedFormData.project_name &&
        // !changedFormData.project_description &&
        // !changedFormData.assign_to &&
        // !changedFormData.internal_approver &&
        // !changedFormData.client_approver &&
        // !changedFormData.watch_list &&
        // !changedFormData.due_date &&
        // !changedFormData.required_sheet
      ) {
        showAlert(t("UPDATE_FAILED"), "success");
        closeModal();
        return;
      }
      setLoading(true);
      const data = await newPut_V4(`project`, formData);
      if (data.status_code == 2004) {
        // showAlert("Project Updated successfully", "success");
        showAlert(i18n.t(data?.message[0]?.property_message), "success");
        closeModal();
      }
      setLoading(false);
      getProjectDetail();
    } catch (error) {
      console.error("error:", error);
      setLoading(false);
    }
  };

  const closeModal = () => {
    const modalElement = document.getElementById("exampleModal");
    const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
    modalInstance.hide();
  };
  const handleClearForm = () => {
    setProjectFormValue({
      project_name: "",
      project_description: "",
      channel: "",
      required_sheet: "",
      assign_to: [],
      internal_approver: [],
      client_approver: [],
      // watchlist: false,
      watch_list: [],
      start_date: "",
      due_date: "",
      type_id: "",
    });
    setErrors({
      project_name: "",
      project_description: "",
      channel: "",
      required_sheet: "",
      assign_to: "",
      // project_type: "",
      internal_approver: "",
      client_approver: "",
      watch_list: "",
      start_date: "",
      due_date: "",
      type_id: "",
    });
    closeModal();
  };
  const handleGoBack = () => {
    navigate("/projects");
  };

  function makeUserArray(input) {
    if (input) {
      const objectsArray = input.split("},").map((item) => {
        // Add back the closing curly brace that was removed during the split (if needed)
        item = item.trim().endsWith("}") ? item : item + "}";

        // Extract the id and username values
        const idMatch = item.match(/id:([a-f0-9\-]+)/);
        const usernameMatch = item.match(/username:([^,}]+)/);

        // Return the object in proper format
        return {
          id: idMatch ? idMatch[1] : null,
          username: usernameMatch ? usernameMatch[1].trim() : null,
        };
      });
      return objectsArray;
    } else {
      return;
    }
  }

  const makeSiteObject = (str) => {
    if (!str) {
      return null;
    }
    const jsonString = str?.replace(/(\w+):([^,}]+)/g, (match, key, value) => {
      // If the value is a number, keep it as is; otherwise, wrap it in quotes
      const formattedValue = isNaN(value) ? `"${value.trim()}"` : value.trim();
      return `"${key}":${formattedValue}`;
    });

    return JSON.parse(jsonString);
  };

  const handelVariant = () => {
    // navigate('/newimage-annotation', {
    //   state: {
    //     selectedImages: "",
    //     createdProjectId: createdProjectId
    //   },
    // });
  };

  const handleStatusChange = async (e) => {
    const urlParams = new URLSearchParams(window.location.search);
    const URLParamsSiteId = urlParams.get("site_id");
    const formData = {
      project_id: parseInt(createdProjectId, 10),
      site_id: siteId?.toString() || URLParamsSiteId,
      status_id: e.target.value,
    };

    if (e.target.value === 3) {
      cancelConfirmation();
      return;
    }
    try {
      const data = await newPut(`project`, formData);
      if (data.status_code == 200) {
      }
    } catch (error) {
      console.error(error);
    }
    setProjectStatus(e.target.value);
  };

  const hadleWorkSpace = (data) => {
    if (loading) return;
    const urlParams = new URLSearchParams(window.location.search);
    const storedSiteId = urlParams.get("site_id");
    const createdProjectId = urlParams.get("project_id");

    navigate(
      `/reference-details?project_id=${createdProjectId}&channel_id=${storedSiteId}&project_name=${encodeURIComponent(
        projectName
      )}&channel_name=${encodeURIComponent(channelName)}`,
      {
        state: {
          createdProjectId: createdProjectId,
          channelId: storedSiteId,
          projectName: projectName,
          channelName: channelName,
          projectStatus: projectStatus,
        },
      }
    );
  };

  const handleDeleteReference = async (ref) => {
    const confirmation = await showConfirmationDialog(
      `${t("projectDeleteMsg")}`,
      "",
      "warning",
      `${t("no")}`,
      `${t("yes")}`
    );
    try {
      if (confirmation) {
        const data = await newDelete(
          `reference/delete?reference_id=${ref.reference_id}`
        );
        if (data.status_code == 200) {
          // showAlert("Reference Deleted successfully", "success")
          showAlert(i18n.t(data?.message[0]?.property_message), "success");
          getReferences();
        }
      }
    } catch (error) {
      console.error("error:???????", error);
    }
  };

  const navigateToVisionStudio = () => {
    if (loading) return;
    const urlParams = new URLSearchParams(window.location.search);
    const storedSiteId = urlParams.get("site_id");
    const createdProjectId = urlParams.get("project_id");

    let data = {
      projectName: projectName,
      // sheet: requiredSheet,
      channelName: channelName,
      createdProjectId: createdProjectId,
      projectStatus: projectStatus,
    };
    navigate(
      `/vision-studio?project_name=${encodeURIComponent(
        projectName
      )}&channel_name=${encodeURIComponent(
        channelName
      )}&project_id=${createdProjectId}&design_id=${designId}&site_id=${storedSiteId}`,
      { state: data }
    );
  };

  useEffect(() => {
    // getProjectStatus()
    const urlParams = new URLSearchParams(window.location.search);
    const projectIdFromUrl = urlParams.get("project_id");
    const siteIdFromUrl = urlParams.get("site_id");
    const storedSiteId = localStorage?.getItem("siteId");
    if (projectIdFromUrl) {
      createdProjectId = projectIdFromUrl;
    }
    if (siteIdFromUrl) {
      siteId = siteIdFromUrl;
    }
    getReferences();
    // if ((createdProjectId || projectIdFromUrl) && (siteId || storedSiteId)) {
    if (createdProjectId || projectIdFromUrl) {
      getProjectDetail();
      getPsdImages();
      // getProjectStatus();
      // getDesigns()
      // if (requiredSheet === designDetails?.length) {
      //   setIsApprovalBtnDisable(false)
      // }
      // else {
      //   setIsApprovalBtnDisable(true)
      // }
    } else {
      navigate("/dashboard");
    }
  }, []);

  const getDesignDetail = async (design_id) => {
    try {
      const response = await newGet_V4(
        `design/getDesignDetail?design_id=${design_id}`
      );
      if (response?.status_code === 200) {
        setVisionBoardData(response?.result?.sheets);
      }
      dispatch(
        initializeCanvas({
          id: design_id,
          sheets: response?.result?.sheets,
        })
      );
      setSheetCount(response?.result?.sheets.length);
      // setSheetListLoading(false)
    } catch (error) {
      console.error("Error fetching design details:", error);
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 5:
        return t("STATUS_COMPLETED");
      // case 3: return t("STATUS_CANCELLED");
      default:
        return "";
    }
  };

  const getMenuItemContent = (value, label) => {
    const isDisabled = checkMenuItemDisabled(value);
    // disableStatus?.[projectStatus]?.[value];
    // console.log("isDisabled ", isDisabled)
    const tooltipContent = t(`CannotChangeStatusTo`) + [label];

    return tooltipContent && isDisabled ? (
      <Tooltip
        title={<div>{tooltipContent}</div>}
        arrow
        placement="right"
        PopperProps={{ style: { zIndex: 3000, pointerEvents: "auto" } }}
      >
        <div
          style={{ pointerEvents: "auto" }}
          onClick={(e) => e.stopPropagation()}
        >
          {label}
        </div>
      </Tooltip>
    ) : (
      label
    );
  };

  const isSelectDisabled = (
    userSettings,
    hasPermission,
    userRole,
    projectStatus
  ) => {
    return (
      userSettings?.permissions && !hasPermission(17) && userRole !== "Creator"
      //   ||
      // projectStatus === 5
    );
  };

  return (
    <>
      {error || !isValidURL ? <CountdownPopup /> : null}
      {!initialLoading && (
        <div style={{ backgroundColor: "#fff" }}>
          <Header screen="reference" />
        </div>
      )}
      <div className="bg_color_gray">
        <div className="refrencemainHeader">
          <div className="container-fluid">
            <div className="refrenceHeader">
              <div className="headerLeftWrap">
                <div onClick={handleGoBack}>
                  <img
                    src={ArrowBack}
                    className=""
                    alt="ArrowBack"
                    style={{ cursor: "pointer" }}
                  />
                </div>
                {/* <Tooltip
                  title={
                    <div>
                      <span>{projectName}</span>
                    </div>
                  }
                  slotProps={{
                    popper: {
                      modifiers: [
                        { name: "offset", options: { offset: [0, -12] } },
                      ],
                    },
                  }}
                  placement="bottom-start"
                  arrow
                >
                  {projectName ? (
                    <div
                      className="font-noto-sans"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontWeight: "bold",
                        maxWidth: "100%",
                      }}
                    >
                      {projectName?.length > 15
                        ? `${projectName?.slice(0, 15)}...`
                        : projectName + " /" || "---"}
                    </div>
                  ) : (
                    <Stack>
                      <Skeleton
                        animation="wave"
                        variant="text"
                        width={80}
                        height={36}
                      />
                    </Stack>
                  )}
                </Tooltip> */}
                <Tooltip
                  title={
                    <div>
                      <span>
                        {channelName} &nbsp;/&nbsp; {projectName}
                      </span>
                    </div>
                  }
                  slotProps={{
                    popper: {
                      modifiers: [
                        { name: "offset", options: { offset: [0, -12] } },
                      ],
                    },
                  }}
                  placement="bottom-start"
                  arrow
                >
                  {channelName && projectName ? (
                    <div
                      className="font-noto-sans"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontWeight: "bold",
                        maxWidth: "100%",
                      }}
                    >
                      {channelName.length > 5
                        ? `${channelName.slice(0, 5)}...`
                        : channelName}
                      <span style={{ margin: "0 5px" }}>/</span>
                      {projectName.length > 5
                        ? `${projectName.slice(0, 5)}...`
                        : projectName}
                    </div>
                  ) : (
                    <Stack>
                      <Skeleton
                        animation="wave"
                        variant="text"
                        width={120}
                        height={36}
                      />
                    </Stack>
                  )}
                </Tooltip>

                {!loading && (
                  <div className="mb-1 header-selector">
                    <FormControl>
                      <Tooltip
                        title=""
                        slotProps={{
                          popper: {
                            modifiers: [
                              { name: "offset", options: { offset: [0, -7] } },
                            ],
                          },
                        }}
                        placement="bottom"
                        arrow
                      >
                        <div
                          className="statusSelect"
                          style={{
                            backgroundColor:
                              projectStatus === 1
                                ? "#E6E6E6" // Not Started
                                : projectStatus === 2
                                  ? "#EFF3FF" // In Progress
                                  : projectStatus === 3
                                    ? "#E74733" // Cancelled (FIXED)
                                    : projectStatus === 4
                                      ? "#FAF1E6" // Approved
                                      : projectStatus === 5
                                        ? "#E6F4ED" // Completed
                                        : projectStatus === 6
                                          ? "#FAF1E6" // Wireframe in progress
                                          : projectStatus === 7
                                            ? "#CCCCCC" // Waiting for wireframe confirmation
                                            : projectStatus === 8
                                              ? "#FFEFBF" // Design in progress
                                              : projectStatus === 9
                                                ? "#e4e4de" // Waiting for design confirmation
                                                : projectStatus === 10
                                                  ? "#FCE8E6" // Pending
                                                  : "#ffffff", // Default
                            color: "red",
                            // padding: "4px 10px",
                            borderRadius: "8px",
                          }}
                        >
                          <Select
                            value={projectStatus}
                            name="channel"
                            onChange={handleStatusChange}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            className="w-100 border-none text-white font-noto-sans fs-14 fw-500 gap-3"
                            disabled={isSelectDisabled(
                              userSettings,
                              hasPermission,
                              userRole,
                              projectStatus
                            )}
                            open={isDropdownOpen}
                            onOpen={handleDropdownOpen}
                            onClose={handleDropdownClose}
                            // style={{marginInline:"7px", marginTop:"-1px"}}
                            MenuProps={{
                              disablePortal: true,
                              PaperProps: {
                                sx: {
                                  //backgroundColor: "#000000 !important", // Darker background color
                                  "& .MuiMenuItem-root": {
                                    transition:
                                      "background-color 0.3s ease-in-out",
                                    marginBottom: "5px", // Adds spacing between menu items
                                  },
                                },
                              },
                            }}
                          >
                            {statusOptions.map(
                              ({
                                value,
                                disabled,
                                backgroundColor,
                                hoverColor,
                                width,
                                icon,
                                text,
                                statusLabel,
                                color = "#282829",
                              }) => (
                                <MenuItem
                                  key={value + text}
                                  value={value}
                                  disabled={disabled}
                                  sx={{
                                    backgroundColor: `${backgroundColor} !important`,
                                    color: `${color} !important`,
                                    borderBottom: "1px solid #ddd",
                                    fontFamily: "Noto Sans",
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    letterSpacing: "0%",
                                    marginBottom: "1px",
                                    marginLeft: "2px",
                                    marginRight: "4px ",
                                    borderRadius: "8px",
                                    padding: "4px 8px",
                                    width: width,
                                    height: "28px",
                                    "&:hover": {
                                      backgroundColor: `${hoverColor} !important`,
                                    },
                                  }}
                                >
                                  <img
                                    src={icon}
                                    alt={text}
                                    width="16"
                                    height="16"
                                    style={{
                                      marginRight: "4px",
                                      flexShrink: 0,
                                    }}
                                  />
                                  {getMenuItemContent(value, text)}
                                </MenuItem>
                              )
                            )}

                            {/* <MenuItem
                              value={1}
                              disabled={checkMenuItemDisabled(1)}
                              sx={{
                                backgroundColor: "#E6E6E6 !important",
                                color: "#282829 !important",
                                borderBottom: "1px solid #ddd",
                                fontFamily: "Noto Sans",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: "0%",
                                marginBottom: "1px",
                                borderRadius: "8px",
                                borderWidth: "1px",
                                paddingTop: "4px",
                                paddingRight: "4px", // Fixed to match requirement
                                paddingBottom: "4px", // Fixed to match requirement
                                paddingLeft: "8px",
                                gap: "8px", // Fixed to match requirement
                                border: "1px solid #2828290D",
                                marginLeft: "2px",
                                marginRight: "4px ",
                                width: "138px",
                                height: "28px", // Added to match requirement
                                left: "10px",
                                "&:hover": {
                                  backgroundColor: "#F2F2F2 !important", // Lighter shade on hover
                                },
                              }}
                            >
                              <img
                                src={NotStarted}
                                alt="Not Started"
                                width="16"
                                height="16"
                                style={{ marginRight: "4px", flexShrink: 0 }}
                              />
                              {getMenuItemContent(1, t("notStarted"))}
                            </MenuItem>

                            <MenuItem
                              value={2}
                              disabled={checkMenuItemDisabled(1)}
                              sx={{
                                backgroundColor: "#FAF1E6 !important",
                                color: "#282829 !important",
                                borderBottom: "1px solid #ddd",
                                fontFamily: "Noto Sans",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: "0%",
                                marginBottom: "1px",
                                borderRadius: "8px",
                                borderWidth: "1px",
                                paddingTop: "4px",
                                paddingRight: "4px",
                                paddingBottom: "4px",
                                paddingLeft: "8px",
                                gap: "8px",
                                border: "1px solid #2828290D",
                                width: "200px",
                                marginLeft: "2px",
                                marginRight: "0px",
                                left: "10px",
                                height: "28px", // Added to match requirement
                                "&:hover": {
                                  backgroundColor: "#E5D1C0  !important", // Lighter shade on hover
                                },
                              }}
                            >
                              <img
                                src={WireFrameInProgress}
                                alt="Wire Frame in Progress"
                                width="16"
                                height="16"
                                style={{ marginRight: "4px", flexShrink: 0 }}
                              />
                              {getMenuItemContent(1, t("WireframeInProgress"))}
                            </MenuItem>

                            <MenuItem
                              value={1}
                              disabled={checkMenuItemDisabled(1)}
                              sx={{
                                backgroundColor: "#CCCCCC !important",
                                color: "#282829 !important",
                                borderBottom: "1px solid #ddd",
                                fontFamily: "Noto Sans",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: "0%",
                                marginBottom: "1px",
                                borderRadius: "8px",
                                borderWidth: "1px",
                                paddingTop: "4px",
                                paddingRight: "4px",
                                paddingBottom: "4px",
                                paddingLeft: "8px",
                                gap: "8px",
                                border: "1px solid #2828290D",
                                width: "294px",
                                left: "10px",
                                height: "28px",
                                "&:hover": {
                                  backgroundColor: "#F0F0F0 !important", // Even lighter gray on hover
                                },
                              }}
                            >
                              <img
                                src={WireFrameConfirmation}
                                alt="Wire Frame Confirmation"
                                width="16"
                                height="16"
                                style={{ marginRight: "4px", flexShrink: 0 }}
                              />
                              <span style={{ flex: 1 }}>
                                {getMenuItemContent(
                                  1,
                                  t("WaitingForWireframeConfirmation")
                                )}
                              </span>
                            </MenuItem>

                            <MenuItem
                              value={1}
                              disabled={checkMenuItemDisabled(1)}
                              sx={{
                                backgroundColor: "#FFEFBF !important",
                                color: "#282829 !important",
                                borderBottom: "1px solid #ddd",
                                fontFamily: "Noto Sans",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: "0%",
                                marginBottom: "1px",
                                borderRadius: "8px",
                                borderWidth: "1px",
                                paddingTop: "4px",
                                paddingRight: "4px",
                                paddingBottom: "4px",
                                paddingLeft: "8px",
                                gap: "8px",
                                border: "1px solid #2828290D",
                                width: "174px",
                                left: "10px",
                                height: "28px",
                                "&:hover": {
                                  backgroundColor: "#FFFDE7 !important", // Even lighter yellow on hover
                                },
                              }}
                            >
                              <img
                                src={designInProgress}
                                alt="Design in Progress"
                                width="16"
                                height="16"
                                style={{ marginRight: "4px" }}
                              />
                              {getMenuItemContent(1, t("designInProgress"))}
                            </MenuItem>

                            <MenuItem
                              value={1}
                              disabled={checkMenuItemDisabled(1)}
                              sx={{
                                backgroundColor: "#e4e4de",
                                color: "#282829 !important",
                                borderBottom: "1px solid #ddd",
                                fontFamily: "Noto Sans",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: "0%",
                                marginBottom: "1px",
                                borderRadius: "8px",
                                borderWidth: "1px",
                                paddingTop: "4px",
                                paddingRight: "4px",
                                paddingBottom: "4px",
                                paddingLeft: "8px",
                                gap: "8px",
                                border: "1px solid #2828290D",
                                width: "267px",
                                left: "10px",
                                height: "28px",
                                "&:hover": {
                                  backgroundColor: "#E0E0DA !important", // Slightly lighter on hover
                                },
                              }}
                            >
                              <img
                                src={designConfirmation}
                                alt="Waiting for Design Confirmation"
                                width="16"
                                height="16"
                                style={{ marginRight: "4px" }}
                              />
                              {getMenuItemContent(
                                1,
                                t("WaitingForDesignConfirmation")
                              )}
                            </MenuItem>

                            <MenuItem
                              value={2}
                              disabled={checkMenuItemDisabled(2)}
                              sx={{
                                backgroundColor: "#EFF3FF !important",
                                color: "#282829 !important",
                                borderBottom: "1px solid #ddd",
                                fontFamily: "Noto Sans",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: "0%",
                                marginBottom: "1px",
                                borderRadius: "8px",
                                borderWidth: "1px",
                                paddingTop: "4px",
                                paddingRight: "4px",
                                paddingBottom: "4px",
                                paddingLeft: "8px",
                                gap: "8px",
                                border: "1px solid #2828290D",
                                width: "124px",
                                left: "10px",
                                height: "28px",
                                "&:hover": {
                                  backgroundColor: "#A9B5FF !important", // Lighter blue on hover
                                },
                              }}
                            >
                              <img
                                src={InProgress}
                                alt="In Progress"
                                width="16"
                                height="16"
                                style={{ marginRight: "4px" }}
                              />
                              {t("inProgress")}
                            </MenuItem>

                            <MenuItem
                              value={4}
                              disabled={checkMenuItemDisabled(4)}
                              sx={{
                                backgroundColor: "#4C58FF !important",
                                color: "#FFFFFF !important",
                                borderBottom: "1px solid #ddd",
                                fontFamily: "Noto Sans",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: "0%",
                                marginBottom: "1px",
                                borderRadius: "8px",
                                borderWidth: "1px",
                                paddingTop: "4px",
                                paddingRight: "4px",
                                paddingBottom: "4px",
                                paddingLeft: "8px",
                                gap: "8px",
                                border: "1px solid #2828290D",
                                width: "113px",
                                left: "10px",
                                height: "28px",
                                "&:hover": {
                                  backgroundColor: "#7A88FF  !important", // Darker blue on hover
                                },
                              }}
                            >
                              <img
                                src={ApprovedNew}
                                alt="Approved"
                                width="16"
                                height="16"
                                style={{ marginRight: "4px" }}
                              />
                              {t("approved")}
                            </MenuItem>

                            <MenuItem
                              value={5}
                              disabled={checkMenuItemDisabled(5)}
                              sx={{
                                backgroundColor: "#E6F4ED !important",
                                color: "#282829 !important",
                                fontFamily: "Noto Sans",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: "0%",
                                borderRadius: "8px",
                                borderWidth: "1px",
                                paddingTop: "4px",
                                paddingRight: "4px",
                                paddingBottom: "4px",
                                paddingLeft: "8px",
                                gap: "8px",
                                border: "1px solid #2828290D",
                                width: "121px",
                                left: "10px",
                                height: "28px",
                                "&:hover": {
                                  backgroundColor: "#A5BFB5 !important", // Lighter green on hover
                                },
                              }}
                            >
                              <img
                                src={Completed}
                                alt="Completed"
                                width="16"
                                height="16"
                                style={{ marginRight: "4px" }}
                              />
                              {t("completed")}
                            </MenuItem>

                            <MenuItem
                              value={3}
                              disabled={checkMenuItemDisabled(3)}
                              sx={{
                                backgroundColor: "#FCE8E6 !important",
                                color: "#282829 !important",
                                borderBottom: "1px solid #ddd",
                                fontFamily: "Noto Sans",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: "0%",
                                marginBottom: "1px",
                                borderRadius: "8px",
                                borderWidth: "1px",
                                paddingTop: "4px",
                                paddingRight: "4px",
                                paddingBottom: "4px",
                                paddingLeft: "8px",
                                gap: "8px",
                                border: "1px solid #2828290D",
                                width: "100px",
                                left: "10px",
                                height: "28px",
                                "&:hover": {
                                  backgroundColor: "#C9AEAC!important", // Lighter pink on hover
                                },
                              }}
                            >
                              <img
                                src={Pending}
                                alt="pending"
                                width="16"
                                height="16"
                                style={{ marginRight: "4px" }}
                              />
                              {getMenuItemContent(3, t("pending"))}
                            </MenuItem>

                            <MenuItem
                              value={3}
                              disabled={checkMenuItemDisabled(3)}
                              sx={{
                                backgroundColor: "#E74733 !important",
                                color: "#FFFFFF !important",
                                borderBottom: "1px solid #ddd",
                                fontFamily: "Noto Sans",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: "0%",
                                marginBottom: "1px",
                                borderRadius: "8px",
                                borderWidth: "1px",
                                paddingTop: "4px",
                                paddingRight: "4px",
                                paddingBottom: "4px",
                                paddingLeft: "8px",
                                gap: "8px",
                                border: "1px solid #2828290D",
                                width: "112px",
                                left: "10px",
                                height: "28px",
                                "&:hover": {
                                  backgroundColor: "#FFA092!important", // Darker red on hover
                                },
                              }}
                            >
                              <img
                                src={Cancelled}
                                alt="Cancelled"
                                width="16"
                                height="16"
                                style={{ marginRight: "4px" }}
                              />
                              {getMenuItemContent(3, t("cancelled"))}
                            </MenuItem> */}
                          </Select>
                        </div>
                      </Tooltip>
                    </FormControl>
                  </div>
                )}
              </div>
              {/* <div className="headerMiddle">
                <div className="sheetbox">
                  <h5 className="sheetbox-title">{t("channel")}</h5>
                  <p className="sheetbox-content">{channelName || "-"}</p>
                </div>
                <div className="sheetbox">
                  <h5 className="sheetbox-title">{t("assignee")}</h5>
                  <div className="sheetbox-content">
                    {assignee?.length > 0
                      ? assignee.map((name) => (
                          <p key={name?.id}>{name?.username}</p>
                        ))
                      : "-"}
                  </div>
                </div>
                <div className="sheetbox">
                  <h5 className="sheetbox-title">{t("requiredSheet")}</h5>
                  <p className="sheetbox-content">{requiredSheet || "-"}</p>
                </div>
              </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  maxWidth: "600px",
                  margin: "0 auto",
                  padding: "10px 0",
                  position: "relative",
                  marginLeft: "-7px",
                }}
              >
                {/* Channel */}
                <div
                  style={{
                    width: "150px",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  <h5
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      marginBottom: "4px",
                      color: "#312EFF",
                    }}
                  >
                    {t("channel")}
                  </h5>
                  <p style={{ fontSize: "13px", color: "#333", margin: "0" }}>
                    {channelName || "-"}
                  </p>
                  {/* Line */}
                  <div
                    style={{
                      backgroundColor: "#e6e6e6",
                      content: '""',
                      height: "30px",
                      width: "1px",
                      position: "absolute",
                      top: "50%",
                      right: "-15px",
                      transform: "translateY(-50%)",
                    }}
                  ></div>
                </div>

                {/* Assignee */}
                <div
                  style={{
                    width: "150px",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  <h5
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      marginBottom: "4px",
                      color: "#312EFF",
                    }}
                  >
                    {t("assignee")}
                  </h5>
                  <div style={{ fontSize: "13px", color: "#333", margin: "0" }}>
                    {assignee?.length > 0
                      ? assignee.map((name) => (
                          <p key={name?.id} style={{ margin: "0" }}>
                            {name?.username}
                          </p>
                        ))
                      : "-"}
                  </div>
                  {/* Line */}
                  <div
                    style={{
                      backgroundColor: "#e6e6e6",
                      content: '""',
                      height: "30px",
                      width: "1px",
                      position: "absolute",
                      top: "50%",
                      right: "-15px",
                      transform: "translateY(-50%)",
                    }}
                  ></div>
                </div>

                {/* Required Sheet */}
                <div style={{ width: "150px", textAlign: "center" }}>
                  <h5
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      marginBottom: "4px",
                      color: "#312EFF",
                    }}
                  >
                    {t("requiredSheet")}
                  </h5>
                  <p style={{ fontSize: "13px", color: "#333", margin: "0" }}>
                    {requiredSheet || "-"}
                  </p>
                </div>
              </div>

              <div className="headerRight">
                {userSettings?.permissions && hasPermission(11) && (
                  <button
                    onClick={openProjectForm}
                    className="cursor_pointer bg-transparent px-2 py-1 rounded-pill font-noto-sans "
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    disabled={userSettings?.permissions && !hasPermission(11)}
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      width: "160px",
                    }}
                  >
                    <ProjectIcon /> {t("projectDetails")}
                  </button>
                )}
                <div
                  className="modal fade font-noto-sans"
                  id="exampleModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-header border-0 pb-0">
                        <h5
                          className="modal-title fw-bold"
                          id="exampleModalLabel"
                        >
                          {t("projectDetails")}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={handleClearForm}
                        ></button>
                      </div>
                      <CreateProjectForm
                        handleChange={handleChange}
                        handlemultipleUsers={handlemultipleUsers}
                        projectFormValue={projectFormValue}
                        setProjectFormValue={setProjectFormValue}
                        errors={errors}
                        userList={userList}
                        allUsers={allUsers}
                        clientManager={clientManager}
                        handleChannelChange={handleChannelChange}
                        loading={loading}
                        handleDateChange={handleDateChange}
                        setInputProjectName={setInputProjectName}
                        IsFormForUpadate={true}
                        checked={checked}
                        setChecked={setChecked}
                        screen="reference"
                        projectStatus={projectStatus}
                        userRole={userRole}
                      />

                      {loading ? (
                        <div className="modal-footer border-0">
                          <CreateProjectFormLoader
                            height={60}
                            width={100}
                            rounded={"rounded-25"}
                          />
                          <CreateProjectFormLoader
                            height={60}
                            width={100}
                            rounded={"rounded-25"}
                          />
                        </div>
                      ) : (
                        <div className="modal-footer border-0">
                          <p
                            type="button"
                            className="cancel_modal_footer"
                            data-bs-dismiss="modal"
                            onClick={handleClearForm}
                          >
                            {t("cancel")}
                          </p>
                          <button
                            type="button"
                            className="send_modal_footer"
                            onClick={handleSubmit}
                            disabled={isCreateDisable || !isProjectActive()}
                            style={{ color: "#FFFFFF" }}
                          >
                            {t("save")}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="exampleModalSubmit"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog me-5 pt-5">
                    <div className="modal-content mt-3">
                      <div className="modal-header border-0">
                        <h5
                          className="font-noto-sans fw-bold fs-20 mb-0 text-black"
                          id="exampleModalLabel"
                        >
                          Internal approver
                        </h5>
                      </div>

                      <div className="modal-body font-noto-sans fw-500 pt-0 ">
                        <div className="d-flex align-items-center gap-3">
                          <div className="d-flex align-items-center gap-2 submit-client-approval rounded-pill p-1">
                            <p className="approver-icon mb-0">
                              <span>TY</span>
                            </p>
                            <span className="pe-2 font-noto-sans fw-bold fs-14">
                              Takashi Yamada
                            </span>
                          </div>
                          <div className="d-flex align-items-center gap-2 submit-client-approval rounded-pill p-1">
                            <p className="approver-icon mb-0">
                              <span>TY</span>
                            </p>
                            <span className="pe-2 font-noto-sans fw-bold fs-14">
                              Takashi Yamada
                            </span>
                          </div>
                        </div>
                        <div className="mt-3">
                          <form>
                            <div className="d-flex flex-column gap-2">
                              <label className=" font-noto-sans fw-bold fs-14 ms-0">
                                Message
                              </label>
                              <textarea
                                placeholder="For your approval"
                                className="submit-client-approval-textarea"
                                value={internalApprovalMsg}
                                onChange={handleInternalApprovalMsg}
                              ></textarea>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div className="modal-footer mt-0 border-0">
                        <button
                          className="rounded-pill px-4 bg-transparent h-36 border-0 text-black font-noto-sans fw-bold fs-14 text-nowrap"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          {t("cancel")}
                        </button>
                        <button
                          className="rounded-pill px-4 bg-color-black h-36 border-black text-white font-noto-sans fw-500 fs-14"
                          // data-bs-toggle="modal"
                          data-bs-target="#exampleModalSubmit"
                          onClick={onHandleClientApproval}
                        >
                          {t("submit")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="visionBoardWrapper">
                <div className="visionBoardBox">
                  <ProjectStages
                    projectDetails={projectDetails}
                    // designDetails={designDetails}
                    createdProjectId={createdProjectId}
                    projectStatus={projectStatus}
                  />
                </div>
                {/* Vision Board Preview */}
                <div className="p-lg-4 mt-3 p-md-3 p-3 bg-white bora_24 bg_white_padding">
                  <div className="d-flex justify-content-between align-items-center cursor_pointer mb-1">
                    <div className="d-flex align-items-center gap-4">
                      <div className="text_formate">{t("wireframe")}</div>
                    </div>
                  </div>

                  <div
                    className="px-3 mt-2"
                    style={{
                      maxHeight: "370px",
                      overflowY: "auto",
                      display: excalidrawLoading ? "none" : "block",
                      position: "relative",
                    }}
                  >
                    <div className="d-flex flex-wrap align-items-center justify-content-center gap-3 hover-container">
                      <div className="wireframe-transparent-layer"></div>
                      <div
                        title="Click to start design."
                        className="position-relative  reference-view-mode workspace-image"

                        // onClick={hadleWorkSpace}
                      >
                        <Excalidraw
                          excalidrawAPI={(api) => setExcalidrawAPI(api)}
                          initialData={initialStatePromiseRef.current.promise}
                        ></Excalidraw>
                      </div>
                    </div>
                    <div>
                      <button
                        className={`font-noto-sans ${styles.referenceRedirectButton}`}
                        onClick={hadleWorkSpace}
                        style={{
                          left: "calc(50% - 15px)",
                        }}
                      >
                        {loading ? (
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            height={24}
                            width={200}
                          />
                        ) : (
                          t("openVisionBoard")
                        )}
                      </button>
                    </div>
                  </div>

                  <div
                    style={{
                      maxHeight: "390px",
                      overflowY: "auto",
                      display: !excalidrawLoading ? "none" : "block",
                    }}
                  >
                    <CreateProjectFormLoader
                      height={"260px"}
                      width={"100%"}
                    ></CreateProjectFormLoader>
                  </div>

                  <ReferenceComp
                    createdProjectId={createdProjectId}
                    referenceList={referenceList}
                    setReferenceList={setReferenceList}
                    channelId={channelId}
                  />
                </div>
                {/* Vision Studio Preview */}
                <div className="visionBoardBox">
                  <div className="visiontitle">
                    <p>{t("visionStudio")}</p>
                  </div>
                  <div className="contentBox">
                    <img
                      style={{
                        borderRadius: "10px",
                        width: "100%",
                        height: "270px",
                        marginLeft: "30px",
                      }}
                      onClick={() => {
                        localStorage.removeItem("originalURLs");
                      }}
                      src={visionStudioPreview}
                      alt="visionStudioPreview"
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "5px",
                        bottom: "30px",
                        position: "absolute",
                      }}
                    >
                      {visionBoardData.length > 0 &&
                        visionBoardData.slice(0, 6).map((item, index) => (
                          <div
                            key={index}
                            style={{
                              margin: "8px",
                              width: "150px",
                              height: "150px",
                              background: "white",
                              filter: "blur(3px)",
                            }}
                          >
                            {item?.layers?.[item?.layers?.length - 1]
                              ?.image_data?.data && (
                              <img
                                src={`data:image/jpeg;base64,${item?.layers[item?.layers?.length - 1].image_data?.data}`}
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            )}
                          </div>
                        ))}
                    </div>
                    <div className="cont">
                      <button
                        onClick={() => navigateToVisionStudio()}
                        style={{ padding: "6px 20px" }}
                        className="font-noto-sans vBoard-btn"
                      >
                        {loading ? (
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            height={24}
                            width={200}
                          />
                        ) : (
                          t("openVisionStudio")
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="visionBoardBox">
                  <div className="visiontitle">
                    <p> {t("addPsdLink")}</p>
                  </div>
                  <div className="contentBox" style={{ alignItems: "normal" }}>
                    <form>
                      <div className="mb-1 psd-link ">
                        <input
                          disabled={
                            // !isProjectActive() ||
                            projectStatus === 3 || projectStatus === 5
                          }
                          type="text"
                          name="psd_link"
                          value={currentLink}
                          onChange={(e) => {
                            setCurrentLink(e.target.value);
                            if (e.target.value.trim()) {
                              setLinkError("");
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              handleAddOrSave();
                            }
                          }}
                          className="form-control from-input-link"
                          placeholder={t("addPsdLink")}
                        />
                        <button
                          onClick={(e) => {
                            e.preventDefault(); // Prevent form submission
                            handleAddOrSave();
                          }}
                          disabled={
                            (editPsdLinkId === null
                              ? isSubmitting || !currentLink.trim()
                              : false) || !isProjectActive()
                          }
                          style={{ marginLeft: "5%" }}
                          type="button"
                          className="send_modal_footer"
                        >
                          {editPsdLinkId === null ? t("add") : t("save")}
                        </button>
                      </div>
                    </form>

                    {linkError && (
                      <div
                        className="text-danger mt-1"
                        style={{ fontSize: "14px" }}
                      >
                        {linkError}
                      </div>
                    )}
                    <div className="list-group list-group-flush">
                      {displayedLinks.map((item, index) => (
                        <div
                          key={item.link_id}
                          className="list-group-item d-flex flex-md-row flex-column justify-content-between align-md-items-center psd-link-with-button"
                        >
                          <a
                            href={formatURL(item.link_url)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-decoration-none text-sm-start"
                          >
                            {item.link_url}
                          </a>
                          <div className="psd-link-actions">
                            <button
                              onClick={() =>
                                handleEdit(item.link_id, item.link_url)
                              }
                              disabled={!isProjectActive()}
                              type="button"
                              className="send_modal_footer"
                            >
                              {t("edit")}
                            </button>
                            <button
                              onClick={() => handleDelete(item.link_id)}
                              type="button"
                              disabled={!isProjectActive()}
                              className="send_modal_footer"
                            >
                              {t("delete")}
                            </button>
                            <hr className="dotted"></hr>
                          </div>
                        </div>
                      ))}
                    </div>

                    {psdLinkItems.length > 5 && (
                      <Link
                        to="#"
                        onClick={handleShowAll}
                        style={{
                          marginTop: "10px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "black",
                        }}
                        className="text-decoration-none"
                      >
                        {showAll ? t("showLess") : t("showMore")}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              {/* Modal to Upload PSD */}
              <div
                className="modal fade"
                id="add_design"
                tabIndex="-1"
                aria-labelledby="add_designLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content px-3">
                    <div className="modal-header border-0">
                      <div className="upload_design">Upload PSD</div>
                      <div className="cursor_pointer" data-bs-dismiss="modal">
                        <img src={Close} alt="Close" />
                      </div>
                    </div>
                    <div className="modal-body">
                      <UploadDesignFile screen="addPsd" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          typeof="button"
          className="chate_box cursor_pointer"
          onClick={() => setIsOpenChat(!isOpenChat)}
        >
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <img src={Chate} className="" alt="Chate" />
          </div>
        </div>
        {isOpenChat && (
          <div>
            <ChateBoat
              isOpenChat={isOpenChat}
              setIsOpenChat={setIsOpenChat}
              createdProjectId={createdProjectId}
              designDetails={designDetails}
              ProjectId={projectId}
              projectStatus={projectStatus}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Reference;
