import React, { useState } from "react";
import CommentAndTask from "./component/CommentAndTask";
import closeIcon from "../../assets/newicons/close.svg";
import frameImage from "../../assets/newicons/Frame_image.png";
import addanotation from "../../assets/newicons/add-anotion-icon.svg";
import noImage from "../../assets/newimages/no_image.jpg";
import image1 from "../../assets/newimages/image1.jpg";
import image2 from "../../assets/newimages/image2.jpg";
import image3 from "../../assets/newimages/image3.jpg";
import fullScreenArrow from "../../assets/newicons/full-screen-arrow.svg";
import commentIcon from "../../assets/newicons/chat_bubble.svg";
import AssignmentIcon from "../../assets/newicons/assignment.svg";
import sendArrowIcon from "../../assets/newicons/send-arrow-icon.svg";
import plus from "../../assets/newicons/variant-plus.svg";
import "./Annotation.scss";
import { useNavigate } from "react-router-dom";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useLocation } from "react-router-dom";

const Annotation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedImage } = location.state || {};
  const { variant } = location.state || false;
  const [selectedVersion, setSelectedVersion] = useState(123);
  const handleVersionChange = (event) => {
    setSelectedVersion(event.target.value);
  };
  const backToReference = () => {
    navigate("/reference");
  };
  return (
    <div>
      <div className="p-3 background-white header-border-radius d-flex align-items-center justify-content-between px-4">
        <div className="d-flex gap-4 align-items-center fw-bold font-noto-sans">
          <span className="font-noto-sans fs-14 text-dark-black fw-bold">
            file-name.png
          </span>
          <div className="rounded-pill py-1 px-3 fs-12 bg-color-gray">
            Active
          </div>
        </div>
        <div className="d-flex align-items-center gap-3">
          <button className="rounded-pill px-4 bg-transparent h-36 border-black text-black font-noto-sans fw-bold fs-14">
            Return
          </button>
          <button className="rounded-pill px-4 bg-color-black h-36 border-black text-white font-noto-sans fw-bold fs-14">
            Approve
          </button>
          <img
            src={closeIcon}
            alt="no_image"
            width={24}
            onClick={backToReference}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between flex-column flex-md-row">
        <div className="d-flex flex-column border-end heigth-calc-56 w-100">
          <div className="background-white width-fit-content mt-4 ms-4 rounded border d-flex justify-content-center align-items-center gap-3 px-2 shadow-md  image-zoom-button">
            <div className="">
              <FormControl>
                <div className="border-0 border-end bg-transparent pe-3 ">
                  <Select
                    value={selectedVersion}
                    name="channel"
                    onChange={handleVersionChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    className="w-100 border-none text-black font-noto-sans fw-bold"
                  >
                    <MenuItem value={123} selected>
                      Version 1
                    </MenuItem>
                    <MenuItem value={456}>Version 2</MenuItem>
                  </Select>
                </div>
              </FormControl>
            </div>
            <button className="border-0 border-end bg-transparent d-flex gap-2 pe-3 py-3">
              <img src={addanotation} alt="addanotation" />
              <p className="mb-0 fw-bold">Annotate</p>
            </button>
            <div className="py-2">
              <button className="font-noto-sans text-dark-black bg-transparent border-0 fw-bold">
                -
              </button>
              <span className="font-noto-sans text-dark-black fw-bold">
                100%
              </span>
              <button className="font-noto-sans text-dark-black bg-transparent border-0 fw-bold">
                +
              </button>
            </div>
          </div>
          <div className="d-flex gap-3 align-items-center justify-content-center overflow-auto mt-4 ms-3 variant-image">
            <div className="image-max-width py-5 py-md-0">
              <div className="d-flex justify-content-between mb-2 align-items-center">
                <div className="font-noto-sans d-flex align-items-center gap-2">
                  <h6 className="fs-14 fw-bold mb-0 ">Variant 1</h6>
                  <span className="fs-12 text-black text-dark-gray">
                    1350x1350px
                  </span>
                  <span className="fs-12 text-dark-gray">|</span>
                  <span className="fs-12 text-dark-gray">220 KB</span>
                </div>
                <button className="border-0 bg-transparent">
                  <img src={plus} alt="variant-plus" />
                </button>
              </div>
              <div className="position-relative d-flex justify-content-center align-items-center bg-white">
                <img
                  src={selectedImage?.imageUrl || frameImage}
                  width={800}
                  height={600}
                  alt="frameImage"
                  className="w-100"
                />
                <button className="rounded-pill position-absolute px-4  h-36 border-black text-black font-noto-sans fw-bold fs-14">
                  Return
                </button>
              </div>
            </div>
            <div className="image-max-width py-5 py-md-0">
              <div className="d-flex justify-content-between mb-2 align-items-center">
                <div className="font-noto-sans d-flex align-items-center gap-2">
                  <h6 className="fs-14 fw-bold mb-0 ">Variant 1</h6>
                  <span className="fs-12 text-black text-dark-gray">
                    1350x1350px
                  </span>
                  <span className="fs-12 text-dark-gray">|</span>
                  <span className="fs-12 text-dark-gray">220 KB</span>
                </div>
                <button className="border-0 bg-transparent">
                  <img src={plus} alt="variant-plus" />
                </button>
              </div>
              <img
                src={selectedImage?.imageUrl || frameImage}
                width={800}
                height={600}
                alt="frameImage"
                className="w-100"
              />
            </div>
          </div>
        </div>
        <div className=" max-w-420px w-100 bg-color-gray border-start heigth-calc-56 overflow-auto">
          <CommentAndTask />
        </div>
      </div>
    </div>
  );
};

export default Annotation;
