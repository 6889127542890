/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from "@mui/material";
import i18next, { t } from "i18next";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "swiper/css";
import "swiper/css/navigation";
import { imageGet, post } from "../../../API/axios";
import { ReactComponent as CloseSmall } from "../../../assets/icons/close_small.svg";
import { ReactComponent as FilterList } from "../../../assets/icons/filter_list.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/project-search.svg";
import CardLoader from "../../../components/Loaders/CardLoader";
import NoDataFound from "../../../components/NoDataComponent/NoDataFound";
import { useAuthContext } from "../../../context/useAuthContext";
import { useEditContext } from "../../../context/useEditContext";
import { useFilterContext } from "../../../context/useFilterContext";
import { useSearchContext } from "../../../context/useSearchContext";
import ModelOptions from "../../../helpers/ModelOptions";
import { getCategoryLabel } from "../../../helpers/labels";
import "../../Search/SearchResults.scss";
import "./BrowseSearch.scss";
import { showAlert } from "./AlertService";
const BrowseSearch = (
  {
    onSetImageUrl,
    showImageDetails,
    setShowImageDetails,
    prevResponseValue = {},
    setSearchOpen,
    searchOpen,
  },
  ref
) => {
  const [counter, setCounter] = useState(8);
  const [isLoading, setIsLoading] = useState(false);
  const { filterTags } = useFilterContext();
  const [scrollLoader, setScrollLoader] = useState(false);
  const [resetData, setResetData] = useState({});
  const [offSetId, setOffSetId] = useState(null);
  const [isEditingTags, setIsEditingTags] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [globalGroupedImageIds, setGlobalGroupedImageIds] = useState(new Set());
  const globalGroupedImageIdsRef = useRef(globalGroupedImageIds);
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [chipList, setChipList] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    industry: [],
    tags: [],
    personInImage: [],
    imageStyle: [],
    deliveryContent: [],
    deliveryType: [],
    imageType: [],
    editableImages: "",
    ctr: 0,
  });
  const [imageDetailsFilters, setImageDetailsFilters] = useState({
    industry: [],
    tags: [],
    deliveryContent: [],
    deliveryType: [],
    imageType: [],
    model_filters: [],
  });
  const {
    searchResults,
    setSearchResults,
    similarResults,
    setSimilarResults,
    results,
    similarityGroup,
    setSimilarityGroup,
    setResults,
    numberOfResults,
    setNumberOfResults,
    formValue,
    setFormValue,
    showResults,
    setShowResults,
    prevSearch,
    setPrevSearch,
    searchView,
    setSearchViewState,
    setSelectedFilter,
    selectedFilter,
    checked,
    setChecked,
  } = useSearchContext();
  const {
    clearEditData,
    searchQuery,
    setSearchQuery,
    refreshSearch,
    setRefreshSearch,
  } = useEditContext();
  const {
    selectedSite,
    initialLoading,
    saveSelectedSite,
    areAPIsLoading,
    isDebug,
    setIsDebug,
    userSettings,
    hasPermission,
  } = useAuthContext();
  const [filteredSites, setFilteredSites] = useState(
    userSettings?.channel_name
  );
  const en_tags = localStorage.getItem("en_tags");
  const ja_tags = localStorage.getItem("ja_tags");
  const currentLanguage = i18next.language;
  // const results = Array.from({ length: 48 });

  useEffect(() => {
    setSelectedFilters((prev) => ({
      ...prev,
      ...selectedFilter,
    }));
  }, [selectedFilter]);

  useEffect(() => {
    setSearchResults(checked ? similarResults : results);
  }, [checked, results, similarResults]);

  useEffect(() => {
    setShowDetails(null);
  }, [isLoading]);

  useEffect(() => {
    if (filterTags) {
      filterTags.categories.forEach((category) => {
        if (category.category_key === "tags") {
          selectedFilters[category.category_key] = Object.entries(
            category.category_options
          )
            .filter(([key]) => key !== "None" && key !== "All")
            .map(([key, value]) =>
              key.replace("category_" + category.category_key + "_", "")
            );
        }
      });
    }
  }, [filterTags]);

  const industryOptions =
    filterTags?.categories?.find(
      (category) => category.category_key === "industry"
    )?.category_options || {};
  const formattedIndustryOptions = Object.entries(industryOptions).map(
    ([key, value]) => ({
      id: value,
      title: t(key),
    })
  );

  const imageTypeOptions =
    filterTags?.categories?.find(
      (category) => category.category_key === "image_type"
    )?.category_options || {};
  const deliveryTypeOptions =
    filterTags?.categories?.find(
      (category) => category.category_key === "delivery_type"
    )?.category_options || {};
  const deliveryContentOptions =
    filterTags?.categories?.find(
      (category) => category.category_key === "delivery_content"
    )?.category_options || {};
  const industryFilter =
    filterTags?.categories?.find(
      (category) => category.category_key === "industry"
    ) || {};
  // Add with other filter definitions
  const deliveryTypeFilter =
    filterTags?.categories?.find(
      (category) => category.category_key === "delivery_type"
    ) || {};
  const deliveryContentFilter =
    filterTags?.categories?.find(
      (category) => category.category_key === "delivery_content"
    ) || {};

  const handleChange = (filterKey, value, checked) => {
    setSelectedFilters((prev) => {
      const updatedFilters = { ...prev };

      // Get options for the current filter type
      let allFilterOptions = {};
      switch (filterKey) {
        case "imageType":
          allFilterOptions = imageTypeOptions;
          break;
        case "deliveryType":
          allFilterOptions = deliveryTypeOptions;
          break;
        case "deliveryContent":
          allFilterOptions = deliveryContentOptions;
          break;
        default:
          allFilterOptions = {};
      }

      // If clicking 'None' (-1)
      if (allFilterOptions[value] === -1) {
        if (checked) {
          // If selecting 'None', clear all other selections and only select 'None'
          updatedFilters[filterKey] = [value];
        } else {
          // If unchecking 'None', clear all selections
          updatedFilters[filterKey] = [];
        }
      }
      // If clicking 'All' (-2)
      else if (allFilterOptions[value] === -2) {
        if (checked) {
          // Select all options except 'None'
          updatedFilters[filterKey] = Object.keys(allFilterOptions).filter(
            (key) => allFilterOptions[key] !== -1
          );
        } else {
          // Deselect all
          updatedFilters[filterKey] = [];
        }
      }
      // If clicking any other option
      else {
        if (checked) {
          // Remove 'None' if it was selected
          const withoutNone = prev[filterKey].filter(
            (key) => allFilterOptions[key] !== -1
          );
          // Add the selected value
          updatedFilters[filterKey] = [...withoutNone, value];

          // Check if all regular options are selected
          const regularOptions = Object.keys(allFilterOptions).filter(
            (key) =>
              allFilterOptions[key] !== -1 && allFilterOptions[key] !== -2
          );

          if (
            regularOptions.every((option) =>
              updatedFilters[filterKey].includes(option)
            )
          ) {
            // Add 'All' option if not already included
            const allOption = Object.keys(allFilterOptions).find(
              (key) => allFilterOptions[key] === -2
            );
            if (!updatedFilters[filterKey].includes(allOption)) {
              updatedFilters[filterKey].push(allOption);
            }
          }
        } else {
          // Remove the value and 'All' option
          const allOption = Object.keys(allFilterOptions).find(
            (key) => allFilterOptions[key] === -2
          );
          updatedFilters[filterKey] = prev[filterKey].filter(
            (item) => item !== value && item !== allOption
          );
        }
      }

      return updatedFilters;
    });
  };
  const handleRadioChange = (filterKey, value) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterKey]: value,
    }));
  };

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value);
    setSelectedFilters((prev) => ({
      ...prev,
      ctr: value,
    }));
  };

  // Update handleRemoveChip function
  const handleRemoveChip = async (category, value) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (Array.isArray(updatedFilters[category])) {
        updatedFilters[category] = updatedFilters[category].filter(
          (item) => item !== value
        );
      } else {
        // Special handling for CTR
        if (category === "ctr") {
          updatedFilters[category] = 0;
        } else {
          updatedFilters[category] = "";
        }
      }
      setSelectedFilter((prevSelectedFilter) => ({
        ...prevSelectedFilter,
        [category]: updatedFilters[category],
      }));

      // Create a new form value with only the updated category
      const newFormValue = { ...formValue };

      // Update form value based on specific filter category
      switch (category) {
        case "industry":
          newFormValue.industry = updatedFilters.industry.map(
            (ind, index) => index + 1
          );
          break;
        case "imageType":
          newFormValue.image_type = updatedFilters.imageType.map(
            (type) => Object.keys(imageTypeOptions).indexOf(type) + 1
          );
          break;
        case "deliveryType":
          newFormValue.delivery_type = updatedFilters.deliveryType.map(
            (type) => Object.keys(deliveryTypeOptions).indexOf(type) + 1
          );
          break;
        case "deliveryContent":
          newFormValue.delivery_content = updatedFilters.deliveryContent.map(
            (content) =>
              Object.keys(deliveryContentOptions).indexOf(content) + 1
          );
          break;
        case "imageStyle":
        case "personInImage":
          const modelFilters = [...(newFormValue.model_filters || [])];
          if (category === "imageStyle") {
            const styleIndex = modelFilters.findIndex(
              (f) => f.model_key_name === "model_image_style"
            );
            if (styleIndex >= 0) {
              modelFilters[styleIndex].model_options =
                updatedFilters.imageStyle.map((style) =>
                  style === "Photo" ? 0 : 1
                );
            }
          } else {
            const personIndex = modelFilters.findIndex(
              (f) => f.model_key_name === "model_image_type"
            );
            if (personIndex >= 0) {
              modelFilters[personIndex].model_options =
                updatedFilters.personInImage.map((person) =>
                  person === "Man" ? 0 : 1
                );
            }
          }
          newFormValue.model_filters = modelFilters;
          break;
        case "ctr":
          newFormValue.ctr = 0;
          break;
        case "editableImages":
          newFormValue.is_edited = "";
          break;
        default:
          break;
      }

      // Update form value state
      setFormValue(newFormValue);
      setTimeout(() => {
        getSearchText(false);
      }, 0);

      return updatedFilters;
    });
  };

  const checkCategoryLengths = (payload) => {
    const updatedPayload = { ...payload };
    for (let tag of filterTags?.categories) {
      const categoryKey = tag?.category_key;
      const payloadKey = payload[categoryKey];

      if (payloadKey) {
        const filterTagLength = Object.keys(tag.category_options)?.length;
        const payloadLength = payloadKey?.length;
        if (filterTagLength === payloadLength) {
          updatedPayload[categoryKey] = [];
          // delete updatedPayload[categoryKey];
        }
      }
    }

    for (let tag of filterTags?.models) {
      const modelKey = tag?.model_key_name;
      const payloadKey = payload?.model_filters?.find(
        (model) => model?.model_key_name === modelKey
      );

      if (payloadKey) {
        const filterTagLength = Object.keys(tag?.model_options)?.length;
        const payloadLength = payloadKey?.model_options?.length;
        if (filterTagLength === payloadLength) {
          updatedPayload.model_filters = updatedPayload.model_filters.map(
            (item) => {
              if (item.model_key_name === modelKey) {
                return { ...item, model_options: [] };
              }
              return item;
            }
          );
        }
      }
    }
    return updatedPayload;
  };

  useEffect(() => {
    globalGroupedImageIdsRef.current = globalGroupedImageIds;
  }, [globalGroupedImageIds]);

  const resetSearch = () => {
    setNumberOfResults({
      primary: 0,
      similar: 0,
    });
    setFormValue({ ...formValue, page: 1 });
    setGlobalGroupedImageIds(new Set());
    setSimilarityGroup([]);
    setSimilarResults([]);
    setSelectedFilter({
      industry: [],
      tags: [],
      ctr: 0,
    });
  };

  const handleSearchIcon = () => {
    setShowResults(true); // Show results only when searching
    setSearchResults([]);
    setSimilarResults([]);
    setResults([]);

    getSearchText(false);
    if (scrollRef) {
      scrollRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const manageSimilarGroup = (response, isNextPage = false) => {
    const newGroupedImageIds = new Set(globalGroupedImageIdsRef.current);

    const groupedImages = response?.data.reduce((acc, image) => {
      const imageId = image.image_id;

      if (!newGroupedImageIds.has(imageId)) {
        const similarGroup = {
          similar_group_id: imageId,
          similar_group_images: [image],
        };
        newGroupedImageIds.add(imageId);

        const similarImageIds = response?.similar_image_map[imageId] || [];

        similarImageIds.forEach((similarId) => {
          if (!newGroupedImageIds.has(similarId)) {
            const similarImageInfo = response?.data.find(
              (img) => img.image_id === similarId
            );
            if (similarImageInfo) {
              similarGroup.similar_group_images.push(similarImageInfo);
              newGroupedImageIds.add(similarId);
            }
          }
        });

        response?.data.forEach((otherImage) => {
          if (!newGroupedImageIds.has(otherImage.image_id)) {
            const otherSimilarIds =
              response?.similar_image_map[otherImage.image_id] || [];
            if (otherSimilarIds.includes(imageId)) {
              similarGroup.similar_group_images.push(otherImage);
              newGroupedImageIds.add(otherImage.image_id);
            }
          }
        });
        acc.push(similarGroup);
      }

      return acc;
    }, []);

    const groupData = groupedImages.map((group) => ({
      ...group,
      ...group.similar_group_images[0],
    }));
    if (!isNextPage) {
      setSimilarityGroup(groupedImages);
      setSimilarResults(groupData);
    } else {
      setSimilarityGroup((prevResults) => [...prevResults, ...groupedImages]);
      setSimilarResults((prevResults) => [...prevResults, ...groupData]);
    }
    setGlobalGroupedImageIds(newGroupedImageIds);
  };

  const getSearchText = async (isNextPage = false) => {
    if (isNextPage) {
      setScrollLoader(true);
    } else {
      setIsLoading(true);
    }

    if (prevSearch) {
      showAlert(t("refreshingSearch"), "warning");
    }

    // if (!isNextPage) {
    //   resetSearch();
    // }
    setRefreshSearch(false);

    // Base payload construction
    let payload = {
      input_text: formValue.input_text || searchQuery?.input_text || "",
      lang: currentLanguage,
      page: isNextPage ? formValue.page + 1 : 1,
      ctr: Number(selectedFilter.ctr || searchQuery?.ctr || 0),
      is_edited: selectedFilters.editableImages || "",
      site_id:
        typeof selectedSite?.site_id === "object"
          ? selectedSite?.site_id
          : [selectedSite?.site_id],
      search_by: "both",
    };

    // Transform selected filters into payload format
    if (selectedFilters.industry?.length > 0) {
      payload.industry = selectedFilters.industry.map(
        (ind, index) => index + 1
      );
    }

    if (selectedFilters.imageType?.length > 0) {
      payload.image_type = selectedFilters.imageType.map(
        (type) => Object.keys(imageTypeOptions).indexOf(type) + 1
      );
    }

    if (selectedFilters.deliveryType?.length > 0) {
      payload.delivery_type = selectedFilters.deliveryType.map(
        (type) => Object.keys(deliveryTypeOptions).indexOf(type) + 1
      );
    }

    if (selectedFilters.deliveryContent?.length > 0) {
      payload.delivery_content = selectedFilters.deliveryContent.map(
        (content) => Object.keys(deliveryContentOptions).indexOf(content) + 1
      );
    }
    // Add CTR range if different from default
    if (selectedFilters.ctr !== 0) {
      payload.ctr = selectedFilters.ctr;
    }

    // Validation checks
    if (payload?.ctr > 100) {
      toast.info("CTR cannot be greater than 100");
      setIsLoading(false);
      return;
    }
    const { tags, ctr, input_text, lang, page, site_id, is_edited, ...rest } =
      formValue;
    payload = {
      ...payload,
      ...rest,
    };

    if (
      !payload?.input_text &&
      payload?.ctr <= 0 &&
      !payload?.industry?.length &&
      !payload?.delivery_content?.length &&
      !payload?.delivery_type?.length &&
      !payload?.image_type?.length &&
      !payload?.model_filters?.length &&
      !payload?.is_edited
    ) {
      showAlert(t("oneFilterAtleast"), "warning");
      //toast.info("Apply At least One Filter!");
      setIsLoading(false);
      return;
    }

    // Clean up input text
    if (payload.input_text.includes("in:")) {
      payload.input_text = payload.input_text.replace(/in:.*/, "").trim();
    }

    setShowResults(true);
    payload = await checkCategoryLengths(payload);
    setResetData(formValue);

    try {
      const response = await post(
        "text_search_image/",
        offSetId && isNextPage ? { offset_id: offSetId, ...payload } : payload
      );

      handleSearchResponse(response, isNextPage);
    } catch (error) {
      setIsLoading(false);
      setScrollLoader(false);
      setShowResults(true);
      console.error("error", error);
    } finally {
      setPrevSearch(false);
    }
  };
  const handleSearchResponse = (response, isNextPage) => {
    setNumberOfResults({
      total_images: response?.pagination?.total_images_in_page,
      primary: response?.pagination?.total_images,
      similar: response?.pagination?.total_images_in_current_page,
    });

    setOffSetId(response?.pagination?.offset_id);

    if (!isNextPage) {
      setSearchResults(response?.data);
      manageSimilarGroup(response, isNextPage);
      setResults(response?.data);
    } else if (response?.data?.length > 0) {
      setSearchResults((prev) => [...prev, ...response?.data]);
      manageSimilarGroup(response, isNextPage);
      setResults((prev) => [...prev, ...response?.data]);
    }

    setTimeout(() => {
      setIsLoading(false);
      setScrollLoader(false);
    }, 500);
  };

  //Search text changes
  const handleSearch = (e) => {
    const { name, value } = e.target;
    if (value.includes("in:")) {
      // setIsSiteSearchOpen(true);
      const searchValue = value.split("in:")[1].trim();
      const filtered = userSettings?.channel_name.filter((site) =>
        site.site_name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredSites(filtered);
    } else {
      // setIsSiteSearchOpen(false);
      setFilteredSites(userSettings?.channel_name);
    }
    setFormValue({ ...formValue, [name]: value });
  };
  // Clear all filters
  const handleClearAllFilters = () => {
    const initialFilters = {
      industry: [],
      tags: [],
      personInImage: [],
      imageStyle: [],
      deliveryContent: [],
      deliveryType: [],
      imageType: [],
      editableImages: "",
      ctr: 0,
    };

    setSelectedFilters(initialFilters);
    setSelectedFilter({});

    // Reset form value
    const resetFormValue = {
      input_text: formValue?.input_text,
      lang: currentLanguage,
      page: 1,
      ctr: 0,
      is_edited: "",
      site_id:
        typeof selectedSite?.site_id === "object"
          ? selectedSite?.site_id
          : [selectedSite?.site_id],
      search_by: "both",
      industry: [],
      tags: [],
      image_type: [],
      delivery_type: [],
      delivery_content: [],
      model_filters: [],
    };

    setFormValue(resetFormValue);
    if (filterTags?.models) {
      filterTags.models.forEach((model) => {
        const modelIndex = resetFormValue.model_filters.findIndex(
          (f) => f.model_key_name === model.model_key_name
        );
        if (modelIndex === -1) {
          resetFormValue.model_filters.push({
            model_id: model.model_id,
            model_key_name: model.model_key_name,
            model_options: [],
          });
        }
      });
    }

    // Trigger search with cleared filters
    setTimeout(() => {
      getSearchText(false);
    }, 0);
  };
  const handleSwitch = async (e) => {
    setChecked(!checked);
  };
  const handleResetIndustry = () => {
    setSelectedFilters((prev) => ({
      ...prev,
      industry: [],
    }));
    getSearchText(false);
  };

  const handleResetTags = () => {
    setSelectedFilters((prev) => ({
      ...prev,
      tags: [],
    }));
    getSearchText(false);
  };

  const [sentryRef] = useInfiniteScroll({
    loading: scrollLoader,
    hasNextPage:
      numberOfResults.total_images === numberOfResults.primary
        ? false
        : true || searchResults?.length === 0
          ? true
          : false,
    onLoadMore: () => getSearchText(true),
    disabled: false,
    delayInMs: 2000,
    rootMargin: "0px 0px 400px 0px",
  });

  const [similarImages, setSimilarImages] = useState([]);
  const swiperRef = useRef(null);

  // Add these helper functions for slider navigation
  const goToNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goToPrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const getImageDetails = async (imageId, load = true) => {
    if (load) {
      setIsLoading(true);
    }
    const { image } = await post("get_image_detail/", {
      image_id: imageId ? imageId : formValue.image_id,
    });
    setImageData(image);

    // Add this: Get similar images
    try {
      const response = await post("get_similar_images/", { image_id: imageId });
      setSimilarImages(response?.similar_image_list || []);
    } catch (error) {
      console.error("Error fetching similar images:", error);
      setSimilarImages([]);
    }

    setFormValue((prev) => ({
      ...prev,
      image_id: image?.image_id || 0,
      image_uuid: image?.image_uuid || "",
      lang: currentLanguage,
      edit_description:
        image[
          currentLanguage === "ja"
            ? "image_description_ja"
            : "image_description"
        ] || "",
      // edit_tags: image["image_" + (currentLanguage || "en") + "_tags"] || [],
      edit_tags: [
        ...(image["image_en_tags"] || []),
        ...(image["image_ja_tags"] || []),
      ],
      inputTag: "",
      image_url: image?.image_url || "",
      ctr: image?.ctr || 0,
      score: image?.score,
      model_filters: image?.model_filters,
      image_size: image?.image_size,
      image_extension: image?.image_extension,
      industry: image?.industry,
      text_extraction: image?.text_extraction,
      delivery_type: image?.delivery_type,
      delivery_content: image?.delivery_content,
      image_type: image?.image_type,
    }));
    // setIsEdited(false);
    if (load) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (showDetails && imageData) {
      setImageDetailsFilters({
        tags: formValue?.edit_tags || [],
        model_filters: formValue?.model_filters || [],
        industry:
          formValue?.industry?.map((ind) =>
            getCategoryLabel("industry", ind, filterTags)
          ) || [],
        deliveryType:
          formValue?.delivery_type?.map((type) =>
            Object.keys(deliveryTypeOptions).find(
              (key) => deliveryTypeOptions[key] === type
            )
          ) || [],
        deliveryContent:
          formValue?.delivery_content?.map((content) =>
            Object.keys(deliveryContentOptions).find(
              (key) => deliveryContentOptions[key] === content
            )
          ) || [],
        imageType:
          formValue?.image_type?.map((type) =>
            Object.keys(imageTypeOptions).find(
              (key) => imageTypeOptions[key] === type
            )
          ) || [],
      });
    }
  }, [showDetails, imageData, formValue]);

  // Add this helper function to create batches
  const createBatches = (data, batchSize) => {
    const batches = [];
    for (let i = 0; i < data?.length; i += batchSize) {
      batches.push(data.slice(i, i + batchSize));
    }
    return batches;
  };

  // Add this function to calculate columns based on screen width
  const calculateColumns = () => {
    const width = window.innerWidth;
    if (width < 768) return 4; // Mobile
    if (width < 1024) return 5; // Tablet
    if (width < 1440) return 6; // Small Desktop
    if (width < 1920) return 7; // Large Desktop
    return 8; // Extra Large Screens
  };

  // Update counter when window resizes
  useEffect(() => {
    const handleResize = () => {
      setCounter(calculateColumns());
    };

    // Set initial value
    setCounter(calculateColumns());

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Update the counter increment/decrement handlers
  const handleIncrement = (event) => {
    event.preventDefault();
    const maxColumns = calculateColumns();
    setCounter((prev) => Math.min(maxColumns, prev + 1));
    setShowDetails(null);
  };

  const handleDecrement = (event) => {
    event.preventDefault();
    setCounter((prev) => Math.max(4, prev - 1));
    setShowDetails(null);
  };

  const handleImageClick = (item, e) => {
    // Multi-select mode
    setSelectedImages((prev) => {
      const isSelected = prev.some((img) => img.image_id === item.image_id);
      if (isSelected) {
        return prev.filter((img) => img.image_id !== item.image_id);
      } else {
        return [...prev, item];
      }
    });
    setShowDetails(item);
    // getImageDetails(item.image_id, false);
    setShowImageDetails(true);
    onSetImageUrl(selectedImages);
  };

  useEffect(() => {
    if (selectedImages.length > 0) {
      onSetImageUrl(selectedImages);
      setCounter(4);
      setIsFilterOpen(false);
    } else {
      onSetImageUrl(selectedImages);
      setCounter(calculateColumns());
      setIsFilterOpen(true);
    }
  }, [selectedImages]);

  const isImageSelected = (imageId) => {
    return selectedImages.some((img) => img.image_id === imageId);
  };

  useEffect(() => {
    // When modal closes (searchOpen becomes false)
    if (!searchOpen) {
      resetBrowseSearchState();
    }

    // Cleanup function when component unmounts
    return () => {
      resetBrowseSearchState();
    };
  }, [searchOpen]);

  const resetBrowseSearchState = () => {
    // Reset visual states
    setShowDetails(null);
    setImageData(null);
    setSearchResults([]);
    setSimilarImages([]);
    setIsFilterOpen(true);
    setChipList(false);
    setScrollLoader(false);
    setIsLoading(false);
    setShowImageDetails(false);
    setSelectedImages([]); // Add this line to clear selected images

    // Reset filters
    setSelectedFilters({
      industry: [],
      tags: [],
      personInImage: [],
      imageStyle: [],
      deliveryContent: [],
      deliveryType: [],
      imageType: [],
      editableImages: "",
      ctr: 0,
    });

    // Reset search context states
    setSelectedFilter({});
    setChecked(false);
    setNumberOfResults({
      primary: 0,
      similar: 0,
      total_images: 0,
    });

    // Reset grouping states
    setGlobalGroupedImageIds(new Set());
    setSimilarityGroup([]);
    setSimilarResults([]);
    setResults([]);

    // Reset query
    setSearchQuery("");
    setOffSetId(null);
  };

  useImperativeHandle(ref, () => ({
    resetState: resetBrowseSearchState,
  }));

  const getModelDisplayLabel = (modelKeyName, value) => {
    if (modelKeyName === "model_image_type") {
      switch (value) {
        case 0:
          return "Photo";
        case 1:
          return "Illustration";
        // Remove -1 and -2 cases since we don't want to show chips for All/None
        default:
          return "";
      }
    }
    return "";
  };

  // Add this function to handle model filter removal
  const handleRemoveModelFilter = (modelKeyName, optionValue) => {
    setFormValue((prev) => {
      const updatedModelFilters = prev.model_filters
        .map((filter) => {
          if (filter.model_key_name === modelKeyName) {
            const updatedOptions = filter.model_options.filter(
              (opt) => opt !== optionValue
            );
            return {
              ...filter,
              model_options: updatedOptions,
            };
          }
          return filter;
        })
        .filter((filter) => filter.model_options.length > 0); // Remove filters with no options

      return {
        ...prev,
        model_filters: updatedModelFilters,
      };
    });

    setTimeout(() => {
      getSearchText(false);
    }, 0);
  };

  // Add the new handleIndustryChange function (adapted from SearchResults.js)
  const handleIndustryChange = (newValue) => {
    // Find the English and Japanese "All" and "None" options
    const allOption = formattedIndustryOptions.find(
      (opt) => opt.title === t("All") // Check English and Japanese
    );
    const noneOption = formattedIndustryOptions.find(
      (opt) => opt.title === t("None") // Check English and Japanese
    );

    // Check if "All" (English or Japanese) was previously selected
    const wasAllSelected = selectedFilters.industry.some(
      // Use selectedFilters
      (title) => title === t("All")
    );
    // Check if "All" (English or Japanese) is currently selected in the new value
    const isAllSelected = newValue.some((opt) => opt.title === t("All"));
    // Check if "None" (English or Japanese) is currently selected
    const isNoneSelected = newValue.some((opt) => opt.title === t("None"));

    let finalSelection;

    if (isNoneSelected) {
      // If "None" is selected, clear everything else
      finalSelection = [noneOption].filter(Boolean); // Ensure noneOption exists
    } else if (!wasAllSelected && isAllSelected) {
      // If "All" (English or Japanese) was just selected, select everything except "None" (English or Japanese)
      finalSelection = formattedIndustryOptions.filter(
        (opt) => opt.title !== t("None")
      );
    } else if (wasAllSelected && !isAllSelected) {
      // If "All" (English or Japanese) was deselected, clear everything
      finalSelection = [];
    } else if (
      wasAllSelected &&
      newValue.length < formattedIndustryOptions.length
    ) {
      // If we had "All" selected and deselected something, remove "All" (English and Japanese) and keep others
      finalSelection = newValue.filter((opt) => opt.title !== t("All"));
    } else {
      // Normal selection
      // Filter out "None" if other items are selected
      finalSelection = newValue.filter((opt) => opt.title !== t("None"));

      // Check if all regular options are now selected
      const regularOptions = formattedIndustryOptions.filter(
        (opt) => opt.title !== t("None") && opt.title !== t("All")
      );
      if (
        finalSelection.length === regularOptions.length &&
        allOption && // Ensure allOption exists
        !finalSelection.some((opt) => opt.title === t("All")) // Avoid duplicates
      ) {
        finalSelection.push(allOption);
      }
    }

    // Update formValue with IDs
    setFormValue((prev) => ({
      ...prev,
      industry: finalSelection
        .map((option) => option.id)
        .filter((id) => id !== undefined && id !== null), // Ensure IDs are valid
    }));

    // Update selectedFilters with titles
    setSelectedFilters((prev) => ({
      // Use setSelectedFilters
      ...prev,
      industry: finalSelection.map((option) => option.title),
    }));
    // Update the global selectedFilter from context as well
    setSelectedFilter((prev) => ({
      ...prev,
      industry: finalSelection.map((option) => option.title),
    }));
  };

  return (
    <section className="heroResult">
      <div className="container-fluid p-0">
        <div
          className="stickyHeader"
          style={{ position: "relative", top: "auto" }}
        >
          <div className="searchHeader">
            <div className="searchInput">
              <div className="searcIcon">
                <SearchIcon />
              </div>
              <input
                type="text"
                placeholder={t("Search...")}
                value={formValue.input_text}
                name="input_text"
                onChange={(e) => handleSearch(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearchIcon();
                  }
                }}
              />
            </div>
            <div className="counterWrapper">
              <Link to="#" onClick={handleDecrement}>
                <img
                  src={require("../../../assets/icons/decreament.png")}
                  alt=""
                  className="img-fluid"
                />
              </Link>
              <p>{counter}</p>
              <Link to="#" onClick={handleIncrement}>
                <img
                  src={require("../../../assets/icons/Increament.png")}
                  alt=""
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
          <div className="chipWrapper">
            <div
              className="filterIcon"
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              <FilterList />
            </div>
            <ul
              style={chipList ? { flexWrap: "wrap" } : { flexWrap: "nowrap" }}
            >
              {Object.entries(selectedFilters)?.map(([category, values]) =>
                (Array.isArray(values) ? values : [values])
                  .filter((value) => value && value != "All")
                  .map((value, index) => (
                    <li key={`${category}-${index}`}>
                      {t(value)}{" "}
                      <CloseSmall
                        onClick={() => handleRemoveChip(category, value)}
                      />
                    </li>
                  ))
              )}
              {/* Add model filters chips */}
              {formValue?.model_filters?.map((filter) =>
                filter.model_options
                  ?.map((option, index) => {
                    const label = getModelDisplayLabel(
                      filter.model_key_name,
                      option
                    );
                    // Only show chips for actual selections (not All/None)
                    if (label && option !== -1 && option !== -2) {
                      return (
                        <li key={`${filter.model_key_name}-${index}`}>
                          {label}
                          <CloseSmall
                            onClick={() =>
                              handleRemoveModelFilter(
                                filter.model_key_name,
                                option
                              )
                            }
                          />
                        </li>
                      );
                    }
                    return null;
                  })
                  .filter(Boolean)
              )}
            </ul>
            <div
              className="searchResult"
              onClick={() => setChipList((prev) => !prev)}
            >
              <p>
                <span>{searchResults?.length || 0}</span> {t("results")}
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="main-result-wrapper">
              {isFilterOpen && (
                <div
                  className="filterWrap"
                  style={{ position: "absolute", maxHeight: "100%" }}
                >
                  <div className="filterHeader">
                    <h5>{t("filterstitle")}</h5>
                    <img
                      src={require("../../../assets/icons/close.png")}
                      alt=""
                      className="img-fluid"
                      onClick={() => setIsFilterOpen(!isFilterOpen)}
                    />
                  </div>
                  {/* <div className="displayOption">
                                    <p>{t('filterssearchDisplayOptions')}</p>
                                    <Link to="#">{t('filtersreset')}</Link>
                                </div> */}
                  <div className="similarImg">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            defaultChecked={checked}
                            onChange={handleSwitch}
                          />
                        }
                        label={t("filtersgroupSimilarImages")}
                      />
                    </FormGroup>
                  </div>
                  <div className="industryWrap">
                    <div className="indTitle">
                      <p>{t(industryFilter?.category_display_code)}</p>
                      <Link to="#" onClick={handleResetIndustry}>
                        {t("filtersreset")}
                      </Link>
                    </div>
                    <div className="indBody">
                      <Autocomplete
                        multiple
                        options={formattedIndustryOptions}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={formattedIndustryOptions.filter(
                          (option) =>
                            selectedFilters.industry.includes(option.title) ||
                            false // Add fallback
                        )}
                        onChange={
                          (event, newValue) => handleIndustryChange(newValue) // Use the new handler
                        }
                        // onChange={(event, newValue) => {
                        //   setSelectedFilters((prev) => ({
                        //     ...prev,
                        //     industry: newValue.map((option) => option.title),
                        //   }));
                        // }}
                        className="autoCompleteDropdown"
                        renderOption={(props, option, { selected }) => {
                          const { key, ...otherProps } = props; // Destructure key
                          return (
                            // Return the list item
                            <li key={key} {...otherProps}>
                              <Checkbox checked={selected} />
                              {option.title}
                            </li>
                          );
                        }}
                        style={{
                          width: "100%",
                          border: "1px solid #B3B3B3",
                          borderRadius: 8,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("filters.industry")}
                          />
                        )}
                        sx={{
                          // Add sx prop to hide end adornment if needed
                          ".MuiAutocomplete-endAdornment": {
                            display: "none",
                          },
                        }} // Correct closing for sx prop
                      />
                    </div>
                  </div>
                  <div className="industryWrap">
                    <div className="indTitle">
                      <p>{t("ctr")}</p>
                      <Link to="#">{t("filtersreset")}</Link>
                    </div>
                    <div className="indBody">
                      <div className="inputWrap">
                        <div className="inputbox">
                          <input
                            type="number"
                            min={0}
                            max={100}
                            value={selectedFilters.ctr}
                            onChange={(e) => handleInputChange(e)}
                          />
                          <div className="perIcon">
                            <img
                              src={require("../../../assets/icons/percentage.png")}
                              alt="mico"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <FormControlLabel control={<Checkbox value={'abc'} />} label={t('filtersincludeUncalculatedCTR')} /> */}
                    </div>
                  </div>
                  <div className="industryWrap">
                    <div className="indTitle">
                      <p>{t("imageStatus")}</p>
                    </div>
                    <div className="indBody">
                      <div className="checklist">
                        <FormGroup>
                          <RadioGroup
                            value={selectedFilters.editableImages}
                            onChange={(e) =>
                              handleRadioChange(
                                "editableImages",
                                e.target.value
                              )
                            }
                          >
                            <FormControlLabel
                              className={
                                selectedFilters.editableImages === "edited"
                                  ? "checked"
                                  : ""
                              }
                              control={<Radio />}
                              value="edited"
                              label={t("edited")}
                            />
                            <FormControlLabel
                              className={
                                selectedFilters.editableImages === "Unedited"
                                  ? "checked"
                                  : ""
                              }
                              control={<Radio />}
                              value="unedited"
                              label={t("unEdited")}
                            />
                          </RadioGroup>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div className="industryWrap">
                    <div className="indTitle">
                      <p>{t("imageType")}</p>
                    </div>
                    <div className="indBody">
                      <div className="checklist">
                        <FormGroup>
                          {Object.entries(imageTypeOptions).map(
                            ([key, value]) => (
                              <FormControlLabel
                                key={key}
                                className={
                                  selectedFilters.imageType.includes(key)
                                    ? "checked"
                                    : ""
                                }
                                control={
                                  <Checkbox
                                    checked={selectedFilters.imageType.includes(
                                      key
                                    )}
                                    onChange={(e) =>
                                      handleChange(
                                        "imageType",
                                        key,
                                        e.target.checked
                                      )
                                    }
                                  />
                                }
                                label={t(
                                  key.replace("category_imageType_", "")
                                )}
                              />
                            )
                          )}
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div className="industryWrap">
                    <div className="indTitle">
                      <p>{t(deliveryTypeFilter?.category_display_code)}</p>
                    </div>
                    <div className="indBody">
                      <div className="checklist">
                        <FormGroup>
                          {Object.entries(deliveryTypeOptions).map(
                            ([key, value]) => {
                              return (
                                <FormControlLabel
                                  key={key}
                                  className={
                                    selectedFilters.deliveryType.includes(key)
                                      ? "checked"
                                      : ""
                                  }
                                  control={
                                    <Checkbox
                                      checked={selectedFilters.deliveryType.includes(
                                        key
                                      )}
                                      onChange={(e) =>
                                        handleChange(
                                          "deliveryType",
                                          key,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  }
                                  label={t(
                                    key.replace("category_deliveryType_", "")
                                  )}
                                />
                              );
                            }
                          )}
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div className="industryWrap">
                    <div className="indTitle">
                      <p>{t(deliveryContentFilter?.category_display_code)}</p>
                      {/* <Link to="#">{t('Reset')}</Link> */}
                    </div>
                    <div className="indBody">
                      <div className="checklist">
                        <FormGroup>
                          {Object.entries(deliveryContentOptions).map(
                            ([key, value]) => (
                              <FormControlLabel
                                key={key}
                                className={
                                  selectedFilters.deliveryContent.includes(key)
                                    ? "checked"
                                    : ""
                                }
                                control={
                                  <Checkbox
                                    checked={selectedFilters.deliveryContent.includes(
                                      key
                                    )}
                                    onChange={(e) =>
                                      handleChange(
                                        "deliveryContent",
                                        key,
                                        e.target.checked
                                      )
                                    }
                                  />
                                }
                                label={t(
                                  key.replace("category_deliveryContent_", "")
                                )}
                              />
                            )
                          )}
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  {filterTags?.models?.map((tag, index) => {
                    return (
                      <div className="industryWrap" key={index}>
                        <div className="indTitle">
                          <p>{t(tag.model_display_code)}</p>
                        </div>
                        <div className="indBody">
                          <div className="checklist">
                            {
                              <ModelOptions
                                tag={tag}
                                formValue={formValue}
                                setFormValue={setFormValue}
                              />
                            }
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="buttongrp">
                    <button onClick={handleClearAllFilters}>
                      {t("filtersclearAll")}
                    </button>
                    <button onClick={() => getSearchText(false)}>
                      {t("filtersapply")}
                    </button>
                  </div>
                </div>
              )}
              <div className={`filterResult ${isFilterOpen ? "active" : ""}`}>
                {isLoading && !searchResults?.length ? (
                  <div className="result-batch">
                    <div className={`batch-grid column${counter}`}>
                      {[...Array(counter * 3)].map((_, i) => (
                        <div className="resultBoxWrapper" key={i}>
                          <div className="resultBox">
                            <CardLoader />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : !isLoading && searchResults?.length === 0 ? (
                  <NoDataFound />
                ) : (
                  <>
                    {createBatches(searchResults || [], counter)?.map(
                      (batch, batchIndex) => (
                        <div key={batchIndex} className="result-batch">
                          <div className={`batch-grid column${counter}`}>
                            {batch?.map((item, index) => (
                              <div className="resultBoxWrapper" key={index}>
                                <div
                                  className={`resultBox ${isImageSelected(item.image_id) ? "selected" : ""}`}
                                  onClick={(e) => handleImageClick(item, e)}
                                >
                                  <LazyLoadImage
                                    src={item?.image_url}
                                    alt={`micovision`}
                                    className="img-lazy"
                                    delayTime={2000}
                                    effect="blur"
                                  />
                                  <div className="ctr-tag">
                                    <p>{(item?.ctr[1] * 100).toFixed(2)}%</p>
                                  </div>
                                </div>
                              </div>
                            ))}
                            {batchIndex ===
                              createBatches(searchResults || [], counter)
                                ?.length -
                                1 &&
                              scrollLoader &&
                              Array.from({
                                length: counter - batch.length,
                              }).map((_, i) => (
                                <div
                                  className="resultBoxWrapper"
                                  key={`scroll-loader-${i}`}
                                >
                                  <div className="resultBox">
                                    <CardLoader />
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      )
                    )}
                    {scrollLoader && (
                      <div className="result-batch">
                        <div className={`batch-grid column${counter}`}>
                          {[...Array(counter)].map((_, i) => (
                            <div
                              className="resultBoxWrapper"
                              key={`scroll-loader-${i}`}
                            >
                              <div className="resultBox">
                                <CardLoader />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <div ref={sentryRef} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default forwardRef(BrowseSearch);
