import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const ToastWrapper = () => {
  const [countdown, setCountdown] = useState(5);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleDismiss = () => {
    // toast.dismiss();
    // navigate('/login');
  };

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      padding: "12px",
      background: "linear-gradient(135deg, #ff4b4b 0%, #ff7676 100%)",
      borderRadius: "8px",
      boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
      border: "1px solid rgba(255,255,255,0.1)",
    },
    title: {
      fontWeight: "600",
      fontSize: "16px",
      color: "#fff",
      marginBottom: "8px",
      textShadow: "0 1px 2px rgba(0,0,0,0.1)",
    },
    message: {
      color: "rgba(255,255,255,0.9)",
      fontSize: "14px",
      lineHeight: "1.4",
    },
    button: {
      marginTop: "12px",
      padding: "8px 12px",
      background: "rgba(255,255,255,0.15)",
      backdropFilter: "blur(4px)",
      borderRadius: "6px",
      fontSize: "14px",
      color: "#fff",
      textAlign: "center",
      boxShadow: "inset 0 1px 1px rgba(255,255,255,0.1)",
      cursor: "pointer",
      transition: "all 0.3s ease",
      "&:hover": {
        background: "rgba(255,255,255,0.2)",
      },
    },
  };

  return (
    <div style={styles.container}>
      <div style={{ flex: 1 }}>
        <div style={styles.title}>{t("accessDenied")}</div>
        <div style={styles.message}>{t("accessDeniedText")}</div>
        <div onClick={handleDismiss} style={styles.button}>
          {t("redirecting", { countdown })}
        </div>
      </div>
    </div>
  );
};

export const showAccessDeniedToast = () => {
  return toast.error(<ToastWrapper />, {
    autoClose: 5000,
    className: "custom-toast",
    closeButton: false,
    progressStyle: {
      background: "rgba(255,255,255,0.9)",
      height: "4px",
      borderRadius: "2px",
    },
  });
};
