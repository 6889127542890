import { Navigate, Route, Routes } from "react-router-dom";
import { useAuthContext } from "../context/useAuthContext";
import DefaultLayout from "../layouts/DefaultLayout";
import UserLayout from "../layouts/UserLayout";
import { protectedRoutes, publicRoutes } from "./index";
import { getAllSearch } from "../helpers/url";
import PageLoading from "../views/PageLoading";

export const AppRouter = () => {
  const { isAuthenticated, authInitialLoading } = useAuthContext();

  // Get the current location for redirect
  const currentPath = window.location.pathname + window.location.search;
  const stateParam =
    currentPath !== "/login"
      ? `redirect:${encodeURIComponent(currentPath)}`
      : "";

  return (
    <Routes>
      {publicRoutes.map((route, idx) => (
        <Route
          key={route.path + idx}
          path={route.path}
          element={<DefaultLayout>{route.element}</DefaultLayout>}
        />
      ))}

      {protectedRoutes.map((route, idx) => (
        <Route
          key={route.path + idx}
          path={route.path}
          element={
            isAuthenticated ? (
              <UserLayout routeInfo={route}>{route.element}</UserLayout>
            ) : authInitialLoading ? (
              <PageLoading />
            ) : (
              <Navigate
                to={{
                  pathname: "/login",
                  search: `${
                    getAllSearch()["code"]
                      ? `?code=${getAllSearch()["code"]}`
                      : stateParam
                        ? `?state=${stateParam}`
                        : ""
                  }`,
                }}
              />
            )
          }
        />
      ))}
    </Routes>
  );
};
