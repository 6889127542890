import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import {
  Autocomplete,
  Checkbox,
  TextField,
  Tooltip,
  Skeleton,
} from "@mui/material";
import { post, imageGet } from "../../API/axios";
import { useAuthContext } from "../../context/useAuthContext";
import { useFilterContext } from "../../context/useFilterContext";
import SimilarImages from "../../views/Search/SimilarImages";
import {
  showAlert,
  saveConfirmationDialog,
} from "../../views/Manager/component/AlertService";
import { useEditContext } from "../../context/useEditContext";
import i18next, { t } from "i18next";
import { ReactComponent as DownloadIcon } from "../../assets/newicons/btn-save.svg";
import { ReactComponent as ShareIcon } from "../../assets/newicons/share-icon.svg";
import { ReactComponent as BackIcon } from "../../assets/newicons/btn-back.svg";
import { ReactComponent as NextIcon } from "../../assets/newicons/btn-next.svg";
import { ReactComponent as CloseIcon } from "../../assets/newicons/btn-close.svg";
import { ReactComponent as EditIcon } from "../../assets/newicons/edit.svg";
import { ReactComponent as AIIcon } from "../../assets/newicons/ico-ai.svg";
import { ReactComponent as ToolIcon } from "../../assets/newicons/info2.svg";
import { getCategoryLabel } from "../../helpers/labels";
import "./ImageDetail.scss";
import debounce from "debounce";

const ImageDetail = ({
  imageId,
  showDetails,
  setShowDetails,
  onClose,
  onPreviousImage,
  onNextImage,
  searchResults = [],
  isStandalone = false,
}) => {
  const [isEditingTags, setIsEditingTags] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [similarImages, setSimilarImages] = useState([]);
  const [showCTRDetails, setShowCTRDetails] = useState(false);
  const [saving, setSaving] = useState(false);
  const [tagInputValue, setTagInputValue] = useState("");
  const [tagData, setTagData] = useState([]);
  const [virtualizedOptions, setVirtualizedOptions] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const ITEMS_PER_PAGE = 20;
  const [initialFormState, setInitialFormState] = useState(null);
  const [imageDetailsFilters, setImageDetailsFilters] = useState({
    industry: [],
    tags: [],
    deliveryContent: [],
    deliveryType: [],
    imageType: [],
    model_filters: [],
  });

  const { filterTags } = useFilterContext();

  const { userSettings } = useAuthContext();
  const currentLanguage = i18next.language;
  const detailsRef = useRef(null);
  const en_tags = localStorage.getItem("en_tags");
  const ja_tags = localStorage.getItem("ja_tags");
  const navigate = useNavigate();
  // Add a ref to prevent duplicate API calls
  const hasLoadedData = useRef(false);
  const loadedImageId = useRef(null);

  // Extract options from filterTags
  const deliveryTypeOptions =
    filterTags?.categories?.find(
      (category) => category.category_key === "delivery_type"
    )?.category_options || {};
  const deliveryContentOptions =
    filterTags?.categories?.find(
      (category) => category.category_key === "delivery_content"
    )?.category_options || {};
  const imageTypeOptions =
    filterTags?.categories?.find(
      (category) => category.category_key === "image_type"
    )?.category_options || {};
  const industryFilter =
    filterTags?.categories?.find(
      (category) => category.category_key === "industry"
    ) || {};
  const formattedIndustryOptionsKeys = Object.entries(
    industryFilter?.category_options || {}
  ).map(([key, value]) => ({
    id: value,
    title: key,
  }));

  useEffect(() => {
    const idToFetch = imageId || showDetails?.image_id;

    // Only fetch if we have an ID and we haven't already loaded this image
    if (
      idToFetch &&
      (!loadedImageId.current || loadedImageId.current !== idToFetch)
    ) {
      loadedImageId.current = idToFetch;
      getImageDetails(idToFetch);
    }
  }, [imageId]); // Only depend on imageId, not showDetails

  useEffect(() => {
    if (isEditingTags && imageDetailsFilters) {
      setInitialFormState({ ...imageDetailsFilters });
    }
  }, [isEditingTags]);

  useEffect(() => {
    if (showDetails && imageData && filterTags) {
      const industry = showDetails?.industry?.map((ind) => {
        return getCategoryLabel("industry", ind, filterTags);
      });

      // Get tags based on language
      const currentTags =
        currentLanguage === "ja"
          ? imageData?.image_ja_tags || showDetails?.image_ja_tags || []
          : imageData?.image_en_tags || showDetails?.image_en_tags || [];

      setImageDetailsFilters({
        image_id: imageData?.image_id || showDetails?.image_id,
        image_uuid: imageData?.image_uuid || showDetails?.image_uuid,
        tags: currentTags,
        model_filters:
          imageData?.model_filters || showDetails?.model_filters || [],
        industry,
        deliveryType:
          (imageData?.delivery_type || showDetails?.delivery_type || [])?.map(
            (type) =>
              Object.keys(deliveryTypeOptions).find(
                (key) => deliveryTypeOptions[key] === type
              )
          ) || [],
        deliveryContent:
          (
            imageData?.delivery_content ||
            showDetails?.delivery_content ||
            []
          )?.map((content) =>
            Object.keys(deliveryContentOptions).find(
              (key) => deliveryContentOptions[key] === content
            )
          ) || [],
        imageType:
          (imageData?.image_type || showDetails?.image_type || [])?.map(
            (type) =>
              Object.keys(imageTypeOptions).find(
                (key) => imageTypeOptions[key] === type
              )
          ) || [],
        image_description:
          imageData?.image_description || showDetails?.image_description || "",
        image_description_ja:
          imageData?.image_description_ja ||
          showDetails?.image_description_ja ||
          "",
        text_extraction:
          imageData?.text_extraction || showDetails?.text_extraction || "",
      });

      hasLoadedData.current = true;
    }
  }, [showDetails, imageData, filterTags, currentLanguage]);

  const getSearchTags = async () => {
    try {
      const { data } = await post(`get_all_unique_tags/`, {
        lang: "all",
      });

      localStorage.setItem("en_tags", JSON.stringify(data.en));
      localStorage.setItem("ja_tags", JSON.stringify(data.ja));

      // Combine and deduplicate tags
      const combinedTags = Array.from(
        new Set([...(data?.en || []), ...(data?.ja || [])])
      );
      setTagData(combinedTags);
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    setupLanguage();
  }, [currentLanguage]);

  const searchTags = debounce(() => {
    getSearchTags();
  }, 1000);

  const setupLanguage = () => {
    if (en_tags || ja_tags) {
      setTagData([...JSON.parse(en_tags), ...JSON.parse(ja_tags)]);
    } else {
      searchTags();
    }
  };

  useEffect(() => {
    setVirtualizedOptions([]);
    setPage(0);
    setHasMore(true);
    loadMoreOptions();
  }, [tagData]);

  // Add this helper function
  const loadMoreOptions = () => {
    if (!hasMore) return;
    const start = page * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    const newOptions = tagData?.slice(start, end) || [];

    if (newOptions.length < ITEMS_PER_PAGE) {
      setHasMore(false);
    }
    setVirtualizedOptions((prev) => [...prev, ...newOptions]);
    setPage((prev) => prev + 1);
  };

  const getImageDetails = async (imageId, load = true) => {
    if (load) {
      // You can add loading state here if needed
    }

    try {
      const { image } = await post("get_image_detail/", {
        image_id: imageId,
      });

      setImageData(image);

      // Don't call setShowDetails if we already have showDetails with the same ID
      // This prevents an infinite loop with the useEffect
      if (!showDetails || showDetails.image_id !== image.image_id) {
        setShowDetails(image);
      }

      // Get similar images
      try {
        const response = await post("get_similar_images/", {
          image_id: imageId,
        });
        setSimilarImages(response || []);
      } catch (error) {
        console.error("Error fetching similar images:", error);
        setSimilarImages([]);
      }
    } catch (error) {
      console.error("Error fetching image details:", error);
    } finally {
      if (load) {
        // End loading state
      }
    }
  };

  const handleSimilarImageClick = async (image) => {
    if (isStandalone) {
      // In standalone mode, just navigate to the image detail page
      navigate(`/image/${image.similar_image_id}`);
      return;
    }

    // Find if the similar image exists in search results
    const imageInResults = searchResults.find(
      (item) => item.image_id === image.similar_image_id
    );

    if (imageInResults) {
      setShowDetails(imageInResults);
    } else {
      setShowDetails({
        ...showDetails,
        image_id: image.similar_image_id,
        image_url: image.image_url,
      });
    }

    await getImageDetails(image.similar_image_id, false);
  };

  const handleDownload = async (url) => {
    try {
      const response = await imageGet("download_image", { image_url: url });
      const downloadUrl = URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = url.substring(url.lastIndexOf("/") + 1);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
      console.log("Image downloaded successfully.");
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const handleShare = async (url) => {
    try {
      // Copy URL to clipboard
      await navigator.clipboard.writeText(
        window.location.origin +
          "/image-detail/" +
          (imageId || showDetails?.image_id)
      );
      showAlert(t("LinkCopied"), "success");
    } catch (err) {
      console.error("Failed to copy URL: ", err);
    }
  };

  const handleEdit = async () => {
    setSaving(true);

    try {
      // Construct base image data for API
      const baseImageData = {
        image_uuid: imageDetailsFilters.image_uuid,
        lang: currentLanguage,
        edit_description:
          imageDetailsFilters?.[
            currentLanguage == "ja"
              ? "image_description_ja"
              : "image_description"
          ]?.trimEnd() || "",
        edit_tags: imageDetailsFilters.tags || [],
        image_id: imageDetailsFilters.image_id,
        industry:
          imageDetailsFilters?.industry
            ?.map(
              (ind) =>
                formattedIndustryOptionsKeys.find(
                  (option) => option.title === ind
                )?.id
            )
            ?.filter(Boolean) || [],
        delivery_content:
          imageDetailsFilters?.deliveryContent
            ?.map((content) => deliveryContentOptions[content])
            ?.filter(Boolean) || [],
        delivery_type:
          imageDetailsFilters?.deliveryType
            ?.map((type) => deliveryTypeOptions[type])
            ?.filter(Boolean) || [],
        image_type:
          imageDetailsFilters?.imageType
            ?.map((type) => imageTypeOptions[type])
            ?.filter(Boolean) || [],
        model_filters: imageDetailsFilters?.model_filters,
        text_extraction: imageDetailsFilters?.text_extraction || "",
      };

      let apiPayload;

      // Handle bulk editing for similar images
      // if (checked && similarImages?.similar_image_list?.length > 1) {
      //   apiPayload = {
      //     image_list: similarImages.similar_image_list.map((image) => ({
      //       ...baseImageData,
      //       image_id: image.image_id,
      //       image_uuid: image.image_uuid,
      //     })),
      //   };
      // } else {
      // Single image edit
      apiPayload = {
        image_list: [baseImageData],
      };
      // }

      const response = await post("edit_images/", apiPayload);

      if (response.status_code === 200) {
        showAlert(t("ImageDetailsUpdatedSuccessfully"), "success");

        setIsEditingTags(false);
        getImageDetails(imageDetailsFilters.image_id, false);
      } else {
        throw new Error("API call failed");
      }
    } catch (error) {
      console.error("Error updating image:", error);
    } finally {
      setSaving(false);
    }
  };

  const hasFormChanges = () => {
    if (!initialFormState || !imageDetailsFilters) return false;

    // Check for changes in each field
    const hasTagChanges =
      JSON.stringify(initialFormState.tags) !==
      JSON.stringify(imageDetailsFilters.tags);
    const hasIndustryChanges =
      JSON.stringify(initialFormState.industry) !==
      JSON.stringify(imageDetailsFilters.industry);
    const hasDeliveryContentChanges =
      JSON.stringify(initialFormState.deliveryContent) !==
      JSON.stringify(imageDetailsFilters.deliveryContent);
    const hasDeliveryTypeChanges =
      JSON.stringify(initialFormState.deliveryType) !==
      JSON.stringify(imageDetailsFilters.deliveryType);
    const hasImageTypeChanges =
      JSON.stringify(initialFormState.imageType) !==
      JSON.stringify(imageDetailsFilters.imageType);
    const hasDescriptionChanges =
      initialFormState.image_description !==
      imageDetailsFilters.image_description;
    const hasDescriptionJaChanges =
      initialFormState.image_description_ja !==
      imageDetailsFilters.image_description_ja;

    return (
      hasTagChanges ||
      hasIndustryChanges ||
      hasDeliveryContentChanges ||
      hasDeliveryTypeChanges ||
      hasImageTypeChanges ||
      hasDescriptionChanges ||
      hasDescriptionJaChanges
    );
  };

  const saveConfirmation = (callback) => {
    saveConfirmationDialog(
      t("There are your unsaved changes do you wanna to save it?"),
      "",
      t("No"),
      t("Yes")
    ).then((result) => {
      if (result.isConfirmed) {
        callback(true);
      } else {
        callback(false);
      }
    });
  };

  const askToSave = (callback) => {
    if (isEditingTags) {
      if (hasFormChanges()) {
        saveConfirmation((result) => {
          if (!result) {
            callback();
          } else {
            handleEdit();
          }
        });
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  const handleCancelClick = () => {
    if (hasFormChanges()) {
      saveConfirmation((result) => {
        if (!result) {
          setImageDetailsFilters(initialFormState);
          setIsEditingTags(false);
          setShowDetails({ ...showDetails });
        } else {
          handleEdit();
        }
      });
    } else {
      setIsEditingTags(false);
      setShowDetails({ ...showDetails });
    }
  };

  const handleTagInput = (event, newValue, reason) => {
    if (event.key === "Enter" && tagInputValue.trim()) {
      event.preventDefault();

      if (!imageDetailsFilters.tags.includes(tagInputValue.trim())) {
        setImageDetailsFilters((prev) => ({
          ...prev,
          tags: [...(prev.tags || []), tagInputValue.trim()],
        }));
      }
      setTagInputValue("");
    }
  };

  const renderCategoryDetails = (label, items, field) => (
    <>
      {items && items?.length > 0 ? (
        items.map((item, index) => (
          <li key={index}>
            {t(
              typeof item === "string"
                ? item
                : getCategoryLabel(field, item, filterTags)
            )}
          </li>
        ))
      ) : (
        <li>{t("None")}</li>
      )}
    </>
  );

  const goToDashboard = () => {
    if (onPreviousImage) {
      onPreviousImage();
    } else {
      navigate("/dashboard");
    }
  };

  const renderModelFilters = () => {
    return imageDetailsFilters?.model_filters?.map((filter, index) => {
      // Find the matching tag from filterTags.models
      const modelTag = filterTags?.models?.find(
        (model) => model.model_key_name === filter.model_key_name
      );

      if (!modelTag) return null;

      // Convert model_options object to array format for Autocomplete
      const options = Object.entries(modelTag.model_options)
        .filter(([key]) => !["None", "All"].includes(key))
        .map(([key, value]) => ({
          label: t(key.replace(`${modelTag.model_key_name}_`, "")),
          value: value,
        }));

      return (
        <div className="addTag" key={index}>
          <h5>{t(modelTag.model_display_code)}</h5>
          {isEditingTags ? (
            <Autocomplete
              multiple
              options={options}
              disableCloseOnSelect
              getOptionLabel={(option) => option?.label || ""}
              value={options.filter(
                (option) =>
                  imageDetailsFilters.model_filters
                    .find((f) => f.model_key_name === filter.model_key_name)
                    ?.model_options?.includes(option.value) || false
              )}
              onChange={(event, newValue) => {
                const updatedModelFilters =
                  imageDetailsFilters.model_filters.map((f) =>
                    f.model_key_name === filter.model_key_name
                      ? { ...f, model_options: newValue.map((v) => v.value) }
                      : f
                  );
                setImageDetailsFilters((prev) => ({
                  ...prev,
                  model_filters: updatedModelFilters,
                }));
              }}
              renderOption={(props, option, { selected }) => {
                const { key, ...otherProps } = props;
                return (
                  <li key={key} {...otherProps}>
                    <Checkbox checked={selected} />
                    {option.label}
                  </li>
                );
              }}
              style={{
                width: "100%",
                border: "1px solid #B3B3B3",
                borderRadius: 8,
              }}
              renderInput={(params) => (
                <TextField {...params} label={t(modelTag.model_display_code)} />
              )}
            />
          ) : (
            <ul>
              {filter.model_options?.map((optionValue) => {
                const option = options.find((opt) => opt.value === optionValue);
                return option && <li key={optionValue}>{option.label}</li>;
              })}
            </ul>
          )}
        </div>
      );
    });
  };

  if (!imageData && !showDetails) {
    return (
      <div className="detailsPage">
        <div className="detailMain">
          <div className="detailImg">
            <div className="imgWrap">
              <Skeleton
                variant="rectangular"
                width="100%"
                height={400}
                animation="wave"
              />
            </div>
          </div>
          <div className="detailContent">
            <div className="detailHeader">
              <div className="detailTitle">
                <Skeleton
                  variant="text"
                  width={200}
                  height={30}
                  animation="wave"
                />
              </div>
              <div className="detailFeature">
                <Skeleton
                  variant="text"
                  width={150}
                  height={40}
                  animation="wave"
                />
              </div>
            </div>
            <div className="ctrBox">
              <div className="formate">
                <div className="formateCont">
                  <Skeleton
                    variant="text"
                    width={100}
                    height={24}
                    animation="wave"
                  />
                  <Skeleton
                    variant="text"
                    width={80}
                    height={24}
                    animation="wave"
                  />
                </div>
                <div className="formateCont">
                  <Skeleton
                    variant="text"
                    width={100}
                    height={24}
                    animation="wave"
                  />
                  <Skeleton
                    variant="text"
                    width={80}
                    height={24}
                    animation="wave"
                  />
                </div>
                <div className="formateCont">
                  <Skeleton
                    variant="text"
                    width={100}
                    height={24}
                    animation="wave"
                  />
                  <Skeleton
                    variant="text"
                    width={80}
                    height={24}
                    animation="wave"
                  />
                </div>
              </div>
            </div>
            <div className="ctrBox">
              <div className="project-filter">
                <div className="project-title">
                  <Skeleton
                    variant="text"
                    width={150}
                    height={30}
                    animation="wave"
                  />
                </div>
                <div className="project-listing">
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={100}
                    animation="wave"
                  />
                  <div className="addTagWrapper">
                    <div className="industryTagBox">
                      {[1, 2, 3].map((item) => (
                        <div className="addTag" key={item}>
                          <Skeleton
                            variant="text"
                            width={150}
                            height={30}
                            animation="wave"
                          />
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={80}
                            animation="wave"
                          />
                        </div>
                      ))}
                    </div>
                    <div className="industryTagBox">
                      {[1, 2].map((item) => (
                        <div className="addTag" key={item}>
                          <Skeleton
                            variant="text"
                            width={150}
                            height={30}
                            animation="wave"
                          />
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={80}
                            animation="wave"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ctrBox">
              <div className="ai-header">
                <Skeleton
                  variant="text"
                  width={200}
                  height={30}
                  animation="wave"
                />
              </div>
              <div className="ai-cont">
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={100}
                  animation="wave"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="detailsPage">
      <div className="detailMain">
        <div className="detailImg">
          <div className="imgWrap">
            <LazyLoadImage
              src={showDetails?.image_url}
              alt={`micovision`}
              className="img-fluid img-lazy"
              delayTime={2000}
              effect="blur"
            />
            {similarImages?.very_similar_image_list &&
              similarImages?.very_similar_image_list?.length > 0 && (
                <div className="similargrpImg">
                  <Swiper
                    modules={[Navigation]}
                    navigation={true}
                    slidesPerView="auto"
                    spaceBetween={10}
                    className="similar-thumb-slider"
                    breakpoints={{
                      320: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      768: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                      },
                      1024: {
                        slidesPerView: 6,
                        spaceBetween: 10,
                      },
                    }}
                  >
                    {similarImages?.very_similar_image_list?.map(
                      (item, index) => (
                        <SwiperSlide key={index}>
                          <div
                            className={`thumb-item ${
                              item.image_id === showDetails?.image_id
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleSimilarImageClick(item)}
                          >
                            <img
                              src={item?.image_url}
                              alt={`micovision`}
                              className="img-fluid"
                            />
                          </div>
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                </div>
              )}
          </div>
        </div>
        <div className="detailContent">
          <div className="detailHeader">
            <div className="detailTitle">
              <h5>{t("visionDetailstitle")}</h5>
            </div>
            <div className="detailFeature">
              <ul>
                <li>
                  <Link
                    to="#"
                    onClick={() => handleDownload(showDetails?.image_url)}
                  >
                    <DownloadIcon />
                  </Link>
                </li>

                {!isStandalone && (
                  <>
                    <li>
                      <Link to="#" onClick={goToDashboard}>
                        <BackIcon
                          style={
                            searchResults.findIndex(
                              (item) => item.image_id === showDetails?.image_id
                            ) === 0
                              ? { fill: "#f8f8f8", stroke: "#f8f8f8" }
                              : {}
                          }
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" onClick={onNextImage}>
                        <NextIcon
                          style={
                            searchResults.findIndex(
                              (item) => item.image_id === showDetails?.image_id
                            ) ===
                            searchResults.length - 1
                              ? { fill: "#f8f8f8", stroke: "#f8f8f8" }
                              : {}
                          }
                        />
                      </Link>
                    </li>
                  </>
                )}
                <li>
                  <Link
                    to="#"
                    onClick={() => {
                      askToSave(() => {
                        if (onClose) {
                          onClose();
                        } else if (isStandalone) {
                          window.history.back();
                        }
                      });
                    }}
                  >
                    <CloseIcon />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="ctrBox">
            <div className="formate">
              <div className="formateCont">
                <h5>{t("ctr")}</h5>
                <p
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  {imageData?.ctr && imageData.ctr.length === 2
                    ? `${t((imageData.ctr[0] * 100).toFixed(2))} ~ ${t(
                        (imageData.ctr[1] * 100).toFixed(2)
                      )} %`
                    : t("NOT_AVAILABLE")}

                  <span
                    onClick={() => setShowCTRDetails((prev) => !prev)}
                    style={{ cursor: "pointer" }}
                  >
                    <Tooltip
                      title={t("clickThrough")}
                      arrow
                      placement="right"
                      PopperProps={{
                        style: {
                          zIndex: 3000,
                          pointerEvents: "auto",
                        },
                      }}
                    >
                      <div
                        style={{ pointerEvents: "auto" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <ToolIcon />
                      </div>
                    </Tooltip>
                  </span>
                </p>
              </div>
              <div className="formateCont">
                <h5>{t("visionDetailsformat")}</h5>
                <p style={{ textAlign: "center", display: "block" }}>
                  {t(
                    imageData?.image_extension?.trim()
                      ? imageData.image_extension.toUpperCase()
                      : t("NOT_AVAILABLE")
                  )}
                </p>
              </div>
              <div className="formateCont">
                <h5>{t("visionDetailssize")}</h5>
                <p style={{ textAlign: "center" }}>
                  {t(
                    imageData?.image_size?.trim()
                      ? imageData.image_size
                      : t("NOT_AVAILABLE")
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="ctrBox">
            <div className="project-filter">
              <div className="project-title">
                <h5>{t("tags")}</h5>
                <EditIcon
                  onClick={() => setIsEditingTags(true)}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="project-listing">
                {isEditingTags ? (
                  <Autocomplete
                    multiple
                    freeSolo
                    options={virtualizedOptions}
                    disableCloseOnSelect
                    value={imageDetailsFilters.tags || []}
                    inputValue={tagInputValue}
                    onInputChange={(event, newValue) => {
                      setTagInputValue(newValue);
                    }}
                    onChange={(event, newValue) => {
                      setImageDetailsFilters((prev) => ({
                        ...prev,
                        tags: newValue,
                      }));
                    }}
                    onKeyDown={handleTagInput}
                    ListboxComponent={React.forwardRef((props, ref) => (
                      <div ref={ref}>
                        <div
                          {...props}
                          onScroll={(event) => {
                            const listboxNode = event.currentTarget;
                            if (
                              listboxNode.scrollTop +
                                listboxNode.clientHeight >=
                                listboxNode.scrollHeight - 50 &&
                              hasMore
                            ) {
                              loadMoreOptions();
                            }
                          }}
                        >
                          {props.children}
                        </div>
                      </div>
                    ))}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox checked={selected} />
                        {option}
                      </li>
                    )}
                    style={{
                      width: "100%",
                      border: "1px solid #B3B3B3",
                      borderRadius: 8,
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("filters.tags")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleTagInput(e, null, "enter");
                          }
                        }}
                      />
                    )}
                    filterOptions={(options, { inputValue }) => {
                      if (!inputValue) {
                        return options;
                      }
                      return options.filter((option) =>
                        option.toLowerCase().includes(inputValue.toLowerCase())
                      );
                    }}
                  />
                ) : (
                  <ul>
                    {imageDetailsFilters?.tags
                      ?.filter((tag) => tag?.length > 1)
                      ?.sort((a, b) => a.localeCompare(b))
                      ?.map((tag, index) => (
                        <li key={index}>{tag}</li>
                      ))}
                  </ul>
                )}

                <div className="addTagWrapper">
                  <div className="industryTagBox">
                    <div className="addTag">
                      <h5>{t(industryFilter?.category_display_code)}</h5>
                      {isEditingTags ? (
                        <Autocomplete
                          multiple
                          options={formattedIndustryOptionsKeys}
                          disableCloseOnSelect
                          getOptionLabel={(option) =>
                            option?.title ? t(option.title) : ""
                          }
                          value={formattedIndustryOptionsKeys.filter((option) =>
                            imageDetailsFilters?.industry?.includes(
                              option.title
                            )
                          )}
                          onChange={(event, newValue) => {
                            setImageDetailsFilters((prev) => ({
                              ...prev,
                              industry: newValue.map((option) => option.title),
                            }));
                          }}
                          className="autoCompleteDropdown"
                          renderOption={(props, option, { selected }) => {
                            const { key, ...otherProps } = props;
                            return (
                              <li key={key} {...otherProps}>
                                <Checkbox checked={selected} />
                                {t(option.title)}
                              </li>
                            );
                          }}
                          style={{
                            width: "100%",
                            border: "1px solid #B3B3B3",
                            borderRadius: 8,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("filters.industry")}
                            />
                          )}
                        />
                      ) : (
                        <ul>
                          {renderCategoryDetails(
                            "category_industry",
                            imageDetailsFilters?.industry,
                            "industry"
                          )}
                        </ul>
                      )}
                    </div>
                    <div className="addTag">
                      <h5>
                        {t(
                          filterTags?.categories?.find(
                            (c) => c.category_key === "delivery_content"
                          )?.category_display_code
                        )}
                      </h5>
                      {isEditingTags ? (
                        <Autocomplete
                          multiple
                          options={Object.keys(deliveryContentOptions)}
                          disableCloseOnSelect
                          getOptionLabel={(option) =>
                            option
                              ? t(
                                  option.replace(
                                    "category_deliveryContent_",
                                    ""
                                  )
                                )
                              : ""
                          }
                          value={imageDetailsFilters.deliveryContent}
                          onChange={(event, newValue) => {
                            setImageDetailsFilters((prev) => ({
                              ...prev,
                              deliveryContent: newValue,
                            }));
                          }}
                          className="autoCompleteDropdown"
                          renderOption={(props, option, { selected }) => {
                            const { key, ...otherProps } = props;
                            return (
                              <li key={key} {...otherProps}>
                                <Checkbox checked={selected} />
                                {t(
                                  option.replace(
                                    "category_deliveryContent_",
                                    ""
                                  )
                                )}
                              </li>
                            );
                          }}
                          style={{
                            width: "100%",
                            border: "1px solid #B3B3B3",
                            borderRadius: 8,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("filters.deliveryContent")}
                            />
                          )}
                        />
                      ) : (
                        <ul>
                          {renderCategoryDetails(
                            "category_delivery_content",
                            imageDetailsFilters?.deliveryContent,
                            "delivery_content"
                          )}
                        </ul>
                      )}
                    </div>
                    <div className="addTag">
                      <h5>
                        {t(
                          filterTags?.categories?.find(
                            (c) => c.category_key === "delivery_type"
                          )?.category_display_code
                        )}
                      </h5>
                      {isEditingTags ? (
                        <Autocomplete
                          multiple
                          options={Object.keys(deliveryTypeOptions)}
                          disableCloseOnSelect
                          getOptionLabel={(option) =>
                            option
                              ? t(option.replace("category_deliveryType_", ""))
                              : ""
                          }
                          value={imageDetailsFilters.deliveryType}
                          onChange={(event, newValue) => {
                            setImageDetailsFilters((prev) => ({
                              ...prev,
                              deliveryType: newValue,
                            }));
                          }}
                          className="autoCompleteDropdown"
                          renderOption={(props, option, { selected }) => {
                            const { key, ...otherProps } = props;
                            return (
                              <li key={key} {...otherProps}>
                                <Checkbox checked={selected} />
                                {t(
                                  option.replace("category_deliveryType_", "")
                                )}
                              </li>
                            );
                          }}
                          style={{
                            width: "100%",
                            border: "1px solid #B3B3B3",
                            borderRadius: 8,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("filters.deliveryType")}
                            />
                          )}
                        />
                      ) : (
                        <ul>
                          {renderCategoryDetails(
                            "category_delivery_type",
                            imageDetailsFilters?.deliveryType,
                            "delivery_type"
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="industryTagBox">
                    <div className="addTag">
                      <h5>{t("imageType")}</h5>
                      {isEditingTags ? (
                        <Autocomplete
                          multiple
                          options={Object.keys(imageTypeOptions)}
                          disableCloseOnSelect
                          getOptionLabel={(option) => (option ? t(option) : "")}
                          value={imageDetailsFilters?.imageType || []}
                          onChange={(event, newValue) => {
                            setImageDetailsFilters((prev) => ({
                              ...prev,
                              imageType: newValue,
                            }));
                          }}
                          className="autoCompleteDropdown"
                          renderOption={(props, option, { selected }) => {
                            const { key, ...otherProps } = props;
                            return (
                              <li key={key} {...otherProps}>
                                <Checkbox checked={selected} />
                                {t(option.replace("category_imageType_", ""))}
                              </li>
                            );
                          }}
                          style={{
                            width: "100%",
                            border: "1px solid #B3B3B3",
                            borderRadius: 8,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("filters.imageType")}
                            />
                          )}
                        />
                      ) : (
                        <ul>
                          {renderCategoryDetails(
                            "category_image_type",
                            imageDetailsFilters?.imageType,
                            "image_type"
                          )}
                        </ul>
                      )}
                    </div>
                    {renderModelFilters()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ctrBox">
            <div className="ai-header">
              <h5>
                <AIIcon /> {t("aiDescriptiontitle")}
              </h5>
            </div>
            <div className="ai-cont">
              {isEditingTags ? (
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  defaultValue={
                    (currentLanguage == "ja"
                      ? imageData?.image_description_ja
                      : imageData?.image_description) || ""
                  }
                  onChange={(e) => {
                    setImageDetailsFilters((prev) => ({
                      ...prev,
                      [currentLanguage == "ja"
                        ? "image_description_ja"
                        : "image_description"]: e.target.value,
                    }));
                  }}
                  placeholder={t("Enter AI description")}
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    border: "1px solid #B3B3B3",
                    borderRadius: "8px",
                  }}
                />
              ) : (
                <p>
                  {t(
                    imageData?.[
                      currentLanguage === "ja"
                        ? "image_description_ja"
                        : "image_description"
                    ]
                  )}
                </p>
              )}
            </div>
          </div>
          {isEditingTags && (
            <div className="buttonGrp">
              <button onClick={handleCancelClick}>{t("cancel")}</button>
              <button onClick={handleEdit}>{t("save")}</button>
            </div>
          )}
        </div>
      </div>
      {similarImages?.similar_image_list?.length > 0 && (
        <SimilarImages
          similarData={similarImages?.similar_image_list}
          handleSimilarImageClick={handleSimilarImageClick}
        />
      )}
    </div>
  );
};

export default ImageDetail;
