import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { newGet, newPut } from "../../../API/axios";
import { showAlert } from "../../Manager/component/AlertService";
import { useReferenceContext } from "../../../context/useReferenceContext";
import { useLocation } from "react-router-dom";

const RenameFilesPopUp = ({ fileData, setFiledata }) => {
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const { setUploadedFiles } = useReferenceContext();
  const location = useLocation();
  let { createdProjectId } = location.state || {};

  const getUploadedFiles = async () => {
    try {
      const data = await newGet(`board/get?project_id=${createdProjectId}`);
      // const designFilterData = data?.result.filter((design => design?.design_id === design_id))
      setUploadedFiles(data?.result?.files);
    } catch (error) {
      console.error(error);
    }
  };

  const renameFile = async (id) => {
    setLoading(true);
    const formData = {
      file_id: id,
      file_name: fileData?.file_name,
    };

    try {
      const data = await newPut(`file/update`, formData);
      if (data.status_code == 200 || data.status_code == 201) {
        getUploadedFiles();
        setLoading(false);
        const modalElement = document.getElementById("renameModal");
        const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
        modalInstance.hide();

        showAlert(t("fileNameChanged"), "success");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="modal fade"
      id="renameModal"
      tabIndex="-1"
      aria-labelledby="renameModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content px-3">
          <div className="modal-header border-0 font-noto-sans">
            <div className="upload_design fw-bolder">{"renameFile"}</div>
            {/* <div className='cursor_pointer' data-bs-dismiss="modal" >
                                            <img src={Close} alt='Close' />
                                        </div> */}
          </div>
          <div className="modal-body font-noto-sans">
            <input
              type="text"
              name="required_sheet"
              value={fileData?.file_name}
              onChange={(e) => {
                let inputValue = e.target.value.trim();

                // Find last dot (`.`) position
                let lastDotIndex = fileData?.file_name.lastIndexOf(".");
                console.log("lastDotIndex", lastDotIndex);
                // Extract filename and extension
                let baseName =
                  lastDotIndex !== -1
                    ? fileData?.file_name.substring(0, lastDotIndex)
                    : fileData?.file_name;
                let extension =
                  lastDotIndex !== -1
                    ? fileData?.file_name.substring(lastDotIndex)
                    : "";

                // Ensure the filename part remains valid
                let newFileName = inputValue.split(".")[0]; // Keep only the part before `.`

                // // Prevent empty filename
                // if (newFileName.trim() === "") {
                //   newFileName = "untitled"; // Default filename
                // }

                // Reconstruct filename + extension
                let updatedFileName = newFileName + extension;

                // Update state
                setFiledata({ ...fileData, file_name: updatedFileName });
              }}
              autoComplete="off"
              // onWheel={(e) => e.preventDefault()}
              className="form-control from-input"
              id="renameFile"
              // placeholder="Enter Miro Board URL"
              max={50}
            ></input>
          </div>
          <div className="modal-footer border-0">
            <p
              type="button"
              className="cancel_modal_footer"
              data-bs-dismiss="modal"
            >
              {t("cancel")}
            </p>
            <button
              type="button"
              className="send_modal_footer"
              disabled={loading || !fileData?.file_name?.trim()}
              onClick={() => renameFile(fileData?.file_id)}
            >
              {!loading ? `${t("save")}` : ""}
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </React.Fragment>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenameFilesPopUp;
