import DatePicker from "react-datepicker";
import CreateProjectFormLoader from "../skeletonLoaders/CreateProjectFormLoader";
import calendar_month from "../../../assets/newicons/calendar_month.svg";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { Button, CircularProgress } from "@mui/material";
import { red } from "@mui/material/colors";
import { newGet } from "../../../API/axios";
import { toast } from "react-toastify";

const Timeline = ({
  timeLineformValue,
  setTimeLineFormValue,
  errors,
  setErrors,
  updateTimeline,
  loading,
  operation,
  createdProjectId,
  isProjectActive,
  //   handleDateChange, validateInput
}) => {
  const initialDateRef = useRef({});
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState("");
  const [designSubmissionDue, setDesignSubmissionDue] = useState("");
  const [clientFeedbackDue, setClientFeedbackDue] = useState("");
  const [implementationDate, setImplementationDate] = useState(
    timeLineformValue?.implementation_date || null
  );
  const [finalDeliveryDate, setFinalDeliveryDate] = useState(
    timeLineformValue?.final_delivery_date || null
  );

  const [dates, setDates] = useState({
    start_date: timeLineformValue?.start_date || null,
    design_submission_due: timeLineformValue?.design_submission_due || null,
    client_feedback_due: timeLineformValue?.client_feedback_due || null,
    implementation_date: timeLineformValue?.implementation_date || null,
    final_delivery_date: timeLineformValue?.final_delivery_date || null,
  });

  const [fieldsEnabled, setFieldsEnabled] = useState({
    start_date: true,
    design_submission_due: true,
    client_feedback_due: false,
    implementation_date: false,
    final_delivery_date: false,
  });

  const [timelineFieldError, setTimelineFieldError] = useState({
    start_date: "",
    design_submission_due: "",
    client_feedback_due: "",
    implementation_date: "",
    final_delivery_date: "",
  });

  const [initialDates, setInitialDates] = useState({});

  const [error, setError] = useState("");
  // const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  const [forceReset, setForceReset] = useState(false); // Force DatePicker to reset

  const isSaveButtonEnabled = dates.start_date && dates.design_submission_due;

  const operationRef = useRef(operation);

  const generateHighlightedDates = (startDate) => {
    const dates = [];
    const currentDate = new Date(startDate);

    for (let i = 0; i < 365; i++) {
      // Highlight up to 1 year
      currentDate.setDate(currentDate.getDate() + 1);
      dates.push(new Date(currentDate)); // Clone the date and push it
    }

    return dates;
  };

  useEffect(() => {
    initialDateRef.current = initialDates;
  }, [initialDates]);

  const submitHandler = () => {
    const validationError = validateDates(dates);
    setTimelineFieldError(validationError);
    if (Object.values(validationError).length) {
      toast.error(t("CORRECT_DATES_OR_CANCEL"));
      return;
    }
    updateTimeline();
    const modalElement = document.getElementById("timeline");
    const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
    modalInstance.hide();
  };

  // const highlightedDates = generateHighlightedDates(highlightFrom);

  useEffect(() => {
    operationRef.current = operation;
  }, [operation]);

  useEffect(() => {
    if (operation === t("updateTimeline")) {
      setFieldsEnabled({
        start_date: true,
        design_submission_due: true,
        client_feedback_due: true,
        implementation_date: true,
        final_delivery_date: true,
      });

      setInitialDates({
        start_date: timeLineformValue?.start_date || null,
        design_submission_due: timeLineformValue?.design_submission_due || null,
        client_feedback_due: timeLineformValue?.client_feedback_due || null,
        implementation_date: timeLineformValue?.implementation_date || null,
        final_delivery_date: timeLineformValue?.final_delivery_date || null,
      });
    }
  }, [operation, timeLineformValue]);

  useEffect(() => {
    if (timeLineformValue) {
      setDates({
        start_date: timeLineformValue?.start_date
          ? new Date(timeLineformValue.start_date)
          : null,
        design_submission_due: timeLineformValue?.design_submission_due
          ? new Date(timeLineformValue.design_submission_due)
          : null,
        client_feedback_due: timeLineformValue?.client_feedback_due
          ? new Date(timeLineformValue.client_feedback_due)
          : null,
        implementation_date: timeLineformValue?.implementation_date
          ? new Date(timeLineformValue.implementation_date)
          : null,
        final_delivery_date: timeLineformValue?.final_delivery_date
          ? new Date(timeLineformValue.final_delivery_date)
          : null,
      });

      // setFieldsEnabled({
      //   start_date: true,
      //   design_submission_due: true,
      //   client_feedback_due: true,
      //   implementation_date: true,
      //   final_delivery_date: true,
      // });

      setError(""); // Reset error when timeline data changes
    }
  }, [timeLineformValue]);

  const skeletonCount = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
  ];

  const validateDates = (updatedDates) => {
    const {
      start_date,
      design_submission_due,
      client_feedback_due,
      implementation_date,
      final_delivery_date,
    } = updatedDates;

    const errorJson = {};

    const datePairs = [
      {
        checkDate: start_date,
        otherDates: {
          design_submission_due,
          client_feedback_due,
          implementation_date,
          final_delivery_date,
        },
        message: "startDate",
      },
      {
        checkDate: design_submission_due,
        otherDates: {
          client_feedback_due,
          implementation_date,
          final_delivery_date,
        },
        message: "designSubmissionDue",
      },
      {
        checkDate: client_feedback_due,
        otherDates: { implementation_date, final_delivery_date },
        message: "feedbackDue",
      },
      {
        checkDate: implementation_date,
        otherDates: { final_delivery_date },
        message: "implementationDate",
      },
    ];

    for (const { checkDate, otherDates, message } of datePairs) {
      if (checkDate) {
        for (const otherDateKey in otherDates) {
          if (
            otherDates[otherDateKey] &&
            checkDate > otherDates[otherDateKey]
          ) {
            // errorJson[otherDateKey] = `${t("TIMELINE_ERROR_MSG")} ${t(message)}`;
            errorJson[otherDateKey] = t("pleaseCorrectPreviousDates");
            break;
          }
        }
        if (Object.keys(errorJson).length) {
          return errorJson;
        }
      }
    }

    return errorJson;
  };

  const handleDateChangeLocal = (date, key) => {
    const updatedDates = { ...dates, [key]: date };
    const validationError = validateDates(updatedDates);
    setDates(updatedDates);
    handleDateChange(date, key); // Call parent update function

    setTimelineFieldError(validationError);
    if (Object.values(validationError || {}).length) {
      return;
      // setError(validationError);
    } else {
      setError(""); // Clear any existing error

      // Enable the next fields based on the selected date
      const fields = { ...fieldsEnabled };

      if (key === "start_date" && date) {
        fields.design_submission_due = true;
        fields.client_feedback_due = true;
        fields.implementation_date = true;
        fields.final_delivery_date = true;
      }
      if (key === "design_submission_due" && date) {
        fields.client_feedback_due = true;
        fields.implementation_date = true;
        fields.final_delivery_date = true;
      }
      if (key === "client_feedback_due" && date) {
        fields.implementation_date = true;
        fields.final_delivery_date = true;
      }
      if (key === "implementation_date" && date) {
        fields.final_delivery_date = true;
      }

      setFieldsEnabled(fields);
    }
  };

  const addYears = (date, years) => {
    const result = new Date(date);
    result.setFullYear(result.getFullYear() + years);
    return result;
  };

  //   const handleImplementationDateChange = (date) => {
  //     setImplementationDate(date);
  //     handleDateChange(date, "implementation_date");
  //     if (finalDeliveryDate && date > finalDeliveryDate) {
  //       setFinalDeliveryDate(null);
  //       handleDateChange(null, "final_delivery_date");
  //     }
  //   };

  const handleImplementationDateChange = (date) => {
    setImplementationDate(date);
    handleDateChange(date, "implementation_date");
    if (finalDeliveryDate && date > finalDeliveryDate) {
      setFinalDeliveryDate(null);
      handleDateChange(null, "final_delivery_date");
    }
  };

  const handleFinalDeliveryDateChange = (date) => {
    setFinalDeliveryDate(date);
    handleDateChange(date, "final_delivery_date");
  };

  const handleDateChange = (date, name) => {
    // if (isValidYear(date)) {
    // if (validateDates) {
    const formattedDate = date ? format(date, "MM-dd-yyyy") : null;
    setTimeLineFormValue({ ...timeLineformValue, [name]: formattedDate });
    setErrors({ ...errors, [name]: "" });
  };

  // Validating the wrong Input in Date Picker
  const validateInput = (e) => {
    // const datePattern = /^\d{2}\/\d{2}\/\d{4}$/; // MM/DD/YYYY format
    const datePattern = /^\d{2}-\d{2}-\d{4}$/; // MM-DD-YYYY format
    if (!datePattern.test(e.target.value)) {
      e.preventDefault(); // Prevent invalid input
    }
  };

  const resetFormState = () => {
    if (operationRef.current !== t("updateTimeline")) {
      // Reset for new timeline
      setDates({
        start_date: null,
        design_submission_due: null,
        client_feedback_due: null,
        implementation_date: null,
        final_delivery_date: null,
      });

      setTimeLineFormValue({
        start_date: null,
        design_submission_due: null,
        client_feedback_due: null,
        implementation_date: null,
        final_delivery_date: null,
      });

      setFieldsEnabled({
        start_date: true,
        design_submission_due: true,
        client_feedback_due: false,
        implementation_date: false,
        final_delivery_date: false,
      });
    } else {
      // Reset to initial values for update
      setDates({ ...(initialDateRef.current || {}) });
      setTimeLineFormValue({ ...timeLineformValue });
      setFieldsEnabled({
        start_date: true,
        design_submission_due: true,
        client_feedback_due: true,
        implementation_date: true,
        final_delivery_date: true,
      });
    }

    setError(""); // Clear any errors
    setForceReset((prev) => !prev); // Toggle to force DatePicker re-render
  };

  // Add useEffect to handle modal close event
  useEffect(() => {
    // Add event listener for modal hidden event
    const timelineModal = document.getElementById("timeline");
    const handleModalHidden = () => {
      resetFormState();
      getProjectTimeline();
      setTimelineFieldError({});
    };

    if (timelineModal) {
      timelineModal.addEventListener("hidden.bs.modal", handleModalHidden);
    }

    // Cleanup listener on component unmount
    return () => {
      if (timelineModal) {
        timelineModal.removeEventListener("hidden.bs.modal", handleModalHidden);
      }
    };
  }, [operation]);

  const getProjectTimeline = async () => {
    try {
      await newGet(`timeline/getTimeline?project_id=${createdProjectId}`).then(
        (res) => {
          if (res?.status_code == 1050) {
            const resultObj = res?.result || {};
            setDates({
              start_date: resultObj?.start_date?.date
                ? format(resultObj?.start_date?.date, "MM-dd-yyyy")
                : null,
              design_submission_due: resultObj?.design_submission?.date
                ? format(resultObj?.design_submission?.date, "MM-dd-yyyy")
                : null,
              client_feedback_due: resultObj?.client_feedback?.date
                ? format(resultObj?.client_feedback?.date, "MM-dd-yyyy")
                : null,
              implementation_date: resultObj?.implementation?.date
                ? format(resultObj?.implementation?.date, "MM-dd-yyyy")
                : null,
              final_delivery_date: resultObj?.final_delivery?.date
                ? format(resultObj?.final_delivery?.date, "MM-dd-yyyy")
                : null,
            });

            if (resultObj !== null) {
              setTimeLineFormValue({
                start_date: resultObj?.start_date?.date
                  ? format(resultObj?.start_date?.date, "MM-dd-yyyy")
                  : null,
                design_submission_due: resultObj?.design_submission?.date
                  ? format(resultObj?.design_submission?.date, "MM-dd-yyyy")
                  : null,
                client_feedback_due: resultObj?.client_feedback?.date
                  ? format(resultObj?.client_feedback?.date, "MM-dd-yyyy")
                  : null,
                implementation_date: resultObj?.implementation?.date
                  ? format(resultObj?.implementation?.date, "MM-dd-yyyy")
                  : null,
                final_delivery_date: resultObj?.final_delivery?.date
                  ? format(resultObj?.final_delivery?.date, "MM-dd-yyyy")
                  : null,
              });
            }
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const openToDate = (date) => {
    const today = new Date();
    return date < today ? today : date;
  };

  return (
    <div>
      {/* <div className=''>
                {skeletonCount.map((count, i) => (
                    <div key={count.id}>
                        <div className='w-50 h-25'>
                            <CreateProjectFormLoader height={30} />
                        </div>
                        <CreateProjectFormLoader />
                    </div>
                ))}
            </div>  */}

      {/* Start date */}
      <div className="py-2">
        <div className="w-100 mb-2">
          <div className="mb-1 w-100">
            <label htmlFor="start_date" className="col-form-label">
              {t("startDate")}
              <span style={{ color: "red" }}>*</span>
            </label>
            {/* <div className={`${IsFormForUpadate && "bg-gray-input rounded-3"}`}> */}
            <div
              className="w-100 position-relative timeline_date_picker"
              id="start_date"
            >
              {error && <div className="error-message">{error}</div>}
              <DatePicker
                selected={dates.start_date}
                openToDate={openToDate(dates.start_date)}
                onChange={(date) => handleDateChangeLocal(date, "start_date")}
                placeholderText={t("mm-dd-yyyy")}
                className="input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-2 from-input"
                dateFormat="MM-dd-yyyy"
                minDate={new Date()}
                type="date"
                focusSelectedMonth={false}
                disabled={!fieldsEnabled.start_date}
                highlightDates={[
                  {
                    "highlight-after": generateHighlightedDates(
                      dates.design_submission_due
                    ),
                  },
                ]}
                // maxDate={dates.design_submission_due || addYears(new Date(), 2)}
                onChangeRaw={validateInput}
              />
              <img
                src={calendar_month}
                alt=" calendar_month"
                className="input-date-icon"
                style={{
                  pointerEvents: "none",
                }}
              />
            </div>
            {/* </div> */}
          </div>
          {timelineFieldError.start_date ? (
            <p className="project-form-error">
              {timelineFieldError.start_date}
            </p>
          ) : null}
        </div>

        {/* Design Submission date */}
        <div className="w-100  mb-2">
          <div className="mb-1 w-100">
            <label htmlFor="due_date" className="col-form-label">
              {t("designSubmissionDue")} <span style={{ color: "red" }}>*</span>
            </label>
            <div className="">
              <div
                className="position-relative timeline_date_picker"
                id="due_date"
              >
                <DatePicker
                  selected={dates.design_submission_due}
                  openToDate={openToDate(dates.design_submission_due)}
                  onChange={(date) =>
                    handleDateChangeLocal(date, "design_submission_due")
                  }
                  placeholderText={t("mm-dd-yyyy")}
                  className={`input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-2 from-input ${
                    !fieldsEnabled.design_submission_due ? "disabled-input" : ""
                  }`}
                  // className="input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-2 from-input"
                  dateFormat="MM-dd-yyyy"
                  type="date"
                  minDate={new Date(Math.max(dates.start_date, new Date()))}
                  // maxDate={dates.client_feedback_due || addYears(new Date(), 2)}
                  highlightDates={[
                    {
                      "highlight-after": generateHighlightedDates(
                        dates.client_feedback_due
                      ),
                    },
                  ]}
                  onChangeRaw={validateInput}
                  disabled={!fieldsEnabled.design_submission_due}
                />
                <img
                  src={calendar_month}
                  alt="calendar_month"
                  className="input-date-icon"
                  style={{
                    pointerEvents: "none",
                  }}
                />
              </div>
            </div>
          </div>
          {timelineFieldError.design_submission_due ? (
            <p className="project-form-error">
              {timelineFieldError.design_submission_due}
            </p>
          ) : null}
        </div>

        {/* Feedback due Date */}
        <div className="w-100  mb-2">
          <div className="mb-1 w-100">
            <label htmlFor="feedback_date" className="col-form-label">
              {t("feedbackDue")}
              {/* <span style={{color:"red"}}>*</span> */}
            </label>
            <div className="">
              <div
                className="position-relative timeline_date_picker"
                id="feedback_date"
              >
                <DatePicker
                  selected={dates.client_feedback_due}
                  openToDate={openToDate(dates.client_feedback_due)}
                  onChange={(date) =>
                    handleDateChangeLocal(date, "client_feedback_due")
                  }
                  placeholderText={t("mm-dd-yyyy")}
                  className={`input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-2 from-input ${
                    !fieldsEnabled.client_feedback_due ? "disabled-input" : ""
                  }`}
                  // className="input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-2 from-input"
                  dateFormat="MM-dd-yyyy"
                  type="date"
                  minDate={
                    new Date(Math.max(dates.design_submission_due, new Date()))
                  }
                  // maxDate={dates.implementation_date || addYears(new Date(), 2)}
                  highlightDates={[
                    {
                      "highlight-after": generateHighlightedDates(
                        dates.implementation_date
                      ),
                    },
                  ]}
                  onChangeRaw={validateInput}
                  disabled={
                    !fieldsEnabled.client_feedback_due ||
                    !dates.design_submission_due
                  }
                />
                <img
                  src={calendar_month}
                  alt="calendar_month"
                  className="input-date-icon"
                  style={{
                    pointerEvents: "none",
                  }}
                />
              </div>
            </div>
          </div>
          {timelineFieldError.client_feedback_due ? (
            <p className="project-form-error">
              {timelineFieldError.client_feedback_due}
            </p>
          ) : null}
        </div>

        {/* Implementation date */}
        <div className="w-100  mb-2">
          <div className="mb-1 w-100">
            <label htmlFor="implement_date" className="col-form-label">
              {t("implementationDate")}{" "}
              {/* <span style={{color:"red"}}>*</span> */}
            </label>
            <div className="">
              <div
                className="position-relative timeline_date_picker"
                id="implement_date"
              >
                <DatePicker
                  selected={dates.implementation_date}
                  openToDate={openToDate(dates.implementation_date)}
                  onChange={(date) =>
                    handleDateChangeLocal(date, "implementation_date")
                  }
                  placeholderText={t("mm-dd-yyyy")}
                  className={`input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-2 from-input ${
                    !fieldsEnabled.implementation_date ? "disabled-input" : ""
                  }`}
                  // className="input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-2 from-input"
                  dateFormat="MM-dd-yyyy"
                  type="date"
                  minDate={
                    new Date(Math.max(dates.client_feedback_due, new Date()))
                  }
                  // maxDate={dates.final_delivery_date || addYears(new Date(), 2)}
                  highlightDates={[
                    {
                      "highlight-after": generateHighlightedDates(
                        dates.final_delivery_date
                      ),
                    },
                  ]}
                  onChangeRaw={validateInput}
                  disabled={
                    !fieldsEnabled.implementation_date ||
                    !dates.client_feedback_due
                  }
                />
                <img
                  src={calendar_month}
                  alt="calendar_month"
                  className="input-date-icon"
                  style={{
                    pointerEvents: "none",
                  }}
                />
              </div>
            </div>
          </div>
          {timelineFieldError.implementation_date ? (
            <p className="project-form-error">
              {timelineFieldError.implementation_date}
            </p>
          ) : null}
        </div>

        {/* Final delvery date */}
        <div className="w-100">
          <div className="mb-1 w-100">
            <label htmlFor="delivery_date" className="col-form-label">
              {t("finaldeliveryDate")}{" "}
              {/* <span style={{color:"red"}}>*</span>   */}
            </label>
            <div className="">
              <div
                className="position-relative timeline_date_picker"
                id="delivery_date"
              >
                <DatePicker
                  selected={dates.final_delivery_date}
                  openToDate={openToDate(dates.final_delivery_date)}
                  onChange={(date) =>
                    handleDateChangeLocal(date, "final_delivery_date")
                  }
                  placeholderText={t("mm-dd-yyyy")}
                  className={`input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-2 from-input ${
                    !fieldsEnabled.final_delivery_date ? "disabled-input" : ""
                  }`}
                  // className="input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-2 from-input"
                  dateFormat="MM-dd-yyyy"
                  type="date"
                  minDate={
                    new Date(Math.max(dates.implementation_date, new Date()))
                  }
                  maxDate={addYears(new Date(), 2)}
                  onChangeRaw={validateInput}
                  disabled={
                    !fieldsEnabled.final_delivery_date ||
                    !dates.implementation_date
                  }
                />
                <img
                  src={calendar_month}
                  alt="calendar_month"
                  className="input-date-icon"
                  style={{
                    pointerEvents: "none",
                  }}
                />
              </div>
            </div>
          </div>
          {timelineFieldError.final_delivery_date ? (
            <p className="project-form-error">
              {timelineFieldError.final_delivery_date}
            </p>
          ) : null}
        </div>
        <style>
          {`
        .disabled-input {
          background-color: #f0f0f0;
          color: #a0a0a0;
          cursor: not-allowed;
        }
        .send_modal_footer:disabled {
          width: 100px !important;
          background-color: #f0f0f0;
          color: #a0a0a0;
          cursor: not-allowed;
        }
      `}
        </style>

        <div className="modal-footer border-0">
          <p
            type="button"
            className="cancel_modal_footer"
            data-bs-dismiss="modal"
            onClick={resetFormState}
          >
            {t("cancel")}
          </p>
          <button
            type="button"
            className="send_modal_footer"
            // data-bs-dismiss="modal"
            disabled={!isSaveButtonEnabled || loading || !isProjectActive()}
            // disabled={
            //   // loading || isSaveDisabled
            //   // timeLineformValue?.start_date === null || loading ? true : false
            // }
            onClick={() => submitHandler()}
          >
            {!loading ? `${t("update")}` : ""}
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
            </React.Fragment>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
