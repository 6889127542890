import React from "react";
import plusIcon from "../../../assets/newicons/add.svg";
import aiIcon from "../../../assets/newicons/ai-white-icon.svg";
import Close from "../../../assets/newicons/close.svg";
import revertIcon from "../../../assets/newicons/refresh.svg";
import removeIcon from "../../../assets/newicons/remove-icon.svg";
import brushImage from "../../../assets/newimages/magic-brush-img.png";
import cropImage from "../../../assets/newimages/image.png";
import chooseImage from "../../../assets/newimages/choose-small-image.png";
import noImage from "../../../assets/newimages/tabs-image.png";
import heartIcon from "../../../assets/newicons/heart-icon.svg";
import detectObject from "../../../assets/newicons/detect-object.svg";
import removeIcons from "../../../assets/newicons/remove-Icon copy.svg";
import replaceIocn from "../../../assets/newicons/replace-icon.svg";
import refineIcon from "../../../assets/newicons/refine-icon.svg";
import extractIcon from "../../../assets/newicons/extract-icon.svg";
import deselectIcon from "../../../assets/newicons/deselect-icon.svg";
import arrow from "../../../assets/newicons/arrow-left-dark.svg";

import "../../Manager/reference.scss";
import "./magic-brush.scss";

const MagicBrush = () => {
  return (
    <div>
      <div
        className="modal fade magic-brush-popup"
        id="magicBrush"
        aria-hidden="true"
        aria-labelledby="magicBrushLabel"
        tabIndex="-1"
      >
        <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content h-100 mx-sm-4 mx-1">
            <div
              className="d-flex w-100 py-sm-3 py-2 px-4 bg-color align-items-center justify-content-between position-sticky top-0"
              style={{ zIndex: "2" }}
            >
              <h4 className="popup-heading mb-0">Magic brush</h4>
              <div type="button" data-bs-dismiss="modal">
                <img src={Close} alt="Close" className="" />
              </div>
            </div>
            <div className="modal-body py-0">
              {/* MicoAI_Magic brush */}

              {/* <div className='position-relative d-flex justify-content-center align-items-center h-100'>
                                <div className='magic-brush-zoom d-flex flex-column gap-2 py-2 align-items-center bg-white'>
                                    <button className='px-2 py-0 bg-transparent border-0 font-noto-sans'>
                                        <img src={plusIcon} alt='plusIcon' width={20} />
                                    </button>
                                    <button className='px-2 py-0 bg-transparent border-0 font-noto-sans'>
                                        <img src={removeIcon} alt='removeIcon' width={20} className='rotete-image' />
                                    </button>
                                    <div className='border-top pt-2'>
                                        <button className='px-2 py-0 bg-transparent border-0 font-noto-sans'>
                                            <img src={revertIcon} alt='revertIcon' width={18} />
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className='brush-size-wrapper py-2 px-2'>
                                            <div className="w-100 d-flex gap-2 align-items-center px-sm-3 px-2">
                                                <label for="formControlRange" className='m-0 text-nowrap'>Brush size</label>
                                                <div className='d-flex gap-2 align-items-center'>
                                                    <input type="range" className="form-control-range w-100 cursor_pointer" style={{ height: "5px", accentColor: "black" }} id="formControlRange" />
                                                </div>
                                            </div>
                                            <div className='ps-sm-3 ps-2 border-start'>
                                                <button
                                                    className='d-flex align-items-center justify-content-center gap-2 px-sm-4 px-3 border-0 black-gradiant-btn py-sm-2 py-1 rounded-pill '
                                                    style={{ height: "auto", width: "auto" }}                                       >
                                                    <img src={aiIcon} alt='aiIcon' />
                                                    <span>Erase</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='magic-brush-container position-relative'>
                                        <img src={brushImage} alt='brushImage' />
                                    </div>
                                </div>
                            </div> */}

              {/* image crop */}
              {/* image bottom className="crop-container2" */}
              {/* 
                            <div>
                                <div className='position-relative d-flex justify-content-center align-items-center'>
                                    <div className='magic-brush-zoom d-flex flex-column gap-2 py-2 align-items-center bg-white'>
                                        <button className='px-2 py-0 bg-transparent border-0 font-noto-sans'>
                                            <img src={plusIcon} alt='plusIcon' width={20} />
                                        </button>
                                        <button className='px-2 py-0 bg-transparent border-0 font-noto-sans'>
                                            <img src={removeIcon} alt='removeIcon' width={20} className='rotete-image' />
                                        </button>
                                        <div className='border-top pt-2'>
                                            <button className='px-2 py-0 bg-transparent border-0 font-noto-sans'>
                                                <img src={revertIcon} alt='revertIcon' width={18} />
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <div className='brush-size-wrapper px-2 w-100' style={{ maxWidth: "400px" }}>
                                                <div className="w-100 d-flex gap-2 align-items-center px-sm-3 py-2 px-2 w-100">
                                                    <input type="checkbox" className="form-control-range cursor_pointer" style={{ height: "20px", accentColor: "black", width: "auto" }} id="formControlRange" />
                                                    <label for="formControlRange" className='m-0 text-nowrap cursor_pointer'>Lock aspect ratio</label>
                                                </div>
                                                <div className='ps-sm-3 ps-2 py-2 border-start'>
                                                    <button
                                                        className='d-flex align-items-center justify-content-center gap-2 px-sm-4 px-3 border-0 black-gradiant-btn py-sm-2 py-1 rounded-pill '
                                                        style={{ height: "auto", width: "auto" }}                                       >
                                                        <img src={aiIcon} alt='aiIcon' />
                                                        <span>Expand</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='crop-container position-relative '>
                                            <img src={cropImage} alt='brushImage' />
                                        </div>
                                    </div>
                                </div>
                                <div className=''>
                                    <h5 className='fs-14 font-noto-sans fw-bolder mb-0 padding'>Variations</h5>
                                    <div className='overflow-auto'>
                                        <div className='casual-image-container2 mt-2 '>
                                            <label
                                                className={`position-relative ms-0 image-container image-container-ref mb-0 cursor-pointerselected`}
                                                id='select_image'
                                            >
                                                <div className='export-images'>
                                                    <img
                                                        loading="lazy"
                                                        src={noImage}
                                                        className='rounded-3 '
                                                        alt='no-img'
                                                    />
                                                </div>
                                                <input
                                                    type='checkbox'
                                                    className='image-checkbox'
                                                    htmlFor='select_image'

                                                />
                                                <img src={heartIcon} alt='heartIcon' className='heart-icon' />
                                            </label>
                                            <label
                                                className={`position-relative ms-0 image-container image-container-ref mb-0 cursor-pointerselected`}
                                                id='select_image'
                                            >
                                                <div className='export-images'>
                                                    <img
                                                        loading="lazy"
                                                        src={noImage}
                                                        className='rounded-3 '
                                                        alt='no-img'
                                                    />
                                                </div>
                                                <input
                                                    type='checkbox'
                                                    className='image-checkbox'
                                                    htmlFor='select_image'

                                                />
                                                <img src={heartIcon} alt='heartIcon' className='heart-icon' />
                                            </label>
                                            <label
                                                className={`position-relative ms-0 image-container image-container-ref mb-0 cursor-pointerselected`}
                                                id='select_image'
                                            >
                                                <div className='export-images'>
                                                    <img
                                                        loading="lazy"
                                                        src={noImage}
                                                        className='rounded-3 '
                                                        alt='no-img'
                                                    />
                                                </div>
                                                <input
                                                    type='checkbox'
                                                    className='image-checkbox'
                                                    htmlFor='select_image'

                                                />
                                                <img src={heartIcon} alt='heartIcon' className='heart-icon' />
                                            </label>
                                            <label
                                                className={`position-relative ms-0 image-container image-container-ref mb-0 cursor-pointerselected`}
                                                id='select_image'
                                            >
                                                <div className='export-images'>
                                                    <img
                                                        loading="lazy"
                                                        src={noImage}
                                                        className='rounded-3 '
                                                        alt='no-img'
                                                    />
                                                </div>
                                                <input
                                                    type='checkbox'
                                                    className='image-checkbox'
                                                    htmlFor='select_image'

                                                />
                                                <img src={heartIcon} alt='heartIcon' className='heart-icon' />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

              <div className="position-relative d-flex justify-content-center align-items-center">
                <div className="magic-brush-zoom d-flex flex-column gap-2 py-2 align-items-center bg-white">
                  <button className="px-2 py-0 bg-transparent border-0 font-noto-sans">
                    <img src={plusIcon} alt="plusIcon" width={20} />
                  </button>
                  <button className="px-2 py-0 bg-transparent border-0 font-noto-sans">
                    <img
                      src={removeIcon}
                      alt="removeIcon"
                      width={20}
                      className="rotete-image"
                    />
                  </button>
                  <div className="border-top pt-2">
                    <button className="px-2 py-0 bg-transparent border-0 font-noto-sans">
                      <img src={revertIcon} alt="revertIcon" width={18} />
                    </button>
                  </div>
                </div>
                {/* image bottom className="magic-brush-container2" */}

                <div className="magic-brush-container magic-brush-container2 position-relative">
                  <img src={brushImage} alt="brushImage" />
                  {/* Smart edit first */}

                  {/* <div className='d-flex align-items-center justify-content-center smart-edit-wrapper'>
                                        <div className='brush-size-wrapper px-md-2'>
                                            <div className="w-100 d-flex gap-md-3 gap-2 align-items-center px-md-3 px-2 flex-wrap">
                                                <div className='pe-md-3 pe-2 border-end  d-flex align-items-center gap-md-3 gap-2'>
                                                    <button className='bg-transparent border-0 py-md-3 py-2 d-flex align-items-center gap-2 smart-edit-button '>
                                                        <img src={detectObject} alt='Detect-object' />
                                                        <span>Detect object</span>
                                                    </button>
                                                    <button className='bg-transparent border border-2 rounded-pill py-1 px-2 d-flex align-items-center gap-2 smart-edit-button '>
                                                        <img src={extractIcon} alt='Detect-object' />
                                                        <span>Deselect</span>
                                                    </button>
                                                    <button className='bg-transparent border-0 py-md-3 py-2 px-0 d-flex align-items-center gap-2 smart-edit-button '>
                                                        <img src={deselectIcon} alt='Detect-object' />
                                                        <span>Extract</span>
                                                    </button>
                                                </div>
                                                <button className='bg-transparent border-0  py-md-3 py-2 px-0 d-flex align-items-center gap-2 smart-edit-button'>
                                                    <img src={removeIcons} alt='Detect-object' />
                                                    <span>Remove</span>
                                                </button>
                                                <button className='bg-transparent border-0  py-md-3 py-2 px-0 d-flex align-items-center gap-2 smart-edit-button'>
                                                    <img src={replaceIocn} alt='replaceIocn' />
                                                    <span>Replace</span>
                                                </button>
                                                <button className='bg-transparent border-0  py-md-3 py-2 px-0 d-flex align-items-center gap-2 smart-edit-button'>
                                                    <img src={refineIcon} alt='refineIcon' />
                                                    <span>Refine</span>
                                                </button>
                                            </div>

                                        </div>
                                    </div> */}

                  <div
                    className="d-flex align-items-center justify-content-center smart-edit-wrapper w-100"
                    style={{ maxWidth: "600px" }}
                  >
                    <div className="brush-size-wrapper px-md-2 w-100 py-2">
                      <div className="d-flex align-items-center gap-2 w-100">
                        <img
                          src={arrow}
                          alt="arrow"
                          style={{ width: "25px" }}
                        />
                        <input
                          placeholder="Enter your prompt to replace everything in the selected area"
                          className="w-100 smart-edit-input"
                        />
                        <button
                          className="d-flex align-items-center justify-content-center gap-2 px-sm-4 px-3 border-0 black-gradiant-btn py-sm-2 py-1 rounded-pill "
                          style={{ height: "auto", width: "auto" }}
                        >
                          <img src={aiIcon} alt="aiIcon" />
                          <span>Replace</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <h5 className="fs-14 font-noto-sans fw-bolder mb-0 ">
                  Variations
                </h5>
                <div className="overflow-auto">
                  <div className="casual-image-container2 mt-2 p-0">
                    <label
                      className={`position-relative ms-0 image-container image-container-ref mb-0 cursor-pointerselected`}
                      id="select_image"
                    >
                      <div className="export-images">
                        <img
                          loading="lazy"
                          src={noImage}
                          className="rounded-3 "
                          alt="no-img"
                        />
                      </div>
                      <input
                        type="checkbox"
                        className="image-checkbox"
                        htmlFor="select_image"
                      />
                      <img
                        src={heartIcon}
                        alt="heartIcon"
                        className="heart-icon"
                      />
                    </label>
                    <label
                      className={`position-relative ms-0 image-container image-container-ref mb-0 cursor-pointerselected`}
                      id="select_image"
                    >
                      <div className="export-images">
                        <img
                          loading="lazy"
                          src={noImage}
                          className="rounded-3 "
                          alt="no-img"
                        />
                      </div>
                      <input
                        type="checkbox"
                        className="image-checkbox"
                        htmlFor="select_image"
                      />
                      <img
                        src={heartIcon}
                        alt="heartIcon"
                        className="heart-icon"
                      />
                    </label>
                    <label
                      className={`position-relative ms-0 image-container image-container-ref mb-0 cursor-pointerselected`}
                      id="select_image"
                    >
                      <div className="export-images">
                        <img
                          loading="lazy"
                          src={noImage}
                          className="rounded-3 "
                          alt="no-img"
                        />
                      </div>
                      <input
                        type="checkbox"
                        className="image-checkbox"
                        htmlFor="select_image"
                      />
                      <img
                        src={heartIcon}
                        alt="heartIcon"
                        className="heart-icon"
                      />
                    </label>
                    <label
                      className={`position-relative ms-0 image-container image-container-ref mb-0 cursor-pointerselected`}
                      id="select_image"
                    >
                      <div className="export-images">
                        <img
                          loading="lazy"
                          src={noImage}
                          className="rounded-3 "
                          alt="no-img"
                        />
                      </div>
                      <input
                        type="checkbox"
                        className="image-checkbox"
                        htmlFor="select_image"
                      />
                      <img
                        src={heartIcon}
                        alt="heartIcon"
                        className="heart-icon"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className=" modal-footer w-100">
              <div className="d-flex justify-content-end ">
                <button
                  type="button"
                  className="border-0 bg-transparent text-black fs-14 me-3 fw-bold font-noto-sans"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn rounded-pill py-2 px-4 fs-14 font-noto-sans fw-bold"
                  data-bs-dismiss="modal"
                  style={{ backgroundColor: "black" }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MagicBrush;
