import React from "react";
// import headerSetting from '../../../assets/newicons/header-setting.svg';

const ManagerSetting = () => {
  return (
    <div>
      <div>
        <div>
          <p>Micoworks ad</p>
          {/* <img src={headerSetting} alt="setting" /> */}
        </div>
      </div>
    </div>
  );
};

export default ManagerSetting;
