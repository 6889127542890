import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { handleCheckboxChange } from "./search";

const CategoryFilter = ({ filterTags, formValue, setFormValue }) => {
  const { t } = useTranslation();
  const [expandedCategories, setExpandedCategories] = useState({});
  const location = useLocation();

  const toggleExpand = (categoryKey) => {
    setExpandedCategories((prevState) => ({
      ...prevState,
      [categoryKey]: !prevState[categoryKey],
    }));
  };

  const checkBoxClick = async (e, value, category) => {
    handleCheckboxChange(e, value, category, formValue, setFormValue);
  };

  const onAllToggle = async (e, category) => {
    category.isAllChecked = !category?.isAllChecked;
    handleCheckboxChange(
      e,
      -2,
      category,
      formValue,
      setFormValue,
      category.isAllChecked
    );
  };

  useEffect(() => {
    if (filterTags?.categories) {
      filterTags.categories = filterTags.categories.map((a) => {
        a.isAllChecked = false;
        return a;
      });
    }
  }, [location.pathname]);

  return (
    <>
      {filterTags?.categories?.map((category, index) => (
        <React.Fragment key={index}>
          <div className="d-flex w-100 model-container">
            <span
              onClick={() => {
                if (
                  category?.category_options &&
                  Object.entries(category?.category_options).length > 4
                ) {
                  toggleExpand(category?.category_key);
                }
              }}
              className="filter-label"
              style={{ fontSize: "16px" }}
            >
              {t(category?.category_display_code)}
            </span>
            <span>{category?.category_options == null ? ": None" : ""}</span>
            <div className="d-flex flex-wrap gap-2 w-100 model-options">
              {category?.category_options &&
                Object.entries(category?.category_options)
                  .sort(([optionA, valueA], [optionB, valueB]) => {
                    const isCheckedA =
                      formValue[category.category_key]?.includes(valueA);
                    const isCheckedB =
                      formValue[category.category_key]?.includes(valueB);
                    return isCheckedA === isCheckedB ? 0 : isCheckedA ? -2 : 1;
                  })
                  .slice(
                    0,
                    expandedCategories[category.category_key] ? undefined : 4
                  )
                  .map(([option, value], optionIndex) => {
                    return (
                      <button
                        className={`gap-1 d-flex text-truncate align-items-center tag-button fs-14 position-relative filter-tags ${
                          formValue[category.category_key]?.includes(value)
                            ? "checked"
                            : "bg-transparent"
                        }`}
                        title={t(option)}
                        key={optionIndex}
                        // disabled={category.isAllChecked}
                        type="button"
                        name={category.category_key}
                        onClick={(e) => {
                          value != -2
                            ? checkBoxClick(e, value, category)
                            : onAllToggle(e, category);
                        }}
                      >
                        <input
                          type="checkbox"
                          id={`checkbox-${optionIndex}`}
                          style={{ display: "none" }}
                          value={option}
                        />
                        <span
                          style={{ pointerEvents: "none" }}
                          className="text-truncate"
                        >
                          {t(option)}
                        </span>
                      </button>
                    );
                  })}
              {category?.category_options &&
                Object.entries(category?.category_options).length > 4 && (
                  <button
                    className="see-more-button text-nowrap"
                    onClick={() => toggleExpand(category?.category_key)}
                  >
                    {expandedCategories[category?.category_key]
                      ? t("less")
                      : t("more")}
                  </button>
                )}
            </div>
          </div>
          <div className="bottom-border"></div>
        </React.Fragment>
      ))}
    </>
  );
};

export default CategoryFilter;
