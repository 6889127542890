import React, { useState } from "react";
import { useAuthContext } from "../../context/useAuthContext";
import AccountManagement from "./AccountManagement";
import { useTranslation } from "react-i18next";
import UserManagementContent from "./UserManagementContent";
import styles from "./profile-settings.module.css";

const ProfileSettings = () => {
  const [activeTab, setActiveTab] = useState("account");
  const { t } = useTranslation();

  return (
    <div className={`vw-90 ${styles.container}`}>
      {" "}
      {/* Full viewport height, 90% width */}
      <div className={`${styles.headingContainer}`}>
        <p className={`${styles.heading}`}>{t("SETTINGS")}</p>
        <ul className={`nav nav-tabs card-header-tabs ${styles.navHeader}`}>
          <li className="nav-item">
            <button
              className={`${activeTab === "account" ? styles.active : ""} ${styles.tabButton}`}
              onClick={() => setActiveTab("account")}
            >
              {t("ACCOUNT_SETTINGS")}
            </button>
          </li>
          {/* <li className="nav-item">
                        <button
                            className={`${activeTab === 'user' ? 'active' : ''} ${styles.tabButton}`}
                            onClick={() => setActiveTab('user')}
                        >
                            {t("USER_MANAGEMENT")}
                        </button>
                    </li> */}
        </ul>
      </div>
      <div
        className={`card-body ${styles.settingsBody}`}
        style={{
          width: "100%",
        }}
      >
        {activeTab === "account" ? (
          <AccountManagement />
        ) : (
          <UserManagementContent />
        )}
      </div>
    </div>
  );
};

export default ProfileSettings;
