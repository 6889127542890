import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import headerArrowDown from "../../../assets/newicons/keyboard_arrow_down.svg";
import { useAuthContext } from "../../../context/useAuthContext";
import "../../Manager/manager.scss";
import CreateProjectFormLoader from "../skeletonLoaders/CreateProjectFormLoader";

const CreateProjectForm = ({
  handleChange,
  handlemultipleUsers,
  userList,
  allUsers,
  clientUserList,
  clientManager,
  projectFormValue,
  setProjectFormValue,
  errors,
  handleChannelChange,
  loading,
  handleDateChange,
  setInputProjectName,
  IsFormForUpadate,
  setChecked,
  checked,
  setErros,
  isAllUsers,
  screen,
  projectStatus,
  userRole,
}) => {
  const { hasPermission, userSettings } = useAuthContext();
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [filteredSites, setFilteredSites] = useState(
    userSettings?.channel_name
  );

  const assignToUsers = userList?.filter(
    (user) => user.role_name === "DESIGNER"
  );
  let internalApproverUser = userList?.filter(
    (user) => user.role_name === "CHANNEL_MANAGER"
  );
  // const allUsers = [...assignToUsers, ...internalApproverUser]

  let filteredWatchList = allUsers?.filter(
    (user) =>
      !projectFormValue?.assign_to?.some(
        (assignee) => assignee.user_id === user.user_id
      ) &&
      !projectFormValue?.internal_approver?.some(
        (approver) => approver.user_id === user.user_id
      ) &&
      !projectFormValue?.client_approver?.some(
        (approver) => approver.user_id === user.user_id
      )
  );

  if (projectFormValue?.type_id === 1) {
    filteredWatchList = filteredWatchList?.filter(
      (user) => user.role_id === 3 || user.role_id === 5 || user.role_id === 7
    );
  } else if (projectFormValue?.type_id === 2) {
    if (
      projectFormValue?.internal_approver &&
      !filteredWatchList.includes(projectFormValue.internal_approver)
    ) {
      filteredWatchList = [
        ...filteredWatchList,
        ...projectFormValue.internal_approver,
      ];
    }
    // const additionalUsers = allUsers.filter(user => user.role_id === 3 );
    // filteredWatchList = [...filteredWatchList, ...additionalUsers];
  }

  // filteredWatchList = { ...filteredWatchList, clientManager }

  const approverLabel = [
    { id: 1, label: "Internal" },
    { id: 2, label: "Client" },
  ];

  const skeletonCount = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
    {
      id: 6,
    },
    {
      id: 7,
    },
  ];

  const handleChannelDropDown = () => {
    if (!IsFormForUpadate) {
      setIsDropDownOpen(!isDropDownOpen);
    }
  };

  const handleSiteChange = (e) => {
    const value = e.target.value;
    const filtered = userSettings?.channel_name?.filter((site) =>
      site.site_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSites(filtered);
  };

  const onSiteChange = (site) => {
    handleChannelChange(site);
    setIsDropDownOpen(false);
    setFilteredSites(userSettings?.channel_name);
  };

  const handleCalendarOpen = (e) => {
    setProjectFormValue({ ...projectFormValue, due_date: "" });
  };

  const handleClientApprover = (e) => {
    setChecked(e.value);

    if (e.value === false) {
      const updatedWatchList = projectFormValue.watch_list.filter(
        (user) => !projectFormValue.client_approver.includes(user)
      );

      setProjectFormValue({
        ...projectFormValue,
        client_approver: [],
        watch_list: updatedWatchList,
      });
    }
  };

  const handleApproverType = (event, option) => {
    let updatedFormValue = { ...projectFormValue };

    if (option?.id === 1) {
      updatedFormValue = {
        ...updatedFormValue,
        type_id: 1,
        client_approver: [],
        watch_list: [],
      };
    } else if (option?.id === 2) {
      updatedFormValue = {
        ...updatedFormValue,
        type_id: 2,
        internal_approver: [],
        watch_list: [],
      };
    }

    // Clear the error dynamically through the form value
    updatedFormValue.errorCleared = true;

    setProjectFormValue(updatedFormValue);
  };

  // const handleApproverType = (event, option) => {
  //     option?.id === 1 ? setProjectFormValue({ ...projectFormValue, type_id: 1, client_approver: [], watch_list: []  }) : setProjectFormValue({ ...projectFormValue, type_id: 2, internal_approver: [], watch_list: [] });
  //     // setProjectFormValue({
  //     //     ...projectFormValue,
  //     //     type_id: option?.id,
  //     //     // internalApproverUser:[]
  //     // });
  // };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropDownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isDropDownOpen) {
      setFilteredSites(userSettings?.channel_name);
    }
  }, [isDropDownOpen]);

  // Validating the wrong Input in Date Picker
  const validateInput = (e) => {
    const datePattern = /^\d{2}\/\d{2}\/\d{4}$/; // MM/DD/YYYY format
    if (!datePattern.test(e.target.value)) {
      e.preventDefault(); // Prevent invalid input
    }
  };

  const isAssignToDisabled = () => {
    if (screen === "manager") {
      if (IsFormForUpadate) {
        return userSettings?.permissions && !hasPermission(19);
      } else {
        return (userSettings?.permissions && !hasPermission(14)) || !isAllUsers;
      }
    } else if (screen === "reference") {
      if (IsFormForUpadate) {
         return userRole !== "Creator" && userSettings?.permissions && !hasPermission(19);
        // return userSettings?.permissions && !hasPermission(19);
      } else {
        return userSettings?.permissions && !hasPermission(14);
      }
    }
  };

  const isProjectTypeDisabled = () => {
    if (screen === "manager") {
      return !isAllUsers;
    } else if (screen === "reference") {
      return isAllUsers;
    }
  };

  const isWatchListDisabled = () => {
    if (screen === "manager") {
      return (userSettings?.permissions && !hasPermission(9)) || !isAllUsers;
    } else if (screen === "reference") {
      return userSettings?.permissions && !hasPermission(9);
    }
  };

  return (
    <div className="modal-body">
      {loading ? (
        <div className="">
          {/* <NewLoader /> */}
          {skeletonCount.map((count, i) => (
            <div key={count.id}>
              <div className="w-50 h-25">
                <CreateProjectFormLoader height={30} />
              </div>
              <CreateProjectFormLoader />
            </div>
          ))}
        </div>
      ) : (
        <div className="">
          <div className="mb-1">
            <label
              htmlFor="message-text"
              className="col-form-label fw-bold ms-0"
            >
              {t("channel")} <span style={{ color: "red" }}>*</span>
            </label>
            <div
              className={`from-input ${IsFormForUpadate && "bg-gray-input"}`}
            >
              <div className="w-100 position-relative" ref={dropdownRef}>
                <div
                  style={{ cursor: "pointer" }}
                  className="border-0 d-flex gap-2 align-items-center justify-content-between p-2 pe-md-3 w-100"
                  // onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                  onClick={() => handleChannelDropDown()}
                >
                  {isDropDownOpen ? (
                    <input
                      type="text"
                      className="remove_outline"
                      onChange={handleSiteChange}
                      autoFocus
                      ref={inputRef}
                      placeholder={t("EnterSiteName")}
                    ></input>
                  ) : (
                    <>
                      {projectFormValue?.channel?.site_name ? (
                        <span className="text-500 fs-14">
                          {projectFormValue?.channel?.site_name}
                        </span>
                      ) : (
                        <span
                          className="text-500 fs-14"
                          style={{ color: "#808080" }}
                        >
                          {t("EnterSiteName")}
                        </span>
                      )}
                    </>
                  )}
                  <img
                    onClick={() => handleChannelDropDown()}
                    src={headerArrowDown}
                    style={{
                      cursor: "pointer",
                      transform: isDropDownOpen
                        ? "rotate(180deg)"
                        : "rotate(0)",
                    }}
                    alt="icon"
                  />
                </div>

                {isDropDownOpen && (
                  <>
                    <ul
                      className="position-absolute list-unstyled sitemenu border-0 w-100"
                      style={{
                        marginTop: "5px",
                        boxShadow: "0px 3px 20px 0px #cdcdcd",
                      }}
                    >
                      {filteredSites &&
                        filteredSites?.map((site, index) => (
                          <li
                            className="border-bottom-0"
                            key={index}
                            onClick={() => onSiteChange(site)}
                          >
                            <div className="sitemenu-item text-center border-bottom-0 w-100 fs-14">
                              {site.site_name}
                            </div>
                          </li>
                        ))}
                    </ul>
                  </>
                )}
              </div>
            </div>
          </div>
          <p className="project-form-error">{errors.channel}</p>
          <div className="mb-1">
            <label
              htmlFor="recipient-name"
              className="col-form-label fw-bold ms-0"
              style={{ cursor: "pointer" }} // Hand cursor on hover
            >
              {t("projectName")} <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="project_name"
              minLength={3}
              value={projectFormValue?.project_name}
              onChange={(e) => {
                setInputProjectName(e.target.value);
                handleChange(e);
              }}
              className="form-control from-input"
              id="recipient-name"
              placeholder={t("enterProjectName")}
              disabled={
                !projectFormValue?.channel ||
                projectStatus === 5 ||
                projectStatus === 3
              }
              maxLength={50}
              style={{ cursor: "pointer" }} // Hand cursor initially
              onMouseOver={(e) => (e.target.style.cursor = "pointer")} // Hand cursor on hover
              onFocus={(e) => (e.target.style.cursor = "text")} // I-beam cursor on focus
            />
          </div>

          <p
            className={`project-form-error ${
              errors.project_name === t("projectNameValid") ? "text-green" : ""
            }`}
          >
            {errors.project_name}
          </p>

          {/* <div className="mb-1">
                        <label htmlFor="message-text" className="col-form-label fw-bold ms-0">{t("projectDescription")}</label>
                        <input
                            type='text'
                            name="project_description"
                            value={projectFormValue?.project_description}
                            onChange={handleChange}
                            className="form-control from-input"
                            id="message-text"
                            placeholder={t("enterProjectDescription")}
                            maxLength={500}
                        />
                    </div>
                    <p className={`project-form-error`}>{errors.project_description}</p> */}

          <div className="mb-1">
            <label
              htmlFor="message-text"
              className="col-form-label fw-bold ms-0"
              style={{ cursor: "pointer" }} // Hand cursor on hover
            >
              {t("projectDescription")} <span style={{ color: "red" }}>*</span>
            </label>
            <textarea
              type="text"
              rows="2"
              name="project_description"
              value={projectFormValue?.project_description}
              onChange={handleChange}
              className="form-control from-input"
              id="message-text"
              placeholder={t("enterProjectDescription")}
              maxLength={500}
              disabled={projectStatus === 5 || projectStatus === 3}
              style={{ cursor: "pointer" }} // Hand cursor initially
              onMouseOver={(e) => (e.target.style.cursor = "pointer")} // Hand cursor on hover
              onFocus={(e) => (e.target.style.cursor = "text")} // I-beam cursor on focus
            />
          </div>
          <p className="project-form-error">{errors.project_description}</p>

          {/* <div className='d-flex gap-2'>
                        <div className='w-100'>
                            <div className='mb-1 w-100'>
                                <label htmlFor="start_date" className="col-form-label">{t("startDate")} <span style={{color:"red"}}>*</span></label>
                                <div className={`${IsFormForUpadate && "bg-gray-input rounded-3"}`}>
                                    <div className="w-100 position-relative" id="start_date">
                                        <DatePicker
                                            // locale="es"

                                            selected={projectFormValue?.start_date}
                                            onChange={(date) => handleDateChange(date, "start_date")}
                                            placeholderText={t("mm/dd/yyyy")}
                                            className='input-border-gray w-100 bg-transparent rounded border-secondary p-2 from-input'
                                            dateFormat="MM/dd/yyyy"
                                            minDate={new Date()}
                                            onCalendarOpen={handleCalendarOpen}
                                            disabled={IsFormForUpadate}
                                            maxDate={addYears(new Date(), 2)}
                                            // maxDate={new Date(2026, 11, 31)}
                                            onChangeRaw={validateInput}
                                        />
                                        <img src={calendar_month} alt=' calendar_month' className='input-date-icon' />
                                    </div>
                                </div>
                            </div>
                            <p className='project-form-error'>{errors.start_date}</p>
                        </div>

                        <div className='w-100'>
                            <div className='mb-1 w-100'>
                                <label htmlFor="due_date" className="col-form-label">{t("dueDate")} <span style={{color:"red"}}>*</span></label>
                                <div className="">
                                    <div className="position-relative" id="due_date">
                                        <DatePicker
                                            // locale="es"
                                            selected={projectFormValue?.due_date}
                                            onChange={(date) => handleDateChange(date, "due_date")}
                                            placeholderText={t("mm/dd/yyyy")}
                                            className='input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-2 from-input'
                                            dateFormat="MM/dd/yyyy"
                                            type="date"
                                            minDate={IsFormForUpadate ? new Date() : (projectFormValue?.start_date || new Date())}
                                            maxDate={addYears(new Date(), 2)}
                                            onChangeRaw={validateInput}
                                        />
                                        <img src={calendar_month} alt='calendar_month' className='input-date-icon' />

                                    </div>
                                </div>
                            </div>
                            <p className='project-form-error'>{errors.due_date}</p>
                        </div>
                    </div> */}

          <div className="mb-1">
            <label
              htmlFor="message-text"
              className="col-form-label fw-bold ms-0"
              style={{ cursor: "pointer" }} // Hand cursor on hover
            >
              {t("requiredSheet")} <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="required_sheet"
              value={projectFormValue?.required_sheet}
              onChange={handleChange}
              autoComplete="off"
              className="form-control from-input"
              id="message-text"
              placeholder={t("enterNoOfSheetRequired")}
              disabled={projectStatus === 5 || projectStatus === 3}
              maxLength={50} // Ensure maxLength instead of max (max is for number type)
              style={{ cursor: "pointer" }} // Hand cursor by default
              onMouseOver={(e) => (e.target.style.cursor = "pointer")} // Hand cursor on hover
              onFocus={(e) => (e.target.style.cursor = "text")} // I-beam cursor on focus
              onWheel={(e) => e.preventDefault()} // Prevent scroll change (useful if it's number input in future)
            />
          </div>

          <p className="project-form-error">{errors.required_sheet}</p>

          <div className="mb-1 multiselect-user">
            <label
              htmlFor="message-text"
              className="col-form-label fw-bold ms-0 "
              style={{ cursor: "pointer" }} // Hand cursor on hover
            >
              {t("assignToDesigner")}{" "}
              {/* <span style={{ color: "red", alignItems: "center" }}>*</span> */}
            </label>

            <Autocomplete
              multiple
              id="tags-outlined"
              options={
                assignToUsers?.filter(
                  (user) =>
                    !projectFormValue.internal_approver.some(
                      (assigned) => assigned.user_id === user.user_id
                    ) &&
                    !projectFormValue.client_approver.some(
                      (approver) => approver.user_id === user.user_id
                    )
                ) || []
              }
              getOptionLabel={(option) => option?.username}
              filterSelectedOptions
              className="input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-0 from-input"
              value={projectFormValue?.assign_to}
              onChange={(e, filterSelectedOptions) =>
                handlemultipleUsers(e, filterSelectedOptions, "assign_to")
              }
              disabled={
                isAssignToDisabled() ||
                projectStatus === 5 ||
                projectStatus === 3
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("selectUserName")}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      "&.Mui-disabled": {
                        backgroundColor: "#e9ecef",
                        borderRadius: "7px",
                        height: "fit-content",
                        padding: "0px",
                        paddingLeft: "5px",
                      },
                      cursor: "pointer", // Hand cursor initially
                      "&:focus": {
                        cursor: "text", // I-beam cursor when focused
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: "14px",
                      color: "#808080 !important",
                      fontWeight: "400",
                      opacity: "100%",
                    },
                  }}
                />
              )}
              componentsProps={{
                popper: {
                  modifiers: [
                    {
                      name: "preventOverflow",
                      options: {
                        boundary: "window",
                      },
                    },
                  ],
                },
                paper: {
                  sx: {
                    cursor: "pointer", // Hand cursor for dropdown list
                    padding: "0px",
                    paddingLeft: "5px",
                  },
                },
              }}
            />
          </div>

          {/* <p className="project-form-error">{errors.assign_to}</p> */}

          {/* Approver */}
          <div className="mb-1 multiselect-user">
            <label
              htmlFor="project-type"
              className="col-form-label fw-bold ms-0"
            >
              {t("projectType")}
              <span style={{ color: "red" }}>*</span>
            </label>
            <Autocomplete
              // disablePorta
              options={approverLabel || []}
              className="input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-0 from-input"
              onChange={(e, option) => handleApproverType(e, option)}
              value={
                projectFormValue?.type_id === 1
                  ? "Internal"
                  : projectFormValue?.type_id === 2
                    ? "Client"
                    : ""
              }
              // value={projectFormValue?.type_id===''? '' : (projectFormValue?.type_id==='0' ? "Internal" : "Client")}
              disabled={
                isProjectTypeDisabled() ||
                projectStatus === 5 ||
                projectStatus === 3
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("selectApprover")}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      "&.Mui-disabled": {
                        backgroundColor: "#e9ecef",
                        borderRadius: "7px",
                        height: "38px",
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: "14px",
                      color: "#808080 !important",
                      fontWeight: "400",
                      opacity: "100%",
                    },
                  }}
                />
              )}
            />
          </div>
          {/* {!projectFormValue?.type_id && (
    <p className="project-form-error">Project Type is required.</p>

)} */}
          <p className="project-form-error">
            {projectFormValue.errorCleared ? "" : errors.type_id}
          </p>
          {/* <p className='project-form-error'>{errors.type_id}</p> */}

          {projectFormValue?.type_id === 1 && (
            <>
              <div className="mb-1 multiselect-user align-items-center">
                <label
                  htmlFor="approver-type"
                  className="col-form-label fw-bold ms-0 align-items-center"
                >
                  {" "}
                  {t("internalApprover")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Autocomplete
                  multiple={true}
                  id="tags-approver"
                  options={
                    internalApproverUser?.filter(
                      (user) =>
                        !projectFormValue.assign_to.some(
                          (assigned) => assigned.user_id === user.user_id
                        ) &&
                        !projectFormValue.client_approver.some(
                          (approver) => approver.user_id === user.user_id
                        )
                    ) || []
                  }
                  getOptionLabel={(option) => option?.username}
                  filterSelectedOptions
                  className="input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-0 from-input"
                  value={projectFormValue?.internal_approver}
                  onChange={(e, filterSelectedOptions) =>
                    handlemultipleUsers(
                      e,
                      filterSelectedOptions,
                      "internal_approver"
                    )
                  }
                  disabled={projectStatus === 5 || projectStatus === 3}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t("selectUserName")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          "&.Mui-disabled": {
                            backgroundColor: "#e9ecef",
                            borderRadius: "7px",
                            // height: "38px",
                            padding: "0px",
                            paddingLeft: "5px",
                          },
                          cursor: "pointer",
                          "&:focus": {
                            cursor: "text",
                          },
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "14px",
                          color: "#808080 !important",
                          fontWeight: "400",
                          opacity: "100%",
                        },
                      }}
                    />
                  )}
                  componentsProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "preventOverflow",
                          options: {
                            boundary: "window",
                          },
                        },
                      ],
                    },
                    paper: {
                      sx: {
                        cursor: "pointer",
                        padding: "0px",
                        paddingLeft: "5px",
                      },
                    },
                  }}
                />
              </div>
              {projectFormValue?.internal_approver?.length === 0 && (
                <p className="project-form-error">{errors.internal_approver}</p>
              )}
              {/* <p className='project-form-error'>{errors.internal_approver}</p> */}
            </>
          )}

          {/* <div className="mb-1 mt-2 multiselect-user position-relative"> */}
          {/* <div className="date-input d-flex align-items-center gap-3">
                                <InputSwitch checked={checked} onChange={(e) => handleClientApprover(e)} />
                                <label htmlFor="message-text" className="col-form-label fw-bold ms-0">{t("clientApprover(optional)")}</label>
                            </div> */}
          {/* <div className='check-popover'></div>

                        <button type="button" className="btn btn-secondary" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="left" data-bs-content="Left popover">
                            Popover on left
                        </button> */}
          {projectFormValue?.type_id === 2 && (
            <>
              <div className="mb-1 multiselect-user">
                <label
                  htmlFor="client-approver"
                  className="col-form-label fw-bold ms-0"
                >
                  Client Approver <span style={{ color: "red" }}>*</span>
                </label>
                <Autocomplete
                  multiple={true}
                  id="tags-client-approver"
                  options={
                    clientManager?.filter(
                      (user) =>
                        !projectFormValue.assign_to.some(
                          (assigned) => assigned.user_id === user.user_id
                        ) &&
                        !projectFormValue.internal_approver.some(
                          (approver) => approver.user_id === user.user_id
                        )
                    ) || []
                  }
                  getOptionLabel={(option) => option?.username}
                  filterSelectedOptions
                  className="input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-0 from-input"
                  // value={projectFormValue?.client_approver}
                  value={projectFormValue?.client_approver}
                  onChange={(e, filterSelectedOptions) =>
                    handlemultipleUsers(
                      e,
                      filterSelectedOptions,
                      "client_approver"
                    )
                  }
                  disabled={projectStatus === 5 || projectStatus === 3}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="filterSelectedOptions"
                      placeholder={t("selectUserName")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          "&.Mui-disabled": {
                            backgroundColor: "#e9ecef",
                            borderRadius: "7px",
                            // height: "38px",
                          },
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "14px",
                          color: "#808080 !important",
                          fontWeight: "400",
                          opacity: "100%",
                        },
                      }}
                    />
                  )}
                />
              </div>
              {projectFormValue?.client_approver?.length === 0 && (
                <p className="project-form-error">{errors.client_approver}</p>
              )}

              {/* <p className='project-form-error'>{errors.client_approver}</p> */}
            </>
          )}
          {/* </div> */}

          <div className="mb-1 multiselect-user">
            <label
              htmlFor="message-text"
              className="col-form-label fw-bold ms-0"
            >
              {t("watchList")}{" "}
            </label>
            <Autocomplete
              multiple={true}
              id="tags-outlined"
              options={filteredWatchList || []}
              getOptionLabel={(option) => option?.username}
              // defaultValue={[userList[1]]}
              filterSelectedOptions
              className="input-border-gray w-100 h-50 bg-transparent rounded border-secondary p-0 from-input"
              value={projectFormValue?.watch_list}
              onChange={(e, filterSelectedOptions) =>
                handlemultipleUsers(e, filterSelectedOptions, "watch_list")
              }
              // disabled={userSettings?.permissions && !hasPermission(9)}
              disabled={
                isWatchListDisabled() ||
                projectStatus === 5 ||
                projectStatus === 3
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="filterSelectedOptions"
                  placeholder={t("selectUserName")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      "&.Mui-disabled": {
                        backgroundColor: "#e9ecef",
                        borderRadius: "7px",
                        // height: "38px",
                        padding: "0px",
                        paddingLeft: "5px",
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: "14px",
                      color: "#808080 !important",
                      fontWeight: "400",
                      opacity: "100%",
                    },
                  }}
                />
              )}
            />
          </div>
          <p className="project-form-error">{errors.watch_list}</p>

          {/* <div className="mb-1">
                    <p className="col-form-label fw-bold mb-1">Watchlist (can watch and comment)</p>
                    <div className='d-flex align-content-center'>
                        <input type="checkbox" id="html"
                            name="watchlist"
                            value={projectFormValue.watchlist}
                            checked={projectFormValue.watchlist}
                            onChange={(e) => handleChange(e, "watchlist")}
                            style={{ height: "18px", width: "18px" }}
                        />
                        <label htmlFor="html">CE/CS, Design manager, assigned designer</label>
                    </div>
                </div> */}
        </div>
      )}

      {/* <div className='d-flex justify-content-end align-items-center gap-3 mt-5' >
                            <button type="button" className="cancel_modal_footer" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="send_modal_footer" data-bs-dismiss="modal" onClick={handleSubmit}>Create</button>
                        </div> */}
    </div>
  );
};

export default CreateProjectForm;
